import LearningPlanAction1CellStd from './LearningPlanAction1CellStd';
import LearningPlanAction1CellUKPN from './LearningPlanAction1CellUKPN';

const LearningPlanAction1Cell = ({ handleStart, row, isUKPN, toggleModalElearningRefresher, onChangeState, openModal, setRowPwSelection }) => {

    return (
        isUKPN ?
            <LearningPlanAction1CellUKPN
                handleStart={handleStart}
                row={row}
                toggleModalElearningRefresher={toggleModalElearningRefresher}
                onChangeState={onChangeState}
                openModal={openModal}
                setRowPwSelection={setRowPwSelection}
            />
            :
            <LearningPlanAction1CellStd
                handleStart={handleStart}
                row={row}
                toggleModalElearningRefresher={toggleModalElearningRefresher}
                openModal={openModal}
                setRowPwSelection={setRowPwSelection}
            />
    );

};

export default LearningPlanAction1Cell;