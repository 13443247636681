import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import MaterialReactTable from 'material-react-table';
import ViewCertificatesPdf from './view-certificates-pdf';
import { apiAxiosCalls } from "../../../services/apiAxiosCalls";
import { Button, Col, Row } from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Link } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

export default function ViewCertificatesHistory({ personID, onBackButtonClick, dimensions }) {
    const [rowSelection, setRowSelection] = useState({});
    const [loading, setLoading] = useState(true);
    const [searchString, setSearchString] = useState('');
    const [gridData, setGridData] = useState([]);
    const [showCertificate, setShowCertificate] = useState(false);
    const [certHistId, setCertHistId] = useState(0);

    const getCertificatesHistory = async (personId) => {
        try {
            //personId = '10624';
            const { data } = await apiAxiosCalls.GetCompCertificateHistory(personId);
            if (!data.isValid) {
                throw new Error("Error getting certificates history");
            }
            else {
                setGridData([...data.value.map(x => ({
                    DatePrinted: x.datePrinted,
                    PrintedBy: x.printedBy,
                    PrintedById: x.printedById,
                    Networks: x.networks,
                    CertHistId: x.certHistId,
                }))]);
            }
        }
        catch (error) {
            console.log(`Error fetching certificates history: ${error}`)
        }
        finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        if (personID > 0) {
            getCertificatesHistory(personID);
        }
    },
        [personID]);

    const viewCertificateOnClick = (event, certHistId) => {
        event.preventDefault();

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className=" card" style={{ 'width': '25rem' }}>
                        <div className="card-body">
                            <h5 className="card-title">Important Note</h5>
                            <p className="card-text">This function is for VIEWING previous certificates.</p>
                            <p className="card-text">DO NOT PRINT certificate for issue from this screen​</p>
                            <div className="d-flex justify-content-center">
                                <Button
                                    variant='btn btn-outline-primary'
                                    onClick={onClose}
                                    style={{ 'width': '100px' }}
                                >
                                    Cancel
                                </Button>&nbsp;
                                <Button
                                    variant='btn btn-outline-primary'
                                    style={{ 'width': '100px' }}
                                    onClick={() => {
                                        setCertHistId(certHistId);
                                        setShowCertificate(true);
                                        onClose();
                                    }}
                                >
                                    Continue
                                </Button>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    };

    const handleBackButtonClick = () => {
        setLoading(true);
        setShowCertificate(false);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }


    const columns = useMemo(
        () =>
            [
                {
                    accessorKey: 'DatePrinted',
                    header: 'Date Printed'
                },
                {
                    accessorKey: 'PrintedBy',
                    header: 'Printed By'
                },
                {
                    accessorKey: 'PrintedById',
                    header: ''
                },
                {
                    accessorKey: 'Networks',
                    header: 'Networks'
                },
                {
                    accessorKey: 'CertHistId',
                    header: 'PDF',
                    size: 20,
                    Cell: ({ cell, row }) => (
                        <Link to='/' onClick={(event) => { viewCertificateOnClick(event, row.original.CertHistId) }}>
                            View
                        </Link>
                    )
                }
            ],
        [],
    );

    return (
            <>
                {
                    !showCertificate ?
                        <Row>
                            <Col>
                                <MaterialReactTable
                                    columns={columns}
                                    data={gridData || []}
                                    enableBottomToolbar={false}
                                    enableColumnActions={false}
                                    enableColumnFilters={false}
                                    enableDensityToggle={false}
                                    enableStickyHeader={true}
                                    enableFullScreenToggle={false}
                                    enableHiding={false}
                                    enablePagination={false}
                                    enableTopToolbar={true}
                                    enableFilterMatchHighlighting={true}
                                    globalFilterFn="contains"//By default is fuzzy
                                    enableGlobalFilter={true}
                                    getRowId={(originalRow) => originalRow.id}
                                    muiTableBodyRowProps={({ row }) => ({
                                        onClick: () =>
                                            setRowSelection((prev) => ({ [row.id]: !prev[row.id] })),
                                        hover: true,
                                        selected: rowSelection[row.id],
                                        sx: {
                                            cursor: 'pointer',
                                        },
                                    })}
                                    onGlobalFilterChange={setSearchString} //zc - new for custom search
                                    muiTableContainerProps={({ table }) => dimensions.computedMuiTableContainerProps(table, null, { 'min-height': '300px' })}
                                    renderTopToolbarCustomActions={() => <>
                                        <h6 className='text-muted border border-secondary shadow p-2'>Competency Certificate History
                                            <Button
                                                variant='btn btn-outline-primary'
                                                className='pt-0 pb-0'
                                                style={{ marginLeft: '1rem' }}
                                                onClick={(event) => { onBackButtonClick() }}
                                            >
                                                Back
                                            </Button></h6></>}
                                    initialState={{
                                        pagination: { pageIndex: 0, pageSize: 10 },
                                        globalFilter: searchString, //zc - new for custom search
                                        showGlobalFilter: true //This displays the seach generic way for the materia react table

                                    }}
                                    state={{
                                        isLoading: loading || !gridData,
                                        globalFilter: searchString,//zc - new for custom search
                                    }}
                                />
                            </Col>
                        </Row>
                        : null
                }
                {
                    showCertificate ?
                        <ViewCertificatesPdf
                            certId={certHistId}
                            onBackButtonClick={handleBackButtonClick}
                        ></ViewCertificatesPdf>
                        : null
                }

            </>
    );
}
