import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {NavLink} from "react-router-dom";
import {ThemeProvider, createTheme} from '@mui/material';
import {faAngleDoubleLeft, faAngleDoubleRight} from '@fortawesome/pro-light-svg-icons';
import {
    faChalkboard,
    faChalkboardUser,
    faPersonChalkboard,
    faCircle,
    faPlus,
    faPhotoFilm,
    faRoad
} from '@fortawesome/pro-solid-svg-icons';
import {useState} from 'react';
import '../styling/splittable.scss';


function randomId() {
    return Math.floor(Math.random() * 999999999999).toString(16);
};

// INFO:
// learningItemTypeId:
//      1: course
//      2: instructor led training
//      3: e-learning
//      8: Virtual/classroom

/**
 * Display column if width is variant or greater
 *
 * @param {string} variant - sm, md, lg or xl
 * @param {*} id  - An optional id. A random id is assigned if not provided.
 */
export const columnBlankIfWidth = (variant, id) => {
    return {
        accessorFn: (row) => '',
        id: id || randomId(),
        enableEditing: false,
        header: '',
        muiTableBodyCellProps: {
            classes: {root: `fw-unset-none-blank fw-cell-${variant}`},   // Flexible width, hidden if below variant size
            sx: {minSize: 0}
        },
        muiTableHeadCellProps: {
            classes: {root: `fw-unset-none-blank fw-cell-${variant}`},   // Flexible width, hidden if below variant size
            sx: {minSize: 0}
        }
    }
};

export const columnIconLearningItem = (addedByYouLabel = 'You', variant, navToFn) => {
    let columnDef = {
        accessorKey: 'code',
        enableEditing: false,
        header: 'Type',
        muiTableHeadCellProps: {
            align: 'center'
        },
        size: 50
    };
    if (navToFn) {
        columnDef.Cell = ({cell, row}) => {
            return (<NavLink to={navToFn(cell, row)}><ColumnIconLearningItemCell addedByYouLabel={addedByYouLabel}
                                                                                 learningItemTypeId={row.original.learningItemTypeId}
                                                                                 isMandatory={row.original.isMandatory}
                                                                                 isAddedByAdmin={row.original.isAddedByAdmin}/></NavLink>)
        };
    } else {
        columnDef.Cell = ({cell, row}) => {
            return (<ColumnIconLearningItemCell addedByYouLabel={addedByYouLabel}
                                                learningItemTypeId={row.original.learningItemTypeId}
                                                isMandatory={row.original.isMandatory}
                                                isAddedByAdmin={row.original.isAddedByAdmin}/>)
        };
    }
    if (variant) {
        return {
            ...columnDef,
            ...{
                muiTableBodyCellProps: ({cell, table}) => {
                    return cell.id.startsWith('pwgrpName:') ? {sx: {display: 'none'}} : {
                        align: 'center',
                        classes: {root: `d-none fw-cell-${variant}`}
                    }
                },
                muiTableHeadCellProps: {classes: {root: `d-none fw-cell-${variant}`}}
            }
        };
    } else {
        return {
            ...columnDef,
            ...{
                muiTableBodyCellProps: ({cell, table}) => {
                    return cell.id.startsWith('pwgrpName:') ? {sx: {display: 'none'}} : {align: 'center'}
                }
            }
        };
    }
};

const ColumnIconLearningItemCell = (props) => {
    return (<>
        <OverlayTrigger placement='right' delay={{show: 250, hide: 400}} overlay={<Tooltip
            className='cls-theme-tooltip'>{(props.isMandatory ? 'Mandatory ' : '') + (props.learningItemTypeId === -1 ? 'Learning Pathway' : learningItemName(props.learningItemTypeId))}</Tooltip>}>
            <FontAwesomeIcon
                icon={props.learningItemTypeId === 8 ? faPhotoFilm : props.learningItemTypeId === 3 ? faChalkboardUser : props.learningItemTypeId === 2 ? faPersonChalkboard : props.learningItemTypeId === -1 ? faRoad : faChalkboard}
                className={props.isMandatory ? 'app-mandatory' : 'app-non-mandatory'} size='2xl'/>
        </OverlayTrigger>
        <OverlayTrigger placement='right' delay={{show: 250, hide: 400}}
                        overlay={<Tooltip className='cls-theme-tooltip'>Added
                            by {props.isAddedByAdmin ? 'Administrator' : props.addedByYouLabel}</Tooltip>}>
            <FontAwesomeIcon icon={props.isAddedByAdmin ? faCircle : faPlus} className='text-info'
                             transform='shrink-6'/>
        </OverlayTrigger>
    </>)
};

/**
 * Display column if width is variant or greater.
 *
 * This function should not be used if columnDef contains
 * muiTableBodyCellProps and/or muiTableHeadCellProps
 * defintitions
 *
 * @param {string} variant - sm, md, lg or xl
 * @param {object} columnDef - column definition
 * @returns
 */
export const columnIfWidth = (variant, columnDef) => {
    return {
        ...{
            muiTableBodyCellProps: {classes: {root: `d-none fw-cell-${variant}`}},
            muiTableHeadCellProps: {classes: {root: `d-none fw-cell-${variant}`}}
        },
        ...columnDef
    }
};

export const learningItemName = (id) => id === 8 ? 'Reference material' : id === 3 ? 'e-learning' : id === 2 ? 'Instructor led' : 'Course';
export const learningItemIcon = (learningItemTypeId, isMandatory) => <FontAwesomeIcon
    icon={learningItemTypeId === 8 ? faPhotoFilm : learningItemTypeId === 3 ? faChalkboardUser : (learningItemTypeId === 2 ? faPersonChalkboard : learningItemTypeId === -1 ? faRoad : faPersonChalkboard)}
    className={isMandatory ? 'app-mandatory' : 'app-non-mandatory'} size='2xl'/>
;
export const muiTableBodyCellEditFieldPropsDate = {
    inputProps: {
        sx: {
            fontSize: '0.875rem',
            padding: '2px 0 2px 0 !important'
        }
    },
    InputProps: {
        sx: {
            paddingLeft: '6px'
        }
    },
    required: false,
    sx: {
        width: '128px'
    },
    type: 'date'
}

export const muiTableBodyCellEditFieldPropsCheckboxFn = (props = {}) => {
    return function ({cell, column, row, table}) {
        return {
            ...{
                inputProps: {
                    checked: cell.getValue(),
                    sx: {
                        fontSize: '0.675rem',
                        border: 'none !important',
                        padding: '2px 0 2px 0 !important'
                    }
                },
                InputProps: {
                    sx: {
                        paddingBottom: '4px',
                        paddingTop: '5px'
                    }
                },
                sx: {
                    border: 'none !important'
                },
                type: 'checkbox'
            },
            ...props
        };
    };
};

const tableContainerTheme = createTheme({
    typography: {
        fontFamily: 'var(--bs-body-font-family)',
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: 'var(--bs-body-font-family)',
                    fontSize: 'var(--bs-body-font-size)'
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    verticalAlign: 'top',
                    fontFamily: 'var(--bs-body-font-family)',
                    fontSize: 'var(--bs-body-font-size)'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    border: "none",
                    borderRadius: "unset",
                    boxShadow: "none",
                    margin: 0,
                    padding: 0
                }
            }
        }
    }
});

export const TableContainer = ({children}) => <ThemeProvider theme={tableContainerTheme}>
    <div className='shadow border rounded bg-white pb-1 pt-1'>{children}</div>
</ThemeProvider>;

export const TableTheme = ({children}) => <ThemeProvider theme={tableContainerTheme}>{children}</ThemeProvider>;

export const TableRowSplit = ({
                                  leftPane = null,
                                  rightPane = null,
                                  outsideCollapsed = false,
                                  setOutsideCollapsed = () => null,
                                    useOutsideCollapsed = false
                              }) => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <TableTheme>
            <Row className='flex-grow-1 pb-2 overflow-hidden'>
                <Col className='col-auto h-100 overflow-hidden' style={{padding: '0 1px 0 0'}}
                     onClick={() => {
                         if(useOutsideCollapsed){
                             setOutsideCollapsed(!outsideCollapsed)
                             return
                         }
                         setCollapsed(!collapsed)
                     }}>
                    <FontAwesomeIcon style={{fontSize: '12px'}}
                                     icon={useOutsideCollapsed ? outsideCollapsed ? faAngleDoubleRight : faAngleDoubleLeft : collapsed ? faAngleDoubleRight : faAngleDoubleLeft}/>
                </Col>
                <Col className='h-100 shadow border rounded bg-white overflow-hidden'
                     style={ useOutsideCollapsed ? outsideCollapsed === null ? {marginLeft: '0.75rem'} : null : collapsed === null ? {marginLeft: '0.75rem'} : null}>
                    <div className='h-100 d-flex flex-row w-100'>
                        <div
                            style={{transition: 'width .9s ease, opacity .9s ease'}}
                            className={ useOutsideCollapsed ? outsideCollapsed ? 'splittable-left-collapsed' : 'splittable-left-expanded border-end' : collapsed ? 'splittable-left-collapsed' : 'splittable-left-expanded border-end'}
                        >
                            {leftPane}
                        </div>
                        <div className='overflow-hidden w-100'>
                            {rightPane}
                        </div>
                    </div>
                </Col>
            </Row>
        </TableTheme>
    );
};
