import { useState } from "react";

import GroupCard from "../GroupCard";
import PathwayCard from "./PathwayCard";
import LearningPathwayEditorGroupTable from "./LearningPathwayEditorGroupTable";
import DataLeftRight from "../DataLeftRight";
export default function LearningPathwayEditorGroup(props) {
  return (
    <>
      <PathwayCard {...props.pathway} />

      <GroupCard
        {...props.dataSelected}
        defaultMessage={<h5>Please select a Group</h5>}
      />

      <DataLeftRight>
        <LearningPathwayEditorGroupTable {...props} />
      </DataLeftRight>
    </>
  );
}
