import MaterialReactTable from 'material-react-table';
import { Badge, Button, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { faMemoCircleInfo, faCircleInfo, faCircleCheck, faFileArrowDown, faRecycle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatDuration, isAttended, isCompleted, isConfirmed, isPassed, isPathway, withSelectedRows } from './learningPlanDataHelpers';
import { getSynopsis, responseData, getEventFullInfo, getEventVenueInfo } from "../../hooks/useLmsStore";
import { isSupportedDate, toDDsMMsYYString } from '../../helpers/formatHelper';
import { useState } from 'react';

const getFlattenedEventVenueInfo = (data) => {
    let flatData = [];
    if (Array.isArray(data)) {
        const uniqueEvents = [...new Set(data.map(a => a.eventId))];
        uniqueEvents.forEach((eventId) => {
            const events = data.filter(x => x.eventId === eventId);
            const firstEvent = events[0];
            if (events.length == 1) {
                flatData.push(firstEvent);
            } else {
                flatData.push({
                    eventId: eventId,
                    eventStartDate: firstEvent.eventStartDate,
                    eventStartTime: firstEvent.eventStartTime,
                    coAddr1: [...new Set(events.map(a => a.coAddr1))].join(', '),
                    coAddr2: firstEvent.coAddr2,
                    coAddr3: firstEvent.coAddr3,
                    coAddr4: firstEvent.coAddr4,
                    coTown: firstEvent.coTown,
                    coCounty: firstEvent.coCounty,
                    coPostCode: firstEvent.coPostCode,
                    coPhone: firstEvent.coPhone,
                    description: [...new Set(events.map(a => a.description))].join(', '),
                    roomCode: [...new Set(events.map(a => a.roomCode))].join(', '),
                    roomName: [...new Set(events.map(a => a.roomName))].join(','),
                })
            }
        });
    }
    return flatData;
};

const LearningPlanAboutCell = ({ cell, row, selectedRows, tableLoadingPw, tableColumnsPw, enableResetElearning, toggleModalResetElearning, setRefLearningItem, setEventVenueInfo, setIsOpenUserEvent, setUserEventInfo }) => {
    const [showSynopsis, setShowSynopsis] = useState(false);
    const [synopsisSections, setSynopsisSections] = useState([]);
    const [synopsisTitle, setSynopsisTitle] = useState(null);

    const handleCloseSynopsis = (event) => {
        setShowSynopsis(false);
    };

    const handleShowSynopsis = (learningItemId, itemName) => {
        getSynopsis(learningItemId).then((response) => {
            let data = responseData(response, []);
            setSynopsisSections(data);
            setShowSynopsis(true);
            setSynopsisTitle(itemName);
        });
    };

    const handleOpenSynopsisLink = async (url) => {
        window.open(url, "_blank");
    }

    function handleOpenUserEventInfo(userEventInfo) {
        getEventFullInfo(userEventInfo.eventId);
        getEventVenueInfo(userEventInfo.eventId)
            .then(d => setEventVenueInfo(getFlattenedEventVenueInfo(d.data)));
        setUserEventInfo(userEventInfo);
        setIsOpenUserEvent(true);
    }

    function handleResetElearning(learningItemId) {
        setRefLearningItem(learningItemId);
        toggleModalResetElearning();
    };

    const renderCompletedDateColumn = (rowData) => {
        return (
            toDDsMMsYYString(rowData.elearningInfo?.dateUpdated) != '' ?
                <>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Completed Date</Tooltip>}>
                        <span>
                            {toDDsMMsYYString(rowData.elearningInfo?.dateUpdated)}
                        </span>
                    </OverlayTrigger>
                </>
                : rowData.dateCreated != undefined ?
                    <>{
                        toDDsMMsYYString(rowData.dateCompleted) != '' ?
                            <>
                                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Date Completed</Tooltip>}>
                                    <span>{toDDsMMsYYString(rowData.dateCompleted)}</span>
                                </OverlayTrigger>
                            </> :
                            <>
                                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Date Completed</Tooltip>}>
                                    <span>{toDDsMMsYYString(rowData.dateCreated)}</span>
                                </OverlayTrigger>
                            </>
                    }</>
                    : <></>
        )
    };

    const renderEventDateColumn = (rowData, durationResult) => {
        return ((isConfirmed(rowData) || isAttended(rowData)) && rowData.instructorInfo ?
            <>
                {toDDsMMsYYString(rowData.instructorInfo.startDate) != '' ?
                    <>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Event Date</Tooltip>}>
                            <span>{toDDsMMsYYString(rowData.instructorInfo.startDate)} {durationResult != '' ? '(' + durationResult + ')' : ''}</span>
                        </OverlayTrigger>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Event Detail</Tooltip>}>
                            <Button variant='clear' onClick={() => handleOpenUserEventInfo(rowData)}>
                                <FontAwesomeIcon className='text-primary' icon={faMemoCircleInfo} />
                            </Button>
                        </OverlayTrigger>
                    </> :
                    <></>
                }
            </>
            : <></>);
    };

    const renderDateColumn = (rowData) => {
        let durationResult = formatDuration(rowData.duration, rowData.durationUnit);
        return (
            <>
                {isCompleted(rowData) ?
                    <>
                        {renderCompletedDateColumn(rowData)}
                    </> :
                    <>
                        {(isSupportedDate(rowData.expiryDate) || isConfirmed(rowData) || isAttended(rowData)) ?
                            <>
                                {isSupportedDate(rowData.expiryDate) ?
                                    <>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Due Date</Tooltip>}>
                                            <span>{toDDsMMsYYString(rowData.expiryDate)} {durationResult != '' ? '(' + durationResult + ')' : ''}</span>
                                        </OverlayTrigger>
                                    </> : <></>}
                            </> : <></>}
                        {renderEventDateColumn(rowData, durationResult)}
                    </>
                }
            </>);
    };

    const renderResetElearning = (rowData) => {
        return (
            rowData.hasRepeatItems !== true && rowData.learningItemTypeId == 3 && enableResetElearning && rowData.elearningInfo != undefined && rowData.statusName !== 'Not Started' ?
                <>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                        overlay={< Tooltip className='cls-theme-tooltip' > {(isConfirmed((rowData)) ? 'Cancel ' : 'Book ') + rowData.name}</Tooltip >}>
                        <Button variant='primary px-3 w-0 mx-3' style={{ lineHeight: '1rem' }} className='pt-0 pb-0' onClick={() => handleResetElearning(rowData.learningItemId)}>
                            <FontAwesomeIcon size='xl' icon={faRecycle} /><span>Reset</span>
                        </Button>
                    </OverlayTrigger>
                </> : <></>)
    };

    const synopsisSectionsContent = synopsisSections.map((item, id) => {
        return <div key={id}>
            <h5>{item.tagTitle}</h5>
            <p><span dangerouslySetInnerHTML={{ __html: item.tagHtml }} /></p>
        </div>
    });

    return (
        <>
            <Row>
                {!isPathway(row.original) ?
                    <>
                        <Col xs="5" >
                            <div className='fw-bold'>{cell.getValue()}</div>
                            {row.original.description ? (row.original.description.length > 190 ? (<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'> {row.original.description}</Tooltip>}>
                                {<div> {row.original.description.substring(0, 190)} ...</div>}
                            </OverlayTrigger>) : <div>{row.original.description}</div>) : ''
                            }
                        </Col>
                        <Col xs='1'>
                            {row.original.hasSynopsis ? (<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>View Synopsis</Tooltip>}>
                                <Button variant='clear' className='pt-0 pb-0' onClick={() => handleShowSynopsis(row.original.learningItemId, row.original.name)}>
                                    <FontAwesomeIcon size='xl' icon={faCircleInfo} className='text-primary' />
                                </Button>
                            </OverlayTrigger>) : ''
                            }
                        </Col>
                        <Col xs='1'>
                            {row.original.synopsisLink != '' && row.original.synopsisLink != undefined ? (<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Course outline</Tooltip>}>
                                <Button variant='clear' className='pt-0 pb-0' onClick={() => handleOpenSynopsisLink(row.original.synopsisLink)} >
                                    <FontAwesomeIcon size='xl' icon={faFileArrowDown} className='text-primary' />
                                </Button>
                            </OverlayTrigger>) : ''}
                        </Col>
                        <Col xs='2'>
                            {renderDateColumn(row.original)}
                        </Col>
                        <Col xs='2'>
                            {row.original.statusName}
                        </Col>
                        {row.original.elearningInfo ?
                            <>
                                <Col xs='1'>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Satisfaction Status - {row.original.elearningInfo.successStatusName} ({toDDsMMsYYString(row.original.elearningInfo.dateUpdated)})</Tooltip>}>
                                        <span>
                                            {row.original.elearningInfo.successStatusName}
                                        </span>
                                    </OverlayTrigger>
                                </Col>
                            </> : <></>}
                    </>
                    :
                    <>
                        <Col xs="6" >
                            <div className='fw-bold'>{cell.getValue()}</div>
                            {row.original.description ? (row.original.description.length > 190 ? (<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'> {row.original.description}</Tooltip>}>
                                {<div> {row.original.description.substring(0, 190)} ...</div>}
                            </OverlayTrigger>) : <div>{row.original.description}</div>) : ''
                            }
                        </Col>
                        <Col xs='3'>
                            <>
                                {!isCompleted(row.original) ?
                                    <>
                                        {row.original.outstandingItems} outstanding item{row.original.outstandingItems > 1 ? 's' : ''}
                                    </> : <></>}
                            </>
                        </Col>
                        <Col xs='1'>
                            {row.original.statusName}
                        </Col>
                    </>}
            </Row>
            <Row className='gx-1' >
                {isCompleted(row.original) && row.original.learningItemTypeId !== 2 ?
                    <Col xs='auto'>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Completed</Tooltip>}>
                            <FontAwesomeIcon size='xl' icon={faCircleCheck} className='text-success' />
                        </OverlayTrigger>
                    </Col> : <></>}
                {row.original.elearningInfo ?
                    row.original.elearningInfo.totalAttemptGroups > 0 ?
                        <>
                            <Col xs='auto'>
                                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Number of times that a new attempt has been made at this eLearning course.</Tooltip>}>
                                    <Badge bg='secondary'>
                                        Attempt {row.original.elearningInfo.totalAttemptGroups}
                                    </Badge>
                                </OverlayTrigger>
                            </Col>
                            {row.original.elearningInfo.maxAttempts > 0 ?
                                <Col xs='auto'>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>The max number of attempts for this course is.</Tooltip>}>
                                        <Badge bg='secondary'>
                                            Max Attempts: {row.original.elearningInfo.maxAttempts}
                                            {row.original.elearningInfo.totalAttemptGroups >= row.original.elearningInfo.maxAttempts ? <span> done</span> : null}
                                        </Badge>
                                    </OverlayTrigger>
                                </Col> : null}
                            <Col xs='auto'>
                                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Completion Status - {row.original.elearningInfo.completionStatusName} ({toDDsMMsYYString(row.original.elearningInfo.dateUpdated)})</Tooltip>}>
                                    <Badge bg={isCompleted(row.original) ? 'success' : 'danger'}>CS</Badge>
                                </OverlayTrigger>
                            </Col>
                            {isCompleted(row.original) ?
                                isPassed(row.original) ?
                                    <Col xs='auto'>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Satisfaction Status - {row.original.elearningInfo.successStatusName} ({toDDsMMsYYString(row.original.elearningInfo.dateUpdated)})</Tooltip>}>
                                            <Badge bg='success'>
                                                SS
                                            </Badge>
                                        </OverlayTrigger>
                                    </Col> : <></>
                                :
                                <Col xs='auto'>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Satisfaction Status - {row.original.elearningInfo.successStatusName} ({toDDsMMsYYString(row.original.elearningInfo.dateUpdated)})</Tooltip>}>
                                        <Badge bg={isPassed(row.original) ? 'success' : 'warning'}>
                                            SS
                                        </Badge>
                                    </OverlayTrigger>
                                </Col>
                            }
                            <Col xs='auto'>
                                {renderResetElearning(row.original)}
                            </Col>
                        </> : <></> : <></>}
                {row.original.isOpen ?
                    <>
                        {
                            row.original.children != null ? <>
                                <Row className='m-4'>
                                    <MaterialReactTable
                                        columns={tableColumnsPw}
                                        data={withSelectedRows(row.original.children, selectedRows)}
                                        enableBottomToolbar={false}
                                        enableColumnActions={false}
                                        enableColumnFilters={false}
                                        enableDensityToggle={false}
                                        enableFullScreenToggle={false}
                                        enableGlobalFilter={false}
                                        enableGrouping={true}
                                        enableHiding={false}
                                        enablePagination={false}
                                        enableSorting={false}
                                        enableTopToolbar={false}
                                        enableFilters={true}
                                        muiTableProps={{ sx: { borderCollapse: 'collapse' } }}
                                        muiTableBodyRowProps={{ hover: false, sx: { border: '0.5rem solid var(--app-nav-primary)', } }}
                                        muiTableHeadProps={{
                                            sx: {
                                                display: 'none'
                                            }
                                        }}
                                        muiToolbarAlertBannerProps={{
                                            sx: {
                                                display: 'none'
                                            }
                                        }}
                                        muiTopToolbarProps={{
                                            sx: {
                                                display: 'none'
                                            }
                                        }}
                                        state={
                                            {
                                                expanded: true,
                                                grouping: ['pwgrpName'],
                                                isLoading: tableLoadingPw,
                                            }
                                        }
                                    />
                                </Row>
                            </> : <></>
                        }
                    </>
                    : <></>
                }
                {/* For debuging purposes - display the json for each row */}
                {/* <Row>
                    <Col>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>JSON</Accordion.Header>
                                <Accordion.Body>
                                    <span dangerouslySetInnerHTML={{ __html: JSON.stringify(row.original).replaceAll(",", ",<br/>") }} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row> */}
            </Row>
            <Modal fullscreen='md-down' show={showSynopsis} onHide={handleCloseSynopsis} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{synopsisTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {synopsisSectionsContent}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={handleCloseSynopsis}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LearningPlanAboutCell;