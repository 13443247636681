import { useState, useEffect,useReducer, useRef, useMemo  } from 'react';
import { Container, Row, Col, Form, InputGroup, Modal, Button,OverlayTrigger, Tooltip } from "react-bootstrap";
import { TableContainer } from '../helpers/tableHelper';
import MaterialReactTable from 'material-react-table';
import useLmsStore, { responseData, getTrainerGroupMembers } from "../hooks/useLmsStore";

const ModalPeopleSelect = ({ closeFn = () => null, open = false, submitFn = (obj) => null, selectedData, allPeople}) => {

    const [inProgressM, setInProgressM] = useState(false);
    const [people, setPeople] = useState([]);
    const [apply, setApply] = useState(false);
    const [headerClickState, handleHeaderClick] = useReducer(function (state, action) { return { count: state.count + 1, flag: action }; }, { count: 0, flag: 'none' }); // count to ensure a change of headerClickState on every click
     //optionally, you can manage the row selection state yourself
    const [rowSelection, setRowSelection] = useState({});
    
    const handleSubmit = async event => {
        let result = [];
        let newItemId= (-1) * selectedData.length;
        setInProgressM(true);
       const selectedPeople =allPeople.map((a) => {
        if (a!=undefined){
               if (rowSelection[a.id]===true){
              
                setInProgressM(false);
                  return a;
                };
            }
    });

    console.log('selectedPeople',selectedPeople);

      if (selectedPeople){
         result= selectedPeople.filter((a) => a!=undefined).map((a) => {
           newItemId++;
              return  {'personId':a.id,
                'fullName':a.fullName,
                'email':a.email,
                'employeeId':a.employeeId,
                'joinId': newItemId};
            
        });
    }
      
        await submitFn(result);
        setInProgressM(false);
        setApply(false); 
    };

    useEffect(() => {
           
        if (allPeople && selectedData){
        //    console.log('all peopel count', allPeople.length);
          const availableLi= allPeople.filter(x => selectedData.filter(e => e.personId===x.id ).length <= 0);
          //console.log('filterr all peopel count', availableLi.length);
               // console.log('available people', availablePeople);
                setPeople(availableLi);
                setRowSelection({});  
        }

    }, [selectedData]);  

    const columnsItems = useMemo(
        () => [
            {
                accessorKey: "fullName",
                header: "FullName"
            },
            {
                accessorKey: "employeeId",
                header: "EmployeeId"     
            },
            {
                accessorKey: "email",
                header: "Email"           
            }
        ],
        [],
    );

  
    function ShowPeople(props) { 
        return (
            <Container fluid>
                <Row>
                    <TableContainer>
                        <MaterialReactTable
                            columns={columnsItems}
                            data={people || []}
                            enableBottomToolbar={true}
                            enableColumnActions={false}
                            enableColumnFilters={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableGrouping={false}
                            enableHiding={false}
                            enablePagination={true}
                            enableSorting={false}
                            enableTopToolbar={true}
                            enableEditing={false}
                            getRowId={(row) => row.id}
                            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own                       
                            enableRowSelection
                            state={{ rowSelection }} //pass our managed row selection state to the table to use                       
                            initialState={{
                                pagination: { pageIndex: 0, pageSize: 10 },
                                showGlobalFilter: true
                            }}
                        />
                    </TableContainer>
                </Row> 
            </Container>
        )};

    return (
        <Modal size="lg" aria-labelledby="example-modal-sizes-title-lg" show={open} onHide={closeFn} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Add Users</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                 <ShowPeople></ShowPeople>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={closeFn}>Close</Button>
                {   
                    inProgressM ?

                    <Button variant="primary" disabled>
                        <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                        Please wait...
                    </Button>
                    :
                    <Button variant="primary" onClick={handleSubmit}>
                        Add
                    </Button>
                }

            </Modal.Footer>
        </Modal>
    );
};

export default ModalPeopleSelect;