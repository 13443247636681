import { useEffect } from 'react';

import { ToastContainer } from 'react-toastify';

import AppRoutes from './lms/components/AppRoutes';
import AppTheme from './lms/components/AppTheme';
import { AuthProvider } from './lms/auth/hooks/useAuth.js';
import { MsalProvider } from "@azure/msal-react"

export default function App({ msalInstance }) {

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log('process.env:');
      for (const key of Object.keys(process.env)) {
        if (typeof process.env[key] !== 'undefined') {
          console.log(`\t${key}=${process.env[key]}`);
        }
      }
      console.log('Note that as we use <React.StrictMode>, and in the development environment only, "on Load" useEffects are executed twice when the component is first loaded (like this one). Nothing to worry about!');
    }
  }, []);


  return (
      <MsalProvider instance={msalInstance} >
        <AppTheme>
          <AuthProvider>
            <ToastContainer />
            <AppRoutes />
          </AuthProvider>
        </AppTheme>
      </MsalProvider>
  );
}
