import BaseBeginContainer from "../components/BaseBeginContainer";
import BaseContainer from "../components/BaseContainer";
import shallow from 'zustand/shallow';
import useLmsStore, { hasPermission } from '../hooks/useLmsStore';
import { BeginButton } from '../components/CustomButtons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { apiAxiosCalls } from '../services/apiAxiosCalls';
import { faAddressCard, faUser } from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { useEffect } from 'react';

function ExternalTitle(props) {
    return (<>{props.title} <FontAwesomeIcon size='xs' icon={faArrowUpRightFromSquare} className='text-mutted' /></>);
};
const AccessCardDashboardCore = ({ dimensions }) => {
    const { personBasic, fetchPersonBasic, hasPermission } = useLmsStore(state =>
    ({
        personBasic: state.personBasic.data,
        fetchPersonBasic: state.fetchPersonBasic,
        hasPermission: state.hasPermission

    }), shallow)
    useEffect(() => {

        //    setHasPermission(handlePermission('ac.view'));
    }, []);


    return (
        <BaseBeginContainer dimensions={dimensions}>
            <BeginButton title='Users' description='View user and upload user photo.' icon={faUser} to='/lms/users' />
            {hasPermission('ac.view') === true ? <>
                <BeginButton title={<ExternalTitle title='Access Card' />} description='View, print, activate, re-issue and deactivate access cards.' icon={faAddressCard} href={apiAxiosCalls.hrefToAdminServer(personBasic, 'portal.admin.accessCards')} target='admin-tab' />
            </> : <></>}
        </BaseBeginContainer>);
};


export default function AccessCardDashboard(props) {
    return (<BaseContainer title='Access Card Dashboard'>
        <AccessCardDashboardCore />
    </BaseContainer>);
}