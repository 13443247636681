import React from 'react';
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {Field, FieldArray} from "formik";
import {faArrowUpRightFromSquare} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/pro-solid-svg-icons";

const SingleAssessment = ({
                              item,
                              index,
                              form: {
                                  handleSubmit, handleChange, values, touched, errors, handleBlur
                              },
                              fieldArrayProps,
                              assessmentNames,
                              assessmentActionTypes
                          }) => {

    const renderNameOptions = () => {
        return assessmentNames?.map(name => {
            return <option key={name.Id} value={name.Id}>{name.Value}</option>
        })
    }

    const renderTypeOptions = () => {
        return assessmentActionTypes?.map(type => {
            return <option key={type.Id} value={type.Id}>{type.Value}</option>
        })
    }

    return <div
        style={{
            background: "rgba(136,136,136,0.15)"
        }}
        className={"d-flex flex-column mb-3 p-3 rounded-2"}
    >
        <div className={"d-flex justify-content-end"}>

            <Button className={""} onClick={() => fieldArrayProps.remove(index)}>
                <FontAwesomeIcon size='xs' icon={faTrash}/>
            </Button>
        </div>
        <Row className={"w-100 mb-3"}>
            <Col md={3}>
                <Form.Group className="mb-3" controlId="dropdownType">
                    <Form.Label>Assessment (Name) <span title='Mandatory field'
                                                        className='mandatory'>*</span></Form.Label>
                    <Form.Select
                        name={`items[${index}].AssessmentId`}
                        value={values.items[index].AssessmentId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        aria-label="Default select example"
                    >
                        <option value="">Select type</option>
                        {renderNameOptions()}
                    </Form.Select>
                    {touched.items?.[index]?.AssessmentId && errors.items?.[index]?.AssessmentId ? (
                        <div className="invalid-feedback d-block">{errors.items?.[index]?.AssessmentId}</div>
                    ) : null}
                </Form.Group>
            </Col>

            <Col md={3}>
                <Form.Group className="mb-3" controlId="dropdownType">
                    <Form.Label>Assessment (Type) <span title='Mandatory field'
                                                        className='mandatory'>*</span></Form.Label>
                    <Form.Select
                        name={`items[${index}].AssessmentTypeId`}
                        value={values.items[index].AssessmentTypeId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        aria-label="Default select example"
                    >
                        <option value="">Select type</option>
                        {renderTypeOptions()}
                    </Form.Select>
                    {touched.items?.[index]?.AssessmentTypeId && errors.items?.[index]?.AssessmentTypeId ? (
                        <div className="invalid-feedback d-block">{errors.items?.[index]?.AssessmentTypeId}</div>
                    ) : null}
                </Form.Group>
            </Col>

            <Col md={3}>
                <Form.Group className="mb-3" controlId="validationFormik01">
                    <Form.Label>Reminder Period (Days) <span title='Mandatory field'
                                                             className='mandatory'>*</span></Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="number"
                            name={`items[${index}].NoDaysToSendAssessment`}
                            value={values.items[index].NoDaysToSendAssessment}
                            onChange={handleChange}
                            isValid={touched.items?.[index]?.NoDaysToSendAssessment && !errors.items?.[index]?.NoDaysToSendAssessment}
                            isInvalid={touched.items?.[index]?.NoDaysToSendAssessment && errors.items?.[index]?.NoDaysToSendAssessment}
                            onBlur={handleBlur}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.items?.[index]?.NoDaysToSendAssessment}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </Col>

            <Col md={3}>
                <Form.Group className="mb-3" controlId={`checkboxIsActive-${index}`}>
                    <Form.Label>Initiate From Last Reminder?</Form.Label>
                    <Form.Check
                        type="checkbox"
                        name={`items[${index}].IsReminderFromPrevReminder`}
                        checked={values.items[index].IsReminderFromPrevReminder}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Form.Group>
            </Col>

        </Row>
        <Row>
            <FieldArray
                name={`items[${index}].Reminders`}
                render={reminderArrayHelpers => (
                    <div>
                        <div className={"mb-3 d-flex flex-column align-items-start"}>
                            <b>Total reminders ({values.items[index].Reminders.length})</b>
                            <Button
                                type="button"
                                onClick={() => reminderArrayHelpers.push({NumberDaysToSend: '' /* default values for a new reminder */})}
                            >
                                Add Reminder
                            </Button>
                        </div>
                        {values.items[index].Reminders.map((reminder, reminderIndex) => (
                            <Row className={"d-flex align-items-center"} key={reminderIndex}>
                                <Col md={5}>
                                    <Form.Group className="mb-3" controlId="validationFormik01">
                                        <Form.Label>Reminder Number <span title='Mandatory field'
                                                                          className='mandatory'>*</span></Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type="number"
                                                name={`items[${index}].Reminders[${reminderIndex}].ReminderNumber`}
                                                value={reminder.ReminderNumber}
                                                onChange={handleChange}
                                                isValid={touched.items?.[index]?.Reminders?.[reminderIndex]?.ReminderNumber && !errors.items?.[index]?.Reminders?.[reminderIndex]?.ReminderNumber}
                                                isInvalid={!!errors.items?.[index]?.Reminders?.[reminderIndex]?.ReminderNumber}
                                                onBlur={handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.items?.[index]?.Reminders?.[reminderIndex]?.ReminderNumber}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                    <Form.Group className="mb-3" controlId="validationFormik01">
                                        <Form.Label>Reminder Period (Days) <span title='Mandatory field'
                                                                                 className='mandatory'>*</span></Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type="number"
                                                name={`items[${index}].Reminders[${reminderIndex}].NumberDaysToSend`}
                                                value={reminder.NumberDaysToSend}
                                                onChange={handleChange}
                                                isValid={touched.items?.[index]?.Reminders?.[reminderIndex]?.NumberDaysToSend && !errors.items?.[index]?.Reminders?.[reminderIndex]?.NumberDaysToSend}
                                                isInvalid={!!errors.items?.[index]?.Reminders?.[reminderIndex]?.NumberDaysToSend}
                                                onBlur={handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.items?.[index]?.Reminders?.[reminderIndex]?.NumberDaysToSend}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Button onClick={() => reminderArrayHelpers.remove(reminderIndex)}>
                                        <FontAwesomeIcon size='xs' icon={faTrash}/>
                                    </Button>
                                </Col>
                            </Row>
                        ))}
                    </div>
                )}
            />
        </Row>
    </div>
};

export default SingleAssessment;
