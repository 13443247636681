import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CustomTableView from '../../../components/common/generic-table-view';
import ExpandCircleDownOutlined from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import ViewDocument from './view-document';
import { faArrowAltSquareDown } from '@fortawesome/pro-solid-svg-icons';
import { cellToLocaleDateTimeString } from '../../../helpers/formatHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import { TableTheme } from '../../../helpers/tableHelper';
import { teamProfileService } from '../services/team-profile.service';
import { useEffect, useMemo, useState } from 'react';

export default function TrainingEvidenceAccordian(props) {
    const header = props.header;
    const personId = props.personId;
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [documents, setDocuments] = useState([]);
    const [showDocument, setShowDocument] = useState(false);
    const [docData, setDocData] = useState(null);
    const [documentMimeType, setDocumentMimeType] = useState(null);

    useEffect(() => {
        if (personId > 0) {
            setLoading(true);
            teamProfileService.GetDocumentsWithoutBlobForPerson(personId)
                .then((result) => {
                    setUserInfo(result.person);
                    setDocuments(result.evidenceFolders);
                })
                .finally(() => { setLoading(false); });
        }
    }, []);

    const rowClickEventHandler = (event, row) => {
        event.preventDefault();
        //console.log(JSON.stringify(row.original));
        if (row.original.document != null) {
            setDocData(row.original.document);
            setDocumentMimeType(row.original.documentMimeType);
            setShowDocument(true);
        }
    }

    const handleBackButtonClick = (event) => {
        // event.preventDefault();
        setShowDocument(false);
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'dateAdded',
                header: 'Date Issued',
                size: 150,
                Cell: cellToLocaleDateTimeString
            },
            {
                accessorKey: 'courseTitle',
                header: 'Course',
                size: 300,
            },
            {
                accessorKey: 'documentName',
                header: 'Title',
                size: 200,
                muiTableBodyCellProps: {
                    classes: { root: 'fw-unset' }   // Flexible width
                },
                Cell: ({ cell, row }) => <span dangerouslySetInnerHTML={{ __html: cell.getValue() }} />
            },
            {
                accessorKey: 'documentType',
                header: 'Type',
            },
            {
                accessorKey: 'documentID',
                header: '',
                muiTableBodyCellProps: {
                    align: 'center',
                    sx: {
                        textOverflow: 'unset'
                    }
                },
                size: 75,
                Cell: ({ cell, row }) => {
                    return (
                        <Link to={{ pathname: '/lms/userdocument/' + cell.getValue() }}>
                            <FontAwesomeIcon icon={faArrowAltSquareDown} className='text-info' size='lg' />
                        </Link>
                    )
                }
            },
        ],
        [],
    );

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandCircleDownOutlined />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ backgroundColor: '#281E84', color: 'white' }}
            >
                <Typography>{header}</Typography>

            </AccordionSummary>
            <AccordionDetails>
                {showDocument ?
                    <ViewDocument
                        docData={docData}
                        documentMimeType={documentMimeType}
                        onBackButtonClick={handleBackButtonClick}
                    />
                    :
                    <TableTheme>
                        <CustomTableView
                            users={documents}
                            columns={columns}
                            loading={loading}
                            rowClickEventHandler={(event, row) => rowClickEventHandler(event, row)}
                        >
                        </CustomTableView>
                    </TableTheme>
                }
            </AccordionDetails>
        </Accordion>);

}
