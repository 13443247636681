import axios from "axios";
import { apiAxios } from "../helpers/axios";
import { getLocationConfigFromDNSKey } from '../hooks/useLocationConfig'
import { getValidToken } from "../helpers/validateHelper";

/*const getApiUrl = (urlFragment) => {
   // let urlSrv = '/rpcall-learningportal.dev';
   let urlSrv = '/rpcall-ukpnportal.dev';
    return `${urlSrv}${urlFragment}`;
}
*/
class ApiAxiosCalls {



    constructor() {
        this.searchPermissionsCancellationToken = null
        this.lastSearchPermissionsId = 0;
        this.searchGroupsCancellationToken = null
        this.searchGroupsId = 0;
        this.getCardsBelongingToGroupCancellationToken = null
        this.getCardsBelongingToGroupId = 0;
        this.locationConfig = null;
    }

    getLocationConfig() {
        const keyFromDNS = window.location.hostname.split(':')[0].replace('.wdr.co.uk', '').replaceAll('/', '');
        let cfg = this.locationConfig;
        if (!cfg || cfg.key !== keyFromDNS) {
            cfg = getLocationConfigFromDNSKey(keyFromDNS);
            this.locationConfig = cfg;
        }
        return cfg;
    }

    getrpcallUrl(urlMethod) {
        return this.getLocationConfig().getRpCallUrl(urlMethod);
    }

    getApplicationLogInPageConfiguration() {
        return apiAxios.get('/api/Application/GetApplicationLogInPageConfiguration')
    }

    samlLogout() {
        return apiAxios.post("/api/samlAuth/logout2", {})
    }

    searchPermissions(payload) {

        const currentSearchId = ++this.lastSearchPermissionsId;

        if (this.searchPermissionsCancellationToken) {
            this.searchPermissionsCancellationToken.cancel('Request cancelled on purpose');
        }

        this.searchPermissionsCancellationToken = axios.CancelToken.source();

        return new Promise((resolve, reject) => {

            if (currentSearchId === this.lastSearchPermissionsId) {
                apiAxios.post(this.getrpcallUrl('/api/permissions/search'), payload, {
                    cancelToken: this.searchPermissionsCancellationToken.token
                }).then(response => {
                    resolve(response);
                }).catch(error => {
                    if (!axios.isCancel(error)) {
                        reject(error);
                    }
                }).finally(() => {
                    if (currentSearchId === this.lastSearchPermissionsId) {
                        this.searchPermissionsCancellationToken = null;
                    }
                });
            }
        });
    }

    createGroupApiCall(payload) {
        return apiAxios.post(this.getrpcallUrl('/api/groupManagement'), payload)
    }


    getCardsBelongingToGroup(payload) {
        const currentSearchId = ++this.getCardsBelongingToGroupId;

        if (this.getCardsBelongingToGroupCancellationToken) {
            this.getCardsBelongingToGroupCancellationToken.cancel('Request cancelled on purpose');
        }

        this.getCardsBelongingToGroupCancellationToken = axios.CancelToken.source();

        return new Promise((resolve, reject) => {
            if (currentSearchId === this.getCardsBelongingToGroupId) {

                apiAxios.post(this.getrpcallUrl('/api/trainingCard/groupManagement/search'), payload, {
                    cancelToken: this.getCardsBelongingToGroupCancellationToken.token
                }).then(response => {

                    resolve(response);
                }).catch(error => {
                    if (!axios.isCancel(error)) {
                        reject(error);
                    }
                }).finally(() => {
                    // Clear the token after the request completes or fails
                    if (currentSearchId === this.getCardsBelongingToGroupId) {
                        this.getCardsBelongingToGroupCancellationToken = null;
                    }
                });
            }

        });
    }

    searchGroupMembers(payload) {
        const currentSearchId = ++this.searchGroupsId;

        if (this.searchGroupsCancellationToken) {
            this.searchGroupsCancellationToken.cancel('Request cancelled on purpose');
        }

        this.searchGroupsCancellationToken = axios.CancelToken.source();

        return new Promise((resolve, reject) => {

            if (currentSearchId === this.searchGroupsId) {
                apiAxios.post(this.getrpcallUrl('/api/people/groupsearch'), payload, {
                    cancelToken: this.searchGroupsCancellationToken.token
                }).then(response => {
                    resolve(response);
                }).catch(error => {
                    if (!axios.isCancel(error)) {
                        reject(error);
                    }
                }).finally(() => {
                    if (currentSearchId === this.searchGroupsId) {
                        this.searchGroupsCancellationToken = null;
                    }
                });
            }

        });
    }

    getGroupSummaryRecords(payload) {
        return apiAxios.post(this.getrpcallUrl('/api/groupManagement/getSummaryRecords'), payload)
    }

    searchGroupsApiCall(payload) {
        return apiAxios.post(this.getrpcallUrl('/api/groupManagement/searchlms'), payload)
    }

    //learning items calls
    //searchLearningItems(payload) {
    //    return apiAxios.post(this.getrpcallUrl('/api/learningItems/search'), payload)       
    //}

    getLearningItemDetail(learningItemId) {
        //alert(this.getrpcallUrl(`/api/learningItems/getlearningiteminfo/${learningItemId}`))
        return apiAxios.get(this.getrpcallUrl(`/api/learningItems/getlearningiteminfo/${learningItemId}`))
    }

    getSuppliers() {
        return apiAxios.post(this.getrpcallUrl(`/api/suppliers/lookup/lookupsuppliers`))
    }

    getLearningItemTypes() {
        return apiAxios.post(this.getrpcallUrl(`/api/learningitems/lookup/learningItemType`))
    }

    saveLearningItem(payload) {
        return apiAxios.post(this.getrpcallUrl(`/api/learningItems/saveLearningItem`), payload)
    }

    getVideoLinkTypes() {
        return apiAxios.post(this.getrpcallUrl(`/api/learningitems/lookup/videolinktype`))
    }

    postUpdateLearningItemImage(learningItemId, newImage) {
        const payload = JSON.stringify(newImage);
        return apiAxios.post(
            this.getrpcallUrl(`/api/learningItems/updateLearningItemImage/${learningItemId}`),
            newImage,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, */*',
                    'authorization': getValidToken()
                }// Ensure to set the content type as application/json
            }
        );
    }

    uploadCourseImage(id, file) {

        let formData = new FormData();

        formData.append('', file);

        return apiAxios.post(
            this.getrpcallUrl(`/api/learningItemUpload/uploadCourse?id=${id}&uploadIdentifier=&type=`),
            formData,

        );
    }
    uploadCourseScormFile(id, file) {

        let formData = new FormData();

        formData.append('', file);

        return apiAxios.post(
            this.getrpcallUrl(`/api/elearningupload/uploadCourse?id=${id}&uploadIdentifier=&type=`),
            formData,

        );
    }


    getLearningItemImage(id) {
        return apiAxios.get(this.getrpcallUrl(`/api/learningItems/getLearningItemImage/${id}`))
    }

    getLearningItemSynopsis(id) {
        return apiAxios.get(this.getrpcallUrl(`/api/learningItems/synopsisAdmin/${id}`))
    }

    saveLearningItemSynopsis(payload) {
        return apiAxios.post(this.getrpcallUrl(`/api/learningItems/SaveSynopsisTemplate`), payload)
    }

    getLearningItem(id) {
        return apiAxios.get(this.getrpcallUrl(`/api/learningItems/${id}`))
    }

    getAssessmentNames() {
        return apiAxios.get(this.getrpcallUrl(`/api/admin/assessment/GetAllAssessmentNames`))
    }

    getAssessmentActionTypes() {
        return apiAxios.get(this.getrpcallUrl(`/api/preAndpostAssessSetting/GetAllAssessmentActionTypes`))
    }

    getLearningItemAssessSettingInfo(id) {
        return apiAxios.get(this.getrpcallUrl(`/api/preAndpostAssessSetting/GetLearningItemAssessSettingInfo/${id}`))
    }

    savePreAndPostAccessSetting(payload) {
        return apiAxios.post(this.getrpcallUrl(`/api/preAndpostAssessSetting/Save`), payload)
    }

    getELearingItemBasic(id) {
        return apiAxios.get(this.getrpcallUrl(`/api/learningItems/GetELearningLearningItemBasic2/${id}`))
    }

    getElearnCourseProperties(id) {
        return apiAxios.get(this.getrpcallUrl(`/api/elearningCourse/GetElearnCourseProperties/${id}`))
    }
    getAllCourseTypes() {
        return apiAxios.get(this.getrpcallUrl(`/api/elearningCourse/GetAllCourseTypes`))
    }
    getAllVersioningActions() {
        return apiAxios.get(this.getrpcallUrl(`/api/elearningCourse/GetAllVersioningActions`))
    }

    updateCourseInfo(payload) {
        return apiAxios.post(this.getrpcallUrl(`/api/elearningCourse/UpdateCourseInfo`), payload)
    }

    updateElearningDueDates(payload){
        return apiAxios.post("/api/elearningadmin/updateduedate", payload);
    }

    getElearningDueDate(id) {
        return apiAxios.get(`/api/elearningadmin/getduedates/${id}`)
    }
    
    getAllRusticiCourseVersionDetails(id) {
        return apiAxios.get(this.getrpcallUrl(`/api/elearningCourse/GetAllRusticiCourseVersionDetails/${id}`))
    }
    getELearningPreviewLink(id) {
        return apiAxios.get(this.getrpcallUrl(`/eLearning/GetELearningPreviewLink?elearnId=${id}`))
    }
    existCourseInScorm(id) {
        return apiAxios.get(this.getrpcallUrl(`/api/elearningCourse/ExistCourseInScorm/${id}`))
    }
    validateElearningCourse(fileName = "Batman_Scorm.zip") {
        return apiAxios.get(this.getrpcallUrl(`/api/elearningCourse/ValidateElearningCourse?fileName=${fileName}`))
    }

    publishElearningCourse(payload) {
        return apiAxios.post(this.getrpcallUrl(`/api/elearningCourse/PublishElearningCourse/`), payload)
    }

    remapCourse(id) {
        return apiAxios.post(this.getrpcallUrl(`/api/elearningCourse/RemapRusticiCourseToAnotherRound`), id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json, text/plain, */*',
            }
        })
    }
    createWaitingDatesByLearningItem(id) {
        return apiAxios.post(this.getrpcallUrl(`/api/waitingDates/createWaitingDatesByLearningItem`), id)
    }

    handleAdminLogin(personIdentifier = "") {
        return apiAxios.post(this.getrpcallUrl("/api/account/singleSignOnFromNewLms"), { ccid: personIdentifier })
            .then(res => {
                apiAxios.get(this.getrpcallUrl("/api/account/getApplicationRegistration")).then(res => {

                })
            })
    }

    searchLearningItems(payload) {
        return apiAxios.post('/api/learningItems/search', payload);
    }

    getDevelopmentTypes() {
        return apiAxios.get('/api/learningItems/developmentTypes');
    }

    getDeliveryMethods() {
        return apiAxios.get('/api/learningItems/deliveryMethods');
    }

    getCourseTypes() {
        return apiAxios.get(`/api/learningitems/coursetypes`);
    }

    /**
     * =============================================================================
     * Competency Management Api's
     * =============================================================================
     */

    getFirstLevelStaffList(managerId) {
        return apiAxios.get(`/api/Competency/GetFirstLevelStaffList/${managerId}`);
    }

    getSecondLevelStaffList(managerId) 
    {
        return apiAxios.get(`/api/Competency/GetSecondLevelStaffList/${managerId}`);
    }

    getTrainingHistory(personId) {
        return apiAxios.get(`/api/Training/GetTrainingHistory/${personId}`);
    }

    getAccredGroupCyclesPlus() {
        return apiAxios.get(`/api/Competency/GetAccredGroupCyclesPlus`);
    }

    getAccredGroupCyclesPlusByManager(mgrId)
    {
        return apiAxios.get(`/api/Competency/GetAccredGroupCyclesPlusByManager/${mgrId}`);
    }

    GetPersonCompetencies3(inputParams)
    {
        return apiAxios.post(`/api/Competency/GetPersonCompetencies3`, inputParams);
    }

    GetViewPrintCertificateContainer(personId) {
        return apiAxios.get(`/api/Competency/GetViewPrintCertificateContainer/${personId}`);
    }

    GetPersonCompetenciesWithApproval(personId) {
        return apiAxios.get(`/api/Competency/GetPersonCompetenciesWithApproval/${personId}`);
    }

    GetCompCertificateHistory(personId) {
        return apiAxios.get(`/api/Competency/GetCompCertificateHistory/${personId}`);
    }

    GetCertificatePdf(certId) {
        return apiAxios.get(`/api/Competency/GetCertificatePdf/${certId}`);
    }

    ApprovePendingCompetencyCertificates(inputParams) {
        return apiAxios.post(`/api/Competency/SaveCompetencyApprovalDetails`, inputParams);
    }

    GenerateAndPrintCompetencyCertificate(inputParams) {
        return apiAxios.post(`/api/Competency/GenerateAndPrintCompetencyCertificate`, inputParams);
    }

    GetPerson(personId) {
        return apiAxios.get(`/api/Competency/GetPerson/${personId}`);
    }

    /**
     * =============================================================================
     * Upcoming events Api's
     * =============================================================================
     */

    getStaffListByManager(managerId) {
        return apiAxios.get(`/api/Training/GetStaffListByManager/${managerId}`);
    }

    getUpcomingEvents(inputParams) {
        return apiAxios.post(`/api/Training/GetUpcomingEvents`, inputParams);
    }

    getUpcomingEventsWithQueryParams(personId, startDate, endDate, searchEventsType) {
        return apiAxios.get(`/api/Training/GetUpcomingEvents/${personId}/${startDate}/${endDate}/${searchEventsType}`);
    }

    /**
     * =============================================================================
     * Catalogue Management Api's
     * =============================================================================
     */

    GetCategoryList() {
        return apiAxios.get(`/api/Catalogue/GetCategoryList`);
    }

    GetCatalogueList(userId, groupId) {
        return apiAxios.get(`/api/Catalogue/GetCatalogueList/${userId}/${groupId}`);
    }

    GetCatalogueGroupName(catalogueGroupID) {
        return apiAxios.get(`/api/Catalogue/GetCatalogueGroupName/${catalogueGroupID}`);
    }

    GetCatalgoueGroupText(catalogueGroupID) {
        return apiAxios.get(`/api/Catalogue/GetCatalgoueGroupText/${catalogueGroupID}`);
    }

    GetCourseList(categoryID, browseByCategory) {
        return apiAxios.get(`/api/Catalogue/GetCourseList/${categoryID}/${browseByCategory}`);
    }

    GetCourseInfo(courseId) {
        return apiAxios.get(`/api/Catalogue/GetCourseInfo/${courseId}`);
    }

    GetCourseTowns(courseId) {
        return apiAxios.get(`/api/Catalogue/GetCourseTowns/${courseId}`);
    }

    GetBusinessJustifications() {
        return apiAxios.get(`/api/Catalogue/GetBusinessJustifications`);
    }

    GetCourseAssignmentContainer(deliveryMethodID, managerId, courseId) {
        return apiAxios.get(`/api/Catalogue/GetCourseAssignmentContainer/${deliveryMethodID}/${managerId}/${courseId}`);
    }

    SaveBusinessJustificationText(inputParams) {
        return apiAxios.post(`/api/Catalogue/SaveBusinessJustificationText`, inputParams);
    }

    CheckIfCourseCompleted(inputParams) {
        return apiAxios.post(`/api/Catalogue/CheckIfCourseCompleted`, inputParams);
    }

    SaveCourseRequest(inputParams) {
        return apiAxios.post(`/api/Catalogue/SaveCourseRequest`, inputParams);
    }

    /**
     * =============================================================================
     * Team Profiles Management Api's
     * =============================================================================
     */

    GetDocumentsForPerson(personId) {
        return apiAxios.get(`/api/Catalogue/GetDocumentsForPerson/${personId}`);
    }

    GetDocumentsWithoutBlobForPerson(personId) {
        return apiAxios.get(`/api/Catalogue/GetDocumentsWithoutBlobForPerson/${personId}`);
    }

    GetDocumentInfo(documentId) {
        return apiAxios.get(`/api/Catalogue/GetDocumentInfo/${documentId}`);
    }

    GetPersonFullInfo(personId) {
        let token = getValidToken();
        return apiAxios.get(`/api/People/GetPersonFullInfo/${personId}`, { cancelToken: axios.CancelToken.source() });
    }

    GetUserInfo(personId) {
        return apiAxios.get(`/api/Catalogue/GetUserInfo/${personId}`);
    }


    GetEventData(ukpnPersonId, courseCode, status = "")
    {
        return apiAxios.get(`/api/UKPN/GetEventData?ukpnPersonId=${ukpnPersonId}&courseCode=${courseCode}&status=${status}`);
    }

    SaveUserInfo(formData)
    {
        return apiAxios.post(`/api/Catalogue/SaveUserInfo`, formData
            // {
            //     headers: {
            //         "Content-Type": "multipart/form-data"
            //     },        
            // }
        );
    }

    GetFilteredEventData(inputParams, searchEventsType)
    {
        let apiName = searchEventsType == 1 ? '/api/UKPN/GetFilteredUpcomingEventsByDateRange' : '/api/UKPN/GetFilteredEventData';
        const queryString = Object.keys(inputParams)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(inputParams[key])}`)
        .join('&');        
        //alert(queryString);
        return apiAxios.get(`${apiName}?${queryString}`);
        // return apiAxios.get(`/api/UKPN/GetFilteredEventData?ukpnPersonId=${ukpnPersonId}&courseCode=${courseCode}&status=${status}`);
    }

    GetUpcomingEventDates(portalManagerId, startDate)
    {
        return apiAxios.get(`/api/UKPN/GetUpcomingEventDates?portalManagerId=${portalManagerId}&startDate=${startDate}`);
    }

    GetUpcomingEventCount(portalManagerId, startDate)
    {
        return apiAxios.get(`/api/UKPN/GetUpcomingEventCount?portalManagerId=${portalManagerId}&startDate=${startDate}`); 
    }

    GetUpcomingEventsByDate(managerId, eventDate)
    {
        console.log(`/api/UKPN/GetUpcomingEventsByDate?managerId=${managerId}&eventDate=${eventDate}`);
        return apiAxios.get(`/api/UKPN/GetUpcomingEventsByDate?managerId=${managerId}&eventDate=${eventDate}`);
    }

    GetMandatoryOrOverdueLearningItems(inputParams)
    {
        const queryString = Object.keys(inputParams)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(inputParams[key])}`)
        .join('&');        
        console.log('queryString:' + queryString);
        return apiAxios.get(`/api/UKPN/GetMandatoryOrOverdueLearningItems?${queryString}`);
    }


    GetPersonInfoTeamProfiles(personId){
        return apiAxios.get(`/api/People/GetPersonInfoTeamProfiles/${personId}`)
    }

    UpdatePersonInfoTeamProfiles(personId, formData){
        return apiAxios.post(`/api/People/UpdatePersonInfoTeamProfiles/${personId}`, formData)
    }

    GetOutstandingActionsDidNotShow(ukpnPersonId){
        return apiAxios.get(`/api/UKPN/GetOutstandingActionsDidNotShow/${ukpnPersonId}`)
    }

    GetCompetencesThatRequireApproval(ukpnPersonId){
        return apiAxios.get(`/api/UKPN/GetCompetencesThatRequireApproval/${ukpnPersonId}`)
    }

    SaveCompetencyApprovalDetailsMultiple(payload){
        return apiAxios.post(`/api/Competency/SaveCompetencyApprovalDetailsMultiple`,payload)
    }

    GetOutstandingActionsCount(ukpnPersonId){
        return apiAxios.get(`/api/UKPN/GetOutActionsCount/${ukpnPersonId}`)
    }

    GetNonAttendanceReasons(){
        return apiAxios.get(`/api/UKPN/GetNonAttendanceReasons`)
    }

    GetStaffTrainingHistoryByCourse(courseTitle){
        return apiAxios.get(`/api/UKPN/GetStaffTrainingHistoryByCourse/?courseTitle=${encodeURIComponent(courseTitle)}`)
    }

    hrefToAdminServer(personBasic, url) {
        if (personBasic && personBasic.personIdentifier) {
            const cfg = this.getLocationConfig();
            return `${cfg.legacy.through}/#/signonFromNewLms?lmsid=${personBasic.personIdentifier}&returnUrl=https:%2F%2Flms.wdr.co.uk%2FadminDashboard&goToUrl=${url}`;
        } else {
            return null;
        }
    };
    UpdateNoShowReasons(data){
        return apiAxios.post(`/api/UKPN/UpdateNoShowReasons`,data)
    }

    GetTeamDashboardSummary(data){
        return apiAxios.get(`/api/TrainingPlan/GetTeamDashboardSummary`)
    }

    GetTeamDashboardSummaryV2(managerId){
        return apiAxios.get(`/api/UKPN/GetTeamDashboardSummaryV2/${managerId}`)
    }

    GetTeamDashboardSummaryV2New(managerId){
        return apiAxios.get(`/api/UKPN/GetTeamDashboardSummaryV2New/${managerId}`)
    }


    CheckIfGroupExistsByName(name){
        return apiAxios.get(`/api/group/CheckIfGroupExistsByName?groupName=${name}`)
    }

    GetDashboardQualifications(personId){
        return apiAxios.get(`/api/UKPN/GetDashboardQualifications/${personId}`)
    }

    GetTeamDashboardQualifications(managerId){
        return apiAxios.get(`/api/UKPN/GetTeamDashboardQualifications?managerId=${managerId}`)
        // return apiAxios.get(`/api/UKPN/GetTeamDashboardQualifications/${managerId}`)
    }

    GetStaffApprovalManagerActions(managerId)
    {
        return apiAxios.get(`/api/UKPN/GetStaffApprovalManagerActions/${managerId}`)
    }

    SaveStaffApprovalManagerActions(formData){
        return apiAxios.post(`/api/UKPN/SaveStaffApprovalManagerActions`, formData);
    }

}

export const apiAxiosCalls = new ApiAxiosCalls()
