import React from 'react';
import GenericSubTabContainer from "../../common/GenericSubTabContainer";
import {Col, Form, InputGroup, Row} from "react-bootstrap";

const checkboxes = [{
    name: "AutoPlay", label: "Auto Play"
}, {
    name: "ShowPlayButton", label: "Show Play Button"
}, {
    name: "ShowPlayBackButton", label: "Show Playback Button"
}, {
    name: "ShowScrubBar", label: "Show Scrub Bar"
}, {
    name: "ShowTime", label: "Show Time"
}, {
    name: "ShowTimeRemaining", label: "Show Time Remaining"
}, {
    name: "ShowVolumeControl", label: "Show Volume Control"
}
]

function AudioSettingsSubTab({form: {handleSubmit, handleChange, values, touched, errors, handleBlur}}) {

    const renderCheckBoxes = (checkboxes) => {
        return checkboxes.map(checkbox => (
            <Col key={checkbox.name}>
                <Form.Group className="mb-3" controlId={`formCheckbox${checkbox.name}`}>
                    <Form.Check
                        type="checkbox"
                        name={checkbox.name}
                        checked={values[checkbox.name]} // Controlled by Formik's values
                        label={checkbox.label}
                        onChange={handleChange} // Formik handles the change
                        onBlur={handleBlur} // For handling touch state
                    />
                </Form.Group>
            </Col>
        ));
    };

    return (
        <GenericSubTabContainer title={'Audio Settings'}>
            <Row className={"w-100"}>
                <Col md={6}>
                    <Form.Group className="mb-3" controlId="code1">
                        <Form.Label>Audio File Url</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                placeholder="Audio File Url"
                                type="text"
                                name="Url"
                                value={values.Url}
                                onChange={handleChange}
                                isValid={touched.Url && !errors.Url}
                                isInvalid={touched.Url && errors.Url}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.Url}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col className={"d-flex"} md={6}>

                    <div className="col-sm-4 mt-auto col-sm-offset-4">
                        <audio controls>
                            <source src={values.Url}/>
                        </audio>
                    </div>
                </Col>
            </Row>
            <Row className={"w-100 mt-2"} >
                <Col  md={6}>
                    {renderCheckBoxes(checkboxes.slice(0,4))}
                </Col>
                <Col  md={6}>
                    {renderCheckBoxes(checkboxes.slice(4,7))}
                </Col>

            </Row>
        </GenericSubTabContainer>

    );
}

export default AudioSettingsSubTab;
