import axios from "axios";
import MailTo from "../../components/MailTo";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import {
  CourseType_Control,
  CourseType_CustomerServices,
  CourseType_Dispatch,
  CourseType_LandD,
  CourseType_Other,
  CourseType_Technical,
} from "./learningPlanDataHelpers";

const defaultMessage = 'In order to cancel your place on this course, please click on the button below to email the team responsible for this event.';

const ShowCancelConfirm = ({ handleCloseModal, handleCancelCourse, show, subject }) => {
  return (
    <>
      <Modal show={show} onHide={handleCancelCourse}>
        <Modal.Header closeButton>
          <Modal.Title>{subject}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you wish to cancel this training?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCancelCourse}>Yes</Button>
          <Button variant="primary" onClick={handleCloseModal}>No</Button>
        </Modal.Footer>
      </Modal>
    </>)
};

const ShowCancelInfo = ({ show, subject, approvalLevel, courseType, isDelegateBooked, handleCloseModal }) => {
  return (
    courseType &&
    <>
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{subject}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{isDelegateBooked ?
            approvalLevel > 2 ?
              'In order to cancel your place on this course, please speak to your line manager who will need to contact their local training co-ordinator.'
              :
              defaultMessage
            :
            approvalLevel > 2 ?
              'In order to remove this item from your learning plan, please speak to your line manager who will need to contact their local training co-ordinator.'
              :
              approvalLevel == 2 ?
                'In order to remove this item from your learning plan, please click on the button below to email the team responsible.'
                :
                defaultMessage
          }</p>
          {approvalLevel > 2 ?
            null
            :
            courseType === 0 ?
              <ListGroup.Item><MailTo email={`Learning&development@ukpowernetworks.co.uk`}>Other Training</MailTo></ListGroup.Item>
              :
              <ListGroup>
                {courseType === CourseType_Control ? (<ListGroup.Item><MailTo email={`andy.hall@ukpowernetworks.co.uk?subject=${subject}`}>Control Training</MailTo></ListGroup.Item>) : null}
                {courseType === CourseType_CustomerServices ? (<ListGroup.Item><MailTo email={`SDCTrainers@ukpowernetworks.co.uk`}>Customer Service Training</MailTo></ListGroup.Item>) : null}
                {courseType === CourseType_Dispatch ? (<ListGroup.Item><MailTo email={`DispatchTrainingTeam@ukpowernetworks.co.uk`}>Dispatch Training</MailTo></ListGroup.Item>) : null}
                {courseType === CourseType_LandD ? (<ListGroup.Item><MailTo email={`Learning&development@ukpowernetworks.co.uk`}>Learning & Development Training</MailTo></ListGroup.Item>) : null}
                {courseType === CourseType_Technical ? (<ListGroup.Item><MailTo email={`TechnicalTraining@ukpowernetworks.co.uk`}>Technical Training</MailTo></ListGroup.Item>) : null}
                {courseType === CourseType_Other ? (<ListGroup.Item><MailTo email={`Learning&development@ukpowernetworks.co.uk`}>Other Training</MailTo></ListGroup.Item>) : null}
              </ListGroup>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
};

const LearningPlanCancelationModal = ({ show, data, handleCloseModal, handleCancelCourse }) => {
  const [courseType, setCourseType] = useState(null);
  const { subject, hasApproval, approvalLevel, isDelegateBooked, itemId } = data || {};

  useEffect(() => {
    axios
      .get(`/api/LearningItem/GetLearningItemCourseType/${itemId}`)
      .then((data) => { setCourseType(data.data); console.log(data.data); });
  }, [itemId]);

  return (
    (!hasApproval ?
      <ShowCancelConfirm
        handleCancelCourse={handleCancelCourse}
        handleCloseModal={handleCloseModal}
        show={show}
        subject={subject} /> :
      <ShowCancelInfo
        courseType={courseType}
        approvalLevel={approvalLevel}
        isDelegateBooked={isDelegateBooked}
        handleCloseModal={handleCloseModal}
        show={show}
        subject={subject} />))
};

export default LearningPlanCancelationModal;
