import { Col, Row } from 'react-bootstrap';
import { FilterClearButton, FilterBadgeButton } from '../../components/CustomButtons';

const QualificationsPlanFilterTabs = ({ filterId, setFilterId }) => {

    return (
        <Row className='gy-2'>
            <Col key='-1' xs='auto'>
                <FilterBadgeButton variant={'info'} title='Expired' onClick={() => setFilterId(filterId === -1 ? 0 : -1)} active={filterId === -1} />
            </Col>
            <Col key='-2' xs='auto'>
                <FilterBadgeButton variant={'info'} title='Expiring' onClick={() => setFilterId(filterId === -2 ? 0 : -2)} active={filterId === -2} />
            </Col>
            <Col key='-3' xs='auto'>
                <FilterBadgeButton variant={'info'} title='Under Personal Supervision' onClick={() => setFilterId(filterId === -3 ? 0 : -3)} active={filterId === -3} />
            </Col>
            <Col key='-7' xs='auto'>
                {filterId < 0 && <FilterClearButton onClick={() => setFilterId(0)} />}
            </Col>
        </Row>);
};

export default QualificationsPlanFilterTabs;