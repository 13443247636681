import useLocationConfig from '../hooks/useLocationConfig';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";


export default function LoginUKPN() {
    const navigate = useNavigate()
    const { themeName } = useLocationConfig();

    const goToContractorLogin = () => {
        navigate("/contractor-login")
    }

    const goToEmployeeLogin = () => {
        navigate("/entraauth")
    }

    return (
        <>
            <Row className="m-0 ">
                <Col className="cls-login-col-left p-0" md={6} lg={5} xl={4} xxl={3}>
                    <div className="cls-login-colour-ovl d-flex align-items-center justify-content-center">
                        <Row style={{ width: '100%' }}>
                            <Col sm={2} md={1}></Col>
                            <Col>
                                <Card className="mb-5" style={{ width: '100%' }}>
                                    <div className="d-flex justify-content-end" style={{ margin: ' 1rem 1.5rem 0 0' }}>
                                        {themeName ? <Card.Img variant="top" src={require(`../assets/instance/${themeName}/logo.png`)} style={{ width: '140px' }} /> : <></>}
                                    </div>
                                    <Card.Body>
                                        <Card.Title>Please select an option</Card.Title>
                                        <Button onClick={() => { goToEmployeeLogin() }} type="button" style={{ backgroundColor: 'var(--app-login-ovl)' }} variant="primary" className="w-100 mt-2 mb-2">Employee Login</Button>
                                        <Button onClick={() => { goToContractorLogin() }} type="button" style={{ backgroundColor: 'var(--app-login-ovl)' }} variant="primary" className="w-100 mt-2 mb-2">Contractor Login</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={2} md={1}></Col>
                        </Row>
                    </div>
                </Col>
                <Col className="cls-login-col-right"><div className="cls-login-colour-ovl"></div></Col>
            </Row>
        </>
    );
}
