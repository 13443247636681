import { useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { Button, Col, Form, OverlayTrigger, Row, Stack, Tooltip } from 'react-bootstrap';

import { ModalConfirmCancel } from './ModalConfirmation';

import { columnBlankIfWidth, columnIconLearningItem, columnIfWidth, muiTableBodyCellEditFieldPropsDate, muiTableBodyCellEditFieldPropsCheckboxFn } from '../helpers/tableHelper';

import { isSupportedDate, toDDsMMsYYYYString, toDDsMMsYYString, toISODateString, toISODateTimeString, toLocaleDateString } from '../helpers/formatHelper';

import { FilterBadgeButton } from './CustomButtons';

import useLmsStore, { postAdminTrainingPlan } from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

import MaterialReactTable from 'material-react-table';
import LearningPlanEditorUserTableSelect from './LearningPlanEditorUserTableSelect';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faSquareCheck, faSquareMinus, faChevronsLeft, faChevronsRight } from '@fortawesome/pro-light-svg-icons';

const empty = (tableData) => !tableData || tableData.length === 0;

const filteredData = (rows, id, isUKPN) => !Array.isArray(rows) ? [] : id < 0 ? rows.filter(x => (id === -1 && !isCompleted(x) && !isAttended(x)) || (id === -2 && isOverdue(x, isUKPN)) || (id === -3 && x.isMandatory) || (id === -4 && (isCompleted(x) || isAttended(x)))) : rows;

const isCompleted = x => x && x.statusName === 'Completed';
const isAttended = x => x && x.statusName === 'Attended';

const isOverdue = x => x && !isCompleted(x) && (isSupportedDate(x.expiryDate) && new Date(x.expiryDate) < new Date());

export default function LearningPlanEditorUserTable(props) {
    const [completedCount, setCompletedCount] = useState(null);
    const [dateToApply, setDateToApply] = useState('');
    const [edit, setEdit] = useState(false);
    const [editMode, setEditMode] = useState('1');
    const [filterId, setFilterId] = useState(0);
    const [headerClickState, handleHeaderClick] = useReducer(function (state, action) { return { count: state.count + 1, flag: action }; }, { count: 0, flag: 'none' }); // count to ensure a change of headerClickState on every click
    const [mandatoryCount, setMandatoryCount] = useState(null);
    const [overdueCount, setOverdueCount] = useState(null);
    const [showCancelEditModal, setShowCancelEditModal] = useState(false);
    const [showCancelModeModal, setShowCancelModeModal] = useState(0); // Store the edit mode id
    const [tableLoading, setTableLoading] = useState(true);
    const [todoCount, setToDoCount] = useState(null);
    const headerSquareCheckRef = useRef();
    const headerSquareMinusRef = useRef();
    const headerSquareRef = useRef();
    const [isUKPN, setIsUKPN] = useState(false);
    const { computedMuiTableContainerProps } = props.dimensions;

    const { personSettings, fetchAdminTrainingPlanItems } = useLmsStore(state =>
    ({
        personSettings: state.personSettings.data,
        fetchAdminTrainingPlanItems: state.fetchAdminTrainingPlanItems,
    }), shallow)

    useEffect(() => {
        setIsUKPN(personSettings?.theme?.toLowerCase() === 'ukpn')
    }, [personSettings]);

    useEffect(() => {
        initDataLeft(initDataPromise, []);
        // eslint-disable-next-line
    }, [props.dataSelected]);

    useEffect(() => {

        setEditMode(edit ? '3' : '1');
        props.onEditChanged(edit);
        // eslint-disable-next-line
    }, [edit]);

    useEffect(() => {
        initDataLeft(initDataPromise, editMode === '3' ? null : []).then(() => {
            if (editMode !== '0') {
                setFilterId(0);
            }
            if (editMode !== '2') {
                setDateToApply('');
            }
            if (!editModeLessThan2()) {
                refreshGlobalInputCheckbox();
            }
        });
        // eslint-disable-next-line 
    }, [editMode]);

    useEffect(() => refreshGlobalInputCheckbox(),
        // eslint-disable-next-line 
        [props.hasSelectedLeft]);

    useEffect(() => {
        props.selectLeft(headerClickState.flag);
        // eslint-disable-next-line 
    }, [headerClickState]);

    const editModeLessThan2 = () => parseInt(editMode) < 2;

    const initDataLeft = (initDataPromiseFn, initDataRightParameter) => {
        return new Promise(res => {
            setTableLoading(true);
            if (props.dataSelected && props.dataSelected.id > 0) {
                let completed = 0,
                    mandatory = 0,
                    overdue = 0,
                    todo = 0;
                initDataPromiseFn().then(data => {
                    data.forEach(d => {
                        if (isCompleted(d) || isAttended(d)) {
                            completed++;
                        } else {
                            todo++;
                            if (isOverdue(d, isUKPN)) overdue++;
                        }
                        if (d.isMandatory) mandatory++;
                    });
                    props.customTracker('clear');
                    props.initData(structuredClone(data || []), initDataRightParameter);
                    setToDoCount(todo);
                    setOverdueCount(overdue);
                    setMandatoryCount(mandatory);
                    setCompletedCount(completed);
                    setTableLoading(false);
                    res(data);
                });
            } else {
                props.customTracker('clear');
                props.initData([], []);
                setToDoCount(0);
                setOverdueCount(0);
                setMandatoryCount(0);
                setCompletedCount(0);
                setTableLoading(false);
                res([]);
            }
        });
    };

    const initDataPromise = () => {
        //fetchAdminTrainingPlanItems(props.dataSelected.id);
        return fetchAdminTrainingPlanItems(props.dataSelected.id);
    }

    const handleApplyDateToSelected = () => {
        if (props.hasSelectedLeft !== 'none' && isSupportedDate(dateToApply)) {
            let date = toISODateTimeString(dateToApply),
                dateStr = toDDsMMsYYYYString(dateToApply);
            props.updateBaseLeft(props.dataLeft.filter(x => x.isRowSelected === true).map(x => {
                return {
                    ...x,
                    expiryDate: date,
                    expiryDateStr: dateStr,
                    isRowSelected: false
                };
            }));
        }
    }

    const handleCellClick = (cell, event) => {
        if (cell.column.id === 'isRowSelected' || !editModeLessThan2()) {
            props.toggleLeft(cell.row.original.id);
        }
    };

    const handleCancelEdit = () => {
        if (props.hasUpdates) {
            setShowCancelEditModal(true);
        } else {
            resetToRO();
        }
    }

    const handleCellChanged = (cell, event) => {
        // We need to track date and hide changes separately as the server update processes are different
        if (cell.column.id === 'hide') {
            props.customTracker('add', cell.row.original.id, 'hideChanged');
            props.updateBaseLeft(props.dataLeft.filter(x => x.id === cell.row.original.id).map(x => { return { ...x, hide: event.target.checked === true } }));
        }
        else if (cell.column.id === 'expiryDate') {
            props.customTracker('add', cell.row.original.id, 'dateChanged');
            props.updateBaseLeft(props.dataLeft.filter(x => x.id === cell.row.original.id).map(x => {
                return {
                    ...x,
                    expiryDate: toISODateTimeString(event.target.value),
                    expiryDateStr: toDDsMMsYYYYString(event.target.value)
                }
            }));
        }
    };

    const handleConfirmCancelEdit = () => {
        setShowCancelEditModal(false);
        resetToRO();
    }

    const handleConfirmMode = () => {
        if (showCancelModeModal > 0) {
            setEditMode(showCancelModeModal);
            setShowCancelModeModal(0);
        }
    }

    const handleConfirmModeCancel = () => {
        setShowCancelModeModal(0);
    }

    const handleEdit = () => {
        setFilterId(0);
        setEdit(true);
    }

    const handleOnSave = () => {
        initDataLeft(() => postAdminTrainingPlan(
            useLmsStore,
            {
                userData: props.dataSelected,
                dateUpdates: editMode === '1' ? props.customTracker('dataLeft', null, 'dateChanged') : (editMode === '2' ? props.getUpdatedLeft().updated : null),
                otherUpdates: editMode === '1' ? (props.customTracker('dataLeft', null, 'hideChanged').length > 0 ? props.dataLeft : null) : (editMode === '2' ? null : props.dataLeft)
            }
        ), true);
    }

    const handleEditModeChange = (event) => {
        if (props.hasUpdates) {
            setShowCancelModeModal(event.target.value);
        } else {
            setEditMode(event.target.value);
        }
    }

    const resetToRO = () => {
        initDataLeft(initDataPromise, []).then(() => {
            setEdit(false);
            setEditMode('1');
        });
    }

    const refreshGlobalInputCheckbox = () => {
        if (headerSquareRef.current && headerSquareCheckRef.current && headerSquareMinusRef.current) {
            headerSquareRef.current.style.display = props.hasSelectedLeft === 'none' ? 'inline-block' : 'none';
            headerSquareMinusRef.current.style.display = props.hasSelectedLeft === 'some' ? 'inline-block' : 'none';
            headerSquareCheckRef.current.style.display = props.hasSelectedLeft === 'all' ? 'inline-block' : 'none';
        }
    }

    const columnVisibility = () => {
        let editModeLT2 = editModeLessThan2();
        return {
            code: !edit,
            isRowSelected: !editModeLT2,
            expiryDate: editMode !== '3',
            supplierName: editModeLT2,
            statusName: editModeLT2,
            isMandatory: editModeLT2,
            hide: editModeLT2
        }
    }

    const columns = useMemo(() => [
        columnIconLearningItem('Learner', 'xl'),
        {
            accessorFn: (row) => row.isRowSelected,
            id: 'isRowSelected',
            header: 'Select',
            Header: ({ column }) => (
                <>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Select All</Tooltip>}>
                        <FontAwesomeIcon icon={faSquare} style={{ cursor: 'pointer', display: 'none', fontSize: '1rem' }} ref={headerSquareRef} onClick={() => handleHeaderClick('all')} />
                    </OverlayTrigger>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Select All</Tooltip>}>
                        <FontAwesomeIcon icon={faSquareMinus} style={{ cursor: 'pointer', display: 'none', fontSize: '1rem' }} ref={headerSquareMinusRef} onClick={() => handleHeaderClick('all')} />
                    </OverlayTrigger>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Deselect All</Tooltip>}>
                        <FontAwesomeIcon icon={faSquareCheck} style={{ cursor: 'pointer', display: 'none', fontSize: '1rem' }} ref={headerSquareCheckRef} onClick={() => handleHeaderClick('none')} />
                    </OverlayTrigger>
                </>
            ),
            muiTableBodyCellProps: {
                sx: {
                    cursor: 'pointer'
                }
            },
            size: 50,
            Cell: ({ cell, row }) => <FontAwesomeIcon icon={cell.getValue() === true ? faSquareCheck : faSquare} style={{ fontSize: '1rem' }} />
        },
        {
            accessorFn: (row) => row.code,
            id: 'code2',
            enableEditing: false,
            header: 'Code',
            size: 75,
            muiTableBodyCellProps: ({ cell, table }) => {
                return {
                    sx: {
                        cursor: 'pointer',
                        fontWeight: cell.row.original.isRowSelected ? 'bold' : 'normal'
                    }
                }
            }
        },
        {
            accessorKey: 'title',
            enableEditing: false,
            header: 'Title',
            muiTableBodyCellProps: ({ cell, table }) => {
                let isRowSelectedIsVisible = table.getColumn('isRowSelected').getIsVisible();
                return {
                    sx: {
                        cursor: isRowSelectedIsVisible ? 'pointer' : 'inherit',
                        fontWeight: cell.row.original.isRowSelected ? 'bold' : 'normal'
                    }
                }
            },
            size: 200
        },
        {
            accessorFn: (row) => toISODateString(row.expiryDate),
            id: 'expiryDate',
            header: 'Due/Expiry',
            size: 100,
            muiTableBodyCellEditTextFieldProps: { ...muiTableBodyCellEditFieldPropsDate, required: false },
            Cell: ({ cell, row }) => toDDsMMsYYString(row.original.expiryDate) //toLocaleDateString(row.original.expiryDate)
        },
        columnIfWidth('xl', {
            accessorKey: 'supplierName',
            enableEditing: false,
            size: 150,
            header: 'Supplier'
        }),
        columnIfWidth('lg', {
            accessorKey: 'statusName',
            enableEditing: false,
            header: 'Status',
            size: 100
        }),
        columnIfWidth('xl', {
            accessorKey: 'isMandatory',
            enableEditing: false,
            header: 'M/R',
            size: 50,
            Cell: ({ cell, row }) => (row.original.isMandatory ? 'M' : '') + (row.original.isRenewable ? (row.original.isMandatory ? '/R' : 'R') : '')
        }),
        {
            accessorFn: (row) => row.hide === true,
            id: 'hide',
            header: 'Hide',
            size: 50,
            muiTableBodyCellEditTextFieldProps: muiTableBodyCellEditFieldPropsCheckboxFn({ required: false }),
            Cell: ({ cell, row }) => <FontAwesomeIcon icon={cell.getValue() === true ? faSquareCheck : faSquare} style={{ fontSize: '1rem' }} />
        },
        columnBlankIfWidth('xl', 'blankcolumn')
    ], [],);

    return (<Row>
        <Col>
            <MaterialReactTable
                columns={columns}
                data={filteredData(props.dataLeft, filterId)}
                editingMode='table'
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableEditing={edit && editMode === '1'}
                enableFullScreenToggle={false}
                enableGrouping={false}
                enableHiding={false}
                enablePagination={false}
                enableRowVirtualization
                enableSorting={false}
                enableTopToolbar={true}
                muiTableBodyCellEditTextFieldProps={({ cell }) => ({
                    onChange: (event) => {
                        handleCellChanged(cell, event);
                    },
                })}
                muiTableBodyCellProps={({ cell }) => ({
                    onClick: (event) => {
                        handleCellClick(cell, event);
                    },
                })}
                muiTableBodyRowProps={{ hover: false }}
                muiTableContainerProps={({ table }) => computedMuiTableContainerProps(table)}
                renderTopToolbarCustomActions={({ table }) => <Row>
                    {edit ? null :
                        <>
                            <Col xs='auto'>
                                <FilterBadgeButton variant={todoCount > 0 ? 'warning' : 'info'} title='To Do' onClick={() => setFilterId(filterId === -1 ? 0 : -1)} active={filterId === -1} disabled={empty(props.dataLeft)}>
                                    {todoCount}
                                </FilterBadgeButton>
                            </Col>
                            <Col xs='auto'>
                                <FilterBadgeButton variant={overdueCount > 0 ? 'danger' : 'info'} title='Overdue' onClick={() => setFilterId(filterId === -2 ? 0 : -2)} active={filterId === -2} disabled={empty(props.dataLeft)}>
                                    {overdueCount}
                                </FilterBadgeButton>
                            </Col>
                            <Col xs='auto'>
                                <FilterBadgeButton variant={mandatoryCount > 0 ? 'warning' : 'info'} title='Mandatory' onClick={() => setFilterId(filterId === -3 ? 0 : -3)} active={filterId === -3} disabled={empty(props.dataLeft)}>
                                    {mandatoryCount}
                                </FilterBadgeButton>
                            </Col>
                            <Col xs='auto'>
                                <FilterBadgeButton variant={completedCount > 0 ? 'success' : 'info'} title='Completed' onClick={() => setFilterId(filterId === -4 ? 0 : -4)} active={filterId === -4} disabled={empty(props.dataLeft)}>
                                    {completedCount}
                                </FilterBadgeButton>
                            </Col>
                            <Col xs='auto'>
                                <Button variant="outline-primary" onClick={handleEdit} disabled={!props.dataSelected}>
                                    Edit
                                </Button>
                            </Col>
                        </>}
                    {edit ? <>
                        <Col xs='auto' style={{ padding: '0.5rem 0 0 0.75rem' }}>
                            Edit Mode:
                        </Col>
                        <Col xs='auto' style={{ padding: '0 0.75rem 0 0.25rem' }}>
                            <Form.Select
                                disabled={empty(props.dataLeft)}
                                value={editMode}
                                onChange={handleEditModeChange}>
                                <option value="1">By Rows</option>
                                <option value="2">Dates</option>
                                <option value="3">Add / Remove</option>
                            </Form.Select>
                        </Col>
                        {editMode === '2' ?
                            <><Col xs='auto'>
                                <Form.Control type="date" onChange={(e) => setDateToApply(e.target.value || '')} />
                            </Col>
                                <Col xs='auto'>
                                    <Button type="submit" variant="outline-primary" onClick={handleApplyDateToSelected} disabled={props.hasSelectedLeft === 'none' || !isSupportedDate(dateToApply)}>
                                        Apply date to selected
                                    </Button>
                                </Col></> : null}
                        <Col xs='auto'>
                            <Button variant="outline-secondary" onClick={handleCancelEdit}>
                                Cancel
                            </Button>
                        </Col>
                        <Col xs='auto'>
                            <Button type="submit" variant="primary" onClick={handleOnSave} disabled={!props.hasUpdates}>
                                {tableLoading ?
                                    <>
                                        <span className="spinner-border spinner-border-sm mt-1" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                                        Please wait...
                                    </>
                                    :
                                    <>
                                        Save
                                    </>
                                }
                            </Button>
                        </Col>
                    </> : null}
                </Row>}
                initialState={{
                    showGlobalFilter: true
                }}
                state={{
                    columnVisibility: columnVisibility(),
                    isLoading: tableLoading
                }}
            />
        </Col>
        {editMode === '3' ?
            <>
                <Col sm='auto' style={{ marginTop: '8rem' }}>
                    <Stack gap={3}>
                        {/* The reason for the more complex if code here (rather than using the disabled value attribute) is because tooltips tend to stay
                             on when the button is disabled before the tooltip has had a chance to hide again (events do not fire on disabled elements) */}
                        {props.hasSelectedRight === 'none' ?
                            <Button variant="outline-secondary" onClick={props.moveSelectedLeft} disabled>
                                <FontAwesomeIcon icon={faChevronsLeft} />
                            </Button>
                            :
                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 0 }} overlay={<Tooltip className='cls-theme-tooltip'>Move Left</Tooltip>}>
                                <Button variant="outline-secondary" onClick={props.moveSelectedLeft}>
                                    <FontAwesomeIcon icon={faChevronsLeft} />
                                </Button>
                            </OverlayTrigger>
                        }
                        {props.hasSelectedLeft === 'none' ?
                            <Button variant="outline-secondary" onClick={props.moveSelectedRight} disabled>
                                <FontAwesomeIcon icon={faChevronsRight} />
                            </Button>
                            :
                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 0 }} overlay={<Tooltip className='cls-theme-tooltip'>Move Right</Tooltip>}>
                                <Button variant="outline-secondary" onClick={props.moveSelectedRight}>
                                    <FontAwesomeIcon icon={faChevronsRight} />
                                </Button>
                            </OverlayTrigger>
                        }
                    </Stack>
                </Col>
                <Col>
                    <LearningPlanEditorUserTableSelect {...props} />
                </Col>
            </> : null}
        <ModalConfirmCancel
            showCancelEditModal={showCancelEditModal}
            abortFn={() => setShowCancelEditModal(false)}
            continueFn={handleConfirmCancelEdit}
        />
        <ModalConfirmCancel
            showCancelEditModal={showCancelModeModal > 0}
            abortFn={handleConfirmModeCancel}
            continueFn={handleConfirmMode}
        />
    </Row>);
}
