import React, { useState, useMemo, useEffect } from "react";
import { Col, Row, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import BaseContainer from "../components/BaseContainer";
import { TableRowSplit } from "../helpers/tableHelper";
import useLmsStore, {
  postPublishPathwayAdmin,
  postUnPublishPathwayAdmin,
  getValidatePathwayRegs,
  updatePathwaysAdmin,
} from "../hooks/useLmsStore";
import shallow from "zustand/shallow";
import Modal from "react-bootstrap/Modal";
import MaterialReactTable from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/pro-solid-svg-icons";
import FormPathway from "../components/learningPathwaysAdminComponents/FormPathway";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import LearningPathwayEditorGroup from "../components/learningPathwaysAdminComponents/LearningPathwayEditorGroup";
import LearningPathwayEditorUser from "../components/learningPathwaysAdminComponents/LearningPathwayEditorUser";
import { ModalConfirmCancel } from "../components/ModalConfirmation";
import { add } from "lodash";

function PathwaysCore({ dimensions }) {
  const [pathways, setPathways] = useState(null);
  const [tableInactive, setTableInactive] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [isUnpublishModalOpen, setIsUnpublishModalOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [dataSelected, setDataSelected] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [pathway, setPathway] = useState([]);
  const [modalMessage, setModalMessage] = useState("");
  const [showModalButtons, setShowModalButtons] = useState(true);
  const [itemToAction, setItemToAction] = useState(null);
  const [editGroupDates, setEditGroupDates] = useState(false);
  const [dataGroupSelected, setDataGroupSelected] = useState({});
  const [editUserDates, setEditUserDates] = useState(false);
  const [dataUserSelected, setDataUserSelected] = useState({});
  const [displayAssignT, setDisplayAssignT] = useState(false);
  const [hasChangedPw, setHasChangedPw] = useState(false);
  const [hasChangeAssign, setHasChangedAssign] = useState(false);
  const [showCancelSelectModalP, setshowCancelSelectModalP] = useState(false);
  const [showCancelSelectModalP2, setshowCancelSelectModalP2] = useState(false);
  const { computedMuiTableContainerProps } = dimensions;

  const { allPathways, fetchAllPathwaysAdmin } = useLmsStore(
    (state) => ({
      allPathways: state.allPathways.data,
      fetchAllPathwaysAdmin: state.fetchAllPathwaysAdmin,
    }),
    shallow
  );

  const openPublishModal = () => {
    setIsUnpublishModalOpen(true);
  };

  const openUnPublishModal = () => {
    setIsPublishModalOpen(true);
  };

  useEffect(() => {
    if (dataSelected) {
      setPathway(dataSelected);
      setDisplayAssignT(false);
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [dataSelected]);

  useEffect(() => {
    fetchAllPathwaysAdmin();
  }, [fetchAllPathwaysAdmin]);

  useEffect(() => {
    if (allPathways) {
      const dataItems = allPathways.value;
      setPathways(dataItems);
    }
  }, [allPathways]);

  useEffect(() => {
    let keys = Object.keys(rowSelection);

    if (keys.length > 0) {
      if (!hasChangeAssign && !hasChangedPw) {
        selectPathway(rowSelection);
      } else {
        setshowCancelSelectModalP(true);
      }
    }
  }, [rowSelection]);

  const selectPathway = (rowSelection) => {
    let keys = Object.keys(rowSelection);

    if (keys.length === 1 && rowSelection[keys[0]] === true) {
      let id = parseInt(keys[0]);
      if (pathways) {
        setDataSelected(
          pathways.find((x) => x.learningProgrammeGroupId === id)
        );
      } else {
        setDataSelected(null);
      }
    } else {
      setDataSelected(null);
    }
  };

  const closePublishModal = () => {
    setIsPublishModalOpen(false);
  };

  const closeUnpublishModal = () => {
    setIsUnpublishModalOpen(false);
  };

  const addPathway = () => {
    let pathwayCopy = {
      learningProgrammeGroupId: -1 * pathways.length,
      name: "",
    };

    setRowSelection({});
    setDataSelected(pathwayCopy);

    setPathway([]);
  };

  const handleAddPathway = () => {
    if (!hasChangeAssign && !hasChangedPw) {
      addPathway();
    } else {
      setshowCancelSelectModalP2(true);
    }
  };

  const setItem = (itemId, isPublish) => {
    let pathwaysCopy = structuredClone(pathways);

    const item = pathwaysCopy.find(
      (x) => x.learningProgrammeGroupId === itemId
    );

    //Update list
    if (item) {
      item.isPublished = isPublish;
      setPathways(pathwaysCopy);
    }
  };
  const handlePublishClick = async () => {
    if (itemToAction) {
      await postPublishPathwayAdmin(useLmsStore, { id: itemToAction });
      setItem(itemToAction, true);
    }

    setIsPublishModalOpen(false);
  };

  const handleUnPublishClick = () => {
    if (itemToAction) {
      postUnPublishPathwayAdmin(useLmsStore, { id: itemToAction });
      setItem(itemToAction, false);
    }

    setIsUnpublishModalOpen(false);
  };

  const onInactiveChange = (event) => {
    setTableInactive(!tableInactive);
  };

  const showPublishModal = (id) => {
    setItemToAction(id);
    setIsPublishModalOpen(true);
  };

  const showUnpublishModal = (id) => {
    setItemToAction(id);

    getValidatePathwayRegs(id).then((valResult) => {
      if (valResult.hasActiveBookings) {
        setShowModalButtons(false);
        setModalMessage(
          "There are users assigned to this learning pathway that have active bookings on events.  These bookings must be cancelled via the events screens before unpublishing this pathway"
        );
      } else {
        setModalMessage(
          "Please note: Any users with learning items in progress will lose all progress. Do you wish to continue?"
        );
      }
    });
    setIsUnpublishModalOpen(true);
  };

  const onEditGroupDatePwFn = (group) => {
    setDataGroupSelected(group);
    setEditGroupDates(true);
  };

  const onEditUserDatePwFn = (user) => {
    setDataUserSelected(user);
    setEditUserDates(true);
  };

  const onEditUserDatePwCancelFn = (msg) => {
    if (msg === "cancel") {
      setDataUserSelected({});
      setEditUserDates(false);
      setDisplayAssignT(true);
    }
  };

  const onEditGroupDatePwCancelFn = (msg) => {
    if (msg === "cancel") {
      setDataGroupSelected({});
      setEditGroupDates(false);
      setDisplayAssignT(true);
    }
  };

  const onDataHasChangedFn = (pathwayInfo) => {
    if (pathwayInfo.isPwDetail) {
      setHasChangedPw(pathwayInfo.isDirty);
      setHasChangedAssign(false);
    } else {
      setHasChangedAssign(pathwayInfo.isDirty);
      setHasChangedPw(false);
    }
  };

  const handleConfirmSelectRowCancel = async (data) => {
    setshowCancelSelectModalP(false);
  };

  const handleConfirmSelectRow = async (data) => {
    selectPathway(rowSelection);
    setshowCancelSelectModalP(false);
  };

  const handleConfirmSelectRowCancel2 = async (data) => {
    setshowCancelSelectModalP2(false);
  };

  const handleConfirmSelectRow2 = async (data) => {
    addPathway();
    setshowCancelSelectModalP2(false);
  };

  const onSavePathwayFFn = (pathway) => {
    let item = pathways.find(
      (a) => a.learningProgrammeGroupId == pathway.learningProgrammeGroupId
    );
    let itemUp = {
      name: pathway.name,
      isPublished: pathway.isActive,
      learningProgrammeGroupId: pathway.learningProgrammeGroupId,
    };
    let isNew = false;

    if (!item) {
      isNew = true;
    }

    updatePathwaysAdmin(useLmsStore, itemUp, isNew);
  };

  const withSelectedRows = (tableData, inactive) =>
    (tableData ?? []).filter((e) => inactive || e.isActive);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Pathway",
        Cell: ({ cell, row }) => {
          return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{row.original.name || "None"}</div>
              {
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip className="cls-theme-tooltip">
                      {row.original.isPublished
                        ? "Unpublish: by unpublishing a learning pathway you are removing it from the users view​"
                        : "Publish"}
                    </Tooltip>
                  }
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                      icon={faUpload}
                      onClick={() => {
                        row.original.isPublished
                          ? showUnpublishModal(
                              row.original.learningProgrammeGroupId
                            )
                          : showPublishModal(
                              row.original.learningProgrammeGroupId
                            );
                      }}
                      style={{
                        color: row.original.isPublished ? "gray" : "#4ec3e0",
                        marginRight: "5px",
                        transform: `rotate(${
                          row.original.isPublished ? 180 : 0
                        }deg)`,
                      }}
                    />
                  </div>
                </OverlayTrigger>
              }
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <>
        {(editGroupDates && dataGroupSelected) ||
        (editUserDates && dataUserSelected) ? (
          <>
            {editGroupDates && dataGroupSelected ? (
              <>
                <LearningPathwayEditorGroup
                  pathway={pathway}
                  dataSelected={dataGroupSelected}
                  displayUserCard={true}
                  onEditGroupDateCancelFn={(obj) =>
                    onEditGroupDatePwCancelFn(obj)
                  }
                />
              </>
            ) : (
              <>
                {editUserDates && dataUserSelected ? (
                  <>
                    <LearningPathwayEditorUser
                      pathway={pathway}
                      dataSelected={dataUserSelected}
                      displayUserCard={true}
                      onEditUserDateCancelFn={(obj) =>
                        onEditUserDatePwCancelFn(obj)
                      }
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <TableRowSplit
              leftPane={
                <>
                  <div className="mt-2 mx-2 form-check form-switch">
                    <input
                      type="checkbox"
                      id="isPublished"
                      className="form-check-input"
                      onChange={onInactiveChange}
                    />
                    {/* onChange={onInactiveChange} */}
                    <label
                      title=""
                      htmlFor="isPublished"
                      className="form-check-label"
                    >
                      Include Inactive
                    </label>
                  </div>
                  <MaterialReactTable
                    columns={columns}
                    data={withSelectedRows(pathways, tableInactive) || []}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableGrouping={false}
                    enableHiding={false}
                    enablePagination={true}
                    enableBottomToolbar={true}
                    enableSorting={false}
                    enableTopToolbar={true}
                    enableFilterMatchHighlighting={true}
                    globalFilterFn="contains" //By default is fuzzy
                    muiTableHeadProps={{
                      sx: {
                        display: "none",
                      },
                    }}
                    muiTableContainerProps={({ table }) =>
                      computedMuiTableContainerProps(table)
                    }
                    getRowId={(originalRow) =>
                      originalRow.learningProgrammeGroupId
                    }
                    muiTableBodyRowProps={({ row }) => ({
                      onClick: () =>
                        setRowSelection((prev) => ({
                          [row.id]: !prev[row.id],
                        })),
                      hover: true,
                      selected: rowSelection[row.id],
                      sx: {
                        cursor: "pointer",
                      },
                    })}
                    renderToolbarInternalActions={({ table }) => (
                      <>
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip className="cls-theme-tooltip">
                              Add Pathway
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="clear"
                            className="pt-0 pb-0"
                            onClick={() => handleAddPathway()}
                          >
                            <FontAwesomeIcon
                              size="xl"
                              icon={faPlus}
                              className="text-primary"
                            />
                          </Button>
                        </OverlayTrigger>
                      </>
                    )}
                    initialState={{
                      pagination: { pageIndex: 0, pageSize: 15 },
                      showGlobalFilter: true, //This displays the seach generic way for the materia react table
                    }}
                    state={{
                      isLoading: !allPathways,
                    }}
                  />
                </>
              }
              rightPane={
                <FormPathway
                  displayAssignTab={displayAssignT}
                  pathwayP={pathway}
                  editGroupDateFFn={(obj) => onEditGroupDatePwFn(obj)}
                  editUserDateFFn={(obj) => onEditUserDatePwFn(obj)}
                  savePathwayFFn={(obj) => onSavePathwayFFn(obj)}
                  dataHasChangedFn={(obj) => onDataHasChangedFn(obj)}
                />
              }
            />
          </>
        )}
      </>
      {/* Modal Publish*/}
      <Modal
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={isPublishModalOpen}
        onHide={closePublishModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to publish this pathway​</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closePublishModal}>
            No
          </Button>
          <Button variant="primary" onClick={handlePublishClick}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal UnPublish*/}
      <Modal
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={isUnpublishModalOpen}
        onHide={closeUnpublishModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{modalMessage}​</p>
        </Modal.Body>
        <Modal.Footer>
          {showModalButtons ? (
            <>
              <Button variant="secondary" onClick={closeUnpublishModal}>
                No
              </Button>
              <Button variant="primary" onClick={handleUnPublishClick}>
                Yes
              </Button>
            </>
          ) : (
            <></>
          )}
        </Modal.Footer>
      </Modal>
      <ModalConfirmCancel
        showCancelEditModal={showCancelSelectModalP}
        abortFn={handleConfirmSelectRowCancel}
        continueFn={handleConfirmSelectRow}
      />
      <ModalConfirmCancel
        showCancelEditModal={showCancelSelectModalP2}
        abortFn={handleConfirmSelectRowCancel2}
        continueFn={handleConfirmSelectRow2}
      />
    </>
  );
}

export default function Pathways() {
  return (
    <BaseContainer title="Learning Pathways Admin">
      <PathwaysCore />
    </BaseContainer>
  );
}
