import React, {useEffect, useState} from 'react';
import TabContext from '@mui/lab/TabContext';
import ElearningTabDrawer from "./ElearningTabDrawer";
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import useLmsStore from "../../../hooks/useLmsStore";
import {apiAxiosCalls} from "../../../services/apiAxiosCalls";
import ELearningTabPropertiesForm from "./ELearningTabPropertiesForm";
import ELearningTabVersions from "./ELearningTabVersions";
import ELearningTabPreview from "./ELearningTabPreview";
import ZipFileSelectorInput from "./ZipFileSelectorInput";
import SelectAndUploadScormFile from "./SelectAndUploadScormFile";
import ElearningRemap from "./ElearningRemap";
import UploadNewVersion from "./UploadNewVersion";
import {Skeleton} from "@mui/material";

export const ELearningTabDrawerEnum = {
    Properties: "Properties",
    Versions: "Versions",
    UploadNewVersion: "UploadNewVersion",
    Preview: "Preview",
}
const ELearningTab = () => {

    const [currentPanel, setCurrentPanel] = useState(ELearningTabDrawerEnum.Properties);
    const [eLearningBasic, setELearningBasic] = useState(null)
    const [properties, setProperties] = useState(null)
    const [displayUpload, setDisplayUpload] = useState(false)
    const [displayCanRemap, setDisplayCanRemap] = useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const [activeDueDates, setActiveDueDates] = useState(null)

    const {learningItemsState, elearningDueDateState} = useLmsStore(state => {
        return {
            learningItemsState: state.learningItemsState,
            elearningDueDateState: state.elearningDueDateState
        }
    })

    const { activeLearningItem } = learningItemsState;

    const handleChange = (event, newValue) => {
        setCurrentPanel(newValue);
    };

    const renderPanels = () => {
        return <>
            <TabPanel sx={{padding: "0"}} value={ELearningTabDrawerEnum.Properties}>
                <ELearningTabPropertiesForm properties={properties} duedates={activeDueDates}/>
            </TabPanel>
            <TabPanel sx={{padding: "0"}} value={ELearningTabDrawerEnum.Versions}>
                <ELearningTabVersions id={eLearningBasic?.ELearningId}/>
            </TabPanel>
            <TabPanel sx={{padding: "0"}} value={ELearningTabDrawerEnum.UploadNewVersion}>
                <UploadNewVersion rusticiCourseId={properties?.RusticiCourseId} id={eLearningBasic?.ELearningId} />
            </TabPanel>
            <TabPanel sx={{padding: "0"}} value={ELearningTabDrawerEnum.Preview}>
                <ELearningTabPreview id={eLearningBasic?.ELearningId}/>
            </TabPanel>
        </>
    }

    const getProperties = async (id) => {
        try {
            const {data} = await apiAxiosCalls.getElearnCourseProperties(id)
            if (!data.IsValid) {
                throw new Error("Error getting course properties")
            }

            setProperties(data.Value);
        } catch (e) {
            console.log(e)
        }
    }

    const getBasic = async () => {
        setIsLoading(true)
        try {
            const {data} = await apiAxiosCalls.getELearingItemBasic(activeLearningItem.applicationLearningItemid)

            if (!data.IsValid) {
                throw new Error("Error getting elearning basics")
            }
            setELearningBasic(data.Value)
            if(data.Value.CanRemap){
                setDisplayCanRemap(true)
                setIsLoading(false)
                return
            }
            if (!data.Value.ELearningId) {
                setDisplayUpload(true)
                setIsLoading(false)
                return
            }
            setDisplayUpload(false)
            setDisplayCanRemap(false)

            await getProperties(data.Value.ELearningId)
        } catch (e) {
            console.log(e)
        }
        setIsLoading(false)
    }

    const renderSkeleton = () => {
        return <Skeleton height={500} />
    }

    const renderContent = () => {
        if(isLoading){
            return  renderSkeleton()
        }

        if(displayCanRemap){
            return <ElearningRemap />
        }

        if(displayUpload){
            return <SelectAndUploadScormFile onUploadSuccess={getBasic} rusticiCourseId={properties?.RusticiCourseId}/>
        }

        return <>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <ElearningTabDrawer handleChange={handleChange}/>
            </Box>
            {renderPanels()}
        </>
    }

    useEffect(() => {
        getBasic();
        if (elearningDueDateState){
            setActiveDueDates(elearningDueDateState)
        }
    }, [])

    return (
        <div>
            <TabContext value={currentPanel}>
                {renderContent()}
            </TabContext>
        </div>
    );
};

export default ELearningTab;
