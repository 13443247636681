import { useEffect, useMemo, useState } from 'react';

import { Container, Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { TableContainer } from '../../../helpers/tableHelper'

import MaterialReactTable from 'material-react-table';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
         faEye
        } from '@fortawesome/pro-light-svg-icons';


import { apiAxiosCalls } from "../../../services/apiAxiosCalls";

export default function ViewDocument(props)
{
    const [loading, setLoading] = useState(false);
    const [docData, setdocData] = useState([]);
    const [docMimeType, setDocMimeType] = useState([]);

    const getCertificatesPdf = async (certId) =>
    {
        try
        {
            setLoading(true);
            const { data } = await apiAxiosCalls.GetCertificatePdf(certId);

            if (!data.isValid)
            {
                throw new Error("Error getting certificates history");
            }
            else
            {
                setdocData(data.value);
            }
        }
        catch(error)
        {
            console.log(`Error fetching certificates: ${error}`)
        }
        finally
        {
            setLoading(false);
        }

    }

    useEffect(() =>
    {
        if (props.docData && props.docData.length > 0)
        {
            setdocData(props.docData);
            setDocMimeType(props.documentMimeType)
        }
        // else
        // {
        //     getCertificatesPdf(props.certId);
        // }
        //alert(props.certId);
        
        //}
    },
    [props.docData]);


    return (
        <>
        {
            <Container fluid>
                <Row>
                    <Col>
                        {
                        loading ?
                        <>
                            <Button variant="primary" disabled className="mx-2">
                                <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                                Please wait...
                            </Button>
                        </>
                        :
                            <>
                            <TableContainer>
                                <Row>
                                    <Col md={12}>
                                         <div className='float-right' >
                                            <Button 
                                                variant='btn btn-outline-primary'
                                                style={{'marginBottom':'10px','marginTop':'10px', marginLeft:'5px', marginRight:'10px', 'width':'100px'}}
                                                onClick={(event)=> {props.onBackButtonClick()}}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    {
                                        docData && docData.length > 0 ?
                                        <embed src={`data:${docMimeType};base64,${docData}`} width="100%" height="600px" />                                        
                                        : 
                                            <div style={{'textAlign':'center', 'marginTop':'70px', 'marginBottom':'70px','fontStyle':'normal', 'fontSize':'22px'}}>No Data Found</div>
                                    }
                                    
                                    </Col>
                                </Row>
                            </TableContainer>
                            </>
                        }
                    </Col>
                </Row>
            </Container>
        }
     </>
);
}
