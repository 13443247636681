import axios from 'axios';
import FAQsCommonItems from '../components/FAQsCommonItems';
import MailTo from '../components/MailTo';
import ModalForceChangePassword from '../components/ModalForceChangePassword';
import ModalForgottenPassword from '../components/ModalForgottenPassword';
import ModalTermsAndConditions from "../components/ModalTermsAndConditions";
import useLocationConfig from '../hooks/useLocationConfig';
import { Accordion, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { authAxios, authAxiosOnError } from "./AuthLogin";
import { clearStore } from "../hooks/useLmsStore";
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from "./hooks/useAuth";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// Wait up to 180 secs in dev mode else 60 secs
const LOGIN_TIMEOUT = process.env.NODE_ENV === 'development' ? 180000 : 90000;

const validateJwt = (token) => {
    try {
        let base64Url = token.split('.')[1],
            base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'),
            jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join('')),
            accessToken = JSON.parse(jsonPayload);
        if (accessToken.isSAML === true || (Date.now() > accessToken.exp * 1000)) {
            throw new Error('Invalid token');
        }
    } catch (e) {
        console.error(e);
        console.dir(token);
        throw e;
    }
};

export default function LoginClassic() {
    const { instance } = useMsal();
    const { login, logout } = useAuth();
    const { themeName } = useLocationConfig();
    const [inProgress, setInProgress] = useState(false);
    const [mainError, setMainError] = useState(null);
    const [username, setUserId] = useState("");
    const [terms, setTerms] = useState("");
    const [user, setUser] = useState(null);
    const [password, setPsw] = useState("");
    const [isOpenForgottenPsw, setisOpenForgottenPsw] = useState(false);
    const [isOpenTerms, setisOpenTerms] = useState(false);
    const [isOpenForceChangePsw, setisOpenForceChangePsw] = useState(false);
    const [validated, setValidated] = useState(false);
    const [serviceLabel, setServiceLabel] = useState('Learning Service');
    const [serviceEmail, setServiceEmail] = useState('learning.service@wdr.co.uk');

    useEffect(() => {
        // Make sure the store is empty;
        clearStore();
        if (window.location.hostname.includes('cerespoweracademy')) {
            setServiceLabel('Ceres Academy');
            setServiceEmail('ceres.academy@wdr.co.uk');
        }
    }, []);

    /* Login setting */
    const handleChangeUserId = (event) => {
        setMainError("");
        setUserId(event.target.value);
    };

    const handleChangePsw = (event) => {
        setMainError("");
        setPsw(event.target.value);
    };

    function handleError(e) {
        setMainError(e.message);
        setPsw("");
        setValidated(false);
        setInProgress(false);
        console.error(e);
    }

    function toggleModalForgottenPsw() {
        setisOpenForgottenPsw(!isOpenForgottenPsw);
    }
    function toggleModalTerms() {
        setisOpenTerms(!isOpenTerms);
    }

    function toggleModalForceChangePsw() {
        setisOpenForceChangePsw(!isOpenForceChangePsw);
    }

    /*
    This function is called when the person login and the user is set sucessfully and is used to check if they require to accept terms and condition
    */
    function processAcceptTermsAndChangePsw() {
        if (user != null) {
            //Check if the user had not approved the terms and conditions
            if (!user.agreedTermsAndConditionsVersion) {
                //Open terms and conditions modal
                setTerms(user.terms);
                toggleModalTerms();
            }
            else {
                //Carry on with the login process
                processLogin();
            }
        }
    }

    /* After all the checking are done sucessfully, do final setting and go to the dashboard page */
    function processLogin() {
        setInProgress(false);
        login(user.token);
        window.location.href = '/lms/dashboard';

    }

    /* This function is call the when submit terms ad condition modal
    */
    function onCloseSubmitModalTerms(event) {

        //Check if require password reset if not then login
        if (user.requiresPasswordReset) {
            toggleModalTerms();
            toggleModalForceChangePsw();
            // handleShowForceChangePsw();           
        }
        else {
            processLogin();
            event.preventDefault();
            event.stopPropagation();
        }
    }


    /*
     It is called when the user click login and if sucessful it will set the user
    */
    function handleLogin(event) {
        try {
            const form = event.currentTarget;
            if (!inProgress && form.checkValidity() === true) {
                setInProgress(true);
                setValidated(true);
                axios({
                    method: 'post',
                    url: '/api/account/loginlms',
                    timeout: LOGIN_TIMEOUT,
                    data: { username, password }
                })
                    .then((result) => {
                        try {
                            if (result.status === 204 || !result.data.isAuthenticated) {
                                throw new Error('Invalid email address or password');
                            }
                            // Validate token
                            validateJwt(result.data.token);
                            // Set the user information
                            setUser(result.data);
                        } catch (e1) {
                            handleError(e1);
                        }
                    }).catch(handleError);
            }
        } catch (e2) {
            handleError(e2);
        }
        event.preventDefault();
        event.stopPropagation();
    }

    const [showFAQs, setShowFAQs] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const handleCloseFAQs = () => setShowFAQs(false);
    const handleShowFAQs = () => setShowFAQs(true);
    const handleCloseSignUp = () => setShowSignUp(false);
    const handleShowSignUp = () => setShowSignUp(true);

    // const goToContractorLogin = () => {
    //     navigate("/contractor-login")
    // }

    //Load when the user change after the login iss approved
    useEffect(() => {
        // Process terms and condtions and change password procedure
        processAcceptTermsAndChangePsw();
    }, [user]);

    // XB NOTE: THIS NEEDS TO BE REVISITED. I AM USURE AS TO WHY WE HAVE A DIFFERENT SCOPE HERE
    // TO THE ONE USED IN AuthLogin AND DEFINED IN location-config.json
    useEffect(() => {
        // If not null then this is a B2C Account
        const currentAccount = instance.getActiveAccount();
        if (currentAccount) {
            const fn = async () => {
                const tokenRequest = {
                    account: currentAccount,
                    scopes: ["https://wdrb2clms.onmicrosoft.com/d68c2106-fb2d-4d22-b81f-9f89bd87dd92/User.Read"],
                };
                await instance.acquireTokenSilent(tokenRequest).then(async res => {
                    login(res.accessToken)
                    await authAxios.get('/api/account/setApReToSesion').then(res => {
                        window.location.href = '/lms/dashboard';
                    }).catch((e) => {
                        authAxiosOnError(e, instance, logout, currentAccount);
                    })
                }).catch(e => {
                    authAxiosOnError(e, instance, logout, currentAccount);
                })
            };
            fn();
        } else {
            setInProgress(false);
        }
    }, [instance])

    return (
        <>
            <Row className="m-0 ">
                <Col className="cls-login-col-left p-0" md={6} lg={5} xl={4} xxl={3}>
                    <div className="cls-login-colour-ovl d-flex align-items-center justify-content-center">
                        <Row style={{ width: '100%' }}>
                            <Col sm={2} md={1}></Col>
                            <Col>
                                <Card className="mb-5" style={{ width: '100%' }}>
                                    <div className="d-flex justify-content-end" style={{ margin: ' 1rem 1.5rem 0 0' }}>
                                        {themeName ? <Card.Img variant="top" src={require(`../assets/instance/${themeName}/logo.png`)} style={{ width: '140px' }} /> : <></>}
                                    </div>
                                    <Card.Body>
                                        <Card.Title>Sign In</Card.Title>
                                        <Form noValidate validated={validated} onSubmit={handleLogin}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control placeholder="Enter email" type="text" required value={username} onChange={handleChangeUserId} disabled={inProgress} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid email.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-4">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control placeholder="Enter password" type="password" required value={password} onChange={handleChangePsw} disabled={inProgress} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a password.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                {
                                                    inProgress ?
                                                        <Button style={{ backgroundColor: 'var(--app-login-ovl)' }} variant="primary" className="w-100" disabled>
                                                            <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                                                            Please wait...
                                                        </Button>
                                                        :
                                                        <Button type="submit" style={{ backgroundColor: 'var(--app-login-ovl)' }} variant="primary" className="w-100">Log in</Button>
                                                }

                                            </Form.Group>
                                            {mainError && (<p className="text-danger">{mainError}</p>)}
                                        </Form>
                                        <Row className="mt-3">
                                            <Col style={{ width: '60px' }}><Button variant="clear" size="lg" onClick={handleShowFAQs} disabled={inProgress}><FontAwesomeIcon style={{ color: 'var(--app-login-ovl)' }} icon={faCircleQuestion} className='m-0' /></Button></Col>
                                            <Col className="d-flex justify-content-end"><div className="mt-3"><Button style={{ color: 'var(--app-login-ovl)' }} variant="a" onClick={toggleModalForgottenPsw} disabled={inProgress}>Forgot password?</Button></div></Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={2} md={1}></Col>
                        </Row>
                    </div>
                </Col>
                <Col className="cls-login-col-right"><div className="cls-login-colour-ovl"></div></Col>
            </Row>
            <Modal fullscreen='xl-down' size='xl' show={showFAQs} onHide={handleCloseFAQs}>
                <Modal.Header closeButton>
                    <Modal.Title>FAQs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Learning Portal - FAQs Frequently Asked Questions</h5>
                    <p>The Learning Portal manages the administration and delivery of online learning (eLearning) courses and learning or training materials, and at the same time tracks members’ online learning progression.</p>
                    <Accordion>
                        <FAQsCommonItems serviceEmail={serviceEmail} serviceLabel={serviceLabel} />
                    </Accordion>
                    <p className="mt-4 text-center">
                        <h6>Can't find the answer to your question?</h6>
                        <MailTo email={`${serviceEmail}?subject=LMS Support Query`}>Click Here</MailTo> to send us an email.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseFAQs}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showSignUp} onHide={handleCloseSignUp}>
                <Modal.Header closeButton>
                    <Modal.Title>Sign-up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Please send an email to <MailTo email='wdrassist@wdr.co.uk?subject=LMS Access Request'>wdrassist@wdr.co.uk</MailTo> to request access.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseSignUp}>Close</Button>
                </Modal.Footer>
            </Modal>
            <ModalForgottenPassword closeFn={toggleModalForgottenPsw}
                open={isOpenForgottenPsw}
            />
            <ModalTermsAndConditions closeFn={toggleModalTerms} closeAndSubmitFn={onCloseSubmitModalTerms} open={isOpenTerms} termsText={terms} />
            <ModalForceChangePassword closeFn={toggleModalForceChangePsw} closeAndSubmitFn={processLogin} open={isOpenForceChangePsw} />

        </>
    );
}
