import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { canShowStart, isAttended, isCompleted, isConfirmed, isPathway } from './learningPlanDataHelpers';
import { faCirclePlay, faCircleXmark, faChevronsUp, faChevronsDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LearningPlanAction1CellStd = ({handleStart, row, toggleModalElearningRefresher, openModal, setRowPwSelection}) => {

    return (<>
        {canShowStart(row.original, false)
            ?
            row.original.learningItemTypeId === 2 ?
                (
                    (!isAttended(row.original) && !isConfirmed(row.original) ?
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                            overlay={< Tooltip className='cls-theme-tooltip' >Book {row.original.name}</Tooltip >}>
                            <Button variant='clear' onClick={() => handleStart(row.original)}>
                                <FontAwesomeIcon size='xl' icon={faCirclePlay} className='text-primary' /><span className='m-2 text-body'>Book</span>
                            </Button>
                        </OverlayTrigger>
                        : <></>
                    ))
                :
                canShowStart(row.original, false) &&
                    row.original.learningItemTypeId === 3 &&
                    (row.original.isOpen) &&
                    row.original.isSelected &&
                    !isCompleted(row.original) ?
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                        overlay={< Tooltip className='cls-theme-tooltip'
                        > {(isCompleted(row.original) ? 'Review ' : 'Start ') + row.original.name}</Tooltip >}>
                        <Button variant='clear' onClick={() => toggleModalElearningRefresher(row.original.learningItemId)}>
                            <FontAwesomeIcon size='xl' icon={faCirclePlay} className='text-primary' /><span className='m-2 text-body'>Refresh</span>
                        </Button>
                    </OverlayTrigger>
                    :
                    <>
                        {!isPathway(row.original) ?
                            !row.original.isSelected ?
                                < OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                                    overlay={< Tooltip className='cls-theme-tooltip' > {(isCompleted(row.original) ? 'Review2 ' : 'Start ') + row.original.name}</Tooltip >}>
                                    <Button variant='clear' onClick={() => isCompleted(row.original) ? openModal(row.original) : handleStart(row.original)}>
                                        <FontAwesomeIcon size='xl' icon={faCirclePlay} className='text-primary' /><span className='m-2 text-body'>{isCompleted(row.original) ? 'Review ' : 'Start '}</span>
                                    </Button>
                                </OverlayTrigger >
                                : ''
                            :
                            row.original.isSelected ?
                                <Button variant="clear" disabled>
                                    <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                                </Button>
                                :
                                <Button variant='clear'
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setRowPwSelection(row.original)}>
                                    <FontAwesomeIcon size='xl' icon={row.original.isOpen ? faChevronsUp : faChevronsDown} className='text-primary' /><span className='m-2 text-body'>{row.original.isOpen ? 'Close' : 'Open'}</span>
                                </Button>
                        }
                    </>
            :
            row.original.IsPathway ? 'Expand' : ''}
    </>);
}


export default LearningPlanAction1CellStd;