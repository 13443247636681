import MaterialReactTable from 'material-react-table';
import { Button } from "react-bootstrap";
import { useCallback, useState } from 'react';

export default function CustomTableView({ 
                                            cellClickEventHandler, 
                                            columns, 
                                            columnVisibility, 
                                            enableCellClickEvent, 
                                            enableMultiRowSelection, 
                                            enableRowSelection, 
                                            heading, 
                                            loading, 
                                            rowClickEventHandler, 
                                            users, 
                                            dimensions,
                                            hasSubRows,
                                            disableGlobalFilter 
                                        }) {
    const [rowSelection, setRowSelection] = useState({});
    const [searchString, setSearchString] = useState('');

    const muiTableContainerProps = useCallback(({ table }) => dimensions ? dimensions.computedMuiTableContainerProps(table) : null, [dimensions]);    

    return (
        <>
            {
                loading ?
                    <>
                        <Button variant="primary" disabled className="mx-2">
                            <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                            Please wait...
                        </Button>
                    </>
                    :
                    <MaterialReactTable
                        columns={columns}
                        data={users || []}
                        enableBottomToolbar={false}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enableDensityToggle={false}
                        enableFilterMatchHighlighting={true}
                        enableFullScreenToggle={false}
                        enableGlobalFilter={disableGlobalFilter ? false : true}
                        enableHiding={false}
                        enableMultiRowSelection={enableMultiRowSelection ? true : false} //use radio buttons instead of checkboxes
                        enablePagination={false}
                        enableRowSelection={enableRowSelection ? true : false}
                        enableStickyHeader={true}
                        enableTopToolbar={true}
                        enableExpanding={hasSubRows}
                        getSubRows= {(originalRow) => originalRow?.subRows } //originalRow.employees} //default, can customize
                        // filterFromLeafRows //apply filtering to all rows instead of just parent rows
                        maxLeafRowFilterDepth={0} //When filtering root rows, keep all child rows of the passing parent rows
                        globalFilterFn="contains"//By default is fuzzy
                        initialState={{
                            globalFilter: searchString, //zc - new for custom search
                            showGlobalFilter: disableGlobalFilter ? false : true, //This displays the seach generic way for the materia react table
                            data: users,
                            expanded: hasSubRows

                        }}
                        renderTopToolbarCustomActions={() => heading}
                        muiTableBodyCellProps={({ cell }) => ({
                            onClick: (event) => {
                                {
                                    enableCellClickEvent &&
                                        cellClickEventHandler(event, cell);
                                }
                            },
                        })}
                        muiTableBodyRowProps={({ row }) => ({
                            onClick: (event) => {
                                rowClickEventHandler(event, row);
                                setRowSelection((prev) => ({ [row.id]: !prev[row.id] }))
                            },
                            hover: true,
                            selected: rowSelection[row.id],
                            sx: { cursor: 'pointer' },
                        })}
                        muiTableContainerProps={muiTableContainerProps}
                        muiToolbarAlertBannerProps={{ sx: { display: 'none' } }}
                        onGlobalFilterChange={setSearchString} //zc - new for custom search
                        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                        state={{
                            setLoading: !users,
                            globalFilter: searchString,//zc - new for custom search
                            columnVisibility: columnVisibility ? columnVisibility : {}
                        }}
                    />
            }
        </>
    );
}
