import { Form, InputGroup } from "react-bootstrap";
import { FormButtons } from '../../../components/buttons/FormButtons';
import { Formik } from 'formik';

function GroupDetailTab({ group, onCreate = () => null, isUpdatingOrCreatingGroup, requestLeave, onRequestLeaveCompleted }) {
    return (
        <div className={"px-1 mr-auto"}>
            <Formik
                initialValues={{
                    Name: group?.Name || "",
                    Description: group?.Description || "",
                }}
                onSubmit={(values) => {
                    onCreate(values);
                }}
                enableReinitialize={true}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    resetForm,
                    dirty
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="Name">
                            <Form.Label>Group name</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    name="Name"
                                    type="input"
                                    placeholder="Group name"
                                    disabled={group?.IsSystemGroup}
                                    value={values.Name}
                                    className="no-border"
                                    onChange={handleChange}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="Description">
                            <Form.Label>Group description</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    name="Description"
                                    as="textarea"
                                    placeholder="Group description"
                                    value={values.Description}
                                    className="no-border"
                                    onChange={handleChange}
                                />
                            </InputGroup>
                        </Form.Group>
                        <FormButtons
                            hasUnsavedChanges={dirty}
                            pleaseWait={isUpdatingOrCreatingGroup}
                            requestLeave={requestLeave}
                            onRequestLeaveCompleted={onRequestLeaveCompleted}
                            cancelButtonProps={{
                                onClick: resetForm
                            }}
                            saveButtonProps={{
                                onClick: handleSubmit
                            }}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default GroupDetailTab;
