import React from 'react';
import {Col, Form, InputGroup, Row} from "react-bootstrap";

function ReferenceMaterialSettingsSubTab({form: {handleSubmit, handleChange, values, touched, errors, handleBlur}}) {
    return (<Row className={"w-100"}>
            <Form.Group className="mb-3" controlId="code1">
                <Form.Label>Reference Material Url</Form.Label>
                <InputGroup hasValidation>
                    <Form.Control
                        placeholder="Reference Material Url"
                        type="text"
                        name="HyperLink"
                        value={values.HyperLink}
                        onChange={handleChange}
                        isValid={touched.HyperLink && !errors.HyperLink}
                        isInvalid={touched.HyperLink && errors.HyperLink}
                        onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.HyperLink}
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
        </Row>);
}

export default ReferenceMaterialSettingsSubTab;
