import { useEffect, useMemo, useState, useCallback } from 'react';
import BaseContainer from "../components/BaseContainer";

import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { TableRowSplit } from '../helpers/tableHelper'

import MaterialReactTable from 'material-react-table';

import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

import FormAdminNews from '../components/FormAdminNews';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/pro-regular-svg-icons';

function NewsFeedItemsCore({ data, dimensions }) {
    const [tableInactive, setTableInactive] = useState(false);
    const [tableData, setTableData] = useState([]);
    const { computedMuiTableContainerProps } = dimensions;

    const [searchString, setSearchString] = useState('');
    const { adminNews, applicationGroups, fetchAdminNews, fetchApplicationGroups } = useLmsStore(state =>
    ({
        adminNews: state.adminNews.data,
        applicationGroups: state.applicationGroups.data,
        fetchAdminNews: state.fetchAdminNews,
        fetchApplicationGroups: state.fetchApplicationGroups
    }), shallow)

    const [adminNewsItem, setAdminNewsItem] = useState([]);
    const [showForm, setShowForm] = useState(false);

    const handleAdd = () => {

        if (applicationGroups == undefined) {
            fetchApplicationGroups();
        }
        setAdminNewsItem([]);
        setShowForm(true);
    }

    const handleRefresh = useCallback(
        (row) => {
            fetchAdminNews(true);
            setShowForm(false);
        },
        [],
    );

    const handleEdit = (rowData) => {
        setAdminNewsItem(rowData);
        setShowForm(true);
    }

    // on load
    useEffect(() => {
        fetchApplicationGroups().then(() => {
            fetchAdminNews();
        })


    }, []);

    const columns = useMemo(
        () => [
            {
                accessorKey: "title",
                header: "title"
            }
        ],
        [],
    );

    const onInactiveChange = (event) => {
        setTableInactive(!tableInactive);
        //console.dir(tableInactive);
    }

    useEffect(() => {
        setTableData(data || []);
    }, [data]);

    const withSelectedRows = (tableData, inactive) => (tableData ?? []).filter(e => inactive == true || (inactive == false && e.isActive == true));

    return (
        <TableRowSplit
            leftPane={
                <>
                    <div className="mt-2 mx-2 form-check form-switch">
                        <input type="checkbox" id="isActive" className="form-check-input" onChange={onInactiveChange} />
                        <label title="" htmlFor="isActive" className="form-check-label">Include Inactive</label>
                    </div>

                    <MaterialReactTable
                        columns={columns}
                        data={withSelectedRows(adminNews, tableInactive) || []}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        enableGrouping={false}
                        enableHiding={false}
                        enablePagination={true}
                        enableSorting={false}
                        enableTopToolbar={true}
                        enableFilterMatchHighlighting={true}
                        showGlobalFilter
                        globalFilterFn="contains"//By default is fuzzy
                        enableGlobalFilter={true}
                        muiTableHeadProps={{
                            sx: {
                                display: 'none'
                            }
                        }}
                        muiTableContainerProps={({ table }) => computedMuiTableContainerProps(table)}
                        getRowId={(row) => row.userId}
                        muiTableBodyRowProps={({ row }) => ({
                            //implement row selection click events manually
                            onClick: () => handleEdit(row.original),
                            sx: {
                                cursor: 'pointer',
                            },
                        })}

                        muiToolbarAlertBannerProps={{
                            sx: {
                                display: 'none'
                            }
                        }}
                        onGlobalFilterChange={setSearchString} //zc - new for custom search
                        renderToolbarInternalActions={({ table }) => (
                            <>
                                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add</Tooltip>}>
                                    <Button variant='clear' className='py-0' onClick={() => handleAdd()}>
                                        <FontAwesomeIcon size='xl' icon={faPlus} className='text-clear' />
                                    </Button>
                                </OverlayTrigger>
                            </>
                        )}
                        initialState={{
                            globalFilter: searchString,//zc - new for custom search
                            showGlobalFilter: true

                        }}
                        state={{
                            isLoading: !adminNews,
                            globalFilter: searchString,//zc - new for custom search
                        }}
                    />
                </>
            }
            rightPane={showForm && applicationGroups ?
                <FormAdminNews refreshFn={handleRefresh} adminNewsItem={adminNewsItem} applicationGroups={applicationGroups} />
                :
                null
            }
        />
    );
}

export default function NewsFeedItems() {
    return (<BaseContainer title='News Admin'>
        <NewsFeedItemsCore />
    </BaseContainer>)
}