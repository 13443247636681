import React, {useEffect, useState} from 'react';
import {apiAxiosCalls} from "../../services/apiAxiosCalls";
import useLmsStore from "../../hooks/useLmsStore";
import SynopsisPreviewModal from "./LearningItemSynopsisTab/SynopsisPreviewModal";

const SynopsisMagnification = ({isPreviewModalOpen=false,setIsPreviewModalOpen=()=>null }) => {
    const [sections, setSections] = useState([])
    const [isLoadingSections, setIsLoadingSections] = useState(false)
    const {learningItemsState} = useLmsStore((state) => {
        return {
            learningItemsState: state.learningItemsState
        }
    })

    const {
        activeLearningItemDetail,
        loadingActiveLearningDetail,
        learningItems
    } = learningItemsState;

    const getSections = async () => {
        try {
            setIsLoadingSections(true)
            const {data} = await apiAxiosCalls.getLearningItemSynopsis(activeLearningItemDetail.Id)
            if (!data.IsValid) {
                throw new Error("Error getting synopsis sections")
            }
            setSections(data.Value.Sections)
        } catch (e) {

        }
        setIsLoadingSections(false)

    }

    useEffect(() => {
        getSections();
    },[])

    return (
        <div>
            <SynopsisPreviewModal isLoadingSections = {isLoadingSections} sections={sections} isModalOpen={isPreviewModalOpen} closeModal={() => {
                setIsPreviewModalOpen(false)
            }}/>
        </div>
    );
};

export default SynopsisMagnification;
