import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import imgGrey266x140 from "../assets/imgs/img-grey-266x140.png";
import imgGrey266x140Blurred from "../assets/imgs/img-grey-266x140-blurred.png";
import useLmsStore, {
  postAddPathwayToPlan
} from "../hooks/useLmsStore";
import userblank from "../assets/imgs/userblank.jpg";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checkThatImageExists } from "../helpers/imgs";
import {
  faCheck,
  faPlus,
  faFileArrowDown,
} from "@fortawesome/pro-solid-svg-icons";
import { learningItemName } from "../helpers/tableHelper";
import { useEffect, useState } from "react";
import ModalConfirmation from "./ModalConfirmation";
import ModalEventUKPN from "./ModalEventUKPN";
import { commonService } from "../services/common/common.service";

export function PathwayCard(props) {
  const [image, setImage] = useState(imgGrey266x140);

  useEffect(() => {
    if (
      props.rowData.imageUrl &&
      props.rowData.imageUrl.endsWith("/userblank.jpg")
    ) {
      setImage(userblank);
    } else {
      checkThatImageExists(props.rowData.imageUrl).then((img) => {
        setImage(img ? img.src : imgGrey266x140);
      });
    }
  }, [props.rowData.imageUrl]);

  return (
    <Card
      className="cls-card-signature-colour m-2 p-0 shadow"
      style={{ height: "220px", width: "300px" }}
    >
      <Card.Header>
        <Row>
          <Col>Learning Pathway</Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Card.Title style={{ fontSize: "1rem" }}>
          {props.rowData.name}
        </Card.Title>
        {/* <p style={{ fontSize: "0.85rem" }}>
          {props.rowData.description.length > 120
            ? props.rowData.description.substring(0, 115) + "..."
            : props.rowData.description}
        </p> */}
      </Card.Body>
      <Card.Footer className="cls-clear">
        <div className="d-flex justify-content-between">
          <div className="p-0" style={{ maxWidth: "50%" }}></div>
          <div className="p-0"></div>
          <div className="p-0">
            <CourseCardInfo {...props} />
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
}

function CourseCardInfo(props) {
  const [addedToPlan, setAddedToPlan] = useState(false);
  const [loading, setLoading] = useState();

  const handleAddToPlan = () => {
    postAddPathwayToPlan(useLmsStore, {
      PathwayId: props.rowData.learningProgrammeGroupId,
      PersonId: 0
    });
  };

  return (
    <>
      {loading && (
        <div className="d-flex justify-content-center">
          <div
            className="spinner-border mt-5 text-primary"
            style={{ width: "5rem", height: "5rem" }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {props.rowData.isItRegisterToMySelf ? (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip className="cls-theme-tooltip">Added to plan</Tooltip>
          }
        >
          <Button variant="clear" className="pt-0 pb-0">
            <FontAwesomeIcon
              size="xl"
              icon={faCheck}
              className="fa-fw"
              style={{ color: "var(--app-success)" }}
            />
          </Button>
        </OverlayTrigger>
      ) : (
        <>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip className="cls-theme-tooltip">Add To Plan</Tooltip>
            }
          >
            <Button
              variant="clear"
              className="pt-0 pb-0"
              onClick={() => handleAddToPlan(props.rowData)}
            >
              <FontAwesomeIcon size="xl" icon={faPlus} className="fa-fw" />
            </Button>
          </OverlayTrigger>
        </>
      )}
    </>
  );
}

export function PathwayCardLoading(props) {
  return (
    <Card
      className="cls-card-signature-colour m-2 p-0 shadow cls-blurred cls-card-anim"
      style={{ height: "220px", width: "300px" }}
    >
      <Card.Header>
        <Row>
          <Col>Learning Pathway</Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Card.Title style={{ fontSize: "1rem" }}>Vultus secretum</Card.Title>
        <p style={{ fontSize: "0.85rem" }}>
          Responsio ad vitam, universum et omnia sane 42. Hoc a duce obtinuit.
        </p>
      </Card.Body>
      <Card.Footer className="cls-clear">
        <Row>
          <Col>Duc ad Galaxy</Col>
          <Col sm="auto" className="d-flex justify-content-end">
            BTN
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}
