import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import OutActionsNotAttendance from "./OutActionsNotAttendance";
import OutActionsCompetencyRewarded from "./OutActionsCompetencyRewarded";
import { apiAxiosCalls } from "../../services/apiAxiosCalls";
import OutActionsTrainingRequestApproval from './OutActionsTrainingRequestApproval';
import useLmsStore from "../../hooks/useLmsStore";
import shallow from 'zustand/shallow';

const filterReasonIds = [626, 627, 632, 633, 634, 635, 637, 638, 639, 640, 641, 645];

// 629 is "Did Not Show" 
const didNotShowDefault = { "cancelReasonID": 629, "cancelReason": "Please select a reason..." };

const TabKeys = {
    NotAttendance: "NotAttendance",
    TrainingRequestApproval: "TrainingRequestApproval",
    TrainingRequestJustification: "TrainingRequestJustification",
}
// This view only displays the "Did Not Show"
const OutActionsManagement = ({ dimensions }) => {
    const [activeTabKey, setActiveTabKey] = useState(TabKeys.NotAttendance);
    const [loading, setLoading] = useState(true)

    //Not attendace state
    const [tableDataNotAttendance, setTableDataNotAttendance] = useState([]);
    const [reasons, setReasons] = useState([]);
    const [allReasons, setAllReasons] = useState([]);
    const [initialDataNotAttendance, setInitialDataNotAttendance] = useState([]);
    const [loadingNotAttendance, setLoadingNotAttendance] = useState(false);

    //Request Training state
    const [trainingRequestApproval, setTrainingRequestApproval] = useState([])

    //Requiring justification state
    const [tableDataRequiringApproval, setTableDataRequiringApproval] = useState([])
    const [loadingRequiringApproval, setLoadingRequiringApproval] = useState(false)


    const { personSettings } = useLmsStore(state =>
    ({
        personSettings: state.personSettings.data,
    }), shallow);

    const counters = {
        [TabKeys.NotAttendance]: tableDataNotAttendance.length,
        [TabKeys.TrainingRequestApproval]: trainingRequestApproval.filter(x => x.approvalLevel > 1).length,
        [TabKeys.TrainingRequestJustification]: tableDataRequiringApproval.length
    }
    const handleSelect = (selectedKey) => {
        setActiveTabKey(selectedKey);
    };


    const getOutActionsNotAttendance = async () => {
        await apiAxiosCalls.GetOutstandingActionsDidNotShow(personSettings?.ukpnPersonId).then(response => {
            setTableDataNotAttendance(response.data);
            setInitialDataNotAttendance(response.data.map(oa => ({
                actionId: oa.actionID,
                reason: oa.noShowReason || '',
                bookingId: oa.bookingID || '',
            })));
        });
    };

    const getOutActionsNotAttendanceInitial = async () => {
        setLoadingNotAttendance(true)
        await getOutActionsNotAttendance()
        setLoadingNotAttendance(false)
    }

    const getReasons = async () => {
        try {
            const { data } = await apiAxiosCalls.GetNonAttendanceReasons();
            setAllReasons(data);
            setReasons([didNotShowDefault].concat(data.filter(x => filterReasonIds.includes(x.cancelReasonID)).sort((a, b) => (a.cancelReason > b.cancelReason) ? 1 : -1)));
        } catch (e) {
            console.error(e);
        }
    };

    const getOutActionsTrainingRequestApproval = async () => {
        try {
            setLoading(true)
            const { data } = await apiAxiosCalls.GetStaffApprovalManagerActions(personSettings?.ukpnPersonId);
            setTrainingRequestApproval(data.map((result, index) =>
            ({
                ...result,
                index: index,
                left: '',
                right: '',
                displayCancelReason: false,
                displayBusinessJustification: false,
                CancelReason: 'Please select a reason...',
                CancelReasonID: -1,
                requireJustificationApproved: result.approvalLevel > 2 ? (result.justificationTextID>0) : true // if approvel level <=2 then we set this flag to approved by default
            })));

        } catch (e) {
            console.error(e);
        }
        setLoading(false)
    };

    const getOutActionsCompetencyRewarded = async () => {
        try {
            setLoadingRequiringApproval(true)
            const { data } = await apiAxiosCalls.GetCompetencesThatRequireApproval(personSettings?.ukpnPersonId);
            setTableDataRequiringApproval(data)

        } catch (e) {
            console.error(e);
        }
        setLoadingRequiringApproval(false)
    };


    const activeTabMapper = {
        [TabKeys.NotAttendance]: () => <OutActionsNotAttendance
            dimensions={dimensions}
            tableData={tableDataNotAttendance}
            initialData={initialDataNotAttendance}
            getOutActionsNotAttendance={getOutActionsNotAttendance}
            getOutActionsNotAttendanceInitial={getOutActionsNotAttendanceInitial}
            reasons={reasons} loading={loadingNotAttendance}
        />,
        [TabKeys.TrainingRequestApproval]: () => <OutActionsTrainingRequestApproval
            dimensions={dimensions}
            tableData={trainingRequestApproval.filter(x => x.approvalLevel > 1)}
            loading={loading}
            setTableData={setTrainingRequestApproval}
            managerId={personSettings?.ukpnPersonId}
            reasons={allReasons}
        />,
        [TabKeys.TrainingRequestJustification]: () => <OutActionsCompetencyRewarded
            dimensions={dimensions}
            loading={loadingRequiringApproval}
            tableData={tableDataRequiringApproval}
            setTableData={setTableDataRequiringApproval}
            getOutActionsRequiringApproval={getOutActionsCompetencyRewarded}

        />,
    }

    const renderActiveTab = () => {
        return activeTabMapper[activeTabKey]()
    }

    useEffect(() => {
        getReasons()
        getOutActionsNotAttendanceInitial()
        getOutActionsCompetencyRewarded()
        getOutActionsTrainingRequestApproval()
    }, []);


    return (
        <div>
            <Nav variant="tabs" activeKey={activeTabKey} onSelect={handleSelect}>
                <Nav.Item className="position-relative">
                    <Nav.Link eventKey={TabKeys.NotAttendance}>
                        Non-Attendance
                        {!loading && <span className={`position-absolute top-0 start-100 translate-middle badge rounded-pill ${counters[TabKeys.NotAttendance] > 0 ? 'bg-danger' : 'bg-success'}`}>
                            {counters[TabKeys.NotAttendance]}
                            <span className="visually-hidden">New alerts</span>
                        </span>}
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item className="position-relative">
                    <Nav.Link eventKey={TabKeys.TrainingRequestApproval}>
                        Training Requests
                        {!loading && <span className={`position-absolute top-0 start-100 translate-middle badge rounded-pill ${counters[TabKeys.TrainingRequestApproval] > 0 ? 'bg-danger' : 'bg-success'}`}>
                            {counters[TabKeys.TrainingRequestApproval]}
                            <span className="visually-hidden">New alerts</span>
                        </span>}
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item className="position-relative">
                    <Nav.Link eventKey={TabKeys.TrainingRequestJustification}>
                        Competency Awarded
                        {!loading && <span className={`position-absolute top-0 start-100 translate-middle badge rounded-pill ${counters[TabKeys.TrainingRequestJustification] > 0 ? 'bg-danger' : 'bg-success'}`}>
                            {counters[TabKeys.TrainingRequestJustification]}
                            <span className="visually-hidden">New alerts</span>
                        </span>}
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            {renderActiveTab()}
        </div>
    );
};

export default OutActionsManagement;
