import {useEffect, useState} from "react";
import {apiAxiosCalls} from "../services/apiAxiosCalls";

export function useGetAllVersioningActions() {
    const [versioningActions, setVersioningActions] = useState([])

    const getActions = async () => {
        try {
            const {data} = await apiAxiosCalls.getAllVersioningActions()
            if (!data.IsValid) {
                throw new Error("An error occurred when getting versioning actions")
            }
            setVersioningActions(data.Value)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (!versioningActions.length) {
            getActions()
        }
    }, [])


    return versioningActions

}
