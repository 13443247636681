import { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { getIsTrainingPlanUpdatedByItem, responseData } from "../../hooks/useLmsStore";

const ModalElearningRefresher = ({ closeFn = () => null, yesFn = () => null, open = false, refLearningItemId = -1 }) => {
    const [message, setMessage] = useState("The information is still updating, please wait for a few seconds..");
    const [inProgressRefresher, setInProgressRefresher] = useState(true);
    const MAXCHECKING = 500;

    function monitorIsTrainingPlanUpdated(refLearningItemId, counter) {
        let message = "You are about to refresh you Learning Plan. <br/>Please ensure you have closed the eLearning session before refreshing the page.";
        if (counter >= MAXCHECKING) /* Maximun call 100 times before it refresh */ {
            setMessage(message);
            setInProgressRefresher(false);
            return true;
        }
        else {
            getIsTrainingPlanUpdatedByItem(refLearningItemId).then((response) => {

                let data = responseData(response, false);
                if (data) {

                    setMessage(message);
                    setInProgressRefresher(false);
                    return true;
                }
                else {

                    delay(80000);

                    if (open) {
                        return monitorIsTrainingPlanUpdated(refLearningItemId, counter + 1);
                    }
                    else {
                        return false;
                    }
                }

            });
        }
        return false;
    }

    function delay(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    // on load when there is a learning item
    useEffect(() => {
        if (open && refLearningItemId > 0) {

            monitorIsTrainingPlanUpdated(refLearningItemId, 0);

        }
    }, [refLearningItemId]);

    // on load
    useEffect(() => {
        if (open && (refLearningItemId > 0)) {

            monitorIsTrainingPlanUpdated(refLearningItemId, 0);
        }
    }, []);

    function handleClose() {
        open = false;
        refLearningItemId = -1;
        closeFn();

    };


    function handleOk() {
        open = false;
        refLearningItemId = -1;
        yesFn();

    };

    return (
        <Modal fullscreen='md-down' show={open} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span dangerouslySetInnerHTML={{ __html: message }} />

                <div className="mb-2">
                    {!inProgressRefresher ?
                        <Button onClick={handleOk} variant="primary"  >
                            Ok
                        </Button> : ''}

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>

    );
};

export default ModalElearningRefresher;