import shallow from 'zustand/shallow';
import useLmsStore from "../../hooks/useLmsStore";
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useBlocker } from 'react-router-dom';
import { useEffect } from 'react';

export const FormButtons = ({
    hasUnsavedChanges,
    pleaseWait,
    requestLeave,
    onRequestLeaveCompleted,
    cancelButtonProps,
    cancelButtonDisabled = false,
    saveButtonProps,
    saveButtonDisabled = false,
    displayCancelSaveButtons = true }) => {

    const { activeUpdate, setActiveUpdateActive, setActiveUpdateLeaveRequest } = useLmsStore(state =>
    ({
        activeUpdate: state.activeUpdate,
        setActiveUpdateActive: state.setActiveUpdateActive,
        setActiveUpdateLeaveRequest: state.setActiveUpdateLeaveRequest,
    }), shallow)

    let blocker = useBlocker(() => hasUnsavedChanges);

    useEffect(() => {
        if (hasUnsavedChanges !== activeUpdate) {
            setActiveUpdateActive(hasUnsavedChanges);
        }
    }, [hasUnsavedChanges])

    useEffect(() => {
        if (requestLeave && !hasUnsavedChanges && blocker.state !== 'blocked') {
            callOnRequestLeaveCompleted(true);
        }
    }, [requestLeave])

    const handleLeave = (doLeaveFlag) => {
        if (blocker.state === 'blocked') {
            if (doLeaveFlag) {
                blocker.proceed();
            } else {
                blocker.reset();
            }
        }
        if (requestLeave && hasUnsavedChanges) {
            callOnRequestLeaveCompleted(doLeaveFlag);
        }
    }

    const callOnRequestLeaveCompleted = (doLeaveFlag) => {
        if (typeof onRequestLeaveCompleted === 'function') {
            onRequestLeaveCompleted(doLeaveFlag);
        } else {
            console.warn('"FormButtons: onRequestLeaveCompleted" is not a function. Either set this property as a function or do not set the "pleaseWait" property.');
        }
    }

    return (
        <>
            <Row className='justify-content-end m-2'>
                <Col></Col>
                {displayCancelSaveButtons && pleaseWait !== true ?
                    <>
                        {cancelButtonProps ? <Col md='auto'>
                            <Button variant='primary' disabled={!hasUnsavedChanges || cancelButtonDisabled} {...cancelButtonProps} >Cancel</Button>
                        </Col> : null}
                        {saveButtonProps ? <Col md='auto'>
                            <Button variant='primary' disabled={!hasUnsavedChanges || saveButtonDisabled} {...saveButtonProps}>Save</Button>
                        </Col> : null}
                    </> : null}
                {pleaseWait === true ?
                    <Col md='auto'>
                        <Button variant='primary' disabled>
                            <span className='spinner-border spinner-border-sm' style={{ marginRight: '0.25rem' }} role='status' aria-hidden='true'></span>
                            Please wait...
                        </Button>
                    </Col> : null}
            </Row>
            <Modal show={blocker.state === 'blocked' || (requestLeave && hasUnsavedChanges)} onHide={() => handleLeave(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Unsaved Changes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='mb-1'>Are you sure you want to leave this page?</p>
                    <p className='mb-1'>The changes you made will not be saved.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={() => handleLeave(false)} >Cancel</Button>
                    <Button variant='secondary' onClick={() => handleLeave(true)}>Proceed</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
