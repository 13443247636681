import LearningPlanAboutCell from './LearningPlanAboutCell';
import LearningPlanAction1Cell from './LearningPlanAction1Cell';
import LearningPlanAction2Cell from './LearningPlanAction2Cell';
import LearningPlanCancelationModal from './LearningPlanCancelationModal';
import LearningPlanFilterTabs from './LearningPlanFilterTabs';
import MaterialReactTable from 'material-react-table';
import ModalConfirmation from '../ModalConfirmation';
import ModalElearningContinue from './ModalElearningContinue';
import ModalElearningRefresher from './ModalElearningRefresher';
import ModalEvent from '../ModalEvent';
import ModalUserEventInfo from '../ModalUserEventInfo';
import shallow from 'zustand/shallow';
import useLmsStore, { postResetTrainingPlanOrPathway, postRemoveToPlanNew, postRemoveToPlan, responseData, getElearningLinks, postCompleteTrainingPlanAndPathwayNew, setMyActiveSortedTrainingPlanNew, updateLearningPlanOpenNew, updatePathwayOpen } from "../../hooks/useLmsStore";
import UserCard from '../UserCard';
import { Button, Modal } from 'react-bootstrap';
import { filteredData, filteredDataS, getTableDataWithStats, isCompleted, isConfirmed, isPathway, withSelectedRows } from './learningPlanDataHelpers';
import { columnIconLearningItem, TableContainer } from '../../helpers/tableHelper';
import { faChevronsUp, faChevronsDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { useSignalrConnection } from '../../hooks/useSignalrConnection';
import { useSignalRHandler } from '../../hooks/useSignalRHandler';
import { WDR_HUB_URL } from "../../hooks/data";

const hideAggregatedCell = ({ cell, table }) => { return cell.id.startsWith('pwgrpName:') ? { sx: { display: 'none' } } : {} };

export default function LearningPlanTableCore(props) {
    const [awaitingCount, setAwaitingCount] = useState(null);
    const [canHaveNewAttempt, setCanHaveNewAttempt] = useState("");
    const [completedCount, setCompletedCount] = useState(null);
    const [continueLink, setContinueLink] = useState("");
    const [dataActivePlan, setDataActivePlan] = useState(null);
    const [eventVenueInfo, setEventVenueInfo] = useState(null);
    const [filterId, setFilterId] = useState(-1);
    const [isElearningOpen, setIsElearningOpen] = useState(false);
    const [isElearningOpenId, setIsElearningOpenId] = useState(0);
    const [isModalReviewOpen, setIsModalReviewOpen] = useState(false);
    const [modalCourseToCancelData, setModalCourseToCancelData] = useState(null);
    const [userCanceled, setUserCanceled] = useState(false);
    const [isOpenElearningContinue, setisOpenElearningContinue] = useState(false);
    const [isOpenElearningRefresher, setisOpenElearningRefresher] = useState(false);
    const [isOpenEvent, setisOpenEvent] = useState(false);
    const [isOpenNewAttemptConfirmation, setisOpenNewAttemptConfirmation] = useState(false);
    const [isOpenResetElearning, setIsOpenResetElearning] = useState(false);
    const [isOpenUserEvent, setIsOpenUserEvent] = useState(false);
    const [isUKPN, setIsUKPN] = useState(false);
    const [mandatoryCount, setMandatoryCount] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [newAttemptLink, setNewAttemptLink] = useState("");
    const [openClosePw, setOpenClosePw] = useState(false);
    const [overdueCount, setOverdueCount] = useState(null);
    const [refLearningItem, setRefLearningItem] = useState(-1);
    const [refLearningItemData, setRefLearningItemData] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchString, setSearchString] = useState(decodeURI(searchParams.get("search") || ''));
    const [selectedLearningItem, setSelectedLearningItem] = useState(-1);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showByGroup, setShowByGroup] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [tabId, setTabId] = useState('-1');
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [todoCount, setToDoCount] = useState(null);
    const [trainingPlanId, setTrainingPlanId] = useState(0);
    const [upComingCount, setUpcomingCount] = useState(0);
    const [userEventInfo, setUserEventInfo] = useState(null);

    const { personSettings } = useLmsStore(state =>
    ({
        personSettings: state.personSettings.data
    }), shallow)

    useEffect(() => {
        return () => {
            setCustomFilterData(-1);
            setRefLearningItem(-1);
        };
    }, []);

    useEffect(() => {
        setIsUKPN(personSettings?.theme?.toLowerCase() === 'ukpn')
    }, [personSettings]);

    useEffect(() => {
        setCustomFilterData(filterId);
        setShowByGroup(tabId !== '0' && (!searchString || searchString === '') && !tableLoading);
    }, [tabId, searchString, tableLoading]);

    // SignalR section start
    const [srConnection, setSRConnection] = useState(null);

    const { hubConnectionState, error } = useSignalrConnection(srConnection);

    useEffect(() => {
        setSRConnection(
            new HubConnectionBuilder()
                .withUrl(WDR_HUB_URL, { skipNegotiations: true, transport: HttpTransportType.ServerSentEvents })
                .withAutomaticReconnect()
                .build()
        )
    }, [setSRConnection]);

    useEffect(() => { if (error) console.error(error); }, [error]);

    useEffect(() => { if (process.env.NODE_ENV === 'development') console.log(`SignalR Connection: ${hubConnectionState}`); }, [hubConnectionState]);

    useSignalRHandler(srConnection, "svr_eLearning_close", (message) => onSrveLearningClose(message));

    useEffect(() => {
        if (isElearningOpenId > 0) {
            updateLearningPlanOpenNew(useLmsStore, isElearningOpenId, isElearningOpen);
        }
    }, [isElearningOpen, isElearningOpenId]);

    const onCloseRow = (learningItemId) => {
        selectedRows.forEach((row) => {
            if (row.learningItemId === learningItemId) {
                row.isActive = false;
                row.isOpen = false;
            }
        });
        setIsElearningOpenId(learningItemId);
        setIsElearningOpen(false);
        setSelectedRows(selectedRows);
    }

    const onSrveLearningClose = (message) => {
        //Check if it is not a preview, so information can be updated. If it is preview, it does not require any update
        if (!message.isPreview) {
            //Update the store with the latest status and elearning info
            if (setMyActiveSortedTrainingPlanNew(useLmsStore, message)) {
                //Check if we need to unlock the items, if it is coming from SAvePlayer or from postaback with a complete status
                if (message.unlockItem) {
                    onCloseRow(message.learningItemId);
                }
            }
        }
        else {
            onCloseRow(message.learningItemId);
        }
        return;
    };

    function toggleModalResetElearning() {
        setIsOpenResetElearning(!isOpenResetElearning);
    }

    function onHandleResetElearning() {
        let userId = props.dataUser.data.personId;
        if (props.dataUser) {
            toggleModalResetElearning();
            //Call method to reset elearning
            postResetTrainingPlanOrPathway(useLmsStore, { id: refLearningItem, personId: userId }).then((result) => {
                //Zc not woking atthe moment
                // props.resetUserElearning(userId);
                //We need to refresh the page
                window.location.reload(false);
                setRefLearningItem(null);
            });
        }
    };

    const openModal = (data) => {
        setIsModalReviewOpen(true);
        setModalData(data);
    };

    const closeModal = () => {
        setIsModalReviewOpen(false);
    };

    const columnAbout = {
        accessorKey: 'name',
        header: 'Course',
        muiTableBodyCellProps: ({ cell, table }) => { return cell.id.startsWith('pwgrpName:') ? { sx: { display: 'none' } } : { classes: { root: 'fw-unset' } } },
        Cell: ({ cell, row }) =>
            <LearningPlanAboutCell
                cell={cell}
                row={row}
                selectedRows={selectedRows}
                tableColumnsPw={tableColumnsPw}
                enableResetElearning={props.enableResetElearning}
                toggleModalResetElearning={toggleModalResetElearning}
                setRefLearningItem={setRefLearningItem}
                setEventVenueInfo={setEventVenueInfo}
                setIsOpenUserEvent={setIsOpenUserEvent}
                setUserEventInfo={setUserEventInfo}
            />
    };

    const columnAction1 = {
        accessorFn: (row) => `${row.id}-1`,
        id: 'action-1',
        maxSize: 150,
        minSize: 150,
        muiTableBodyCellProps: hideAggregatedCell,
        Cell: ({ cell, row }) => <LearningPlanAction1Cell
            handleStart={handleStart}
            row={row}
            isUKPN={isUKPN}
            toggleModalElearningRefresher={toggleModalElearningRefresher}
            onChangeState={props.onChangeState}
            openModal={openModal}
            setRowPwSelection={setRowPwSelection}
        />
    };

    const columnAction2 = {
        accessorFn: (row) => `${row.id}-2`,
        id: 'action-2',
        maxSize: 150,
        minSize: 150,
        muiTableBodyCellProps: hideAggregatedCell,
        Cell: ({ cell, row }) => <LearningPlanAction2Cell
            handleCancel={handleCancel}
            handleRemove={handleRemove}
            row={row}
        />
    };

    const columnActionPw = {
        accessorKey: 'id',
        header: 'Id',
        maxSize: 200,
        minSize: 200,
        muiTableBodyCellProps: hideAggregatedCell,
        Cell: ({ cell, row }) => {
            return <>
                {isPathway(row.original) ?
                    <Button variant='clear'
                        aria-label="expand row"
                        size="small"
                        onClick={() => setRowPwSelectionTeam(row.original)}>
                        <FontAwesomeIcon size='xl' icon={row.original.isOpen ? faChevronsUp : faChevronsDown} className='text-primary' /><span className='m-2 text-body'>{row.original.isOpen ? 'Close' : 'Open'}</span>
                    </Button>
                    : <></>
                }
            </>
        }
    }

    const handleCancel = (rowData) => {
        debugger;
        if (rowData.levelOfApproval > 1 || rowData.learningItemTypeId !== 1) {
            setModalCourseToCancelData({
                approvalLevel: rowData.levelOfApproval,
                hasApproval: true,
                isDelegateBooked: isConfirmed(rowData),
                itemId: rowData.learningItemId,
                subject: `Cancellation Request – ${rowData.name}`
            });
        } else {
            setModalCourseToCancelData({
                courseId: rowData.learningItemId,
                hasApproval: false,
                itemId: rowData.learningItemId,
                subject: `Cancellation Request – ${rowData.name}`
            });
        }
    }

    const handleCloseConfirmDelete = (event) => {
        setShowConfirmDelete(false);
    };

    const handleReferenceMaterialStart = async (rowData) => {
        if (rowData.url !== "") {
            if (!isCompleted(rowData)) {
                await postCompleteTrainingPlanAndPathwayNew(useLmsStore, rowData.learningItemId);
            }
            onCloseRow(rowData.learningItemId);
            //Always open he link
            window.open(rowData.url, "_blank");
        }
    }

    const handleRemove = async (rowData) => {
        setShowConfirmDelete(true);
        setTrainingPlanId(rowData.id);
    }

    const handlePostRemove = async () => {
        postRemoveToPlan(useLmsStore, trainingPlanId)
        setShowConfirmDelete(false);
    }

    const handleEventStart = (rowData) => {
        setRefLearningItem(rowData.learningItemId);
        setRefLearningItemData(rowData);
        setisOpenEvent(true);
    }

    const handleElearningStart = async (rowData) => {
        let canHaveContinue = false;
        let canHaveNewAttempt = false;
        let pwGroupId = -1;
        let regItemId = -1;
        let tpId = -1;
        let myregId = -1;
        let progId = -1;
        let isComplete = isCompleted(rowData);
        if (rowData.regPathwayId) {
            pwGroupId = rowData.addedFromPathway;
            myregId = rowData.regPathwayId;
            regItemId = rowData.id;
            progId = rowData.learnProgId;
            if (!isComplete && rowData.elearningInfo != null && rowData.elearningInfo != undefined) { //It is not started
                canHaveContinue = true;
            }
            canHaveNewAttempt = (rowData.elearningInfo ? (rowData.elearningInfo.canHaveNewAttempt ?? true) : true);
        }
        else {
            tpId = rowData.id;
            canHaveNewAttempt = (rowData.elearningInfo ? (rowData.elearningInfo.canHaveNewAttempt ?? true) : true);
            if (!isComplete && rowData.statusName !== 'Not Started' && rowData.statusId != null && rowData.statusId != undefined) { //It is not started
                canHaveContinue = true;
            }
        }
        getElearningLinks(rowData.learningItemId, tpId, myregId, regItemId, progId).then((response) => {
            let data = responseData(response,);
            if (data) {
                if (isComplete && data.PreviewLink !== "") {
                    setIsElearningOpenId(rowData.learningItemId);
                    setIsElearningOpen(true);
                    window.open(data.previewLink, "_blank");
                }
                else {
                    //It is a start 
                    if ((data.newAttemptLink !== null) && canHaveNewAttempt && ((data.continueAttemptLink === null) || !canHaveContinue)) {
                        //It is used to check if a refresher button is required                           
                        setIsElearningOpenId(rowData.learningItemId);
                        setIsElearningOpen(true);
                        window.open(data.newAttemptLink, '_blank');
                    }
                    else {
                        if (data.continueAttemptLink !== null && canHaveContinue) {
                            if (!canHaveNewAttempt) {
                                //It is used to check if a refresher button is required
                                setIsElearningOpenId(rowData.learningItemId);
                                setIsElearningOpen(true);
                                window.open(data.continueAttemptLink, '_blank');
                            }
                            else {
                                //Open continue elearning modal
                                toggleModalElearningContinue(data.continueAttemptLink, data.newAttemptLink, canHaveNewAttempt);
                            }
                        }
                    }
                }
            }
        });
    }

    const setRowSelection = (rowData) => {
        //Add the item as selected
        setSelectedLearningItem(rowData.learningItemId);
        let index = selectedRows.findIndex((item) => item.learningItemId === rowData.learningItemId);
        if (index < 0) {
            selectedRows.push(
                { learningItemId: rowData.learningItemId, isActive: true, isOpen: false });
        }
        else {
            if (!selectedRows[index].isActive) {
                selectedRows[index].isActive = true;
                selectedRows[index].isOpen = false;
            }
        }
        setSelectedRows(selectedRows);
        return;
    }

    const setRowPwSelection = (rowData) => {
        //Add the item as selected
        updatePathwayOpen(useLmsStore, rowData.id, !rowData.isOpen);
        setTimeout(() => {
            setOpenClosePw(false);
        }, 1000);
        return;
    }

    //This expand the pathway row only when it is used by my team screen as the data is not in uselmsstore but locally
    const setRowPwSelectionTeam = (rowData) => {
        if (props.dataActiveTrainingPlan) {
            //Pathway only show in filter 1
            let transformedData = structuredClone(filteredDataS(dataActivePlan, 1, isUKPN));
            let idx = transformedData.findIndex(x => x.id === rowData.id);
            if (idx > -1) {
                transformedData[idx].isOpen = !rowData.isOpen;
                transformedData[idx].isSelected = true;
                setTableData(transformedData);
                setTimeout(() => {
                    setOpenClosePw(false);
                }, 1000);
            }
        }
        return;
    }

    const handleStart = (rowData) => {
        console.log('handleStart:', rowData.learningItemTypeId);
        //Add the item as selected
        setRowSelection(rowData);
        switch (rowData.learningItemTypeId) {
            case 2: //events
                handleEventStart(rowData);
                break;
            case 3: //eLearning
                handleElearningStart(rowData);
                break;
            case 8:
                handleReferenceMaterialStart(rowData);
                break;
            default:
                alert('You click on start default' + rowData.name);
                break;
        }
        setIsModalReviewOpen(false);
    };

    /*
     Give a warning to the user that if the want to do a new attempt
   */
    function onHandleNewAttempt() {
        if (newAttemptLink !== "") {
            setIsElearningOpenId(selectedLearningItem);
            setIsElearningOpen(true);
            setisOpenNewAttemptConfirmation(false);
            setisOpenElearningContinue(false);
            window.open(newAttemptLink, '_blank');
        }
    };

    /*
      Trigger when the user click on the elearning continue button
    */
    function onHandleContinueElearning() {
        setisOpenElearningContinue(false);
        //Set the elearnig as open so therefresh button can appear       
        setIsElearningOpenId(selectedLearningItem);
        setIsElearningOpen(true);
    };

    function toggleModalElearningContinue(continueLink, newAttemptLink, canHaveNewAttempt) {
        setContinueLink(continueLink);
        setNewAttemptLink(newAttemptLink);
        setCanHaveNewAttempt(canHaveNewAttempt);
        setisOpenElearningContinue(!isOpenElearningContinue);
    }

    function onCloseModalElearningContinue() {
        setisOpenElearningContinue(false);
        onCloseRow(selectedLearningItem);
    }

    function toggleModalNewAttemptConfirmation() {
        setisOpenNewAttemptConfirmation(!isOpenNewAttemptConfirmation);
    }

    function toggleModalElearningRefresher(learningItemId) {
        setRefLearningItem(learningItemId);
        setisOpenElearningRefresher(!isOpenElearningRefresher);
    }

    function onCloseAndRefreshModalElearningRefresher() {
        onCloseModalElearningRefresher();
        //We need to refresh the page
        window.location.reload(false);
    }

    function onCloseModalElearningRefresher() {
        setRefLearningItem(-1);
        setisOpenElearningRefresher(false);
    }

    const onCloseModalEvent = () => {
        setRefLearningItem(0);
        setisOpenEvent(false);
    }

    const onCloseModalUserEvent = () => {
        setIsOpenUserEvent(false);
        setUserEventInfo(null);
    }

    // on searchParams
    useEffect(() => {
        if (searchParams.has('search') || searchParams.has('filter')) {
            if (searchParams.has('search')) {
                searchParams.delete('search');
            }
            if (searchParams.has('filter')) {
                switch (searchParams.get("filter")) {
                    case "todo":
                        setFilterId(filterId === -1 ? 0 : -1);
                        break;
                    case "overdue":
                        setFilterId(filterId === -2 ? 0 : -2);
                        break;
                    case "mandatory":
                        setFilterId(filterId === -3 ? 0 : -3);
                        break;
                    case "upcoming":
                        setFilterId(filterId === -4 ? 0 : -4);
                        break;
                    case "awaiting dates":
                        setFilterId(filterId === -5 ? 0 : -5)
                        break;
                    case "completed":
                        setFilterId(filterId === -6 ? 0 : -6)
                        break;
                    default:
                        setFilterId(-1)
                }
                searchParams.delete('filter');
            }
            setSearchParams(searchParams);
        }
    }, [searchParams]);

    // on dataActiveTrainingPlan change
    useEffect(() => {
        if (props.dataActiveTrainingPlan.loading > 0) {
            setTableLoading(true);
        } else {
            const tableDataWithStats = getTableDataWithStats(props.dataActiveTrainingPlan.data, isUKPN);
            setToDoCount(tableDataWithStats.todo);
            setOverdueCount(tableDataWithStats.overdue);
            setMandatoryCount(tableDataWithStats.mandatory);
            setCompletedCount(tableDataWithStats.completed);
            setAwaitingCount(tableDataWithStats.awaiting);
            setUpcomingCount(tableDataWithStats.upComing);
            setDataActivePlan(tableDataWithStats.tableData);
            setTableLoading(false);
        }
    }, [props.dataActiveTrainingPlan]);

    // on tabId change
    useEffect(() => {
        if (tabId === '0' && !props.dataActiveTrainingPlan.loading > 0) {
            setCustomFilterData(filterId);
        }
        else {
            if (props.dataActiveTrainingPlan.loading > 0) {
                setTableLoading(true);
            }
            else {
                setTableLoading(false);
                setTabId('0');
            }
        }
        // eslint-disable-next-line
    }, [tabId, filterId, dataActivePlan, props.dataActiveTrainingPlan]);

    const setCustomFilterData = (filterId) => {
        if (props.dataActiveTrainingPlan.loading > 0) {
            setTableLoading(true);
        }
        else {
            setTableLoading(false);
        }
        if (searchString != undefined && searchString !== '') {
            //Show the latest complete itme first
            if (filterId == -6) {
                let completedResult = filteredDataS(dataActivePlan, filterId, isUKPN);
                completedResult = completedResult.filter(a => a.sortingDate != null).sort().reverse().concat(completedResult.filter(a => a.sortingDate == null));
                setTableData(completedResult);
            }
            else {
                //For any other tab shows the oldest first
                setTableData(filteredDataS(dataActivePlan, filterId, isUKPN));
            }
        }
        else {
            //Show the latest complete itme first
            if (filterId == -6) {
                let completedResult = filteredData(dataActivePlan, filterId, isUKPN);
                completedResult = completedResult.filter(a => a.sortingDate != null).sort().reverse().concat(completedResult.filter(a => a.sortingDate == null));
                setTableData(completedResult);
            }
            else {
                //For any other tab shows the oldest first
                setTableData(filteredData(dataActivePlan, filterId, isUKPN));
            }
        }
        if (props.dataActiveTrainingPlan.loading > 0) {
            setTableLoading(true);
        }
        else {
            setTableLoading(false);
        }
    }

    const columnsFn = (props) => {
        let columns = [
            {
                accessorKey: 'pwgrpName',
                header: 'PathWay',
                GroupedCell: ({ cell }) => <div>{cell.getValue()}</div>,
                muiTableBodyCellProps: ({ cell, table }) => {
                    return !tableLoading && cell.getIsGrouped() ? {
                        colSpan: 4,
                        sx: {
                            backgroundColor: 'var(--app-nav-primary)',
                            color: 'white',
                            display: cell.getValue() ? 'table-cell' : 'none !important',
                            fontSize: '1.25rem',
                            fontWeight: 'bold',
                            padding: '2rem 0 0 0',
                            verticalalign: 'top'
                        }
                    } :
                        {
                            sx: {
                                display: 'none'
                            }
                        }
                }
            },
            columnIconLearningItem(),
            columnAbout
        ];
        if (props.enableActions) {
            columns.push(columnAction1);
            columns.push(columnAction2);
        }
        if (props.enablePw) {
            columns.push(columnActionPw);
        }
        return columns;
    };

    const columnsFnPw = (props) => {
        let columns = [
            {
                accessorKey: 'pwgrpName',
                header: 'PathWay',
                GroupedCell: ({ cell, row }) => <div>{cell.getValue()}</div>,
                muiTableBodyCellProps: ({ cell, table }) => {
                    return !tableLoading && cell.getIsGrouped() ? {
                        colSpan: 7,
                        id: 'hide-next-td-1', // Cheat to hide the collapse button (see cheats.scss)
                        sx: {
                            backgroundColor: 'var(--app-nav-primary)',
                            color: 'white',
                            display: cell.getValue() ? 'table-cell' : 'none !important',
                            fontSize: '1.25rem',
                            fontWeight: 'bold',
                            padding: '2rem 0 0 0',
                        }
                    } :
                        {
                            sx: {
                                display: 'none'
                            }
                        }
                }
            },
            columnIconLearningItem(),
            columnAbout,
        ];
        if (props.enableActions) {
            columns.push(columnAction1);
            columns.push(columnAction2);
        }
        return columns;
    };

    const tableColumns = useMemo(() => columnsFn(props), [tableLoading],);

    const tableColumnsPw = useMemo(() => columnsFnPw(props), [tableLoading],);

    //Logic to hide the arrow that appears in the table because the enableGrouping gets set to true in material react table but
    // we do not want to have that arrow. We just query the dom directly for an element with the class in query selector and attach it to
    // the observer
    useEffect(() => {
        const hideElements = () => {
            const elements = document.querySelectorAll('[data-mui-internal-clone-element="true"]');
            elements.forEach(element => {
                element.style.display = 'none';
            });
        };
        // Initially hide elements
        hideElements();
        // Create an observer to monitor changes in the DOM
        const observer = new MutationObserver(hideElements);
        // Configure the observer to watch for changes in the subtree
        observer.observe(document.body, { childList: true, subtree: true });
        // Clean up the observer on component unmount
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (userCanceled === true && modalCourseToCancelData?.itemId) {
            const cancelItem = dataActivePlan.filter((i) => i.learningItemId == modalCourseToCancelData?.itemId);
            if (cancelItem.length > 0) {
                //if (cancelItem[0].bookingId == 0){
                postRemoveToPlanNew(useLmsStore, cancelItem[0].id).then(() => {
                    setDataActivePlan(dataActivePlan.filter((i) => i.learningItemId != modalCourseToCancelData?.itemId));
                    setModalCourseToCancelData(null);
                    // setModalCourseId(0);
                    setUserCanceled(false);
                });
                //} else {
                // if it's a booking then need to be able to cancel the booking  
                //}
            }
        }
    }, [userCanceled]);

    return (<>
        <TableContainer>
            {props.displayUserCard ? <UserCard {...props.dataUser.data} displayBackButton={true} loading={props.dataUser.loading} /> : null}
            <MaterialReactTable
                columns={tableColumns}
                data={withSelectedRows(tableData, selectedRows)}
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableGlobalFilter={true}
                enableGrouping={false}
                enableFilters={true}
                globalFilterFn={'includesString'}
                enableStickyHeader={true}
                enableHiding={false}
                enablePagination={false}
                enableSorting={false}
                enableTopToolbar={true}
                muiTableBodyRowProps={showByGroup ? { hover: false, sx: { borderBottom: '0.25rem solid var(--app-nav-primary)' } } : { hover: false }}
                muiTableContainerProps={({ table }) => showByGroup ?
                    props.dimensions.computedMuiTableContainerProps(table, {}, { backgroundColor: 'var(--app-nav-primary)' })
                    :
                    props.dimensions.computedMuiTableContainerProps(table)
                }
                muiTableHeadProps={{
                    sx: {
                        display: 'none'
                    }
                }}
                muiToolbarAlertBannerProps={{
                    sx: {
                        display: 'none'
                    }
                }}
                onGlobalFilterChange={setSearchString}
                renderTopToolbarCustomActions={({ table }) =>
                    <LearningPlanFilterTabs
                        tabId={tabId}
                        filterId={filterId}
                        todoCount={todoCount}
                        overdueCount={overdueCount}
                        mandatoryCount={mandatoryCount}
                        upComingCount={upComingCount}
                        awaitingCount={awaitingCount}
                        completedCount={completedCount}
                        setFilterId={setFilterId}
                    />
                }
                initialState={{
                    globalFilter: searchString,
                    showGlobalFilter: true,
                }}
                state={
                    {
                        isLoading: tableLoading,
                        columnVisibility: {
                            pwgrpName: false
                        },
                        globalFilter: searchString
                    }}
            />
        </TableContainer>
        <Modal fullscreen='md-down' show={showConfirmDelete} onHide={handleCloseConfirmDelete} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>Are you sure you wish to remove this item from your learning plan?</h6>
                <Button variant='primary px-3 w-25 mx-3' onClick={handlePostRemove} >Yes</Button>
                <Button variant='primary px-3 w-25 mx-3 float-right' onClick={handleCloseConfirmDelete} >No</Button>
            </Modal.Body>
        </Modal>
        <ModalElearningContinue closeFn={onCloseModalElearningContinue} continueFn={onHandleContinueElearning} newAttemptFn={toggleModalNewAttemptConfirmation} open={isOpenElearningContinue} newAttemptUrl={newAttemptLink} continueUrl={continueLink} canHaveNewAttempt={canHaveNewAttempt} />
        <ModalConfirmation closeFn={toggleModalNewAttemptConfirmation} yesFn={onHandleNewAttempt} noFn={toggleModalNewAttemptConfirmation} open={isOpenNewAttemptConfirmation} title='Confirm new attempt' description='<p>Are you sure you want to start a new attempt?</p><p>If you do this you will lose your current progress</p>' />
        <ModalElearningRefresher closeFn={onCloseModalElearningRefresher} yesFn={onCloseAndRefreshModalElearningRefresher} open={isOpenElearningRefresher} refLearningItemId={refLearningItem} />
        <ModalEvent closeFn={onCloseModalEvent} open={isOpenEvent} refLearningItemId={refLearningItem} refLearningItemData={refLearningItemData} />
        {(personSettings?.ukpnPersonId && eventVenueInfo) || (!personSettings?.ukpnPersonId && userEventInfo) ?
            <ModalUserEventInfo personSettings={personSettings} closeFn={onCloseModalUserEvent} open={isOpenUserEvent} eventVenueData={eventVenueInfo} userEventData={userEventInfo?.instructorInfo ?? null} eventName={userEventInfo?.name ?? ''} status={userEventInfo?.statusName ?? ''} />
            : <></>}
        {isModalReviewOpen && (
            <Modal show={isModalReviewOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title><b>My Learning Plan:</b> {modalData.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You've already completed this course and your result has already been recorded. <b>Review</b> is a read only view of the content.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => handleStart(modalData)}>
                        Continue to eLearning
                    </Button>
                </Modal.Footer>
            </Modal>
        )}
        <ModalConfirmation showContinue={true} closeFn={toggleModalResetElearning} yesFn={onHandleResetElearning} open={isOpenResetElearning} title='Please Note:' description='<p>By resetting the eLearning for this user, all previous attempts will still be recorded but the status of this item will return to ‘start’ and they will be able to retake the eLearning again.​</p>' />
        {openClosePw && (
            <Modal show={openClosePw} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title><b>This action will take sometime</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This action will take sometime</p>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        )
        }
        <LearningPlanCancelationModal
            data={modalCourseToCancelData}
            show={!!modalCourseToCancelData}
            handleCancelCourse={() => {
                setUserCanceled(true);
            }}
            handleCloseModal={() => {
                setModalCourseToCancelData(null);
            }}
        />
    </>);
} 
