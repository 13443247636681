import BaseContainer from "../components/BaseContainer";
import GroupDetailTab from "../components/groupManagementPageComponents/groupManagementPageTabs/GroupDetailTab";
import GroupManagementGroupSearchResult from "../components/groupManagementPageComponents/groupManagementPageSearch/GroupManagementGroupSearchResult";
import GroupManagementNavCard from "../components/groupManagementPageComponents/GroupManagementNavCard";
import GroupManagementSearch from "../components/groupManagementPageComponents/groupManagementPageSearch/GroupManagementSearch";
import GroupMembersTab from "../components/groupManagementPageComponents/groupManagementPageTabs/GroupMembersTab";
import LearningPathwaysTab from "../components/groupManagementPageComponents/groupManagementPageTabs/LearningPathwaysTab";
import PermissionsTab from "../components/groupManagementPageComponents/groupManagementPageTabs/PermissionsTab";
import usePrevious from '../hooks/usePrevious';
import { Badge, Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton, TablePagination } from "@mui/material";
import { TableRowSplit } from '../helpers/tableHelper'
import { activeTabsEnum, defaultPageSize, initialUpdateCreateGroupPayload, summaryWidgetGroupManagementNames } from "../helpers/constantsAndEnums";
import { apiAxiosCalls } from "../services/apiAxiosCalls";
import { faKey, faPeopleGroup, faRoad, faSquareList } from "@fortawesome/pro-solid-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { getSummaryWidget } from "../helpers/generalHelpers";
import { toastError } from "../hooks/data";
import { useEffect, useState } from 'react';

const pageSize = defaultPageSize

const searchGroupsInitialPayload = {
    filter: "",
    itemOffset: 0,
    itemCount: pageSize,
}
const GroupManagementPageCore = ({ dimensions }) => {
    const [activeTab, setActiveTab] = useState(activeTabsEnum.GroupDetail)
    const [groups, setGroups] = useState([])
    const [activeGroup, setActiveGroup] = useState(null)
    const [updateCreateGroupPayload, setUpdateCreateGroupPayload] = useState({ ...initialUpdateCreateGroupPayload })
    const [hasDoneInitialGroupsFetch, setHasDoneInitialGroupsFetch] = useState(false)
    const [triggerSearch, setTriggerSearch] = useState(0)
    const [searchGroupsPayload, setSearchGroupsPayload] = useState({ ...searchGroupsInitialPayload })
    const [isUpdatingOrCreatingGroup, setIsUpdatingOrCreatingGroup] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [groupsCount, setGroupsCount] = useState(0)
    const [activeGroupAndTabRequested, setActiveGroupAndTabRequested] = useState(null);
    const activeGroupAndTabRequestedPrevious = usePrevious(activeGroupAndTabRequested)
    const [requestLeave, setRequestLeave] = useState(false);

    const currentPage = searchGroupsPayload.itemOffset ? (searchGroupsPayload.itemOffset / searchGroupsPayload.itemCount) : 0

    const searchGroups = async () => {
        setIsLoading(true)
        try {
            const result = await apiAxiosCalls.searchGroupsApiCall(searchGroupsPayload)
            setHasDoneInitialGroupsFetch(true)
            setGroupsCount(result.data.Value.Count)
            setGroups([...groups, ...result.data.Value.Items])
        } catch (e) {

        }
        setIsLoading(false)
    }

    const checkIfGroupExistsAlready = async (name) => {
        const { data } = await apiAxiosCalls.CheckIfGroupExistsByName(name)
        return data.exists
    }

    const createGroup = async (payload) => {
        try {
            setIsUpdatingOrCreatingGroup(true)
            if (payload.groupId === -1) {
                const groupAlreadyExists = await checkIfGroupExistsAlready(payload.Name)
                if (groupAlreadyExists) {
                    toastError({ message: "Group with such name already exists" })
                    setIsUpdatingOrCreatingGroup(false)
                    return
                }
            }
            const result = await apiAxiosCalls.createGroupApiCall(payload)
            setIsUpdatingOrCreatingGroup(false)
            if (payload.groupId === -1) {
                await onSuccessUpdateGroup({ Id: result.id })
            } else {
                await onSuccessUpdateGroup({ ...payload, Id: payload.groupId })
            }
        } catch (e) {
            setIsUpdatingOrCreatingGroup(false)
        }
    }

    const onGroupDetailClick = () => {
        setActiveGroupAndTabRequested({
            group: activeGroup,
            tab: activeTabsEnum.GroupDetail
        });
    }

    const onLearningPathWaysClick = () => {
        setActiveGroupAndTabRequested({
            group: activeGroup,
            tab: activeTabsEnum.LearningPathways
        });
    }

    const onPermissionsClick = () => {
        setActiveGroupAndTabRequested({
            group: activeGroup,
            tab: activeTabsEnum.Permisssions
        });
    }
    
    const onGroupMembersClick = () => {
        setActiveGroupAndTabRequested({
            group: activeGroup,
            tab: activeTabsEnum.GroupMembers
        });
    }

    const CustomBadge = ({ children }) => {
        return <Badge bg="info" pill style={{ width: "3rem" }} className={"p-1"}>{children}</Badge>
    }

    const renderSkeletonsForGroups = () => {
        return Array(10).fill("x").map((x, i) => {
            return <Skeleton key={i} variant="rectangular" style={{ marginBottom: "1rem" }} width={"100%"} height={60} />
        })
    }

    const renderCards = () => {
        return <div className={"mt-1 row gap-1 p-2"}>
            <GroupManagementNavCard
                title={"Group detail"}
                onClick={onGroupDetailClick}
                icon={faSquareList}
                count={10}
                isActive={activeTab === activeTabsEnum.GroupDetail}
                bottomText={<div className={"text-end fw-bold"}></div>}
            />
            {
                activeGroup?.Id !== -1 && <>
                    <GroupManagementNavCard
                        title={"Learning pathways"}
                        onClick={onLearningPathWaysClick}
                        icon={faRoad}
                        count={10}
                        isActive={activeTab === activeTabsEnum.LearningPathways}
                        bottomText={<div className='text-end fw-bold' >
                            <CustomBadge>
                                {getSummaryWidget(activeGroup, summaryWidgetGroupManagementNames.LearningPathways)?.GroupCount || 0}
                            </CustomBadge>
                        </div>}
                    />
                    <GroupManagementNavCard
                        title={"Permissions"}
                        onClick={onPermissionsClick}
                        icon={faKey}
                        isActive={activeTab === activeTabsEnum.Permisssions}
                        bottomText={<div className='text-end fw-bold' >
                            <CustomBadge>
                                {getSummaryWidget(activeGroup, summaryWidgetGroupManagementNames.Permissions)?.GroupCount || 0}
                            </CustomBadge>
                        </div>}
                    />
                    <GroupManagementNavCard
                        title={"Group members?"}
                        onClick={onGroupMembersClick}
                        icon={faPeopleGroup}
                        isActive={activeTab === activeTabsEnum.GroupMembers}
                        bottomText={<div className='text-end fw-bold' >
                            <CustomBadge>
                                {getSummaryWidget(activeGroup, summaryWidgetGroupManagementNames.GroupMembers)?.GroupCount || 0}
                            </CustomBadge>
                        </div>}
                    />
                </>
            }

        </div>
    }

    const onGroupDetailFormChange = (e) => {
        setActiveGroup({
            ...activeGroup,
            [e.target.name]: e.target.value
        })
    }

    const activeTabToRenderTabMapper = {
        [activeTabsEnum.GroupDetail]: () => <GroupDetailTab
            group={updateCreateGroupPayload.groupId < 0 ? updateCreateGroupPayload : activeGroup}
            onChange={onGroupDetailFormChange}
            onCreate={(values) => {
                createGroup({
                    ...updateCreateGroupPayload,
                    ...values
                })
            }}
            isUpdatingOrCreatingGroup={isUpdatingOrCreatingGroup}
            isNew={activeGroup?.Id === -1}
            requestLeave={requestLeave}
            onRequestLeaveCompleted={onRequestLeaveCompleted}
        />,
        [activeTabsEnum.LearningPathways]: () => <LearningPathwaysTab
            group={activeGroup}
            onSuccessUpdateGroup={onSuccessUpdateGroup}
            onRequestLeaveCompleted={onRequestLeaveCompleted}
            requestLeave={requestLeave}
        />,
        [activeTabsEnum.Permisssions]: () => <PermissionsTab
            group={activeGroup}
            onSuccessUpdateGroup={onSuccessUpdateGroup}
            requestLeave={requestLeave}
            onRequestLeaveCompleted={onRequestLeaveCompleted}
        />,
        [activeTabsEnum.GroupMembers]: () => <GroupMembersTab
            group={activeGroup}
            onSuccessUpdateGroup={onSuccessUpdateGroup}
            requestLeave={requestLeave}
            onRequestLeaveCompleted={onRequestLeaveCompleted}
        />,
    }
    const renderActiveTab = () => {
        if (!activeTabToRenderTabMapper.hasOwnProperty(activeTab)) {
            return () => <>Tab not mapped correctly</>
        }
        return activeTabToRenderTabMapper[activeTab]
    }

    const onSingleGroupClick = (group) => {
        setActiveGroupAndTabRequested({
            group: group,
            tab: activeTabsEnum.GroupDetail
        });
    }
    const onSingleGroupIconClick = (group, activeTabType, e) => {
        e.stopPropagation()
        setActiveGroupAndTabRequested({
            group: group,
            tab: activeTabType
        });
    }

    const onSearchSubmit = async () => {
        setSearchGroupsPayload({
            ...searchGroupsInitialPayload,
            filter: searchTerm
        })
        setGroups([])
        setGroupsCount(0)
        setActiveGroup(null)
        setTriggerSearch(prev => prev + 1)
    }

    const onSearchChange = (e) => {
        setSearchTerm(e.target.value)
    }

    const onSearchClear = () => {
        if (!searchTerm) {
            return
        }
        setSearchTerm("")
        setSearchGroupsPayload({
            ...searchGroupsInitialPayload,
            filter: ""
        })
        setGroups([])
        setGroupsCount(0)
        setActiveGroup(null)
        setTriggerSearch(prev => prev + 1)
    }

    const onSuccessDeleteGroup = (deletedGroup) => {
        const filtered = groups.filter((group) => {
            return group.Id !== deletedGroup.Id
        })

        const firstGroupInList = groups.length ? groups[0] : null
        setActiveGroup(firstGroupInList)

        setGroups(filtered)
    }

    const onSuccessUpdateGroup = async (updatedGroup) => {
        const result = await apiAxiosCalls.getGroupSummaryRecords([updatedGroup.Id])
        const group = result.data.Value ? result.data.Value[0] : null
        if (!group) {
            return
        }
        const groupsCopy = JSON.parse(JSON.stringify(groups))

        const index = groupsCopy.findIndex(group => {
            return group.Id === updatedGroup.Id
        })

        if (index > -1) {
            groupsCopy[index] = group
            setGroups(groupsCopy)
            setActiveGroup(group)
            // toastSuccess("Group updated successfully")
        } else {
            groupsCopy.unshift(group)
            setGroups(groupsCopy)
            setActiveGroup(group)
            // toastSuccess("Group created successfully")
        }

    }

    const renderGroups = () => {
        return <div>
            {
                groups.map((group) => {
                    return <GroupManagementGroupSearchResult
                        key={group.Id}
                        onSuccessDeleteGroup={onSuccessDeleteGroup}
                        isActive={activeGroup?.Id === group?.Id}
                        group={group} onClick={onSingleGroupClick}
                        onSingleGroupIconClick={onSingleGroupIconClick}
                    />
                })
            }
            {isLoading && <div className={"w-100 d-flex justify-content-center align-items-center"}>
                <div className="spinner-border text-primary" style={{ width: '5rem', height: '5rem' }} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
        </div>
    }

    const onCreateGroupClick = () => {
        setActiveTab(activeTabsEnum.GroupDetail)
        setUpdateCreateGroupPayload({ ...initialUpdateCreateGroupPayload })
        setActiveGroup(
            {
                Description: "",
                Id: -1,
                Name: "",
                SummaryWidgets: []
            }
        )
    }

    const renderCreateNewGroupButton = () => {
        return <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
            overlay={
                <Tooltip className='cls-theme-tooltip'>Add</Tooltip>
            }
        >
            <Button variant='clear' className='pt-0 pb-0' onClick={() => onCreateGroupClick()}>
                <FontAwesomeIcon size='xl' icon={faPlus} className='text-primary' />
            </Button>
        </OverlayTrigger>

    }

    useEffect(() => {
        if (!hasDoneInitialGroupsFetch) {
            searchGroups()
        }
    }, [searchGroupsPayload])

    useEffect(() => {
        if (groups.length && !activeGroup) {
            setActiveGroup(groups[0])
        }
    }, [groups])

    useEffect(() => {
        if (triggerSearch) {
            searchGroups()
        }
    }, [triggerSearch])

    useEffect(() => {
        setUpdateCreateGroupPayload({
            ...initialUpdateCreateGroupPayload,
            groupId: activeGroup?.Id || -1,
            Description: activeGroup?.Description || "",
            Name: activeGroup?.Name || ""
        })
    }, [activeGroup])

    useEffect(() => {
        if (activeGroupAndTabRequested) {
            if (!hasDoneInitialGroupsFetch || (!groups.length & isLoading)) {
                // Form not initialised so nothing to check.
                onRequestLeaveCompleted(true);
            } else {
                // console.dir(activeGroupAndTabRequested);
                const selectionChanged = !activeGroupAndTabRequestedPrevious || activeGroupAndTabRequested?.group?.Id != activeGroupAndTabRequestedPrevious?.group?.Id || activeGroupAndTabRequested?.tab != activeGroupAndTabRequestedPrevious?.tab;
                // console.log(`${activeGroupAndTabRequested?.group?.Id} != ${activeGroupAndTabRequestedPrevious?.group?.Id} || ${activeGroupAndTabRequested?.tab} != ${activeGroupAndTabRequestedPrevious?.tab}`);
                // console.log(`${!requestLeave} && ${selectionChanged}`);

                if (!requestLeave && selectionChanged) {
                    setRequestLeave(true);
                    // console.log('setRequestLeave');
                }
            }
        }
    }, [requestLeave, activeGroupAndTabRequested, activeGroupAndTabRequestedPrevious]);

    const onRequestLeaveCompleted = (doLeaveFlag) => {
        // console.log('doLeaveFlagg', doLeaveFlag);
        if (doLeaveFlag) {
            // Accept new selection
            setActiveGroup(activeGroupAndTabRequested.group)
            setActiveTab(activeGroupAndTabRequested.tab)
        } else {
            // Reject new selection. Ensure that rowSelectionRequestedPrevious is reset.
            setActiveGroupAndTabRequested(null);
        }
        if (requestLeave) {
            // The onRequestLeaveCompleted sequence was triggered by requestLeave so reset flag.
            setRequestLeave(false);
        }
    };

    return (
        <TableRowSplit
            leftPane={<Container fluid className='h-100 d-flex flex-column overflow-hidden'>
                <div className={"w-100 mb-4 mt-4 d-flex align-items-center justify-content-end py-2"}>
                    <div className={"w-80"}>
                        <GroupManagementSearch onSearchSubmit={onSearchSubmit} value={searchTerm} onSearchClear={onSearchClear} onChange={onSearchChange} />
                    </div>
                    <div className={"w-20"}>
                        {renderCreateNewGroupButton()}
                    </div>
                </div>
                <Container fluid className='flex-grow-1 overflow-auto'>
                    {(!hasDoneInitialGroupsFetch || (!groups.length & isLoading)) ? renderSkeletonsForGroups() : renderGroups()}
                </Container>
                {hasDoneInitialGroupsFetch && groups.length ? <TablePagination
                    component='div'
                    sx={{
                        borderBottom: "none",
                        padding: '0 !important',
                        margin: '0 !important'
                    }}
                    classes={{
                        displayedRows: 'tablepagination-displayedRows',
                        root: 'tablepagination-root',
                        selectLabel: 'tablepagination-selectLabel',
                        toolbar: 'tablepagination-toolbar'
                    }}
                    showFirstButton
                    showLastButton
                    disabled={isLoading}
                    onPageChange={(_, page) => {
                        setSearchGroupsPayload({
                            ...searchGroupsPayload,
                            itemOffset: page * searchGroupsPayload.itemCount
                        })
                        setGroups([])
                        setActiveGroup(null)
                        setTriggerSearch(triggerSearch + 1)
                    }}
                    onRowsPerPageChange={(e) => {
                        setSearchGroupsPayload({
                            ...searchGroupsPayload,
                            itemCount: e.target.value
                        })
                        setGroups([])
                        setActiveGroup(null)
                        setTriggerSearch(prev => prev + 1)
                    }}
                    page={currentPage}
                    count={groupsCount}
                    rowsPerPage={searchGroupsPayload.itemCount}
                    rowsPerPageOptions={[defaultPageSize, 20, 30, 40, 50]}
                /> : ''}
            </Container>}
            rightPane={<Container fluid className='h-100 overflow-auto'>
                {renderCards()}
                {renderActiveTab()()}
            </Container>}
        />
    );
};

const GroupManagementPage = () => {
    return (<BaseContainer title='Group Management'>
        <GroupManagementPageCore />
    </BaseContainer>)
};

export default GroupManagementPage;
