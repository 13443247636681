import About from '../pages/About';
import AccessCardDashboard from '../pages/AccessCardDashboard';
import Administration from '../pages/Administration';
import Alerts from '../pages/Alerts';
import AllUserRegisteredInterest from '../pages/AllUserRegisteredInterest';
import AllUsersElearningProgress from '../pages/AllUsersElearningProgress';
import ApplicationSettings from '../pages/ApplicationSettings';
import AuthError from '../auth/AuthError';
import AuthLogin from '../auth/AuthLogin';
import BookingEvents from '../pages/BookingEvents';
import CatalogueManagement from '../pages/catalogue-management/catalogue-management';
import Catalogues from '../pages/Catalogues';
import ChangePassword from '../pages/ChangePassword';
import ClientTeamProfiles from '../pages/ClientTeamProfiles';
import Companies from '../pages/Companies';
import CompetenciesAccordian from '../pages/CompetenciesAccordian';
import CompetencyManagement from '../pages/competency-management/competency-management';
import CourseLibrary from '../pages/CourseLibrary';
import CreateUsersPage from '../pages/CreateUsersPage';
import Dashboard from '../pages/Dashboard';
import EntraAuth from '../auth/EntraAuth';
import Experiment from '../pages/_Experiment';
import FAQs from '../pages/FAQs';
import GroupManagementPage from '../pages/GroupManagementPage';
import Invitation from '../pages/Invitation';
import LearningItemsPage from '../pages/LearningItemsPage';
import LearningPlan from '../pages/LearningPlan';
import Login from '../pages/Login';
import LoginClassic from '../auth/LoginClassic';
import LoginContractor from '../auth/ukpn/LoginContractor';
import MandatoryTrainingManagement from '../pages/my-team-mandatory-training/mandatory-training-management';
import MyAdminDashboard from '../pages/MyAdminDashboard';
import MyLearningPlan from '../pages/MyLearningPlan';
import MyLearningPlanOld from '../pages/MyLearningPlanOld';
import MySuperAdminDashboard from '../pages/MySuperAdminDashboard';
import MyTeamElearningProgress from '../pages/MyTeamElearningProgress';
import MyTeamProfiles from '../pages/MyTeamProfiles';
import MyTeamTrainingHistory from '../pages/my-team-training-history/MyTeamTrainingHistory';
import News from '../pages/News';
import NewsFeedItems from '../pages/NewsFeedItems';
import NotFound from '../pages/NotFound';
import OutActions from '../pages/Outstanding';
import Pathways from '../pages/Pathways';
import ProtectedLayout from './ProtectedLayout';
import Qualifications from '../pages/Qualifications';
import RegisterContractor from '../auth/ukpn/RegisterContractor';
import Registrations from '../pages/Registrations';
import Reports from '../pages/Reports';
import Saml from '../auth/Saml';
import SamlAuth from '../auth/SamlAuth';
import ServerError from '../pages/ServerError';
import TeamBookingEvents from '../pages/TeamBookingEvents';
import TeamDashboard from '../pages/TeamDashboard';
import TeamQualifications from '../pages/TeamQualifications';
import TeamRegistrations from '../pages/TeamRegistrations';
import UKPNServiceAdminDashboard from '../pages/UKPNServiceAdminDashboard';
import UpcomingEvents from '../pages/upcoming-events/upcomingEvents';
import UserAlerts from '../pages/UserAlerts';
import UserDocument from '../pages/team-profile-management/components/user-document';
import UserDocuments from '../pages/team-profile-management/components/user-documents';
import UserLearningPlan from '../pages/UserLearningPlan';
import UserLearningPlanOld from '../pages/UserLearningPlanOld';
import UserProfile from '../pages/team-profile-management/components/user-profile';
import Users from '../pages/Users';
import { createBrowserRouter, Navigate, RouterProvider, Route, Routes } from 'react-router-dom';

const Root = () => {
    return (
        <Routes>
            {
                (process.env.REACT_APP_SERVER === 'live' || process.env.REACT_APP_SERVER === 'test') ?
                    window.location.hostname.split('.')[0] === 'principality' ?
                        <Route path='/' element={<SamlAuth />} />
                        :
                        <Route path='/' element={<Navigate to='/lms/dashboard' replace={true} />} />
                    :
                    <Route path='/' element={<Navigate to='/lms/dashboard' replace={true} />} />
            }
            {/* Legacy URLs */}
            <Route path='/admindashboard' element={<Navigate to='/lms/administration' replace={true} />} />
            <Route path='/administration' element={<Navigate to='/lms/administration' replace={true} />} />
            <Route path='/adminreports' element={<Navigate to='/lms/reports' replace={true} />} />
            <Route path='/alerts' element={<Navigate to='/lms/alerts' replace={true} />} />
            <Route path='/applicationsettings' element={<Navigate to='/lms/applicationsettings' replace={true} />} />
            <Route path='/catalogue-management' element={<Navigate to='/lms/catalogue-management' replace={true} />} />
            <Route path='/catalogues' element={<Navigate to='/lms/catalogues' replace={true} />} />
            <Route path='/clientdashboard' element={<Navigate to='/lms/myadmindashboard' replace={true} />} />
            <Route path='/clientteamprofiles' element={<Navigate to='/lms/clientteamprofiles' replace={true} />} />
            <Route path='/companies' element={<Navigate to='/lms/companies' replace={true} />} />
            <Route path='/competency-management' element={<Navigate to='/lms/competency-management' replace={true} />} />
            <Route path='/courselibrary' element={<Navigate to='/lms/courselibrary' replace={true} />} />
            <Route path='/dashboard' element={<Navigate to='/lms/dashboard' replace={true} />} />
            <Route path='/faqs' element={<Navigate to='/lms/faqs' replace={true} />} />
            <Route path='/learningplan' element={<Navigate to='/lms/learningplan' replace={true} />} />
            <Route path='/library' element={<Navigate to='/lms/courselibrary' replace={true} />} />
            <Route path='/lms/autherror' element={<AuthError />} />
            <Route path='/lms/servererror' element={<ServerError />} />
            <Route path='/lms/support' element={<Navigate to='/lms/faqs' replace={true} />} />
            <Route path='/manager' element={<Navigate to='/lms/teamdashboard' replace={true} />} />
            <Route path='/myadmindashboard' element={<Navigate to='/lms/myadmindashboard' replace={true} />} />
            <Route path='/mylearningplan' element={<Navigate to='/lms/mylearningplan' replace={true} />} />
            <Route path='/myteamprofiles' element={<Navigate to='/lms/myteamprofiles' replace={true} />} />
            <Route path='/myteamreports' element={<Navigate to='/lms/reports' replace={true} />} />
            <Route path='/news' element={<Navigate to='/lms/news' replace={true} />} />
            <Route path='/newsfeeditems' element={<Navigate to='/lms/newsfeeditems' replace={true} />} />
            <Route path='/reports' element={<Navigate to='/lms/reports' replace={true} />} />
            <Route path='/support' element={<Navigate to='/lms/faqs' replace={true} />} />
            <Route path='/teamalerts' element={<Navigate to='/lms/alerts' replace={true} />} />
            <Route path='/teamdashboard' element={<Navigate to='/lms/teamdashboard' replace={true} />} />
            <Route path='/training' element={<Navigate to='/lms/mylearningplan' replace={true} />} />
            <Route path='/ukpnserviceadmindashboard' element={<Navigate to='/lms/ukpnserviceadmindashboard' replace={true} />} />
            <Route path='/upcomingEvents' element={<Navigate to='/lms/upcomingEvents' replace={true} />} />
            <Route path='/users' element={<Navigate to='/lms/users' replace={true} />} />
            <Route path='/lms' element={<ProtectedLayout />}>
                <Route path='about' element={<About />} />
                <Route path='accesscarddashboard' element={<AccessCardDashboard />} />
                <Route path='administration' element={<Administration />} />
                <Route path='alerts' element={<Alerts />} />
                <Route path='allUserRegisteredInterest' element={<AllUserRegisteredInterest />} />
                <Route path='allUsersElearningProgress' element={<AllUsersElearningProgress />} />
                <Route path='applicationsettings' element={<ApplicationSettings />} />
                <Route path='bookingEvents' element={<BookingEvents />} />
                <Route path='catalogue-management' element={<CatalogueManagement />} />
                <Route path='catalogues' element={<Catalogues />} />
                <Route path='clientteamprofiles' element={<ClientTeamProfiles />} />
                <Route path='companies' element={<Companies />} />
                <Route path='competenciesaccordian' element={<CompetenciesAccordian />} />
                <Route path='competency-management' element={<CompetencyManagement />} />
                <Route path='courselibrary' element={<CourseLibrary />} />
                <Route path='createUsers' element={<CreateUsersPage />} />
                <Route path='dashboard' element={<Dashboard />} />
                <Route path='experiment' element={<Experiment />} />
                <Route path='faqs' element={<FAQs />} />
                <Route path='groupManagement' element={<GroupManagementPage />} />
                <Route path='learningItems' element={<LearningItemsPage />} />
                <Route path='learningplan' element={<LearningPlan />} />
                <Route path='myTeamElearningProgress' element={<MyTeamElearningProgress />} />
                <Route path='myTeamTraining/:id' element={<MandatoryTrainingManagement />} />
                <Route path='myTeamTrainingHistory' element={<MyTeamTrainingHistory />} />
                <Route path='myTeamQualifications' element={<TeamQualifications />} />
                <Route path='myadmindashboard' element={<MyAdminDashboard />} />
                <Route path='mylearningplan' element={<MyLearningPlan />} />
                <Route path='mylearningplanold' element={<MyLearningPlanOld />} />
                <Route path='mysuperadmindashboard' element={<MySuperAdminDashboard />} />
                <Route path='myteamprofiles' element={<MyTeamProfiles />} />
                <Route path='news' element={<News />} />
                <Route path='newsfeeditems' element={<NewsFeedItems />} />
                <Route path='outstanding' element={<OutActions />} />
                <Route path='outactions' element={<OutActions />} />
                <Route path='pathways' element={<Pathways />} />
                <Route path='qualifications' element={<Qualifications />} />
                <Route path='registrations' element={<Registrations />} />
                <Route path='reports' element={<Reports />} />
                <Route path='teamBookingEvents' element={<TeamBookingEvents />} />
                <Route path='teamRegistrations' element={<TeamRegistrations />} />
                <Route path='teamdashboard' element={<TeamDashboard />} />
                <Route path='ukpnserviceadmindashboard' element={<UKPNServiceAdminDashboard />} />
                <Route path='upcomingEvents' element={<UpcomingEvents />} />
                <Route path='user-profile' element={<UserProfile />} />
                <Route path='useralerts' element={<UserAlerts />} />
                <Route path='userdocument/:id' element={<UserDocument />} />
                <Route path='userdocuments' element={<UserDocuments />} />
                <Route path='userlearningplan' element={<UserLearningPlan />} />
                <Route path='userlearningplanold' element={<UserLearningPlanOld />} />
                <Route path='users' element={<Users />} />
                {process.env.REACT_APP_RNEXT === 'true' ? <Route path='experiment' element={<Experiment />} /> : null}
            </Route>
            <Route path='/changePassword/:verificationKey' element={<ChangePassword />} />
            <Route path='/invitation/:verificationKey' element={<Invitation />} />
            <Route path='/login' element={<Login />} />
            <Route path='/login-classic' element={<LoginClassic />} />
            <Route path='/authLogin' element={<AuthLogin />} />
            <Route path='/contractor-login' element={<LoginContractor />} />
            <Route path='/contractor-registration' element={<RegisterContractor />} />
            <Route path='/entraauth' element={<EntraAuth />} />
            <Route path='/saml' element={<Saml />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    )
}

// Router singleton created
const router = createBrowserRouter([
    { path: '*', element: <Root /> },
]);

// RouterProvider added
export default function AppRoutes() {
    return <RouterProvider router={router} />;
}
