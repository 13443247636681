
export default function CustomRadioGroupList(props) {
    return (
        <div className="pt-2 pb-2">
            <span id='leftDiv' className="m-2">
                {props.title ? props.title : 'Search By'}:
            </span>
            <span key={`inline-radio`}>
                {props.options.map((option) => (
                    <div className="form-check form-check-inline" key={option.id}>
                        <input
                            name={option.name}
                            type="radio"
                            id={`inline-radio-${option.id}`}
                            className="form-check-input"
                            value={option.value}
                            defaultChecked={option.defaultCheck}
                            onChange={(event) => { props.onchange(event) }}
                        />
                        <label htmlFor={`inline-radio-${option.id}`} className="form-check-label">{option.label}</label>
                    </div>
                ))}
            </span>
        </div>
    );
}