import { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Row,
  Col,
  Cell,
  Form,
  InputGroup,
  Button,
  Modal,
} from "react-bootstrap";
import RedAsterisk from "../../helpers/RedAsterisk";
import MaterialReactTable from "material-react-table";
import { Container, Alert, Tab, Tabs } from "react-bootstrap";
import useLmsStore, {
  responseData,
  getPathWayAdmin,
  postSavePathwayAdmin,
  FORMCONFIRM,
  toastError,
  toastSuccess,
} from "../../hooks/useLmsStore";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TableContainer } from "../../helpers/tableHelper";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import ModalLearningItemsSelect from "../ModalLearningItemsSelect";
import { faLoveseat } from "@fortawesome/pro-light-svg-icons";
import {
  CatchingPokemonSharp,
  PanoramaWideAngleSelectSharp,
} from "@mui/icons-material";

//const TabLearningPathwayDetail = ({ submitFn = (obj) => null, hasChangedFn = () => null, closeFn = (obj) => null, companyDetails }) => {
const TabLearningPathwayDetail = ({
  pathway,
  allLearningItems,
  savePathwayFn = (obj) => null,
  hasChangedPwFn = (obj) => null,
}) => {
  const [pathwayInfo, setPathwayInfo] = useState(null);
  const [pathwayInfoOri, setPathwayInfoOri] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [infoLoading, setinfoLoading] = useState(false);
  const [editingItem, setEditingItem] = useState(false);
  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState({});
  const [rowSelectionM, setRowSelectionM] = useState({});
  const [currentIdx, setCurrentIdx] = useState(0);
  const [moduleToDelete, setModuleToDelete] = useState({});
  const [itemToDelete, setItemToDelete] = useState({});
  const [items, setItems] = useState([]);
  const [allPathwayItems, setAllPathwayItems] = useState([]);
  const [isDirtyAndValid, setIsDirtyAndValid] = useState(false);
  const [showModalLearningItems, setShowModalLearningItems] = useState(false);
  const [showConfirmModuleDeleteModal, setShowConfirmModuleDeleteModal] =
    useState(false);
  const [showConfirmItemDeleteModal, setShowConfirmItemDeleteModal] =
    useState(false);
  const [apply, setApply] = useState(false);
  const [validated, setValidated] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const setValueInForm = (event) => {
    let data = pathwayInfo;

    data[event.target.id] = event.target.value;

    setPathwayInfo(data);
    handleIsDirtyAndValid();
  };

  const handleIsDirtyAndValid = () => {
    let isDirtyAndValidCopy = isDirtyAndValid;
    let isDirty = false;
    let isValid = true;

    isDirty = isDirtyForm();
    hasChangedPwFn({ isDirty: isDirty, pathway: pathwayInfo });

    isValid = isValidForm();

    isDirtyAndValidCopy = isDirty && isValid;
    setIsDirtyAndValid(isDirtyAndValidCopy);
  };

  const isDirtyForm = () => {
    let isDirty = false;

    if (pathwayInfo && pathwayInfoOri) {
      if (
        pathwayInfoOri.name != pathwayInfo.name ||
        pathwayInfoOri.isActive != pathwayInfo.isActive ||
        pathwayInfoOri.areProgrammesSequenced !=
          pathwayInfo.areProgrammesSequenced
      ) {
        return true;
      }

      if (
        pathwayInfoOri.listOfUpdateLearningProgramme.length !=
        pathwayInfo.listOfUpdateLearningProgramme.length
      ) {
        return true;
      }

      let counter = 0;

      for (const modUp of pathwayInfo.listOfUpdateLearningProgramme) {
        if (
          modUp.name !=
            pathwayInfoOri.listOfUpdateLearningProgramme[counter].name ||
          modUp.isDeleted !=
            pathwayInfoOri.listOfUpdateLearningProgramme[counter].isDeleted
        ) {
          isDirty = true;
          break;
        }

        if (
          modUp.listOfLearningProgrammeItems.length !=
          pathwayInfoOri.listOfUpdateLearningProgramme[counter]
            .listOfLearningProgrammeItems.length
        ) {
          return true;
        }

        if (
          modUp.listOfLearningProgrammeItems.filter(
            (a) => a.learningItemJoinId < 0
          ).length > 0
        ) {
          return true;
        }

        if (
          modUp.listOfLearningProgrammeItems.filter((a) => a.isDeleted).length >
          0
        ) {
          return true;
        }

        for (let i = 0; i < modUp.listOfLearningProgrammeItems.length; i++) {
          if (
            modUp.listOfLearningProgrammeItems[i].itemName !=
            pathwayInfoOri.listOfUpdateLearningProgramme[counter]
              .listOfLearningProgrammeItems[i].itemName
          ) {
            return true;
          }
        }

        counter++;
      }
    }

    return isDirty;
  };

  const isValidForm = () => {
    let isValid = true;

    if (pathwayInfo?.name === "") {
      return false;
    }

    let progs = pathwayInfo?.listOfUpdateLearningProgramme.filter(
      (a) => a.isDeleted === false
    );

    if (progs.length === 0) {
      return false;
    }

    let progEmpty = pathwayInfo?.listOfUpdateLearningProgramme.filter(
      (a) => a.name === "" && a.isDeleted === false
    );

    if (progEmpty.length > 0) {
      return false;
    }

    let counter = 0;
    let counterI = 0;

    for (const mod of pathwayInfo.listOfUpdateLearningProgramme) {
      if (
        mod.listOfLearningProgrammeItems.filter((a) => a.isDeleted === false)
          .length === 0
      ) {
        isValid = false;
        break;
      }

      for (const item of mod.listOfLearningProgrammeItems) {
        if (item.name === "" && item.isDeleted === false) {
          isValid = false;

          break;
        }

        counterI++;
      }
      counter++;
    }

    return isValid;
  };

  const handleSwitchChange = (event) => {
    let data = pathwayInfo;
    data[event.target.id] = event.target.checked;

    setPathwayInfo(data);

    handleIsDirtyAndValid();
  };

  const updateData = (data) => {
    let allItemsCopy = [];
    if (data) {
      let copy = structuredClone(data);
      setPathwayInfo(data);
      setPathwayInfoOri(copy);

      if (data.listOfUpdateLearningProgramme) {
        setModules(data.listOfUpdateLearningProgramme);

        if (data.listOfUpdateLearningProgramme.length > 0) {
          let moduleCopy = structuredClone(
            data.listOfUpdateLearningProgramme[0]
          );
          setSelectedModule(moduleCopy);
          setItems(
            data.listOfUpdateLearningProgramme[0].listOfLearningProgrammeItems
          );
          setRowSelectionM(
            data.listOfUpdateLearningProgramme[0].learningProgrammeJoinId
          );
        }
      }
      if (data.listOfUpdateLearningProgramme) {
        data.listOfUpdateLearningProgramme.map((prog) => {
          if (prog.listOfLearningProgrammeItems)
            allItemsCopy = allItemsCopy.concat(
              prog.listOfLearningProgrammeItems
            );
        });
      }
    }

    setAllPathwayItems(allItemsCopy);
  };

  const handleNewPathway = () => {
    setIsDirtyAndValid(false);
    setItems([]);
    setModules([]);
    setAllPathwayItems([]);

    setPathwayInfo({
      name: "",
      isActive: false,
      areProgrammesSequenced: false,
      learningProgrammeGroupId: -1,
      listOfUpdateLearningProgramme: [],
    });

    setPathwayInfoOri({
      name: "",
      isActive: false,
      areProgrammesSequenced: false,
      learningProgrammeGroupId: -1,
      listOfUpdateLearningProgramme: [],
    });

    let form = document.getElementById("pathway-form");

    if (form) {
      form.reset();
    }

    setinfoLoading(false);
  };

  const handleFormLoad = (forceLoad) => {
    let allItemsCopy = [];
    setinfoLoading(true);

    if (!pathwayInfo || forceLoad) {
      if (pathway.learningProgrammeGroupId > 0) {
        getPathWayAdmin(pathway.learningProgrammeGroupId).then((response) => {
          let data = responseData(response, []);

          if (data.value) {
            updateData(data.value);
          }

          setinfoLoading(false);
        });
      } else {
        handleNewPathway();
      }
    } else {
      handleNewPathway();
    }
  };

  const handleSaveModuleCell = async (cell, value) => {
    let pathwayInfoCopy = structuredClone(pathwayInfo);
    let modulesCopy = modules;
    modulesCopy[cell.row.index][cell.column.id] = value;

    pathwayInfoCopy.listOfUpdateLearningProgramme = modulesCopy;
    setModules([...modulesCopy]);

    setPathwayInfo(pathwayInfoCopy);

    setSelectedModule(modulesCopy[cell.row.index]);

    handleIsDirtyAndValid();
  };

  const toggleModalLearningItems = () => {
    setShowModalLearningItems(!showModalLearningItems);
  };

  const addLearningItemsFn = (obj) => {
    let pathwayInfoCopy = pathwayInfo;

    let found = items.find((a) => a.learningItemId == obj[0].learningItemId);

    if (!found) {
      let newItems = items.concat(obj);
      let selectedModuleCopy = structuredClone(selectedModule);

      let allPathwayItemsCopy = allPathwayItems;

      allPathwayItemsCopy = allPathwayItemsCopy.concat(obj);

      if (selectedModuleCopy) {
        selectedModuleCopy.listOfLearningProgrammeItems = newItems;
        setSelectedModule(selectedModuleCopy);
      }

      setAllPathwayItems(allPathwayItemsCopy);
      setItems([...newItems]);

      if (
        pathwayInfoCopy != null &&
        pathwayInfoCopy.listOfUpdateLearningProgramme &&
        selectedModule
      ) {
        let idx = pathwayInfoCopy.listOfUpdateLearningProgramme.findIndex(
          (item) =>
            item.learningProgrammeJoinId ===
            selectedModule.learningProgrammeJoinId
        );

        if (idx >= 0) {
          pathwayInfoCopy.listOfUpdateLearningProgramme[idx] =
            selectedModuleCopy;

          setModules([...pathwayInfoCopy.listOfUpdateLearningProgramme]);

          setPathwayInfo(pathwayInfoCopy);
        }
      }

      handleIsDirtyAndValid();
      toggleModalLearningItems();
    }
  };

  /*------------------------*/
  /* Call for react table to add a new row to the table
    /*-------------------------*/
  const handleModuleAdd = () => {
    var pathwayCopy = pathwayInfo; //structuredClone(pathwayInfo);
    let modulesCopy = modules;
    let newMod = {
      isDeleted: false,
      listOfLearningProgrammeItems: [],
      learningProgrammeJoinId: -1 * (modules.length + 1),
      name: "Double-click to rename!",
    };
    modulesCopy.push(newMod);
    setModules([...modulesCopy]);
    setSelectedModule(newMod);

    pathwayCopy.listOfUpdateLearningProgramme = modulesCopy;

    setPathwayInfo(pathwayCopy);
    const newItems = [];
    setItems([...newItems]);

    handleIsDirtyAndValid();
  };

  const handleModuleDelete = useCallback((row) => {
    setModuleToDelete(row);
    setShowConfirmModuleDeleteModal(true);
  }, []);

  const handleItemDelete = useCallback((row) => {
    setItemToDelete(row);
    setShowConfirmItemDeleteModal(true);
  }, []);

  const handleItemsAdd = () => {
    setShowModalLearningItems(true);
  };

  useEffect(() => {
    if (pathway && pathway.learningProgrammeGroupId > 0) {
      handleFormLoad(true);
    } else {
      handleFormLoad(false);
    }
  }, [pathway]);

  useEffect(() => {
    let keys = Object.keys(rowSelectionM);
    if (keys.length === 1) {
      let id = parseInt(keys[0]);

      if (modules) {
        let moduleCopy = modules.find(
          (x) =>
            x.learningProgrammeJoinId ===
            pathwayInfo.listOfUpdateLearningProgramme[id]
              .learningProgrammeJoinId
        );

        if (moduleCopy) {
          setSelectedModule(
            modules.find(
              (x) =>
                x.learningProgrammeJoinId ===
                pathwayInfo.listOfUpdateLearningProgramme[id]
                  .learningProgrammeJoinId
            )
          );
          setItems(
            pathwayInfo.listOfUpdateLearningProgramme[id]
              .listOfLearningProgrammeItems
          );
        }
      }
    }
  }, [rowSelectionM]);

  const columns = [
    {
      header: "Module Title",
      accessor: "name",
      accessorKey: "name",
      muiEditTextFieldProps: {
        required: true,
        error: !!validationErrors?.name,
        helperText: validationErrors?.name,
        //remove any previous validation errors when user focuses on the input
        onFocus: () =>
          setValidationErrors({
            ...validationErrors,
            name: undefined,
          }),
        //optionally add validation checking for onBlur or onChange
      },
    },
    {
      accessorKey: "learningProgrammeJoinId",
      header: "Actions",
      muiTableBodyCellProps: {
        align: "center",
        sx: {
          textOverflow: "unset",
        },
      },
      size: 50,
      enableEditing: false,
      Cell: ({ cell, row }) => {
        return (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="cls-theme-tooltip">Delete module</Tooltip>
              }
            >
              <span>
                <Button
                  variant="contained"
                  onClick={() => handleModuleDelete(row.original)}
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={faTrash}
                    className="text-primary"
                  />
                </Button>
              </span>
            </OverlayTrigger>
          </>
        );
      },
    },
  ];

  const columnsItem = [
    {
      header: "Title",
      accessor: "itemName",
      accessorKey: "itemName",
    },
    {
      accessorKey: "learningItemJoinId",
      header: "Actions",
      muiTableBodyCellProps: {
        align: "center",
        sx: {
          textOverflow: "unset",
        },
      },
      size: 50,
      enableEditing: false,
      Cell: ({ cell, row }) => {
        return (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="cls-theme-tooltip">
                  Delete item from module {selectedModule?.name}
                </Tooltip>
              }
            >
              <span>
                <Button
                  variant="contained"
                  onClick={() => handleItemDelete(row.original)}
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={faTrash}
                    className="text-primary"
                  />
                </Button>
              </span>
            </OverlayTrigger>
          </>
        );
      },
    },
  ];

  const confirmDeleteModule = () => {
    let modulesCopy = modules;
    if (moduleToDelete) {
      setApply(true);

      const index = modulesCopy.findIndex(
        (item) =>
          item.learningProgrammeJoinId ===
          moduleToDelete.learningProgrammeJoinId
      );
      if (index !== -1) {
        modulesCopy[index].isDeleted = true;
        setModules([...modulesCopy]);
        setItems([]);
        setModuleToDelete({});
        handleIsDirtyAndValid();
      }

      setShowConfirmModuleDeleteModal(false);
    }
  };

  const cancelDeleteModule = () => {
    setShowConfirmModuleDeleteModal(false);
    setModuleToDelete({});
  };

  const confirmDeleteItem = () => {
    let itemsCopy = items;
    let allPathwayItemsCopy = allPathwayItems;
    if (itemToDelete) {
      setApply(true);

      const index = itemsCopy.findIndex(
        (item) => item.learningItemJoinId === itemToDelete.learningItemJoinId
      );
      if (index !== -1) {
        itemsCopy[index].isDeleted = true;
        setItems([...itemsCopy]);
        setItemToDelete({});
        handleIsDirtyAndValid();
      }

      var index2 = allPathwayItemsCopy.findIndex(
        (item) => item.learningItemJoinId === itemToDelete.learningItemJoinId
      );
      if (index2 !== -1) {
        allPathwayItemsCopy.splice(index2, 1);
        setAllPathwayItems([...allPathwayItemsCopy]);
      }

      setShowConfirmItemDeleteModal(false);
    }
  };

  const cancelDeleteItem = () => {
    setShowConfirmItemDeleteModal(false);
    setItemToDelete({});
  };

  const handleCancelEdit = () => {
    setEditingItem(null);
  };

  const handleAddItem = () => {
    setEditingItem({ id: new Date().getTime(), name: "" });
  };

  const handleEditChange = (e) => {
    setEditingItem((prevItem) => ({
      ...prevItem,
      name: e.target.value,
    }));
  };

  const handleSaveItem = () => {
    if (editingItem.name.trim() !== "") {
      setItems((prevItems) => [...prevItems, editingItem]);
      setEditingItem(null);
      handleIsDirtyAndValid();
    }
  };

  const savePathway = async (event) => {
    await postSavePathwayAdmin(useLmsStore, pathwayInfo).then((data) => {
      if (data) {
        if (data) {
          updateData(data);
        }
        setIsDirtyAndValid(false);
        setSaveLoading(false);
        if (
          pathwayInfo.name != pathwayInfoOri.name ||
          pathwayInfo.isActive != pathwayInfoOri.isActive
        ) {
          savePathwayFn(data);
        }
        hasChangedPwFn({
          isDirty: false,

          pathway: data,
        });
      }
    });
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    setSaveLoading(true);
    if (!saveLoading && pathwayInfo) {
      savePathway(event);
    }
    setValidated(true);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCancel = async (event) => {
    setinfoLoading(true);
    handleFormLoad(true);
    setinfoLoading(false);
    setIsDirtyAndValid(false);
    hasChangedPwFn({ isDirty: false, pathway: pathway });
    let form = document.getElementById("pathway-form");

    if (form) {
      form.reset();
    }
  };

  return infoLoading ? (
    <>
      <div className="d-flex justify-content-center">
        <div
          className="spinner-border mt-5 text-primary"
          style={{ width: "5rem", height: "5rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </>
  ) : (
    <>
      <Container fluid>
        {pathwayInfo ? (
          <>
            <Form
              id="pathway-form"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <Row>
                <Col sm={12}>
                  <Row>
                    <Col sm={10}>
                      <Form.Group className="mb-3" controlId="name">
                        <Form.Label>
                          Name
                          <RedAsterisk />
                        </Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="input"
                            placeholder="Pathway name"
                            defaultValue={
                              pathwayInfo?.name ? pathwayInfo?.name : ""
                            }
                            className="no-border"
                            onChange={(e) => setValueInForm(e)}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide pathway name.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group className="mb-3" controlId="isActive">
                        <Form.Check
                          type="switch"
                          onChange={handleSwitchChange}
                          label="Active"
                          disabled={pathwayInfo?.hasActiveBooking}
                          defaultChecked={pathwayInfo?.isActive}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12}>
                      <Form.Label>
                        Are modules in the Learning Pathway sequenced?
                      </Form.Label>
                      <Form.Group
                        className="mb-3"
                        controlId="areProgrammesSequenced"
                      >
                        <Form.Check
                          type="switch"
                          onChange={handleSwitchChange}
                          label=""
                          defaultChecked={pathwayInfo?.areProgrammesSequenced}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col sm={6}>
                  <Row>
                    <h6>
                      Module Title <RedAsterisk />
                    </h6>
                    <div className="borderDiv">
                      {modules ? (
                        <TableContainer>
                          <MaterialReactTable
                            columns={columns}
                            autoResetPageIndex={false}
                            data={modules.filter((a) => a.isDeleted !== true)}
                            enableRowOrdering
                            enableSorting={true}
                            editingMode="cell"
                            enableEditing={true}
                            enablePagination={
                              modules.length > 10 ? true : false
                            }
                            initialState={{
                              pagination: { pageIndex: 0, pageSize: 10 },
                              showGlobalFilter: true,
                            }}
                            muiTableBodyRowProps={({ row }) => ({
                              onClick: () => {
                                setCurrentIdx(row.id)
                                setRowSelectionM((prev) => ({
                                  [row.id]: !prev[row.id],
                                }));
                              },
                              hover: true,
                              selected: rowSelectionM[row.id],
                              sx: {
                                cursor: "pointer",
                              },
                            })}
                            muiSearchTextFieldProps={{
                              placeholder: `search assigned`,
                            }}
                            muiTableBodyCellEditTextFieldProps={({ cell }) => ({
                              //onBlur is more efficient, but could use onChange instead
                              onBlur: (event) => {
                                handleSaveModuleCell(cell, event.target.value);
                              },
                              variant: "outlined",
                            })}
                            renderToolbarInternalActions={({ table }) => (
                              <>
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip className="cls-theme-tooltip">
                                      Add Module
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="clear"
                                    className="pt-0 pb-0"
                                    onClick={() => handleModuleAdd()}
                                  >
                                    Add
                                  </Button>
                                </OverlayTrigger>
                              </>
                            )}
                            muiTableBodyRowDragHandleProps={({ table }) => ({
                              onDragEnd: () => {
                                const { draggingRow, hoveredRow } =
                                  table.getState();
                                if (hoveredRow && draggingRow) {
                                  modules.splice(
                                    hoveredRow.index,
                                    0,
                                    modules.splice(draggingRow.index, 1)[0]
                                  );

                                  handleIsDirtyAndValid();
                                  setModules([...modules]);
                                }
                              },
                            })}
                            muiTableHeadProps={{
                              sx: {
                                display: "none",
                              },
                            }}
                            muiToolbarAlertBannerProps={{
                              sx: {
                                display: "none",
                              },
                            }}
                          />
                        </TableContainer>
                      ) : (
                        <></>
                      )}
                      <ul className="custom-list">
                        {editingItem && (
                          <li>
                            <input
                              type="text"
                              value={editingItem.name}
                              onChange={handleEditChange}
                              placeholder="Module name"
                              muiTableHeadProps={{
                                sx: {
                                  display: "none",
                                },
                              }}
                            />
                            <button onClick={handleSaveItem}>Save</button>
                            <button onClick={handleCancelEdit}>Cancel</button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </Row>
                </Col>
                <Col sm={6}>
                  <Row>
                    <h6>
                      Learning Items for Selected Module <RedAsterisk />
                    </h6>
                    <div className="borderDiv">
                      {selectedModule && selectedModule.name != "" ? (
                        <TableContainer>
                          <MaterialReactTable
                            columns={columnsItem}
                            data={items.filter((a) => a.isDeleted !== true)}
                            enableRowOrdering
                            enableSorting={true}
                            enableEditing={false}
                            enablePagination={items.length > 10 ? true : false}
                            initialState={{
                              pagination: { pageIndex: 0, pageSize: 10 },
                              showGlobalFilter: true,
                            }}
                            muiTableBodyCellEditTextFieldProps={({ cell }) => ({
                              //onBlur is more efficient, but could use onChange instead
                              onBlur: (event) => {
                                handleSaveModuleCell(cell, event.target.value);
                              },
                              variant: "outlined",
                            })}
                            muiSearchTextFieldProps={{
                              placeholder: `search assigned`,
                              /*sx: { minWidth: '300px' },
                      variant: 'outlined', */
                            }}
                            renderToolbarInternalActions={({ table }) =>
                              modules.filter((m) => !m.isDeleted).length > 0 &&
                              selectedModule &&
                              selectedModule.name != "" ? (
                                <>
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Tooltip className="cls-theme-tooltip">
                                        Add learning items
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="clear"
                                      className="pt-0 pb-0"
                                      onClick={() => handleItemsAdd()}
                                    >
                                      Add
                                    </Button>
                                  </OverlayTrigger>
                                </>
                              ) : (
                                <></>
                              )
                            }
                            muiTableBodyRowDragHandleProps={({ table }) => ({
                              onDragEnd: () => {
                                const { draggingRow, hoveredRow } =
                                  table.getState();
                                if (hoveredRow && draggingRow) {
                                  pathwayInfo?.listOfUpdateLearningProgramme[currentIdx].listOfLearningProgrammeItems.splice(
                                    hoveredRow.index,
                                    0,
                                    pathwayInfo?.listOfUpdateLearningProgramme[currentIdx].listOfLearningProgrammeItems.splice(draggingRow.index, 1)[0]
                                  );
                                  items.splice(
                                    hoveredRow.index,
                                    0,
                                    items.splice(draggingRow.index, 1)[0]
                                  );
                                  
                                  setItems([...items]);
                                  handleIsDirtyAndValid();
                                }
                              },
                            })}
                            muiTableHeadProps={{
                              sx: {
                                display: "none",
                              },
                            }}
                            muiToolbarAlertBannerProps={{
                              sx: {
                                display: "none",
                              },
                            }}
                          />
                        </TableContainer>
                      ) : (
                        <>
                          <div>Select a module</div>
                        </>
                      )}
                    </div>
                  </Row>
                </Col>
              </Row>
              <Button
                variant="primary"
                className="mt-2 float-right"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              {saveLoading ? (
                <Button
                  variant="primary"
                  disabled
                  className="mx-2 mt-2 float-right"
                >
                  <span
                    className="spinner-border spinner-border-sm mt-1"
                    style={{ marginRight: "0.25rem" }}
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Please wait...
                </Button>
              ) : (
                <>
                  <Button
                    variant="primary"
                    className="mx-2 mt-2 float-right"
                    type="submit"
                    disabled={!isDirtyAndValid}
                  >
                    Save
                  </Button>
                </>
              )}
            </Form>
          </>
        ) : (
          <></>
        )}
      </Container>
      <Modal show={showConfirmModuleDeleteModal} onHide={cancelDeleteModule}>
        <Modal.Header closeButton>
          <Modal.Title>
            {moduleToDelete.hasActiveBooking
              ? "Deletion warinnig"
              : "Confirm Delete"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {moduleToDelete.hasActiveBooking ? (
            <div>
              {" "}
              There are active bookings for this item. You must cancel the
              booking before you can delete this module from the pathway '
              {pathway?.name}'?
            </div>
          ) : (
            <>
              {moduleToDelete.hasActiveItems ? (
                <div>
                  Warning: The are active learning for this module. Are you sure
                  you want to delete this module from the pathway '
                  {pathway?.name}'?
                </div>
              ) : (
                <div>
                  Are you sure you want to delete this module from the pathway '
                  {pathway?.name}'?
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {moduleToDelete.hasActiveBooking ? (
            <Button variant="secondary" onClick={cancelDeleteModule}>
              Cancel
            </Button>
          ) : (
            <>
              <Button variant="secondary" onClick={cancelDeleteModule}>
                Cancel
              </Button>
              <Button variant="primary" onClick={confirmDeleteModule}>
                Confirm
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmItemDeleteModal} onHide={cancelDeleteItem}>
        <Modal.Header closeButton>
          <Modal.Title>
            {itemToDelete.hasActiveBooking
              ? "Deletion warinnig"
              : "Confirm Delete"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {itemToDelete.hasActiveBooking ? (
            <div>
              {" "}
              There are active bookings for this item. You must cancel the
              booking before you can delete the item from module '
              {selectedModule?.name}'?
            </div>
          ) : (
            <>
              {itemToDelete.hasActiveItems ? (
                <div>
                  Warning: The are active learning for this item. Are you sure
                  you want to delete this item from the module '
                  {selectedModule?.name}'?
                </div>
              ) : (
                <div>
                  Are you sure you want to delete this item from the module '
                  {selectedModule?.name}'?
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {itemToDelete.hasActiveBooking ? (
            <Button variant="secondary" onClick={cancelDeleteItem}>
              Ok
            </Button>
          ) : (
            <>
              <Button variant="secondary" onClick={cancelDeleteItem}>
                Cancel
              </Button>
              <Button variant="primary" onClick={confirmDeleteItem}>
                Confirm
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <ModalLearningItemsSelect
        id={selectedModule ? selectedModule?.learningProgrammeJoinId : -1}
        allLearningItems={allLearningItems}
        selectedData={allPathwayItems}
        closeFn={toggleModalLearningItems}
        open={showModalLearningItems}
        submitFn={(obj) => addLearningItemsFn(obj)}
      ></ModalLearningItemsSelect>
    </>
  );
};

export default TabLearningPathwayDetail;
