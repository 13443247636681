import { Col, Row } from 'react-bootstrap';

export default function BaseBeginContainer({ children }) {
    return (
        <Row className='flex-grow-1 mb-2 overflow-auto'>
            <Col className='d-flex align-content-start flex-wrap' style={{ margin: '-1rem 0 0 -1rem' }}>
                {children}
            </Col>
        </Row>
    );
}
