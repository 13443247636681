import locationConfig from '../location-config.json';
import themes from '../styling/_themes.json';
import { mergeDeep } from '../helpers/mergeHelper'
import { useEffect, useState } from 'react';

const isObject = (obj) => obj && typeof obj === 'object' && !Array.isArray(obj);

const isLocationObject = (key) => key && isObject(locationConfig[key]);

const getDefaultLocationObjectKey = (key) => !key ? 'lms' : key.includes('uat') ? 'lms.uat' : key.includes('dev') ? 'lms.dev' : key.includes('test') ? 'lms.test' : 'lms';

/*
    Format of location-config.json:

    The location-url is the domain name (window.location.hostname) without the '.wdr.co.uk'.
    
    "location-url": {
        "base": "Optional: string key. The key of the object to use as the base configuration.",
        "override":{
            "themeName": "lms"
        },
        "authB2C": { // Optional: B2C configuration
            
            "authority": "Required: string. B2C authority",
            "clientId": "Required: string. B2C client id",
            "knownAuthority": "Required: string. B2C known authority",
            "scope": "Required: string. B2C scope",
            "userFlowPasswordReset": "Required: string. user flow for password reset",
            "userFlowSignIn": "Required: string. B2C authority"
        },
        "themeName": "Required: string constant. The theme to apply. See _themes.json for constants in use.",
        "legacy": {
            "rpcall": "Required: string. The reverse proxy url.",
            "through": "Required: string. The 'pass through' url.",
            "admin": "Required: string. The admin url."
        }

    },

*/

export const getLocationConfigFromDNSKey = (keyFromDNS) => {
    let key = keyFromDNS;
    let baseObject = {}, overrideObject = {};
    // console.log('newKey', newKey);
    if (!isLocationObject(key)) {
        console.warn(`Location configuration for '${key}' not found.`);
        key = getDefaultLocationObjectKey(key);
        console.warn(`Using '${key}' as default.`);
    }
    if (isObject(locationConfig[key].override)) {
        overrideObject = mergeDeep({}, locationConfig[key].override);
        // console.log('overrideObject', overrideObject);
    }
    if (typeof locationConfig[key].base === 'string') {
        if (isLocationObject(locationConfig[key].base)) {
            baseObject = mergeDeep({}, locationConfig[locationConfig[key].base]);
            // console.log('baseObject', baseObject);
            key = locationConfig[key].base;
            // console.log('newKey', newKey);
        } else {
            console.warn(`Location configuration defined in 'locationConfig[${key}].base' as '${locationConfig[key].base}' for '${window.location.hostname}' not found.`);
            key = getDefaultLocationObjectKey(key);
            console.warn(`Using '${key}' as default.`);
            baseObject = locationConfig[key];
            // console.log('baseObject', baseObject);
        }
    } else {
        baseObject = locationConfig[key];
        // console.log('baseObject', baseObject);
    }
    let cfg = mergeDeep(baseObject, overrideObject);
    cfg.key = key;
    cfg.getRpCallUrl = (urlMethod) => `${cfg.legacy.rpcall}${urlMethod}`
    if (themes[cfg.themeName] && typeof themes[cfg.themeName] === 'object') {
        cfg.theme = mergeDeep({}, themes.default, themes[cfg.themeName]);
    } else {
        cfg.theme = themes.default;
    }
    return cfg;
};

const useLocationConfig = () => {
    const [key, setKey] = useState(null);
    const [config, setConfig] = useState(null);

    const setLocationConfiguration = () => {
        const keyFromDNS = window.location.hostname.split(':')[0].replace('.wdr.co.uk', '').replaceAll('/', '');
        if (key !== keyFromDNS) {
            const cfg = getLocationConfigFromDNSKey(keyFromDNS);
            setKey(keyFromDNS);
            setConfig(cfg);
        }
    };

    useEffect(() => {
        setLocationConfiguration();
    }, []);

    return { ...config };
};

export default useLocationConfig;