import { useState } from 'react';
import PathwayCard from './PathwayCard';
import UserCard from '../UserCard';
import LearningPathwayEditorUserTable from './LearningPathwayEditorUserTable';
import DataLeftRight from '../DataLeftRight';

export default function LearningPathwayEditorUser(props) {

    return (<>  
         <PathwayCard {...props.pathway} /> 
        <UserCard {...props.dataSelected} defaultMessage={<h5>Please select a User</h5>} />
        <DataLeftRight>
            <LearningPathwayEditorUserTable {...props} />
        </DataLeftRight>
    </>);
}