import BaseContainer from '../components/BaseContainer';
import MaterialReactTable from 'material-react-table';
import shallow from 'zustand/shallow';
import useLmsStore from "../hooks/useLmsStore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareRss } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useMemo } from 'react';

const getExtension = (path) => /(?:\.([^.]+))?$/.exec(path)[1];

const NewsCore = ({ dimensions }) => {
    const { computedMuiTableContainerProps } = dimensions;

    const { allNews, myLatestPersonNews, fetchAllNews, fetchMyLatestPersonNews } = useLmsStore(state =>
    ({
        allNews: state.allNews.data,
        fetchAllNews: state.fetchAllNews,
        myLatestPersonNews: state.myLatestPersonNews.data,
        fetchMyLatestPersonNews: state.fetchMyLatestPersonNews,
    }), shallow)

    // on load
    useEffect(() => {
        fetchAllNews();
        fetchMyLatestPersonNews();
    }, [fetchAllNews, fetchMyLatestPersonNews]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'newsId',
                header: 'Id',
                muiTableBodyCellProps: {
                    align: 'center',
                    sx: {
                        textOverflow: 'unset'
                    }
                },
                size: 75,
                Cell: ({ cell, row }) => {
                    return (<FontAwesomeIcon icon={faSquareRss} className='text-info' size='lg' />)
                }
            },
            {
                accessorKey: 'title',
                header: 'Title',
                muiTableBodyCellProps: {
                    classes: { root: 'fw-unset' }   // Flexible width
                },
                Cell: ({ cell, row }) => {
                    const isNew = myLatestPersonNews && myLatestPersonNews.length >= 0 && myLatestPersonNews.some(news => news.id === row.original.id);
                    return (
                        <>
                            <h5>{cell.getValue()} <span dangerouslySetInnerHTML={{ __html: (isNew ? '<span class="badge badge-pill badge-primary" style="background-color: #F8AC5C; color: black;">NEW</span>' : '') }} /></h5>
                            <span dangerouslySetInnerHTML={{ __html: row.original.description + (row.original.link ? '<br/><a href="' + row.original.link + (getExtension(row.original.link) ? '" target="_blank' : '') + '">' + row.original.linkDescription + '</a><br/>' : '') + (row.original.addedPeriod || '') }} />
                        </>
                    );
                }
            }
        ],
        [],
    );

    return (
        <MaterialReactTable
            columns={columns}
            data={allNews || []}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={false}
            enableStickyHeader={true}
            enableSorting={false}
            enableBottomToolbar={false}
            enableTopToolbar={false}
            muiTableBodyRowProps={{ hover: false }}
            muiTableContainerProps={({ table }) => computedMuiTableContainerProps(table)}
            muiTableHeadProps={{
                sx: {
                    display: 'none'
                }
            }}
            muiTableProps={{
                sx: {
                    tableLayout: 'fixed',
                },
            }}
            positionActionsColumn="last"
            state={{
                isLoading: !allNews
            }}
        />
    );
};

export default function News(props) {
    return (
        <BaseContainer title='News'>
            <NewsCore />
        </BaseContainer>
    );
}
