import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

/*
*     style={{background: isActive ? "var(--app-nav-primary-link)" : "var(--app-topbar-bg)"}}
* */
const GroupManagementNavCard = ({title, icon, count, onClick = () => null, bottomText = null, isActive = false}) => {
    const getContainerStyle = () => {
        const style = {
            border: "1.5px solid ",
            borderColor: "rgba(148,144,144,0.53)",
            background:"white"
        }

        if (isActive) {
            style.background = "var(--app-bg-filter-active)"
            style.borderColor = "rgba(var(--bs-primary-rgb)"
        } else {

        }

        return style
    }

    return <div
        onClick={onClick}
        role={"button"}
        style={getContainerStyle()}
        className={"d-flex  justify-content-between align-items-center p-2 cursor-pointer text-white col-sm hover-darken  custom-time-animation rounded"}
    >
        <div style={{marginRight: "0.2rem"}} className={"d-flex align-items-center text-secondary"}>
            <FontAwesomeIcon style={{marginRight: "0.5rem", color: isActive ? "rgba(var(--bs-primary-rgb)" : "" }} size='lg' icon={icon} className='fa-fw '/>
            <div style={{color: isActive ? "rgba(var(--bs-primary-rgb)" : "" }} className={"d-flex align-items-center  "}  >{title}</div>
        </div>


        {bottomText}

    </div>
}

export default GroupManagementNavCard;
