import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";

export default function AuthError() {
    const [searchParams] = useSearchParams();
    const [error, setError] = useState('500');

    useEffect(() => setError(searchParams.get("error") || ''), [searchParams]);

    return (
        <Container style={{ height: '100vh' }}>
            <Row style={{ padding: '4rem 0' }} className='justify-content-center'>
                <Col md='auto'>
                    <p style={{ fontSize: '3rem', fontWeight: 400 }}><span className='text-danger'>Access denied</span><br />We are unable to authenticate you.<br />Please contact your administrator if the issue persists.</p>
                    <p>
                        Details: <i>{error === '401' ? 'This account is set as inactive.' : error}</i>
                    </p>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col md='auto'>
                    <Button href='/'>Home</Button>
                </Col>
            </Row>
        </Container>
    );
}