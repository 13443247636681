import React, { useMemo, useState } from 'react';
import { apiAxiosCalls } from "../../services/apiAxiosCalls";
import MaterialReactTable from "material-react-table";
import Checkbox from "@mui/material/Checkbox";
import { Button, Spinner } from "react-bootstrap";
import { toastSuccess } from "../../hooks/data";

const OutActionsCompetencyRewarded = ({
    dimensions,
    tableData,
    loading,
    setTableData,
}) => {

    const [toApprove, setToApprove] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)


    const approveCompetencies = async () => {
        try {
            setIsSubmitting(true)
            await apiAxiosCalls.SaveCompetencyApprovalDetailsMultiple(toApprove)
            setTableData(tableData.filter(td => !toApprove.map(x => x.personId * 1).includes(td.personId * 1)))
            setToApprove([])
            toastSuccess("Training request approved successfully")
        } catch (e) {
            console.log(e)
            toastSuccess("An error occurred while approving requests")
        }
        setIsSubmitting(false)


    }

    const onCheckBoxChange = (row) => {
        const inArray = toApprove.findIndex(x => x.employeeId == row.employeeId) > -1

        if (inArray) {
            setToApprove(toApprove.filter(x => x.employeeId !== row.employeeId))
        } else {
            setToApprove([...toApprove, {
                employeeId: row.employeeId.toString(),
                personId: row.personId.toString(),
            }])
        }
    }

    const renderCheckBox = (row) => {
        const checked = toApprove.findIndex(x => x.employeeId == row.employeeId) > -1
        return <Checkbox
            onChange={() => {
                onCheckBoxChange(row)
            }}
            checked={checked}
        />
    }

    const columns = useMemo(() => [
        {
            accessorKey: 'fullName',
            header: 'Employee',
            size: 200,
            Cell: ({ row }) => {
                const original = row.original;
                return (
                    <>
                        {original.firstName} {original.surname}
                        <br />
                        {original.employeeId}
                    </>
                );
            }
        },
        {
            accessorKey: 'qualificationsGroupName',
            header: 'Group',
            size: 150,
        },
        {
            accessorKey: 'qualificationsName',
            header: 'Qualification',
            size: 300,
        },
        {
            accessorKey: 'profDesc',
            header: 'Proficiency',
            size: 150,
        },
        {
            accessorKey: 'validFrom',
            header: 'Valid From',
            size: 150,
        },
        {
            accessorKey: 'validTo',
            header: 'Valid To',
            size: 150,
        },
        {
            accessorKey: 'employeeid',
            header: 'Approve',
            size: 200,
            Cell: ({ row }) => renderCheckBox(row.original)
        },
    ], [toApprove.length, tableData, tableData.length]);

    return (
        <div>
            <MaterialReactTable
                columns={columns}
                data={tableData}
                enableExpanding={false}
                enableBottomToolbar={true}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableGrouping={false}
                enableHiding={false}
                enablePagination={true}
                enableSorting={false}
                enableTopToolbar={false}
                muiTableBodyRowProps={{ hover: false }}
                muiTableProps={{
                    sx: {
                        tableLayout: 'fixed',
                    },
                }}
                muiTableContainerProps={({ table }) => dimensions.computedMuiTableContainerProps(table, null, null, 64)}
                positionActionsColumn="last"
                initialState={{
                    pagination: { pageIndex: 0, pageSize: 10 },
                    showGlobalFilter: true,
                    columnVisibility: {
                        employeeId: false
                    }
                }}
                state={{
                    isLoading: loading
                }}
            />
            <div className={"d-flex justify-content-end mt-3"}>
                <Button
                    type="button"
                    variant="primary"
                    style={{ marginRight: "1rem" }}
                    className={"d-flex align-items-center"}
                    disabled={isSubmitting || !toApprove.length}
                    onClick={() => {
                        approveCompetencies()
                    }}
                >
                    {isSubmitting && <><Spinner animation={"border"} size={"sm"} /> &nbsp;</>}
                    Submit
                </Button>
                <Button
                    type="button"
                    onClick={() => {
                        setToApprove([])
                    }}
                    variant="primary"
                    className={"d-flex align-items-center"}
                    disabled={isSubmitting || !toApprove.length}
                >
                    {isSubmitting && <><Spinner animation={"border"} size={"sm"} /> &nbsp;</>}
                    Cancel
                </Button>
            </div>
        </div>
    );
};

export default OutActionsCompetencyRewarded;
