import React, {useEffect, useState} from 'react';
import GenericSubTabContainer from "../../common/GenericSubTabContainer";
import SingleSection from "./SingleSection";
import useLmsStore, {toastError, toastSuccess} from "../../../hooks/useLmsStore";
import {apiAxiosCalls} from "../../../services/apiAxiosCalls";
import {Button, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-light-svg-icons";
import SynopsisModal from "./SynopsisModal";
import {faQuestionCircle} from "@fortawesome/pro-solid-svg-icons";
import SynopsisPreviewModal from "./SynopsisPreviewModal";
import {Skeleton} from "@mui/material";

const sectionTemplate = {
    Content: "",
    Id: 0,
    IsVisible: 1,
    Name: "",
    Title: "",
}

const LearningItemSynopsisTab = () => {
    const [sections, setSections] = useState([])
    const [isLoadingSections, setIsLoadingSections] = useState(false)
    const [isUpdatingSections, setIsUpdatingSections] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
    const [sectionsInitial, setSectionsInitial] = useState([])
    const [triggerRerender, setTriggerRerender] = useState(0)
    const {learningItemsState} = useLmsStore((state) => {
        return {
            learningItemsState: state.learningItemsState
        }
    })

    const areButtonsDisabled = isLoadingSections || isUpdatingSections

    const {
        activeLearningItemDetail,
        loadingActiveLearningDetail,
        learningItems
    } = learningItemsState;

    const onSynopsisContentChange = (value, index) => {

        const sectionsCopy = JSON.parse(JSON.stringify(sections))

        sectionsCopy[index].Content = value

        setSections(sectionsCopy)
    }

    const onAddNewSection = () => {
        setSections([...sections, sectionTemplate])
    }

    const onDeleteSection = (indexFromComponent) => {
        setSections(sections.filter((section, index) => {
            return index !== indexFromComponent
        }))
    }

    const onSaveLearningItem = async () => {
        try {
            setIsUpdatingSections(true)
            const {data} = await apiAxiosCalls.saveLearningItemSynopsis({
                LearningItemId: activeLearningItemDetail.Id,
                Sections: sections,
                Title: ""
            })

            if (!data.IsValid) {
                throw  new Error("Error saving sections")
            }

            setSections(data.Value.Sections)
            setSectionsInitial(JSON.parse(JSON.stringify(data.Value.Sections)))
            setTriggerRerender(prev => prev + 1)
            toastSuccess("Synopsis updated successfully")

        } catch (e) {
            toastError({message: "An error has occured"})
        }
        setIsUpdatingSections(false)
    }

    const onTitleChange = (e, index) => {
        const sectionsCopy = JSON.parse(JSON.stringify(sections))

        sectionsCopy[index].Title = e.target.value

        setSections(sectionsCopy)
    }

    const onCheckBoxChange = (index) => {
        setSections(sections.map((section, i) => {
            if (index === i) {
                section.IsVisible = +!section.IsVisible
                return section
            }
            return section
        }))
    }

    const renderSections = () => {
        return <div key={triggerRerender}>
            {sections.map((section, i) => {
                return <SingleSection
                    onDeleteSection={onDeleteSection}
                    onChange={onSynopsisContentChange}
                    onTitleChange={onTitleChange}
                    onCheckBoxChange={onCheckBoxChange}
                    areButtonsDisabled={areButtonsDisabled}
                    key={`${i}${section.Name}${triggerRerender}`}
                    index={i}
                    section={section}
                />
            })}
        </div>
    }

    const renderSpinnerForButtons = () => {
        return areButtonsDisabled ? <Spinner size={"sm"} animation={"border"} variant={"light"}/> : <></>
    }

    const renderModalQustionMark = () => {
        return <div className={"fw-bold"}>
            Please click below to add the synopsis for this learning item
            &nbsp;
            <Button
                onClick={() => {
                    setIsModalOpen(true)
                }}
                variant={"light"}
            >
                <FontAwesomeIcon icon={faQuestionCircle}/>
            </Button>
        </div>
    }

    const getSections = async () => {
        try {
            setIsLoadingSections(true)
            const {data} = await apiAxiosCalls.getLearningItemSynopsis(activeLearningItemDetail.Id)
            if (!data.IsValid) {
                throw new Error("Error getting synopsis sections")
            }
            setSections(data.Value.Sections)
            setSectionsInitial(JSON.parse(JSON.stringify(data.Value.Sections)))
            setTriggerRerender(prev => prev + 1)
        } catch (e) {

        }
        setIsLoadingSections(false)

    }

    const renderSkeleton = () => {
        return <>
            {[1,2,3,4,5].map(item=> {
                return <Skeleton key={item} variant="rectangular" sx={{marginTop:"1rem"}} height={60} />
            } )}
        </>
    }

    const renderSectionsOrSkeleton = () => {
        return isLoadingSections ? renderSkeleton() : renderSections()
    }

    useEffect(() => {
        getSections()
    }, [])

    return (
        <div className={"p-1"} style={{maxHeight: "600px", overflowY: "auto"}}>
            {renderModalQustionMark()}
            <SynopsisModal isModalOpen={isModalOpen} closeModal={() => {
                setIsModalOpen(false)
            }}/>
            <SynopsisPreviewModal sections={sections} isModalOpen={isPreviewModalOpen} closeModal={() => {
                setIsPreviewModalOpen(false)
            }}/>
            <GenericSubTabContainer title={'Synopsis'}>

                {renderSectionsOrSkeleton()}

            </GenericSubTabContainer>
            <div className={"d-flex  mt-2"}>
                <Button
                    disabled={areButtonsDisabled}
                    onClick={() => {
                        onAddNewSection()
                    }}
                >
                    Add new section <FontAwesomeIcon icon={faPlus}/> {renderSpinnerForButtons()}
                </Button>

                <Button
                    className={"d-block mx-2"}
                    variant={"light"}
                    disabled={areButtonsDisabled}
                    onClick={() => {
                        setIsPreviewModalOpen(true)
                    }}
                >
                    Preview {renderSpinnerForButtons()}
                </Button>

            </div>
            <div className={"d-flex justify-content-between mt-2"}>
                <Button
                    disabled={areButtonsDisabled}
                    onClick={() => {
                        onSaveLearningItem()
                    }}
                >
                    Save {renderSpinnerForButtons()}
                </Button>
                <Button
                    onClick={() => {
                        setSections(JSON.parse(JSON.stringify(sectionsInitial)))
                        setTriggerRerender(prev => prev + 1)
                    }}
                    disabled={areButtonsDisabled}
                    variant={"light"}
                >
                    Cancel {renderSpinnerForButtons()}
                </Button>
            </div>
        </div>


    );
};

export default LearningItemSynopsisTab;
