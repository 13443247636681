import Card from "react-bootstrap/Card";
import imgGrey266x140 from '../assets/imgs/img-grey-1584x834.png';
import { checkThatImageExists } from '../helpers/imgs';
import { useEffect, useState } from 'react'

export default function DashboardCarouselCard(props) {
    const [image, setImage] = useState(imgGrey266x140)
    useEffect(() => {
        checkThatImageExists(props.rowData.imageUrl).then((exist) => {
            setImage(exist ? props.rowData.imageUrl : imgGrey266x140);
        })
    }, [props.rowData.imageUrl])

    return (
        <Card className='border-0 m-0'>
            <Card.Header as="h5">{props.rowData.name}</Card.Header>
            <Card.Img variant="middle" src={image} />
            <Card.Body>
                <Card.Text>{(props.rowData.description || '').trim()}</Card.Text>
            </Card.Body>
        </Card>
    );
}