import CourseTypeFilter from "../components/learningItemsPageComponents/CourseTypeFilter"

export const activeTabsEnum = {
    GroupDetail: "GroupDetail",
    LearningPathways: "LearningPathways",
    Permisssions: "Permisssions",
    GroupMembers: "GroupMembers",
}


export  const summaryWidgetGroupManagementNames =  {
    LearningPathways: "Training Cards",
    GroupMembers: "Group Members",
    Permissions: "Permissions",
    GroupDetails: "Group Details",
}

export  const learningItemsTabEnum = {
    Detail: "Detail",
    ELearning: "ELearning",
    Images: "Images",
    Synopsis: "Synopsis",
    Assessments: "Assessments",
}

export  const learningItemsCardNames = {
    [learningItemsTabEnum.Detail]: "Learning item Detail",
    [learningItemsTabEnum.ELearning]: "eLearning",
    [learningItemsTabEnum.Images]: "Images",
    [learningItemsTabEnum.Synopsis]: "Synopsis",
    [learningItemsTabEnum.Assessments]: "Assessments",
}

export  const learningItemTypesIdsEnum = {
    Course: 2,
    Elearning: 3,
    Video: 4,
    Audio: 5,
    ManualIntervention: 6,
    ReferenceMaterial: 8
}




export const initialUpdateCreateGroupPayload = {
    groupId: -1,
    Name: "",
    Description: "",
    Reports: [],
    GroupMembers: [],
    TrainingCards: [],
    Permissions: [],
    LearningItems: [],
    Groups: []
}


export const defaultPageSize = 10

export const searchGroupsInitialPayload = {
    filter: "", itemOffset: 0, itemCount: defaultPageSize,
}

export const searchLearningItemsInitialPayload = {
    search: "", itemOffset: 0, itemCount: defaultPageSize,
    filter: {
        courseType: 0,
        deliveryMethod: 0,
        developmentType: 0,
    }
}
