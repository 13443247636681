import axios from 'axios';
import create from "zustand";
import produce from 'immer';
import { devtools } from 'zustand/middleware'
import { getValidToken } from "../helpers/validateHelper";
import { getLocationConfigFromDNSKey } from '../hooks/useLocationConfig'
import { responseData, fetchFn, postDataPromisegetRpCallUrl, toastError } from '../hooks/useLmsStore';

const store = (set, get) => ({
    applicationRegistrationDetail: { data: undefined },
    applicationReports: { data: undefined },
    searchGroups: { data: undefined },
    locationConfig: null,
    getLocationConfig: () => {
        const keyFromDNS = window.location.hostname.split(':')[0].replace('.wdr.co.uk', '').replaceAll('/', '');
        let cfg = get().locationConfig;
        if (!cfg || cfg.key !== keyFromDNS) {
            cfg = getLocationConfigFromDNSKey(keyFromDNS);
            set(produce((state) => {
                state.locationConfig = cfg;
            }));
        }
        return cfg;
    },
    fetchApplicationRegistrationDetail: () => {
        return fetchFn(set, get, 'applicationRegistrationDetail', get().getLocationConfig().getRpCallUrl('/api/application/getApplicationRegistrationDetail/10'));
    }

});

export const postSearchApplicationReports = postDataPromisegetRpCallUrl('/api/application/searchApplicationReports',
    (store, postData, response) => {
        if (store.getState().applicationReports) {
            if (!(store.getState().applicationReports.loading > 0)) {
                try {

                    let data = responseData(response);
                    console.log('data', data);
                    return true;
                } catch (err) {
                    toastError(err);
                    return false;
                }
            }
        }
        return true;
    },
    (store, postData) => false
);

export const postSearchGroups = postDataPromisegetRpCallUrl('/api/groupManagement/searchlms',
    (store, postData, response) => {
        if (store.getState().searchGroups) {
            if (!(store.getState().searchGroups.loading > 0)) {
                try {

                    let data = responseData(response);
                    console.log('data', data);
                    return true;
                } catch (err) {
                    toastError(err);
                    return false;
                }
            }
        }
        return true;
    },
    (store, postData) => false
);

const useLegacyStore = process.env.NODE_ENV === 'development' ? create(devtools(store)) : create(store);

export default useLegacyStore;

if (process.env.NODE_ENV === 'development') {
    Object.defineProperty(window, 'legacyStore', {
        get() {
            return useLegacyStore.getState();
        },
    });
}
