import { useEffect } from "react";
import { useGetApplicationLoginPageConfiguration } from "../hooks/useGetApplicationLoginPageConfiguration";

export default function EntraAuth() {
    const { loginPageConfiguration } = useGetApplicationLoginPageConfiguration()

    useEffect(() => {
        if (loginPageConfiguration && loginPageConfiguration?.applicationLogInMethods) {
            try {
                const url = loginPageConfiguration.applicationLogInMethods.microsoftAccountSignInUrl;
                window.location.replace(url);
            } catch (error) {
                console.error(error);
                window.location.replace('/lms/servererror?error=in the initial Entra login process');
            }
        }
    }, [loginPageConfiguration]);

    return (
        <div style={{ minHeight: "100vh" }} className="d-flex justify-content-center align-items-center" >
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginBottom: '5rem' }}>
                <div className="row">
                    <div className="spinner-border mt-5 text-primary" style={{ width: '5rem', height: '5rem' }} role="status" />
                </div>
                <div className="row mt-2">
                    <strong>Contacting Microsoft Entra...</strong>
                </div>
            </div>
        </div >
    );
}
