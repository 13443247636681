import { useState,  useMemo,useEffect, useCallback, useReducer  } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faPlus, faEye } from '@fortawesome/pro-regular-svg-icons';
import { faFolderArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { Row, Button, OverlayTrigger, Tooltip, Modal,InputGroup, Form,Col  } from "react-bootstrap";
import { Container, Alert, Tab, Tabs } from "react-bootstrap";
import MaterialReactTable from 'material-react-table';
import useLmsStore, { responseData, getPathwayAssignedInfo,postSavePathwayAssignInfo, FORMCONFIRM, toastError, toastSuccess } from "../../hooks/useLmsStore";
import Papa from 'papaparse';
import UploadGroupsDropZone from "../UploadGroupDropZone";
import UploadUsersDropZone from "../UploadUsersDropZone";
import {TableContainer} from "../../helpers/tableHelper";
import Badge from 'react-bootstrap/Badge';
import { faCalendar, faSeal } from '@fortawesome/pro-light-svg-icons';
import ModalPeopleSelect from '../ModalPeopleSelect';
import ModalGroupsSelect from '../ModalGroupsSelect';
import LearningPathwayEditorGroup  from './LearningPathwayEditorGroup';
import GroupCard from '../GroupCard';

import { ModalConfirmCancel } from '../ModalConfirmation';
const TabLearningPathwayAssignedGroupsUser = ({pathway, applicationGroups, applicationPeople,editGroupDateFn = (obj) => null, editUserDateFn = (obj) => null, hasChangedAssignFn = () => null}) => {
    const [assignInfo, setAssignInfo] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);
    const [infoLoading, setinfoLoading] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [removedItemCopy, setRemovedItemCopy ] = useState([]);
    const [removeId, setRemoveId ] = useState(0);
    const [isDirty, setIsDirty ] = useState(false);
    const [assignedPeople, setAssignedPeople] = useState(null);
    const [assignedGroups, setAssignedGroups] = useState(null);
    const [unAssignMessage, setUnassignMessage ] = useState("");
    const [unAssignTitle, setUnassignTitle ] = useState("Are you sure?");
    const [newListAPCopy, setNewListAPCopy ] = useState([]);
    const [newListAGCopy, setNewListAGCopy ] = useState([]);
    const [removedListAPCopy, setRemovedListAPCopy ] = useState([]);
    const [removedListAGCopy, setRemovedListAGCopy ] = useState([]);
    const [showModalGroups,  setShowModalGroups] = useState(false);
    const [showModalPeople, setShowModalPeople] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showCancelSelectUserDateModal, setShowCancelSelectUserDateModal] = useState(false);
    const [userRow, setUserRow] = useState(null);
    const [showCancelSelectGroupDateModal, setShowCancelSelectGroupDateModal] = useState(false);
    const [groupRow, setGroupRow] = useState(null);

    /* CSV upload */
    const [appGList, setAppGList] = useState([]);
    const [appPList, setAppPList] = useState([]);

    const [showUploadGroupCSV, setShowUploadGroupCSV] = useState(false);
    const [showUploadUserCSV, setShowUploadUserCSV] = useState(false);
    const [groupsCSV, setGroupsCSV] = useState(null);
    const [usersCSV, setUsersCSV] = useState(null);
    const [parsedCSV, setParsedCSV] = useState(null);
    const exampleCSVHeaders = [['groupName']];
    const exampleUsersCSVHeaders =[['firstName', 'surname','employeeId', 'emailAddress']];
    const [validUploadGroup, setValidUploadGroup] = useState(true);
    const [validUploadUser, setValidUploadUser] = useState(true);
    const [errorMessageGCSV, setErrorMessageGCSV] = useState("There is one or more error in the csv file. Please check the table for more detail, correct the csv file and upload it again.");
    const [errorMessageUCSV, setErrorMessageUCSV] = useState("There is one or more error in the csv file. Please check the table for more detail, correct the csv file and upload it again.");
    const [saveLoadingCSV, setSaveLoadingCSV] = useState(false);
    const [papaParseOptions, setPapaParseOptions] = useState({
      header: true, skipEmptyLines: true
  })

  /*--------------------------------------------------
  /* Use effect 
  /*--------------------------------------------------*/
  useEffect(() => {
          
    if (pathway && pathway.learningProgrammeGroupId) {
     // alert('loading all again ' + pathway.learningProgrammeGroupId);
   handleFormLoad(true);
    } 
}, [pathway]);

  /* End use effect --------------------------------- */

  //Clean all variables neeed to be reset
  const cleanPage = () => {
   let cleanCopy = [];
    setNewListAPCopy(cleanCopy);
    setNewListAGCopy([]);
    setRemovedListAGCopy([]);
    setRemovedListAPCopy([]);
  
  }
 
  const handleCancelForm = () =>{
   
    handleFormLoad(false);
  }

  //Reload the form from original values or database
  const handleFormLoad = (forceLoad) => {
   // alert('handleForm ' + forceLoad);
    setinfoLoading(true); 
    cleanPage();                 
        if (!assignInfo || forceLoad){
      
        getPathwayAssignedInfo(pathway.learningProgrammeGroupId).then((response) => {
            let data = responseData(response, []);
           
           if (data.value){
            setAssignInfo(data.value);
            if (data.value.groups){
                 
           setAssignedGroups(data.value.groups);
              
           }
          
            if (data.value.people){
           setAssignedPeople(data.value.people);
              
          }
        //cleanPage();
        setinfoLoading(false);  
        updateIsDirty(); 
        setIsDirty(false);              
        }
    });
  }
  else{
    setTimeout(() => {
        setAssignedGroups(assignInfo.group);
        setAssignedPeople(assignInfo.people);
      setinfoLoading(false);
      updateIsDirty();
   }, 500);
  }
     
}
   
/*-------------------------------------------------------------*/
/* Manage selection modals
/*-------------------------------------------------------------*/
const handleGroupAdd = () => {
   
    setShowModalGroups(true);
}

const handleEditGroupDate = (row) =>{
  editGroupDateFn({
    id:row.groupId,
    name:row.name
  });
 
} 

const handleEditUserDate = (row) =>{
 // alert('tab: selectec user ' + row.fullName + ' ' + row.regPathwayId);
  
    editUserDateFn({
      id:row.personId,
      fullName:row.fullName,
      regPathway: row.regPathwayId
    });
   // setDataGroupSelected();
   // console.log('datagroupselected', dataGroupSelected);
   //   setEditGroupDates(true);
  } 

const handlePeopleAdd = () => {
   
    setShowModalPeople(true);
}

const toggleModalGroups = () => {
    setShowModalGroups(!showModalGroups);
}  

const toggleModalPeople = () => {
    setShowModalPeople(!showModalPeople);
}  

//Add the selected group to the assigned group
const addGroupsFn = (objs) => {
    //Keep oroginal data for comparison
    let ctgData= assignInfo; 

    //Make a clone copy so it can be re-assigned
    let assignedGroupsCopy =  structuredClone(assignedGroups); 
    let  removedListAGTemp=  removedListAGCopy;
    let newListAGTemp =newListAGCopy; 

    //Get all selected groups
    let objsC = objs;
      
    //Add selected items in the new list
    for (const g of objsC){
        const item = (element) => element.groupId === g.id;

        //Check if was already selected amd was removed annd added
        let idx = ctgData.groups.findIndex(item);
        
       
          if (idx<0){ // Add only if iti s new
          //Add the new list that would be used to send to database for saiving
          newListAGTemp.push({
          groupId: g.id,
          name:g.name,
          joinId:g.joinId
        });  
        }

        //Check if exist in the assign group list and only add if it does not exist
        idx = assignedGroupsCopy.findIndex(item);      
        if (idx<0){
         assignedGroupsCopy.push({
            groupId: g.id,
            name:g.name
          });   
        }

        //Check if it is in the remove list and remove it from the list if exist
        idx= removedListAGCopy.findIndex(item);        
        if (idx>=0){
        
          removedListAGTemp.splice(idx, 1);
                    
        }
       }

        //Reassign the lists
        setRemovedListAGCopy(removedListAGTemp);
        setNewListAGCopy(newListAGTemp);
        setAssignedGroups(assignedGroupsCopy);

       //Set if it is dirty
       updateIsDirty();

       //Close the selection modal for groups
       toggleModalGroups();
    
  }  
  
 
//Add the selected list of new people to assign to the required list to update both database and screen
  const addPeopleFn = (objs) => {
    //Copy oroginal values to compare
    let ctgData= assignInfo; 
    //Copy the current assigned people
    let assignedPeopleCopy =  structuredClone(assignedPeople); 
    let objsC = objs;
    let  removedListAPTemp=  removedListAPCopy;
    let newListAPTemp =newListAPCopy; 

    //Add selected people in the new list thst would be used later to save in database
     for (const g of objsC){
        const item = (element) => element.personId === g.personId;

        //Check if was already selected amd was removed annd added, so we only added once
        let idx = ctgData.people.findIndex(item);        
              
        if (idx<0){           
        newListAPTemp.push({
          personId: g.personId,
          fullName:g.fullName,        
          employeeId:g.employeeId,
          joinId:g.joinId
        });  
        }
      
         //Check if exist in the assign group list
         idx = assignedPeopleCopy.findIndex(item);
      
         if (idx<0){
        assignedPeopleCopy.push({
            personId: g.personId,
            fullName:g.fullName,
            employeeId:g.employeeId,
            email: g.email
          });         
        
        }
             

        //Check if it is in the remove list and remvoe it from it if exist
        idx= removedListAPCopy.findIndex(item);        
        if (idx>=0){     
        removedListAPTemp.splice(idx, 1);           
        }
       }
     
        //Update list
        setRemovedListAPCopy(removedListAPTemp);    
        setNewListAPCopy(newListAPTemp);  
        setAssignedPeople(assignedPeopleCopy);

         //Set if it is dirty
       updateIsDirty();

       toggleModalPeople();
    }

/* End select modal ---------------------------------------------*/


//Handle the business logic behind deleting a group and remove or show a confirmation window if it is required
const onAGRemove = (removedItem) => {
          let ctgData= assignInfo;
          setRemoveId(1);
          const item = (element) => element.groupId === removedItem.groupId;
          let idx = ctgData.groups.findIndex(item);
          
         if (idx>=0){

            if (removedItem.hasActiveBookings){
              setUnassignTitle("Warning")
              setUnassignMessage('There are active bookings for this group. You must cancel the booking before you can delete the item from module ');

            }
            else{
              if (removedItem.hasActiveItems){
                setUnassignTitle("Warning")
                setUnassignMessage('You are about to remove an item with active learning  for  the group ' + removedItem.name + ' from ' + pathway.name + '. Do you wish to continue?');

              }
              else{
                  setUnassignMessage('You are about to remove ' + removedItem.name + ' from ' + pathway.name + ' do you wish to continue?');
              }
            }           
              //setSelectedListCopy(selectedList);
              setShowConfirmDelete(true);
              console.log('onremove removedItem',removedItem);
              setRemovedItemCopy(removedItem); 
              console.log('onremove removedItemCopy',removedItemCopy);
              setShowDialog(true);
          }
          else{
            setRemovedItemCopy(removedItem); 

            //Remove without confirmation screen, as it is a new item
            remove(1, removedItem);
          }      
       //Set if it is dirty
       updateIsDirty();

        
};
    
 //Handle the business logic beind remove a person nd remove or show the required confirmation modal when it is required
        const onAPRemove = (removedItem) => {
                   let ctgData= assignInfo;
                setRemoveId(2);
                const item = (element) => element.personId === removedItem.personId;
                let idx = ctgData.people.findIndex(item);
                
               if (idx>=0){
      
                  if (removedItem.hasActiveBookings){
                    setUnassignTitle("Warning")
                    setUnassignMessage('There are active bookings for this user. You must cancel the booking before you can delete the item from module ');
      
                  }
                  else{
                    if (removedItem.hasActiveItems){
                      setUnassignTitle("Warning")
                      setUnassignMessage('You are about to remove an user registration with active learning  for  the user ' + removedItem.fullName + ' from pathway ' + pathway.name + '. Do you wish to continue?');
      
                    }
                    else{
                        setUnassignMessage('You are about to remove the registration for ' + removedItem.fullName + ' from ' + pathway.name + ' do you wish to continue?');
                    }
                  }           
             
                   setShowConfirmDelete(true);
                    setRemovedItemCopy(removedItem); 
                  
                    setShowDialog(true);
                }
                else{
                  setRemovedItemCopy(removedItem); 
                  remove(2, removedItem);
                }       
                //Set if it is dirty
                 updateIsDirty();
 
            };

      
   //Call remove people of users with correct parameter   
    const handleRemoveClick = () => {
      console.log('handle removedItemCopy',removedItemCopy);
        remove(removeId, removedItemCopy);
     }

    
//Logic to remove group (remove==1) or users (remove==2) for the selected pathway
  const remove = (remove, removeItem) => {
    let ctgData= assignInfo;
      
         if(remove===1){ //Remove groups from pathway
            let assignedGroupsCopy = structuredClone(assignedGroups);
            const toRemove = (element) =>  element.groupId===removeItem.groupId;

            //Check if the item to remove existed in the initial assigned list
             let idx2 = ctgData.groups.findIndex(toRemove);
              
             //Check if the item we want to remove is newly added so it does not exist in the database
            let idx = newListAGCopy.findIndex(toRemove);
                
            //If it was added new
            if (idx>=0){
            //Remove from the new list
             let  newListAGTemp =  newListAGCopy;
             newListAGTemp.splice(idx, 1);
             setNewListAGCopy(newListAGTemp);                  
            }
            else{                
            if (idx2>=0){  //Check if it was in the database 
              let removeTemp = removedListAGCopy;       
              removeTemp.push(assignedGroupsCopy[idx2]);
          /*    console.log('ssignedGroupsCopy[idx2]',assignedGroupsCopy[idx2]);
              console.log('ssignedGroupsCopy',assignedGroupsCopy);
              console.log('[idx2]',idx2);
              console.log('removeTemp',removeTemp); */
              setRemovedListAGCopy(removeTemp);
             // console.log('RemovedListAGCopy',removedListAGCopy);
            }
           }

            //Check if exist in the assigned list
            idx2 = assignedGroupsCopy.findIndex(toRemove);              
            if (idx2>=0){
            assignedGroupsCopy.splice(idx2,1);
            setAssignedGroups(assignedGroupsCopy);                   
            }
                
        }
        else if(remove===2){
            //Copy current asigned people
            let assignedPeopleCopy = structuredClone(assignedPeople);
                 
            const toRemove = (element) => element.personId === removeItem.personId;
          
            //Check if the item to remove existed in the initial assigned list
            let idx2 = ctgData.people.findIndex(toRemove);
             
            //Check if thei tems to remove exist in the new list and remove if exist
            let idx = newListAPCopy.findIndex(toRemove);
            if (idx>=0){
                let newListAPTemp = newListAPCopy;
                newListAPTemp.splice(idx, 1);
                setNewListAPCopy(newListAPTemp);
                }
                else{
                //Check if the item to remove already exist in the remove lsit used to send to database if not add it    
                if (assignedPeopleCopy){
                    idx = assignedPeopleCopy.findIndex(toRemove);
                  
                    if (idx2>=0){     
                    let removeTemp = removedListAPCopy;       
                    removeTemp.push(assignedPeopleCopy[idx]);
                    setRemovedListAPCopy(removeTemp);                
                   }
                }

                 //Check if exist in the assigned list
                  idx2 = assignedPeopleCopy.findIndex(toRemove);              
                  if (idx2>=0){
                    assignedPeopleCopy.splice(idx,1);
                    setAssignedPeople(assignedPeopleCopy);
                  }
                }
        }
              setRemoveId(0);
              setShowConfirmDelete(false); 
           
              //Set if it is dirty
              updateIsDirty();

  }
        
//Close confirmation modal
const handleCloseConfirmDelete = (event) => {
 
handleFormLoad();
setShowConfirmDelete(false);
};       
      
//Update the dirity variables
const updateIsDirty = () =>{
    let dirtyTemp = isDirtyForm();

    hasChangedAssignFn({
      pathway: dirtyTemp,
      isDirty:false
    });
    
    setIsDirty(dirtyTemp);
  }

//Check any variable that is diry
const isDirtyForm = () => {

      //  if ((removedListAGCopy.length> 0) || (removedListAPCopy.length>0) || (newListAGCopy.length>0) || (newListAPCopy.length>0))
      if ((removedListAGCopy.length> 0)  || (newListAGCopy.length>0) || (newListAPCopy.length>0) || (removedListAPCopy.length>0))      
      {
       /* console.log('val true');
         console.log('removedListAGCopy.length',removedListAGCopy.length);
         console.log('newListAGCopy',newListAGCopy.length );
         console.log('removedListAPCopy.length',removedListAPCopy.length);
         console.log('newListAPCopy',newListAPCopy.length ); */
          return true;
        }
   else{     
          return false;
   }
        }

    const onConfirmSelectUserDateCancel = (row)=> {
      setUserRow(row);
          if (isDirty){
            setShowCancelSelectUserDateModal(true);
          }
          else{
            handleEditUserDate(row);
          }
        }
        
const handleConfirmSelectUserDateCancel = ()=> {
          setShowCancelSelectUserDateModal(false);
         }
     
  const handleConfirmSelectUserDate = () => {
    if (userRow)
     handleEditUserDate(userRow);
         }
   

const onConfirmSelectGroupDateCancel = (row)=> {
    setGroupRow(row);
     if (isDirty){
        setShowCancelSelectGroupDateModal(true);
      }
      else{
        handleEditGroupDate(row);
      }
    }

const handleConfirmSelectGroupDateCancel = ()=> {
      setShowCancelSelectGroupDateModal(false);
     }
 
const handleConfirmSelectGroupDate = () => {
if (groupRow)
 handleEditGroupDate(groupRow);
     }
//Submit the information for new people,groups or unassign people abd groups to the database
const handleSubmit = async event => {
          setSaveLoading(true);
          if (!saveLoading && pathway) {
          // console.log('submit removedListAGCopy',removedListAGCopy); 
            postSavePathwayAssignInfo(useLmsStore,{
             pathwayId: pathway.learningProgrammeGroupId,
             groupsToUnassign:removedListAGCopy,
             newGroups:newListAGCopy,
             peopleToUnassign:removedListAPCopy,
             newPeople:newListAPCopy,
          }).then((result) => {
            setSaveLoading(false);
            handleFormLoad(true);
          });    
          
          }
      };

/************************************************* */
/* CSV Code                                        */
/************************************************* */
const createCSV = (fileName, headers) => {
    const csv = Papa.unparse(headers);
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
}

/*------------------------------------------------------------------------
/* Groups
/*----------------------------------------------------------------------- */

const closeGroupsUpload = () => {
        setShowUploadGroupCSV(false);
    };
  
const cancelGroupsUpload = () => {
        setGroupsCSV(null);
        setParsedCSV(null);
        setShowUploadGroupCSV(false);
    };
  
const toggleModalAGUploadCSV = useCallback(
        (row) => {
            setShowUploadGroupCSV(true);
        },
        [],
    );
  
const groupColumnsCsv = useMemo(() => [{
      accessorKey: 'groupName', header: 'Group Name', size: 2,
      Cell: ({ cell, row }) => (
        <>
        <Row >
        <Col>{row.original.groupName} </Col> 
        
        <Col>
          
       <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{row.original.error}</Tooltip>}>
     
       <Badge pill bg='danger' className='p-2 w-10' >
      {row.original.error!=''? row.original.error  : '' }
           </Badge>
      </OverlayTrigger>
       </Col> 

        </Row>
        </>)

  }], [],);

    const parseTheGroupCsv = async (file) => {       
      const reader = new FileReader();
      reader.onload = (event) => {
          const csvData = event.target.result;
          const csv = Papa.parse(csvData, papaParseOptions);
          let validatedItems = [];
          let err = ''
          for (let i = 0; i < csv.data.length; i++) {
           err= '';
            if ( csv.data[i].groupName=='' ||  csv.data[i].groupName==undefined){
                err='Must enter a group name';                
            }
            else{            

            if (!existGroup( csv.data[i].groupName)){              
              err= 'No exist';              
            }
            else{
                if (duplicateGroup( csv.data[i].groupName)){
                    err= "Already assigned to pathway";                    
                }
            }
        }

        //Makse sure there is not duplication in the csv file, if there is, add only on time
        let alreadyExist = validatedItems.filter((a)=> a.groupName== csv.data[i].groupName).length;
        if (alreadyExist==0){
            validatedItems.push({
                groupName: csv.data[i].groupName,
                error:err
            });
        }
          }

          setParsedCSV(validatedItems);        
      };
      reader.readAsText(file);
  }

  const createAssignedGroupsFunction = () => {

    let ctgData = [];
   
    
    for (let i = 0; i < parsedCSV.length; i++) {
  
        const groupName = parsedCSV[i].groupName;
        // Check if the group name already exists in the current list       
            const formattedData = {
                name: groupName,
            };
           
            ctgData.push({
                name: formattedData.name,
                joinId: (-1)*(i+1)             
            });
        }
        
        setSaveLoadingCSV(true);
        if (!saveLoadingCSV && pathway) {
          
          postSavePathwayAssignInfo(useLmsStore,{
           pathwayId: pathway.learningProgrammeGroupId,
           groupsToUnassign:[],
           newGroups:ctgData,
           peopleToUnassign:[],
           newPeople:[],
        }).then((result) => {
          setSaveLoading(false);
          setShowUploadGroupCSV(false);
        
          handleFormLoad(true);
          setGroupsCSV(null);
          setParsedCSV(null);
          setValidUploadGroup(true);
          setSaveLoadingCSV(false);
        });  
    }
     
    for (let i = 0; i < appGList.length; i++) {
        ctgData.push(appGList[i]);
    }
   
    setNewListAGCopy(ctgData);
    setAppGList(ctgData.map(function(c){ return {'title' : c.name, 'id' : c.id }}));
    setShowDialog(true);
  }   
  
  
  

    const onSelectGroupFile = (file) => {
     setGroupsCSV(file)
      parseTheGroupCsv(file)
  }

   
const renderTheGroupDropzone = () => {
      return !groupsCSV ? <div className={'d-flex flex-column'}>
          <UploadGroupsDropZone onSelectFile={onSelectGroupFile}/>
          <Button type="button" className="btn btn-primary d-block mt-2" onClick={() => {
              createCSV('groups_example.csv',exampleCSVHeaders)
          }}>Download example CSV</Button>
      </div> : <></>
  }


     const renderSelectedGroupsTable = () => {
      /**    return (parsedCSV && !createUsers.data.length) ? <div className={"w-100"} >
 */
      return (parsedCSV ) ? <div className={"w-100"} >
          <TableContainer>
              <MaterialReactTable
                  columns={groupColumnsCsv}
                  data={parsedCSV || []}
                  enableColumnActions={false}
                  enableColumnFilters={false}
                  enableDensityToggle={false}
                  enableFullScreenToggle={false}
                  enableGlobalFilter={false}
                  enableGrouping={false}
                  enableHiding={false}
                  enablePagination={true}
                  enableSorting={false}
                  enableTopToolbar={false}
                  muiTableBodyRowProps={{hover: false}}
                  enableBottomToolbar={true}
                  muiTableProps={{
                      sx: {
                          tableLayout: 'fixed',

                      },
                  }}               
              />

          </TableContainer>
      </div> : <></>
  }


 //Render buttons
const renderCancelAndUploadGroupsButtons = () => {  
    return (groupsCSV) ? <>
       {!validUploadGroup?<><div className='text-danger'>Error: {errorMessageGCSV}</div></>:<></>}
        <div className={"create_users_page__buttons_container"}>
            <Button type="button" className="btn btn-primary" onClick={() => {
                onAGCancel()
            }}>Cancel</Button>
          
           {saveLoadingCSV?
           <> 
          <div className="d-flex justify-content-center">
             <div className="spinner-border mt-5 text-primary" style={{width: '2rem', height: '2rem'}} role="status">
              <span className="sr-only">Loading...</span>
           </div>
          </div>
          </>
           :
           <> 
            <Button type="button" className="btn btn-primary" disabled={!validUploadGroup}  onClick={() => {
               createAssignedGroupsFunction()
            }}>Upload groups</Button>
            </>
           }
        </div>
        </> : <></>
}



//Check if the group exsit in the database
const existGroup = (groupName) => {

    if (applicationGroups){
     let group = applicationGroups.filter(x => x.name==groupName);
   
    if (group.length>0){    
    return true;
    }
    else{
    setValidUploadGroup(false);
    return false;
    }
   
}
  return false;
}

//Validate if group is already assiged to the pathway
const duplicateGroup = (groupName) => {
   
    if (assignedGroups){
    let group =  assignedGroups.filter(x => x.name==groupName);
  
   if (group.length>0){     
     setValidUploadGroup(false);
     return true;
   }
   else{
   
   return false;
   }

   return false;

   }
}

const onAGCancel = () => {
    cleanGroupCSV();
  }
  
  const cleanGroupCSV = () =>{
      setGroupsCSV(null);
      setParsedCSV(null);
      setValidUploadGroup(true);
      setSaveLoadingCSV(false);
  }
/*------------------------------------------------------------------------
/* END CSV Groups
/*----------------------------------------------------------------------- */


/*------------------------------------------------------------------------
/* User CSV
/*----------------------------------------------------------------------- */
const closeUsersUpload = () => {
    setShowUploadUserCSV(false);
};

const cancelUsersUpload = () => {
    setShowUploadUserCSV(false);
};

const toggleModalAUUploadCSV = useCallback(
    (row) => {
        setShowUploadUserCSV(true);
    },
    [],
);

/*const usersColumnsCsv = useMemo(() => [{
    accessorKey: 'groupName', header: 'Group Name', size: 2,
    Cell: ({ cell, row }) => (
      <>
      <Row >
      <Col>{row.original.groupName} </Col> 
      
      <Col>
        
     <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{row.original.error}</Tooltip>}>
   
     <Badge pill bg='danger' className='p-2 w-10' >
    {row.original.error!=''? row.original.error  : '' }
         </Badge>
    </OverlayTrigger>
     </Col> 

      </Row>
      </>)

}], [],); */

const usersColumnsCsv = useMemo(() => [
    {
        accessorKey: 'firstName', header: 'First Name', size: 100, Cell: ({cell}) => renderCell(cell)
    },
    {
        accessorKey: 'lastName', header: 'Last Name', size: 100, Cell: ({cell}) => renderCell(cell)
    },
    {
        accessorKey: 'employeeId', header: 'Employee Id', size: 100, Cell: ({cell}) => renderCell(cell)
    },
    {
        accessorKey: 'emailAddress', header: 'Email', size: 100, Cell: ({cell}) => renderCell(cell)
    },
    {
        accessorKey: 'id', header: '', size: 100, Cell: ({ cell, row }) => (
            <>
            <Row>              
            <Col>              
           <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{row.original.error}</Tooltip>}>
       
           <Badge pill bg='danger' className='p-2 w-10' >
          {row.original.error!=''? row.original.error  : '' }
               </Badge>
          </OverlayTrigger>
           </Col>         
            </Row>
            </>
    )
    } 
], [],);

  const renderCell = (cell) => {
    const cellValue = cell.getValue()
    return <>
     <div>{cellValue}</div>
    </>
   
    /*cellValue.errorMessage ? <>
        <OverlayTrigger
            placement='top'
            delay={{show: 250, hide: 400}}

            overlay={<Tooltip className='cls-theme-tooltip'>{cellValue.errorMessage}</Tooltip>}>
            <div className={"text-danger"}>
                {cell.getValue().value || "N/A"}
            </div>
        </OverlayTrigger>
    </> : <div>
        {cell.getValue().value}
    </div> */
}

  const parseTheUserCsv = async (file) => {       
    const reader = new FileReader();
    let user={};
    reader.onload = (event) => {
        const csvData = event.target.result;
        const csv = Papa.parse(csvData, papaParseOptions);
        let validatedItems = [];
        let err = '';
        let existUserInfo={};
        let user = {};
        for (let i = 0; i < csv.data.length; i++) {
         existUserInfo=existUser(csv.data[i].firstName,csv.data[i].surname,csv.data[i].emailAddress, csv.data[i].employeeId);
         err= '';
         user = {
            firstName: csv.data[i].firstName,
            surname: csv.data[i].surname,
            employeeId: csv.data[i].employeeId,
            emailAddress:csv.data[i].emailAddress,
           
        };
       
          if (!existUserInfo.exist){              
            err= 'No exist';              
          }
          else{
              user.personId= existUserInfo.personId;
              if (duplicateUser(csv.data[i].firstName,csv.data[i].surname,csv.data[i].emailAddress, csv.data[i].employeeId)){
                  err= "Already assigned to pathway";                  
              }              
            }                

      //Makse sure there is not duplication in the csv file, if there is, add only on time
      let alreadyExist = validatedItems.filter((a)=> (a.firstName!=undefined && a.firstName!='' && a.firstName== csv.data[i].firstName && a.surname!=undefined && a.surname!='' && a.surname== csv.data[i].surname && a.surname.undefined) || 
                                                     (a.emailAddress!=undefined && a.emailAddress!='' && a.emailAddress== csv.data[i].emailAddress) || (a.employeeId!=undefined && a.employeeId!='' && a.employeeId== csv.data[i].employeeId));
    
      if (alreadyExist.length==0){
        user.erro=err;
          validatedItems.push(user);
        }
        }

        setParsedCSV(validatedItems);      
    };
    reader.readAsText(file);
}

const createAssignedUsersFunction = () => {

  let ctgData = [];
 
  
  for (let i = 0; i < parsedCSV.length; i++) {
    
      // Check if the group name already exists in the current list       
          const formattedData = parsedCSV[i];
        
          ctgData.push({
              firstName: formattedData.firstName,
              lastname: formattedData.surname,
              email: formattedData.emailAddress,
              employeeId: formattedData.employeeId,
              personId: formattedData.personId,
              joinId: (-1)*(i+1)             
          });
      }
      
      setSaveLoadingCSV(true);
      if (!saveLoadingCSV && pathway) {
        
        postSavePathwayAssignInfo(useLmsStore,{
         pathwayId: pathway.learningProgrammeGroupId,
         groupsToUnassign:[],
         newGroups:[],
         peopleToUnassign:[],
         newPeople:ctgData,
      }).then((result) => {
        setSaveLoading(false);
        setShowUploadUserCSV(false);
      
        handleFormLoad(true);
        setUsersCSV(null);
        setParsedCSV(null);
        setValidUploadUser(true);
        setSaveLoadingCSV(false);
      });  
  }
   
  for (let i = 0; i < appGList.length; i++) {
      ctgData.push(appGList[i]);
  }
 
  setNewListAGCopy(ctgData);
  setAppGList(ctgData.map(function(c){ return {'title' : c.name, 'id' : c.id }}));
  setShowDialog(true);
}   




  const onSelectUserFile = (file) => {
   setUsersCSV(file)
    parseTheUserCsv(file)
}

 
const renderUsersDropzone = () => {
    return !usersCSV ? <div className={'d-flex flex-column'}>
        <UploadUsersDropZone onSelectFile={onSelectUserFile}/>
        <Button type="button" className="btn btn-primary d-block mt-2" onClick={() => {
            createCSV('users_example.csv', exampleUsersCSVHeaders)
        }}>Download example CSV</Button>
    </div> : <></>
}


   const renderSelectedUsersTable = () => {
    /**    return (parsedCSV && !createUsers.data.length) ? <div className={"w-100"} >
*/
    return (parsedCSV ) ? <div className={"w-100"} >
        <TableContainer>
            <MaterialReactTable
                columns={usersColumnsCsv}
                data={parsedCSV || []}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableGlobalFilter={false}
                enableGrouping={false}
                enableHiding={false}
                enablePagination={true}
                enableSorting={false}
                enableTopToolbar={false}
                muiTableBodyRowProps={{hover: false}}
                enableBottomToolbar={true}
                muiTableProps={{
                    sx: {
                        tableLayout: 'fixed',

                    },
                }}               
            />

        </TableContainer>
    </div> : <></>
}


//Render buttons
const renderCancelAndUploadUsersButtons = () => {  
  return (usersCSV) ? <>
     {!validUploadUser?<><div className='text-danger'>Error: {errorMessageUCSV}</div></>:<></>}
      <div className={"create_users_page__buttons_container"}>
          <Button type="button" className="btn btn-primary" onClick={() => {
              onAUCancel()
          }}>Cancel</Button>
        
        {saveLoadingCSV?
      <> 
      <div className="d-flex justify-content-center">
          <div className="spinner-border mt-5 text-primary" style={{width: '2rem', height: '2rem'}} role="status">
              <span className="sr-only">Loading...</span>
          </div>
      </div>
      </>
      :
        <> 
          <Button type="button" className="btn btn-primary" disabled={!validUploadUser}  onClick={() => {
             createAssignedUsersFunction()
          }}>Upload Users</Button>
        </>}
      </div>
  </> : <></>
}


//Check if the user exist in the database
const existUser = (firstName,surname, email, employeeId) => {
    let fullName = firstName + ' ' + surname;
    let result={
        exist:false,
        personId:-1
    };
    
  if (applicationPeople){
   let user = applicationPeople.filter(a =>  (a.fullName!=undefined &&  a.fullName!='' && a.fullName.toLowerCase()== fullName.toLowerCase()) || 
   (a.email!=undefined && a.email!='' && a.email== email) || (a.employeeId!=undefined && a.employeeId!='' && a.employeeId== employeeId));

 
  if (user.length>0){ 
    result.exist=true;
    result.personId=user[0].id; 
  }
  else{
  setValidUploadUser(false);
 
  }
}

return result;
}

//Validate if user is already assiged to the pathway
const duplicateUser = (firstName,surname, email, employeeId) => {
 
 
  if (assignedPeople){
  let user =  assignedPeople.filter(a =>  (a.firstName!=undefined &&  a.firstName!='' && a.firstName.toLowerCase()== firstName.toLowerCase() && a.surname!=undefined && a.surname!='' && a.surnametoLowerCase()== surname.toLowerCase() && a.surname!=undefined) || 
  (a.email!=undefined && a.email!='' && a.email== email) || (a.employeeId!=undefined && a.employeeId!='' && a.employeeId== employeeId));


 if (user.length>0){     
   setValidUploadUser(false);
   return true;
 }
 else{
 
 return false;
 }

 return false;

 }
}

const onAUCancel = () => {
  cleanUserCSV();
}

const cleanUserCSV = () =>{
    setUsersCSV(null);
    setParsedCSV(null);
    setValidUploadUser(true);
    setSaveLoadingCSV(false);
}

/*------------------------------------------------------------------------
/*End User CSV
/*----------------------------------------------------------------------- */



/*-----------------------------------------------------------------------------
/* Columns for them ain reference material table for assigned groups and people
/*------------------------------------------------------------------------------*/
const columnsG = [
    {
      header: 'Group',
      accessor: 'name',
      accessorKey:'name',    
      
    },
    {
        accessorKey: 'groupId',
        header: 'Actions',
        muiTableBodyCellProps: {
            align: 'center',
            sx: {
                textOverflow: 'unset',        
            }
        },               
        size: 50,
        enableEditing:false,                
        Cell: ({ cell, row }) => {
               return (
                <>
                    <OverlayTrigger
                            placement="top"   
                            overlay={<Tooltip className='cls-theme-tooltip'>
                                Delete module
                                </Tooltip>}                                >
                            <span >
                                <Button
                                    variant='contained'
                                    onClick={() =>
                                    onAGRemove(row.original)}
                                    >
                                    <FontAwesomeIcon
                                        size='lg'
                                        icon={faTrash}
                                        className='text-primary'
                                    />
                                </Button>
                            </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="top"   
                            overlay={<Tooltip className='cls-theme-tooltip'>
                                  Edit Due Dates
                                </Tooltip>}                                >
                            <span >
                                <Button
                                    variant='contained'
                                    onClick={() => onConfirmSelectGroupDateCancel(row.original)}
                                    >
                                    <FontAwesomeIcon
                                        size='lg'
                                        icon={faCalendar}
                                        className='text-primary'
                                    />
                                </Button>
                            </span>
                    </OverlayTrigger>
                </>
            );
        }
    }
  ];


  const columnsP = [
    {
      header: 'Fullname',
      accessor: 'fullName',
      accessorKey:'fullName',    
      Cell: ({ cell, row }) => {
        return (
         <>
        <p>{row.original.fullName}</p> 
        <p>{row.original.employeeId}</p>
        
         </>)}
    },      
    {
        accessorKey: 'personId',
        header: 'Actions',
        muiTableBodyCellProps: {
            align: 'center',
            sx: {
                textOverflow: 'unset',        
            }
        },                     
        enableEditing:false,                
        Cell: ({ cell, row }) => {
               return (
                <>
                    <OverlayTrigger
                            placement="top"   
                            overlay={<Tooltip className='cls-theme-tooltip'>
                                Unassign person
                                </Tooltip>}                                >
                            <span >
                                <Button
                                    variant='contained'
                                    onClick={() =>

                                      onAPRemove(row.original)}
                                    >
                                    <FontAwesomeIcon
                                        size='lg'
                                        icon={faTrash}
                                        className='text-primary'
                                    />
                                </Button>
                            </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="top"   
                            overlay={<Tooltip className='cls-theme-tooltip'>
                                Edit Due Dates
                                </Tooltip>}                                >
                            <span >
                                <Button
                                    variant='contained'
                                    onClick={() => onConfirmSelectUserDateCancel(row.original)}
                                     //handleEditUserDate(row.original)}
                                    >
                                    <FontAwesomeIcon
                                        size='lg'
                                        icon={faCalendar}
                                        className='text-primary'
                                    />
                                </Button>
                            </span>
                    </OverlayTrigger>
                </>
            );
        }
    }
  ];


    return (        
    infoLoading?
      <> 
      <div className="d-flex justify-content-center">
          <div className="spinner-border mt-5 text-primary" style={{width: '5rem', height: '5rem'}} role="status">
              <span className="sr-only">Loading...</span>
          </div>
      </div>
      </>
      :
        <>
        <Container fluid>        
        <Row>
          <Col sm={5}>
          <h5>Assigned Groups</h5>
            <Row>
            <TableContainer>
              <MaterialReactTable
                columns={columnsG}
                autoResetPageIndex={false}
                data={assignedGroups?assignedGroups:[]}
                enableSorting={true}
                enablePagination={assignedGroups? (assignedGroups.length>10?true:false):false}
                initialState={{
                    pagination: { pageIndex: 0, pageSize: 10 },
                    showGlobalFilter: true
                }}            
                muiSearchTextFieldProps={{
                  placeholder: `search assigned`,                 
                }}
                renderToolbarInternalActions={({ table }) => (
                    <>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add new Department</Tooltip>}>
                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleGroupAdd()}>
                                Add
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add new Department</Tooltip>}>
                            <Button variant='clear' className='pt-0 pb-0' onClick={() => toggleModalAGUploadCSV()}>
                            <FontAwesomeIcon
                                    size='lg'
                                    icon={faFolderArrowUp}
                                    className='text-primary'
                                />
                            </Button>
                          
                        </OverlayTrigger>
                    </>
                )}   
             muiTableHeadProps={{
                            sx: {
                                display: 'none'
                            }
                        }}
              muiToolbarAlertBannerProps={{
                            sx: {
                                display: 'none'
                            }
                        }}

            
            />
            </TableContainer>
            </Row>
          </Col>
          <Col sm={1}></Col>
          <Col sm={6}>
          <h5>Assigned Users</h5>
            <Row>
            <TableContainer>
              <MaterialReactTable
                columns={columnsP}
                autoResetPageIndex={false}
                data={assignedPeople?assignedPeople:[]}
                enableSorting={true}
                enablePagination={assignedPeople? (assignedPeople.length>10?true:false):false}
                initialState={{
                    pagination: { pageIndex: 0, pageSize: 10 },
                    showGlobalFilter: true
                }}            
                muiSearchTextFieldProps={{
                  placeholder: `search assigned`,                 
                }}
                renderToolbarInternalActions={({ table }) => (
                    <>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Assign a new user</Tooltip>}>
                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handlePeopleAdd()}>
                                Add
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Assign users</Tooltip>}>
                            <Button variant='clear' className='pt-0 pb-0' onClick={() => toggleModalAUUploadCSV()}>
                            <FontAwesomeIcon
                                    size='lg'
                                    icon={faFolderArrowUp}
                                    className='text-primary'
                                />
                            </Button>
                          
                        </OverlayTrigger>
                    </>
                )}   
             muiTableHeadProps={{
                            sx: {
                                display: 'none'
                            }
                        }}
              muiToolbarAlertBannerProps={{
                            sx: {
                                display: 'none'
                            }
                        }}

            
            />
            </TableContainer>
            </Row>
                </Col>    
   </Row>
        <Button variant='primary' className="mt-2 float-right"  onClick={handleCancelForm} disabled={!isDirty} >Cancel</Button>
        {(saveLoading)?  
        <Button variant="primary" disabled className="mx-2 mt-2 float-right"  >
          <span className="spinner-border spinner-border-sm mt-1" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
          Please wait...
        </Button>
        :
        <Button variant="primary" className="mx-2 mt-2 float-right" onClick={handleSubmit}  disabled={!isDirty} >
          Save
        </Button>
      }    
      </Container>  
      <ModalConfirmCancel
      showCancelEditModal={showCancelSelectUserDateModal}
      abortFn={handleConfirmSelectUserDateCancel}
      continueFn={handleConfirmSelectUserDate}
       />
       <ModalConfirmCancel
       showCancelEditModal={showCancelSelectGroupDateModal}
       abortFn={handleConfirmSelectGroupDateCancel}
       continueFn={handleConfirmSelectGroupDate}
        />
      <ModalGroupsSelect allGroups={applicationGroups} selectedData={assignedGroups} closeFn={toggleModalGroups} open={showModalGroups} submitFn={(obj) =>addGroupsFn(obj)}></ModalGroupsSelect>
      <ModalPeopleSelect allPeople={applicationPeople} selectedData={assignedPeople} closeFn={toggleModalPeople} open={showModalPeople} submitFn={(obj) =>addPeopleFn(obj)}></ModalPeopleSelect>
        {/* Groups */}
        <Modal show={showUploadGroupCSV} onHide={closeGroupsUpload}>
        <Modal.Header closeButton>
            <Modal.Title>Upload Groups CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {renderTheGroupDropzone()}
            {renderSelectedGroupsTable()}
            {renderCancelAndUploadGroupsButtons()}                    
        </Modal.Body>
        <Modal.Footer>
                    <Button variant="secondary" onClick={cancelGroupsUpload}>
                Cancel
            </Button>                       
        </Modal.Footer>
    </Modal> 
     {/* Users */}
     <Modal show={showUploadUserCSV} onHide={closeUsersUpload}>

         <Modal.Header closeButton>
             <Modal.Title>Upload Users CSV</Modal.Title>
         </Modal.Header>
         <Modal.Body>
              {renderUsersDropzone()}
              {renderSelectedUsersTable()}
              {renderCancelAndUploadUsersButtons()}           
         </Modal.Body>
         <Modal.Footer>
        <Button variant="secondary" onClick={cancelUsersUpload}>
                 Cancel
             </Button>          
         </Modal.Footer>
      </Modal> 
      {pathway?          
        <Modal fullscreen='md-down' show={showConfirmDelete} onHide={handleCloseConfirmDelete} backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
              <Modal.Title>{unAssignTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <h6>{unAssignMessage}</h6>

              {!removedItemCopy.hasActiveBookings?
                  <>
                  <Button variant='primary px-3 w-25 mx-3' onClick={handleRemoveClick} >Yes</Button>
                  <Button variant='primary px-3 w-25 mx-3 float-right' onClick={handleCloseConfirmDelete} >No</Button>
                  </>
                 :<></>
              }
          </Modal.Body>
       </Modal>
          :<></> }
        </>            
   )

   };  

export default  TabLearningPathwayAssignedGroupsUser;