
import React, {useEffect, useState} from "react";
import Card from 'react-bootstrap/Card';
import HeadingWithBackButton from '../../../components/common/heading-with-back-button';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Button, Col, Form, Row} from "react-bootstrap";

import {TableContainer} from '../../../helpers/tableHelper'
import BaseContainer from "../../../components/BaseContainer";
import AppAvatar from "../../../components/AppAvatar";

import {apiAxiosCalls} from "../../../services/apiAxiosCalls";
import {toastError, toastSuccess} from "../../../hooks/useLmsStore";

export default function UserProfile(props) {
    let inputModelObj = {
        employeeId: '',
        company: '',
        emailAddress: '',
        address: '',
        phoneNumber: '',
        extraPhoneNumber: '',
        photograph: '',
        fullName: '',
        primaryPhone: '',
        secondaryPhone: "",
        postalAddress: ""
    }
    const navigate = useNavigate();
    const [image, setImage] = useState({preview: "", raw: ""});
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileSize, setFileSize] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');

    const [searchParams] = useSearchParams();
    const [userId, setUserId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(inputModelObj);
    const [saving, setSaving] = useState(false);

    const [inputValue, setInputValue] = useState('');


    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"});
        let id = parseInt(searchParams.get('id'));
        setUserId(id > 0 ? id : 0);

    }, [searchParams])


    const onBackButtonClick = (e) => {
        navigate(-1);
    }
    

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const validateSchema = Yup.object().shape({
        primaryPhone: Yup.string()
            .required("Phone number is required").matches(phoneRegExp, 'Phone number is not valid').min(10, "Phone number is too short"),
        secondaryPhone: Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid').min(10, "Phone number is too short"),
        photographLocal: Yup.mixed()
            .nullable()
    });


    const formik = useFormik({
        initialValues: {
            employeeId: userInfo.employeeId || "",
            company: userInfo.company || "",
            emailAddress: userInfo.emailAddress || "",
            postalAddress: userInfo.postalAddress || "",
            primaryPhone: userInfo.primaryPhone || "",
            secondaryPhone: userInfo.secondaryPhone || "",
            extraPhoneNumber: "",
            firstName: userInfo.firstName || "",
            lastName: userInfo.lastName || "",
        },
        enableReinitialize: true,
        validationSchema: validateSchema,
        onSubmit: async (values, {resetForm}) => {
            const formData = new FormData()
            formData.append("primaryPhone", values.primaryPhone)
            formData.append("secondaryPhone", values.secondaryPhone)

            await updateUserInfo(userId, formData)

        },
    });

    const updateUserInfo = async (userId, formData) => {
        try {
            const {data} = await apiAxiosCalls.UpdatePersonInfoTeamProfiles(userId, formData)
            setUserInfo(data)
            formik.setValues({
                employeeId: data.employeeId,
                company: data.company,
                emailAddress: data.emailAddress,
                postalAddress: data.postalAddress || "",
                firstName: data.firstName,
                lastName: data.lastName,
                photo: data.photo,
                primaryPhone: data.primaryPhone,
                secondaryPhone: data.secondaryPhone,
            })
            toastSuccess("User info updated successfully")
        }catch (e) {
            console.log(e)
            toastError({message: "An error occured"})
        }


    }

    const getPersonInfo = async (userId) => {
        setLoading(true);
        try {
            const {data} = await apiAxiosCalls.GetPersonInfoTeamProfiles(userId)
            setUserInfo(data)
            formik.setValues({
                employeeId: data.employeeId,
                company: data.company,
                emailAddress: data.emailAddress,
                postalAddress: data.postalAddress || "",
                firstName: data.firstName,
                lastName: data.lastName,
                photo: data.photo,
                primaryPhone: data.primaryPhone,
                secondaryPhone: data.secondaryPhone,
            })
        } catch (e) {
            console.log(e)
        }

        setLoading(false);

    }

    useEffect(() => {
        if (userId > 0) {
            getPersonInfo(userId)
        }

    }, [userId])


    const handlePicChange = (event) => {
        if (event.currentTarget.files.length) {
            const file = event.currentTarget.files[0];
            setSelectedFile(file);
            setImage({
                preview: URL.createObjectURL(file),
                raw: file
            });

            formik.setFieldValue("photographLocal", file);
        }
    };



    return (<>
            {<BaseContainer>
                <TableContainer>
                    <Row>
                        <Col>
                            {loading ? <>
                                <Button variant="primary" disabled className="mx-2">
                                                <span className="spinner-border spinner-border-sm"
                                                      style={{marginRight: '0.25rem'}} role="status"
                                                      aria-hidden="true"></span>
                                    Please wait...
                                </Button>
                            </> : <>
                                {<>
                                    <Card>
                                        <HeadingWithBackButton
                                            Title={formik.values.fullName}
                                            onBackButtonClick={onBackButtonClick}
                                        >
                                        </HeadingWithBackButton>
                                        <Card.Body>

                                            <div className="row">
                                                <div className="col-8">
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <label htmlFor="Employee Id">Email
                                                                    Address</label>
                                                                <input
                                                                    style={{maxWidth: '95%'}}
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="emailAddress"
                                                                    id="emailAddress"
                                                                    readOnly
                                                                    value={formik.values.emailAddress}
                                                                >
                                                                </input>

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <label htmlFor="Employee Id">Employee
                                                                    Id</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="employeeId"
                                                                    id="employeeId"
                                                                    readOnly
                                                                    defaultValue={formik.values.employeeId}

                                                                >
                                                                </input>

                                                            </div>

                                                            <div className="col-6">
                                                                <label
                                                                    htmlFor="Employee Id">Company</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="Company"
                                                                    id="Company"
                                                                    defaultValue={formik.values.company}
                                                                    readOnly

                                                                >
                                                                </input>
                                                            </div>


                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <label htmlFor="Postal Address">Postal
                                                                    Address</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="postalAddress"
                                                                    id="postalAddress"
                                                                    rows={5}
                                                                    defaultValue={formik.values.postalAddress}
                                                                    readOnly

                                                                >
                                                    </textarea>
                                                            </div>

                                                        </div>

                                                        <div className="row">
                                                            <div className="col-6">
                                                                <label htmlFor="Primary Phone">Primary
                                                                    Phone</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="primaryPhone"
                                                                    id="primaryPhone"
                                                                    maxLength={15}
                                                                    value={formik.values.primaryPhone}
                                                                    onChange={(event) => {
                                                                        formik.handleChange(event);
                                                                    }}
                                                                >
                                                                </input>
                                                                {formik.errors.primaryPhone ? <span
                                                                    style={{color: 'red'}}>{formik.errors?.primaryPhone}</span> : null}
                                                            </div>

                                                            <div className="col-6">
                                                                <label htmlFor="Secondary Phone">Secondary
                                                                    Phone</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="secondaryPhone"
                                                                    id="secondaryPhone"
                                                                    maxLength={15}
                                                                    value={formik.values.secondaryPhone}
                                                                    onChange={(event) => {
                                                                        formik.handleChange(event);
                                                                    }}
                                                                >
                                                                </input>
                                                                {formik.errors.secondaryPhone ? <span
                                                                    style={{color: 'red'}}>{formik.errors?.secondaryPhone}</span> : null}
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>

                                                <div className="col-4">
                                                    <div className="col-12">
                                                        <Row className="justify-content-center">
                                                            <div>
                                                            </div>
                                                            <div style={{
                                                                clear: 'both',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                                <Row>
                                                                    <Col sm={12} md={12}
                                                                         className="pt-3">
                                                                        <div style={{
                                                                            clear: 'both',
                                                                            display: 'flex',
                                                                            alignItems: 'center'
                                                                        }}>

                                                                            <AppAvatar
                                                                                size={85}
                                                                                name={`${formik.values.firstName} ${formik.values.lastName}`}
                                                                                sx={{
                                                                                    width: 25, height: 25
                                                                                }}
                                                                                src={ formik.values.photographLocal ? URL.createObjectURL(formik.values.photographLocal)  : userInfo.photo}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={12} md={9}>
                                                                        <Form.Group
                                                                            className="mb-3 ml-2">
                                                                            <Form.Label>Change Profile
                                                                                Picture:</Form.Label>
                                                                            <Form.Control type="file"
                                                                                          placeholder="Select Picture"
                                                                                          id="photo"
                                                                                          name={"photo"}
                                                                                          onChange={handlePicChange}/>
                                                                        </Form.Group>
                                                                        <>

                                                                            {errorMessage && <div
                                                                                className="text-danger">{errorMessage}</div>}
                                                                        </>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </div>

                                            </div>

                                        </Card.Body>
                                    </Card>
                                    <div>&nbsp;</div>
                                    <div className="float-right">
                                        <Button
                                            type={"button"}
                                            variant='btn btn-outline-primary'
                                            style={{
                                                marginLeft: '5px', marginRight: '10px', 'width': '100px'
                                            }}
                                            onClick={formik.handleSubmit}
                                            disabled={!formik.isValid || formik.isSubmitting}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            variant='btn btn-outline-primary'
                                            style={{
                                                marginLeft: '5px', marginRight: '10px', 'width': '100px'
                                            }}
                                            onClick={(event) => {
                                                formik.resetForm()
                                            }}
                                        >
                                            Cancel
                                        </Button>

                                    </div>
                                </>}
                            </>}
                        </Col>
                    </Row>
                </TableContainer>
            </BaseContainer>}
        </>)

}
