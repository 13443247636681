import { useEffect } from 'react';

import axios from 'axios';

import BaseContainer from "../components/BaseContainer";
import { BeginButton } from '../components/CustomButtons'

import { Button, Row } from 'react-bootstrap';

import useLmsStore, { responseData } from '../hooks/useLmsStore';
import useLegacyStore, { fetchApplicationRegistrationDetail, getApplicationRegistrationDetail, postSearchApplicationReports, postSearchGroups  } from '../hooks/useLegacyStore';
import shallow from 'zustand/shallow';

import { toast } from 'react-toastify';
import { getValidToken } from '../helpers/validateHelper';

// Wait up to 180 secs in dev mode else 60 secs
const LOGIN_TIMEOUT = process.env.NODE_ENV === 'development' ? 180000 : 60000;

const HEADERS_JSON = { headers: { 'Content-Type': 'application/json' } };

const apiAxios = axios.create({
    withCredentials: true,
    headers: {'Authorization': getValidToken() }
});

const toastError = (error = { message: 'Error' }, altMessage = null) => {
    console.error(error);
    toast.error(altMessage || error.message, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
    });
};

export default function Experiment(props) {
    const { personBasic, fetchPersonBasic, getLocationConfig } = useLmsStore(state =>
    ({
        personBasic: state.personBasic.data,
        fetchPersonBasic: state.fetchPersonBasic,
        getLocationConfig: state.getLocationConfig
    }), shallow)    

    useEffect(() => {
        fetchPersonBasic();
    }, []);

    const {applicationRegistrationDetail, fetchApplicationRegistrationDetail } = useLegacyStore(state =>
        ({
           
            fetchApplicationRegistrationDetail: state.fetchApplicationRegistrationDetail
        }), shallow); 

    const getApiUrl = (urlFragment) => {
       // let urlSrv = '/rpcall-learningportal.dev';
       let urlSrv = '/rpcall-ukpnportal.dev';
        return `${urlSrv}${urlFragment}`;
    };

    const handleAdminLogin = () => {
        apiAxios.post(getApiUrl('/api/account/singleSignOnFromNewLms'), { ccid: personBasic.personIdentifier }, HEADERS_JSON)
            .then(res=>{
                apiAxios.get(getApiUrl('/api/account/getApplicationRegistration'))
            })
            .catch(toastError);
    }

    const handleApplicationRegistrationDetail = () => {
        apiAxios(getLocationConfig.getRpCallUrl('/api/application/getApplicationRegistrationDetail/10')).then((response) => {
            console.log('response',response);
            let data = responseData(response, []);          
        });
    };

    const handleFetchApplicationRegistrationDetail = () => {
        fetchApplicationRegistrationDetail().then((response) => {
            console.log('response',response);
           // let data = responseData(response);
          
        });
    };

    const handlePostSearchApplicationReports  = () => {
        let searchInfo ={
            "filter": "",
            "itemOffset": 0,
            "itemCount": 30,
            "ApplicationId": 10
        };
       postSearchApplicationReports(useLegacyStore,searchInfo).then((response) => {
            console.log('response',response);
           // let data = responseData(response);
          
        });
    };

    const handlePostSearchGroups  = () => {
        let searchInfo ={
            "filter": "",
            "itemOffset": 0,
            "itemCount": 30
            
        };
       postSearchGroups(useLegacyStore,searchInfo).then((response) => {
            console.log('response',response);
           // let data = responseData(response);
          
        });
    };


    return (<BaseContainer title='Admin Dashboard'>
        <Row className='gx-4 gy-4 mt-0'>
            <Button variant="outline-primary" onClick={handleAdminLogin}>Login into Admin Server</Button>
            <Button variant="outline-primary" onClick={() => {
                apiAxios.post(getApiUrl('/api/suppliers/lookup/lookupsuppliers'), undefined, HEADERS_JSON)
                    .then((result) => {
                        try {
                            console.dir(result);
                        } catch (e1) {
                            toastError(e1);
                        }
                    }).catch(toastError);
            }}>Post /api/suppliers/lookup/lookupsuppliers</Button>
            <Button variant="outline-primary" onClick={() => {
                apiAxios.get(getApiUrl('/api/application/getApplicationRegistrationDetail/10'))
                    .then((result) => {
                        try {
                            console.dir(result);
                        } catch (e1) {
                            toastError(e1);
                        }
                    }).catch(toastError);
            }}>Get /api/application/getApplicationRegistrationDetail/10 </Button>
            <Button variant="outline-primary" onClick={() => {
                apiAxios.post(getApiUrl('/api/application/searchApplicationReports'), {
                    "filter": "",
                    "itemOffset": 0,
                    "itemCount": 30,
                    "ApplicationId": 10
                }, HEADERS_JSON)
                    .then((result) => {
                        try {
                            console.dir(result);
                        } catch (e1) {
                            toastError(e1);
                        }
                    }).catch(toastError);
            }}>Post /api/application/searchApplicationReports</Button>
                <Button variant="outline-primary" onClick={() => {
                 apiAxios.get(getLocationConfig().getRpCallUrl('/api/application/getApplicationRegistrationDetail/10'))
                    .then((result) => {
                        try {
                            console.dir(result);
                        } catch (e1) {
                            toastError(e1);
                        }
                    }).catch(toastError);
            }}>ZC - Get /api/application/getApplicationRegistrationDetail/10</Button>

              <Button variant="outline-primary" onClick={handleApplicationRegistrationDetail}>ZC -Legacy Store: Get /api/application/getApplicationRegistrationDetail/10</Button>
                <Button variant="outline-primary" onClick={handleFetchApplicationRegistrationDetail}>ZC - Legacy store: Fetch /api/application/getApplicationRegistrationDetail/10</Button>
                <Button variant="outline-primary" onClick={handlePostSearchApplicationReports} >Legacy - Post /api/application/searchApplicationReports</Button>
                <Button variant="outline-primary" onClick={handlePostSearchGroups}>Search Groups</Button>



            <Button variant="outline-primary" onClick={() => {
                apiAxios.post(getLocationConfig().getRpCallUrl('/api/groupManagement/searchLms'),{
                    "filter": "",
                    "itemOffset": 0,
                    "itemCount": 30,
                })
                    .then((result) => {
                        try {
                            console.dir(result);
                        } catch (e1) {
                            toastError(e1);
                        }
                    }).catch(toastError);

            }}>Search for groups rajko</Button>

            <Button variant="outline-primary" onClick={() => {
                apiAxios.post(getLocationConfig().getRpCallUrl('/api/trainingCard/groupManagement/search'),{
                    "groupId": 2362,
                    "itemOffset": 0,
                    "itemCount": 30,
                    "filter":""
                })
                    .then((result) => {
                        try {
                            console.dir(result);
                        } catch (e1) {
                            toastError(e1);
                        }
                    }).catch(toastError);

            }}>Search for training cards</Button>


            <Button variant="outline-primary" onClick={() => {
                apiAxios.post(getLocationConfig().getRpCallUrl('/api/permissions/search'),{
                    "groupId": 967,
                    "itemOffset": 0,
                    "itemCount": 30,
                    "filter":""
                })
                    .then((result) => {
                        try {
                            console.dir(result);
                        } catch (e1) {
                            toastError(e1);
                        }
                    }).catch(toastError);

            }}>Search for permissions in groups</Button>
        
        </Row>
    </BaseContainer>);
}
