import axios from "axios";
import FAQsCommonItems from '../../components/FAQsCommonItems';
import MailTo from '../../components/MailTo';
import useLocationConfig from '../../hooks/useLocationConfig';
import { Accordion, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from "../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";

/**
 * This function is used exclusively to check the success / failure of the B2C login 
 */
export const authAxios = axios.create({
    withCredentials: true
});

authAxios.interceptors.request.use((config) => {
    // Abort if no token present (user not logged in)
    const token = window.localStorage.getItem('token') !== null && window.localStorage.getItem('token').length > 0 ? window.localStorage.getItem('token').replace(/"/g, '') : "";
    if (token && token.length > 0) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (!token) {
        throw new Error('No Authorization Token');
    }
    return config;
}, (error) => {
    return Promise.reject(error); // Delegate error to calling side
});

/**
 * This function is used exclusively to handle a B2C login failure
 */
export const authAxiosOnError = (error, msalInstance, logout, currentAccount) => {
    try {
        console.error(error);
        const status = error.response?.status || 500;
        let redirectUrl;
        // eslint-disable-next-line
        if (status == '401') {
            redirectUrl = '/lms/autherror?error=401';
        } else {
            redirectUrl = `/lms/servererror?error=${status}`
        }
        if (currentAccount) {
            msalInstance.logoutRedirect({
                account: msalInstance.getActiveAccount(), // The account that needs to be signed out
                postLogoutRedirectUri: redirectUrl

            });
        } else {
            window.location = `${window.location.protocol}//${window.location.host}${redirectUrl}`
        }
    } finally {
        // Remove token for good measure
        logout();
    }
};

export default function LoginContractor() {
    const location = useLocation();
    const navigate = useNavigate()
    const { instance } = useMsal();
    const { login, logout } = useAuth();
    const { themeName } = useLocationConfig();
    const [inProgress, setInProgress] = useState(true);
    const queryParams = new URLSearchParams(location.search);
    const samlError = queryParams.get("error");
    const [showFAQs, setShowFAQs] = useState(false);

    const handleCloseFAQs = () => setShowFAQs(false);

    const handleShowFAQs = () => setShowFAQs(true);

    const onLoginWithB2CClick = () => {
        setInProgress(true);
        instance.loginRedirect();
    }

    const goToContractorRegistrationSetup = () => {
        navigate("/contractor-registration")
    }

    useEffect(() => {
        if (samlError) {
            window.location = `${window.location.protocol}//${window.location.host}/lms/autherror?error=${samlError}`
        }
    }, [samlError])

    useEffect(() => {
        // If not null then this is a B2C Account
        const currentAccount = instance.getActiveAccount();
        if (currentAccount) {
            const fn = async () => {
                setInProgress(true);
                const tokenRequest = {
                    account: currentAccount,
                    scopes: instance.config.auth.scopes,
                };
                await instance.acquireTokenSilent(tokenRequest).then(async response => {
                    login(response.accessToken)
                    await authAxios.get('/api/account/setApReToSesion').then(res => {
                        window.location.href = '/lms/mylearningplan';
                    }).catch((e) => {
                        authAxiosOnError(e, instance, logout, currentAccount);
                    })
                }).catch(e => {
                    authAxiosOnError(e, instance, logout, currentAccount);
                    setInProgress(false);
                });
            };
            fn();
        } else {
            setInProgress(false);
        }
    }, [instance])

    return (<>
        {
            (inProgress === true) ?
                <div style={{ minHeight: "100vh" }} className="d-flex justify-content-center align-items-center" >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="row">
                            <div className="spinner-border mt-5 text-primary" style={{ width: '5rem', height: '5rem' }}
                                role="status">
                            </div>
                        </div>
                        <div className="row mt-2">
                            <strong>Establishing session</strong>
                        </div>
                    </div>
                </div >
                :
                <>
                    <Row className="m-0 ">
                        <Col className="cls-login-col-left p-0" md={6} lg={5} xl={4} xxl={3}>
                            <div className="cls-login-colour-ovl d-flex align-items-center justify-content-center">
                                <Row style={{ width: '100%' }}>
                                    <Col sm={2} md={1}></Col>
                                    <Col>
                                        <Card className="mb-5" style={{ width: '100%' }}>
                                            <div className="d-flex justify-content-end"
                                                style={{ margin: ' 1rem 1.5rem 0 0' }}>
                                                {themeName ? <Card.Img variant="top"
                                                    src={require(`../../assets/instance/${themeName}/logo.png`)}
                                                    style={{ width: '140px' }} /> : <></>}
                                            </div>
                                            <Card.Body>
                                                <Card.Title className='m-2'>Learning Management System</Card.Title>
                                                <Form>
                                                    <Form.Group className="mb-3">
                                                        <Button
                                                            type="button"
                                                            style={{ backgroundColor: 'var(--app-login-ovl)' }}
                                                            variant="primary"
                                                            onClick={onLoginWithB2CClick}
                                                            className="w-100 mt-3">
                                                            UKPN Contractor Login
                                                        </Button>
                                                        <Button
                                                            type="button"
                                                            style={{ backgroundColor: 'var(--app-login-ovl)' }}
                                                            variant="primary"
                                                            onClick={goToContractorRegistrationSetup}
                                                            className="w-100 mt-3">
                                                            UKPN Contractor New Account Setup
                                                        </Button>
                                                    </Form.Group>
                                                </Form>
                                                <Row className="mt-3">
                                                    <Col style={{ width: '60px' }}>
                                                        <Button variant="clear" size="lg" onClick={handleShowFAQs}                                                        >
                                                            <FontAwesomeIcon style={{ color: 'var(--app-login-ovl)' }} icon={faCircleQuestion} className='m-0' />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={2} md={1}></Col>
                                </Row>
                            </div>
                        </Col>
                        <Col className="cls-login-col-right">
                            <div className="cls-login-colour-ovl"></div>
                        </Col>
                    </Row>
                    <Modal fullscreen='xl-down' size='xl' show={showFAQs} onHide={handleCloseFAQs}>
                        <Modal.Header closeButton>
                            <Modal.Title>FAQs</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h5>Learning Portal - FAQs Frequently Asked Questions</h5>
                            <p>The Learning Portal manages the administration and delivery of online learning
                                (eLearning)
                                courses and learning or training materials, and at the same time tracks members’ online
                                learning
                                progression.</p>
                            <Accordion>
                                <FAQsCommonItems serviceEmail='learning.service@wdr.co.uk' serviceLabel='Learning Service' />
                            </Accordion>
                            <p className="mt-4 text-center">
                                <h6>Can't find the answer to your question?</h6>
                                <MailTo email='learning.service@wdr.co.uk?subject=LMS Support Query'>Click Here</MailTo> to send
                                us an
                                email.
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleCloseFAQs}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </>
        }
    </>);
}
