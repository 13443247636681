import React, {useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Button, Col, Row} from "react-bootstrap";
import {Checkbox, FormControlLabel, TextField} from "@mui/material";

const disabledNames = [
    "Overview",
    "Target-audience",
    "Learning-content",
    "Prerequisites",
    "Pre-and-post-course-work"
]

const SingleSection = ({
                           onChange = () => null,
                           onTitleChange = () => null,
                           onDeleteSection = () => null,
                           onCheckBoxChange = () => null,
                           areButtonsDisabled,
                           section,
                           index
                       }) => {

    const renderCheckAndButton = () => {
        return disabledNames.includes(section.Name) ?
            <></>
            :
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={areButtonsDisabled}
                            onChange={() => {
                                onCheckBoxChange(index)
                            }}
                            checked={!!section.IsVisible}
                        />
                    }
                    label="Display title"
                />
                <Button
                    onClick={() => {
                        onDeleteSection(index)
                    }}
                    size={"sm"}
                    variant={"light"}
                    className={"mt-2"}
                    disabled={areButtonsDisabled}
                >
                    Delete
                </Button>
            </>
    }

    return (
        <div className={"mt-3 w-100"}>
            <Row>
                <Col xs={12} md={3}>
                    <TextField
                        disabled={disabledNames.includes(section.Name) || areButtonsDisabled}
                        onChange={(e) => {
                            onTitleChange(e, index)
                        }}
                        id="standard-basic"
                        label={`Name`}
                        value={section.Title}
                        variant="standard"
                    />
                    {renderCheckAndButton()}
                </Col>
                <Col xs={12} md={9}>
                    <ReactQuill
                        theme="snow"
                        defaultValue={section.Content}
                        onChange={(e) => {
                            onChange(e, index)
                        }}/>
                </Col>
            </Row>
        </div>
    );
};

export default SingleSection;
