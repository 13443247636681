import BaseContainer from '../../../components/BaseContainer';
import CustomTableView from '../../../components/common/generic-table-view';
import UserCard from '../../../components/UserCard';
import ViewDocument from './view-document';
import { cellToLocaleDateTimeString } from '../../../helpers/formatHelper';
import { faArrowAltSquareDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TableContainer } from '../../../helpers/tableHelper';
import { teamProfileService } from '../services/team-profile.service';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams, Link, redirect, useHistory } from "react-router-dom";

export default function UserDocuments(props) {
    const [userId, setUserId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [documents, setDocuments] = useState([]);
    const [searchParams] = useSearchParams();
    const [showDocument, setShowDocument] = useState(false);
    const [docData, setDocData] = useState(null);
    const [documentMimeType, setDocumentMimeType] = useState(null);


    useEffect(() => {
        let id = parseInt(searchParams.get("id"));
        setUserId(id > 0 ? id : 0);
    }, [searchParams]);

    useEffect(() => {
        if (userId > 0) {
            setLoading(true);
            teamProfileService.GetDocumentsWithoutBlobForPerson(userId)
                .then((result) => {
                    setUserInfo(result.person);
                    setDocuments(result.evidenceFolders);
                })
                .finally(() => { setLoading(false) });
        }
    }, [userId]);


    const columns = useMemo(
        () => [
            {
                accessorKey: 'dateAdded',
                header: 'Date Issued',
                size: 150,
                Cell: cellToLocaleDateTimeString
            },
            {
                accessorKey: 'courseTitle',
                header: 'Course',
                size: 300,
            },
            {
                accessorKey: 'documentName',
                header: 'Title',
                size: 200,
                muiTableBodyCellProps: {
                    classes: { root: 'fw-unset' }   // Flexible width
                },
                Cell: ({ cell, row }) => <span dangerouslySetInnerHTML={{ __html: cell.getValue() }} />
            },
            {
                accessorKey: 'documentType',
                header: 'Type',
            },
            {
                accessorKey: 'documentID',
                header: '',
                muiTableBodyCellProps: {
                    align: 'center',
                    sx: {
                        textOverflow: 'unset'
                    }
                },
                size: 75,
                Cell: ({ cell, row }) => {
                    return (
                        <Link to={{ pathname: '/lms/userdocument/' + cell.getValue() }}>
                            <FontAwesomeIcon icon={faArrowAltSquareDown} className='text-info' size='lg' />
                        </Link>
                    )
                }
            },
        ],
        [],
    );

    const rowClickEventHandler = (event, row) => {
        event.preventDefault();
        //console.log(JSON.stringify(row.original));
        if (row.original.document != null) {
            setDocData(row.original.document);
            setDocumentMimeType(row.original.documentMimeType);
            setShowDocument(true);
        }
    }

    const handleBackButtonClick = (event) => {
        // event.preventDefault();
        setShowDocument(false);
    }

    return (
        <BaseContainer title='My Team Profiles / Evidence Folder'>
            <TableContainer>
                {
                    showDocument ?
                        <ViewDocument
                            docData={docData}
                            documentMimeType={documentMimeType}
                            onBackButtonClick={handleBackButtonClick}
                        />
                        :
                        <>
                            <UserCard {...userInfo} loading={loading} displayBackButton={true} />
                            <CustomTableView
                                users={documents}
                                columns={columns}
                                loading={loading}
                                rowClickEventHandler={(event, row) => rowClickEventHandler(event, row)}
                            >
                            </CustomTableView>
                        </>
                }

            </TableContainer>
        </BaseContainer>);
}
