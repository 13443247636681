import BaseBeginContainer from "../components/BaseBeginContainer";
import BaseContainer from "../components/BaseContainer";
import MaterialReactTable from 'material-react-table';
import shallow from 'zustand/shallow';
import useLmsStore from '../hooks/useLmsStore'
import { BeginButton } from '../components/CustomButtons'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row } from 'react-bootstrap';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { faAward, faUserGear } from '@fortawesome/pro-solid-svg-icons';
import { faCirclePlay } from '@fortawesome/pro-solid-svg-icons';
import { useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiAxiosCalls } from '../services/apiAxiosCalls';

function ExternalTitle(props) {
    return (<>{props.title} <FontAwesomeIcon size='xs' icon={faArrowUpRightFromSquare} className='text-mutted' /></>);
};
const UKPNServiceAdminDashboardCore = ({ dimensions }) => {

    const { personBasic, fetchPersonBasic } = useLmsStore(state =>
    ({
        personBasic: state.personBasic.data,
        fetchPersonBasic: state.fetchPersonBasic
    }), shallow);

    useEffect(() => {
        fetchPersonBasic();
    }, []);
    return (
        <BaseBeginContainer dimensions={dimensions}>
            <BeginButton title={<ExternalTitle title='Team Competencies' />} description='View, upload demonstrations and approve people competencies.' icon={faUserGear} href={apiAxiosCalls.hrefToAdminServer(personBasic, 'portal.teamcompetencies')} target='admin-tab' />
            <BeginButton title={<ExternalTitle title='People Qualifications' />} description='View people qualifications.' icon={faAward} href={apiAxiosCalls.hrefToAdminServer(personBasic, 'portal.teamqualifications')} target='admin-tab' />
        </BaseBeginContainer>
    );
};

export default function UKPNServiceAdminDashboard(props) {

    return (<BaseContainer title='UKPN Services Admin Dashboard' >
        <UKPNServiceAdminDashboardCore />
    </BaseContainer>);
}