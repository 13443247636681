import * as dayjs from "dayjs";
import LinearProgress from "@mui/material/LinearProgress";
import MaterialReactTable from "material-react-table";
import ViewCertificatesHistory from "./view-certificates-history";
import ViewCertificatesPdf from "./view-certificates-pdf";
import { Alert, Container, Row, Col, Button } from "react-bootstrap";
import { apiAxiosCalls } from "../../../services/apiAxiosCalls";
import { confirmAlert } from "react-confirm-alert";
import { faCheck, faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";

export default function ViewAndPrintCertificates({ personID, dimensions }) {
  const [rowSelection, setRowSelection] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [personData, setPersonData] = useState({});
  const [qualificationsWithApproval, setQualificationsWithApproval] = useState(
    []
  );
  const [showCertificateHistory, setShowCertificateHistory] = useState(false);
  const [allApproved, setAllApproved] = useState(true);
  const [networkTypes, setNetworkTypes] = useState([]);
  const [certificatePdf, setCertificatePdf] = useState(null);
  const [showCertificate, setShowCertificate] = useState(false);

  const getViewAndPrintCertificates = async (personId) => {
    try {
      const { data } = await apiAxiosCalls.GetViewPrintCertificateContainer(
        personId
      );
      if (!data.isValid) {
        throw new Error("Error getting view & print certificates");
      } else {
        populatePersonAndApprovalCertData(data);
      }
    } catch (error) {
      console.log(`Error fetching view & print certificates: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (personID > 0) {
      setLoading(true);
      getViewAndPrintCertificates(personID);
    }
  }, [personID]);

  const populatePersonAndApprovalCertData = (data) => {
    setPersonData({
      fullName: data.value.person.fullName,
      firstName: data.value.person.firstName,
      surname: data.value.person.surname,
      employeeID: data.value.person.employeeNumber,
      waPgroup: data.value.person.waPgroup,
      overallAuthorisation: data.value.person.overallAuthorisation,
    });

    setQualificationsWithApproval([
      ...data.value.personCompetenciesWithApproval.map((x, index) => ({
        index: index,
        description: x.title,
        proficiency: x.profDesc,
        group: x.description,
        validFrom: x.validFrom,
        validTo: x.validTo,
        fromDate: x.fromDate,
        toDate: x.toDate,
        qualificationId: x.qualificationID,
        approved: x.approved,
        approver: x.approver,
        approvedDate: x.approvedDate,
        highlight: x.highlight,
      })),
    ]);
    let totalApproved = data.value.personCompetenciesWithApproval.filter(
      (x) => x.approved == true
    );
    setAllApproved(
      totalApproved?.length == data.value.personCompetenciesWithApproval?.length
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "group",
        header: "Group",
        size: 150,
      },
      {
        accessorKey: "description",
        header: "Description",
        size: 150,
      },
      {
        accessorKey: "proficiency",
        header: "Proficiency",
        size: 150,
      },
      {
        accessorKey: "fromDate",
        header: "Valid from",
        size: 30,
        sortingFns: "datetime",
        Cell: ({ cell, row }) => (
          <span>
            {dayjs(row.original.fromDate)
              .format("DD/MM/YYYY")
              .includes("01/01/1901")
              ? " No Expiry"
              : dayjs(row.original.fromDate).format("DD/MM/YYYY")}
          </span>
        ),
      },
      {
        accessorKey: "toDate",
        header: "Valid to",
        size: 30,
        sortingFns: "datetime",
        Cell: ({ cell, row }) => (
          <span>
            {dayjs(row.original.toDate)
              .format("DD/MM/YYYY")
              .includes("01/01/1901")
              ? " No Expiry"
              : dayjs(row.original.toDate).format("DD/MM/YYYY")}
          </span>
        ),
      },
      {
        accessorKey: "approved",
        header: "Approved",
        size: 20,
        Cell: ({ cell, row }) => (
          <>
            {cell.getValue() == true ? (
              <FontAwesomeIcon color="green" icon={faCheck} size="lg" />
            ) : (
              <FontAwesomeIcon color="red" icon={faXmark} size="lg" />
            )}
          </>
        ),
      },
    ],
    []
  );

  const sortedList = useMemo(() => {
    if (qualificationsWithApproval) {
      let sortByList = [...qualificationsWithApproval].sort((a, b) =>
        a.group.localeCompare(b.group)
      );
      return sortByList;
    }
    return [];
  }, [qualificationsWithApproval]);

  const onOptionChange = (event) => {
    const { value, checked} = event.target;
    if (checked) {
        setNetworkTypes([...networkTypes, value]);
    } else {
      setNetworkTypes(networkTypes.filter(type => type !== value));
    }

    event.target.focus();
  };

  const approvePendingCertificates = async () => {
    try {
      let inputParams = {
        employeeId: personData.employeeID,
        personId: personID.toString(),
      };
      setLoading(true);
      const { data } = await apiAxiosCalls.ApprovePendingCompetencyCertificates(
        inputParams
      );
      if (!data.isValid) {
        throw new Error(
          "Error in the approval process of pending certificates."
        );
      } else {
        populatePersonAndApprovalCertData(data);
        setAllApproved(true);
      }
    } catch (error) {
      throw new Error("Error in the approval process of pending certificates.");
    } finally {
      setLoading(false);
    }
  };

  const generatePdfCertificate = async () => {
    if (networkTypes.length == 0) {
      displayPleaseSelectNetworkAlert();
      return;
    }
    let inputParams = {
      NetworkTypes: networkTypes,
      PersonID: personID.toString(),
    };

    try {
      setLoading(true);
      let { data } = await apiAxiosCalls.GenerateAndPrintCompetencyCertificate(
        inputParams
      );

      if (!data.isValid) {
        throw new Error("Error generating pdf certificate");
      } else {
        if (data.value.result && data.value.result.length > 0) {
          setCertificatePdf(data.value.result);
          setShowCertificateHistory(false);
          setShowCertificate(true);
        } else if (data.value.message && data.value.message.length > 0) {
          alert(data.value.message);
        }
      }
    } catch (error) {
      throw new Error(`Error generating Pdf certificate:${error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleBackButtonClick = () => {
    setLoading(true);
    setShowCertificateHistory(false); // if back button is clicked from view-certificate-history to return back to view-certificate
    setShowCertificate(false); // if back button is clicked from view-certificate-pdf to return back to view-certificate
    setNetworkTypes([]);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  const displayPleaseSelectNetworkAlert = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className=" card" style={{ width: "25rem" }}>
            <div className="card-body">
              <h5 className="card-title">Please note:</h5>
              <p className="card-text">
                You Must select at least one network to print a competency
                certificate.
              </p>
              <div className="d-flex justify-content-center">
                <Button
                  variant="btn btn-outline-primary"
                  onClick={onClose}
                  style={{ width: "100px" }}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <Container
      fluid
      className="overflow-auto"
      style={{ maxHeight: "calc(100% - 50px)" }}
    >
      {!showCertificateHistory && !showCertificate ? (
        <>
          {loading ? (
            <Row>
              <Col>
                <LinearProgress />
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col>
                  <p className="text-muted">Print Competency Documents</p>
                  <div style={{ fontStyle: "normal", fontSize: "12px" }}>
                    <div>
                      Print Competency Documents for{" "}
                      <b>
                        {personData?.firstName} {personData.surname}{" "}
                        &nbsp;&nbsp;&nbsp; {personData?.employeeID}{" "}
                      </b>
                    </div>
                    <div>
                      Accreditation Group: <b>{personData?.waPgroup}</b>
                    </div>
                    <div>
                      Overall Authorisation:{" "}
                      <b>{personData?.overallAuthorisation}</b>
                    </div>
                    <div style={{ marginTop: "15px", fontWeight: "bold" }}>
                      Issue for Network(s):
                    </div>
                    <div
                      key={`inline-radio`}
                      className="m-1 d-flex"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <div className="form-check form-check-inline">
                        <input
                          name="group1"
                          type="checkbox"
                          id="inline-radio-lpn"
                          className="form-check-input"
                          value="lpn"
                          checked={networkTypes.includes("lpn")}
                          onChange={onOptionChange}
                        />
                        <label
                          htmlFor="inline-radio-lpn"
                          className="form-check-label"
                        >
                          LPN
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          name="group2"
                          type="checkbox"
                          id="inline-radio-spn"
                          className="form-check-input"
                          value="spn"
                          checked={networkTypes.includes("spn")}
                          onChange={onOptionChange}
                        />
                        <label
                          htmlFor="inline-radio-spn"
                          className="form-check-label"
                        >
                          SPN
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          name="group3"
                          type="checkbox"
                          id="inline-radio-epn"
                          className="form-check-input"
                          value="epn"
                          checked={networkTypes.includes("epn")}
                          onChange={onOptionChange}
                        />
                        <label
                          htmlFor="inline-radio-epn"
                          className="form-check-label"
                        >
                          EPN
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          name="group4"
                          type="checkbox"
                          id="inline-radio-private"
                          className="form-check-input"
                          value="private"
                          checked={networkTypes.includes("private")}
                          onChange={onOptionChange}
                        />
                        <label
                          htmlFor="inline-radio-private"
                          className="form-check-label"
                        >
                          Private
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          name="group5"
                          type="checkbox"
                          id="inline-radio-idno"
                          className="form-check-input"
                          value="idno"
                          checked={networkTypes.includes("idno")}
                          onChange={onOptionChange}
                        />
                        <label
                          htmlFor="inline-radio-idno"
                          className="form-check-label"
                        >
                          IDNO
                        </label>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="text-center" style={{ maxWidth: "270px" }}>
                  <Button
                    variant="outline-secondary"
                    disabled={!allApproved}
                    onClick={(event) => {
                      event.preventDefault();
                      generatePdfCertificate();
                    }}
                  >
                    Print Competency Certificate
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="mt-2"
                    onClick={(event) => {
                      event.preventDefault();
                      setShowCertificateHistory(true);
                    }}
                  >
                    View Certificate History
                  </Button>
                </Col>
              </Row>
              {!allApproved ? (
                <Row>
                  <Col>
                    <Alert variant="danger" className="m-0">
                      <div className="fw-bold">
                        Dear Manager - the competencies below with a{" "}
                        <FontAwesomeIcon
                          style={{ fontWeight: "bold", fontSize: "16" }}
                          size={"lg"}
                          icon={faXmark}
                        ></FontAwesomeIcon>{" "}
                        require your attention as they have either been added or
                        altered.
                      </div>
                      <div className="pt-1">
                        You must now give your consent by clicking the `Approve
                        Qualifications` button below
                      </div>
                      <div className="pt-1">
                        Once you have done this, you must print and issue a new
                        competency certificate to ensure that the person's
                        competence certificate and LMS record are the same.
                      </div>
                    </Alert>
                  </Col>
                  <Col className="text-center" style={{ maxWidth: "270px" }}>
                    <Button
                      variant={"outline-secondary"}
                      className="mt-2"
                      onClick={(event) => {
                        event.preventDefault();
                        approvePendingCertificates();
                      }}
                    >
                      Approve Qualifications
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </>
          )}
          <Row>
            <Col className="border-end">
              <MaterialReactTable
                columns={columns}
                data={sortedList || []}
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableStickyHeader={true}
                enableFullScreenToggle={false}
                enableHiding={false}
                enablePagination={false}
                enableTopToolbar={true}
                enableFilterMatchHighlighting={true}
                globalFilterFn="contains" //By default is fuzzy
                enableGlobalFilter={true}
                getRowId={(originalRow) => originalRow.index}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: () =>
                    setRowSelection((prev) => ({ [row.id]: !prev[row.id] })),
                  hover: true,
                  selected: rowSelection[row.id],
                  sx: {
                    cursor: "pointer",
                  },
                })}
                onGlobalFilterChange={setSearchString} //zc - new for custom search
                muiTableContainerProps={({ table }) =>
                  dimensions.computedMuiTableContainerProps(table, null, {
                    "min-height": "300px",
                  })
                }
                renderTopToolbarCustomActions={() => (
                  <h6 className="text-muted border border-secondary shadow p-2">
                    Competency Documents
                  </h6>
                )}
                initialState={{
                  globalFilter: searchString, //zc - new for custom search
                  showGlobalFilter: true, //This displays the seach generic way for the materia react table
                }}
                state={{
                  isLoading: loading || !qualificationsWithApproval,
                  globalFilter: searchString, //zc - new for custom search
                }}
              />
            </Col>
          </Row>
        </>
      ) : null}
      {showCertificateHistory && !showCertificate ? (
        <ViewCertificatesHistory
          personID={personID}
          onBackButtonClick={handleBackButtonClick}
          dimensions={dimensions}
        />
      ) : null}
      {showCertificate ? (
        <ViewCertificatesPdf
          pdfData={certificatePdf}
          certId={0}
          onBackButtonClick={handleBackButtonClick}
        />
      ) : null}
    </Container>
  );
}
