import React, { useState, useRef } from 'react';
import { Container, Row, Col, Form } from "react-bootstrap";
import useLmsStore, { apiAxios, postPersonfilestore } from "../hooks/useLmsStore";
import { toastError } from '../hooks/useLmsStore';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import { CropperRef, CircleStencil, Cropper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import 'react-advanced-cropper/dist/themes/corners.css';
import shallow from 'zustand/shallow';

const ModalMyProfile = ({ closeFn = () => null, updateImageFn = (img) => null, open = false, personSummaryError, personSummaryLoading, personSummary }) => {
    const [image, setImage] = useState(null);
    const [profileImageName, setProfileImageName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [save, setSave] = useState(false);

    const cropperRef = useRef(null);

    const { hasPermission } = useLmsStore(state =>
    ({
        hasPermission: state.hasPermission
    }), shallow)

    const loadProfileFile = (e) => {
        e.preventDefault();

        // 500 KB * 1024 bytes/KB = 512,000 bytes
        const maxFileSize = 512000;

        setProfileImageName(e.target.files[0].name)
        setFileSize(e.target.files[0].size);

        let file;
        if (e.target.files.length) {
            file = e.target.files[0];
            setLoading(true);
            if (fileSize >= maxFileSize) {
                toastError({ message: `Image should be less than 500KB in size` })

                setErrorMessage('File size exceeds the limit. Limit to 500KB.');
                setLoading(false);
                setSave(false);
            } else {
                setSave(true);
                setErrorMessage('');
                if (file && file.size > 0) {
                    if (file instanceof Blob) {
                        const reader = new FileReader();
                        reader.onload= () => {
                            setImage(reader.result);
                        };
                        reader.onerror = (error) =>
                        {
                            console.error("FileReader error:", error);
                        }
                        reader.readAsDataURL(file);
                    } else {
                        console.error("Selected file is not a blob");
                    }
                }
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
            }
        }
    };

    const getCroppedImage = () => {
        const cropper = cropperRef.current;
        return cropper.getCanvas().toDataURL();
      };

    const handleMyProfileUpload = async e => {
        e.preventDefault();

        if (!profileImageName) {
            setErrorMessage('Please select a file to upload.');
            return;
        }

        if (profileImageName) {
            // Call crop function if cropperRef.current is available
            const croppedImage = getCroppedImage();

            const myProfileFormData = new FormData();
            myProfileFormData.append("file", dataURLtoBlob(croppedImage), profileImageName);
            for (let [key, value] of myProfileFormData.entries()) {
                console.log(`${key}:`, value);
              }
              await apiAxios.postForm('/api/personfilestore/upload', myProfileFormData);
        } else {
            console.error('Cropper reference is not properly initialized.');
        }
    };

    const dataURLtoBlob = (dataurl) => {
        const [header, base64] = dataurl.split(',');
        const mime = header.match(/:(.*?);/);
        const bstr = atob(base64);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      };

    const [fileSize, setFileSize] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');

    function ShowMyProfile(props) {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        {image ? (
                            <Cropper
                                ref={cropperRef}
                                stencilProps={{
                                    aspectRatio: 1 / 1,
                                    aspectRatio: 1 / 1,
                                    movable: true,
                                    grid: true,
                                    resizable: true
                                }}
                                className={'cropper'}
                                src={image} // Use the preview image as the source for cropping
                                stencilComponent={CircleStencil} // Use CircleStencil for circular cropping
                            />
                        ) : (
                            <>
                                <Image fluid src={props.data.photograph} />
                            </>
                        )}

                    </Col>
                    <Col>
                        <h1>{props.data.fullName}</h1>
                        <h3>{props.data.jobTitle}</h3>
                        <Form>
                            <div className="mb-2">
                                <input
                                    type="file"
                                    accept="image/*"
                                    name="image"
                                    id="file"
                                    onChange={loadProfileFile}
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="file" style={{
                                    cursor: 'pointer',
                                    fontWeight: '2',
                                    padding: '5px',  // Adjust padding according to your preference
                                    border: '1px solid #ccc',  // Add border for a button-like appearance
                                    borderRadius: '4px',  // Optional: Add border-radius for rounded corners
                                    backgroundColor: '#E9ECEF',  // Optional: Set a background color
                                    color: '#darkgray',
                                    marginRight: '10px',
                                }}>
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                                    )}
                                    Choose File
                                </label>

                                {profileImageName ? (<span>{profileImageName}</span>) : (<span>No file chosen</span>)}
                                {errorMessage && <div className="text-danger">{errorMessage}</div>}
                            </div>
                            <>
                                {hasPermission('pe.photoupload') ? (
                                    <>
                                        <Button type="button" disabled={!profileImageName || !save} className="btn btn-primary ml-2" onClick={handleMyProfileUpload}>
                                            Save
                                        </Button>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        </Form>
                    </Col>
                </Row>
                <Row className="mt-2 w-100"><hr /></Row>
                <Row>
                    <h3>Personal Details</h3>
                    <Col>
                        <div className="fw-bold">First Name</div>
                        <div className="fst-italic"> { props.data.knownAs ? props.data.knownAs : props.data.firstName ? props.data.firstName : "None"}<br /></div>
                        <div className="fw-bold">Last Name</div>
                        <div className="fst-italic"> {props.data.lastName ? props.data.lastName : "None"}<br /></div>
                        <div className="fw-bold">Job Title</div>
                        <div className="fst-italic"> {props.data.jobTitle ? props.data.jobTitle : "None"}<br /></div>
                        <div className="fw-bold">Line Manager</div>
                        <div className="fst-italic"> {props.data.managerName ? props.data.managerName : "None"}<br /></div>
                        <div className="fw-bold">Department</div>
                        <div className="fst-italic"> {props.data.department ? props.data.department : "None"}<br /></div>
                    </Col>
                    <Col>
                        <div className="fw-bold">Email Address</div>
                        <div className="fst-italic"> {props.data.email ? props.data.email : "None"}<br /></div>
                        <div className="fw-bold">Phone Number</div>
                        <div className="fst-italic"> {props.data.phoneNumber ? props.data.phoneNumber : "None"}<br /></div>
                        <div className="fw-bold">Company</div>
                        <div className="fst-italic"> {props.data.company ? props.data.company : "None"}<br /></div>
                        <div className="fw-bold">Division</div>
                        <div className="fst-italic"> {props.data.division ? props.data.division : "None"}<br /></div>
                        <div className="fw-bold">Team</div>
                        <div className="fst-italic"> {props.data.team ? props.data.team : "None"}<br /></div>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Modal fullscreen='md-down' show={open} onHide={closeFn} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{personSummaryError ? 'My Profile Error' : personSummaryLoading || !personSummary ? 'My Profile Loading...' : 'My Profile'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {personSummaryError ? <h6>Error!</h6> : personSummaryLoading || !personSummary ? <div className="loader-container"><div className="loader"></div></div> : <ShowMyProfile data={personSummary} />}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={closeFn}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalMyProfile;