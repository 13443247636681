import React, {useEffect, useMemo, useState} from 'react';
import {apiAxiosCalls} from "../../../services/apiAxiosCalls";
import MaterialReactTable from "material-react-table";
import EventDetailsModal from "./EventDetailsModal";
import {Button} from "react-bootstrap";
import useLmsStore from "../../../hooks/useLmsStore";
import shallow from "zustand/shallow";

const MyTeamTrainingHistoryCourseView = ({
                                             selectedCourse,
                                             dimensions
                                         }) => {

    const [trainingHistory, setTrainingHistory] = useState([])
    const [showEventInfoModal, setShowEventInfoModal] = useState(false)
    const [selectedEmploy, setSelectedEmploy] = useState(null)
    const [globalSearchString, setGlobalSearchString] = useState("")
    const [loading, setLoading] = useState(false)
    const { computedMuiTableContainerProps } = dimensions;

    const {personSettings} = useLmsStore(state =>
        ({
            personSettings: state.personSettings.data,
        }), shallow)

    const getTrainingHistoryForCourse = async () => {
        try {
            setLoading(true)
            const {data} = await apiAxiosCalls.GetStaffTrainingHistoryByCourse(selectedCourse.CourseTitle)
            setTrainingHistory(data)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    const columns = useMemo(
        () =>
            [
                {
                    accessorKey: 'employeeId',
                    header: 'Employee / Title',
                    Cell: ({cell, row}) => {
                        const rowOriginal = row.original

                        return <>
                            {rowOriginal.firstName} {rowOriginal.lastName} {rowOriginal.employeeId}
                        </>
                    }
                },
                {
                    accessorKey: 'firstName',
                    header: 'firstName',
                },
                {
                    accessorKey: 'lastName',
                    header: 'lastName',
                },
                {
                    accessorKey: 'passFail',
                    header: 'Status',
                },
                {
                    accessorKey: 'startDate',
                    header: 'Start Date',
                },

            ],
        [],
    );


    useEffect(() => {
        getTrainingHistoryForCourse()
    }, [selectedCourse.CourseID]);


    return (
        <div>
            <MaterialReactTable
                columns={columns}
                data={trainingHistory}
                enableBottomToolbar={true}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableStickyHeader={true}
                enableFullScreenToggle={false}
                enableGrouping={false}
                enableHiding={false}
                enablePagination={false}
                enableSorting={false}
                enableTopToolbar={true}
                enableFilterMatchHighlighting={true}
                globalFilterFn="contains"//By default is fuzzy
                //globalFilterFn={customGlobalFilterFn}
                enableGlobalFilter={true}
                onGlobalFilterChange={setGlobalSearchString}
                getRowId={(originalRow) => originalRow.personID}
                muiTableBodyRowProps={({row}) => ({})}
                muiTableContainerProps={({ table }) => computedMuiTableContainerProps(table)}

                muiTableHeadProps={{}}
                muiToolbarAlertBannerProps={{
                    sx: {
                        display: 'none'
                    }
                }}
                initialState={{
                    showGlobalFilter: true,
                }}
                state={{
                    isLoading: loading,
                    globalFilter: globalSearchString,
                    columnVisibility: {
                        firstName: false,
                        lastName: false
                    }
                }}
            />
            <EventDetailsModal
                courseCode={selectedCourse?.CourseCode}
                title={selectedCourse?.CourseTitle}
                personID={selectedEmploy?.personID}
                handleClose={() => {
                    setShowEventInfoModal(false)
                }}
                show={showEventInfoModal}
            />
        </div>
    );
};

export default MyTeamTrainingHistoryCourseView;
