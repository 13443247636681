import { useEffect, useMemo, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { TableContainer } from "../../../helpers/tableHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faCalendarDays,
} from "@fortawesome/pro-light-svg-icons";

import { apiAxiosCalls } from "../../../services/apiAxiosCalls";
import CustomTableView from "../../../components/common/generic-table-view";
import {
  FilterClearButton,
  FilterBadgeButton,
} from "../../../components/CustomButtons";
import * as dayjs from "dayjs";

export default function UpcomingEventsList(props) {
  const [loading, setLoading] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [displayUpcomingEvents, setDisplayUpcomingEvents] = useState(false);
  const [displaySubordinates, setDisplaySubordinates] = useState(false);

  const [breadcrumb, setBreadcrumb] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [filterId, setFilterId] = useState(2);
  const [displayDateRange, setDisplayDateRange] = useState("");

  const handleUserSelection = (user, type) => {
    user.personID = props.userView?.personID;
    setSelectedUser(user);

    if (type === 0) {
      // zero indicates main user (manager)
      setBreadcrumb([user]); // Add/reset user to breadcrumb
    } else {
      setBreadcrumb([...breadcrumb, user]); // Add user to breadcrumb
    }
  };

  const handleBreadcrumbClick = (index) => {
    setBreadcrumb(breadcrumb.slice(0, index + 1)); // Remove users after clicked index
    setSelectedUser(breadcrumb[index]);
  };

  const getFirstLevelStaffList = async (managerId) => {
    try {
      if (managerId == null || managerId == undefined) {
        throw new Error(
          "Error getting direct staff list with invalid manager id"
        );
        return;
      }

      setDisplaySubordinates(false);
      setLoading(true);
      const { data } = await apiAxiosCalls.getFirstLevelStaffList(managerId);

      if (!data.isValid) {
        throw new Error("Error getting direct staff list");
      } else {
        setUsers([
          ...data.value.map((x) => ({
            personID: x.personID,
            employeeID: x.userId,
            jobTitle: x.jobTitle,
            firstname: x.firstname,
            surname: x.surname,
            fullname: x.fullname,
            emailAddress: x.emailAddress,
          })),
        ]);
      }
    } catch (error) {
      console.log(`Error getting direct staff list: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("props.selectedUser:" + JSON.stringify(props.selectedUser));
    handleUserSelection(props.selectedUser, 0);
    getFirstLevelStaffList(props.selectedUser.managerID);
  }, [props.selectedUser]);

  const isStaffSelected = () => {
    if (selectedUser && selectedUser.employeePersonID) return true;
    return false;
  };
  const getPersonId = () => {
    {
      return selectedUser.employeePersonID
        ? selectedUser.employeePersonID
        : selectedUser.personID;
    }
  };

  const getEmployeeId = () => {
    {
      return props.searchEventsType == 0 ? props.selectedUser.employeeID : "";
    }
  };

  function toggleDisplaySubordinates() {
    setDisplaySubordinates(!displaySubordinates);

    setLoading(!displaySubordinates);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }

  const displayData = (event, actionType, fullname, personID, employeeID) => {
    if (personID && fullname) {
      let userArr = [];
      userArr.push(props.selectedUser);

      let employeeUser = {
        personID: props.userView?.personID,
        name: fullname,
        employeePersonID: personID,
        managerID: null,
        employeeID: employeeID,
      };
      userArr.push(employeeUser);
      setBreadcrumb([...breadcrumb, ...userArr]);
      setSelectedUser(employeeUser);
      console.log("Breadcrumb:" + JSON.stringify(breadcrumb));
    }

    switch (actionType) {
      case 1: { // display upcoming events
        setDisplaySubordinates(false);
        setDisplayUpcomingEvents(true);

        break;
      }
    }
  };

  const getUpcomingEvents = async (
    personId,
    managerId,
    startDate,
    endDate,
    searchEventsType
  ) => {
    try {
      let inputParams = {};
      let currentDate = dayjs(new Date()).format(props.dateFormat);
      if (props.searchEventsType == 1) {
        inputParams = {
          ukpnPersonId: managerId,
          status: filterId,
          startDate: startDate
            ? dayjs(startDate).format(props.dateFormat)
            : currentDate,
          toDate: endDate
            ? dayjs(endDate).format(props.dateFormat)
            : currentDate,
        };
        let dateRange =
          dayjs(startDate).format("DD/MM/YYYY") +
          " - " +
          dayjs(endDate).format("DD/MM/YYYY");
        setDisplayDateRange(dateRange);
      } else {
        let defaultDate = "01/01/0001 00:00:00";
        inputParams = {
          ukpnPersonId: personId,
          status: filterId,
          startDate: defaultDate,
          toDate: defaultDate,
        };
        setDisplayDateRange("");
      }
      setLoading(true);
      const { data } = await apiAxiosCalls.GetFilteredEventData(
        inputParams,
        props.searchEventsType
      );
      {
        setUpcomingEvents([
          ...data.map((x) => ({
            eventID: x.eventID,
            courseTitle: x.courseTitle,
            startDate: x.startDate,
            status: x.status,
            personFullName: x.PersonFullName,
            subRows: x.subRows,
          })),
        ]);
      }
    } catch (error) {
      console.log(`Error fetching upcoming events data: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    displayData(null, 1);
    getUpcomingEvents(
      props.selectedUser.personID,
      props.selectedUser.managerID,
      props.startDate,
      props.endDate,
      props.searchEventsType
    );
  }, [
    props.selectedUser.personID,
    props.selectedUser.managerID,
    props.startDate,
    props.endDate,
    props.searchEventsType,
    filterId,
  ]);

  const usersColumns = useMemo(
    () => [
      {
        accessorKey: "fullname",
        header: "",
        size: 500,
      },

      {
        accessorKey: "employeeID",
        header: "",
        size: 150,
      },
      {
        accessorKey: "firstname",
        header: "",
        size: 150,
        //Format a number in a cell render
        Cell: ({ cell, row }) => (
          <>
            <Link
              to="/"
              onClick={(event) => {
                event.preventDefault();
                displayData(
                  event,
                  1,
                  row.original.fullname,
                  row.original.personID,
                  row.original.employeeID
                );
                getUpcomingEvents(
                  row.original.personID,
                  props.startDate,
                  props.endDate,
                  props.searchEventsType
                );
              }}
            >
              <FontAwesomeIcon icon={faCalendarDays} size="lg" />
            </Link>{" "}
            &nbsp;&nbsp;
          </>
        ),
      },
    ],
    []
  );

  const eventsColumnsByEmployee = useMemo(
    () => [
      {
        accessorKey: "courseTitle",
        header: "Title",
        size: 600,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return (
            <>
              {row.original.courseTitle.includes("parent:") ? (
                <b>{row.original.courseTitle.replace("parent:", "")}</b>
              ) : (
                row.original.courseTitle
              )}
            </>
          );
        },
      },
      {
        id: "startDate",
        accessorKey: "startDate",
        header: "Event Date",
        size: 100,
        enableSorting: false,
      },
    ],
    []
  );

  const eventsColumnsByDateRange = useMemo(
    () => [
      {
        accessorKey: "courseTitle",
        header: "Title",
        size: 600,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return (
            <>
              {row.original.courseTitle.includes("parent:") ? (
                <b>{row.original.courseTitle.replace("parent:", "")}</b>
              ) : (
                row.original.courseTitle
              )}
            </>
          );
        },
      },
      {
        id: "startDate",
        accessorKey: "startDate",
        header: "Event Date",
        size: 100,
        enableSorting: false,
      },
      {
        id: "attendedBy",
        accessorKey: "attendedBy",
        header: "Name",
        size: 150,
        enableSorting: false,
      },
    ],
    []
  );

  const onRowClickEventHandler = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Container fluid>
        {isStaffSelected() && (
          <Row>
            <Col>
              <Button
                variant="btn btn-outline-primary"
                style={{
                  width: "100px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={(event) => {
                  handleUserSelection(props.selectedUser, 0);
                  setDisplaySubordinates(true);
                  toggleDisplaySubordinates();
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        )}
        {!isStaffSelected() && !props.showCalendar ? (
          <Button
            variant="btn btn-outline-primary"
            style={{ width: "100px", marginTop: "10px", marginBottom: "10px" }}
            onClick={(event) => {
              props.onBackClick();
            }}
          >
            Back
          </Button>
        ) : null}
        <Row style={{ marginBottom: "10px", marginTop: "5px" }}>
          <Col style={{ minWidth: "550px" }}>
            {users &&
            props.userView?.isManager &&
            !isStaffSelected() &&
            !props.showCalendar ? (
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => toggleDisplaySubordinates()}
              >
                <FontAwesomeIcon
                  icon={
                    displaySubordinates ? faAngleDoubleUp : faAngleDoubleDown
                  }
                />
              </span>
            ) : null}
            &nbsp; &nbsp;
            {!props.showCalendar
              ? breadcrumb.map((user, index) => (
                  <span
                    key={index}
                    onClick={() => handleBreadcrumbClick(index)}
                  >
                    {user?.name} {breadcrumb?.length > index + 1 ? " / " : ""}
                  </span>
                ))
              : null}
            {props.showCalendar && <div>{displayDateRange}</div>}
          </Col>
          <Col>{getEmployeeId()}</Col>
          <Col></Col>
        </Row>
      </Container>
      {!displaySubordinates ? (
        <Container fluid>
          <Row className="d-flex justify-content-center">
            <Col>
              <CustomTableView
                users={upcomingEvents || []}
                columns={
                  props.searchEventsType == 0
                    ? eventsColumnsByEmployee
                    : eventsColumnsByDateRange
                }
                loading={loading}
                dimensions={props.dimensions}
                hasSubRows={true}
                rowClickEventHandler={onRowClickEventHandler}
              />
            </Col>
          </Row>
        </Container>
      ) : null}
      {displaySubordinates ? (
        <Container fluid>
          <Row>
            <Col>
              {
                <TableContainer>
                  <Row>
                    <Col className="border-end">
                      <CustomTableView
                        users={users}
                        columns={usersColumns}
                        dimensions={props.dimensions}
                      />
                    </Col>
                  </Row>
                </TableContainer>
              }
            </Col>
          </Row>
        </Container>
      ) : null}
    </>
  );
}
