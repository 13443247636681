import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {Button, Spinner} from "react-bootstrap";
import {apiAxiosCalls} from "../../../services/apiAxiosCalls";
import moment from "moment";

const EventDetailsModal = ({
                               show = false,
                               handleClose = () => null,
                               personID = null,
                               courseCode = null,
                               title= ""
                           }) => {

    const [eventData, setEventData] = useState([])
    const [loading, setLoading] = useState(false)

    const groupByEventDate = (events) => {
        return events.reduce((acc, event) => {
            if (!acc[event.eventDate]) {
                acc[event.eventDate] = [];
            }
            acc[event.eventDate].push(event);
            return acc;
        }, {});
    }
    const getEventData = async (ukpnPersonid, courseId) => {
        try {
            setLoading(true)
            const {data} = await apiAxiosCalls.GetEventData(ukpnPersonid, courseId)
            setEventData(Object.entries(groupByEventDate(data)))

        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    const renderSingleEventEntry = (eventEntry) => {
        const eventDate = eventEntry[0]
        const events = eventEntry[1]
        return <div style={{
            background: "#F2F2F2"
        }} className={" d-flex flex-column rounded-3 border border-dark mt-2"}>
            <div className={"d-flex p-1"}><b>Event date:</b> &nbsp; <span>{moment(eventDate).format('DD/MM/YYYY')} </span></div>
            <div style={{
                paddingLeft: "1.5rem",
                paddingRight: "0.5rem",
                paddingBottom: "0.5rem",
                paddingTop: "0.5rem",
            }}>
                <div className={"bg-light d-flex flex-column border border-dark"}>
                    {events.map((event, index) => {
                        return <div key={index} className={"p-1 mt-1 "}>
                            <div>
                                <b>Time:</b> {event.startTime}
                            </div>
                            <div>
                                <b>Room:</b> {event.roomName}
                            </div>
                            <div>
                                <b>Venue:</b> {event.venue}
                            </div>
                            <div>
                                <b>Status:</b> {event.status}
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    }

    const rednerNoEventData = () => {
        return (!eventData.length && !loading )?  <div>
            No Event data
        </div> : <></>
    }

    const renderEventEntries = () => {
        return eventData.map((e,index) => {
            return <div key={index}>
                {renderSingleEventEntry(e)}
            </div>
        })
    }

    useEffect(() => {
        if (courseCode && personID) {
            getEventData(personID, courseCode)
        }
    }, [courseCode, personID]);

    return <>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{
                    maxHeight: "500px"
                }} className={"overflow-auto"}>
                    {loading ? <div className={"d-flex justify-content-center"} ><Spinner animation={"border"} /></div> : renderEventEntries()}
                    {rednerNoEventData()}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>

};

export default EventDetailsModal;
