import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandCircleDownOutlined from "@mui/icons-material/ExpandMore";
import moment from "moment";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import QualificationsPlanFilterTabs from "../components/qualifications/QualificationsFilterTabs";
import { ColdObservable } from "rxjs/internal/testing/ColdObservable";

export default function CompetenciesAccordion(props) {
  const data = props.data;
  const header = props.header;

  const [operationalData, setOperationalData] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [compFilterId, setCompFilterId] = useState(1);

  const shouldBeOpen = searchParams.get("comp");
  const todayDateString = moment().format("DD/MM/YYYY");

  function isDateBeforeToday(dateString) {
    return moment(dateString, "DD/MM/YYYY").isBefore(
      moment(todayDateString, "DD/MM/YYYY")
    );
  }

  function isWithin6Months(dateString) {
    return moment(dateString, "DD/MM/YYYY").isBetween(
      moment(todayDateString, "DD/MM/YYYY"),
      moment(todayDateString, "DD/MM/YYYY").add(6, "months")
    );
  }

  const validateDate = (dateString) => {
    let color;
    if (isWithin6Months(dateString)) {
      color = "#E68B15";
    } else if (isDateBeforeToday(dateString)) {
      color = "red";
    } else {
      color = "green";
    }

    return color;
  };

  const doSetFilterIdFromParams = (filterParam) => {
    if (filterParam == "expired") {
      setCompFilterId(-1);
      return;
    }
    if (filterParam == "expiring") {
      setCompFilterId(-2);
      return;
    }
    if (filterParam == "supervised") {
      setCompFilterId(-3);
      return;
    }
    setCompFilterId(1);
  };

  useEffect(() => {
    setOperationalData(data.filter((f) => f.isOperational == 1));
    setOtherData(data.filter((f) => f.isOperational == 0));
    doSetFilterIdFromParams(searchParams.get("filter"));
  }, []);

  useEffect(() => {
    if (compFilterId === 0) {
      setOperationalData(data.filter((f) => f.isOperational == 1));
      setOtherData(data.filter((f) => f.isOperational == 0));
    } else if (compFilterId === -1) {
      // expired
      setOperationalData(
        data.filter((f) => f.isOperational == 1 && isDateBeforeToday(f.validTo))
      );
      setOtherData(
        data.filter((f) => f.isOperational == 0 && isDateBeforeToday(f.validTo))
      );
    } else if (compFilterId === -2) {
      // expiring
      setOperationalData(
        data.filter((f) => f.isOperational == 1 && isWithin6Months(f.validTo))
      );
      setOtherData(
        data.filter((f) => f.isOperational == 0 && isWithin6Months(f.validTo))
      );
    } else if (compFilterId === -3) {
      // expiring
      setOperationalData(
        data.filter(
          (f) =>
            f.isOperational == 1 &&
            f.proficiency.toLowerCase().includes("under personal supervision")
        )
      );
      setOtherData(
        data.filter(
          (f) =>
            f.isOperational == 0 &&
            f.proficiency.toLowerCase().includes("under personal supervision")
        )
      );
    } else {
      doSetFilterIdFromParams(searchParams.get("filter"));
    }
  }, [compFilterId]);

  return (
    <Accordion defaultExpanded={shouldBeOpen == "1"}>
      <AccordionSummary
        expandIcon={<ExpandCircleDownOutlined />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{ backgroundColor: "#281E84", color: "white" }}
      >
        <Typography>{header}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <QualificationsPlanFilterTabs
          key="qualiTabs"
          filterId={compFilterId}
          setFilterId={setCompFilterId}
        />
        {operationalData.length === 0 && otherData.length === 0 && 
          <>
            <p className="col-12 p-1 bg-light align-centre">
              <em>No data</em>
            </p>
          </>
        }
        {operationalData.length > 0 && (
          <>
            <p className="col-12 mt-4 display-6 bg-light">Operational</p>
            <Table key="oList">
              <TableHead>
                <TableRow>
                  <TableCell>Qualifications Group</TableCell>
                  <TableCell>Qualification Name</TableCell>
                  <TableCell>Proficiency</TableCell>
                  <TableCell>Valid From</TableCell>
                  <TableCell>Valid To</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {operationalData?.map((competency) => (
                  <>
                    <TableRow key={competency.historyID}>
                      <TableCell width={"25%"}>
                        {competency.qualificationGroup}
                      </TableCell>
                      <TableCell width={"25%"}>
                        {competency.qualificationName}
                      </TableCell>
                      <TableCell width={"10%"}>
                        {competency.proficiency}
                      </TableCell>
                      <TableCell width={"10%"}>
                        {competency.validFrom}
                      </TableCell>
                      <TableCell
                        style={{
                          color: competency.validTo.includes("31/12/9999")
                            ? validateDate(competency.cycleEndDate)
                            : validateDate(competency.validTo),
                        }}
                        width={"10%"}
                      >
                        <b>
                          {competency.validTo.includes("31/12/9999")
                            ? competency.cycleEndDate
                            : competency.validTo}
                        </b>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </>
        )}
        {otherData.length > 0 && (
          <>
            <p className="col-12 mt-4 display-6 bg-light">Non Operational</p>
            <Table key="noList">
              <TableHead>
                <TableRow>
                  <TableCell>Qualifications Group</TableCell>
                  <TableCell>Qualification Name</TableCell>
                  <TableCell>Proficiency</TableCell>
                  <TableCell>Valid From</TableCell>
                  <TableCell>Valid To</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {otherData?.map((competency) => (
                  <>
                    <TableRow key={competency.historyID}>
                      <TableCell width={"25%"}>
                        {competency.qualificationGroup}
                      </TableCell>
                      <TableCell width={"25%"}>
                        {competency.qualificationName}
                      </TableCell>
                      <TableCell width={"10%"}>
                        {competency.proficiency}
                      </TableCell>
                      <TableCell width={"10%"}>
                        {competency.validFrom}
                      </TableCell>
                      <TableCell
                        style={{ color: validateDate(competency.validTo) }}
                        width={"10%"}
                      >
                        <b>
                          {
                            // competency.validTo
                            competency.validTo.includes("31/12/9999")
                              ? " No Expiry"
                              : competency.validTo
                          }
                        </b>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
