import useLmsStore, {setCourseTypesState} from "./useLmsStore";
import {useEffect} from "react";
import {apiAxiosCalls} from "../services/apiAxiosCalls";

export default function useGetCourseTypes() {
    const {courseTypesState} = useLmsStore((state) => {
        return {
            courseTypesState: state.courseTypesState
        }
    })

    const getCourseTypes = async () => {
        try {
            const {data} = await apiAxiosCalls.getCourseTypes();
            setCourseTypesState(useLmsStore, {
                data: data
            });
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getCourseTypes()
    }, [])
}