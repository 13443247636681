import BaseContainer from "../../components/BaseContainer";
import { Container, Row, Modal, Button } from "react-bootstrap";
import {learningItemName,learningItemIcon } from '../../helpers/tableHelper';
import MaterialReactTable from 'material-react-table';
import { useState, useMemo, useEffect } from "react";

import { faPlus, faInfoCircle,faCheckSquare, faArrowAltSquareDown} from '@fortawesome/pro-regular-svg-icons';
import {apiAxiosCalls} from "../../services/apiAxiosCalls";
import CustomTableView from '../../components/common/generic-table-view';
import RequestCourseTraining from './components/request-course-training';
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import { catalogService } from './services/catalog.service'; 
import { userService } from '../../services/common/user.service';

import CustomRadioGroupList from '../../components/common/generic-radio-button';
import useLmsStore from "../../hooks/useLmsStore";
import shallow from 'zustand/shallow';
import ModalSynopsis from '../../components/ModalSynopsis';
import { TableRowSplit } from '../../helpers/tableHelper';


function CatalogueManagementCore({dimensions})
{
    const [loading, setLoading] = useState(false);
    const [courseLoading, setCourseLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [dashboardCourses, setDashboardCourses] = useState();
    const [courses, setCourses] = useState([]);
    const [heading, setHeading] = useState('<b>Please select a category</b>');
    const [displayCourses, setDisplayCourses] = useState(true)
    const [displayCourseTrainingView, setDisplayCourseTrainingView] = useState(false);
    const [courseInfo, setCourseInfo] = useState({});
    const [userId, setUserId] = useState(0);
    const [displayCategories, setDisplayCategories] = useState(true);
    const [showSynopsis, setShowSynopsis] = useState(false);
    const [learningItemId, setLearningItemId] = useState(null);
    const [learningItemTitle, setLearningItemTitle] = useState("");
    // const { computedMuiTableContainerProps, styleFn } = dimensions;
    
    const radioOptions = 
    [
        {id:'category', name:'rdnGroup', value:'category', label: 'Category', defaultCheck: true },
        {id:'course', name:'rdnGroup', value:'course', label: 'Course', defaultCheck: false }
    ]

    const { personSettings } = useLmsStore(state =>
    ({
        personSettings: state.personSettings.data,
    }), shallow);

    // catalogService.setUserId(personSettings?.ukpnPersonId);
    // catalogService.getUserId().subscribe(x=> 
    //     {
    //         console.log('user id:' + x);
    //     })

    //---------------------------------------
    // #region Row click event handler
    //---------------------------------------

    const onRadioOptionChange = (event) => {
        let value = event.target.value;
        setDisplayCategories(value == 'category');
    }
    const requestCourseTrainingBackButtonClick = (event) => {
        event.preventDefault();
        setLoading(true);
        setTimeout(() => {
            setDisplayCourseTrainingView(false);
            setDisplayCourses(true);
            setLoading(false);
        }, 100);
    }
    const categoryOrCourseRowClickEventHandler = (event, row, isCategory) => {
        event.preventDefault();
        setDisplayCourseTrainingView(false);
        setDisplayCourses(true);

        if (isCategory) {
            let id = row.original.CourseCategoryID;
            if (id) {
                setHeading('<strong>Category:</strong> ' + row.original.CourseCategory);
                setCourseLoading(true);
                catalogService.getCourseList(id)
                    .then(result => {
                        setCourses(result[0]);
                    })
                    .finally(() => setCourseLoading(false))
            }
            // console.log('category id: ' + row.original.CourseCategoryID);
        }
        else {
            let id = row.original.CourseID;
            if (id) {
                setHeading(row.original.CourseTitle);
                //console.log(JSON.stringify(row.original));
                setCourses([row.original]);
            }
        }


    };

    const handleOpenSynopsisLink = async (url) => {
        window.open(url, "_blank");
    }

    const handleShowSynopsis = (learningItemId,itemName) => {
        setLearningItemId(learningItemId);
        setLearningItemTitle(itemName);
        setShowSynopsis(true); 
        // getSynopsis(learningItemId).then((response) => {
        //     let data = responseData(response, []);
        //     setSynopsisSections(data);
         
        //     setShowSynopsis(true);
        //     setSynopsisTitle(itemName);
        // });
    };

    const onCloseModalSynopsis = () => {
        setLearningItemId(null);
        setShowSynopsis(false);
    }
    const requestTrainingButtonEventHandler = (event, row) => 
    {
        event.preventDefault();
        setDisplayCourseTrainingView(true);
        setDisplayCourses(false);

        setCourseInfo({
            ManagerID: userId,
            CourseID: row.original.CourseID,
            DeliveryMethodID: row.original.DeliveryMethodID,
            CourseTitle: row.original.CourseTitle
        })

    };

    const coursesRowClickEventHandler = (event) => {
        event.preventDefault();
        //console.log('course id: ' + row.original.CourseID);
    }

    //---------------------------------------
    // #endregion
    //---------------------------------------


    //---------------------------------------
    // #region components columns region
    //---------------------------------------


    const dashboradCoursesColumns = useMemo(
        () =>
            [
                {
                    accessorKey: 'CourseCode',
                    // id: 'CourseID',
                    header:'',
                    size: 0
                },
                {
                    accessorKey: 'CourseTitle',
                    // id: 'CourseID',
                    header:'',
                    size: 0
                },
                {
                    accessorKey: 'CourseID',
                    header: '',
                    //Format a number in a cell render
                    Cell: ({ cell, row }) => (
                        <>
                            <div style={{ 'textAlign': 'left' }}>{row.original.CourseCode + ' - ' + row.original.CourseTitle}</div>
                        </>
                    )
                }
            ],
        [],
    );


    const coursesColumns = useMemo(
        () => 
        [
            {
                // accessorKey: 'CourseID',
                id: 'CourseID',
                header:'',
                size: 0
                
            },
                {
                     accessorKey: 'LearningItemTypeId',
                    id: 'LearningItemTypeId',
                    header:'Type',
                    size: 0,              
                    Cell: ({ cell, row }) => (<OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{(row.original.IsMandatory ? 'Mandatory ' : '') + learningItemName(row.original.LearningItemTypeId)}</Tooltip>} >
                    {learningItemIcon(row.original.LearningItemTypeId,row.original.IsMandatory)}
                   </OverlayTrigger>
              )
                },
                {
                    accessorKey: 'CourseCode',
                    header: 'Code',
                    size: 100,

                },
                {
                    accessorKey: 'CourseTitle',
                    header: 'Title',
                    size: 300,
                  },            
                  {
                    accessorKey: 'HasSynopsis',
                    header: '',
                    size: 30,
                    //Format a number in a cell render
                    Cell: ({ cell, row }) => (
                    <>
                       {/* <span>${row.original.personID}</span> */}
                       {row.original.HasSynopsis && row.original.LearningItemId > 0 ? <OverlayTrigger 
                            placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>View Synopsis</Tooltip>}>
                            <Button   variant="clear" className='pt-0 pb-0' onClick={() => handleShowSynopsis(row.original.LearningItemId,row.original.CourseTitle)}>
                            <FontAwesomeIcon color='red' icon={faInfoCircle} size="lg"/> 
                            </Button>
                            </OverlayTrigger>:'' }
                     {  /*
                      <Link to='/' onClick={(event)=> {}}><FontAwesomeIcon color='red' icon={faInfoCircle} size="lg"/></Link> &nbsp;&nbsp;&nbsp;
                     <Link to='/' onClick={(event)=> {}}><FontAwesomeIcon color='red' icon={faArrowAltSquareDown} size="lg"/></Link> &nbsp;&nbsp; */ }
                    </>
                    )
                  },
                  {
                    accessorKey: 'SynopsisLink',
                    header: '',
                    size: 30,
                    //Format a number in a cell render
                    Cell: ({ cell, row }) => (
                    <>        
                     {row.original.SynopsisLink !='' && row.original.SynopsisLink!=undefined? (<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Course outline</Tooltip>}>
                      <Button variant="clear" className='pt-0 pb-0' onClick={() => handleOpenSynopsisLink(row.original.SynopsisLink) } >
                      <FontAwesomeIcon size='lg' icon={faArrowAltSquareDown} color='red'  />              
                       </Button> 
                       </OverlayTrigger>) : ''}
                    </>
                    )
                },
                {
                    accessorKey: 'Duration',
                    header: 'Duration',
                    size: 100,

                },
                {
                    accessorKey: 'ActualRate',
                    header: 'Cost',
                    size: 100,

                },
                {
                    accessorKey: 'currSym',
                    header: 'Request',
                    size: 100,
                    //Format a number in a cell render
                    Cell: ({ cell, row }) => (
                        // <span>${cell.getValue.toLocaleString()}</span>
                    <>
                       {/* <span>${row.original.personID}</span> */}
                       <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Request Course for my Team</Tooltip>}                >
                       {/* <Link to='/' onClick={(event)=> 
                                            {
                                                requestTrainingButtonEventHandler(event, row);
                                            }}>
                                        <FontAwesomeIcon color='red' icon={faCheckSquare} size="lg"/>
                        </Link> */}
                        <Button 
                            variant='btn btn-outline-primary'
                            style={{marginLeft:'5px', marginRight:'10px', 'width':'90px'}}
                            onClick={(event)=> {requestTrainingButtonEventHandler(event, row)}}
                        >
                            Continue
                        </Button>

                        </OverlayTrigger> &nbsp;&nbsp;
                    </>
                    )
                }
            ],
        [],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: '',
                id: 'CourseCategoryID',
                header: '',
                size: 0,
            },
            {
                accessorKey: "CourseCategory",
                header: '',
                header: '',
                //Format a number in a cell render
                Cell: ({ cell, row }) => (
                    <div style={{ 'textAlign': 'left' }}>{row.original.CourseCategory}</div>
                )
            }
        ],
        [],
    );

    //---------------------------------------
    // #endregion
    //---------------------------------------

    useEffect(() => {
        if (personSettings?.ukpnPersonId) {
            setUserId(personSettings?.ukpnPersonId);
            userService.UserId = personSettings?.ukpnPersonId;
            console.log('setting user id: ' + userService.UserId);
            //console.log('personSettings?.ukpnPersonId:' + personSettings?.ukpnPersonId);
        }
    }, [personSettings?.ukpnPersonId])

    useEffect(() => {
        setCourses([]);
        if (displayCategories) {
            if (categories?.length > 0) return;
            setLoading(true);
            catalogService.getCategoryList(userId)
                .then(result => {
                    setCategories(result[0]);
                    setLoading(false)
                })
                .finally(() => setLoading(false));
        }
        else {
            if (dashboardCourses?.length > 0) return;
            setLoading(true);
            catalogService.getCourseList(-1)
                .then(result => {
                    setDashboardCourses(result[0]);
                    setLoading(false)
                })
                .finally(() => setLoading(false));
        }
    },
        [displayCategories]);


    return (
        <>
        <TableRowSplit
            leftPane={
                <>
                    <CustomRadioGroupList
                        options={radioOptions}
                        title='Search By'
                        onchange={onRadioOptionChange}
                    />
                    {
                        displayCategories && categories?.length > 0 ?
                            <CustomTableView
                                users={categories}
                                columns={columns}
                                loading={loading}
                                rowClickEventHandler={(event, row) => categoryOrCourseRowClickEventHandler(event, row, true)}
                                columnVisibility={{ CourseCategoryID: false }}
                                dimensions={dimensions}
                            />
                            : null
                    }
                    {
                        !displayCategories && dashboardCourses?.length > 0 ?
                            <CustomTableView
                                users={dashboardCourses}
                                columns={dashboradCoursesColumns}
                                loading={loading}
                                rowClickEventHandler={(event, row) => categoryOrCourseRowClickEventHandler(event, row, false)}
                                dimensions={dimensions}
                                columnVisibility = {{CourseCode: false, CourseTitle: false}}
                            />
                            : null
                    }
                </>
            }
            rightPane={<>
                {displayCourses ?
                    <CustomTableView
                        users={courses}
                        columns={coursesColumns}
                        loading={courseLoading}
                        rowClickEventHandler={coursesRowClickEventHandler}
                        // heading={<h6 className='text-muted border border-secondary border-rounded p-2' dangerouslySetInnerHTML={{ __html: heading }} />}
                        dimensions={dimensions}
                        disableGlobalFilter={true}
                    />
                    : null
                }
                {
                    displayCourseTrainingView ?
                        <RequestCourseTraining
                            courseInfo={courseInfo}
                            onBackButtonClick={requestCourseTrainingBackButtonClick}
                        />
                        : null
                }
            </>}
        />
        <ModalSynopsis closeFn={onCloseModalSynopsis} open={showSynopsis} learningItemId={learningItemId} title={learningItemTitle}></ModalSynopsis>
        </>
    );
}

export default function CatalogueManagement() {
    return (<BaseContainer title='Browse Catalogues / Request Training'>
        <CatalogueManagementCore />
    </BaseContainer>)
}