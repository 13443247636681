import { StrictMode, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { b2cPCA } from './lms/auth/configs/b2cPCA';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <StrictMode>
        <App msalInstance={b2cPCA} />
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
