// import { Table, Row, Col } from "@mui/material";
import React from "react";
import { useState, useEffect, useMemo } from "react";
import { Button, Container, Table, Row, Col } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import { catalogService } from "../catalogue-management/services/catalog.service";
import RequestCourseTrainingEmployees from '../catalogue-management/components/request-course-training-employees'; 
import HeadingWithBackButton from '../../components/common/heading-with-back-button';
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";


export default function RequestCourseRequiresAuthentication(props)
{
    let inputModelObj = 
    {
        Justification: ''
    }
    const [inputModel, setInputModel] = useState(inputModelObj);
    const [errors, setErrors] = useState(inputModelObj);
    const [loading, setLoading] = useState(false);
    const [courseInfo, setCourseInfo] = useState({});
    const [saving, setSaving] = useState(false);
    const [state, setState] = useState({
                                                wordCount: 0, 
                                                charCount: 0
                                            });
 
const handleKeyPress = (e) => {
    const count = e.target.value;
    
    const countWords = (count) => {
        if (count.length === 0) {
        return 0;
        } else {
        count = count.replace(/(^\s*)|(\s*$)/gi,"");
        count = count.replace(/[ ]{2,}/gi," ");
        count = count.replace(/\n /,"\n");
        return count.split(' ').length; 
        }
    }
    
    setState({
        wordCount: countWords(count),
        charCount: count.length
    });
    }
                                                                                      

    const validateSchema = Yup.object().shape({
        Justification: Yup.string()
          .required("This field is required")
          .min(10, "This field must be 10 or more characters")
          .max(300,"This field must be than or euqal to 300 characters")
        }
      );    

      const formik = useFormik({
        initialValues: {
            Justification: "",
        },
        validationSchema: validateSchema,
        onSubmit: (values, { resetForm }) => {
          console.log(values);
          setLoading(true);

          let inputParams = 
          {
            CourseID: props.courseInfo.CourseID,
            BusJustText: values.Justification,
            AuthorType: 'Initiator',
            PersonID: props.currentRow.original.personID,
            UserID: props.courseInfo.managerID            
            // UserID: props.currentRow.original.employeeID,
          };

          if (props.authorType!=undefined && props.authorType!=null){
            inputParams.AuthorType=props.authorType;
          }
          
          catalogService.SaveBusinessJustificationText(inputParams)
          .then(result => 
              {
                  let res = result;
                  props.onBackSubmitButtonClick(props.currentRow, true);
              })
          .finally(() => 
          {
              setLoading(false);
              //console.log('getting staff list:' + JSON.stringify(staffList));
          });
        //   setTimeout(() => {
        //     setLoading(false);
        //     //resetForm();
        //     props.onBackSubmitButtonClick(props.currentRow, true);
        //   }, 100);
        },
      });      
    useEffect(() => 
    {
        setLoading(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
        catalogService.getCourseInfo(props.courseInfo.CourseID)
        .then(result => 
            {
                setCourseInfo(result);
            })
        .finally(() => 
        {
            setLoading(false);
        })

    },[props]);

    const handleChange = (event) => 
    {
        const name = event.target.name;
        const value = event.target.value;

        setInputModel({...inputModel,[name]:value });
    }

    const validateModel = () => 
    {
      //const errors:any = {};
      setErrors(inputModelObj);
      if (inputModel?.Justification == undefined ||  inputModel?.Justification?.length == 0)
      {
        errors.Justification = 'Please enter Justification';
      }
      else if (inputModel?.Justification?.length > 300)
      {
        errors.Justification = 'Please enter item name length cannot greater than 300';    
      }
      setErrors(errors);
    }
  
    const onClickSaveAuthenticationRequest = (event) => 
    {
        event.preventDefault();
        validateModel();
        console.log(JSON.stringify(errors));
        if (Object.keys(errors).length > 0)
        {
            // Swal.fire(
            //     {
            //         icon:'error',
            //         title:'An error has occured',
            //         showConfirmButton: false,
            //         timer:1500
            //     });
                return false;
        }
        else
        {
            setSaving(true);
        }
    };


    return (
        <>
            {
                loading ?
                <>
                    {/* <div className="d-flex justify-content-center">
                        <div className="spinner-border mt-5 text-primary" style={{ width: '5rem', height: '5rem' }} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> */}
                    <Button variant="primary" disabled className="mx-2">
                        <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                        Please wait...
                    </Button>
                </>
                :
                <>
                {
                    <>
                        <form>
                        <Card>
                        <HeadingWithBackButton
                            Title = { props.currentRow.original.fullName ? 'Business Justification for: ' + props.currentRow.original.fullName : '' } 
                            onBackButtonClick = {props.onBackButtonClick}
                        >
                        </HeadingWithBackButton>

                        {/* <Card.Header style={{'maxHeight':'70px'}}>
                                    <div className="row">
                                        <div className="col col-md-8 col-lg-10">
                                            {props.courseInfo.CourseTitle}
                                        </div>
                                        <div className="col col-md-4 col-lg-2 float-right">
                                            &nbsp;&nbsp;
                                            <Button 
                                                variant='btn btn-outline-primary'
                                                style={{marginLeft:'5px', marginRight:'10px', 'width':'100px'}}
                                                onClick={(event)=> {props.onBackButtonClick(event)}}
                                            >
                                                Back
                                            </Button>

                                        </div>
                                    </div>
                            <div></div>
                            
                            </Card.Header> */}
                        <Card.Body>
                        <Card.Title style={{fontSize:'16px'}}>Request Course: "{props.courseInfo?.CourseTitle}"</Card.Title>
                        <div style={{fontSize:'13px'}}>Please enter between 10 & 300 characters</div>
                        <br></br>
                        {/* <hr></hr> */}
                        
                            <div>
                                {/* <label htmlFor="Notes">Notes</label> */}
                                <textarea
                                    // type="text"
                                    className="form-control"
                                    name="Justification"
                                    id="Justification"
                                    rows={5}
                                    value={formik.values.Justification}
                                    onChange={(event) => {
                                        handleKeyPress(event);
                                        formik.handleChange(event);
                                    }}
                                >
                                </textarea>
                            </div>
                            <div>
                                <span className='num'> {state.charCount} Characters entered</span>
                            </div>
                            {formik.errors.Justification  ? <span style={{color:'red'}}>{formik.errors?.Justification}</span> : null }
                        </Card.Body>
                        </Card>        
                        <div>&nbsp;</div>
                        <div className="float-right">
                                <Button 
                                    variant='btn btn-outline-primary'
                                    style={{marginLeft:'5px', marginRight:'10px', 'width':'100px'}}
                                    onClick={formik.handleSubmit}
                                >
                                    Save
                                </Button>
                                <Button 
                                    variant='btn btn-outline-primary'
                                    style={{marginLeft:'5px', marginRight:'10px', 'width':'100px'}}
                                    onClick={(event)=> {props.onBackButtonClick(event)}}
                                >
                                    Cancel
                                </Button>

                        </div>
                        </form>
                    </>
                }
                </>
            }
        </>
    )

}