import { useEffect, useMemo, useState } from 'react';
import BaseContainer from "../components/BaseContainer";
import { TableContainer } from '../helpers/tableHelper'
import MaterialReactTable from 'material-react-table';
import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload,faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import {Button, Col, OverlayTrigger, Tooltip } from "react-bootstrap";

export default function MyTeamElearningProgress() {
    const navigate = useNavigate();
    const { teamElearningTrainingPlans,  fetchTeamElearningTrainingPlans } = useLmsStore(state =>
    ({
        teamElearningTrainingPlans: state.teamElearningTrainingPlans.data,
        fetchTeamElearningTrainingPlans: state.fetchTeamElearningTrainingPlans
    }), shallow);

    const downloadFile = ({ data, fileName, fileType }) => {
      const blob = new Blob([data], { type: fileType });

      const a = document.createElement("a");
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvt);
      a.remove();
    };

    const exportToJson = (e) => {
      e.preventDefault();
      downloadFile({
        data: JSON.stringify(teamElearningTrainingPlans),
        fileName: "registrations.json",
        fileType: "text/json",
      });
    };
          
    const exportToCsv = (e) => {
      e.preventDefault();

      // Headers for each column
      let headers = ["Employee ID, First Name, Surname, Job Title, Email Address, Person Status Name, Item Name, Item Status Name, Item Type Name, Number Of Attempts, Start Date DD/MM/YY, Due Date DD/MM/YY, Completion Date DD/MM/YY, Catalogue Name, Is Mandatory"];

      // Convert users data to a csv
      let usersCsv = teamElearningTrainingPlans.reduce((acc, user) => {
        const { employeeId, firstName, surname, jobTitle, emailAddress, personStatusName, itemName, itemStatusName, itemTypeName, numberOfAttempts, startDate, dueDate, completionDate, catalogueName, isMandatory } = user;
        acc.push([employeeId, firstName, surname, jobTitle, emailAddress, personStatusName, itemName, itemStatusName, itemTypeName, numberOfAttempts, startDate, dueDate, completionDate, catalogueName, isMandatory].join(","));
        return acc;
      }, []);

      downloadFile({
        data: [...headers, ...usersCsv].join("\n"),
        fileName: "TeamElearningTrainingPlans.csv",
        fileType: "text/csv",
      });
    };

    // on load
    useEffect(() => {
        fetchTeamElearningTrainingPlans();
    }, []);

    
    const columns = useMemo(
        () => [
            {
                accessorKey: "employeeId",
                header: (
                    <>
                    Employee<br />ID
                    </>
                ),
                size: 1
            },
            {
                accessorKey: "firstName",
                header: (
                    <>
                        First<br />Name
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "surname",
                header: "Surname",
                //size: 30
            },
            {
                accessorKey: "jobTitle",
                header: "Job Title",
                //size: 30
            },
            {
                accessorKey: "emailAddress",
                header:  (
                    <>
                        Email
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "personStatusName",
                header: (
                    <>
                        Person<br />Status
                    </>
                ),
                //size: 30
            },
            { 
         
                accessorKey: "itemName",
                header: "Item Name",
                //size: 30
            },
            {
                accessorKey: "itemStatusName",
                header: (
                    <>
                        Item<br />Status
                    </>
                ),
                //size: 30
            },            
            {
                accessorKey: "itemTypeName",
                header: (
                    <>
                        Item<br />Type
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "numberOfAttempts",
                header: (
                    <>
                        Number<br />Of Attempts
                    </>
                ),
                //size: 30
            },            
            {
                accessorKey: "startDate",
                header: "Start Date",
                header: (
                    <>
                        Start<br />Date
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "dueDate",
                header: "Due Date",
                header: (
                    <>
                        Due<br />Date
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "completionDate",
                header: "Completion Date",
                header: (
                    <>
                        Completion<br />Date
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "catalogueName",
                header: "Catalogue Name",
                header: (
                    <>
                        Catalogue<br />Name
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "isMandatory",
                header: "Is Mandatory",
                //size: 30
            },
        ],
        [],
    );
        const viewPort = window.innerHeight
        

    return (
        <BaseContainer title='My Team Elearning Progress Report'>        
            <Button variant='outline-secondary' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faChevronLeft} /><span className='m-2'>Back</span></Button>
            <TableContainer className={"mb-2"} >
                <Col style={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto',  overflowX: 'hidden' }} className='border-end'>
                    <MaterialReactTable 
                        columns={columns}
                        globalFilterFn={'includesString'}
                        enableGlobalFilter={true}
                        data={teamElearningTrainingPlans || []}
                        renderToolbarInternalActions={({ table }) => (
                            <>
                                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Download Data</Tooltip>}>
                                    <Button variant='clear' className='pt-0 pb-0' onClick={exportToCsv}>
                                        <FontAwesomeIcon size='xl' icon={faDownload} className='text-primary' />
                                    </Button>
                                </OverlayTrigger>
                            </>
                        )}                        
                        initialState={{
                            pagination: { pageIndex: 0, pageSize: 10 },
                            showGlobalFilter: true
                        }}
                        enableStickyHeader={true}
                        useSticky
                        muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 400px)', minHeight: 'calc(100vh - 400px)' } }}
                        state={{
                            isLoading: !teamElearningTrainingPlans,
                        }}
                    />
                </Col>
            </TableContainer>
        </BaseContainer>
    );
}
