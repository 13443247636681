import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-regular-svg-icons";
import {Button, Spinner} from "react-bootstrap";

const SaveAndCancel = ({form}) => {
    const {isSubmitting} = form
    return (<div className={"d-flex justify-content-between "}>
        <div>
            <Button
                type={"submit"}
                className={"w-100 mb-3   mt-2 text-center"}
                disabled={!form.isValid || isSubmitting}
            >
                {isSubmitting ? <>Submitting <Spinner animation={"border"} size={"sm"}
                                                      variant={"light"}/></> : <>Save <FontAwesomeIcon size={"lg"}
                                                                                                       icon={faPlus}/></>}


            </Button>
        </div>
        <div>
            <Button
                type={"reset"}
                className={"w-100 mb-3   mt-2 text-center"}
                disabled={isSubmitting}
            >
                Cancel
            </Button>
        </div>
    </div>);
};

export default SaveAndCancel;
