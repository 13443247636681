import React from 'react';
import GenericSubTabContainer from "../../common/GenericSubTabContainer";
import {useGetVideoLinkTypes} from "../../../hooks/useGetVideoLinkTypes";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import ReactPlayer from "react-player";

const checkboxes = [{
    name: "AutoPlay", label: "Auto Play"
}, {
    name: "ShowPlayButton", label: "Show Play Button"
}, {
    name: "ShowPlayBackButton", label: "Show Playback Button"
}, {
    name: "ShowScrubBar", label: "Show Scrub Bar"
}, {
    name: "ShowTime", label: "Show Time"
}, {
    name: "ShowTimeRemaining", label: "Show Time Remaining"
}, {
    name: "ShowVolumeControl", label: "Show Volume Control"
}, {
    name: "AutoHide", label: "Auto hide"
}, {
    name: "ShowVolumeControl", label: "Show Volume Control"
}]

function VideoSettingsSubTab({form: {handleSubmit, handleChange, values, touched, errors, handleBlur}}) {

    const videoLinkTypes = useGetVideoLinkTypes()

    const renderCheckBoxes = (checkboxes) => {
        return checkboxes.map(checkbox => (<Col key={checkbox.name}>
                <Form.Group className="mb-3" controlId={`formCheckbox${checkbox.name}`}>
                    <Form.Check
                        type="checkbox"
                        name={checkbox.name}
                        checked={values[checkbox.name]} // Controlled by Formik's values
                        label={checkbox.label}
                        onChange={handleChange} // Formik handles the change
                        onBlur={handleBlur} // For handling touch state
                    />
                </Form.Group>
            </Col>));
    };

    const renderTypeOptions = () => {
        return videoLinkTypes.map(videoLinkType => {
            return <option key={videoLinkType.Id} value={videoLinkType.Id}>{videoLinkType.Value}</option>
        })
    }

    return (<GenericSubTabContainer title={'Video Settings'}>
            <Row className={"w-100"}>
                <Col md={6}>

                    <Form.Group className="mb-3" controlId="dropdownType">
                        <Form.Label>Video Source <span title='Mandatory field'
                                                       className='mandatory'>*</span></Form.Label>
                        <Form.Select
                            name="LinkType"
                            value={values.LinkType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            aria-label="Default select example"
                            required
                        >
                            <option value="">Select type</option>
                            {renderTypeOptions()}
                        </Form.Select>
                        {touched.LinkType && errors.LinkType ? (
                            <div className="invalid-feedback d-block">{errors.LinkType}</div>) : null}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Video Url</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                placeholder="Video Url"
                                type="text"
                                name="Url"
                                value={values.Url}
                                onChange={handleChange}
                                isValid={touched.Url && !errors.Url}
                                isInvalid={touched.Url && errors.Url}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.Url}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Poster</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                placeholder="Poster"
                                type="text"
                                name="Poster"
                                value={values.Poster}
                                onChange={handleChange}
                                isValid={touched.Poster && !errors.Poster}
                                isInvalid={touched.Poster && errors.Poster}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.Poster}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col className={"d-flex"} md={6}>
                        <ReactPlayer style={{
                            maxWidth:"100%"
                        }} url={values.Url} controls={true} />
                </Col>
            </Row>
            <Row className={"w-100 mt-2"}>
                <Col md={6}>
                    {renderCheckBoxes(checkboxes.slice(0, 5))}
                </Col>
                <Col md={6}>
                    {renderCheckBoxes(checkboxes.slice(5, 8))}
                </Col>

            </Row>

        </GenericSubTabContainer>);
}

export default VideoSettingsSubTab;
