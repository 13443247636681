import React from 'react';
import SelectAndUploadScormFile from "./SelectAndUploadScormFile";

const UploadNewVersion = ({id,rusticiCourseId}) => {
    return (
        <SelectAndUploadScormFile rusticiCourseId={rusticiCourseId} isNew={false} />
    );
};

export default UploadNewVersion;
