import axios from 'axios';
import MessageQ from '../../helpers/messageQ'
import produce from 'immer';
import { getValidToken } from '../../helpers/validateHelper';
import { toast } from 'react-toastify';
import { b2cPCA } from "../../auth/configs/b2cPCA";

export const FORMCONFIRM = "Unsaved Changes  You have made changes that have yet to be saved. Are you sure you want to cancel and discard your changes?";
export const HEADERS_JSON = { headers: { 'Content-Type': 'application/json' } };
export const HEADERS_MULTIPART = { headers: { 'Content-Type': 'multipart/form-data' } };
export const REFRESH_AFTER = 600000; // 5 minutes. Refresh the local data if more that REFRESH_AFTER milliseconds has elapsed.
export const WDR_HUB_URL = '/wdrlmshub';

export const apiAxios = axios.create({
    withCredentials: true,
    headers: { 'authorization': getValidToken() }
});

apiAxios.interceptors.request.use((config) => {
    // Abort if no token present (user not logged in)
    let token = getValidToken();
    if (token.length == 0) {
        throw new Error('No Authorization Token');
    }
    return config;
}, (error) => {
    return Promise.reject(error); // Delegate error to calling side
});

apiAxios.interceptors.response.use((response) => {
    return response;
}, (error) => { // Anything except 2XX goes to here
    const status = error.response?.status || 500;
    if (!error.response || status === 401) {
        forceLogin();
        // Clear token
    } else if (status > 499) {
        if (error.response && error.response.data && error.response.data.toLowerCase().includes('session expired')) {
            forceLogin();
        } else if (status === 504) {
            window.location = `${window.location.protocol}//${window.location.host}/lms/servererror?error=${status}`
        } else if (process.env.NODE_ENV !== 'development') {
            window.location = `${window.location.protocol}//${window.location.host}/lms/servererror?error=${status}`
        }
    } else {
        return Promise.reject(error); // Delegate error to calling side
    }
});

function forceLogin() {
    // Clear token
    window.localStorage.setItem('token', null);
    if (b2cPCA.getActiveAccount()) {
        b2cPCA.logoutRedirect()
    }
    window.location = `${window.location.protocol}//${window.location.host}/login`
};

export const fetchAsynTransformFn = (set, get, propName, url, asyncTransformedDataFn) => {
    if (!asyncTransformedDataFn) {
        throw new Error('asyncTransformedDataFn must be defined.');
    }
    return (forceFetch = false) => {
        return new Promise((resolve, reject) => {
            // Ajax calls are async promises so ensure that each call is queued.
            // Identify each call with a unique call. When the lock is aquired,
            // it is time for that call to determine if the store data needs
            // refreshing before being returned by the promise.
            let lockId = getLockId();
            // Wait for the lock to be acquired.
            until(() => {
                if (!(get()[propName].loading > 0)) {
                    // Acquiring the lock
                    set(produce((state) => { state[propName] = { ...state[propName], loading: lockId } }));
                }
                return get()[propName].loading === lockId;
            }, 300000, `'${propName} ${lockId}' fetch failed to complete in the allocated time. This may be due to a slow server response time.`, () => {
                //do something to stop loading
                set(produce((state) => { state[propName] = { ...state[propName], loading: 0 } }));
            }).then(() => {
                // Does the store data need refreshing?
                if (forceFetch || timeElapsed(get()[propName])) {
                    // Get the data from the server
                    apiAxios(url).then(async (response) => {
                        try {
                            if (!response || !response.status) {
                                throw new Error('Reponse format invalid.');
                            }
                            // Apply the tranformation function to the response
                            const data = await asyncTransformedDataFn(response);
                            // Update the store and return the promised data
                            set(produce((state) => { state[propName] = response.status === 200 ? { data: data, time: new Date(), loading: 0 } : { data: undefined } }));
                            resolve(data);
                        } catch (e1) {
                            // Report error and update the store. Store data not updated.
                            toastError(e1);
                            set(produce((state) => { state[propName] = { ...state[propName], loading: 0 } }));
                            reject(e1);
                        }
                    }).catch((e2) => {

                        // Report error and update the store. Store data not updated.
                        toastError(e2);
                        set(produce((state) => { state[propName] = { ...state[propName], loading: 0 } }));
                        reject(e2);
                    });
                } else {
                    // No need to refresh the data so serve exisiting promised data
                    set(produce((state) => { state[propName] = { ...state[propName], loading: 0 } }));
                    resolve(get()[propName].data);
                }
                // });
            }).catch((e3) => {
                // Report error and update the store. Store data not updated.
                toastError(e3);
                set(produce((state) => { state[propName] = { ...state[propName], loading: false } }));
                reject(e3);
            });
        });
    };
};

export const fetchByIdFn = (set, get, propName, url, transformedDataFn = (response) => responseData(response)) => {
    return (id, forceFetch = false) => {
        return new Promise((resolve, reject) => {
            // Ajax calls are async promises so ensure that each call is queued.
            // Identify each call with a unique call. When the lock is aquired,
            // it is time for that call to determine if the store data needs
            // refreshing before being returned by the promise.
            let lockId = getLockId();
            // Wait for the lock to be acquired.
            until(() => {
                if (!(get()[propName].loading > 0)) {
                    // Acquiring the lock
                    set(produce((state) => { state[propName] = { ...state[propName], loading: lockId } }));
                }
                return get()[propName].loading === lockId;
            }, 120000, `'${propName} ${lockId}' fetch failed to complete in the allocated time. This may be due to a slow server response time.`, () => {
                //do something to stop loading
                set(produce((state) => { state[propName] = { ...state[propName], loading: 0 } }));
            }).then(() => {
                // Does the store data need refreshing?
                if (forceFetch || timeElapsed(get()[propName][id])) {
                    // Get the data from the server
                    apiAxios(`${url}/${id.toString().replace(/-/g, '\\')}`).then((response) => {
                        try {
                            if (!response || !response.status) {
                                throw new Error('Reponse format invalid.');
                            }
                            // Apply the tranformation function to the response
                            const data = transformedDataFn(response);
                            // Update the store and return the promised data
                            set(produce((state) => { state[propName] = response.status === 200 ? { ...state[propName], [id]: { data: data, time: new Date() }, loading: 0 } : { ...state[propName], [id]: { data: undefined } } }));
                            resolve(data);
                        } catch (e1) {
                            // Report error and update the store. Store data not updated.
                            toastError(e1);
                            set(produce((state) => { state[propName] = { ...state[propName], loading: 0 } }));
                            reject(e1);
                        }
                    }).catch((e2) => {
                        // Report error and update the store. Store data not updated.
                        toastError(e2);
                        set(produce((state) => { state[propName] = { ...state[propName], loading: 0 } }));
                        reject(e2);
                    });
                } else {
                    // No need to refresh the data so serve exisiting promised data
                    set(produce((state) => { state[propName] = { ...state[propName], loading: false } }));
                    resolve(get()[propName][id].data);
                }
                // });
            }).catch((e3) => {
                // Report error and update the store. Store data not updated.
                toastError(e3);
                set(produce((state) => { state[propName] = { ...state[propName], loading: false } }));
                reject(e3);
            });
        });
    };
};

export const fetchByIdNoGlobalLockFn = (set, get, propName, url, transformedDataFn = (response) => responseData(response)) => {
    return (id, forceFetch = false) => {
        return new Promise((resolve, reject) => {
            // Ajax calls are async promises so ensure that each call is queued.
            // Identify each call with a unique call. When the lock is aquired,
            // it is time for that call to determine if the store data needs
            // refreshing before being returned by the promise.
            let lockId = getLockId();
            // Wait for the lock to be acquired.
            until(() => {
                //console.log('test RM=> ',get()[propName][id]);
                if (get()[propName][id] === undefined) {
                    // Acquiring the lock
                    set(produce((state) => { state[propName] = { ...state[propName], [id]: { data: undefined, loading: 0 }, loading: 0 } }));
                    //console.log(`test RM=> ${propName}${id} loading:  `, lockId);
                } else if (!(get()[propName][id].loading > 0)) {
                    // Acquiring the lock
                    set(produce((state) => { state[propName][id].loading = lockId; }));
                    //console.log(`test RM=> ${propName}${id} loading:  `, lockId);
                }
                return get()[propName][id].loading === lockId;
            }, 300000, `'${propName} ${lockId} ${url}' fetch failed to complete in the allocated time. This may be due to a slow server response time.`, () => {
                //do something to stop loading
                set(produce((state) => { state[propName] = { ...state[propName], [id]: { data: undefined, loading: 0 }, loading: 0 } }));
            }).then(() => {
                // Does the store data need refreshing?
                if (forceFetch || timeElapsed(get()[propName][id])) {
                    // Get the data from the server
                    apiAxios(`${url}/${id.toString().replace(/-/g, '\\')}`).then((response) => {
                        try {
                            if (!response || !response.status) {
                                throw new Error('Reponse format invalid.');
                            }
                            // Apply the tranformation function to the response
                            const data = transformedDataFn(response);
                            // Update the store and return the promised data
                            set(produce((state) => { state[propName] = response.status === 200 ? { ...state[propName], [id]: { data: data, time: new Date() }, loading: 0 } : { ...state[propName], [id]: { data: undefined, loading: 0 } } }));
                            resolve(data);
                        } catch (e1) {
                            // Report error and update the store. Store data not updated.
                            toastError(e1);
                            set(produce((state) => {
                                state[propName][id].loading = 0;
                            }));
                            reject(e1);
                        }
                    }).catch((e2) => {
                        // Report error and update the store. Store data not updated.
                        toastError(e2);
                        set(produce((state) => { state[propName][id].loading = 0; }));
                        reject(e2);
                    });
                } else {
                    // No need to refresh the data so serve exisiting promised data
                    set(produce((state) => { state[propName][id].loading = 0; }));
                    resolve(get()[propName][id].data);
                }
                // });
            }).catch((e3) => {
                // Report error and update the store. Store data not updated.
                toastError(e3);
                set(produce((state) => { state[propName][id] = { data: undefined, loading: 0 } }));
                reject(e3);
            });
        });
    };
};

export const fetchFn = (set, get, propName, url, transformedDataFn = (response) => responseData(response)) => {
    return fetchAsynTransformFn(set, get, propName, url, (response) => new Promise((resolve) => resolve(transformedDataFn(response))));
};

export const getLockId = () => { return Math.floor(Math.random() * 999999999999); };

export const postBooleanPromiseFn = (url, noPostData = false, doToastSuccess = true) => {
    return function (store, postData) {
        !noPostData && throwIfDataNotObject(postData);
        return new Promise(async (resolve) => {

            return apiAxios.post(url, postData, HEADERS_JSON).then((response) => {

                throwIfStatusNot200(response);

                if (!noPostData && doToastSuccess) {
                    toastSuccess();
                }
                resolve(true);
            }).catch((e) => {
                toastError(e);
                resolve(false);
            });
        });
    };
};

export function postDataPromiseFn(url, successDataFn = (store, postData, response) => responseData(response), failureDataFn = (store, postData) => null) {
    return function (store, postData) {
        //Zc - need to check with Xavier for postmethod that receive integers and not objects  throwIfDataNotObject(postData);
        return new Promise(async (resolve) => {
            return apiAxios.post(url, postData, HEADERS_JSON).then((response) => {

                throwIfStatusNot200(response);

                resolve(successDataFn(store, postData, response));

            }).catch((e) => {

                toastError(e);
                resolve(failureDataFn(store, postData));
            });
        });
    };
};

export function postDataPromiseGetRpCallUrl(urlMethod, successDataFn = (store, postData, response) => responseData(response), failureDataFn = (store, postData) => null) {
    return function (store, postData) {
        //Zc - need to check with Xavier for postmethod that receive integers and not objects  throwIfDataNotObject(postData);
        return new Promise(async (resolve) => {
            return apiAxios.post(store.get().getLocationConfig().getRpCallUrl(urlMethod), postData, HEADERS_JSON).then((response) => {

                throwIfStatusNot200(response);

                resolve(successDataFn(store, postData, response));

            }).catch((e) => {

                toastError(e);
                resolve(failureDataFn(store, postData));
            });
        });
    };
};

export function postFilePromiseFn(url, successDataFn = (store, postData, response) => responseData(response), failureDataFn = (store, postData) => null) {
    return function (store, postData) {
        //Zc - need to check with Xavier for postmethod that receive integers and not objects  throwIfDataNotObject(postData);
        return new Promise(async (resolve) => {
            return apiAxios.post(url, postData, HEADERS_MULTIPART).then((response) => {
                throwIfStatusNot200(response);
                resolve(successDataFn(store, postData, response));
            }).catch((e) => {
                toastError(e);
                resolve(failureDataFn(store, postData));
            });
        });
    };
};

export const responseData = (response, defaultValue = null) => {
    let data = defaultValue;
    const contentType = response && response.headers && response.headers['content-type'];
    if (response.isValid === true || (contentType && contentType.indexOf("application/json") !== -1)) {
        data = (response && response.data && response.data.value ? response.data.value : response.data) || defaultValue;
    } else {

        let err = new Error(`Invalid content type returned (${contentType})`);
        toastError(err);
        console.log('error1', response);
        console.dir(response);
    }
    return data || defaultValue;
};

export const throwIfDataIndexNotFound = (methodName, idx, id) => {
    if (idx === -1) {
        throw new Error(`${methodName}: Data item ${id} not found.`);
    }
};

export const throwIfDataNotObject = (data) => {
    if (typeof data !== 'object') {
        throw new Error(`Expected an object. Got a ${typeof data})`);
    }
};

export const throwIfDataNotValid = (data) => {
    if (!data.isValid) {
        throw new Error(`Data error (data.isValid: ${data.isValid})`);
    }
};

export const throwIfDataStatusNot200 = (data) => {
    if (data.statusCode !== 200) {
        throw new Error(`Data error (data.statusCode: ${data.statusCode})`);
    }
};

export const throwIfStatusNot200 = (response) => {
    if (response.status !== 200) {
        throw new Error(`Data error (status ${response.status})`);
    }
};

/**
 * Return true if data not set or the time elapsed since
 * the last property update is greater than REFRESH_AFTER.
 *
 * @param {object} obj
 * @returns
 */
export const timeElapsed = (obj) => !obj || !obj.data || (obj.time instanceof Date && (new Date() - obj.time > REFRESH_AFTER));

export const toastError = (error = { message: 'Error' }, altMessage = null) => {
    console.error("Toast error : " + (altMessage || JSON.stringify(error)));
    try {
        const msg = altMessage || error.message;
        // If the same messages are received within 5 sec of the first one then do not display.
        MessageQ.execute(msg, 5000, () => toast.error(msg, {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light"
        }))
    } catch (e) {
        console.error(e);
    }
};

export const toastSuccess = (message = 'Data saved') => {
    toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
    });
};

export const until = (conditionFn, timeout = 60000, errorMsg = 'Wait time exceeded.', onErrorFn = null) => {
    let attempts = 0;
    const poll = async (resolve, reject) => {
        let waitFor = Math.floor(Math.random() * (2000 - 100)) + 100; // 10 to 200
        attempts += waitFor;
        if (await conditionFn()) {
            resolve();
        } else {
            if (attempts > timeout) {
                if (onErrorFn) {
                    onErrorFn();
                }
                reject(new Error(errorMsg));
            } else {
                setTimeout(_ => poll(resolve, reject), waitFor);
            }
        }
    }
    return new Promise(poll);
};
