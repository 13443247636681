import React, {useEffect, useRef} from 'react';
import {toastError} from "../../../hooks/useLmsStore";

const ImageSelector = ({

                           onSelect = () => null,
                           onClear = () => null,
                           triggerClear = 0
                       }) => {

    const inputRef = useRef(null);
    const maxFileSize = 500 ;

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0];
            if (img.size > maxFileSize * 1024) {
                toastError({message: `Image should be less than ${maxFileSize}KB in size`})
                return
            }

            onSelect(img);
        }
    };

    const clearImage = () => {
        if (inputRef.current) {
            inputRef.current.value = null;
        }
        onClear();
    };

    useEffect(() => {
        if (triggerClear) {
            clearImage()
        }
    }, [triggerClear])

    return (
        <div>
            <div className="input-group mb-3">
                <input
                    type="file"
                    className="form-control"
                    ref={inputRef}
                    accept="image/*"
                    onChange={handleImageChange}
                />
                <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={clearImage}
                >
                    Clear
                </button>
            </div>
        </div>
    );
};

export default ImageSelector;
