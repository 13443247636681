import MaterialReactTable from 'material-react-table';
import { faPrint } from '@fortawesome/pro-light-svg-icons';
import { apiAxiosCalls } from "../../../services/apiAxiosCalls";
import { Container, Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { userService } from '../../../services/common/user.service';

export default function PersonGroupDetails({ personID, dimensions }) {
    const [rowSelection, setRowSelection] = useState({});
    const [loading, setLoading] = useState(true);
    const [searchString, setSearchString] = useState('');
    const [users, setUsers] = useState([]);
    let userId = userService.UserId;

    const getPersonGroupDetails = async (userId) => {
        try {
            const { data } = await apiAxiosCalls.getAccredGroupCyclesPlusByManager(userId);
            if (!data.isValid) {
                throw new Error("Error getting person group details");
            }
            else {
                data.value = data.value.filter(d => d.personID === 0);
                setUsers([...data.value.map((x, index) => ({
                    index: index,
                    groupId: x.groupId,
                    groupName: x.groupName,
                    groupDescription: x.groupDescription,
                    isAutoAssignDefault: x.isAutoAssignDefault,
                    dateRangeOrFullName: x.dateRangeOrFullName,
                    BE: x.be,
                    QA: x.qa,
                    AP: x.ap,
                    KT: x.kt,
                    OF: x.of,
                    LV: x.lv,
                    CR: x.cr,
                    MR: x.mr,
                    SE: x.se,
                    sortDate: x.sortDate,
                }))]);
            }
        }
        catch (error) {
            console.log(`Error fetching person group details: ${error}`)
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (personID > 0) {
            setLoading(true);
            getPersonGroupDetails(personID);
        }
    },
        [personID]);

    const renderColumnHeaderWithTooltip = (columnName, message) => {
        return <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{message}</Tooltip>}><div>{columnName}</div></OverlayTrigger>
    }

    const columns = useMemo(
        () =>
            [
                {
                    accessorKey: 'groupName',
                    header: 'Group Name',
                    size: 100
                },
                {
                    accessorKey: 'groupDescription',
                    header: 'Description',
                    size: 150
                },
                {
                    accessorKey: 'dateRangeOrFullName',
                    header: ' Start         -            End',
                    size: 170,
                    Cell: ({ cell, row }) => (

                        <>
                            {
                                row.original.dateRangeOrFullName.includes('/') ?
                                    <div style={{ 'textAlign': 'left' }}>{row.original.dateRangeOrFullName}</div> :
                                    <div style={{ 'textAlign': 'center' }}>{row.original.dateRangeOrFullName}</div>
                            }
                        </>
                    )
                },
                {
                    accessorKey: 'BE',
                    header: renderColumnHeaderWithTooltip("BE", "Behavioural Exchange"),
                    size: 10
                },
                {
                    accessorKey: 'QA',
                    header: renderColumnHeaderWithTooltip("OA", "Operational Audit"),
                    size: 10
                },
                {
                    accessorKey: 'KT',
                    header: renderColumnHeaderWithTooltip("KT", "Knowledge Test"),
                    size: 10
                },
                {
                    accessorKey: 'OF',
                    header: renderColumnHeaderWithTooltip("OF", "Operational Forum"),
                    size: 10
                },
                {
                    accessorKey: 'LV',
                    header: renderColumnHeaderWithTooltip("LV", "LV Forum"),
                    size: 10
                },
                {
                    accessorKey: 'CR',
                    header: renderColumnHeaderWithTooltip("CR", "Craft Refresher"),
                    size: 10
                },
                {
                    accessorKey: 'MR',
                    header: renderColumnHeaderWithTooltip("MR", "Meter Response"),
                    size: 10
                },
                {
                    accessorKey: 'SE',
                    header: renderColumnHeaderWithTooltip("SE", "Sub Entry"),
                    size: 10
                },
                {
                    accessorKey: 'index',
                    header: '',
                    size: 20,
                    Cell: ({ cell, row }) => (
                        <>
                            <Link to='/lms/upcomingevents/${row.original.groupId}'><FontAwesomeIcon icon={faPrint} size="lg" /></Link>  &nbsp;&nbsp;
                        </>
                    )
                }
            ],
        [],
    );

    return (
        <Container fluid >
            <MaterialReactTable
                columns={columns}
                data={users || []}
                enableHiding={false}
                enableBottomToolbar={true}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableStickyHeader={true}
                enableFullScreenToggle={false}
                enableGrouping={false}
                enablePagination={true}
                enableSorting={false}
                enableTopToolbar={true}
                enableFilterMatchHighlighting={true}
                globalFilterFn="contains"//By default is fuzzy
                enableGlobalFilter={true}
                getRowId={(originalRow) => originalRow.index}
                muiTableBodyRowProps={({ row }) => ({
                    onClick: () =>
                        setRowSelection((prev) => ({ [row.id]: !prev[row.id] })),
                    hover: true,
                    selected: rowSelection[row.id],
                    sx: {
                        cursor: 'pointer',
                    },
                })}
                muiTableContainerProps={({ table }) => dimensions.computedMuiTableContainerProps(table)}
                onGlobalFilterChange={setSearchString} //zc - new for custom search
                muiToolbarAlertBannerProps={{
                    sx: {
                        display: 'none'
                    }
                }}
                renderTopToolbarCustomActions={() => <h6 className='text-muted border border-secondary shadow p-2'>Employee Group Details</h6>}
                initialState={{
                    pagination: { pageIndex: 0, pageSize: 10 },
                    globalFilter: searchString, //zc - new for custom search
                    showGlobalFilter: true //This displays the seach generic way for the materia react table

                }}
                state={{
                    isLoading: loading,
                    globalFilter: searchString,//zc - new for custom search
                }}
            />
        </Container>
    );
}
