import { useState, useEffect } from 'react';
import { Container, Alert, Tab, Tabs, TabPane } from "react-bootstrap";
import useLmsStore, { responseData, getCompanyDetail, postCompany, FORMCONFIRM } from "../hooks/useLmsStore";

import TabCompanyDetails from '../components/TabCompanyDetails';
import TabCompanyAddresses from '../components/TabCompanyAddresses';
import TabCompanyDivisions from '../components/TabCompanyDivisions';
import TabCompanyPeople from '../components/TabCompanyPeople';

let showConfirm = false;

const FormCompany = ({ company, addressTypes, allCountries, allCounties, allTowns }) => {
    const [companyDetail, setCompanyDetail] = useState([]);
    const [companyDetailLoading, setCompanyDetailLoading] = useState(true);
    const [companyError, setCompanyError] = useState(false);
    const [key, setKey] = useState(0);
    const [showDialog, setShowDialog] = useState(false);


    const isTrainingSupplier = x => x && x.companyTypes && x.companyTypes.filter(e => e === ('training supplier')).length > 0;

    

    const handleFormLoad = () => {
        setCompanyError(false);
        setCompanyDetailLoading(true);
        setShowDialog(false);
        showConfirm = false;
        if (company && company.companyId) {
            getCompanyDetail(company.companyId).then((response) => {
                let data = responseData(response, []);
                if (data.companyId) {
                    setCompanyDetail(data);
                    setCompanyDetailLoading(false);
                }
                if (data.statusCode !== 200 || !data.value) {
                    setCompanyError(true);
                    setCompanyDetailLoading(false);
                }
            });
        }
        else {
            //if it is a new company!
            setCompanyDetail({
                companyId: -1,
                isActive: true,
                companyName: "",
                mainContactName: "",
                mainContactEmail: "",
                accountContactName: "",
                accountContactPhone: "",
                accountContactEmail: "",
                mainContactPhone: "",
                canBeShare: false,
                mailByPost: false,
                mailElectronically: false,
                mainSwitchboard: "",
                companyEmail: "",
                website: "",
                isTrainingSupplier: false,
                isVenue: false,
                isClient: false,
                isExternal: false,
                cancellationTerms: "",
                comments: "",
                isApproved: false,
                auditBy: null,
                auditDate: null,
                addresses: [],
                divisions: []
            });
            setCompanyDetailLoading(false);
        }
    };

    const handleSubmit = async data => {
        showConfirm = false;
        await postCompany(useLmsStore, data);
        setCompanyDetail(data);
    };

    const handleSubmitGen = async data => {
        let d = data;       
        await handleSubmit(d);
    };

    const handleSubmitAdr = async data => {
        let d = companyDetail;
        d.addresses = data;
        await handleSubmit(d);
    };

    const handleSubmitPeople = async data => {
        let d = companyDetail;
        d.trainers = data;
        await handleSubmit(d);
    };

    const handleSubmitDiv = async data => {
        let d = companyDetail;
        d.divisions = data;
        await handleSubmit(d);
    };

    const handleHasChanged = () => {
        //setShowDialog(true);
        showConfirm = true;
    };

    useEffect(() => {
        if(showConfirm)
        {
            let confirm = window.confirm(FORMCONFIRM);
            if(confirm){
                setKey(0);
                handleFormLoad();
            }
        }
        else{
            setKey(0);
            handleFormLoad();
        }
    }, [company]);

    function ShowCompany(props) {
        return (
            companyDetailLoading ? companyError ?
                <>
                    <Alert variant="danger" >
                        <Alert.Heading>Oh snap! No data!</Alert.Heading>
                        <p>
                            No data was returned for this user!
                        </p>
                    </Alert>
                </>
                :
                <>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border mt-5 text-primary" style={{ width: '5rem', height: '5rem' }} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </>
                :
            
                <Container fluid>
                <Tabs defaultActiveKey={key} className="mt-3 mb-2" onSelect={(k) => setKey(k)}>
                        <Tab title='Company Details' key={0} eventKey={0} >
                            <TabCompanyDetails submitFn={(obj) => handleSubmitGen(obj)} hasChangedFn={handleHasChanged} closeFn={handleFormLoad} companyDetails={companyDetail} />
                        </Tab>                   
                        <Tab title='Trainers' key={1} eventKey={1}  tabClassName={!isTrainingSupplier(company) ? 'd-none' :''} >
                        <TabCompanyPeople submitFn={(obj) => handleSubmitPeople(obj)} hasChangedFn={handleHasChanged} closeFn={handleFormLoad} people={companyDetail.trainers} companyId={companyDetail.companyId}  />
                        </Tab>                      
                        <Tab title='Addresses' key={2} eventKey={2} >
                            <TabCompanyAddresses submitFn={(obj) => handleSubmitAdr(obj)} hasChangedFn={handleHasChanged} closeFn={handleFormLoad} addresses={companyDetail.addresses} addressTypes={addressTypes} allCountries={allCountries} allCounties={allCounties} allTowns={allTowns} />
                        </Tab>
                        <Tab title='Divisions' key={3} eventKey={3} >
                            <TabCompanyDivisions submitFn={(obj) => handleSubmitDiv(obj)} hasChangedFn={handleHasChanged} closeFn={handleFormLoad} divisions={companyDetail.divisions} />
                        </Tab>
                    </Tabs>
                </Container>
             
        );
    };

    return (
        <ShowCompany data={companyDetail} />
        
    );
};

export default FormCompany;