import { useState, useMemo, useCallback, useReducer, useRef } from 'react';
import { Row, Button, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { TableContainer } from '../helpers/tableHelper';
import MaterialReactTable from 'material-react-table';
import ModalPeopleSelect from './ModalPeopleSelect';


const PeopleSelect = ({addFn = (obj) => null,removeFn = (obj) => null, selectedPeople, allPeople}) => {
const [showModalPeople, setShowModalPeople] = useState(false);
const [assignedPeopleS, setAssignedPeopleS] = useState(selectedPeople);
const [apply, setApply] = useState(false);
const [userToRemove, setUserToRemove] = useState(null);
const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false); 
const toggleModalPeople = () => {
        setShowModalPeople(!showModalPeople);
    }   

const handleRemove = (row) => {
   
    setUserToRemove(row);
    setShowConfirmRemoveModal(true);
}
    const handlePeopleAdd = () => {
   
        setShowModalPeople(true);
    }
    

    //Add the selected list of new people to assign to the required list to update both database and screen
  const addPeopleFn = (objs) => {
   
    let assignedPeopleCopy = (assignedPeopleS.length>0? structuredClone(assignedPeopleS):[]); 
    let objsC = objs;
 
    //Add selected people in the new list thst would be used later to save in database
     for (const g of objsC){

        const item = (element) => element.personId === g.personId;

         //Check if exist in the assign group list
        let idx = assignedPeopleCopy.findIndex(item);
         if (idx<0){

        assignedPeopleCopy.push({
            personId: g.personId,
            fullName:g.fullName,
            employeeId:g.employeeId,
            email: g.email
          });   
        }
         
        setAssignedPeopleS([...assignedPeopleCopy]);

        //Set if it is dirty
       //updateIsDirty();
   
       toggleModalPeople(); 
       addFn(assignedPeopleCopy);
    }
}

    const cancelRemove = () => {
        setShowConfirmRemoveModal(false);
    };

    const confirmRemove = () => {
        if (userToRemove) {
            setApply(true);
            const index =  assignedPeopleS.findIndex((item) => item.personId === userToRemove.personId);
           
            if (index !== -1) {
                 assignedPeopleS.splice(index, 1);
                
                setAssignedPeopleS([...assignedPeopleS]);
                removeFn(assignedPeopleS);
            }          
            setShowModalPeople(false);
            setShowConfirmRemoveModal(false);         
        }

        
    };

    const columnsPeople = useMemo(
        () => [            
            {
                size: 150,
                accessorKey: "fullName",
                header: "Name"
            },
            {
                size: 35,
                accessorKey: "employeeId",
                header: "Employee Id"              
            },
            {
                accessorKey: 'personId',
                header: 'Actions',
                muiTableBodyCellProps: {
                    align: 'center',
                    sx: {
                        textOverflow: 'unset'
                    }
                },
                size: 25,
                Cell: ({ cell, row }) => {
                    return (<>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Unassign person</Tooltip>}>
                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleRemove(row.original)}>
                                <FontAwesomeIcon size='xl' icon={faTrash} className='text-primary' />
                            </Button>
                        </OverlayTrigger>
               
                    </>)
                }
            } 
        ],
        [],
    );


    return (
        <>
         <h5>Assigned Users</h5>
            <Row>
            <TableContainer>
              <MaterialReactTable
                columns={columnsPeople}
                autoResetPageIndex={false}
                data={assignedPeopleS}
                enableSorting={true}
                enablePagination={assignedPeopleS? (assignedPeopleS.length>10?true:false):false}
                initialState={{
                    pagination: { pageIndex: 0, pageSize: 10 },
                    showGlobalFilter: true
                }}            
                muiSearchTextFieldProps={{
                  placeholder: `search assigned`,                 
                }}
                renderToolbarInternalActions={({ table }) => (
                    <>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Assign a new user</Tooltip>}>
                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handlePeopleAdd()}>
                                Add
                            </Button>
                        </OverlayTrigger>
                        
                    </>
                )}  
             muiTableHeadProps={{
                            sx: {
                                display: 'none'
                            }
                        }}
              muiToolbarAlertBannerProps={{
                            sx: {
                                display: 'none'
                            }
                        }}

            
            />
            </TableContainer>
            </Row>
            <ModalPeopleSelect allPeople={allPeople} selectedData={assignedPeopleS} closeFn={toggleModalPeople} open={showModalPeople} submitFn={(obj) =>addPeopleFn(obj)}></ModalPeopleSelect>
            <Modal show={showConfirmRemoveModal} onHide={cancelRemove}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Remove</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to remove this user?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancelRemove}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={confirmRemove}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    );
};

export default PeopleSelect;