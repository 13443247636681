// import { Table, Row, Col } from "@mui/material";
import React from "react";
import { useState, useEffect, useMemo } from "react";
import { Button, Container, Table, Row, Col } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import { catalogService } from "../services/catalog.service";
import { userService } from '../../../services/common/user.service';
import RequestCourseTrainingEmployees from './request-course-training-employees'; 
import HeadingWithBackButton from '../../../components/common/heading-with-back-button';

export default function RequestCourseTraining(props)
{
    const [loading, setLoading] = useState(false);
    const [courseInfo, setCourseInfo] = useState({});
    const [dislayCourseTrainingEmployees, setDislayCourseTrainingEmployees] = useState(false);
    const [staffList, setStaffList] = useState([]);
    //const[userId, setUserId] = useState(0);
    //let userId = 0; 
    let userId = userService.UserId;

    // userService.getUserId().subscribe(x=> 
    //     {
    //         console.log('user id => ' + x);
    //         userId = x;
    //     })

    useEffect(() => 
    {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setLoading(true);
        catalogService.getCourseInfo(props.courseInfo.CourseID)
        .then(result => 
            {
                setCourseInfo(result);
            })
        .finally(() => 
        {
            setLoading(false);
        })

    },[props.courseInfo.CourseID]);

    const onClickDisplayEmployeesList = (event) => 
    {
        
        let mgrId = props.courseInfo.ManagerID && props.courseInfo.ManagerID > 0 ? props.courseInfo.ManagerID : userId;
        //alert('props user id: ' + props.courseInfo.ManagerID + '  user id from observable:' + userId);
        event.preventDefault();
        setLoading(true);
        // alert(JSON.stringify(props.courseInfo));
        catalogService.GetCourseAssignmentContainer(props.courseInfo.DeliveryMethodID, mgrId, props.courseInfo.CourseID)
        .then(result => 
            {
              
                setStaffList(result[0]);
            })
        .finally(() => 
        {
            // if (staffList?.length === 0)
            //   setStaffList([]);
            setLoading(false);
            setDislayCourseTrainingEmployees(true);
            //console.log('getting staff list:' + JSON.stringify(staffList));
        });
    }

    const requestCourseTrainingBackButtonClick = (event) => 
    {
      
        event.preventDefault();
        setLoading(true);
        setTimeout(() => 
        {
            setDislayCourseTrainingEmployees(false);
            setLoading(false);
        }, 100);
    }

    const onUpdateStaffList = (staffList) => 
    {       
        setStaffList(staffList);
       
    }

    const onSubmit= (staffList) => 
        {        
           onUpdateStaffList(staffList);      
          

        }


    return (
        <Container fluid className='h-100 overflow-auto'>
            {
                loading ?
                <>
                    {/* <div className="d-flex justify-content-center">
                        <div className="spinner-border mt-5 text-primary" style={{ width: '5rem', height: '5rem' }} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> */}
                    <Button variant="primary" disabled className="mx-2">
                        <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                        Please wait...
                    </Button>
                </>
                :
                <>
                {
                    !dislayCourseTrainingEmployees ?
                
                    <>
                        <Card>
                        <HeadingWithBackButton
                            Title = {"Request Course: " + "[ " + props.courseInfo?.CourseTitle + " ]" }
                            titleStyle = {{'fontWeight':'bold', 'fontSize':'20px'}}
                            onBackButtonClick = {props.onBackButtonClick}
                        >
                        </HeadingWithBackButton>

                        {/* <Card.Header style={{'maxHeight':'70px'}}>
                                    <div className="row">
                                        <div className="col col-md-8 col-lg-10">
                                            {props.courseInfo.CourseTitle}
                                        </div>
                                        <div className="col col-md-4 col-lg-2 float-right">
                                            &nbsp;&nbsp;
                                            <Button 
                                                variant='btn btn-outline-primary'
                                                style={{marginLeft:'5px', marginRight:'10px', 'width':'100px'}}
                                                onClick={(event)=> {props.onBackButtonClick(event)}}
                                            >
                                                Back
                                            </Button>

                                        </div>
                                    </div>
                            <div></div>
                            
                            </Card.Header> */}
                        <Card.Body>
                        <Card.Title style={{fontSize:'16px'}}>Prerequisites</Card.Title>
                        <hr></hr>
                            <div style={{fontSize:'12px'}}>
                            
                                {' '}
                                { courseInfo.Prerequisites ?
                                <p>{ courseInfo.Prerequisites }</p>
                                :
                                <p style={{fontSize:'16px', 'textAlign':'center', 'paddingTop':'30px', 'paddingBottom':'30px'}}>
                                    Not Applicable
                                </p>
                                }
                                {/* {  
                                    !courseInfo.Prerequisites && 
                                    <div style={{fontSize:'12px'}}>
                                        <p>You also need to have attended the course, LV Overhead Lines Construction.&nbsp; ​<br />You must hold an IPAF Static Boom 1b Qualification and you must have 4 x 4 Driving Authorisation.&nbsp; ​<br />You must bring evidence of all the above or you may not be able to attend this course.​<br /><br />You need to bring the following items of PPE with you: Flame Retardant Coveralls, Safety Footwear, Gloves, Wet Weather Gear, Protective Eyewear (Safety Glasses or Goggles), a High Visibility Coat or Waistcoat, Climbing Equipment (Climbing Irons, Pole Choke, Full Body Pole Harness, Pole Strap, JAG 10 and Minimaxi) and a Safety Helmet, along with a copy of the Distribution Safety Rules, the Overhead Craft Manual and the Overhead Construction Manual.&nbsp; If you don&rsquo;t bring them, then you may not be able to complete the Course.&nbsp; ​<br /><br />You also need to have a current Fitness for Work Assessment, be fit to use Climbing Irons and be declared as being fit to Work at Height by Occupational Health. ​<br />&nbsp;​<br /><br />OVERHEAD LINES APPRENTICES ​<br /><br />This course is the fourth of seven in Stages 1 and 2 of your Apprentice Scheme.​<br /><br />​<br />UPSKILLING​<br />The course is normally for those who are registered on a formal company training programme, such as the Overhead Lines Apprentice Scheme.&nbsp; If you aren&rsquo;t an Overhead Lines Apprentice, before booking a place on this course, you must agree a personal training programme with your Supervisor, which needs to include</p>                            
                                    </div>
                                } */}
                                {' '}
                            </div>
                        </Card.Body>
                        {/* <Card.Footer className="text-muted">
                            <button variant = 'btn btn-secondary'>Cancel</button>
                        </Card.Footer>             */}
                        </Card>        
                        <div>&nbsp;</div>
                        <div className="float-right">
                                <Button 
                                    variant='btn btn-outline-primary'
                                    style={{marginLeft:'5px', marginRight:'10px', 'width':'100px'}}
                                    onClick={(event)=> {onClickDisplayEmployeesList(event)}}
                                >
                                    Continue
                                </Button>
                                <Button 
                                    variant='btn btn-outline-primary'
                                    style={{marginLeft:'5px', marginRight:'10px', 'width':'100px'}}
                                    onClick={(event)=> {props.onBackButtonClick(event)}}
                                >
                                    Cancel
                                </Button>

                        </div>
                    </> : ''
                }
                {
                    dislayCourseTrainingEmployees && staffList.length > 0 ?
                    <RequestCourseTrainingEmployees
                        courseInfo = { props.courseInfo }
                        staffList = { staffList }
                        onBackButtonClick = { requestCourseTrainingBackButtonClick }
                        onUpdateStaffList = {onUpdateStaffList}
                        onSubmit = {onSubmit}
                    >

                    </RequestCourseTrainingEmployees>
                    : ''
                }
                </>
            }
        </Container>
    )

}