
import { Container, Alert, Tab, Tabs } from "react-bootstrap";
import { React, useState, useEffect } from 'react';
import TabLearningPathwayDetail from './TabLearningPathwayDetail';
import TabLearningPathwayAssignedGroupsUser from './TabLearningPathwayAssignedGroupsUser';
import useLmsStore, { postSavePathwayAdmin, FORMCONFIRM } from "../../hooks/useLmsStore";
import { ModalConfirmCancel } from '../ModalConfirmation';
import shallow from 'zustand/shallow';
import { faRoad, faPeopleGroup, faArrowsUpDownLeftRight } from '@fortawesome/pro-solid-svg-icons';
import LearningPathwaysAdminNavCard from "./LearningPatwaysAdminNavCard";
import {
  activeTabsEnum,
} from "./learningPathwaysAdminEnumsAndConstants";
import LearningItemTabCard from "../learningItemsPageComponents/LearningItemTabCard";

let showConfirm = false;
const FormPathway = ({ pathwayP, displayAssignTab, editGroupDateFFn = (obj) => null, editUserDateFFn = (obj) => null, savePathwayFFn = (obj) => null, dataHasChangedFn = (obj) => null }) => {
  const [key, setKey] = useState(0);
  const [activeTab, setActiveTab] = useState((displayAssignTab ? activeTabsEnum.AssignedGroupsUser : activeTabsEnum.LearningPathwayDetail));
  const [items, setItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [assignedPeople, setAssignedPeople] = useState(null);
  const [assignedGroups, setAssignedGroups] = useState(null);
  const [pathway, setPathway] = useState(null);
  const [hasChangedPwF, setHasChangedPwF] = useState(false);
  const [hasChangeAssignF, setHasChangedAssignF] = useState(false);
  const [showCancelSelectModal1, setshowCancelSelectModal1] = useState(false);
  const [showCancelSelectModal2, setshowCancelSelectModal2] = useState(false);
  const { applicationGroups, applicationPeople, allLearningItems, fetchApplicationGroups, fetchApplicationPeople, fetchAllLearningItems } = useLmsStore(state =>
  ({
    allLearningItems: state.allLearningItems.data,
    applicationGroups: state.applicationGroups.data,
    applicationPeople: state.applicationPeople.data,
    fetchAllLearningItems: state.fetchAllLearningItems,
    fetchApplicationGroups: state.fetchApplicationGroups,
    fetchApplicationPeople: state.fetchApplicationPeople
  }), shallow)

  useEffect(() => {
    if (showConfirm) {
      let confirm = window.confirm(FORMCONFIRM);
      if (confirm) {
        handleFormLoad();
      }
    }
    else {
      handleFormLoad();
    }

    if (!displayAssignTab) {
      setActiveTab(activeTabsEnum.LearningPathwayDetail);
    }
  }, [pathwayP]);

  const handleFormLoad = () => {
    if (pathwayP && pathwayP.learningProgrammeGroupId) {
      setPathway(pathwayP);
      fetchApplicationGroups();
      fetchApplicationPeople();
      fetchAllLearningItems();
    }
  }

  const handleHasChanged = () => {
    //setShowDialog(true);
    // showConfirm = true;
  };

  const onLearningPathwayDetailClick = () => {

    if (hasChangeAssignF) {
      setshowCancelSelectModal1(true);
    }
    else {
      setActiveTab(activeTabsEnum.LearningPathwayDetail);
    }
  }
  const onAssignedGroupsUserClick = () => {
    if (hasChangedPwF) {
      setshowCancelSelectModal2(true);
    }
    else {
      setActiveTab(activeTabsEnum.AssignedGroupsUser);
    }
  }

  const onEditGroupDateFFn = (group) => {
    editGroupDateFFn(group);
  }

  const onEditUserDateFFn = (user) => {
    editUserDateFFn(user);

  }

  const handleAddItem = () => {
    setEditingItem({ id: new Date().getTime(), name: '' });
  };

  const handleEditChange = (e) => {
    setEditingItem((prevItem) => ({
      ...prevItem,
      name: e.target.value,
    }));
  };

  const handleSaveItem = () => {
    if (editingItem.name.trim() !== '') {
      setItems((prevItems) => [...prevItems, editingItem]);
      setEditingItem(null);
    }
  };

  const handleCancelEdit = () => {
    setEditingItem(null);
  };

  const onSavePathwayFn = (pathway) => {

    if (pathwayP.learningProgrammeGroupId < 0) {
      let pwCopy = pathwayP;
      pwCopy.learningProgrammeGroupId = pathway.learningProgrammeGroupId;
      setPathway(pwCopy);
    }

    savePathwayFFn(pathway);
  };

  const onHasChangedPwFn = (pathwayInfo) => {
    //  alert('change pw f', (pathwayInfo.isDirty?'t':'f'));
    setHasChangedPwF(pathwayInfo.isDirty);
    dataHasChangedFn({
      pathway: pathwayInfo.pathway,
      isDirty: pathwayInfo.isDirty,
      isPwDetail: true
    });
    // showConfirm(true);
    // savePathwayFFn(pathway);
  };

  const onHasChangedAssignFn = (pathwayInfo) => {
    //  alert('change pw f');

    setHasChangedAssignF(pathwayInfo.isDirty);
    dataHasChangedFn({
      pathway: pathwayInfo.pathway,
      isDirty: pathwayInfo.isDirty,
      isPwDetail: false
    });
    // showConfirm(true);
    // savePathwayFFn(pathway);
  };

  const renderCards = () => {
    return <div className={"mt-1 row gap-1 p-2"}>
      <LearningPathwaysAdminNavCard
        title={"Learning Pathway Detail"}
        onClick={onLearningPathwayDetailClick}
        icon={faRoad}
        count={10}
        isActive={activeTab === activeTabsEnum.LearningPathwayDetail}
        bottomText={<div className={"text-end fw-bold"}></div>}
      />
      {pathway.learningProgrammeGroupId > 0 ?
        <LearningPathwaysAdminNavCard
          title={"Assigned Groups / User"}
          onClick={onAssignedGroupsUserClick}
          icon={faPeopleGroup}
          count={10}
          isActive={activeTab === activeTabsEnum.AssignedGroupsUser}
          bottomTexallLearningItemseditUserDateFFnt={<div
            className={"text-end fw-bold"}
          >
          </div>
          }
        />
        : <></>}

    </div>
  }

  /*const activeTabToRenderTabMapper = {
    [activeTabsEnum.LearningPathwayDetailLearningPathwayDetail]: () => {pathway?
       <TabLearningPathwayDetail  pathway={pathway} allLearningItems={allLearningItems}  />:<><div>aa</div></>},
    [activeTabsEnum.AssignedGroupsUser]: () => <TabLearningPathwayAssignedGroupsUser />,
  }*/

  const activeTabToRenderTabMapper = {
    [activeTabsEnum.LearningPathwayDetail]: () =>
      <TabLearningPathwayDetail pathway={pathway} allLearningItems={allLearningItems} savePathwayFn={(obj) => onSavePathwayFn(obj)} hasChangedPwFn={(obj) => onHasChangedPwFn(obj)} />,
    [activeTabsEnum.AssignedGroupsUser]: () => <TabLearningPathwayAssignedGroupsUser pathway={pathway} applicationGroups={applicationGroups} applicationPeople={applicationPeople} editGroupDateFn={(obj) => onEditGroupDateFFn(obj)} editUserDateFn={(obj) => onEditUserDateFFn(obj)} hasChangedAssignFn={(obj) => onHasChangedAssignFn(obj)} />,
  }

  const renderActiveTab = () => {
    if (!activeTabToRenderTabMapper.hasOwnProperty(activeTab)) {
      return () => <>Tab not mapped correctly</>
    }

    return activeTabToRenderTabMapper[activeTab]
  }

  const handleLearningPathwayAssignedGroupsUser = async data => {

  };

  const handleLearningPathwayDetail = async data => {

  };

  const handleConfirmSelectTabCancel1 = async data => {
    setshowCancelSelectModal1(false);
  };

  const handleConfirmSelectTab1 = async data => {
    setActiveTab(activeTabsEnum.LearningPathwayDetail);
    setshowCancelSelectModal1(false);
    setHasChangedAssignF(false);
    setHasChangedPwF(false);
    dataHasChangedFn({
      pathway: pathway,
      isPwDetail: false
    });
  };

  const handleConfirmSelectTabCancel2 = async data => {
    setshowCancelSelectModal2(false);
  };

  const handleConfirmSelectTab2 = async data => {
    setActiveTab(activeTabsEnum.AssignedGroupsUser);
    setshowCancelSelectModal2(false);
    setHasChangedAssignF(false);
    setHasChangedPwF(false);
    dataHasChangedFn({
      pathway: pathway,
      isPwDetail: false
    });
  };


  return (
    <>
      <Container fluid className='h-100 overflow-auto'>
        <div>
          {pathway ? <>
            {renderCards()}
            {renderActiveTab()()}
          </> : <><div>
            No pathway selected
          </div></>}
        </div>
      </Container>
      <ModalConfirmCancel
        showCancelEditModal={showCancelSelectModal1}
        abortFn={handleConfirmSelectTabCancel1}
        continueFn={handleConfirmSelectTab1}
      />
      <ModalConfirmCancel
        showCancelEditModal={showCancelSelectModal2}
        abortFn={handleConfirmSelectTabCancel2}
        continueFn={handleConfirmSelectTab2}
      />
    </>
  );
};

export default FormPathway;