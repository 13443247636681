import MaterialReactTable from 'material-react-table';
import PersonCompetencies from './person-qualifications';
import PersonGroupDetails from './person-group-details';
import usePrevious from '../../../hooks/usePrevious';
import ViewAndPrintCertificates from './view-print-certificates';
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import { TableTheme } from '../../../helpers/tableHelper'
import { apiAxiosCalls } from "../../../services/apiAxiosCalls";
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/pro-light-svg-icons';
import { faFileCertificate, faPrint } from '@fortawesome/pro-solid-svg-icons';
import { faPeopleGroup } from '@fortawesome/pro-duotone-svg-icons';
import { useEffect, useMemo, useState, useCallback } from 'react';

export default function CompetencyUserView({ selectedManager, onBackClick, userView, actionType, setActionType, dimensions }) {
    const selectedManagerPrevious = usePrevious(selectedManager)
    const [loading, setLoading] = useState(true);
    const [searchString, setSearchString] = useState('');
    const [subordinates, setSubordinates] = useState([]);
    const [displaySubordinates, setDisplaySubordinates] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [isSelectedASubordinate, setIsSelectedASubordinate] = useState(false);

    const getSecondLevelStaffList = async (managerId) => {
        if (managerId) {
            try {
                const { data } = await apiAxiosCalls.getFirstLevelStaffList(managerId);
                if (!data.isValid) {
                    throw new Error("Error getting second level staff");
                }
                else {
                    setSubordinates([...data.value.map(x => ({
                        personID: parseInt(x.personID),
                        employeeID: x.userId,
                        fullname: x.fullname,
                        parentPersonID: parseInt(selectedManager.personID)
                    }))]);
                }
            }
            catch (error) {
                console.log(`Error fetching second level staff: ${error}`)
            }
            finally {
                setLoading(false);
            }
        } else {
            console.log('Error fetching second level staff: managerId not defined.')
        }
    }

    useEffect(() => {
        if (selectedManager && selectedManager?.personID !== selectedManagerPrevious?.personID) {
            setLoading(true);
            setSelectedUser(selectedManager);
            getSecondLevelStaffList(selectedManager.personID);
        }
    },
        [selectedManager]);

    useEffect(() => {
        if (selectedManager && selectedUser) {
            const isSelectedASubordinate = selectedUser.parentPersonID > 0;
            setIsSelectedASubordinate(isSelectedASubordinate);
            if (isSelectedASubordinate) {
                if (!breadcrumb.find(x => x.personID === selectedUser.personID)) {
                    setBreadcrumb([...breadcrumb, selectedUser]);
                }
            } else if (breadcrumb.length === 0 || breadcrumb.length > 1) {
                setBreadcrumb([selectedManager]);
            }
            setDisplaySubordinates(false);
        }
    },
        [selectedUser]);

    const columns = useMemo(
        () =>
            [
                {
                    accessorKey: 'fullname',
                    header: ''
                },

                {
                    accessorKey: 'employeeID',
                    header: ''
                },
                {
                    accessorKey: 'personID',
                    header: '',
                    //Format a number in a cell render
                    Cell: ({ cell, row }) => (
                        <>
                            <Link to='/' onClick={(event) => switchUserAndDisplay(event, 1, row.original)}><FontAwesomeIcon icon={faPrint} size="lg" /></Link>
                            <Link to='/' onClick={(event) => switchUserAndDisplay(event, 2, row.original)}><FontAwesomeIcon icon={faPeopleGroup} size="lg" style={{ margin: '0 0.75rem' }} /></Link>
                            <Link to='/' onClick={(event) => switchUserAndDisplay(event, 3, row.original)}><FontAwesomeIcon icon={faFileCertificate} size="lg" /></Link>
                        </>
                    )
                }
            ],
        [],
    );

    const sortedUsers = useMemo(() => {
        if (subordinates) {
            return [...subordinates].sort((a, b) => a.firstname?.localeCompare(b.firstname));
        }
        return [];
    }, [subordinates]);

    const getSubordinatesById = (personID) => subordinates.find(x => x.personID === personID);

    const switchUserAndDisplay = (event, actionType, newSelectedUser) => {
        if (newSelectedUser) {
            if (!selectedUser || selectedUser.personID !== newSelectedUser.personID) {
                setSelectedUser(newSelectedUser);
            }
            if (actionType) {
                switchDisplay(event, actionType);
            }
        }
    }

    const switchDisplay = (event, requestedActionType) => {
        event.preventDefault();
        if (actionType !== requestedActionType) {
            setActionType(requestedActionType);
        }
    }
    const handleBackButton = (event, index) => {
        event.preventDefault();
        let crumb = breadcrumb;
        if (breadcrumb.length > 1) {
            crumb.pop();
            setBreadcrumb(crumb);
        }
        setSelectedUser(crumb[crumb.length - 1]);
    }

    return (
        <TableTheme>
            <Container fluid className='pt-3 pb-3'>
                {
                    isSelectedASubordinate &&
                    <Row>
                        <Col>
                            <Button variant='btn btn-outline-primary' style={{ width: '100px', marginTop: '10px', marginBottom: '10px' }} onClick={handleBackButton} >
                                Back
                            </Button>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col>
                        {(subordinates && userView.isManager) && !isSelectedASubordinate ?
                            <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setDisplaySubordinates(true)}>
                                <FontAwesomeIcon icon={displaySubordinates ? faAngleDoubleUp : faAngleDoubleDown} />
                            </span>
                            : null}
                        {
                            breadcrumb.map((user, index) => (
                                <span style={{ marginLeft: '0.75rem' }} key={index} onClick={(event) => handleBackButton(event, index)}>
                                    {user.fullname} {breadcrumb.length > (index + 1) ? " / " : ''}
                                </span>
                            ))}
                    </Col>
                    <Col>
                        {selectedUser?.managerID || selectedUser.employeeID}
                    </Col>
                    <Col>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>View & Print Certificate</Tooltip>}                >
                            <Link to='/' onClick={(event) => switchDisplay(event, 1)}>
                                <FontAwesomeIcon icon={faPrint} size="lg" />
                            </Link>
                        </OverlayTrigger>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>View Employee Group Details</Tooltip>}                >
                            <Link to='/' onClick={(event) => switchDisplay(event, 2)}>
                                <FontAwesomeIcon icon={faPeopleGroup} size="lg" style={{ margin: '0 0.75rem' }} />
                            </Link>
                        </OverlayTrigger>
                        {/* Task 678 - Hide option */}
                        {/* <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>View Qualifications</Tooltip>}                >
                            <Link to='/' onClick={(event) => switchDisplay(event, 3)}>
                                <FontAwesomeIcon icon={faFileCertificate} size="lg" />
                            </Link>
                        </OverlayTrigger> */}
                    </Col>
                </Row>
            </Container>
            {
                displaySubordinates ?
                    <MaterialReactTable
                        columns={columns}
                        data={sortedUsers || []}
                        enableBottomToolbar={false}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enableDensityToggle={false}
                        enableStickyHeader={true}
                        enableFullScreenToggle={false}
                        enableGrouping={false}
                        enableHiding={false}
                        enablePagination={false}
                        enableSorting={false}
                        enableTopToolbar={true}
                        enableFilterMatchHighlighting={true}
                        globalFilterFn="contains"//By default is fuzzy
                        enableGlobalFilter={true}
                        getRowId={(originalRow) => originalRow.personID}
                        muiTableBodyRowProps={({ row }) => ({
                            onClick: (event) => {
                                switchUserAndDisplay(event, null, getSubordinatesById(row.id));
                            },
                            sx: {
                                cursor: 'pointer',
                            },
                        })}
                        muiTableContainerProps={({ table }) => dimensions.computedMuiTableContainerProps(table)}
                        onGlobalFilterChange={setSearchString} //zc - new for custom search
                        initialState={{
                            globalFilter: searchString, //zc - new for custom search
                            showGlobalFilter: true //This displays the seach generic way for the materia react table

                        }}
                        state={{
                            isLoading: loading || !subordinates,
                            globalFilter: searchString,//zc - new for custom search
                        }}
                    />
                    : null
            }
            {
                actionType === 1 ?
                    <ViewAndPrintCertificates personID={selectedUser.personID} dimensions={dimensions}></ViewAndPrintCertificates>
                    : null
            }
            {
                actionType === 2 ?
                    <PersonGroupDetails personID={selectedUser.personID} dimensions={dimensions} />
                    : null
            }
            {
                actionType === 3 ?
                    <PersonCompetencies personID={selectedUser.personID} dimensions={dimensions} />
                    : null
            }
        </TableTheme>
    );
}
