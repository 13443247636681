import { useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import { ModalConfirmCancel } from '../ModalConfirmation';

import useLmsStore, {responseData,getGroupRegItemsDates, postUpdateGroupRegItemsDates } from "../../hooks/useLmsStore";
import shallow from 'zustand/shallow';

import { isSupportedDate, toDDsMMsYYString, toDDsMMsYYYYString, toISODateString, toISODateTimeString, toLocaleDateString } from '../../helpers/formatHelper';

import { muiTableBodyCellEditFieldPropsDate } from '../../helpers/tableHelper';

import MaterialReactTable from 'material-react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faSquareCheck, faSquareMinus } from '@fortawesome/pro-light-svg-icons';

export default function LearningPathwayEditorGroupTableDate(props) {
    const [learningItemId, setLearningItemId] = useState(0);
    const [learningItem, setLearningItem] = useState(null);
    const [peopleDates, setPeopleDates] = useState([]);
    const [headerClickState, handleHeaderClick] = useReducer(function (state, action) { return { count: state.count + 1, flag: action }; }, { count: 0, flag: 'none' }); // count to ensure a change of headerClickState on every click
    const [showCancelEditModal, setShowCancelEditModal] = useState(false);
    const [tableLoading, setTableLoading] = useState(true);
    const headerSquareCheckRef = useRef();
    const headerSquareMinusRef = useRef();
    const headerSquareRef = useRef();

   /* const { fetchAdminTrainingPlanGroupSettingInfo } = useLmsStore(state =>
    ({
        fetchAdminTrainingPlanGroupSettingInfo: state.fetchAdminTrainingPlanGroupSettingInfo,
    }), shallow) */

    useEffect(() => {
        initDataRight(initDataPromise)
        // eslint-disable-next-line 
    }, [props.groupId, learningItemId]);

    useEffect(() => refreshGlobalInputCheckbox(),
        // eslint-disable-next-line 
        [props.hasSelectedRight]);

    useEffect(() => {
        props.selectRight(headerClickState.flag);
        // eslint-disable-next-line 
    }, [headerClickState]);

    useEffect(() => {
        if (props.selectedLeft && props.selectedLeft.length) {
            
         
            setLearningItemId(props.selectedLeft[0]);
        } else {
            setLearningItemId(0);
        }
        // eslint-disable-next-line 
    }, [props.selectedLeft]);

    const handleApplyDateToSelected = () => {
        if (props.hasSelectedRight !== 'none' && isSupportedDate(props.dateToApply)) {
            let date = toISODateTimeString(props.dateToApply),
                dateStr = toDDsMMsYYYYString(props.dateToApply);
            props.updateBaseRight(props.dataRight.filter(x => x.isRowSelected === true).map(x => {
                return {
                    ...x,
                    expiryDate: date,
                    expiryDateStr: dateStr,
                    isRowSelected: false
                };
            }));
        }
    }

    const handleCancelEdit = () => {
        if (props.hasUpdates) {
            setShowCancelEditModal(true);
        } else {
            setShowCancelEditModal(false);
            props.resetToRO();
        }
    }

    const handleCellClick = (cell, event) => {
        props.toggleRight(cell.row.original.personId);
    }

    const handleConfirmCancelEdit = () => {
        setShowCancelEditModal(false);
        props.resetToRO();
    }

   // const handleOnSave = () => initDataRight(() => postAdminTrainingPlanGroupSettingInfo(useLmsStore, { ...learningItem, listPeopleTrainingPlan: props.dataRight }));
   const handleOnSave = () => {
    if  (props.dataLeft && props.dataLeft.length && props.selectedLeft && props.selectedLeft.length)
     {         
      let item =  props.dataLeft.filter((a)=> a.id===props.selectedLeft[0]);

      if (item){
        let progId=item[0].learningProgrammeId;                          
        let pwId =props.pathway.learningProgrammeGroupId;
      
        postUpdateGroupRegItemsDates(useLmsStore, 
            { groupId:props.groupId, 
              learningItemId, 
              pathwayId:pwId, 
              learnProgId:progId, 
              peopleDates: props.dataRight }).then((re)=>{
                initDataRight(initDataPromise);
              });
     }
 }


     };

    const initDataRight = (initDataPromiseFn) => {
        return new Promise(res => {
            setTableLoading(true);
         
            if (learningItemId>0 && props.dataSelected && props.dataSelected.id > 0 && props.groupId > 0) {
                initDataPromiseFn().then(result => {
                    if (result){
                   let data = responseData(result, []);
                   
                   if (data){
                    if (data.length>0){
              
                   let peopleDatesC = structuredClone(data);
                    props.initData(null,peopleDatesC || []);
               //     delete learningitem.listPeopleTrainingPlan;
                    setPeopleDates(peopleDatesC);
                    setTableLoading(false);
                    res(data);
                    }
                 }
                    }
                });
            } else {
                props.initData(null, []);
                setLearningItem(null);
                setTableLoading(false);
                res([]);
            }
        });
    };

    const initDataPromise = () => new Promise((res => {
        if (props.groupId > 0 && learningItemId > 0) {
        
            getGroupRegItemsDates(learningItemId,props.groupId).then(data => res(data));
           // fetchAdminTrainingPlanGroupSettingInfo(`${props.groupId}-${learningItemId}`).then(data => res(data));
        } else {
            res([]);
        }
    }));

    const refreshGlobalInputCheckbox = () => {
        if (headerSquareRef.current && headerSquareCheckRef.current && headerSquareMinusRef.current) {
            headerSquareRef.current.style.display = props.hasSelectedRight === 'none' ? 'inline-block' : 'none';
            headerSquareMinusRef.current.style.display = props.hasSelectedRight === 'some' ? 'inline-block' : 'none';
            headerSquareCheckRef.current.style.display = props.hasSelectedRight === 'all' ? 'inline-block' : 'none';
        }
    };

    const columns = useMemo(() => [
        {
            accessorKey: 'isRowSelected',
            header: 'Select',
            Header: ({ column }) => (
                <>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Select All</Tooltip>}>
                        <FontAwesomeIcon icon={faSquare} style={{ cursor: 'pointer', fontSize: '1rem' }} ref={headerSquareRef} onClick={() => handleHeaderClick('all')} />
                    </OverlayTrigger>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Select All</Tooltip>}>
                        <FontAwesomeIcon icon={faSquareMinus} style={{ cursor: 'pointer', display: 'none', fontSize: '1rem' }} ref={headerSquareMinusRef} onClick={() => handleHeaderClick('all')} />
                    </OverlayTrigger>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Deselect All</Tooltip>}>
                        <FontAwesomeIcon icon={faSquareCheck} style={{ cursor: 'pointer', display: 'none', fontSize: '1rem' }} ref={headerSquareCheckRef} onClick={() => handleHeaderClick('none')} />
                    </OverlayTrigger>
                </>
            ),
            muiTableBodyCellProps: {
                sx: {
                    cursor: 'pointer'
                }
            },
            size: 50,
            Cell: ({ cell, row }) => <FontAwesomeIcon icon={!row.original.isInPathway? (cell.getValue() === true ?  faSquareCheck : faSquare): ''} style={{ fontSize: '1rem' }} /> 
        },
        {
            accessorKey: 'fullName',
            enableEditing: false,
            header: 'Name',
            muiTableBodyCellProps: ({ cell, table }) => {
                let isRowSelectedIsVisible = table.getColumn('isRowSelected').getIsVisible();
                return {
                    classes: { root: 'fw-unset' },   // Flexible width
                    sx: {
                        cursor: isRowSelectedIsVisible ? 'pointer' : 'inherit',
                        fontWeight: cell.row.original.isRowSelected ? 'bold' : 'normal'
                    }
                }
            },
            muiTableHeadCellProps: { classes: { root: 'fw-unset' } } // Flexible width
        },
        {
            accessorFn: (row) => toISODateString(row.expiryDate),
            id: 'expiryDate',
            header: 'Due/Expiry',
            size: 160,
            muiTableBodyCellEditTextFieldProps: { ...muiTableBodyCellEditFieldPropsDate, required: false },
            Cell: ({ cell, row }) =>  toDDsMMsYYString(row.original.expiryDate)
        },
        {
            accessorKey: 'isMandatory',
            enableEditing: false,
            header: 'M/R',
            size: 80,
            Cell: ({ cell, row }) => (row.original.isMandatory ? 'M' : '') + (row.original.isRenewable ? (row.original.isMandatory ? '/R' : 'R') : '')
        }
    ], [],);

    return (<>
        <MaterialReactTable
            columns={columns}
            data={props.dataRight}
            enableBottomToolbar={false}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableDensityToggle={false}
            enableEditing={false}
            enableFullScreenToggle={false}
            enableGrouping={false}
            enableHiding={false}
            enablePagination={false}
            enableRowVirtualization
            enableSorting={false}
            enableTopToolbar={true}
            muiTableBodyCellProps={({ cell }) => ({
                onClick: (event) => {
                    handleCellClick(cell, event);
                },
            })}
            muiTableBodyRowProps={{ hover: false }}
            muiTableContainerProps={{ sx: { maxHeight: 'max(170px, calc(100vh - 477px))' } }}
            renderTopToolbarCustomActions={({ table }) => <Row>
                <><Col xs='auto'>
                    <Form.Control type="date" onChange={(e) => props.setDateToApply(e.target.value || '')} />
                </Col>
                    <Col xs='auto'>
                        <Button type="submit" variant="outline-primary" onClick={handleApplyDateToSelected} disabled={props.hasSelectedLeft === 'none' || !isSupportedDate(props.dateToApply)}>
                            Apply date
                        </Button>
                    </Col></>
                <Col xs='auto'>
                    <Button variant="outline-secondary" onClick={handleCancelEdit}>
                        Cancel
                    </Button>
                </Col>
                <Col xs='auto'>
                    <Button type="submit" variant="primary" onClick={handleOnSave} disabled={!props.hasUpdates}>
                        {tableLoading?
                            <>
                            <span className="spinner-border spinner-border-sm mt-1" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                            Please wait...
                            </>
                            :
                            <>
                            Save
                            </>
                        }
                    </Button>
                </Col>
            </Row>}
            initialState={{
                showGlobalFilter: true
            }}
            state={{
                isLoading: tableLoading
            }}
        />
        <ModalConfirmCancel
            showCancelEditModal={showCancelEditModal}
            abortFn={() => setShowCancelEditModal(false)}
            continueFn={handleConfirmCancelEdit}
        />
    </>);
}