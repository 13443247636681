import React, { useEffect, useMemo, useState } from 'react';
import Container from "react-bootstrap/Container";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown, faAngleDoubleUp } from "@fortawesome/pro-light-svg-icons";
import { apiAxiosCalls } from "../../../services/apiAxiosCalls";
import MaterialReactTable from "material-react-table";
import { TableContainer } from "../../../helpers/tableHelper";
import moment from "moment";
import { faRectangleHistoryCircleUser } from '@fortawesome/pro-solid-svg-icons';
import EventDetailsModal from "./EventDetailsModal";

const MyTeamTrainingHistoryUserView = ({
    selectedUser = null,
    dimensions
}) => {
    const [rowSelection, setRowSelection] = useState({});
    const [trainingHistoryRowSelection, setTrainingHistoryRowSelection] = useState({});
    const [displaySubordinates, setDisplaySubordinates] = useState()
    const [subordinates, setSubordinates] = useState([])
    const [loadingSubordinates, setLoadingSuborinates] = useState(false)
    const [searchString, setSearchString] = useState('');
    const [trainingHistorySearchString, setTrainingHistorySearchString] = useState('');
    const [trainingHistory, setTrainingHistory] = useState([])
    const [subordinateTrainingHistory, setSubordinateTrainingHistory] = useState([])
    const [selectedSubordinate, setSelectedSubordinate] = useState(null)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [showEventInfoModal, setShowEventInfoModal] = useState(false)
    const [loadingTrainingHistory, setLoadingTrainingHistory] = useState(false)
    const { computedMuiTableContainerProps } = dimensions;

    function getSelectedSubordinate() {
        const keys = Object.keys(rowSelection)
        if (!keys.length) {
            return null
        }

        let id = keys[0]
        const selectedSubordinate = subordinates.find(s => s.personID === id)
        return selectedSubordinate
    }

    const toggleDisplaySubordinates = () => {
        setDisplaySubordinates(prev => !prev)
    }

    const getSubordinates = async () => {
        setLoadingSuborinates(true)
        try {
            const { data } = await apiAxiosCalls.getFirstLevelStaffList(selectedUser.personID);

            if (!data.isValid) {
                throw new Error("Error getting second level staff");
            }
            setSubordinates(data.value)
        } catch (error) {
            console.log(`Error fetching second level staff: ${error}`)
        }
        setLoadingSuborinates(false)

    }

    const sortHistoryByDate = (data) => {
        return data.sort((a, b) => {
            const dateA = moment(a.startDate, "DD/MM/YYYY");
            const dateB = moment(b.startDate, "DD/MM/YYYY");


            return dateB - dateA;
        });
    }


    const columns = useMemo(
        () =>
            [
                {
                    accessorKey: 'fullname',
                    header: '',

                },

                {
                    accessorKey: 'userId',
                    header: '',

                },
                {
                    accessorKey: 'firstname',
                    header: '',
                    //Format a number in a cell render
                    Cell: ({ cell, row }) => {
                        return <span onClick={() => {
                            setSelectedSubordinate(row.original)
                        }}>
                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip className='cls-theme-tooltip'>View training
                                    history</Tooltip>}>
                                <FontAwesomeIcon size='lg' icon={faRectangleHistoryCircleUser} className='text-info' />
                            </OverlayTrigger>
                        </span>
                    }
                }
            ],
        [],
    );

    const trainingHistoryColumns = useMemo(
        () =>
            [
                {
                    accessorKey: 'title',
                    header: 'Title',
                    size: 300
                },

                {
                    accessorKey: 'passFail',
                    header: 'Status',
                    size: 100

                },
                {
                    accessorKey: 'startDate',
                    header: 'Start date',
                    size: 100
                },
                {
                    accessorKey: 'endDate',
                    header: 'End date',
                    size: 100
                },
                {
                    accessorKey: 'duration',
                    header: 'Duration',
                    size: 100
                },
                {
                    accessorKey: 'supplier',
                    header: 'Supplier',
                    size: 150
                }
            ],
        [],
    );

    const renderTrainingHistory = () => {
        return !displaySubordinates ?
            <MaterialReactTable
                columns={trainingHistoryColumns}
                data={selectedSubordinate ? subordinateTrainingHistory : trainingHistory}
                enableBottomToolbar={true}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableStickyHeader={true}
                enableFullScreenToggle={false}
                enableGrouping={false}
                enableHiding={false}
                enablePagination={false}
                enableSorting={false}
                enableTopToolbar={true}
                enableFilterMatchHighlighting={true}
                globalFilterFn="contains"//By default is fuzzy
                //globalFilterFn={customGlobalFilterFn}
                enableGlobalFilter={true}

                getRowId={(originalRow) => originalRow.personID}
                muiTableBodyRowProps={({ row }) => ({
                    onClick: () =>
                        setTrainingHistoryRowSelection((prev) => ({ [row.id]: !prev[row.id] })),
                    hover: true,
                    selected: trainingHistoryRowSelection[row.id],
                    sx: {
                        cursor: 'pointer',
                    },
                })}
                muiTableContainerProps={({ table }) => computedMuiTableContainerProps(table)}
                onGlobalFilterChange={setTrainingHistorySearchString} //zc - new for custom search
                muiTableHeadProps={{}}
                muiToolbarAlertBannerProps={{
                    sx: {
                        display: 'none'
                    }
                }}
                initialState={{
                    pagination: { pageIndex: 0, pageSize: 10 },
                    globalFilter: trainingHistorySearchString, //zc - new for custom search
                    showGlobalFilter: true //This displays the seach generic way for the materia react table

                }}
                state={{
                    isLoading: loadingTrainingHistory,
                    globalFilter: trainingHistorySearchString,//zc - new for custom search
                }}
            />
            : <></>
    }


    const renderSubordinates = () => {
        return <> {displaySubordinates &&
            <MaterialReactTable
                columns={columns}
                //data={withSelectedRows(companies,tableOption, tableInactive) || []}
                data={subordinates || []}
                enableBottomToolbar={true}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableStickyHeader={true}
                enableFullScreenToggle={false}
                enableGrouping={false}
                enableHiding={false}
                enablePagination={true}
                enableSorting={false}
                enableTopToolbar={true}
                enableFilterMatchHighlighting={true}
                globalFilterFn="contains"
                enableGlobalFilter={true}

                getRowId={(originalRow) => originalRow.personID}
                muiTableBodyRowProps={({ row }) => ({
                    onClick: () =>
                        setRowSelection((prev) => ({ [row.id]: !prev[row.id] })),
                    hover: true,
                    selected: rowSelection[row.id],
                    sx: {
                        cursor: 'pointer',
                    },
                })}
                onGlobalFilterChange={setSearchString}
                muiTableHeadProps={{
                    sx: {
                        display: 'none'
                    }
                }}
                muiToolbarAlertBannerProps={{
                    sx: {
                        display: 'none'
                    }
                }}
                initialState={{
                    globalFilter: searchString,
                    showGlobalFilter: true

                }}
                state={{
                    setLoading: !subordinates,
                    globalFilter: searchString,
                }}
            />
        }</>
    }

    const getTrainingHistory = async (personId, setHistoryState) => {
        try {
            setLoadingTrainingHistory(true)
            const { data } = await apiAxiosCalls.getTrainingHistory(personId)
            if (!data.isValid) {
                throw new Error(data.message || "Something went wrong")
            }
            setHistoryState(sortHistoryByDate(data.value))
        } catch (e) {
            console.log(e)
        }
        setLoadingTrainingHistory(false)

    }

    const getTrainingHistoryForSelectedUser = async (personId) => {
        getTrainingHistory(personId, setTrainingHistory)
    }

    const getTrainingHistoryForSelectedSubordinate = (personId) => {
        getTrainingHistory(personId, setSubordinateTrainingHistory)
    }

    useEffect(() => {
        setTrainingHistory([])
        setSubordinateTrainingHistory([])
        setDisplaySubordinates(false)
        setSubordinates([])
        setRowSelection({})
        setSelectedSubordinate(null)
        getTrainingHistoryForSelectedUser(selectedUser.personID)
        getSubordinates()
    }, [selectedUser]);

    useEffect(() => {
        if (selectedSubordinate) {
            setSubordinateTrainingHistory([])
            setDisplaySubordinates(false)
            getTrainingHistoryForSelectedSubordinate(selectedSubordinate.personID)
        }

    }, [selectedSubordinate]);


    return (<>
        <Container fluid>
            {(displaySubordinates || selectedSubordinate) && <Row>
                <Button
                    variant='btn btn-outline-primary'
                    style={{ width: '100px', marginTop: '10px', marginBottom: '10px' }}
                    onClick={(event) => {
                        setRowSelection({})
                        setSelectedSubordinate(null)
                        toggleDisplaySubordinates()
                    }
                    }
                >
                    Back
                </Button>
            </Row>}

            <Row style={{ marginBottom: '10px', marginTop: '5px' }}>
                <Col>
                    {selectedUser?.isManager && <span
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => toggleDisplaySubordinates()}
                    >
                        <FontAwesomeIcon icon={displaySubordinates ? faAngleDoubleUp : faAngleDoubleDown} />
                    </span>
                    }
                    &nbsp;
                    <span style={{ cursor: "pointer" }} onClick={() => {
                        setRowSelection({})
                        setSelectedSubordinate(null)
                    }}>{selectedUser.fullname}</span> {selectedSubordinate ? `/ ${selectedSubordinate.fullname}` : ""}
                </Col>
                <Col>
                    {selectedSubordinate ? selectedSubordinate.userId : selectedUser.userId}
                </Col>
                <Col>

                </Col>
            </Row>
            <Row>
                <Row>

                    {renderSubordinates()}
                </Row>
                <Row>

                    {renderTrainingHistory()}
                </Row>
            </Row>

        </Container>
        <EventDetailsModal personID={selectedSubordinate?.personID || selectedUser?.personID}
            courseCode={selectedEvent?.courseCode} title={selectedEvent?.title} handleClose={() => {
                setShowEventInfoModal(false)
            }} show={showEventInfoModal} />
    </>);
};

export default MyTeamTrainingHistoryUserView;
