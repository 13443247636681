import BaseNav from './BaseNav';
import { Children, cloneElement } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';

export default function BaseContainerLandscape({ title, children, dimensions }) {
    const [contentHeight, setContentHeight] = useState(600)
    const [contentWidth, setContentWidth] = useState(600)
    const { containerHeight, containerWidth, isSmall } = dimensions;

    const renderChildren = (cHeight, cWidth) => {
        return Children.map(children, (child) => {
            return cloneElement(child, {
                dimensions: { ...dimensions, contentHeight: cHeight, contentWidth: cWidth }
            });
        });
    };

    useEffect(() => {
        setContentHeight(containerHeight - 56);
    }, [containerHeight]);

    useEffect(() => {
        setContentWidth(containerWidth - 82);
    }, [containerWidth]);

    // Landscape && not isSmall layout
    return (
        <Row style={isSmall ? {} : { height: `${contentHeight}px`, width: '100%', overflow: 'hidden' }}>
            <BaseNav dimensions={{ ...dimensions, contentHeight: contentHeight, contentWidth: contentWidth }} />
            <Col className='d-flex flex-column pt-3' style={{ height: `${contentHeight}px`, overflow: 'hidden' }}>
                <Row style={{ height: '2.25rem', maxHeight: '2.25rem' }}>
                    <Col className='pb-1'>
                        <h5>{title}</h5>
                    </Col>
                </Row>
                {renderChildren(contentHeight, contentWidth)}
            </Col>
        </Row>
    );
}
