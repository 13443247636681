import React from 'react';
import Box from "@mui/material/Box";
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import {ELearningTabDrawerEnum} from "./ELearningTab";


const ElearningTabDrawer = ({
                                handleChange = () => null
                            }) => {
    return (<Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="E learning tabs">
                <Tab label="Properties" value={ELearningTabDrawerEnum.Properties}/>
                <Tab label="Versions" value={ELearningTabDrawerEnum.Versions}/>
                <Tab label="Upload new version" value={ELearningTabDrawerEnum.UploadNewVersion}/>
                <Tab label="Preview" value={ELearningTabDrawerEnum.Preview}/>
            </TabList>
        </Box>);
};

export default ElearningTabDrawer;
