import useLmsStore, {setLearningItemsState} from "./useLmsStore";
import {useEffect} from "react";
import {apiAxiosCalls} from "../services/apiAxiosCalls";

export default function useGetLearningItemTypes() {
    const {learningItemsState} = useLmsStore((state) => {
        return {
            learningItemsState: state.learningItemsState
        }
    })

    const {learningItemsTypes} = learningItemsState

    const getLearningItemTypes = async () => {
        if (!learningItemsTypes.length) {
            try {
                const {data} = await apiAxiosCalls.getLearningItemTypes()
                setLearningItemsState(useLmsStore, {
                    learningItemsTypes: data.Value.Items
                });

            } catch (e) {
                console.log(e)
            }
        }
    }

    useEffect(() => {
        getLearningItemTypes()
    }, [])
}
