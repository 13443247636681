import {useEffect, useState} from "react";
import {apiAxiosCalls} from "../services/apiAxiosCalls";

export function useGetCourseVersionDetails(id) {
    const [courseVersionDetails, setCourseVersionDetails] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getCourseVersionDetails = async () => {
        try {
            setIsLoading(true)
            const {data} = await apiAxiosCalls.getAllRusticiCourseVersionDetails(id)
            if (!data.IsValid) {

            }
            setCourseVersionDetails(data.Value)
        } catch (e) {
            console.log(e)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (!courseVersionDetails.length && id) {
            getCourseVersionDetails()
        }
    }, [id])

    return {
        courseVersionDetails,
        isLoading
    }
}
