import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import imgGrey266x140 from '../assets/imgs/img-grey-266x140.png';
import imgGrey266x140Blurred from '../assets/imgs/img-grey-266x140-blurred.png';
import useLmsStore, { getSynopsis, responseData, postAddToPlan,createWaitingDatesCat,removeWaitingDatesCat } from '../hooks/useLmsStore';
import userblank from '../assets/imgs/userblank.jpg';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { checkThatImageExists } from '../helpers/imgs';
import { faCircleInfo, faCheck, faPlus,faClock, faCalendarLinesPen, faCalendarXmark,faFileArrowDown} from '@fortawesome/pro-solid-svg-icons';
import { learningItemName } from '../helpers/tableHelper';
import { useEffect, useState } from 'react'
import ModalConfirmation from './ModalConfirmation';
import ModalEventUKPN from './ModalEventUKPN';
import { commonService } from '../services/common/common.service';
export function CourseCard(props) {
    const [image, setImage] = useState(imgGrey266x140);
   
    useEffect(() => {
        if (props.rowData.imageUrl && props.rowData.imageUrl.endsWith('/userblank.jpg')) {
            setImage(userblank);
        } else {
            checkThatImageExists(props.rowData.imageUrl).then((img) => {
                setImage(img ? img.src : imgGrey266x140);
            })
        }
    }, [props.rowData.imageUrl])

   

    return (
        <Card className='cls-card-signature-colour m-2 p-0 shadow' style={{ height: '420px', width: '300px' }}>
            <Card.Header>
                <Row>
                    {/* <Col>customerId: {props.customerId}</Col> */}
                    <Col>{learningItemName(props.rowData.learningItemTypeId)}</Col>
                    <Col sm='auto' className='p-0'>{props.rowData.duration > 0 ? props.rowData.duration + ' hours' : ''}</Col>
                    <Col className='d-flex justify-content-end'>{props.rowData.code}</Col>
                </Row>
            </Card.Header>
            <div className='ratio' style={{ '--bs-aspect-ratio': '60%' }}>
                <Card.Img variant='middle' src={image} />
            </div>
            <Card.Body>
                <Card.Title style={{ fontSize: '1rem' }}>{props.rowData.name}</Card.Title>
                <p style={{ fontSize: '0.85rem' }}>{props.rowData.description.length>120? props.rowData.description.substring(0, 115) +'...':props.rowData.description}</p>
            </Card.Body>
            <Card.Footer className='cls-clear'>
                <div className='d-flex justify-content-between'>
                    <div className='p-0' style={{ maxWidth: '50%' }}>
                        <CourseCardCatalogueName {...props} />
                    </div>
                    <div className='p-0'></div>
                    <div className='p-0'>
                        <CourseCardInfo {...props} />
                    </div>
                </div>
            </Card.Footer>
        </Card>
    );
}

function CourseCardCatalogueName(props) {
    const names = props.rowData.allCatalogueNames;
    const name = props.rowData.catalogueName;
    return (<>
        {names && names.length > 1 ?
            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{names.map((n, idx) => <div key={idx}>{n}</div>)}</Tooltip>}>
                <Badge pill bg='info' style={{ lineHeight: '1.2rem', width: '3rem' }}>...</Badge>
            </OverlayTrigger>
            :
            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{name}</Tooltip>}>
                <Badge pill bg='info' style={{ lineHeight: '1.2rem', maxWidth: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{name}</Badge>
            </OverlayTrigger>
        }
    </>);
}

function CourseCardInfo(props) {
    const [showSynopsis, setShowSynopsis] = useState(false);
    const [addedToPlan, setAddedToPlan] = useState(false);
    const [synopsisTitle, setSynopsisTitle] = useState('');
    const [synopsisHtml, setSynopsisHtml] = useState('');
    const [postingWaitingDates, setPostingWaitingDates] = useState(null);
    const [isOpenRemoveRegisterInterest, setIsOpenRemoveRegisterInterest] = useState(false);
    const [learningItemId, setLearningItemId] = useState(null);
    const [statusId, setStatusId] = useState(props.rowData.statusId);
    const [isOpenEventUKPN, setisOpenEventUKPN] = useState(false);
    const [refLearningItem, setRefLearningItem] = useState(-1);
    const [refLearningItemData, setRefLearningItemData] = useState({});
    const [loading, setLoading] = useState();

    const handleShowSynopsis = (event) => {
        getSynopsis(props.rowData.learningItemId).then((response) => {
            let data = responseData(response, []);
            if (data.length > 0) {
                setSynopsisTitle(data[0].tagTitle || '');
                setSynopsisHtml(data[0].tagHtml || '');
                setShowSynopsis(true);
            }
        });
    };

    const handleWaitingDates = () => {
        setPostingWaitingDates(true);
        setLoading(true);
        createWaitingDatesCat(useLmsStore,{
            learningItemId:props.rowData.learningItemId,
            catalogueId:props.rowData.catalogueId,
            regPwId : props.rowData.pwRegId,
            learnProgId: props.rowData.learnProgId,
            levelOfApproval: props.rowData.levelOfApproval,
            learningItemTypeId: props.rowData.learningItemTypeId
        } ).then((responseResult) => 
        {
            // alert(JSON.stringify(responseResult))
            if (responseResult && 
                responseResult.value && 
                responseResult.value.statusId && 
                responseResult.value.statusId > 0)
            {
                props.onChangeStore(true);
                //setStatusId(responseResult.value.statusId);
            }
            setAddedToPlan(true);
            setPostingWaitingDates(false);            
        })
        .finally(() => setLoading(false));
    }

    const handleRemoveWaitingDates = (learningItemId) => {
        setPostingWaitingDates(true);
        removeWaitingDatesCat(useLmsStore,learningItemId).then(() => {
        setPostingWaitingDates(false);           
        });
    }

    const showRegisterInterestModal =  (learningItemId) => {
       setLearningItemId(learningItemId);
       toggleModalRemoveRegisterInterest();
    }

    function onHandleRemoveRegisterInterest() {
     
        toggleModalRemoveRegisterInterest();  
        handleRemoveWaitingDates(learningItemId);         

    };

   
    function toggleModalRemoveRegisterInterest() {
        setPostingWaitingDates(true);
        setIsOpenRemoveRegisterInterest(!isOpenRemoveRegisterInterest);
        
    }

    const onCloseModalEventUKPN = () => {
        setRefLearningItem(0);
        setisOpenEventUKPN(false);
        if (commonService.NotifyBookCourseEvent)
        {
            setStatusId(1);
        }
    }

    const handleEvents = (rowData) => 
    {
        // alert(JSON.stringify(rowData));
        setRefLearningItem(rowData.learningItemId);
        setRefLearningItemData({'name':rowData.name, 'code': rowData.code});
        setisOpenEventUKPN(true);
    }

    const handleAddToPlan = () => {
        setAddedToPlan(true);
        // *XB-080822* Not sure why all this data must be posted but for now simply post the same data posted by the angular code (previous iteration)
        postAddToPlan(
            useLmsStore,
            {
                CatalogueId: props.rowData.catalogueId,
                IsMandatory: props.rowData.isMandatory,
                IsRenewable: props.rowData.isRenewable,
                ItemGroupId: props.rowData.learinigItemGroupId,
                ItemPersonId: props.rowData.learinigItemPersonId,
                LearningItemId: props.rowData.learningItemId,
                LevelOfApproval: props.rowData.levelOfApproval,
                StatusId: props.rowData.statusId ? props.rowData.statusId : 11
            }
        )
            .then((success) => setAddedToPlan(success));
    };
    const handleOpenSynopsisLink = async (url) => {
        window.open(url, "_blank");
    }
    const handleCloseSynopsis = (event) => {
        //event.preventDefault();
        setShowSynopsis(false);
    };
    return (<>
         {
            loading &&
                <div className="d-flex justify-content-center">
                    <div className="spinner-border mt-5 text-primary" style={{width: '5rem', height: '5rem'}} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
         }
         {props.rowData.synopsisLink !== "" &&  props.rowData.synopsisLink != undefined && props.rowData.learningItemId > 0 ?
            <>
                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Course outline</Tooltip>}>
                    <Button variant='clear' className='pt-0 pb-0'  onClick={() => handleOpenSynopsisLink(props.rowData.synopsisLink)} >
                        <FontAwesomeIcon size='xl' icon={faFileArrowDown} className='text-primary' />
                    </Button>
                </OverlayTrigger>
            </>
             :
             null
         }
        {props.rowData.hasSynopsis === true && props.rowData.learningItemId > 0 ?
            <>
                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>View Synopsis</Tooltip>}>
                    <Button variant='clear' className='pt-0 pb-0' onClick={()=>  handleShowSynopsis()}>
                        <FontAwesomeIcon size='xl' icon={faCircleInfo} className='text-primary' />
                    </Button>
                </OverlayTrigger>
                <Modal fullscreen='md-down' show={showSynopsis} onHide={handleCloseSynopsis} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{props.rowData.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h6>{synopsisTitle}</h6>
                        <p><span dangerouslySetInnerHTML={{ __html: synopsisHtml }} /></p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='primary' onClick={handleCloseSynopsis}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </>
            :
            null
        }
        {/* {'status:' + props.rowData.statusId + ' tp id:' + props.rowData.trainingPlanId} */}
        {
            // 'rowData.levelOfApproval' + props.rowData.levelOfApproval
            // const dontshowcalendarIconForLevel3Level4 = props.rowData.levelOfApproval == 3 || props.rowData.levelOfApproval == 4;
        }
        {props.rowData.trainingPlanId > 0 || props.rowData.regItemId>0 || addedToPlan === true ?
          
          (
            (props.customerId != 5 &&
                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Added to plan</Tooltip>}>
                    <Button variant='clear' className='pt-0 pb-0'
                        // onClick={() => alert(JSON.stringify(props.rowData))}
                    >
                        <FontAwesomeIcon size='xl' icon={faCheck} className='fa-fw' style={{ color: 'var(--app-success)' }} />
                    </Button>
                </OverlayTrigger>
            )
            ||
            (props.customerId == 5 &&
                (props.rowData.statusId == 1 &&
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Added to plan</Tooltip>}>
                        <Button variant='clear' className='pt-0 pb-0'
                            // onClick={() => alert(JSON.stringify(props.rowData))}
                        >
                            <FontAwesomeIcon size='xl' icon={faCheck} className='fa-fw' style={{ color: 'var(--app-success)' }} />
                        </Button>
                    </OverlayTrigger>
                )
                ||
                ((statusId == 15 || props.rowData.statusId==15) &&
                (props.rowData.statusId == 15 &&
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Waiting for Approval</Tooltip>}>
                        <Button variant='clear' className='pt-0 pb-0'
                            // onClick={() => alert(JSON.stringify(props.rowData))}
                        >
                            <FontAwesomeIcon size='xl' icon={faClock} className='fa-fw' style={{ color: 'var(--app-success)' }} />
                        </Button>
                    </OverlayTrigger>
                )
                ||
                (props.rowData.statusId == 8 &&
                    (props.rowData.levelOfApproval != 3 && props.rowData.levelOfApproval != 4) &&
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Request Dates</Tooltip>}>
                        <Button variant='clear' className='pt-0 pb-0'onClick={() => handleEvents(props.rowData) }>
                            <FontAwesomeIcon size='xl' icon={faCalendarLinesPen} className='fa-fw' style={{ color: 'var(--app-success)' }} />
                        </Button>
                    </OverlayTrigger>
                )
                )
            )
        )
            :
            <>
            {props.customerId != 5 ?
            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add To Plan</Tooltip>}>
                <Button variant='clear' className='pt-0 pb-0' onClick={() => handleAddToPlan(props.rowData)}>
                    <FontAwesomeIcon size='xl' icon={faPlus} className='fa-fw' />
                </Button>
            </OverlayTrigger>:            
            <>
            {
            props.customerId == 5 && props.rowData.statusId == null &&
                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add To Plan</Tooltip>}>
                    <Button variant='clear' className='pt-0 pb-0' onClick={() => handleWaitingDates()}>
                        <FontAwesomeIcon size='xl' icon={faPlus} className='fa-fw' />
                    </Button>
                </OverlayTrigger>
            /*             
            { props.rowData.learningItemTypeId==2? 
            <>
            {
              props.customerId == 5 ? 
             props.rowData.waitingDatesId==undefined?            
          
               <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Request Dates</Tooltip>}>
                   <Button variant='clear' className='pt-0 pb-0' onClick={() =>handleWaitingDates()}>
                       <FontAwesomeIcon size='xl' icon={faPlus} className='fa-fw' />
                   </Button>
               </OverlayTrigger>
           :
          null
      
            }
            </>
           :<></>            
           } */}
            
            </>
             }
            </>
        }
        {/* {JSON.stringify(props)} */}
          <ModalConfirmation closeFn={toggleModalRemoveRegisterInterest} yesFn={onHandleRemoveRegisterInterest} noFn={toggleModalRemoveRegisterInterest} open={isOpenRemoveRegisterInterest} title='Confirm remove from request date' description='<p>Are you sure you want to be removed from the request date?</p>' />
          <ModalEventUKPN closeFn={onCloseModalEventUKPN} open={isOpenEventUKPN} refLearningItemId={refLearningItem} refLearningItemData={refLearningItemData} />

    </>);
}

export function CourseCardLoading(props) {
    return (
        <Card className='cls-card-signature-colour m-2 p-0 shadow cls-blurred cls-card-anim' style={{ height: '420px', width: '300px' }}>
            <Card.Header>
                <Row>
                    <Col>Lorem Ipsum</Col>
                    <Col sm='auto' className='p-0'>1 hora</Col>
                    <Col className='d-flex justify-content-end'>Curiosus</Col>
                </Row>
            </Card.Header>
            <div className='ratio' style={{ '--bs-aspect-ratio': '60%' }}>
                <Card.Img variant='middle' src={imgGrey266x140Blurred} />
            </div>
            <Card.Body>
                <Card.Title style={{ fontSize: '1rem' }}>Vultus secretum</Card.Title>
                <p style={{ fontSize: '0.85rem' }}>Responsio ad vitam, universum et omnia sane 42. Hoc a duce obtinuit.</p>
            </Card.Body>
            <Card.Footer className='cls-clear'>
                <Row>
                    <Col>Duc ad Galaxy</Col>
                    <Col sm='auto' className='d-flex justify-content-end'>BTN</Col>
                </Row>
            </Card.Footer>
        </Card>
    );
}