import React, { useEffect } from "react";
import useLmsStore from '../hooks/useLmsStore';
import shallow from 'zustand/shallow';

export default function ProtectedAccess(props) {
    const { personSettings, fetchPersonSettings } = useLmsStore(state =>
    ({
        personSettings: state.personSettings.data,
        fetchPersonSettings: state.fetchPersonSettings
    }), shallow)


    useEffect(() => {
        fetchPersonSettings();
    }, []);

    return (<>
        {
            typeof personSettings !== 'object' ? props.defaultComponent :

                personSettings.isAdministrator === true ?
                    <>
                        {props.adminComponent}
                        {
                            personSettings.isCompetencyApprover === true ?
                                props.competencyApproverComponent : <></>
                        }
                        {
                            personSettings.isCompetencyUser === true ?
                                props.competencyUserComponent : <></>
                        }
                        {
                            personSettings.hasAccessCard === true && personSettings.theme !== 'ukpn' ?
                                props.accessCardComponent : <></>
                        }                        
                        {
                            personSettings.theme === 'ukpn' ?
                                props.ukpnComponent : <></>
                        }
                    </>
                    :
                    personSettings.isSuperApplicationAdministrator === true ?
                        <>
                            {
                                props.appSuperAdminComponent
                            }
                            {
                                personSettings.isManager === true ?
                                    props.managerComponent : <></>
                            }
                            {
                                personSettings.isApplicationAdministrator === true ?
                                    props.appAdminComponent : <></>
                            }
                            {
                                personSettings.isCompetencyApprover === true ?
                                    props.competencyApproverComponent : <></>
                            }
                            {
                                personSettings.isCompetencyUser === true ?
                                    props.competencyUserComponent : <></>
                            }
                            {
                                personSettings.hasAccessCard === true && personSettings.theme !== 'ukpn' ?
                                    props.accessCardComponent : <></>
                            }
                            {
                                personSettings.theme === 'ukpn' ?
                                    props.ukpnComponent : <></>
                            }
                        </>
                        :
                        <>

                            {personSettings.isManager === true ?
                                <>
                                    {
                                        personSettings.isSuperApplicationAdministrator !== true ?
                                            props.managerComponent : <></>
                                    }
                                    {
                                        personSettings.isApplicationAdministrator === true ?
                                            props.appAdminComponent : <></>
                                    }
                                    {
                                        personSettings.isSuperApplicationAdministrator === true ?
                                            props.appSuperAdminComponent : <></>
                                    }
                                    {
                                        personSettings.isCompetencyApprover === true ?
                                            props.competencyApproverComponent : <></>
                                    }
                                    {
                                        personSettings.isCompetencyUser === true ?
                                            props.competencyUserComponent : <></>
                                    }
                                    {
                                        personSettings.hasAccessCard === true && personSettings.theme !== 'ukpn' ?
                                            props.accessCardComponent : <></>
                                    }
                                    {
                                        personSettings.theme === 'ukpn' ?
                                            props.ukpnComponent : <></>
                                    }
                                </>

                                :
                                <>
                                    {
                                        personSettings.isApplicationAdministrator === true ?
                                            <>
                                                {
                                                    props.appAdminComponent
                                                }
                                                {
                                                    personSettings.isCompetencyApprover === true ?
                                                        props.competencyApproverComponent : <></>
                                                }
                                                {
                                                    personSettings.isCompetencyUser === true ?
                                                        props.competencyUserComponent : <></>
                                                }
                                                {
                                                    personSettings.hasAccessCard === true && personSettings.theme !== 'ukpn' ?
                                                        props.accessCardComponent : <></>
                                                }
                                                {
                                                    personSettings.theme === 'ukpn' ?
                                                        props.ukpnComponent : <></>
                                                }
                                            </>
                                            :
                                            <>{

                                                personSettings.isCompetencyApprover === true ?
                                                    <>
                                                        {props.competencyApproverComponent}

                                                        {
                                                            personSettings.isCompetencyUser === true ?
                                                                props.competencyUserComponent : <></>
                                                        }
                                                        {
                                                            personSettings.showReport === true ?
                                                                props.userReportComponent : <></>
                                                        }
                                                        {
                                                            personSettings.hasAccessCard === true && personSettings.theme !== 'ukpn' ?
                                                                props.accessCardComponent : <></>
                                                        }
                                                        {
                                                            personSettings.theme === 'ukpn' ?
                                                                props.ukpnComponent : <></>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            personSettings.isCompetencyUser === true ?
                                                                <>
                                                                    {
                                                                        props.competencyUserComponent
                                                                    }
                                                                    {
                                                                        personSettings.hasAccessCard === true && personSettings.theme !== 'ukpn' ?
                                                                            props.accessCardComponent : <></>
                                                                    }
                                                                    {
                                                                        personSettings.theme === 'ukpn' ?
                                                                            props.ukpnComponent : <></>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        personSettings.showReport === true ?
                                                                            <>
                                                                                {
                                                                                    personSettings.theme !== 'ukpn' ?
                                                                                        props.userReportComponent : <></>
                                                                                }
                                                                                {
                                                                                    personSettings.hasAccessCard === true && personSettings.theme !== 'ukpn' ?
                                                                                        props.accessCardComponent : <></>
                                                                                }
                                                                                {
                                                                                    personSettings.theme === 'ukpn' ?
                                                                                        props.ukpnComponent : <></>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    personSettings.hasAccessCard === true ?
                                                                                        <>
                                                                                            {
                                                                                                personSettings.theme !== 'ukpn' ?
                                                                                                    props.accessCardComponent : <></>
                                                                                            }
                                                                                            {
                                                                                                personSettings.theme === 'ukpn' ?
                                                                                                    props.ukpnComponent : <></>
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                personSettings.theme === 'ukpn' ?
                                                                                                    props.ukpnComponent :
                                                                                                    props.defaultComponent
                                                                                            }

                                                                                        </>
                                                                                }
                                                                            </>

                                                                    }
                                                                </>

                                                        }
                                                    </>
                                            }
                                            </>
                                    }
                                </>
                            }
                        </>

        }
    </>);
};