import '../baseStyle.scss';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect } from 'react';
import useLocationConfig from '../hooks/useLocationConfig'
import { ThemeProvider, createTheme } from '@mui/material';

function injectTheme(json, name) {
    let styleSheet = document.getElementById('app-theme-vars'),
        selectors = Object.keys(json);
    if (!styleSheet) {
        styleSheet = document.createElement('style');
        styleSheet.setAttribute('id', 'app-theme-vars');
        document.head.appendChild(styleSheet);
    }
    styleSheet.innerHTML = '@charset "UTF-8";\n/* Theme: ' + name + ' */\n' + selectors.map((selector) => {
        let definition = json[selector],
            rules = Object.keys(definition),
            result = rules.map((rule) => {
                return `\n\t${rule}: ${definition[rule]}`;
            }).join(';');
        return `${selector} {${result}\n}`;
    }).join(';\n');
}

export default function AppTheme(props) {
    const { theme, themeName } = useLocationConfig();
    const [injectedName, setInjectedName] = useState(null);

    useEffect(() => {
        if (theme && injectedName !== themeName) {
            injectTheme(theme, themeName);
            setInjectedName(themeName);
        }
    }, [theme]);

    const defaultMaterialTheme = createTheme({
        typography: {
            fontFamily: 'var(--bs-body-font-family)',
        },
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontFamily: 'var(--bs-body-font-family)',
                        fontSize: 'var(--bs-body-font-size)'
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        fontFamily: 'var(--bs-body-font-family)',
                        fontSize: 'var(--bs-body-font-size)'
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        border: 'var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)',
                        borderRadius: 'var(--bs-border-radius)',
                        boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
                        color: 'var(--bs-dark)',
                        fontFamily: 'var(--bs-body-font-family)',
                        fontSize: 'var(--bs-body-font-size)',
                        paddingBottom: '0.25rem',
                        paddingTop: '0.25rem'
                    }
                }
            }
        }
    });

    return (<ThemeProvider theme={defaultMaterialTheme}>
        {props.children}
    </ThemeProvider>);
}
