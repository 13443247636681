import { useEffect, useMemo, useState } from "react";
import BaseContainer from "../../components/BaseContainer";

import { Row, Col } from "react-bootstrap";

import { TableRowSplit } from "../../helpers/tableHelper";

import useLmsStore from "../../hooks/useLmsStore";
import shallow from "zustand/shallow";

import { apiAxiosCalls } from "../../services/apiAxiosCalls";
import UpcomingEventsList from "./components/upcomingEventsList";

// reference article: https://reactdatepicker.com/#example-input-time
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as dayjs from "dayjs";
import CustomTableView from "../../components/common/generic-table-view";

function UpcomingEventsCore({ dimensions }) {
  const [rowSelection, setRowSelection] = useState({});
  const [dataSelected, setDataSelected] = useState(null);
  const [tableOption, setTableOption] = useState("users");
  const [tableInactive, setTableInactive] = useState(false);
  const [users, setUsers] = useState([]);
  const [userView, setUserView] = useState();
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [employeeIdSearchString, setEmployeeIdSearchString] = useState(""); // New state for employee ID search
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [showUpcomingList, setShowUpcomingList] = useState(false);
  const [personId, setPersonId] = useState(0);
  const [dateValue, setDateValue] = useState(new Date());

  const [showCalendar, setShowCalendar] = useState(false);
  const [showHighlightCalendar, setShowHighlightCalendar] = useState(true);
  const [searchEventsType, setSearchEventsType] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [leftDivWidth, setLeftDivWidth] = useState();
  const [heading, setHeading] = useState("<b>Please select a category</b>");
  const [employeeID, setEmployeeId] = useState(0);
  const [upcomingEventsDates, setUpcomingEventsDates] = useState([]);
  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");

  const { personSettings, dashboardSummary, fetchDashboardSummary } =
    useLmsStore(
      (state) => ({
        personSettings: state.personSettings.data,
        dashboardSummary: state.dashboardSummary.data,
        fetchDashboardSummary: state.fetchDashboardSummary,
      }),
      shallow
    );

  const ToggleSidebar = () => {
    //toggle ?  setIsOpen(false) : setIsOpen(true);
  };

  const getFirstLevelStaffList = async (managerId) => {
    try {
      if (managerId == null || managerId == undefined) {
        throw new Error("Error getting staff list with invalid manager id");
        return;
      }
      const { data } = await apiAxiosCalls.getFirstLevelStaffList(managerId);
      setUsers([
        ...data.value.map((x) => ({
          personID: x.personID,
          employeeID: x.userId,
          jobTitle: x.jobTitle,
          firstname: x.firstname,
          surname: x.surname,
          fullname: x.fullname,
          emailAddress: x.emailAddress,
          subs: x.subs,
          isManager: x.isManager,
        })),
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false once data fetching is done
    }
  };

  useEffect(() => {
    if (!users.length && personSettings?.ukpnPersonId) {
      setPersonId(personSettings?.ukpnPersonId);
      getFirstLevelStaffList(personSettings?.ukpnPersonId);
    }
  }, [personSettings?.ukpnPersonId]);

  useEffect(() => {
    console.log("up coming events:" + JSON.stringify(dashboardSummary));
    if (dashboardSummary) {
      let upcomingItemsList = dashboardSummary.upcomingItems;
      if (upcomingItemsList) {
        let currentDate = dayjs(new Date()).format(dateFormat);
        let upcomingEvents = upcomingItemsList.filter(
          (x) => dayjs(x.sortingDate).format(dateFormat) > currentDate
        );
        console.log("upcomingEvents:" + JSON.stringify(upcomingEvents));
        if (upcomingEvents) {
          setUpcomingEvents(upcomingEvents);
          let upcomingEventsDates = upcomingEvents.map(
            (x) => new Date(x.sortingDate)
          );
          if (upcomingEventsDates) {
            setUpcomingEventsDates(upcomingEventsDates);
          }
        }
      }
    }
  }, [dashboardSummary]);

  const onRowClickEventHandler = (event, row) => {
    event.preventDefault();
    let id = row.original.personID;
    if (users && id) {
      let user = users.find((x) => x.personID === id);
      if (user) {
        setUserView(user);
        setPersonId(id);
        setDataSelected(user);
        setEmployeeId(user.employeeID);
      }
    } else {
      setPersonId(0);
      setDataSelected(null);
      setEmployeeId(0);
    }
  };

  useEffect(() => {
    if (dataSelected) {
      setShowUpcomingList(true);
    } else {
      setShowUpcomingList(false);
    }
  }, [dataSelected]);

  useEffect(() => {
    let keys = Object.keys(rowSelection);
    if (keys.length === 1 && rowSelection[keys[0]] === true) {
      let id = keys[0];
      if (users && id) {
        let user = users.find((x) => x.personID === id);
        if (user) {
          setUserView(user);
          setPersonId(id);
          setDataSelected(user);
        }
      } else {
        setPersonId(0);
        setDataSelected(null);
      }
    } else {
      setDataSelected(null);
    }
  }, [rowSelection]);

  const onDateRangeChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);

    setEndDate(end);
    if (start != null && end != null) {
      setShowUpcomingList(true);
    }
  };

  const onBackClick = () => {
    setUserView(null);
    setShowUpcomingList(false);
    setRowSelection({});
  };

  const onOptionChange = (event) => {
    let option = event.target.value;
    setShowCalendar(option === "date");
    setSearchEventsType(option === "date" ? 1 : 0);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "employeeID",
        header: "",
        size: 0,
      },
      {
        accessorKey: "personID",
        header: "",
        size: 0,
      },
      {
        accessorKey: "fullname",
        header: "",
        Cell: ({ cell, row }) => {
          return (
            <>
              <span style={{ color: "gray", fontSize: "18px" }}>
                <b>
                  {row.original.firstname || ""} {row.original.surname || ""}
                </b>
              </span>{" "}
              <br />
              <b>Employee Id:</b> {row.original.employeeID || "None"}
              <br />
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <TableRowSplit
      leftPane={
        <>
          <div className="pt-2 pb-2">
            <span id="leftDiv" className="m-2">
              Search By:
            </span>
            <span key={`inline-radio`}>
              <div className="form-check form-check-inline">
                <input
                  name="group1"
                  type="radio"
                  id="inline-radio-users"
                  className="form-check-input"
                  value="users"
                  defaultChecked
                  onChange={onOptionChange}
                />
                <label htmlFor="inline-radio-all" className="form-check-label">
                  Employee
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  name="group1"
                  type="radio"
                  id="inline-radio-date"
                  className="form-check-input"
                  value="date"
                  onChange={onOptionChange}
                />
                <label
                  htmlFor="inline-radio-suppliers"
                  className="form-check-label"
                >
                  Date
                </label>
              </div>
            </span>
          </div>
          <>
            {!showCalendar ? (
              <CustomTableView
                users={users}
                columns={columns}
                columnVisibility={{ personID: false, employeeID: false }}
                rowClickEventHandler={(event, row) =>
                  onRowClickEventHandler(event, row)
                }
                dimensions={dimensions}
              />
            ) : (
              <>
                <Row className="d-flex justify-content-center pt-3">
                  <Col style={{ "text-align": "center" }}>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={new Date()}
                      onChange={onDateRangeChange}
                      isClearable={true}
                      onCalendarClose={() => setShowHighlightCalendar(true)}
                      onCalendarOpen={() => setShowHighlightCalendar(false)}
                    />
                  </Col>
                </Row>
                {showHighlightCalendar && (
                  <Row className="d-flex justify-content-center pt-3">
                    <Col style={{ "text-align": "center" }}>
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={onDateRangeChange}
                        inline
                        highlightDates={upcomingEventsDates}
                        dateFormat={dateFormat}
                        isClearable={true}
                        selected={dateValue}
                        minDate={new Date()}
                        showDisabledMonthNavigation
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </>
        </>
      }
      rightPane={
        showUpcomingList ? (
          <UpcomingEventsList
            startDate={
              startDate ? startDate.toISOString() : new Date().toISOString()
            }
            endDate={
              endDate ? endDate?.toISOString() : new Date().toISOString()
            }
            searchEventsType={searchEventsType}
            showCalendar={showCalendar}
            dateFormat={dateFormat}
            key={userView?.personID || 1}
            onBackClick={onBackClick}
            userView={userView}
            selectedUser={{
              personID: userView?.personID,
              name: userView?.fullname,
              employeePersonID: null,
              managerID: personSettings?.ukpnPersonId,
              employeeID: employeeID,
            }}
            dimensions={dimensions}
          />
        ) :
        !loading && !userView?.personID ? (
          <div className={"shadow  p-3 mt-3 mr-1 rounded"}>
            {" "}
            Please select an employee
          </div>
        ) : (
          <></>
        )
      }
    />
  );
}

export default function UpcomingEvents(props) {
  return (
    <BaseContainer title="Upcoming events">
      <UpcomingEventsCore />
    </BaseContainer>
  );
}
