import AppAvatar from './AppAvatar';
import Dropdown from 'react-bootstrap/Dropdown';
import ModalAbout from './ModalAbout';
import ModalMyProfile from './ModalMyProfile';
import ModalPassword from './ModalPassword';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import shallow from 'zustand/shallow';
import useLmsStore, { postLogout, toastError } from '../hooks/useLmsStore';
import useLocationConfig from '../hooks/useLocationConfig';
import { apiAxiosCalls } from '../services/apiAxiosCalls';
import { useAuth } from '../auth/hooks/useAuth';
import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';

export default function BaseTopBar({ isLandscape, isSmall }) {
    const { instance } = useMsal()
    const [displayChangePassword, setDisplayChangePassword] = useState(false);
    const { logout } = useAuth();
    const { themeName } = useLocationConfig();

    useEffect(
        () => {
            // The change password option should not be displayed unless is is a local account.
            if (instance?.getActiveAccount() || themeName === 'ukpn') {
                setDisplayChangePassword(false);
            } else {
                setDisplayChangePassword(true);
            }
        }, [instance, themeName]);

    const { personBasic, personSummary, fetchPersonBasic, fetchPersonSettings, fetchPersonSummary } = useLmsStore(state =>
    ({
        personBasic: state.personBasic.data,
        personSummary: state.personSummary.data,
        fetchPersonBasic: state.fetchPersonBasic,
        fetchPersonSettings: state.fetchPersonSettings,
        fetchPersonSummary: state.fetchPersonSummary
    }), shallow)

    useEffect(() => {
        fetchPersonBasic();
        fetchPersonSettings();
        fetchPersonSummary();
    }, []);

    useEffect(() => {
        if (process.env.REACT_APP_RNEXT === 'true' && personBasic && personBasic.personIdentifier) {

            apiAxiosCalls.handleAdminLogin(personBasic.personIdentifier)
                .then((response) => console.log('TopBar: Signed on to legacy Lms.'))
                .catch(toastError);
        }
    }, [personBasic]);


    function handleError(e) {
        console.error(e);
    }

    const [isOpenProfile, setisOpenProfile] = useState(false);
    const [isOpenPassword, setisOpenPassword] = useState(false);
    const [isOpenAbout, setisOpenAbout] = useState(false);
    const [image, setImage] = useState({ preview: '', raw: '' });

    function toggleModalProfile() {
        setisOpenProfile(!isOpenProfile);
    }

    function toggleModalPassword() {
        setisOpenPassword(!isOpenPassword);
    }

    function toggleImage(image) {
        setImage({
            preview: image.preview,
            raw: image.row
        });
        fetchPersonSummary(true);
    }
    function toggleModalAbout() {
        setisOpenAbout(!isOpenAbout);
    }

    function handleLogout() {
        postLogout(useLmsStore)
            .then((result) => {
                try {
                    if (result.status === 204) {
                        throw new Error('Invalid email address or password');
                    }
                    // Logout
                    logout();
                } catch (e1) {
                    handleError(e1);
                }
                if (instance?.logout) {
                    instance?.logout();
                }
            });
    };


    return (
        <>
            <Navbar sticky='top' id='id-topbar-wrapper' className='d-flex'>
                {(!isLandscape || isSmall) && themeName ?
                    <div className='p-1 rounded' style={{ background: 'white', marginLeft: '0.75rem' }}>
                        <img
                            src={require(`../assets/instance/${themeName}/icon_small.png`)}
                            style={{ height: '34px' }} />

                    </div> : null}
                {
                    themeName === 'ukpn' ?
                        <div className='me-auto' style={{ marginLeft: '1rem' }} >
                            <img
                                src={require(`../assets/instance/${themeName}/MyLearningLogo.png`)}
                                style={{ height: '34px' }} />
                        </div>
                        :
                        <div className='me-auto fst-italic' style={{ fontSize: '1.25rem', fontWeight: '500', marginLeft: '0.75rem' }}>{themeName ? 'Learning Management System' : ''}</div>
                }
                <Nav.Item className='pt-3'>
                    <h6>
                        {!personBasic ? 'Loading...' : personBasic.fullName}
                    </h6>
                </Nav.Item>
                <Nav.Item>
                    <Dropdown>
                        <Dropdown.Toggle variant='clear-no-arrow' size='2rem' className='p-0-1-0-1' id='topBarAccountDropdown'>
                            {image.preview !== '' ?
                                <AppAvatar
                                    size={46}
                                    color={'var(--app-topbar-avatar-default)'}
                                    name={personSummary && personSummary.fullName}
                                    src={image.preview}
                                />
                                :
                                <AppAvatar
                                    size={46}
                                    color={'var(--app-topbar-avatar-default)'}
                                    name={personSummary && personSummary.fullName}
                                    src={personSummary && personSummary.photograph}
                                />
                            }

                        </Dropdown.Toggle>
                        <Dropdown.Menu align='end'>
                            <Dropdown.Item onClick={toggleModalProfile}>Profile</Dropdown.Item>

                            {displayChangePassword && <Dropdown.Item onClick={toggleModalPassword}>Change Password</Dropdown.Item>}
                            <Dropdown.Item onClick={handleLogout}>Sign Out</Dropdown.Item>
                            <hr />
                            <Dropdown.Item onClick={toggleModalAbout}>About</Dropdown.Item>
                        </Dropdown.Menu>

                    </Dropdown>
                </Nav.Item>
            </Navbar>
            <ModalMyProfile closeFn={toggleModalProfile}
                updateImageFn={toggleImage}
                open={isOpenProfile}
                personSummary={personSummary} />
            <ModalPassword closeFn={toggleModalPassword}
                open={isOpenPassword}
                personSummary={personSummary} />
            <ModalAbout closeFn={toggleModalAbout}
                open={isOpenAbout} />
        </>
    );
}
