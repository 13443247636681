import React from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { useState, useEffect, useMemo, useRef } from "react";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };



// const names = [
//   'Oliver Hansen',
//   'Van Henry',
//   'April Tucker',
//   'Ralph Hubbard',
//   'Omar Alexander',
//   'Carlos Abbott',
//   'Miriam Wagner',
//   'Bradley Wilkerson',
//   'Virginia Andrews',
//   'Kelly Snyder',
// ];

export default function MultiSelectDropdown(props) 
{
  const [personName, setPersonName] = useState([]);

  let names = props.data ? props.data : [];

  useEffect(() => 
  {
    if (personName == null || personName == undefined || personName == '')
    {
      setPersonValue(props.defaultValue);
    }
  
  }, [props.defaultValue])
    
  //console.log('list data:' + JSON.stringify(props.data));
  // useEffect(() => 
  // {
  //   alert('data:' + JSON.stringify(props.data));
  //   //if (props.data)
  //   names = props.data;

  // },[props]);

  const setPersonValue = (value) => 
  {
    let index =  personName.findIndex(x=> x == value);
    if (index == -1)
    {
      //  setPersonName(personName.push(value)); 
      setPersonName(typeof value === 'string' ? value.split(',') : value);
    }
    else
    {
      setPersonName(personName.filter(x=> x != value));
    }
  }  

  const handleChange = (event) => 
  {
    let value = event.target.value;
    //alert(JSON.stringify(value));
    setPersonValue(value);

    let index =  personName.findIndex(x=> x == value);

    props?.onChange(event, index == -1, value, props.cell);

    //setPersonName(typeof value === 'string' ? value.split(',') : value);
    // const {
    //   target: { value },
    // } = event;
    // alert(JSON.stringify(value));
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 120  }} size="small">
        <InputLabel id="demo-multiple-checkbox-label">Select</InputLabel>
        <Select 
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          // multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => 
            { 
              let item = names.filter(x=> x.value == selected)[0];
              //console.log('total Items:' + names.filter(x=> x.value == selected).length + ' selected array length:' + personName.length);
              if (item)
              {
                return item.key; 
              }
            }}
          // MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem key={name?.key} value={name?.value}>
              <Checkbox 
                  checked={personName && personName.length > 0 && personName.indexOf(name?.value) > -1 } 
              />
              <ListItemText primary={name?.key} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

