import { useMemo, useEffect, useState } from 'react';
import BaseContainer from "../components/BaseContainer";
import MaterialReactTable from 'material-react-table';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import useLmsStore, { hasPermission } from '../hooks/useLmsStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlay } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { apiAxiosCalls } from '../services/apiAxiosCalls';

function ReportsCore({ dimensions }) {
    const navigate = useNavigate();
    const [showInstructorLedReports, setShowInstructorLedReports] = useState(false);
    const [showOtherReports, setShowOtherReports] = useState(false);
    const { computedMuiTableContainerProps } = dimensions;

    const { hasPermission } = useLmsStore(state =>
    ({
        hasPermission: state.hasPermission
    }), shallow)

    const { personBasic, personSettings, fetchPersonBasic, fetchPersonSettings } = useLmsStore(state =>
    ({
        personBasic: state.personBasic.data,
        personSettings: state.personSettings.data,
        fetchPersonBasic: state.fetchPersonBasic,
        fetchPersonSettings: state.fetchPersonSettings
    }), shallow);

    useEffect(() => {
        fetchPersonBasic();
        setShowInstructorLedReports(hasPermission('rpt.bookingreports'));
        setShowOtherReports(personSettings.hasPortalReports && personSettings?.theme !== 'ukpn' );

    }, []);

    const eLearnigRep = [
        {
            title: 'All users eLearning progress report',
            description: 'Progress data for all users',
            goTo: '/lms/AllUsersElearningProgress',
        },
        {
            title: 'Awaiting Dates Report',
            description: 'Run this report to view all courses where users have requested additional dates.',
            goTo: '/lms/registrations',
        }];

    const eLearnigManRep = [
        {
            title: 'My Team eLearning progress report',
            description: 'Progress data for my team',
            goTo: '/lms/MyTeamElearningProgress',
        },
        {
            title: 'My Team Awaiting Dates Report',
            description: 'Run this report to view all courses where users have registered interest in attending.',
            goTo: '/lms/teamregistrations',
        }];

    const instLedRep = [
    {
        title: 'All Events & Bookings Report',
        description: 'Run this report to view all events and bookings along with booking status.',
        goTo: '/lms/bookingEvents',

    }];

    const instLedManRep = [
    {
        title: 'My Team Events & Bookings Report',
        description: 'Run this report to view all events and bookings along with booking status.',
        goTo: '/lms/teambookingEvents',

    }
    ];

    const reports = () => {
        if (personSettings.isApplicationAdministrator === true || personSettings.isAdministrator === true || personSettings.isSuperApplicationAdministrator === true) {
            console.log(1);
            if (showInstructorLedReports) {
                if (personSettings.isManager === true) {
                    console.log(2);
                    return eLearnigRep.concat(eLearnigManRep).concat(instLedRep).concat(instLedManRep);
                }
                else {
                    console.log(3);
                    return eLearnigRep.concat(instLedRep);
                }
            } else {

                if (personSettings.isManager === true) {
                    console.log(4);
                    return eLearnigRep.concat(eLearnigManRep);
                }
                else {
                    console.log(5);
                    return eLearnigRep;
                }
            }

        }
        else {
            if (showInstructorLedReports) {
                if (personSettings.isManager === true) {
                    console.log(7);
                    return eLearnigManRep.concat(instLedManRep)
                }
                else {
                    console.log(8);
                    return [];
                }
            } else {

                if (personSettings.isManager === true) {
                    console.log(9);
                    return eLearnigManRep;
                }
                else {
                    console.log(10);
                    return [];
                }
            }
        }

    };
    /*  showInstructorLedReports?
       
          eLearnigRep.concat(eLearnigManRep).concat(instLedRep).concat(instLedManRep)
       :
       eLearnigRep.concat(eLearnigManRep)
       
      : personSettings.isManager === true ?
      showInstructorLedReports?
      eLearnigManRep.concat(instLedManRep)
      :
      eLearnigManRep
       
      :
      [
        
      ] */



    const columns = useMemo(
        () => [
            {
                accessorKey: 'title',
                header: 'Title',
                muiTableBodyCellProps: {
                    classes: { root: 'h5 fw-unset' }   // Flexible width
                }
            },
            {
                accessorKey: 'description',
                header: 'Description',
                muiTableBodyCellProps: {
                    classes: { root: 'fw-unset' }   // Flexible width
                }
            },
            {
                accessorKey: 'alertId',
                header: 'Actions',
                muiTableBodyCellProps: {
                    align: 'center',
                    sx: {
                        textOverflow: 'unset'
                    }
                },
                size: 120,
                Cell: ({ cell, row }) => {
                    return (<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Execute Report 1</Tooltip>}>
                        <Button variant='clear' className='pt-0 pb-0' onClick={() => navigate(row.original.goTo)}>


                            <FontAwesomeIcon size='xl' icon={faCirclePlay} className='text-primary' style={{ height: '35px', width: '35px' }} alt="Execute Report" />
                        </Button>

                    </OverlayTrigger>)
                }
            }
        ],
        [],
    );

    return (
        <>
            <MaterialReactTable
                columns={columns}
                data={reports()}
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableGlobalFilter={true}
                enableGrouping={false}
                enableHiding={false}
                enablePagination={false}
                enableSorting={false}
                enableTopToolbar={true}
                muiTableContainerProps={({ table }) => computedMuiTableContainerProps(table, null, null, 50)}
                muiTableBodyRowProps={{ hover: false }}
                muiTableHeadProps={{
                    sx: {
                        display: 'none'
                    }
                }}
                muiTableProps={{
                    sx: {
                        tableLayout: 'fixed',
                    },
                }}
                initialState={{
                    showGlobalFilter: true
                }}
                state={{
                    showProgressBars: false
                }}

            />
            {
                showOtherReports ?
                    <Button variant='outline-primary' className='mt-2' target='_blank' href={apiAxiosCalls.hrefToAdminServer(personBasic, 'portal.myReports')} ><FontAwesomeIcon icon={faCirclePlay} /><span className='m-2'>Other Reports</span></Button>
                    :
                    <></>
            }
        </>);
}


export default function Reports() {
    return (
        <BaseContainer title='Reports'>
            <ReportsCore />
        </BaseContainer>
    );
}
