import BaseBeginContainer from "../components/BaseBeginContainer";
import BaseContainer from "../components/BaseContainer";
import { BeginButton } from '../components/CustomButtons'
import { faPeopleLine, faNewspaper, faFileChartColumn, faPersonChalkboard } from '@fortawesome/pro-solid-svg-icons';
import { Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';

const MyAdministrationDashboardCore = ({ dimensions }) => {
    const [showReportsGroup2, setShowReportsGroup2] = useState(false);

    useEffect(() => {
        setShowReportsGroup2(window.location.hostname.includes('cerespoweracademy') || window.location.hostname.includes('lms'));
    }, []);

    return (
        <BaseBeginContainer dimensions={dimensions}>
            <BeginButton title='Team Profiles' description='View all users learning plans, learning pathways and review their progress.' icon={faPeopleLine} to='/lms/clientteamprofiles' />
            <BeginButton title='News Items' description='View, create, update or delete news items.' icon={faNewspaper} to='/lms/newsfeeditems' />
            <BeginButton title='Reports' description='Run reports.' icon={faFileChartColumn} to='/lms/reports' />
        </BaseBeginContainer>
    );
};

export default function MyAdministrationDashboard(props) {
    return (<BaseContainer title='My Admin Dashboard' >
        <MyAdministrationDashboardCore />
    </BaseContainer>);
}