import BaseContainer from '../components/BaseContainer';
import OldLearningPlanTableCore from '../components/learningplan/old/OldLearningPlanTableCore';
import shallow from 'zustand/shallow';
import useLmsStore from "../hooks/useLmsStore";
import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";

export default function UserLearningPlanOld(props) {
    const [activeSortedTrainingPlan, setDataActiveSortedTrainingPlan] = useState({ data: null, loading: true });
    const [dataUser, setDataUser] = useState({ data: null, loading: true });
    const [searchParams] = useSearchParams();
    const [userId, setUserId] = useState(0);

    const {
        fetchPersonTrainingPlanSummary,
        fetchActiveSortedTrainingPlanNew,
        fetchPersonSettings,
        personSettings
    } = useLmsStore(state =>
    ({
        personSettings: state.personSettings.data,
        fetchPersonSettings: state.fetchPersonSettings,
        fetchPersonTrainingPlanSummary: state.fetchPersonTrainingPlanSummary,
        fetchActiveSortedTrainingPlanNew: state.fetchActiveSortedTrainingPlanNew

    }), shallow);

    // on load
    useEffect(() => {
        let id = parseInt(searchParams.get("id"));
        setUserId(id > 0 ? id : 0);
    }, [searchParams]);

    useEffect(() => {
        if (userId > 0) {
            setDataActiveSortedTrainingPlan({ data: null, loading: true });
            setDataUser({ data: null, loading: true });
            fetchPersonSettings();

            fetchPersonTrainingPlanSummary(userId).then((d) => {
                // In LMS 2022 the user object is d.value
                // In the old lms it is d
                setDataUser({ data: (d.value?.personId ? d.value : d) || [], loading: false });
            });
            fetchActiveSortedTrainingPlanNew(userId).then((d) => {
                setDataActiveSortedTrainingPlan({ data: d || [], loading: false });
            });
        }
    }, [userId]);

    // ZC - It is not working at the moment, the data is ok but it is not updating the table
    const onResetUserElearningFn = (obj) => {
        fetchActiveSortedTrainingPlanNew(userId).then((d1) => {
            setDataActiveSortedTrainingPlan({ data: d1 || [], loading: false });
        });
    }


    return (<>
        <BaseContainer title='Learning Plan'>
            <OldLearningPlanTableCore
                resetUserElearning={(obj) => onResetUserElearningFn()}
                dataActiveTrainingPlan={activeSortedTrainingPlan}
                dataUser={dataUser}
                displayUserCard={true}
                enablePw={true}
                enableResetElearning={personSettings ? personSettings.isAdministrator === true || personSettings.isSuperApplicationAdministrator === true : false}
            />
        </BaseContainer>
    </>);
}