import useLocationConfig from '../hooks/useLocationConfig'
import { apiAxiosCalls } from "../services/apiAxiosCalls";
import { useEffect, useState } from "react";

export function useGetApplicationLoginPageConfiguration() {
    const [configuration, setConfiguration] = useState()
    const [loading, setLoading] = useState(true)
    const { applicationLogInMethods, key } = useLocationConfig();

    const getConfiguration = async () => {
        setLoading(true)
        if (applicationLogInMethods) {
            console.log('Using applicationLogInMethods settings defined in location-config.json...');
            setConfiguration({ applicationLogInMethods: applicationLogInMethods })
            setLoading(false)
        } else {
            console.log('Retrieving applicationLogInMethods settings from server...');
            try {
                const { data } = await apiAxiosCalls.getApplicationLogInPageConfiguration()
                setConfiguration(data)
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        // Wait for useLocationConfig to be initialised before deciding to use the local or server settings.
        // Here we exploit the fact that key is set when the useLocationConfig object is initialised.
        if (key) {
            getConfiguration();
        }
    }, [key])

    return {
        loginPageConfiguration: configuration,
        loading
    }
}
