import React, {useState} from 'react';
import {apiAxios, toastSuccess} from "../../../hooks/useLmsStore";
import DeleteGroupModal from "../modals/DeleteGroupModal";
import useLocationConfig from '../../../hooks/useLocationConfig'


function GroupManagementGroupSearchResult({
                                              group,
                                              onClick = () => null,
                                              onSingleGroupIconClick = () => null,
                                              isActive = false,
                                              onSuccessDeleteGroup
                                          }) {

    const [showModal, setShowModal] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const { getRpCallUrl } = useLocationConfig();

    const onDeleteClick = async () => {
        try {
            setIsDeleting(true)
            await apiAxios.delete(getRpCallUrl(group.Id))
            setIsDeleting(false)
            onSuccessDeleteGroup(group)
            setShowModal(false)
            toastSuccess("Group deleted successfully")
        } catch (e) {
            setShowModal(false)
            setIsDeleting(false)
        }
    }

    return <div
        key={group.Id}
        onClick={() => {
            onClick(group)
        }}
        className={"row p-3 w-100 mb-1 align-items-center justify-content-center  hover-darken custom-time-animation border-bottom"}
        style={{background: isActive ? "var(--app-bg-filter-active)" : "white"}}
        role={"button"}

    >
        <DeleteGroupModal isDeleting={isDeleting} show={showModal} group={group} toggle={() => {
            setShowModal(!showModal)
        }} onDelete={onDeleteClick}/>
        <div className={"row text-dark"}>
            <div className={"col-12"}>
                {group.Name}
            </div>
{/*
temporary remove the option for deleting groups
<div className={"col-2 "}>
                <FontAwesomeIcon onClick={() => setShowModal(true)} role={"button"} size='xl' icon={faTrash}
                                 className='fa-fw text-primary'/>
            </div>*/}
        </div>

    </div>;
}

export default GroupManagementGroupSearchResult;

