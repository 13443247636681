import { useState } from 'react';

import GroupCard from './GroupCard';
import LearningPlanEditorGroupTable from './LearningPlanEditorGroupTable';
import DataLeftRight from './DataLeftRight';

export default function LearningPlanEditorGroup(props) {

    //Get ggroup memmbers and pass the data
    return (<>
        <GroupCard {...props.dataSelected} defaultMessage={<h5>Please select a Group</h5>} />
        <DataLeftRight>
            <LearningPlanEditorGroupTable {...props} />
        </DataLeftRight>
    </>);
}