import useLmsStore, { setDeliveryMethodState } from "./useLmsStore";
import { useEffect } from "react";
import { apiAxiosCalls } from "../services/apiAxiosCalls";

export default function useGetDeliveryMethods() {
    const { deliveryMethodState } = useLmsStore((state) => {
        return {
            deliveryMethodState: state.deliveryMethodState
        }
    })

    const deliveryMethods = deliveryMethodState.data

    const getDeliveryMethods = async () => {
        if (!deliveryMethods.length) {
            try {
                const { data } = await apiAxiosCalls.getDeliveryMethods();
                setDeliveryMethodState(useLmsStore, {
                    data: data
                });
            } catch (e) {
                console.log(e)
            }
        }
    }

    useEffect(() => {
        getDeliveryMethods()
    }, [])
}
