import { Button, Col, Row } from "react-bootstrap";

export default function HeadingWithBackButton(props) {
    return (
        <>
            <Row>
                <Col md={9}>
                    <div style={{ 'marginTop': '20px', 'marginLeft': '20px' }}>
                        <p
                            className="text-muted"
                            style={props.titleStyle ? props.titleStyle : {}}
                        >
                            {props.Title}
                        </p>
                    </div>
                </Col>
                <Col md={3}>
                    <div className='float-right' >
                        <Button
                            variant='btn btn-outline-primary'
                            style={{ 'marginBottom': '10px', 'marginTop': '10px', marginLeft: '5px', marginRight: '10px', 'width': '100px' }}
                            onClick={(event) => { props.onBackButtonClick(event) }}
                        >
                            Back
                        </Button>
                    </div>
                </Col>
            </Row>
        </>
    )
}