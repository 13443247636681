import { useId } from "react";
import {apiAxiosCalls} from "../apiAxiosCalls";
import {BehaviorSubject, Subject} from 'rxjs';

const  _NotifyBookCourseEvent = new BehaviorSubject(false);

class CommonService
{
    constructor()
    {

    }

    /*
      here below we are creating methods to set user id and return user id as observable.
    */ 
    setNotifyBookCourseEvent = (value) => _NotifyBookCourseEvent.next(value);
    getNotifyBookCourseEvent = () => _NotifyBookCourseEvent.asObservable();

    /*
      here below we are creating properties to set event state and return event state value.
    */ 

    set NotifyBookCourseEvent(value)
    {
        _NotifyBookCourseEvent.next(value);
    }
    get NotifyBookCourseEvent()
    {
        let  rtnValue = false;
        this.getNotifyBookCourseEvent().subscribe(x=> rtnValue = x);
        return rtnValue;
    } 
}

export const commonService = new CommonService();

