import DashboardQualifications from '../components/DashboardQualifications';
import shallow from 'zustand/shallow';
import Spinner from 'react-bootstrap/Spinner';
import useLmsStore from "../hooks/useLmsStore";
import { apiAxiosCalls } from "../services/apiAxiosCalls";
import { NavButton, NavButtonSmallVariant } from './CustomButtons';
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from 'react';

export default function DashboardTeamToDo(props) {
    const [loadingSummary, setLoadingSummary] = useState(true);
    const [loadingOutstanding, setLoadingOutstanding] = useState(true);
    const [outStandingCount, setOutstandingCount] = useState(0);
    const [myQualificationsCount, setMyQualificationsCount] = useState({});
    const [summary, setSummary] = useState({
        totalOverdue: 0,
        totalTodo: 0,
        totalUpcoming: 0,
        totalMandatory: 0
    })

    const { fetchFirstLevelStaffQualifications } = useLmsStore(state =>
    ({
        fetchFirstLevelStaffQualifications: state.fetchFirstLevelStaffQualifications
    }), shallow)

    const getTeamQualificationsCount = async () => fetchFirstLevelStaffQualifications()
        .then(data => {
            setMyQualificationsCount(data);
        });

    useEffect(() => {
        setMyQualificationsCount({});
        getTeamQualificationsCount();
    }, []);

    const getSummary = async (managerId) => {
        setLoadingSummary(true)
        try {
            apiAxiosCalls.GetTeamDashboardSummaryV2(managerId)
                .then((response) => {
                    setSummary(response.data);
                    summary.totalUpcoming = props.upcomingEventsCount;
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                })
                .finally(() => {
                    setLoadingSummary(false); // Set loading to false once data fetching is done
                })

        } catch (e) {
            console.log(e)
        }
        setLoadingSummary(false)
    }

    const getDashboardQualifications = async (managerId) => {
        if (managerId == null || managerId == undefined) {
            throw new Error("Error getting Qualification count with invalid manager id");
            return;
        }
        setLoadingOutstanding(true);
        apiAxiosCalls.GetTeamDashboardQualifications(managerId)
            .then((response) => {
                const actionsCounts = response.data.actionsCounts;
                setOutstandingCount(actionsCounts.competencyRewardedCount + actionsCounts.noShowCount + actionsCounts.requestsAwaitingCount);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            })
            .finally(() => {
                setLoadingOutstanding(false); // Set loading to false once data fetching is done
            })
    };


    useEffect(() => {
        getSummary(props.portalPersonId);
        getDashboardQualifications(props.ukpnPersonId);
    }, [props.upcomingEventsCount]);

    return (
        <>
            <Row className='mt-2'>
                <Col xs={3}>
                    <NavButtonSmallVariant disabled={false} className={"d-block d-sm-none"} variant='danger' title='Outstanding Actions' to={{ pathname: '/lms/outactions' }}>
                        {!loadingOutstanding ? outStandingCount : <Spinner animation="grow" variant="light" size="sm" />}
                    </NavButtonSmallVariant>

                    <NavButton className={"d-none d-sm-block"} variant='danger' title='Outstanding Actions' to={{ pathname: '/lms/outactions', search: 'filter=outstanding' }}>
                        {!loadingOutstanding ? outStandingCount : <Spinner animation="grow" variant="light" size="sm" />}
                    </NavButton>
                </Col>
                <Col xs={3}>
                    <NavButtonSmallVariant className={"d-block d-sm-none"} variant='warning' title='Mandatory' to={{ pathname: '/lms/myTeamTraining/1', search: 'filter=mandatory' }}>
                        {!loadingSummary ? summary?.totalMandatory : <Spinner animation="grow" variant="light" size="sm" />}
                    </NavButtonSmallVariant>

                    <NavButton className={"d-none d-sm-block"} variant='warning' title='Mandatory' to={{ pathname: '/lms/myTeamTraining/1', search: 'filter=mandatory' }}>
                        {!loadingSummary ? summary?.totalMandatory : <Spinner animation="grow" variant="light" size="sm" />}
                    </NavButton>
                </Col>
                <Col xs={3}>
                    <NavButtonSmallVariant className={"d-block d-sm-none"} variant='danger' title='Overdue' to={{ pathname: '/lms/myTeamTraining/2', search: 'filter=overdue' }}>
                        {!loadingSummary ? summary?.totalOverdue : <Spinner animation="grow" variant="light" size="sm" />}
                    </NavButtonSmallVariant>
                    <NavButton className={"d-none d-sm-block"} variant='danger' title='Overdue' to={{ pathname: '/lms/myTeamTraining/2', search: 'filter=overdue' }}>
                        {!loadingSummary ? summary?.totalOverdue : <Spinner animation="grow" variant="light" size="sm" />}
                    </NavButton>
                </Col>
                <Col xs={3}>
                    <NavButtonSmallVariant className={"d-block d-sm-none"} variant='warning' title='Upcoming events' to={{ pathname: '/lms/upcomingEvents', search: 'filter=upcoming-events' }}>
                        {!loadingSummary ? props.upcomingEventsCount : <Spinner animation="grow" variant="light" size="sm" />}
                    </NavButtonSmallVariant>
                    <NavButton className={"d-none d-sm-block"} variant='warning' title='Upcoming events' to={{ pathname: '/lms/upcomingEvents', search: 'filter=upcoming-events' }}>
                        {!loadingSummary ? props.upcomingEventsCount : <Spinner animation="grow" variant="light" size="sm" />}
                    </NavButton>
                </Col>
            </Row>
            <DashboardQualifications
                myQualificationsCount={myQualificationsCount}
                basePath='/lms/myTeamQualifications'
                paddingTop='1.5rem'
                title="My Team Qualifications" />
        </>
    );
}
