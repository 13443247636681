import { getLocationConfigFromDNSKey } from '../../hooks/useLocationConfig'
import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { mergeDeep } from '../../helpers/mergeHelper'

const b2cPublicClientApplication = async () => {
    // Get the configuration from location-config.json
    const currentLocationConfig = getLocationConfigFromDNSKey(window.location.hostname.split(':')[0].replace('.wdr.co.uk', '').replaceAll('/', '')).authB2C;
    let auth;
    // Format the configuration object to be passed to the PublicClientApplication function.
    if (currentLocationConfig) {
        auth = mergeDeep(
            {
                redirectUri: `${window.location.origin}/contractor-login`,
                postLogoutRedirectUri: `${window.location.origin}/`,
                navigateToLoginRequestUrl: false
            },
            currentLocationConfig
        );
        auth.authority = `${currentLocationConfig.authority}/${currentLocationConfig.userFlowSignIn}`;
        auth.prauthority = `${currentLocationConfig.authority}/${currentLocationConfig.userFlowPasswordReset}`;
        // delete auth.scopes;
        delete auth.userFlowPasswordReset;
        delete auth.userFlowSignIn;
    } else {
        auth = {
            clientId: '', // This is the ONLY mandatory field that you need to supply.
            authority: '', // Choose SUSI as your default authority.
            knownAuthorities: [], // Mark your B2C tenant's domain as trusted.
            redirectUri: `${window.location.origin}/contractor-login`, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
            postLogoutRedirectUri: `${window.location.origin}/`, // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: false // If "true", will navigate back to the original request location before processing the auth code response.
        }
    }
    const pca = new PublicClientApplication({
        auth: auth,
        cache: {
            cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                    }
                },
                logLevel: "Info"
            }
        }
    });

    // On success set the active account.
    await pca.handleRedirectPromise()
        .then((tokenResponse) => {
            if (tokenResponse) {
                pca.setActiveAccount(tokenResponse.account)
            }
        })
        .catch((error) => {
            console.error(error);
        });

    return pca;
}

export const b2cPCA = await b2cPublicClientApplication();
