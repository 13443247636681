import { useState, useMemo, useCallback, useReducer, useRef } from 'react';
import { Row, Button, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { TableContainer } from '../helpers/tableHelper';
import MaterialReactTable from 'material-react-table';
import ModalLearningItemsSelect from './ModalLearningItemsSelect';


const LearningItemsSelect = ({addFn = (obj) => null,removeFn = (obj) => null, selectedItems, allItems}) => {
const [showModal, setShowModal] = useState(false);
const [assignedItemsS, setAssignedItemsS] = useState(selectedItems);
const [apply, setApply] = useState(false);
const [itemToRemove, setItemToRemove] = useState(null);
const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false); 
const toggleModalItems = () => {
        setShowModal(!showModal);
    }   

const handleRemove = (row) => {
   
    setItemToRemove(row);
    setShowConfirmRemoveModal(true);
}
    const handleAddItem = () => {
   
        setShowModal(true);
    }
    

    //Add the selected list of new people to assign to the required list to update both database and screen
  const addItemFn = (objs) => {
   
    let assignedItemsCopy = (assignedItemsS.length>0? structuredClone(assignedItemsS):[]); 
    let objsC = objs;
 
    //Add selected people in the new list thst would be used later to save in database
     for (const g of objsC){

        const item = (element) => element.learningItemId === g.learningItemId;

         //Check if exist in the assign group list
        let idx = assignedItemsCopy.findIndex(item);
         if (idx<0){

        assignedItemsCopy.push({
            learningItemId: g.learningItemId,            
            title:g.itemName,
            code:g.code
          });   
        }
         
        setAssignedItemsS([...assignedItemsCopy]);

        //Set if it is dirty
       //updateIsDirty();
   
       toggleModalItems(); 
       addFn(assignedItemsCopy);
    }
}

    const cancelRemove = () => {
        setShowConfirmRemoveModal(false);
    };

    const confirmRemove = () => {
        if (itemToRemove) {
            setApply(true);
            const index =  assignedItemsS.findIndex((item) => item.learningItemId === itemToRemove.learningItemId);
           
            if (index !== -1) {
                 assignedItemsS.splice(index, 1);
                
                setAssignedItemsS([...assignedItemsS]);
                removeFn(assignedItemsS);
            }          
            setShowModal(false);
            setShowConfirmRemoveModal(false);         
        }

        
    };

    const columnsItem = useMemo(
        () => [            
            {
                accessorKey: "title",
                header: "Title",
                size: 220
            },
            {
                accessorKey: "code",
                header: "Code",
                size: 20,          
            },
            {
                accessorKey: 'learningItemId',
                header: 'Actions',
                muiTableBodyCellProps: {
                    align: 'center',
                    sx: {
                        textOverflow: 'unset'
                    }
                },
                size: 20,
                Cell: ({ cell, row }) => {
                    return (<>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Unassign person</Tooltip>}>
                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleRemove(row.original)}>
                                <FontAwesomeIcon size='xl' icon={faTrash} className='text-primary' />
                            </Button>
                        </OverlayTrigger>
               
                    </>)
                }
            } 
        ],
        [],
    );


    return (
        <>
         <h5>Assigned Learning Items</h5>
            <Row>
            <TableContainer>
              <MaterialReactTable
                columns={columnsItem}
                autoResetPageIndex={false}
                data={assignedItemsS}
                enableSorting={true}
                enablePagination={assignedItemsS? (assignedItemsS.length>10?true:false):false}
                initialState={{
                    pagination: { pageIndex: 0, pageSize: 10 },
                    showGlobalFilter: true
                }}            
                muiSearchTextFieldProps={{
                  placeholder: `search assigned`,                 
                }}
                renderToolbarInternalActions={({ table }) => (
                    <>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Assign a new item</Tooltip>}>
                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleAddItem()}>
                                Add
                            </Button>
                        </OverlayTrigger>
                        
                    </>
                )}  
             muiTableHeadProps={{
                            sx: {
                                display: 'none'
                            }
                        }}
              muiToolbarAlertBannerProps={{
                            sx: {
                                display: 'none'
                            }
                        }}

            
            />
            </TableContainer>
            </Row>
            <ModalLearningItemsSelect allLearningItems={allItems} selectedData={assignedItemsS} closeFn={toggleModalItems} open={showModal} submitFn={(obj) =>addItemFn(obj)}></ModalLearningItemsSelect>
            <Modal show={showConfirmRemoveModal} onHide={cancelRemove}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Remove</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to remove this user?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancelRemove}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={confirmRemove}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    );
};

export default LearningItemsSelect;