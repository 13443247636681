import React, {useEffect, useState} from 'react';
import GenericSubTabContainer from "../../common/GenericSubTabContainer";
import useLmsStore, {toastError, toastSuccess} from "../../../hooks/useLmsStore";
import {apiAxiosCalls} from "../../../services/apiAxiosCalls";
import ImageSelector from "./ImageSelector";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Radio,
    RadioGroup,
    Skeleton
} from "@mui/material";
import NoImageProvided from "../../../assets/imgs/no-image.jpg"

const defaultImages = {
    First: "http://images.test.wdr.co.uk/LearningItems/Default/Elearning/elearn1.jpg",
    Second: "http://images.test.wdr.co.uk/LearningItems/Default/Elearning/elearn2.jpg",
    Third: "http://images.test.wdr.co.uk/LearningItems/Default/Elearning/elearn3.jpg",
}

function ImagesTab(props) {
    const [selectedImage, setSelectedImage] = useState(null)
    const [image, setImage] = useState(null)
    const [triggerClear, setTriggerClear] = useState(0)
    const [useDefaultImage, setUseDefaultImage] = useState(false)
    const [defaultImageToUse, setDefaultImageToUse] = useState(null)
    const [isUploading, setIsUploading] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const isSaveDisabled = isUploading || (!defaultImageToUse && !selectedImage)

    const {learningItemsState} = useLmsStore((state) => {
        return {
            learningItemsState: state.learningItemsState
        }
    })

    const {
        activeLearningItemDetail,
    } = learningItemsState;
    const getImage = async () => {
        try {
            const {data} = await apiAxiosCalls.getLearningItemImage(activeLearningItemDetail.Id)
            if (!data.IsValid) {
                throw new Error("Error getting image")
            }
            setImage(data.Value)
        } catch (e) {
            console.log(e)
            setImage("")
        }
    }

    const getImageInitialCall = async () => {
        try {
            setIsLoading(true)
            await getImage()
        } catch (e) {
            console.log(e)
        }
        setIsLoading(false)
    }

    const uploadImage = async () => {
        try {
            setIsUploading(true)
            const {data} = await apiAxiosCalls.uploadCourseImage(activeLearningItemDetail.Id, selectedImage)
            if (!data.IsValid) {
                console.log("Error uploading image")
            }
            const image = data.Value[0]
            await apiAxiosCalls.postUpdateLearningItemImage(activeLearningItemDetail.Id, image.Name)
            await getImage()
            setTriggerClear(prev => prev + 1)
            toastSuccess("Image uploaded successfully")
        } catch (e) {
            toastError({message: "An error has occurred"})
        }
        setIsUploading(false)

    }

    const onCheckChange = (e) => {
        if (!e.target.checked) {
            setDefaultImageToUse(null)
        }
        setUseDefaultImage(e.target.checked)
        setSelectedImage(null)
        setTriggerClear(prev => prev + 1)
    }

    const onRadioChange = (e) => {
        setDefaultImageToUse(e.target.value)
    }

    const onSelectImage = (file) => {
        setSelectedImage(file)
        setUseDefaultImage(false)
        setDefaultImageToUse(null)
    }

    const renderRadioImage = (src) => {
        return <img style={{width: "100px"}} alt={"radio image"} src={src}/>
    }

    const uploadDefaultImage = async () => {
        try {
            setIsUploading(true)
            await apiAxiosCalls.postUpdateLearningItemImage(activeLearningItemDetail.Id, defaultImageToUse)
            await getImage()
            setDefaultImageToUse(null)
            setUseDefaultImage(false)
        } catch (e) {
            setDefaultImageToUse(null)
            setUseDefaultImage(false)
        }
        setIsUploading(false)

    }

    const onSave = async () => {
        if (defaultImageToUse) {
            await uploadDefaultImage()
        } else {
            await uploadImage()
        }
    }

    const renderSkeletonOrContent = () => {
        return isLoading ? <>
        <Skeleton height={250} />
        </> : <>
            <div className={"mb-3"}>
                <img className={"rounded"} alt={"learning item image"} style={{width: "200px"}}
                     src={image || NoImageProvided}/>
            </div>
            <Row>
                <Col md={6}>
                    <ImageSelector
                        onSelect={onSelectImage}
                        onClear={() => {
                            setSelectedImage(null)
                        }}
                        triggerClear={triggerClear}
                    />
                </Col>
                <Col md={6}>
                    {selectedImage &&
                        <img className={"rounded"} alt={"selected learning item image"} style={{width: "200px"}}
                             src={URL.createObjectURL(selectedImage)}/>}
                </Col>
                <div>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox id="use-default-image-checkbox"
                                          onChange={onCheckChange} checked={useDefaultImage}
                                />
                            }
                            label="Use default image"
                        />

                    </FormGroup>


                    {useDefaultImage && <div>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Select default image to use</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                onChange={onRadioChange}
                            >
                                <FormControlLabel
                                    className={"mt-3"}
                                    checked={defaultImageToUse ? defaultImageToUse === defaultImages.First : image === defaultImages.First}
                                    value={defaultImages.First}
                                    control={<Radio/>}
                                    label={renderRadioImage(defaultImages.First)}
                                />
                                <FormControlLabel
                                    className={"mt-3"}
                                    value={defaultImages.Second}
                                    checked={defaultImageToUse ? defaultImageToUse === defaultImages.Second : image === defaultImages.Second}
                                    control={<Radio/>}
                                    label={renderRadioImage(defaultImages.Second)}
                                />
                                <FormControlLabel
                                    className={"mt-3"}
                                    value={defaultImages.Third}
                                    checked={defaultImageToUse ? defaultImageToUse === defaultImages.Third : image === defaultImages.Third}
                                    control={<Radio/>}
                                    label={renderRadioImage(defaultImages.Third)}
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>}
                </div>
            </Row>
        </>
    }


    useEffect(() => {
        getImageInitialCall()

    }, [])

    return (
        <GenericSubTabContainer title={"Learning Item Image"}>
            {renderSkeletonOrContent()}
            <Button disabled={isSaveDisabled} className={"mt-3 d-flex align-items-center justify-content-center"}
                    onClick={onSave}>
                {isUploading ? <Spinner animation={"border"}/> : "Save"}
            </Button>


        </GenericSubTabContainer>
    );
}

export default ImagesTab;
