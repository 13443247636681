import {apiAxiosCalls} from "../../../services/apiAxiosCalls";
import useLmsStore from "../../../hooks/useLmsStore";
import shallow from 'zustand/shallow';



class MandatoryTrainingService
{
    constructor()
    {

    }


    getCourseList = async (categoryId) =>
    {
        const dataContainer = [];
        try
        {
            const {data} = await apiAxiosCalls.GetCourseList(categoryId,true);

            if (!data.isValid)
            {
                throw new Error("Error getting courses");
            }
            else
            {
                dataContainer.push([...data.value.map(x => ({
                    CourseID: x.courseID,
                    DeliveryMethodID: x.deliveryMethodID,
                    CourseCode: x.courseCode,
                    CourseTitle: x.courseTitle,
                    FullRate: x.fullRate,
                    EventCount: x.eventCount,
                    ActualRate: x.actualRate,
                    CurrSym: x.currSym,
                    Duration: x.duration,
                    CompanyID: x.companyID,
                    LearningPgmID: x.learningPgmID,
                    LearningSeqNo: x.learningSeqNo,
                    IsPOA: x.IsPOA
                }))]);

                // alert(JSON.stringify(users));
            }

        }
        catch(error)
        {
            console.log(`Error fetching courses: ${error}`)
        }
        finally
        {
            // setLoading(false);
        }
        return dataContainer;
    }


    getStaffList = async (managerId) => 
    {
        const dataContainer = [];
        try {
            if (managerId == null || managerId == undefined)
            {
                throw new Error("Error getting staff list with invalid manager id");
                return;                
            }
            const { data } = await apiAxiosCalls.getFirstLevelStaffList(managerId);
            //console.log(JSON.stringify(data));
            // if (!data.isValid) {
            //     throw new Error("Error getting staff list");
            // }
            dataContainer.push([...data.value.map(x => ({ 
                                                personID: x.personID, 
                                                PortalPersonID: x.portalPersonID,
                                                employeeID: x.userId,
                                                jobTitle: x.jobTitle,
                                                firstname: x.firstname,
                                                surname: x.surname,
                                                fullname: x.fullname,
                                                emailAddress: x.emailAddress, 
                                                subs: x.subs,
                                                isManager: x.isManager
                                            }))]);
        } 
        catch (error) 
        {
            console.error("Error fetching data:", error);
        } 
        finally 
        {
            // setLoading(false); // Set loading to false once data fetching is done
        }
        return dataContainer;
    };
    
    getCourseList = async (categoryId) =>
    {
        const dataContainer = [];
        try
        {
            const {data} = await apiAxiosCalls.GetCourseList(categoryId,true);

            if (!data.isValid)
            {
                throw new Error("Error getting courses");
            }
            else
            {
                dataContainer.push([...data.value.map(x => ({
                    CourseID: x.courseID,
                    DeliveryMethodID: x.deliveryMethodID,
                    CourseCode: x.courseCode,
                    CourseTitle: x.courseTitle,
                    FullRate: x.fullRate,
                    EventCount: x.eventCount,
                    ActualRate: x.actualRate,
                    CurrSym: x.currSym,
                    Duration: x.duration,
                    CompanyID: x.companyID,
                    LearningPgmID: x.learningPgmID,
                    LearningSeqNo: x.learningSeqNo,
                    IsPOA: x.IsPOA
                }))]);

                // alert(JSON.stringify(users));
            }

        }
        catch(error)
        {
            console.log(`Error fetching courses: ${error}`)
        }
        finally
        {
            // setLoading(false);
        }
        return dataContainer;
    }

    GetMandatoryOrOverdueLearningItems = async (inputParams) =>
    {
        let errorMesage = 'Error getting learning items';

        const dataContainer = [];
        try
        {
            const {data} = await apiAxiosCalls.GetMandatoryOrOverdueLearningItems(inputParams);
            //alert(JSON.stringify(data))
                dataContainer.push([...data.map(x => ({
                    ManagerId: x.managerId,
                    PersonId: x.personId,
                    PrimaryEmail: x.primaryEmail,
                    EmployeeId: x.employeeId,
                    FirstName: x.firstName,
                    LastName: x.lastName,
                    CourseId: x.courseId,
                    CourseCode: x.courseCode,
                    CourseTitle: x.courseTitle,
                    Status: x.status,
                    StatusId: x.statusId,
                    ExpiryDate: x.expiryDate,
                    StartDate: x.startDate,
                    CourseTypeId: x.courseTypeId,
                    CourseType: x.courseType,
                    IsMandatory: x.isMandatory,
                    IsElearning: x.isElearning,
                    MaxAttempts: x.maxAttempts,
                    TotalAttemptGroups: x.totalAttemptGroups,
                    ResultStatusName: x.resultStatusName,
                    CompletionStatusName: x.completionStatusName,
                    SuccessStatusName: x.successStatusName,
                    Score: x.score,
                    DateUpdated: x.dateUpdated,
                    Id:x.id
                }))]);
        }
        catch(error)
        {
            console.log(`${errorMesage}: ${error}`)
        }
        finally
        {
            // setLoading(false);
        }
        return dataContainer;
    }

}

export const mandatoryTrainingService = new MandatoryTrainingService();

