import React from 'react';
import {Modal} from "react-bootstrap";
import {Skeleton} from "@mui/material";

const SynopsisPreviewModal = ({
                                  isModalOpen = false,
                                  closeModal = () => null,
                                  sections = [],
                                  isLoadingSections=false
                              }) => {

    const renderSkeletons = () => {
        return [1,2,3,4,5,6].map(x=>{
            return <Skeleton key={x} height={60} />
        })
    }

    const renderSections = () => {
        if(isLoadingSections){
            return  renderSkeletons()
        }
        return sections.map((section, index) => {
            return <div key={index} className={"mt-4"}>
                <h5>{section.Title}</h5>
                <div dangerouslySetInnerHTML={{__html: section.Content}}></div>
            </div>
        })
    }

    return (
        <Modal show={isModalOpen} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Synopsis</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderSections()}
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    );

};

export default SynopsisPreviewModal;
