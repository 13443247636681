import { Approval } from "@mui/icons-material";
import { apiAxiosCalls } from "../../../services/apiAxiosCalls";
import { BehaviorSubject, Subject } from 'rxjs';

const userId = new BehaviorSubject(0);

class CatalogService {
    constructor() {

    }

    setUserId = (id) => userId.next(id);
    getUserId = () => userId.asObservable();
    getCategoryList = async (managerId) => {
        const dataContainer = [];
        try {
            const { data } = await apiAxiosCalls.GetCategoryList();

            if (!data.isValid) {
                throw new Error("Error getting categories list");
            }
            dataContainer.push([...data.value.map(x => ({
                CourseCategoryID: x.courseCategoryID,
                CourseCategory: x.courseCategory
            }))]);
        }
        catch (error) {
            console.error("Error fetching data:", error);
        }
        return dataContainer;
    };

    getCourseList = async (categoryId) => {
        const dataContainer = [];
        try {
            const { data } = await apiAxiosCalls.GetCourseList(categoryId, true);

            if (!data.isValid) {
                throw new Error("Error getting courses");
            }
            else {
                dataContainer.push([...data.value.map(x => ({
                    CourseID: x.courseID,
                    DeliveryMethodID: x.deliveryMethodID,
                    CourseCode: x.courseCode,
                    CourseTitle: x.courseTitle,
                    FullRate: x.fullRate,
                    EventCount: x.eventCount,
                    ActualRate: x.actualRate,
                    CurrSym: x.currSym,
                    Duration: x.duration,
                    CompanyID: x.companyID,
                    LearningPgmID: x.learningPgmID,
                    LearningSeqNo: x.learningSeqNo,
                    IsPOA: x.IsPOA,
                    SynopsisLink: x.synopsisLink,
                    HasSynopsis: x.hasSynopsis,
                    LearningItemId: x.learningItemId,
                    LearningItemTypeId: x.learningItemTypeId,
                    IsMandatory: x.isMandatory
                }))]);
            }

        }
        catch (error) {
            console.log(`Error fetching courses: ${error}`)
        }
        return dataContainer;
    }


    getStaffList = async (managerId) => {
        const dataContainer = [];
        try {
            const { data } = await apiAxiosCalls.getFirstLevelStaffList(managerId);

            if (!data.isValid) {
                throw new Error("Error getting staff list");
            }
            else {
                const names = [
                    'Oliver Hansen',
                    'Van Henry',
                    'April Tucker',
                    'Ralph Hubbard',
                    'Omar Alexander',
                    'Carlos Abbott',
                    'Miriam Wagner',
                    'Bradley Wilkerson',
                    'Virginia Andrews',
                    'Kelly Snyder',
                ];

                dataContainer.push([...data.value.map(x => ({
                    personID: x.personID,
                    employeeID: x.userId,
                    jobTitle: x.jobTitle,
                    firstname: x.firstname,
                    surname: x.surname,
                    fullname: x.fullname,
                    emailAddress: x.emailAddress,                   
                    ShowSelectDropDown: false,
                    SelectCheckBox: false,
                    businessJustification: [
                        'Oliver Hansen',
                        'Van Henry',
                        'April Tucker',
                        'Ralph Hubbard',
                        'Omar Alexander',
                        'Carlos Abbott',
                        'Miriam Wagner',
                        'Bradley Wilkerson',
                        'Virginia Andrews',
                        'Kelly Snyder',
                    ]

                }))]);

            }

        }
        catch (error) {
            console.log(`Error fetching staff list: ${error}`)
        }
        return dataContainer;
    }

    GetCourseAssignmentContainer = async (deliveryMethodID, managerId, courseId) => {
        const dataContainer = [];
        try {
            const { data } = await apiAxiosCalls.GetCourseAssignmentContainer(deliveryMethodID, managerId, courseId);

            if (!data.isValid) {
                throw new Error("Error getting staff list");
            }
            else {
                dataContainer.push([...data.value.staffList.map((x, index) => ({
                    Index: index,
                    personID: x.personID,
                    employeeID: x.userId,
                    jobTitle: x.jobTitle,
                    firstname: x.firstname,
                    surname: x.surname,
                    fullname: x.fullname,
                    emailAddress: x.emailAddress,
                    statusName:x.statusName,
                    isCompleted:x.isCompleted,
                    BusinessDropDown: false,
                    BusinessDropDownDefaultValue: '',
                    SelectCheckBox: false,
                    IsAuthorisationRequired: (data.value.approvalLevel==4 || data.value.approvalLevel==3),
                    ApprovalLevel:data.value.approvalLevel, // ZC - it may replace the field authorisation required as per new level requirement as it seems to be the same as level 4. I am leaving it for now as I am not sure of it yet.
                    Authenticated: !x.isAuthorisationRequired && data.value.approvalLevel!=4 && data.value.approvalLevel!=3, // if authentication is not required then we will set this fag to true.                    
                    businessJustification: data.value.businessJustifications.map((y, index) => (
                        {
                            key: y.businessJustificationDesc,
                            value: y.businessJustificationId.toString()
                        })),
                    CourseTowns: data.value.courseTowns.map((z, index) => (
                        {
                            key: z.coTown,
                            value: index.toString()
                        }))

                }))]);

            }

        }
        catch (error) {
            console.log(`Error fetching staff list: ${error}`)
        }
        return dataContainer;
    }

    getCourseInfo = async (courseId) => {
        try {
            const { data } = await apiAxiosCalls.GetCourseInfo(courseId);

            if (!data.isValid) {
                throw new Error("Error getting course info");
            }
            else {
                const dataContainer = ({
                    CourseID: data.courseID,
                    DeliveryMethodID: data.deliveryMethodID,
                    CourseCode: data.courseCode,
                    CourseTitle: data.courseTitle,
                    CoursePreRequisites: data.coursePreRequisites,
                    FullRate: data.fullRate,
                    EventCount: data.eventCount,
                    ActualRate: data.actualRate,
                    CurrSym: data.currSym,
                    Duration: data.duration,
                    CompanyID: data.companyID,
                    LearningPgmID: data.learningPgmID,
                    LearningSeqNo: data.learningSeqNo,
                    IsPOA: data.IsPOA,
                    IsAuthorisationRequired: data.isAuthorisationRequired
                });
                return dataContainer;
            }

        }
        catch (error) {
            console.log(`Error fetching course info: ${error}`)
        }
    }

    GetCourseTowns = async (courseID) => {
        const dataContainer = [];
        try {
            const { data } = await apiAxiosCalls.GetCourseTowns(courseID);

            if (!data.isValid) {
                throw new Error("Error getting Course Towns list");
            }
            else {
                dataContainer.push([...data.value.map(x => ({
                    Town: x.coTown
                }))]);
            }

        }
        catch (error) {
            console.log(`Error fetching Course Towns list: ${error}`)
        }
        return dataContainer;
    }

    GetBusinessJustifications = async () => {
        const dataContainer = [];
        try {
            const { data } = await apiAxiosCalls.GetBusinessJustifications();

            if (!data.isValid) {
                throw new Error("Error getting Business Justification list");
            }
            else {
                dataContainer.push([...data.value.map(x => ({
                    key: x.businessJustificationId,
                    value: x.businessJustificationDesc
                }))]);

            }

        }
        catch (error) {
            console.log(`Error fetching Business Justification list: ${error}`)
        }
        return dataContainer;
    }

    SaveBusinessJustificationText = async (inputParams) => {
        const dataContainer = -1;
        try {
            const { data } = await apiAxiosCalls.SaveBusinessJustificationText(inputParams);

            if (!data.isValid) {
                throw new Error("Error saving Business Justification text");
            }
            // else {
            //     dataContainer = data?.value;
            // }
        }
        catch (error) {
            console.log(`Error saving Business Justification text: ${error}`)
        }
        return dataContainer;
    }


    SaveCourseRequest = async (inputParams) =>{
      
        try {
       return  apiAxiosCalls.SaveCourseRequest(inputParams).then((response) => {
                let data = response.data;              
                if (!data.isValid) {
                    throw new Error("Error saving Course Request", data);
                }
                else {
                    return data.value;
                }
             }
            );
        }
        catch (error) {
            console.log(`Error saving Course Request: ${error}`)
        }
        return null;
    }

}

export const catalogService = new CatalogService();

