import React, {useEffect, useState} from "react";
import {apiAxiosCalls} from "../services/apiAxiosCalls";

export function useGetVideoLinkTypes() {
    const [videoLinkTypes, setVideoLinkTypes] = useState([])

    const getData = async () => {
        try {
            const {data} = await apiAxiosCalls.getVideoLinkTypes()
            if (!data.IsValid) {
                throw new Error("An error occured")
            }

            setVideoLinkTypes(data.Value.Items)
        } catch (e) {

        }
    }

    useEffect(() => {
        if (!videoLinkTypes.length) {

            getData()
        }
    }, [])

    return videoLinkTypes
}
