import BaseContainerLandscape from './BaseContainerLandscape';
import BaseContainerPortrait from './BaseContainerPortrait';
import BaseTopBar from "./BaseTopBar";
import shallow from 'zustand/shallow';
import useLmsStore from '../hooks/useLmsStore';
import useResize from '../hooks/useResize';
import { useEffect, useRef } from 'react';

export default function BaseContainer(props) {
    const containerRef = useRef(null);
    const dimensions = useResize(containerRef);
    const { isLandscape, isSmall } = dimensions;

    const { personSettings, fetchPersonSettings } = useLmsStore(state =>
    ({
        personSettings: state.personSettings.data,
        fetchPersonSettings: state.fetchPersonSettings
    }), shallow)

    useEffect(() => {
        fetchPersonSettings();
    }, []);

    useEffect(() => {
        console.log('isLandscape', isLandscape);
    }, [isLandscape]);

    return (
        <div ref={containerRef} style={isSmall ? {height: '100vh', maxHeight: '100vh', overflowX: 'hidden', overflowY: 'auto'} : { height: '100vh', maxHeight: '100vh', overflow: 'hidden' }}>
            {
                personSettings ?
                    <>
                        <BaseTopBar isLandscape={isLandscape} />
                        {isLandscape && !isSmall ? <BaseContainerLandscape {...props} dimensions={dimensions} /> : <BaseContainerPortrait {...props} dimensions={dimensions} />}
                    </>
                    : null
            }
        </div>);
}
