import React from 'react';
import GenericSubTabContainer from "../../common/GenericSubTabContainer";
import {useGetCourseVersionDetails} from "../../../hooks/useGetCourseVersionDetails";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import {Skeleton} from "@mui/material";

const ELearningTabVersions = ({id}) => {
    const {courseVersionDetails, isLoading} = useGetCourseVersionDetails(id)

    const renderSkeletons = () => {
        return [1,2,3].map(x=>{
            return <Skeleton key={x} height={50} />
        })
    }

    const renderModules = (modules) => {
        return modules.map((module, i) => {
            return <div key={module.Id}>
                <div>
                    {module.Title}
                </div>
                <div>
                    (mastery score: {module.MasteryScore})
                </div>
            </div>
        })
    }

    const renderAccordionItems = () => {
        return courseVersionDetails.map((version, index) => {
            return <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header>{version.VersionName} ({moment(version.DateUpdated).format("DD-MM-YYYY")})</Accordion.Header>
                <Accordion.Body>
                    {renderModules(version.Modules)}
                </Accordion.Body>
            </Accordion.Item>
        })
    }

    return (
        <GenericSubTabContainer title={'Versions'}>
            <Accordion>
                {isLoading ? renderSkeletons() : renderAccordionItems()}
            </Accordion>
        </GenericSubTabContainer>
    );
};

export default ELearningTabVersions;
