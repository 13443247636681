import React from 'react';
import BasicSettingsSubTab from "./BasicSettingsSubTab";
import useLmsStore, {setLearningItemsState, toastError, toastSuccess} from "../../../hooks/useLmsStore";
import {Spinner} from "react-bootstrap";
import ElearningSettingsSubTab from "./ElearningSettingsSubTab";
import SaveAndCancel from "../SaveAndCancel";
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import {learningItemTypesIdsEnum} from "../../../helpers/constantsAndEnums";
import InstructorLedCourseSettings from "./InstructorLedCourseSettings";
import {apiAxiosCalls} from "../../../services/apiAxiosCalls";
import AudioSettingsSubTab from "./AudioSettingsSubTab";
import ReferenceMaterialSettingsSubTab from "./ReferenceMaterialSettingsSubTab";
import VideoSettingsSubTab from "./VideoSettingsSubTab";
import {Skeleton} from "@mui/material";

const LearningItemDetailTab = () => {
    const {learningItemsState} = useLmsStore((state) => {
        return {
            learningItemsState: state.learningItemsState
        }
    })

    const {
        activeLearningItemDetail,
        loadingActiveLearningDetail,
        learningItems,
        activeLearningItem
    } = learningItemsState;


    const schema = yup.object().shape({
        Title: yup.string().required("Title is required"),
        ApplicationSupplierId: yup.string().required("Supplier is required"),
        LearningItemTypeId: yup.string().required("Type is required"),
        Url: yup.string().url().nullable().notRequired(),
        HyperLink: yup.string().url().nullable().notRequired(),
        IsRenewable: yup.boolean(),
        Duration: yup.number().when('IsRenewable', {
            is: val => val === true,
            then: () => yup.number().required('Duration is required when the item is renewable.')
                .min(1, 'Duration must be greater than 1'),
            otherwise: () => yup.string().nullable().notRequired()

        }).nullable(),
        DurationUnit: yup.string().when('IsRenewable', {
            is: val => val === true,
            then: () => yup.string().required('Duration unit is required when the item is renewable.'),
            otherwise: () => yup.string().nullable().notRequired()


        }).nullable(),
    });


    const learningItemTypeToSubTabMapper = {
        [learningItemTypesIdsEnum.Course]: (form) => <InstructorLedCourseSettings form={form}/>,
        [learningItemTypesIdsEnum.Audio]: (form) => <AudioSettingsSubTab form={form}/>,
        [learningItemTypesIdsEnum.Video]: (form) => <VideoSettingsSubTab form={form}/>,
        [learningItemTypesIdsEnum.Elearning]: (form) => <ElearningSettingsSubTab form={form}/>,
        [learningItemTypesIdsEnum.ManualIntervention]: () => "",
        [learningItemTypesIdsEnum.ReferenceMaterial]: (form) => <ReferenceMaterialSettingsSubTab form={form}/>,
    }

    const getSubTabBasedOnCourseType = (key) => {
        if (!key) {
            return () => <></>
        }
        try {
            return learningItemTypeToSubTabMapper[key]
        } catch (e) {
            return () => <></>
        }

    }

    const onUpdateLearningItem = async (payload) => {
        const reconstructPayload = !activeLearningItem?.AreWeInCreationMode ? {
            ...activeLearningItemDetail,
            ...payload,
            NumberRequiredApprovals: +payload.NumberRequiredApprovals
        } : {
            ...activeLearningItemDetail,
            ...payload,
            NumberRequiredApprovals: +payload.NumberRequiredApprovals,
            Id: 0,
            ELearningType: 2,
        }

        try {
            const {data} = await apiAxiosCalls.saveLearningItem(reconstructPayload)
            if (!data.IsValid) {
                throw new Error("An error occured")
            }
            const {Value} = data

            setLearningItemsState(useLmsStore, {
                activeLearningItemDetail: Value,
            })
            await postUpdateLearningItem(Value)
        } catch (e) {
            toastError({message: "An error has occurred"})
        }
    }

    const postUpdateLearningItem = async (learningItem) => {
        const found = learningItems.findIndex(li => li.LearningItemId === learningItem.Id)

        const learningItemsCopy = JSON.parse(JSON.stringify(learningItems))
        if (found > -1) {
            learningItemsCopy[found] = {
                ...learningItemsCopy[found],
                Title: learningItem.Title,
                Name: learningItem.Name,
                Code: learningItem.Code,
            }
            setLearningItemsState(useLmsStore, {
                learningItems: learningItemsCopy
            })
            toastSuccess("Learning item updated successfully")

        }

        if (activeLearningItem?.AreWeInCreationMode) {
            const {data} = await apiAxiosCalls.getLearningItem(learningItem.Id)
            setLearningItemsState(useLmsStore, {
                learningItems: [
                    {
                        ...data.Value
                    },
                    ...learningItems
                ],
                activeLearningItem: {
                    ...data.Value
                }
            })
            toastSuccess("Learning item created successfully")
        }
    }

    return (
        <Formik
            validationSchema={schema}
            onSubmit={async (values) => {
                await onUpdateLearningItem(values)
            }}
            initialValues={{
                Id: activeLearningItemDetail?.Id || 0,
                Title: activeLearningItemDetail?.Title || "",
                Code: activeLearningItemDetail?.Code || "",
                LearningItemTypeId: activeLearningItemDetail?.LearningItemTypeId || "",
                ApplicationSupplierId: activeLearningItemDetail?.ApplicationSupplierId || "",
                DeliveryMethodId: activeLearningItemDetail?.DeliveryMethodId || "-1",
                Description: activeLearningItemDetail?.Description || "",
                InvitationText: activeLearningItemDetail?.InvitationText || "",
                IsActive: !!activeLearningItemDetail?.IsActive,
                CanBeShared: !!activeLearningItemDetail?.CanBeShared,
                NumberRequiredApprovals: !!activeLearningItemDetail?.NumberRequiredApprovals,
                IsMandatory: !!activeLearningItemDetail?.IsMandatory,
                IsRenewable: !!activeLearningItemDetail?.IsRenewable,
                Url: activeLearningItemDetail?.Url,
                IsExternallyHosted: !!activeLearningItemDetail?.Url,
                MaxDelegates: activeLearningItemDetail?.MaxDelegates,
                MinDelegates: activeLearningItemDetail?.MinDelegates,
                AutoPlay: !!activeLearningItemDetail?.AutoPlay,
                AutoHide: !!activeLearningItemDetail?.AutoHide,
                Loop: !!activeLearningItemDetail?.Loop,
                ShowPlayBackButton: !!activeLearningItemDetail?.ShowPlayBackButton,
                ShowPlayButton: !!activeLearningItemDetail?.ShowPlayButton,
                ShowScrubBar: !!activeLearningItemDetail?.ShowScrubBar,
                ShowTime: !!activeLearningItemDetail?.ShowTime,
                ShowTimeRemaining: !!activeLearningItemDetail?.ShowTimeRemaining,
                ShowVolumeControl: !!activeLearningItemDetail?.ShowVolumeControl,
                HyperLink: activeLearningItemDetail?.HyperLink,
                Poster: activeLearningItemDetail?.Poster,
                LinkType: activeLearningItemDetail?.LinkType,
                Duration: activeLearningItemDetail?.Duration,
                DurationUnit: activeLearningItemDetail?.DurationUnit,
            }}
            validateOnBlur={true}
            enableReinitialize
        >
            {(form) => {
                return (
                    <Form
                        noValidate
                        onSubmit={form.handleSubmit}

                    >
                        <div
                            style={{maxHeight: "600px"}}
                            className={"d-flex flex-column overflow-auto"}
                        >
                            {(loadingActiveLearningDetail || !activeLearningItemDetail) ?
                                <>
                                    <Skeleton variant="rectangular" height={118}/>
                                    <Skeleton
                                        sx={{
                                        marginTop: "1rem"
                                     }}
                                              variant="rectangular"
                                              height={118}
                                    />
                                </>
                                :
                                <>
                                    <BasicSettingsSubTab form={form}/>
                                    {getSubTabBasedOnCourseType(form.values.LearningItemTypeId)(form)}

                                </>
                            }

                        </div>
                        <SaveAndCancel form={form}/>
                    </Form>
                )
            }}
        </Formik>
    );
};

export default LearningItemDetailTab;
