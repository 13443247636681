import DashboardCarouselCard from './DashboardCarouselCard';
import shallow from 'zustand/shallow';
import useLmsStore from "../hooks/useLmsStore";
import { Card, Carousel, Container, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useEffect } from 'react';

export default function DashboardCarousel(props, dimensions) {
    const { containerHeight } = dimensions;

    const { allTopPersonCatalogueItems, fetchAllTopPersonCatalogueItems } = useLmsStore(state =>
    ({
        allTopPersonCatalogueItems: state.allTopPersonCatalogueItems.data,
        fetchAllTopPersonCatalogueItems: state.fetchAllTopPersonCatalogueItems
    }), shallow)
    useEffect(() => {
        fetchAllTopPersonCatalogueItems();
    }, []);
    return (
        !props.personSettings?.ukpnPersonId ? 
        <Col className='pt-3'>
            <Container>
                <Card className='cls-carousel shadow'>
                    <Card.Body>
                        <Card.Title>Courses</Card.Title>
                        <div className={allTopPersonCatalogueItems?.length > 0 ? 'border rounded shadow' : ''} style={{ height: `${containerHeight - 374}px`, maxHeight: '892px', minHeight: '376px', margin: '0 1rem 1rem 1rem' }}>
                            {allTopPersonCatalogueItems && allTopPersonCatalogueItems.length > 0 ?
                                <Carousel variant='dark'>
                                    {allTopPersonCatalogueItems.map((rowData, idx) => (
                                        <Carousel.Item
                                            interval={5500}
                                            key={idx}
                                        >
                                            <NavLink style={{ color: "var(--bs-body-color)", textDecoration: 'none' }} to={"/lms/courselibrary?search=" + rowData.code}>
                                                <DashboardCarouselCard key={idx} rowData={rowData} />
                                            </NavLink>
                                        </Carousel.Item>
                                    ))}
                                </Carousel> :
                                allTopPersonCatalogueItems?.length === 0 ? <i>None available</i> : null
                            }
                        </div>
                    </Card.Body>
                </Card>
            </Container>
        </Col>
        : null
    );
}
