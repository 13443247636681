import React, {useState} from 'react';
import {faFileAudio, faLaptop, faList, faSearch, faTag, faUser, faVideo} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Badge, Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import useLmsStore, {setLearningItemsState} from "../../hooks/useLmsStore";
import {learningItemsTabEnum, learningItemTypesIdsEnum} from "../../helpers/constantsAndEnums";
import {
    faChalkboardUser,
    faPersonChalkboard,
    faPhotoFilm,
    faTriangleExclamation
} from "@fortawesome/pro-solid-svg-icons";
import SynopsisMagnification from "./SynopsisMagnification";

const learningItemsDeliveryMethodsEnum = {
    WDReLearning: "WDR eLearning",
    Instructor: "Instructor",
    Undefined: "Undefined",
}
const LearningItemSingleSearchResult = ({learningItem}) => {
    const {learningItemsState} = useLmsStore((state) => {
        return {
            learningItemsState: state.learningItemsState
        }
    })
    const {activeLearningItem} = learningItemsState

    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
    const [isRegisterInterestModalOpen, setIsRegisterInterestModalOpen] = useState(false)


    const isActive = activeLearningItem?.Id === learningItem.id

    const deliveryMethodIconMapper = {
        [learningItemTypesIdsEnum.Elearning]: <FontAwesomeIcon icon={faChalkboardUser}/>,
        [learningItemTypesIdsEnum.Course]: <FontAwesomeIcon icon={faPersonChalkboard}/>,
        [learningItemTypesIdsEnum.Video]: <FontAwesomeIcon icon={faVideo}/>,
        [learningItemTypesIdsEnum.Audio]: <FontAwesomeIcon icon={faFileAudio}/>,
        [learningItemTypesIdsEnum.ReferenceMaterial]: <FontAwesomeIcon icon={faPhotoFilm}/>,
        [learningItemTypesIdsEnum.Undefined]: <FontAwesomeIcon icon={faList}/>,
    }

    const renderDeliveryMethodIcon = () => {
        return deliveryMethodIconMapper[learningItem.typeId]
    }

    const onItemClick = () => {
        setLearningItemsState(useLmsStore, {
            activeLearningItem: learningItem,
            activeTab: learningItemsTabEnum.Detail
        })
    }

    const renderButtons = () => {
        return <>
{/*
            Hide this reinstate later
           {learningItem.SynopsisTemplate && <Button size={"sm"} onClick={() => {
                setIsPreviewModalOpen(true)
            }} className={"w-100 d-flex justify-content-center align-items-center"}>
                <FontAwesomeIcon size={"sm"} icon={faSearch}/>
            </Button>}*/}

            {/* Hide this for now maybe reinstate later
           {(learningItem.TypeId === learningItemTypesIdsEnum.Course && !learningItem.HasWaitingDate) &&
                <Button size={"sm"} onClick={() => {
                    setIsRegisterInterestModalOpen(true)
                }} className={"w-100 d-flex justify-content-center align-items-center mt-1"}>
                    <FontAwesomeIcon size={"sm"} icon={faTag}/>
                </Button>}*/}

        </>
    }

    return (
        <div
            role={"button"}
            onClick={() => {
                onItemClick()
            }}
            className={"row p-3 w-100 mb-1 align-items-center justify-content-center  hover-darken custom-time-animation border-bottom"}
            style={{
                background: isActive ? "var(--app-bg-filter-active)" : "white",
            }}
        >
            <div className={"col-2 d-flex flex-column align-items-center justify-content-center"}>
                <div className={""}>{renderDeliveryMethodIcon()}</div>
                <div className={"text-small"}> {learningItem.code}</div>

            </div>
            <div className={"col-8"}>
                <div>{learningItem.title}</div>
                {!learningItem.isActive && <div className={"text-medium"}>
                    <OverlayTrigger
                        placement='top'
                        delay={{show: 250, hide: 400}}
                        overlay={
                            <Tooltip className='cls-theme-tooltip'>This learning item is inactive</Tooltip>
                        }
                    >
                        <FontAwesomeIcon
                            style={{fontSize: "1rem"}}
                            icon={faTriangleExclamation}
                        />
                    </OverlayTrigger>
                </div>
                }

{/*
              remove temporarily
              {learningItem.HasWaitingDate && <div className={"text-medium"}>
                    <Badge bg="primary">
                        Waiting dates
                    </Badge>
                </div>}*/}


            </div>

            <div className={"col-2"}>
                {renderButtons()}
            </div>
            {isPreviewModalOpen && <SynopsisMagnification
                isPreviewModalOpen={isPreviewModalOpen}
                setIsPreviewModalOpen={setIsPreviewModalOpen}
            />}

            {/*           Hide this modal for now maybe reinstate it later
{isRegisterInterestModalOpen && <ConfirmInterestModal
                isModalOpen={isRegisterInterestModalOpen}
                learningItem={learningItem}
                closeModal={() => {
                    setIsRegisterInterestModalOpen(false)
                }}/>
            }*/}

        </div>
    );
};

export default LearningItemSingleSearchResult;
