import { useEffect, useMemo, useState } from 'react';
import { Badge, Container, Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router';
import { TableContainer } from '../../../helpers/tableHelper'
import {learningItemName,learningItemIcon } from '../../../helpers/tableHelper';

import { apiAxiosCalls } from "../../../services/apiAxiosCalls";
import CustomTableView from '../../../components/common/generic-table-view';
import { FilterClearButton, FilterBadgeButton } from '../../../components/CustomButtons';

import { userService } from '../../../services/common/user.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay} from '@fortawesome/pro-solid-svg-icons';
import { isSupportedDate, /*toLocaleDateString,*/toDDsMMsYYString, toLocaleDateString } from '../../../helpers/formatHelper';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

import useLmsStore, { responseData, getTeamMandatoryTrainingPlan } from "../../../hooks/useLmsStore";
import { mandatoryTrainingService } from '../services/mandatory-training.service';
import TrainingReport from './mandatory-report';
import LinkContainer from '../../../../_libs/react-router-bootstrap/LinkContainer';
import dayjs from 'dayjs';

const filteredData = (rows, id, isUKPN) => !Array.isArray(rows) ? [] : id < 0 ? rows.filter(x => (id === -1 && filterTodo(x) && !isPathwayItem(x)) || (id === -2 && isOverdue(x, isUKPN) && !isPathway(x)) || (id === -3 && x.isMandatory  && !isPathway(x)) || (id===-4 && filterTodo(x) && x.expiryDate!==undefined && x.expiryDate!==null) || (id===-5 && x.statusName==='Awaiting Dates' && !isPathway(x)) || (id === -6 && filterComplete(x) )) : rows.filter(x => filterTodo(x) && !isPathwayItem(x));
const filteredDataS = (rows, id) => !Array.isArray(rows) ? [] : id < 0 ? rows.filter(x => (id === -1 && filterTodo(x) ) || (id === -2 && isOverdue(x) && !isPathway(x)) || (id === -3 && x.isMandatory  && !isPathway(x)) || (id===-4 && filterTodo(x) && x.expiryDate!==undefined && x.expiryDate!==null) || (id===-5 && x.statusName==='Awaiting Dates' && !isPathway(x)) || (id === -6 && filterComplete(x))) :rows.filter(x => filterTodo(x) && !isPathwayItem(x));

const filterComplete = x => x && (isCompleted(x) || isAttended(x))  && !isPathway(x);
const filterTodo = x => x && !isCompleted(x) && !isAttended(x);
//const filteredData = (rows, id, search) => !Array.isArray(rows) ? [] : id < 0 ? rows.filter(x => (id === -1 && !isCompleted(x) && !isAttended(x)  &&  search==null) || (id === -2 && isOverdue(x) && !isPathway(x)) || (id === -3 && x.isMandatory  && !isPathway(x)) || (id === -4 && (isCompleted(x) || isAttended(x))  && !isPathway(x))) : rows;

const isPathway = x => x &&  (x.regPathwayId != null && x.learnProgId==null);
const isPathwayItem = x => x &&   x.learnProgId!=null;

const isCompleted = x => x && (x.StatusName === 'Completed' || x.StatusName==='Completed - Colleague' || (x.elearningInfo && x.elearningInfo.completionStatusName === 'Complete' && x.elearningInfo.sucessStatusName !== 'Failed'));

const isConfirmed = x => x && (x.StatusName === 'Confirmed' || (x.instructorInfo && x.instructorInfo.statusName === 'Confirmed'));
const isAttended = x => x && (x.StatusName === 'Attended' || (x.instructorInfo && x.instructorInfo.statusName === 'Attended'));

const canPreviewElearning = (rowData) => rowData && isCompleted(rowData);

const isOverdue = x => x && !isCompleted(x) && (isSupportedDate(x.ExpiryDate) && (new Date(x.ExpiryDate) < new Date() ||  new Date(x.MandatoryExpectedDate) < new Date()))  ;

const isPassed = x => x && x.elearningInfo && x.elearningInfo.SucessStatusName === 'Passed';

const filterByCourseId = (rows,courseId) => rows.filter(x=> x.CourseId == courseId);
const filterByPersonId = (rows, personId) => rows.filter(x=> x.PersonId == personId);

export default function MandatoryTrainingList(props) 
{
    const [loading, setLoading] = useState(false);
    const [learningItems, setLearningItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [mandatoryCount, setMandatoryCount] = useState();
    const [overdueCount, setOverdueCount] = useState();
    const [filterId, setFilterId] = useState(props.pageType);
    const [showReport, setShowReport] = useState(false);
    const { navigate } = useNavigate();
    const [isDev, setIsDev] = useState(!process.env.NODE_ENV || process.env.NODE_ENV == 'development');

    let userId = userService.UserId; 
    //console.log('props.pageType:' + props.pageType );
    const getFilteredLearningItems = (personId, courseId) => 
        {
            let items =  structuredClone(learningItems);
            if (isDev)
            {
                //console.log('items:' + JSON.stringify(items));
            }
            items = items.filter(x=> x.Status != 'Deleted');

            let mandatoryItems = items.filter(x=> x.IsMandatory == true
                && x.Status != 'Attended' 
                && x.Status != 'Completed'
                && x.Status != 'Completed - All'
                && x.Status != 'Completed - Colleague'
            );
            setMandatoryCount(mandatoryItems?.length || 0);

            let overdueItems = items.filter(x=> (dayjs(x.ExpiryDate).isBefore(dayjs()) ||  dayjs(x.StartDate).isBefore(dayjs()) )
                    && x.Status != 'Attended' 
                    && x.Status != 'Completed'
                    && x.Status != 'Completed - All'
                    && x.Status != 'Completed - Colleague'
                    && x.courseTypeId==3
                    );
            setOverdueCount(overdueItems?.length || 0);

            items = (filterId == 1) ? mandatoryItems : overdueItems;
            if (isDev)
            {
                //console.log('items:' + JSON.stringify(items));
                console.log('course id:' + courseId + '  person id:' + personId);
            }
                
            if (courseId && courseId > 0)
            {
                items = filterByCourseId(items, courseId);
            }
            if (personId && personId > 0)
            {
                items = filterByPersonId(items, personId);
            }
            setFilteredItems(items);                    

            /**  get count for mandatory and overdue */


            // let mandatoryItems = learningItems.filter(x=> x.isMandatory == true && x.Status != 'Deleted');
            // setMandatoryCount(mandatoryItems?.length || 0);

            // let overdueItems = learningItems.filter(x=> dayjs(x.ExpiryDate).isBefore(dayjs())
            //                                             && x.Status != 'Attended' 
            //                                             && x.Status != 'Completed'
            //                                             && x.Status != 'Completed - All'
            //                                             && x.Status != 'Completed - Colleague'
            //                                             && x.Status != 'Deleted'
            //                                        );
            // setOverdueCount(overdueItems?.length || 0);
            // if (isDev)            
            //     console.log('total:' + learningItems.length + ' mandatoryCount: ' + mandatoryCount + ' overdueCount:' + overdueCount);
        }

    const getLearningEvents = (managerId, personId, courseId) =>
    {
        try
        {
                let inputParams = 
                {
                    managerId : managerId,
                    pageType: filterId,
                    // staffId : personId == 0 ? -1 : personId,
                    // courseId : courseId == null ? -1 : courseId 
                }
                //alert(JSON.stringify(inputParams));
                //console.log('learningItems.count:' + learningItems.length);
                    setLoading(true);
                    mandatoryTrainingService.GetMandatoryOrOverdueLearningItems(inputParams)
                    .then(result=> 
                        {
                            setLearningItems(result[0]);
                            //getFilteredLearningItems(personId, courseId);         
                        })
                    .finally(() => 
                        { 
                            setLoading(false) 
                        });
        
        }
        catch(error)
        {
            console.log(`Error fetching data: ${error}`)
        }
        // finally
        // {
        //     setLoading(false);
        // }

    }

    useEffect(() => 
    {
            //setLearningItems([]);
            if (learningItems && learningItems.length > 0) 
                {
                    getFilteredLearningItems(props.personId, props.courseId);
                }
            else
            {
                getLearningEvents(props.managerId, props.personId, props.courseId);
            }    
            
    }, 
    [
            props.managerId, 
            props.selectedViewType,  // course or employee
            props.personId, 
            props.courseId, 
            props.pageType,   // mandatory or overdue
            filterId,
            learningItems
    ]);


    const columns = useMemo(
        () => 
        [
                {
                    accessorKey: 'FirstName', 
                    header: '',
                    size: 0,
                },
                {
                    accessorKey: 'LastName', 
                    header: '',
                    size: 0,
                },
                {
                    accessorKey: 'EmployeeId', 
                    header: '',
                    size: 0,
                },
                {
                    accessorKey: 'CourseCode', 
                    id: 'CourseCode',  
                    header: '',
                    size: 0,
                },
                {
                    accessorKey: 'CourseId', 
                    id: 'CourseId',  
                    header: '',
                    size: 0,
                },
                {
                    accessorKey: 'CourseTypeId',
                   id: 'CourseTypeId',
                   header:'',
                   size: 0,              
                   Cell: ({ cell, row }) => (<OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{(row.original.IsMandatory ? 'Mandatory ' : '') + learningItemName(row.original.CourseTypeId)}</Tooltip>} >
                           {learningItemIcon(row.original.CourseTypeId,row.original.IsMandatory)}
                      </OverlayTrigger>
                     )
               },

                {
                    accessorKey: 'CourseTitle', 
                    header: 'Employee/Title',
                    size: 600,
                    Cell: ({ cell, row }) => {
                        return (<>
                             <div><b>{row.original?.FirstName + ' ' + row.original?.LastName  } - {row.original?.EmployeeId}</b></div>
                             <div>{row.original?.CourseCode } - {row.original?.CourseTitle}</div>
                             <Row className='gx-1' >
                                {isCompleted(row.original) && row.original.CourseTypeId !== 2 ?
                                    <Col xs='auto'>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Completed</Tooltip>}>
                                            <FontAwesomeIcon size='xl' icon={faCircleCheck} className='text-success' />
                                        </OverlayTrigger>
                                    </Col> : <></>}

                                    {row.original.IsElearning ?
                        row.original.TotalAttemptGroups > 0 ?
                            <>
                                <Col xs='auto'>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Number of times that a new attempt has been made at this eLearning course.</Tooltip>}>
                                        <Badge bg='secondary'>
                                            Attempt {row.original.TotalAttemptGroups}
                                        </Badge>
                                    </OverlayTrigger>
                                </Col>
                                {row.original.maxAttempts > 0 ?
                                    <Col xs='auto'>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>The max number of attempts for this course is.</Tooltip>}>
                                            <Badge bg='secondary'>
                                                Max Attempts: {row.original.MaxAttempts}
                                                {row.original.TotalAttemptGroups >= row.original.MaxAttempts ? <span> done</span> : null}
                                            </Badge>
                                        </OverlayTrigger>
                                    </Col> : null}

                                <Col xs='auto'>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Completion Status - {row.original.CompletionStatusName} ({toDDsMMsYYString(row.original.DateUpdated)})</Tooltip>}>
                                        <Badge bg={isCompleted(row.original) ? 'success' : 'danger'}>CS</Badge>
                                    </OverlayTrigger>
                                </Col>
                                {isCompleted(row.original) ?
                                    isPassed(row.original) ?
                                    <>
                                        <Col xs='auto'>
                                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Satisfaction Status - {row.original.SuccessStatusName} ({toDDsMMsYYString(row.original.DateUpdated)})</Tooltip>}>
                                                <Badge bg='success'>
                                                    SS
                                                </Badge>
                                            </OverlayTrigger>
                                        </Col> 
                                               </>
                                        : <></>
                                    :
                                    <Col xs='auto'>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Satisfaction Status - {row.original.SuccessStatusName}  ({toDDsMMsYYString(row.original.DateUpdated)})</Tooltip>}>
                                            <Badge bg={isPassed(row.original) ? 'success' : 'warning'}>
                                                SS
                                            </Badge>
                                        </OverlayTrigger>
                                    </Col>
                                }
                            </> : null : null}

                             </Row>

                        </>)                   
                        }
                  },
                  {
                    // id: 'Status',
                    accessorKey: 'Status',
                    header: 'Status',
                    size: 100,
            
                  },            
                  {
                    id: 'ExpiryDate',
                    accessorKey: 'ExpiryDate',
                    header: 'Due Date',
                    size: 100,
                    Cell: ({cell, row}) => 
                        {
                            const expiryDate = dayjs(row.original.ExpiryDate);
                            const startDate = dayjs(row.original.StartDate);
                            const isPastDate = expiryDate.isBefore(dayjs());
                            const isPastStartDate = startDate.isBefore(dayjs());
                            return (
                            <>
                                  {
                                    row.original.ExpiryDate ? 
                                    <span style={{color: isPastDate ? 'red' : 'black'}}> 
                                        { expiryDate.format('DD/MM/YYYY') }
                                    </span>
                                        : 
                                        row.original.StartDate ? 
                                        <span style={{color: isPastStartDate ? 'red' : 'black'}}> 
                                            { startDate.format('DD/MM/YYYY') }
                                        </span>:
                                        ''                                    
                                  }
                            </>
                    )}
                  }          
        ],
        [],
    );

    const onBackButtonClick = (event) => 
    {
        event.preventDefault();
        setShowReport(false);
        // setLoading(true);
        // setTimeout(() => 
        // {
        //     setDislayCourseTrainingEmployees(false);
        //     setLoading(false);
        // }, 100);
    }

    
    return (
            <>
            <Container fluid style={{display: !showReport ? 'block': 'none'}}>

                <Row style={{marginBottom:'10px', marginTop:'5px'}}>
                    <Col style={{'minWidth':'550px'}}>
                            {
                                props.heading &&
                                <div>
                                    {<h6 className='text-muted border border-secondary border-rounded p-2' dangerouslySetInnerHTML={{ __html: props.heading }} />} 
                                </div>
                            }
                            {
                                //props.pageType == 1 &&
                                <div style={{'paddingTop':'20px'}}>
                                    <FilterBadgeButton 
                                            title='Overdue' 
                                            variant={overdueCount > 0 ? 'danger' : 'danger'}
                                            // onClick={() => setFilterId(filterId === 2 ? -1 : 2)} 
                                            onClick = {() => { setFilterId(2); props.setPersonId(-1); props.setCourseId(-1);} }
                                            active={filterId == 2}
                                    >
                                        {overdueCount || 0}
                                    </FilterBadgeButton>&nbsp;
                                    <FilterBadgeButton 
                                            title='Mandatory' 
                                            variant={mandatoryCount > 0 ? 'warning' : 'warning'}
                                            // onClick={() => setFilterId(filterId === 1 ? -1 : 1)} 
                                            onClick = {() => { setFilterId(1); props.setPersonId(-1); props.setCourseId(-1);} }
                                            active={filterId == 1}
                                    >
                                        {mandatoryCount || 0}
                                    </FilterBadgeButton>

                                </div>
                            }                           
                    </Col>
                    <Col>
                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Execute Report</Tooltip>}>
                                    <Button variant='clear' className='pt-0 pb-0' onClick={() => setShowReport(true) }>
                                        <FontAwesomeIcon size='xl' icon={faCirclePlay} className='text-danger'  style={{ height: '35px', width: '35px' }} alt="Execute Report"  />
                                    </Button>
                                </OverlayTrigger>                                    

                            {/* <LinkContainer to={{ pathname: '/lms/trainingreport', search: `id=${cell.getValue()}` }}>
                                            <Button variant='clear' className='pt-0 pb-0'>
                                                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Execute Report</Tooltip>}>
                                                    <Button variant='clear' className='pt-0 pb-0' onClick={() => setShowReport(true) }>
                                                        <FontAwesomeIcon size='xl' icon={faCirclePlay} className='text-danger'  style={{ height: '35px', width: '35px' }} alt="Execute Report"  />
                                                    </Button>
                                                </OverlayTrigger>                                    
                                            </Button>
                            </LinkContainer> */}


                    </Col>
                    <Col></Col>
                </Row>
            </Container>
            {
                <Container fluid style={{display: !showReport ? 'block': 'none'}}>
                    {/* { JSON.stringify(upcomingEvents) } */}
                    <Row className="d-flex justify-content-center">
                        <Col >                 
                            {
                                <CustomTableView
                                    users = { filteredItems || [] }
                                    columns = {columns}
                                    loading = {loading}
                                    columnVisibility = {{ FirstName : false, LastName : false, EmployeeId : false, CourseId: false, CourseCode: false }}
                                    // rowClickEventHandler = {(event, row) => onRowClickEventHandler(event, row) }
                                    rowClickEventHandler = { ()=> {}}
                                >
                                </CustomTableView>
                            }
                        </Col>
                    </Row>
                </Container>
                        
            }
            <Container style={{display: showReport ? 'block': 'none'}}>
                    <div>
                        <TrainingReport
                            heading  = { filterId == 1 ? 'My Team Mandatory Report' : 'My Team Overdue Report'}
                            trainingDetails = { filteredItems }
                            onBackButtonClick = {onBackButtonClick}
                        ></TrainingReport>
                    </div>
            </Container>
            </>
    );
}
