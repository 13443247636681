import BaseContainer from '../components/BaseContainer';
import OldLearningPlanTableCore from '../components/learningplan/old/OldLearningPlanTableCore';
import shallow from 'zustand/shallow';
import useLmsStore from "../hooks/useLmsStore";
import { useEffect,useState } from 'react';

export default function MyLearningPlanOld(props) 
{
    const [changeState, setChangeState] = useState(0);
    const {
        personSettings, 
        myActiveSortedTrainingPlanNew,
        fetchMyActiveSortedTrainingPlanNew
        
    } = useLmsStore(state =>
    ({
        personSettings: state.personSettings?.data,
        myActiveSortedTrainingPlanNew: state.myActiveSortedTrainingPlanNew,
        fetchMyActiveSortedTrainingPlanNew: state.fetchMyActiveSortedTrainingPlanNew
        
    }), shallow);

 
    useEffect(() => {
        fetchMyActiveSortedTrainingPlanNew();
    }, []);

    return (<>
        <BaseContainer title='My Learning Plan'>
            <OldLearningPlanTableCore
                dataActiveTrainingPlan={myActiveSortedTrainingPlanNew}
                enableActions={true}
                enableResetElearning={false}
                theme = {personSettings?.theme}
                onChangeState = {(value)=> setChangeState(value)}
            />
        </BaseContainer>
    </>);
}
