import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import useLmsStore, { getSynopsis,responseData} from "../hooks/useLmsStore";
import { useEffect, useMemo, useState } from 'react';
const ModalSynopsis = ({ closeFn = () => null, open = false,learningItemId=null, title = ""}) => {
const [synopsisSections, setSynopsisSections] = useState([]);
    useEffect(() => {

        getSynopsis(learningItemId).then((response) => {
            if (learningItemId){           
            let data = responseData(response, []);
            setSynopsisSections(data); 
            }
        });
    }, [learningItemId]);

    const synopsisSectionsContent=synopsisSections.map((item,id)=>{
        return <div key={id}>
          <h5>{item.tagTitle}</h5>
          <p><span dangerouslySetInnerHTML={{ __html:  item.tagHtml }} /></p>
        </div>
    })

    return (
        <Modal fullscreen='md-down' show={open} onHide={closeFn} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                 {synopsisSectionsContent}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={closeFn}>Close</Button>
            </Modal.Footer>
        </Modal>

    );
};

export default ModalSynopsis;

