import { useId } from "react";
import {apiAxiosCalls} from "../apiAxiosCalls";
import {BehaviorSubject, Subject} from 'rxjs';

const  _userId = new BehaviorSubject(0);
const _isAlertsRead = new BehaviorSubject(false);

class UserService
{
    constructor()
    {

    }

    set IsAlertsRead(value) 
    {
      _isAlertsRead.next(value);
    } 

    getIsAlertsRead = () => _isAlertsRead.asObservable();  

    get IsAlertsRead()
    {
      let rtnValue = false;
      _isAlertsRead.subscribe(x=> rtnValue = x);
      return rtnValue;
    }

    /*
      here below we are creating methods to set user id and return user id as observable.
    */ 
    setUserId = (id) => _userId.next(id);
    getUserId = () => _userId.asObservable();

    /*
      here below we are creating properties to set user id and return user id value.
    */ 

    set UserId(value)
    {
        _userId.next(value);
    }
    get UserId()
    {
        let  rtnValue = 0;
        this.getUserId().subscribe(x=> rtnValue = x);
        return rtnValue;
    } 
}

export const userService = new UserService();

