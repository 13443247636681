import * as Yup from 'yup';
import React, { useState } from 'react';
import useLocationConfig from "../../hooks/useLocationConfig";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { ErrorMessage, Field, Form as FormikForm, Formik } from "formik";
import { apiAxios } from "../../helpers/axios";
import { useMsal } from "@azure/msal-react";

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .trim()
        .required('Email is required'),
    contractorId: Yup.string()
        .trim()
        .required('Contractor ID is required'),
});


const RegisterContractor = () => {
    const [response, setResponse] = useState(null)
    const { themeName } = useLocationConfig();
    const { instance } = useMsal();

    return (
        <Row className="m-0 ">
            <Col className="cls-login-col-left p-0" md={6} lg={5} xl={4} xxl={3}>
                <div className="cls-login-colour-ovl d-flex align-items-center justify-content-center">
                    <Row style={{ width: '100%' }}>
                        <Col sm={2} md={1}></Col>
                        <Col>
                            <Card className="mb-5" style={{ width: '100%' }}>
                                <div className="d-flex justify-content-end" style={{ margin: ' 1rem 1.5rem 0 0' }}>
                                    {themeName ? <Card.Img variant="top"
                                        src={require(`../../assets/instance/${themeName}/logo.png`)}
                                        style={{ width: '140px' }} /> : <></>}
                                </div>
                                <Card.Body>
                                    <Card.Title className='m-2'>Learning Management System</Card.Title>
                                    <Card.Title className='m-2'>New Account Setup</Card.Title>
                                    <Formik
                                        initialValues={{ email: '', contractorId: '' }}
                                        validationSchema={validationSchema}
                                        onSubmit={(values, actions) => {
                                            if (response) {
                                                if (response.status === 1) {
                                                    instance.loginRedirect();
                                                } else {
                                                    actions.resetForm();
                                                    setResponse(null);
                                                    actions.setSubmitting(false);
                                                }
                                            } else {
                                                apiAxios.post("/api/ukpn/ukpnContractorsSetupLogin", values).then(d => {
                                                    try {
                                                        if (d.data) {
                                                            setResponse(d.data);
                                                        } else {
                                                            console.log(d);
                                                            setResponse({ status: 2, message: 'Something went wrong.' });
                                                        }
                                                    } catch (e) {
                                                        console.log(e);
                                                        setResponse({ status: 2, message: 'Something went wrong.' });
                                                    } finally {
                                                        actions.setSubmitting(false);
                                                    }
                                                });
                                            }
                                        }}
                                    >
                                        {({ isSubmitting, handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
                                            <FormikForm onSubmit={handleSubmit} >
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Email address</Form.Label>
                                                    <Field name="email">
                                                        {({ field }) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="email"
                                                                placeholder="Enter email"
                                                                isInvalid={touched.email && errors.email}
                                                                disabled={isSubmitting || response}
                                                            />
                                                        )}
                                                    </Field>
                                                    <ErrorMessage name="email" component={Form.Control.Feedback} type="invalid" />
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Contractor ID</Form.Label>
                                                    <Field name="contractorId">
                                                        {({ field }) => (
                                                            <Form.Control
                                                                {...field}
                                                                placeholder="Enter Contractor ID"
                                                                isInvalid={touched.contractorId && errors.contractorId}
                                                                disabled={isSubmitting || response}
                                                            />
                                                        )}
                                                    </Field>
                                                    <ErrorMessage name="contractorId" component={Form.Control.Feedback} type="invalid" />
                                                </Form.Group>
                                                <Button className='w-100 mt-3 mb-3' type="submit" disabled={isSubmitting}>
                                                    {response?.status === 1 ? 'Login' : response?.status === 2 ? 'Retry' : 'Register'}
                                                </Button>
                                            </FormikForm>
                                        )}
                                    </Formik>
                                    {response && <Alert variant={response?.status === 1 ? 'success' : response?.status === 2 ? 'warning' : 'danger'}>
                                        {response?.message}
                                    </Alert>}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={2} md={1}></Col>
                    </Row>
                </div>
            </Col>
            <Col className="cls-login-col-right">
                <div className="cls-login-colour-ovl"></div>
            </Col>
        </Row>
    );
};

export default RegisterContractor;
