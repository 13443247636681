import BaseContainer from "../components/BaseContainer";
import MaterialReactTable from "material-react-table";
import shallow from 'zustand/shallow';
import useLmsStore from '../hooks/useLmsStore';
import { Col, Row, Spinner } from 'react-bootstrap';
import { FilterClearButton, FilterBadgeButton } from '../components/CustomButtons';
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

const getDateColorFromStatus = (dateStatus) => dateStatus === 1 ? '#E68B15' : dateStatus === 2 ? 'red' : 'green';

const TeamQualificationsCore = ({ dimensions }) => {
  const [tableData, setTableData] = useState([]);
  const [filterDateStatusId, setFilterDateStatusId] = useState(0);
  const [filterOperational, setFilterOperational] = useState(false);
  const [filterUnderPersonalSupervision, setFilterUnderPersonalSupervision] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { teamData, loading, fetchFirstLevelStaffQualifications } = useLmsStore(state =>
  ({
    teamData: state.firstLevelStaffCompetencies.data,
    loading: state.firstLevelStaffCompetencies?.loading > 0 || !state.firstLevelStaffCompetencies.data,
    fetchFirstLevelStaffQualifications: state.fetchFirstLevelStaffQualifications
  }), shallow)

  useEffect(() => {
    fetchFirstLevelStaffQualifications();
  }, []);

  useEffect(() => {
    if (searchParams.has('filter')) {
      switch (searchParams.get("filter")) {
        case "operational":
          setFilterOperational(true);
          break;
        case "expiring":
          setFilterDateStatusId(1);
          break;
        case "expired":
          setFilterDateStatusId(2);
          break;
        case "supervised":
          setFilterUnderPersonalSupervision(true);
          break;
        default:
          break;
      }
      searchParams.delete('filter');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    if (filterUnderPersonalSupervision) {
      setFilterDateStatusId(0);
    }
  },
    [filterUnderPersonalSupervision])

  useEffect(() => {
    if (filterDateStatusId > 0) {
      setFilterUnderPersonalSupervision(false);
    }
    if (filterDateStatusId > 1) {
      setFilterOperational(false);
    }
  },
    [filterDateStatusId])

  useEffect(() => {
    if (!loading && teamData?.rows) {
      setTableData(teamData.rows.filter(
        x =>
          (filterDateStatusId === 0 || x.dateStatus === filterDateStatusId) &&
          ((filterOperational && x.isOperational && x.dateStatus < 2) || !filterOperational) &&
          ((filterUnderPersonalSupervision && x.isUnderPersonalSupervision) || !filterUnderPersonalSupervision)
      ));
    }
  },
    [loading, teamData, filterDateStatusId, filterOperational, filterUnderPersonalSupervision])

  const columns = useMemo(
    () => [
      {
        header: "Person Name",
        accessorKey: "name"
      },
      {
        header: "Group",
        accessorKey: "qualificationGroup"
      },
      {
        header: "Name",
        accessorKey: "qualificationName"
      },
      {
        header: "Proficiency",
        accessorKey: "proficiency",
        enableColumnFilter: false
      },
      {
        header: "Valid From",
        accessorKey: "validFrom",
        enableColumnFilter: false
      },
      {
        header: "Valid To",
        accessorKey: "validTo",
        enableColumnFilter: false,
        Cell: ({ cell, row }) => <span className='fw-bold' style={{ color: getDateColorFromStatus(row.original.dateStatus) }}>{cell.getValue()}</span>
      }
    ], []);

  return (
    <MaterialReactTable
      columns={columns}
      data={tableData}
      enableBottomToolbar={false}
      enableColumnActions={false}
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      enableGrouping={false}
      enableHiding={false}
      enablePagination={false}
      muiTableContainerProps={({ table }) => dimensions.computedMuiTableContainerProps(table)}
      enableSorting={false}
      enableFilters={true}
      globalFilterFn="contains"
      renderTopToolbarCustomActions={({ table }) => {
        return (
          <Row className='gy-2'>
            <Col xs='auto'>
              <FilterBadgeButton variant='warning' title='Operational' onClick={() => setFilterOperational(!filterOperational)} active={filterOperational}>
                {loading ? <Spinner animation="grow" variant="light" size="sm" /> : teamData.operational}
              </FilterBadgeButton>
            </Col>
            <Col xs='auto'>
              <FilterBadgeButton variant='warning' title='Expiring' onClick={() => setFilterDateStatusId(filterDateStatusId === 1 ? 0 : 1)} active={filterDateStatusId === 1}>
                {loading ? <Spinner animation="grow" variant="light" size="sm" /> : teamData.expiring}
              </FilterBadgeButton>
            </Col>
            <Col xs='auto'>
              <FilterBadgeButton variant='danger' title='Expired' onClick={() => setFilterDateStatusId(filterDateStatusId === 2 ? 0 : 2)} active={filterDateStatusId === 2}>
                {loading ? <Spinner animation="grow" variant="light" size="sm" /> : teamData.expired}
              </FilterBadgeButton>
            </Col>
            <Col xs='auto'>
              <FilterBadgeButton variant='info' title='Under Personal Supervision' onClick={() => setFilterUnderPersonalSupervision(!filterUnderPersonalSupervision)} active={filterUnderPersonalSupervision}>
                {loading ? <Spinner animation="grow" variant="light" size="sm" /> : teamData.underPersonalSupervision}
              </FilterBadgeButton>
            </Col>
            <Col xs='auto'>
              {(filterDateStatusId > 0 || filterOperational || filterUnderPersonalSupervision) && <FilterClearButton onClick={() => {
                setFilterDateStatusId(0);
                setFilterOperational(false);
                setFilterUnderPersonalSupervision(false);
              }} />}
            </Col>
          </Row>)
      }}
      initialState={{
        showGlobalFilter: true
      }}
      state={
        {
          isLoading: loading
        }
      }
    />
  );
};

export default function TeamQualifications() {
  return (
    <BaseContainer title="Team Qualifications">
      <TeamQualificationsCore />
    </BaseContainer>
  );
}
