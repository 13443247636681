import { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "../../hooks/data";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useLocalStorage("token", null);

    const getDecodedToken = () => {
        if (token) {
            try {
                return JSON.parse(decodeURIComponent(window.atob(token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')).split('').map(function (c) { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2); }).join('')));
            } catch (e) {
                console.error(e);
                return undefined;
            }
        } else {
            return undefined;
        }
    };

    const isSAML = () => token ? getDecodedToken().isSAML : false;

    const login = (tokenData) => {
        setToken(tokenData);
    };

    const logout = () => {
        setToken(null);
    };

    const value = useMemo(
        () => ({
            token,
            login,
            logout,
            getDecodedToken,
            isSAML
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [token]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
