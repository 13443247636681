import shallow from 'zustand/shallow';
import useLmsStore from "../hooks/useLmsStore";
import { Col, Spinner } from "react-bootstrap";
import { NavButton, NavButtonSmallVariant } from './CustomButtons';
import { isSupportedDate, toLocaleDateString } from '../helpers/formatHelper';
import { useEffect, useState } from 'react';

const filteredData = (rows, id, isUKPN) => !Array.isArray(rows) ? [] : id > 0 ? rows.filter(x => (id === 1 && filterTodo(x) && !isPathway(x)) || (id === 2 && isOverdue(x, isUKPN) && !isPathway(x)) || (id === 3 && (isCompleted(x) || isAttended(x)) && !isPathway(x))) : rows.filter(x => filterTodo(x) && !isPathway(x));

const isFutureDate = x => (isSupportedDate(x) && (new Date(x) >= new Date()));
const isPastDate = x => (isSupportedDate(x) && (new Date(x) < new Date()));
const isBooking = x => x && x.instructorInfo && x.instructorInfo.bookingId > 0 && isConfirmed(x);
const isPastBooking = x => x && isBooking(x) && isPastDate(x.instructorInfo.startDate);

const isConfirmed = x => x && (x.statusName === 'Confirmed' || (x.instructorInfo && x.instructorInfo.statusName === 'Confirmed'));
const isCancelled = x => x && (x.statusName === 'Cancelled booking');
const isOverdue = (x, isUKPN) => x && ((isUKPN && x.learningItemTypeId == 3) || !isUKPN) && !isCompleted(x) && ((isPastDate(x.expiryDate)) || isPastDate(x.mandatoryExpectedDate) || isPastBooking(x))
const isCompleted = x => x && (x.statusName === 'Completed' || x.statusName === 'Completed - Colleague' || (x.elearningInfo && x.elearningInfo.completionStatusName === 'Complete' && x.elearningInfo.successStatusName !== 'Failed'));
const isAttended = x => x && (x.statusName === 'Attended' || (x.instructorInfo && x.instructorInfo.statusName === 'Attended'));
//const isOverdue = x => x && !isCompleted(x) && !isAttended(x) && (isSupportedDate(x.expiryDate) && (new Date(x.expiryDate) < new Date() ||  new Date(x.mandatoryExpectedDate) < new Date()))  ;

const filterTodo = x => x && !isCompleted(x) && !isAttended(x) && !isCancelled(x) && x.learningItemTypeId != 8;
const isPathway = x => x && (x.regPathwayId != null && x.learnProgId == null);
export default function DashboardToDo({ isUKPN }) {
    const { myActiveSortedTrainingPlan, fetchMyActiveSortedTrainingPlanNew } = useLmsStore(state =>
    ({
        myActiveSortedTrainingPlan: state.myActiveSortedTrainingPlanNew.data,
        fetchMyActiveSortedTrainingPlanNew: state.fetchMyActiveSortedTrainingPlanNew
    }), shallow)
    const [overdueCount, setOverdueCount] = useState(0);
    const [completeCount, setCompleteCount] = useState(0);
    const [todoCount, setTodoCount] = useState(0);

    useEffect(() => {
        fetchMyActiveSortedTrainingPlanNew();
        if (myActiveSortedTrainingPlan) {
            if (!myActiveSortedTrainingPlan.loading > 0) {
                setTodoCount(filteredData(myActiveSortedTrainingPlan, 1, isUKPN).length);
                setOverdueCount(filteredData(myActiveSortedTrainingPlan, 2, isUKPN).length);
                setCompleteCount(filteredData(myActiveSortedTrainingPlan, 3, isUKPN).length);
            }
        }
    }, []);

    return (
        <>
            <Col xs={4}>
                <NavButtonSmallVariant className={"d-block d-sm-none"} variant='warning' title='To Do' to={{ pathname: '/lms/mylearningplan', search: 'filter=myplan' }}>
                    {myActiveSortedTrainingPlan ? todoCount === 0 ? filteredData(myActiveSortedTrainingPlan, 1, isUKPN).length : todoCount : <Spinner animation="grow" variant="light" size="sm" />}
                </NavButtonSmallVariant>

                <NavButton className={"d-none d-sm-block"} variant='warning' title='To Do' to={{ pathname: '/lms/mylearningplan', search: 'filter=myplan' }}>
                    {myActiveSortedTrainingPlan ? todoCount === 0 ? filteredData(myActiveSortedTrainingPlan, 1, isUKPN).length : todoCount : <Spinner animation="grow" variant="light" size="sm" />}
                </NavButton>
            </Col>
            <Col xs={4}>
                <NavButtonSmallVariant className={"d-block d-sm-none"} variant='danger' title='Overdue' to={{ pathname: '/lms/mylearningplan', search: 'filter=overdue' }}>
                    {myActiveSortedTrainingPlan ? overdueCount === 0 ? filteredData(myActiveSortedTrainingPlan, 2, isUKPN).length : overdueCount : <Spinner animation="grow" variant="light" size="sm" />}
                </NavButtonSmallVariant>
                <NavButton className={"d-none d-sm-block"} variant='danger' title='Overdue' to={{ pathname: '/lms/mylearningplan', search: 'filter=overdue' }}>
                    {myActiveSortedTrainingPlan ? overdueCount === 0 ? filteredData(myActiveSortedTrainingPlan, 2, isUKPN).length : overdueCount : <Spinner animation="grow" variant="light" size="sm" />}
                </NavButton>
            </Col>
            <Col xs={4}>
                <NavButtonSmallVariant className={"d-block d-sm-none"} variant='success' title='Completed' to={{ pathname: '/lms/mylearningplan', search: 'filter=completed' }}>
                    {myActiveSortedTrainingPlan ? completeCount === 0 ? filteredData(myActiveSortedTrainingPlan, 3, isUKPN).length : completeCount : <Spinner animation="grow" variant="light" size="sm" />}
                </NavButtonSmallVariant>
                <NavButton className={"d-none d-sm-block"} variant='success' title='Completed' to={{ pathname: '/lms/mylearningplan', search: 'filter=completed' }}>
                    {myActiveSortedTrainingPlan ? completeCount === 0 ? filteredData(myActiveSortedTrainingPlan, 3, isUKPN).length : completeCount : <Spinner animation="grow" variant="light" size="sm" />}
                </NavButton>
            </Col>
        </>
    );
}
