import { useState, useMemo, useEffect} from 'react';
import { TableContainer } from '../helpers/tableHelper'
import MaterialReactTable from 'material-react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencil, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Container, Row, Col, Form, InputGroup, Modal, Button } from "react-bootstrap";
import useLmsStore, { responseData, getAllActiveEventsByPersonGroups, getEventFullInfo, createEventBookingNew, getCanCancelBooking, cancelEventBookingNew,createWaitingDatesNew, removeWaitingDatesNew, getAllActiveEvents, BookUKPNCourseEvent, updateCatalogueApprovalStatus } from "../hooks/useLmsStore";
import moment from 'moment'
import shallow from 'zustand/shallow';
import ModalConfirmation from './ModalConfirmation';
import { array } from 'yup';
import { commonService } from '../services/common/common.service';

const ModalBookingFormUKPN = ({ closeFn = () => null, open = false,  refLearningItemId, refLearningItemData }) => {
    const {
        allBookingCancellationReasons,
        fetchAllBookingCancellationReasons
    } = useLmsStore(state =>
    ({
        allBookingCancellationReasons: state.allBookingCancellationReasons,
        fetchAllBookingCancellationReasons: state.fetchAllBookingCancellationReasons

    }), shallow);

    const [events, setEvent] = useState([]);
    const [eventsLoading, setEventsLoading] = useState(false);
    const [info, setInfo] = useState(false);
    const [infoData, setInfoData] = useState("");
    const [createBooking, setCreateBooking] = useState(false);
    const [postingBooking, setPostingBooking] = useState(false);
    const [postingWaitingDates, setPostingWaitingDates] = useState(false);
    const [postingRemoveWaitingDates, setPostingRemoveWaitingDates] = useState(false);
    const [bookingData, setBookingData] = useState({});
    const [booking, setBooking] = useState([]);
    const [bookingLoading, setBookingLoading] = useState(false);
    const [selectReason, setSelectReason] = useState(1);
    const [comments, setComments] = useState("");
    const [title, setTitle] = useState("Book an Event");
    const canBook = x =>  x && (x.instructorInfo==null || (x.statusName!=='Confirmed' && x.statusName!=='Attended' && x.instructorInfo && x.instructorInfo.statusId === 6));
    const [loading, setLoading] = useState(false);
 
    const handlePostBooking = () => {
        let data = {
            LearningItemId: refLearningItemId,
            UKPN_EventId: bookingData.eventId
        }
        //console.log(`Post booking=> ${refLearningItemId} data:  `, data);
        setPostingBooking(true);
        BookUKPNCourseEvent(useLmsStore, data).then(() => 
        {
            setPostingBooking(false);
            setCreateBooking(false);
            if (data)
            {
                commonService.NotifyBookCourseEvent = true;
                closeFn();
            }

        }).finally(() => setPostingBooking(false));
    }

    const handleBooking = (row) => {
        //alert('booking available:' + row.placesLeft);
        setBookingData(row);
        setCreateBooking(true);
    };

    const handleCloseBooking = () => {
        setCreateBooking(false);
    }


    const handleCloseInfo = ()=> {
        setInfo(false);
    };

    const handleInfo = (row) => 
    {
        if (eventsLoading) 
        {
            alert('Events are still loading. Please wait.');
            return;
        }        
        setInfo(true);
        setInfoData(row);
        //alert('events: ' + JSON.stringify(events));        
        // let eventInfo = events.find(x=> x.eventId === id);
        
        // if (eventInfo != null)
        // {
        //     setInfoData(eventInfo);
        // }
        // getEventFullInfo(id).then((response) => {
        //     let data = responseData(response, []);
        //     //console.log(`data => `, data);
        //     if(data){
        //         setInfoData(data);
        //     }
        // });
    };

    const timeConvert = (n) => {
        const num = n;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        // return num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
        return rhours + " hour(s) and " + rminutes + " minute(s).";
        }
   
    useEffect(() => {
        setCreateBooking(false);
         if(refLearningItemId && refLearningItemId > 0)
         {
                setEventsLoading(true);
                setTitle("Book an Event");
                getAllActiveEvents(refLearningItemId).then((response) => {
                    let data = responseData(response, []);
                    //alert(JSON.stringify(response));
                     if(data)
                     {
                        setEvent(response.data.value);
                    } 
                }).finally(() => setEventsLoading(false));
                
            // }
        }
    }, [refLearningItemId]);

    useEffect(() => {
        // http://lms-angular.test.wdr.co.uk/api/booking/getAllBookingCancellationReasons
        //fetchAllBookingCancellationReasons();
    }, []);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: '',
                size: 0
            },
            {
                accessorKey: 'startDate',
                header: '',
                size: 0
            },
            {
                accessorKey:'endDate',
                header:'',
                size: 0
            },
            {
                accessorKey: "searchTerms",
                header: "Info",
                align: "left",
                size: 400,
                Cell: ({ cell, row }) => {
                    return (<div className="float-start">
                        {"Event: " + row.original.name + " at room " + row.original.roomName + " on " + row.original.startDateTime  + " ends " + row.original.endDateTime  }
                        {/* {"Event: " + row.original.name + " on " + moment(row.original.startDate).format('DD/MM/YYYY HH:mm:ss')  + " ends " + moment(row.original.endDate).format('DD/MM/YYYY HH:mm:ss')  } */}
                        
                        <br/>
                        <Button variant='clear' className='pt-0 pb-0' style={{'fontSize':'1.3rem'}} onClick={() => handleInfo(row.original)}>
                            Event Info
                        </Button><br/>
                        {/* {" Duration " + row.original.duration + " " + row.original.durationUnit }<br/> */}
                        {/* {row.original.venueName + " " + row.original.venueCity + " - Room " + row.original.roomName } */}
                    </div>)
                }
            },
            {
                accessorKey: "eventId",
                header: "Action",
                align: "center",
                size: 40,
                Cell: ({ cell, row }) => {
                    return (<>
                        <Button variant='primary' disabled = {row.original.placesLeft == 0} className='pt-0 pb-0' onClick={() => handleBooking(row.original)}>
                            Book
                        </Button><br/>
                        <span>{row.original.placesLeft} Places</span>
                    </>)
                }
            }
        ],
        [],
    );

    return (
        <Modal size="lg" aria-labelledby="example-modal-sizes-title-lg" show={open} onHide={closeFn} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                   {title} 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Container fluid>
                {    
                    info?
                        <>
                        
                            {infoData ?
                            <>
                            <h3>Info for event: {infoData.name}</h3> 
                            <div>Room Name: {infoData.roomName}</div>
                            <div><h6>Places remaining {infoData.placesLeft}</h6><div> {infoData.startDateTime} - {timeConvert(infoData.duration)}..<br/> Time slot between {infoData.displayStartTime} and {infoData.displayEndTime} ({ infoData.duration} mins) <br/>  At {infoData.roomName}, {infoData.venueName}</div></div>
                            {/* <div><h6>Places remaining {infoData.placesLeft}</h6><div> {moment(infoData.startDate).format('DD/MM/YYYY')}- {timeConvert(infoData.duration)}..<br/> Time slot between {infoData.displayStartTime} and {infoData.displayEndTime} ({ infoData.duration} mins) <br/>  At {infoData.roomName}, {infoData.venueName}</div></div> */}
                            <Button variant='primary' onClick={handleCloseInfo}>Back To Booking</Button>
                            </>
                            : 
                            <>
                            <div className="d-flex justify-content-center">
                                <div className="" >
                                    <h4 >No event available</h4>
                                    
                                </div>
                            </div>
                            <div><Button variant='primary' onClick={handleCloseInfo}>Back To Booking</Button></div>
                            </>
                            }
                        </>
                        :
                        createBooking || postingWaitingDates || postingRemoveWaitingDates ?
                            postingBooking || postingWaitingDates || postingRemoveWaitingDates?
                                <>
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-border mt-5 text-primary" style={{width: '5rem', height: '5rem'}} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div>Confirm the booking the event '{bookingData.name}' on {bookingData.displayStartTime+ ' and ' + bookingData.displayEndTime}</div>
                                </>
                            :
                            <>
                                <div style={{'paddingBottom':'10px'}}><h5>We have the following scheduled events for the course:</h5></div><div>{refLearningItemData?.name}</div>
                                <TableContainer>
                                    <MaterialReactTable
                                        columns={columns}
                                        data={events || []}
                                        enableColumnActions={false}
                                        enableColumnFilters={false}
                                        enableDensityToggle={false}
                                        enableFullScreenToggle={false}
                                        columnVisibility = {{ name : false, startDate: false, endDate:false }}
                                        enableGrouping={false}
                                        enableHiding={false}
                                        enablePagination={false}
                                        enableSorting={false}
                                        enableTopToolbar={true}
                                        muiTableHeadProps={{
                                            sx: {
                                                display: 'none'
                                            }
                                        }}
                                        muiTableBodyCellProps={ {
                                            align: 'center',
                                        }}
                                        getRowId={(originalRow) => originalRow.id}
                                        muiToolbarAlertBannerProps={{
                                            sx: {
                                                display: 'none'
                                            }
                                        }}
                                        initialState={{
                                            pagination: { pageIndex: 0, pageSize: 10 },
                                            showGlobalFilter: true
                                        }}
                                        state={{
                                            isLoading: eventsLoading,
                                            columnVisibility: {
                                                name: false,
                                                startDate: false,
                                                endDate: false
                                            }
                        
                                        }}
                                    />
                                </TableContainer>

                            </>
                }
            </Container>
            </Modal.Body>
            <Modal.Footer>
            {
                    refLearningItemId && refLearningItemId > 0 && /*(canBook(refLearningItemData)*/ !info && createBooking && !postingBooking?
                    <>
                        <Button variant='primary px-3 w-25' onClick={handlePostBooking} disabled={postingBooking} >Yes</Button>
                        <Button variant='primary px-3 w-25' onClick={handleCloseBooking} disabled={postingBooking} >No</Button>
                    </>
                    :
                    <>
                    </>   
                }

                <Button variant='primary' onClick={closeFn}>Close</Button>
                {/* <ModalConfirmation closeFn={()=> setCreateBooking(false)} yesFn={handlePostBooking} noFn={()=> setCreateBooking(false)} open={createBooking} 
                    title='Confirm Booking Event' 
                    description={'<p>Are you sure you want to confirm this event?</p><h6>Event Name:'+ 
                                bookingData.name + '</h6>' +
                                '<div>' + bookingData.startDateTime +' - ' + timeConvert(bookingData.duration) +'..<br/> Time slot between ' + bookingData.displayStartTime + ' and ' + bookingData.displayEndTime + '</div>'
                                } 
                    // <div><h6>Places remaining {infoData.placesLeft}</h6><div> {infoData.startDateTime} - {timeConvert(infoData.duration)}..<br/> Time slot between {infoData.displayStartTime} and {infoData.displayEndTime} ({ infoData.duration} mins) <br/>  At {infoData.roomName}, {infoData.venueName}</div></div>

                /> */}
            </Modal.Footer>
        </Modal>
        
    );
};

export default ModalBookingFormUKPN;