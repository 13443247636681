import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
const ModalConfirmation = ({ closeFn = () => null, yesFn = () => null, noFn = () => null, open = false, title = "", description = "", showContinue=false }) => {

    return (
        <Modal fullscreen='md-down' show={open} onHide={closeFn} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span dangerouslySetInnerHTML={{ __html: description }} />
            </Modal.Body>
            <Modal.Footer>
                {showContinue?
                <>
                  <Button onClick={yesFn} variant="primary">Continue</Button>
                  <Button variant='primary' onClick={closeFn}>Cancel</Button>
                  </>
                  :
                  <>
                <Button onClick={yesFn} variant="primary">Yes</Button>
                <Button onClick={noFn} variant="primary">No</Button>
                <Button variant='primary' onClick={closeFn}>Close</Button>
                </>}
               
            </Modal.Footer>
        </Modal>

    );
};

export default ModalConfirmation;

export const ModalConfirmCancel = ({ showCancelEditModal = false, abortFn = () => null, continueFn = () => null }) => {
    return (
        <Modal fullscreen='md-down' show={showCancelEditModal} onHide={abortFn} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Unsaved changes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You have made changes that have yet to be saved.<br />Are you sure you want to cancel and discard your changes?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={abortFn} variant="outline-secondary">No</Button>
                <Button onClick={continueFn} variant="outline-primary">Yes</Button>
            </Modal.Footer>
        </Modal>
    );
};

