import shallow from 'zustand/shallow';
import useLmsStore from "../hooks/useLmsStore";
import { Carousel, Col, Row } from 'react-bootstrap';
import { useEffect } from 'react';

const getExtension = (path) => /(?:\.([^.]+))?$/.exec(path)[1];

export default function NewsStand(props) {
    const { allNews, fetchAllNews } = useLmsStore(state =>
    ({
        allNews: state.allNews.data,
        fetchAllNews: state.fetchAllNews
    }), shallow)

    // on load
    useEffect(() => {
        fetchAllNews();
    }, [fetchAllNews]);

    function truncate(str, n) {
        return (str.length > n) ? str.slice(0, n - 1) + '&hellip;' : str;
    };

    return (
        !props.personSettings?.ukpnPersonId && allNews && allNews.length > 0 ?
            <Row style={{padding:'0 1.5rem 1rem 0.75rem'}}>
                <Col className='border rounded shadow' style={{ height: '5.5rem', overflowX: 'hidden' }}>
                    <Carousel variant='dark' style={{ height: '100%' }} controls={false} indicators={false}>
                        {allNews.map((rowData, idx) => (
                            <Carousel.Item key={idx} interval={5000} >
                                <div className="w-100"><span style={{ fontSize: "0.8rem" }} dangerouslySetInnerHTML={{ __html: rowData.description + (rowData.link ? '<br><a href="' + rowData.link + (getExtension(rowData.link) ? '" target="_blank' : '') + '">' + rowData.linkDescription + '</a>' : '') }} /></div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
            </Row>
            : null
    );
}
