import { useEffect, useState } from "react";
import { Container, Row, Modal, Button } from "react-bootstrap";
import { toDDsMMsYYString, fromISODate, fromIntTime } from "../helpers/formatHelper";
import Card from "react-bootstrap/Card";

const ModalUserEventInfo = ({
  personSettings,
  closeFn = () => null,
  open = false,
  eventVenueData,
  userEventData,
  eventName,
  status,
}) => {
  useEffect(() => {}, []);

  const [venueData, setVenueData] = useState(eventVenueData);
  const [userData, setUserData] = useState(userEventData);

  const closeLocal = () => {
    closeFn();
    setVenueData(null);
    setUserData(null);
  };

useEffect(() =>
{
  setVenueData(eventVenueData)
}, [eventVenueData])

useEffect(() =>
  {
    setUserData(userEventData)
  }, [userEventData])

return personSettings.ukpnPersonId ? UkpnModal() : GenericModal();

  function UkpnModal() {
    return (
      <Modal
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={open}
        onHide={closeLocal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{eventName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {venueData ? (
              <>
                {venueData ? (
                  <>
                    {venueData.length > 0 ? (
                      <>
                        <div>
                          {venueData.map((d) => (
                            <div key={d.eventId}>
                              {
                                <>
                                  <Row>
                                    <Card
                                      className="shadow"
                                      style={{
                                        backgroundColor: "#e9ecef",
                                        marginTop: "1rem",
                                      }}
                                    >
                                      <Card.Header
                                        style={{
                                          border: "none",
                                          backgroundColor: "#e9ecef",
                                        }}
                                      >
                                        <strong>Event date:</strong>{" "}
                                        {fromISODate(d.eventStartDate.toString())}
                                      </Card.Header>
                                      <Card.Body style={{ paddingTop: "0px" }}>
                                          <Row
                                            className="gx-3"
                                            style={{
                                              backgroundColor: "#ffffff",
                                              border: "ridge 0.2px #696969",
                                            }}
                                          >
                                            <div>
                                              <b>Time:</b> {fromIntTime(d.eventStartTime.toString())}
                                            </div>
                                            <div>
                                              <b>Room:</b> {d.description}
                                            </div>
                                            <div>
                                              <b>Venue:</b> {d.coAddr1}
                                            </div>
                                          </Row>
                                      </Card.Body>
                                    </Card>
                                  </Row>
                                  <Row>
                                    <></>
                                  </Row>
                                </>
                              }
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                      </>
                    )}
                  </>
                ) : (
                  <>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeLocal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function GenericModal() {
    return (
      <Modal
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={open}
        onHide={closeFn}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{eventName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {userData ? (
              <>
                {userData.eventDates ? (
                  <>
                    {userData.eventDates.length > 0 ? (
                      <>
                        <div>
                          {userData.eventDates.map((d) => (
                            <div key={d.date}>
                              {
                                <>
                                  <Row>
                                    <Card
                                      className="shadow"
                                      style={{
                                        backgroundColor: "#e9ecef",
                                        marginTop: "1rem",
                                      }}
                                    >
                                      <Card.Header
                                        style={{
                                          border: "none",
                                          backgroundColor: "#e9ecef",
                                        }}
                                      >
                                        <strong>Event date:</strong>{" "}
                                        {toDDsMMsYYString(d.date)}
                                      </Card.Header>
                                      <Card.Body style={{ paddingTop: "0px" }}>
                                        {d.slots.map((s) => (
                                          <Row
                                            className="gx-3"
                                            style={{
                                              backgroundColor: "#ffffff",
                                              border: "ridge 0.2px #696969",
                                            }}
                                          >
                                            <div>
                                              <b>Time:</b> {s.startTime}-
                                              {s.endTime}
                                            </div>
                                            <div>
                                              <b>Room:</b> {s.roomName}
                                            </div>
                                            <div>
                                              <b>Venue:</b> {s.venueName}
                                            </div>
                                            <div>
                                              <b>Status:</b> {s.statusName}
                                            </div>
                                          </Row>
                                        ))}
                                      </Card.Body>
                                    </Card>
                                  </Row>
                                  <Row>
                                    <></>
                                  </Row>
                                </>
                              }
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <Card
                          className="shadow"
                          style={{
                            backgroundColor: "#e9ecef",
                            marginTop: "1rem",
                          }}
                        >
                          <Card.Header
                            style={{
                              border: "none",
                              backgroundColor: "#e9ecef",
                            }}
                          >
                            <strong>Event date: </strong>{" "}
                            {toDDsMMsYYString(userData.startDate)}
                          </Card.Header>

                          <Card.Body style={{ paddingTop: "0px" }}>
                            <Row
                              className="gx-3"
                              style={{
                                backgroundColor: "#ffffff",
                                border: "ridge 0.2px #696969",
                              }}
                            >
                              <div>
                                <strong>Time: </strong>
                                {userData.startTime}-
                                {userData.endTime}
                              </div>
                              <div>
                                <strong>Room: </strong> {userData.roomName}
                              </div>
                              <div>
                                <strong>Venue: </strong>{" "}
                                {userData.venueName}
                              </div>
                              <div>
                                <strong>Status: </strong> {status}
                              </div>
                            </Row>
                          </Card.Body>
                        </Card>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Card
                      className="shadow"
                      style={{ backgroundColor: "#e9ecef", marginTop: "1rem" }}
                    >
                      <Card.Header
                        style={{ border: "none", backgroundColor: "#e9ecef" }}
                      >
                        <strong>Event date: </strong>{" "}
                        {toDDsMMsYYString(userData.startDate)}
                      </Card.Header>

                      <Card.Body style={{ paddingTop: "0px" }}>
                        <Row
                          className="gx-3"
                          style={{
                            backgroundColor: "#ffffff",
                            border: "ridge 0.2px #696969",
                          }}
                        >
                          <div>
                            <strong>Time: </strong>
                            {userData.displayStartTime}-
                            {userData.displayEndTime}
                          </div>
                          <div>
                            <strong>Room: </strong> {userData.roomName}
                          </div>
                          <div>
                            <strong>Venue: </strong> {userData.venueName},{" "}
                            {userData.venueCity}
                          </div>
                          <div>
                            <strong>Status: </strong> {status}
                          </div>
                        </Row>
                      </Card.Body>
                    </Card>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeFn}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default ModalUserEventInfo;
