import Multiselect from 'multiselect-react-dropdown';
import useLmsStore, { responseData, getCatalogueInfo, postCatalogue, FORMCONFIRM } from "../hooks/useLmsStore";
import { Container, Row, Col, Alert, Button, Modal } from "react-bootstrap";
import { FormButtons } from '../components/buttons/FormButtons';
import { useState, useEffect } from 'react';
import PeopleSelect from '../components/PeopleSelect';
import GroupSelect from './GroupSelect';
import LearningItemsSelect from './LearningItemsSelect';
const FormCataloguez = ({ catalogue, allLearningItems, applicationGroups, applicationPeople, requestLeave, onRequestLeaveCompleted }) => {
    const [catalogueInfo, setCatalogueInfo] = useState([]);
    const [catalogueLoading, setCatalogueLoading] = useState(false);
    const [catalogueError, setCatalogueError] = useState(false);
    const [inProgress, setInProgress] = useState(false);
     const [title, setTitle] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [isDirtyPeopleList, setIsDirtyPeopleList] = useState(false);
    const [isDirtyGroupList, setIsDirtyGroupList] = useState(false);
    const [isDirtyItemsList, setIsDirtyItemsList] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [selectedPeopleOri, setSelectedPeopleOri] = useState([]);
    const [selectedGroupsOri, setSelectedGroupsOri] = useState([]);
    const [selectedItemsOri, setSelectedItemsOri] = useState([]);
    const [assignedPeople, setAssignedPeople] = useState([]);
    const [assignedGroups, setAssignedGroups] = useState([]);
    const [assignedItems, setAssignedItems] = useState([]);


    const handleFormLoad = () => {
        setCatalogueError(false);
        setCatalogueLoading(true);
        setHasUnsavedChanges(false);
       
        setSelectedPeopleOri([]);
        setSelectedGroupsOri([]);
        setSelectedItemsOri([]);
        setAssignedPeople([]);      
        setAssignedGroups([]);
        setAssignedItems([]);

        if (catalogue && catalogue.id) {
            getCatalogueInfo(catalogue.id).then((response) => {
                let data = responseData(response, []);

                if (data.title) {
                   
                    setAssignedGroups(data.catGroups);
                    setAssignedPeople(data.catPeople);
                    setAssignedItems(data.catLearningItems); 
                 
                   //if it is an update!                  
                    setCatalogueInfo(data);
                    let copy =structuredClone(data);
                    setSelectedPeopleOri(copy.catPeople);
                    setSelectedGroupsOri(copy.catGroups);
                    setSelectedItemsOri(copy.catLearningItems);


                    setTitle(data.title ? data.title : "");
                    setIsActive(data.isActive);

                    setCatalogueLoading(false);

                }

                if (data.statusCode !== 200 || !data) {
                    setCatalogueError(true);
                }
            });
        } else {
            setTitle("");
            setSelectedPeopleOri([]);
            setSelectedGroupsOri([]);
            setSelectedItemsOri([]);

            //if it is a new catalogue!
            setCatalogueInfo({
                catalogueApplicationId: -1,
                isActive: false,
                isDeleted: false,
                catLearningItems: [],
                catGroups: [],
                catPeople: [],
                portalEnvironment: null
            });
            setCatalogueLoading(false);
        }
    };

    const handleSubmit = async event => {
        if (!inProgress) {
            let ctgData = catalogueInfo;
            ctgData["title"] = title;
            ctgData["isActive"] = isActive;
            setInProgress(true);
            await postCatalogue(useLmsStore, ctgData);
            setInProgress(false);
            setHasUnsavedChanges(false);
           
        }
    };



    
    useEffect(() => {
        handleFormLoad();
    }, [catalogue]);


    const checkSetIsDirty=(assignedList,type)=>{
        let isDirty = false;
       
        if (assignedList){    
        switch(type){
        case 1:
        if (selectedPeopleOri){
            if (selectedPeopleOri && assignedList && !isDirty){
                if (selectedPeopleOri.length!= assignedList.length){
                   isDirty=true;
                }
                else{
                    //Check if ids are same
                    assignedList.forEach((g) => {
                        if (selectedPeopleOri.findIndex(a=> a.personId==g.personId) <0){
                          isDirty=true;
                          setIsDirtyPeopleList(isDirty);
                        }                       
                      });
                }
            }
        }
        break;         
        
        case 2:      
        if (selectedGroupsOri){  
        if (selectedGroupsOri.length!= assignedList.length){
            isDirty=true;
         }
         else{
             //Check if ids are same
             assignedList.forEach((g) => {
               if (selectedGroupsOri.findIndex(a=> a.id==g.id) <0){
                 isDirty=true;    
                 setIsDirtyGroupList(isDirty);                
               }
             });
         }
        }
         break;
        
        case 3:
        if (selectedItemsOri && assignedList){                   
            if (selectedItemsOri.length!= assignedList.length){
               isDirty=true;
            }
            else{
                  //Check if ids are same
             assignedList.forEach((g) => {
                if (selectedItemsOri.findIndex(a=> a.learningItemId==g.learningItemId) <0){
                  isDirty=true;    
                  setIsDirtyItemsList(isDirty);                
                }
              });
            }
            break;
        }
    }
}

        //Check if current or any list is Dirty
        if (isDirty || (isDirtyGroupList && type==2) || (isDirtyPeopleList && type ==1) || (isDirtyItemsList && type==3)){
        setHasUnsavedChanges(true);
        }
        else{
            setHasUnsavedChanges(false);
        }
    }

  //Call when the user assigned an user to catalogue
  const addPeopleFormFn = (objs) => { 
    let ctgData = catalogueInfo;   
    
    if (selectedPeopleOri.length>0){
    //Checking if any of the items was added and removed, so we can put the id back so it is not added in the system twice
    for (const g of objs){
        const item = (element) => element.personId === g.personId;
        let idx = selectedPeopleOri.findIndex(item);

        if (idx>=0){
          g.catPersonId =  selectedPeopleOri[idx].catPersonId;
        }
    }
    }  

    ctgData.catPeople=objs;

    setCatalogueInfo(ctgData);
    setAssignedPeople(objs);
    checkSetIsDirty(objs, 1);
  }

  //Cann when the user unassigned a person from the catalogue
  const removePeopleFormFn = (objs) => {
 
    let ctgData = catalogueInfo;    
    ctgData.catPeople=objs;
   
    setCatalogueInfo(ctgData);
    setAssignedPeople(objs);
    checkSetIsDirty(objs, 1);
  }

    //Call when the groups ares assigned to catalogue
    const addGroupsFormFn = (objs) => { 
        let ctgData = catalogueInfo;   
        
        if (selectedGroupsOri.length>0){
        //Checking if any of the items was added and removed, so we can put the id back so it is not added in the system twice
        for (const g of objs){
            const item = (element) => element.groupId === g.groupId;
            let idx = selectedGroupsOri.findIndex(item);
    
            if (idx>=0){
              g.catGroupId =  selectedGroupsOri[idx].catGroupId;
            }
        }
        }  
    
        ctgData.catGroups=objs;
        setCatalogueInfo(ctgData);
        setAssignedGroups(objs);

        checkSetIsDirty(objs, 2);
      }
    
      //Cann when the groups are unassigned from the catalogue
      const removeGroupsFormFn = (objs) => {
     
        let ctgData = catalogueInfo;    
        ctgData.catGroups=objs;        
      
        setCatalogueInfo(ctgData);
        setAssignedGroups(objs);
        checkSetIsDirty(objs, 2);
      }
    

      //Call when the learning items are assigned to catalogue
    const addItemsFormFn = (objs) => { 
        let ctgData = catalogueInfo;   
        
        if (selectedItemsOri.length>0){
        //Checking if any of the items was added and removed, so we can put the id back so it is not added in the system twice
        for (const g of objs){
            const item = (element) => element.learningItemId === g.learningItemId;
            let idx = selectedItemsOri.findIndex(item);
    
            if (idx>=0){
              g.joinId =  selectedItemsOri[idx].joinId;
            }
        }
        }  
    
        ctgData.catLearningItems=objs;
     
        setCatalogueInfo(ctgData);
        setAssignedItems(objs);

        checkSetIsDirty(objs, 3);
      }
    
      //Cann when learning items are unassigned from the catalogue
      const removeItemsFormFn = (objs) => {
     
        let ctgData = catalogueInfo;    
        ctgData.catLearningItems=objs;
       
        setCatalogueInfo(ctgData);
        setAssignedItems(objs);
        checkSetIsDirty(objs, 3);
      }
    /*end new code */
    return (
        <Container fluid className='h-100 overflow-auto'>
            {catalogueLoading ? catalogueError ?
                <>
                    <Alert variant="danger" >
                        <Alert.Heading>Oh snap! No data!</Alert.Heading>
                        <p>
                            No data was returned for this user! {catalogueError}
                        </p>
                    </Alert>
                </>
                :
                <>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border mt-5 text-primary" style={{ width: '5rem', height: '5rem' }} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </>
                :
                <>
                    <Container fluid>
                        <Row className="mt-2 w-100">
                            <h3 className="catalogue-details-title">Catalogue Details</h3>
                        </Row>
                        <Row className="mt-2 w-100"><hr /></Row>
                        <Row>
                            <fieldset disabled={inProgress} style={{ display: "flex" }} className="mb-3">
                                <div className="w-100 row">
                                    <label htmlFor="title" className="form-label col-form-label col-sm-2">Catalogue Title</label>
                                    <div className="col-sm-10">
                                        <div className="input-group has-validation">
                                            <input placeholder="Catalogue Title" type="input" id="title" className="form-control" value={title} onChange={e => {
                                                setTitle(e.target.value);
                                                setHasUnsavedChanges(true);
                                            }} />
                                            <div className="invalid-feedback">Please catalogue title.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="mt-2 mx-3 form-check form-switch">
                                        <input type="checkbox" id="active" className="form-check-input" checked={isActive} onChange={e => {
                                            setIsActive(e.target.checked);
                                            setHasUnsavedChanges(true);
                                        }} />
                                        <label title="" htmlFor="active" className="form-check-label">Active</label>
                                    </div>
                                </div>
                            </fieldset>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Row>
                                <LearningItemsSelect  selectedItems={assignedItems} allItems={allLearningItems} addFn={(obj) =>addItemsFormFn(obj)} removeFn={(obj) =>removeItemsFormFn(obj)} ></LearningItemsSelect>
                     
                                </Row>
                            </Col>
                            <Col sm={4}>
                                <Row>
                                <GroupSelect  selectedGroups={assignedGroups} allGroups={applicationGroups} addFn={(obj) =>addGroupsFormFn(obj)} removeFn={(obj) =>removeGroupsFormFn(obj)} ></GroupSelect>
                     
                                </Row>
                            </Col>
                            <Col sm={4}>
                            <Row>                           
                               <PeopleSelect  selectedPeople={assignedPeople} allPeople={applicationPeople} addFn={(obj) =>addPeopleFormFn(obj)} removeFn={(obj) =>removePeopleFormFn(obj)} ></PeopleSelect>
                              </Row>
                            </Col>
                        </Row>
                        <FormButtons
                            hasUnsavedChanges={hasUnsavedChanges}
                            pleaseWait={inProgress || catalogueLoading}
                            requestLeave={requestLeave}
                            onRequestLeaveCompleted={onRequestLeaveCompleted}
                            cancelButtonProps={{
                                onClick: handleFormLoad
                            }}
                            saveButtonProps={{
                                onClick: handleSubmit
                            }}
                        />
                    </Container>
                </>}
        </Container>
    );
};

export default FormCataloguez;