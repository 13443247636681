import BaseBeginContainer from "../components/BaseBeginContainer";
import BaseContainer from "../components/BaseContainer";
import shallow from 'zustand/shallow';
import useLmsStore, { hasPermission } from '../hooks/useLmsStore';
import { BeginButton } from '../components/CustomButtons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row } from 'react-bootstrap';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { faClipboardList, faUser, faUsers, faBooks, faListOl, faFileChartColumn, faRoad, faGear, faBuildings, faUserCheck, faPersonChalkboard, faLaptopFile } from '@fortawesome/pro-solid-svg-icons';
import { isLocalOrDevelopment } from "../helpers/EnvironmentDeterminator";
import { useEffect } from 'react';
import { apiAxiosCalls } from '../services/apiAxiosCalls';

function ExternalTitle(props) {
    return (<>{props.title} <FontAwesomeIcon size='xs' icon={faArrowUpRightFromSquare} className='text-mutted' /></>);
};

const AdministrationCore = ({ dimensions }) => {

    const { personBasic, personSettings, fetchPersonSettings, fetchPersonBasic, hasPermission } = useLmsStore(state =>
    ({
        personBasic: state.personBasic.data,
        personSettings: state.personSettings.data,
        fetchPersonBasic: state.fetchPersonBasic,
        fetchPersonSettings: state.fetchPersonSettings,
        hasPermission: state.hasPermission
    }), shallow)

    useEffect(() => {
        fetchPersonBasic();
        fetchPersonSettings();
    }, []);

    return (
        <BaseBeginContainer dimensions={dimensions}>

            {personSettings?.theme === 'ukpn' ?
                <>
                    <BeginButton title='Learning items' description='View, create and update learning items.' icon={faLaptopFile} to={"/lms/learningitems"} />
                </>
                :
                <>
                    <BeginButton title={<ExternalTitle title='Learning Items' />} description='View, create and update learning items.' icon={faLaptopFile} href={apiAxiosCalls.hrefToAdminServer(personBasic, 'portal.learningItems')} target='admin-tab' />
                    <BeginButton title='Companies' description='View, create, update or delete companies.' icon={faBuildings} to='/lms/companies' />
                </>
            }
            <BeginButton title='Users' description='View, create & update user records.' icon={faUser} to='/lms/users' />
            <BeginButton title='Create Users (Starters)' description='Upload starters feed file' icon={faUser} to='/lms/createusers' />
            <BeginButton title='Catalogues' description='View, create, update or mark catalogues as inactive and assign to users/groups.' icon={faBooks} to='/lms/catalogues' />
            <BeginButton title='Learning Plan Admin' description='View, create, update learning plans and assign to users/groups.' icon={faListOl} to='/lms/learningplan' />
            <BeginButton title='Groups' description='View, create and update groups, assign users and learning pathways to the group.' icon={faUsers} to='/lms/GroupManagement' target='admin-tab' />

            <BeginButton title='Reports' description='Run reports.' icon={faFileChartColumn} to='/lms/reports' />
            <BeginButton title='PathWays Admin' description='View, create & update user records.' icon={faUser} to='/lms/pathways' />


            <BeginButton title='Application Settings' description='Modify application settings.' icon={faGear} to='/lms/applicationsettings' />
            <BeginButton title={<ExternalTitle title='Assessments' />} description='View assessments.' icon={faUserCheck} href={apiAxiosCalls.hrefToAdminServer(personBasic, 'portal.admin.assessments')} target='admin-tab' />
            {hasPermission('ea.view') === true ? <>
                <BeginButton title={<ExternalTitle title='Event Attendance' />} description='View & update event attendance for instructor-led events and upload copies of registers to the event.' icon={faUsers} href={apiAxiosCalls.hrefToAdminServer(personBasic, 'portal.admin.eventAttendance')} target='admin-tab' />
            </> : <></>}
            {process.env.REACT_APP_RNEXT === 'true' ? <BeginButton title='Experiment' description='Experiment.' icon={faBuildings} to='/lms/experiment' /> : null}
        </BaseBeginContainer>
    );
};

export default function Administration(props) {

    return (<BaseContainer title='Admin Dashboard'>
        <AdministrationCore />
    </BaseContainer>);
}
