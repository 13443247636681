import React, { useEffect, useState } from 'react';
import BaseContainer from "../components/BaseContainer";
import LearningItemTabCard from "../components/learningItemsPageComponents/LearningItemTabCard";
import {
    defaultPageSize,
    learningItemsCardNames,
    learningItemsTabEnum,
    learningItemTypesIdsEnum,
    searchLearningItemsInitialPayload
} from "../helpers/constantsAndEnums";
import { apiAxiosCalls } from "../services/apiAxiosCalls";
import LearningItemSingleSearchResult from "../components/learningItemsPageComponents/LearningItemSingleSearchResult";
import useLmsStore, { setLearningItemsState, setDueDatesState, setCourseTypesState, setDeliveryMethodState } from "../hooks/useLmsStore";
import LearningItemDetailTab from "../components/learningItemsPageComponents/learningDetailsTab/LearningItemDetailTab";
import useGetLearningItemTypes from "../hooks/useGetLearningItemTypes";
import useGetDeliveryMethods from "../hooks/useGetDeliveryMethods";
import useGetCourseTypes from "../hooks/useGetCourseTypes";
import ImagesTab from "../components/learningItemsPageComponents/ImagesTab/ImagesTab";
import LearningItemSynopsisTab
    from "../components/learningItemsPageComponents/LearningItemSynopsisTab/LearningItemSynopsisTab";
import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import AssessmentsTab from "../components/learningItemsPageComponents/AssessmentsTab/AssessmentsTab";
import ELearningTab from "../components/learningItemsPageComponents/ElearningTab/ELearningTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import LearningItemsSearch from "../components/learningItemsPageComponents/LearningItemsSearch";
import { Skeleton, TablePagination, useStepContext } from "@mui/material";
import shallow from 'zustand/shallow';
import { TableRowSplit } from '../helpers/tableHelper'

import CourseTypeFilter from "../components/learningItemsPageComponents/CourseTypeFilter";
import DevelopmentTypeFilter from '../components/learningItemsPageComponents/DevelopmentTypeFilter';
import DeliveryMethodFilter from '../components/learningItemsPageComponents/DeliveryMethodFilter';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';

const LearningItemsCore = ({ dimensions }) => {
    const [inactiveItems, setInactiveItems] = useState(false);
    const [dueDates, setDueDates] = useState(null);

    useGetLearningItemTypes();
    useGetCourseTypes();
    useGetDeliveryMethods();

    const { learningItemsState, hasPermission } = useLmsStore(state => ({
        learningItemsState: state.learningItemsState,
        hasPermission: state.hasPermission
    }), shallow)

    const { courseTypesState } = useLmsStore(state => ({
        courseTypesState: state.courseTypesState
    }), shallow)

    const { deliveryMethodState } = useLmsStore(state => ({
        deliveryMethodState: state.deliveryMethodState
    }), shallow)

    const {
        activeTab,
        learningItemsCount,
        learningItems,
        searchLearningItemsPayload,
        triggerSearch,
        activeLearningItem,
        suppliers
    } = learningItemsState;

    const [searchTerm, setSearchTerm] = useState("")
    const [hasDoneInitialLearningItemsFetch, setHasDoneInitialLearningItemsFetch] = useState(false)
    const [isLoadingLearningItems, setIsLoadingLearningItems] = useState(false)

    const onTabCardClick = (tab) => {

        setLearningItemsState(useLmsStore, {
            activeTab: tab
        })

    }

    const onSearchClick = () => {
        setLearningItemsState(useLmsStore, {
            learningItemsCount: 0,
            learningItems: [],
            searchLearningItemsPayload: { ...searchLearningItemsInitialPayload, search: searchTerm },
            triggerSearch: triggerSearch + 1
        })
    }

    const activeTabMapperElearning = {
        [learningItemsTabEnum.Images]: () => <ImagesTab />,
        [learningItemsTabEnum.ELearning]: () => <ELearningTab dueDates={dueDates} />,
        [learningItemsTabEnum.Synopsis]: () => <LearningItemSynopsisTab />,
        [learningItemsTabEnum.Detail]: () => <LearningItemDetailTab />,
    }

    const activeTabMapperCourse = {
        [learningItemsTabEnum.Images]: () => <ImagesTab />,
        [learningItemsTabEnum.Synopsis]: () => <LearningItemSynopsisTab />,
        [learningItemsTabEnum.Detail]: () => <LearningItemDetailTab />,
        [learningItemsTabEnum.Assessments]: () => <AssessmentsTab />,
    }

    const courseTypeToActiveTabMapperMapper = {
        [learningItemTypesIdsEnum.Elearning]: activeTabMapperElearning,
        [learningItemTypesIdsEnum.Course]: activeTabMapperCourse,
        [learningItemTypesIdsEnum.Audio]: activeTabMapperElearning,
        [learningItemTypesIdsEnum.Video]: activeTabMapperElearning,
        [learningItemTypesIdsEnum.ManualIntervention]: activeTabMapperElearning,
        [learningItemTypesIdsEnum.ReferenceMaterial]: activeTabMapperElearning,
    }

    const searchLearningItems = async () => {
        try {
            setIsLoadingLearningItems(true)
            const result = await apiAxiosCalls.searchLearningItems(
                {
                    ...searchLearningItemsPayload,
                    includeInactive: inactiveItems,
                    filter: {
                        courseType: courseTypeValue,
                        deliveryMethod: delMethodValue
                    }
                }
            )
            setHasDoneInitialLearningItemsFetch(true)
            setLearningItemsState(useLmsStore, {
                learningItemsCount: result.data.count,
                learningItems: [...result.data.items],
                activeLearningItem: result.data.items.length ? result.data.items[0] : null
            })

        } catch (e) {
            console.log(e)
        }
        setIsLoadingLearningItems(false)
    }


    const renderActiveTab = () => {
        if (!activeLearningItem) {
            return () => <></>
        }

        let fn = courseTypeToActiveTabMapperMapper[activeLearningItem.typeId][activeTab];
        if (!fn) {
            setLearningItemsState(useLmsStore, { activeTab: 'Detail'});
            fn = courseTypeToActiveTabMapperMapper[activeLearningItem.typeId]['Detail'];
        }

        return fn;
    }

    const getLearningItemDetail = async () => {
        try {
            setLearningItemsState(useLmsStore, {
                activeLearningItemDetail: null,
                loadingActiveLearningDetail: true
            })
           
            apiAxiosCalls.getLearningItemDetail(activeLearningItem.applicationLearningItemid)
            .then((data) => {
                setLearningItemsState(useLmsStore, {
                    activeLearningItemDetail: data.data.Value,
                    editLearningItemState: data.data.Value,
                    loadingActiveLearningDetail: false
                })
                apiAxiosCalls.getElearningDueDate(data.data.Value.Id)
                .then((dd) => {
                    setDueDatesState(useLmsStore, { elearningDueDateState: dd.data.value })
                })
            })
            
        } catch (e) {
            setLearningItemsState(useLmsStore, {
                activeLearningItemDetail: null,
                loadingActiveLearningDetail: false
            })
        }
    }

    const getSuppliers = async () => {
        try {
            setLearningItemsState(useLmsStore, {
                loadingSuppliers: true
            })
            const { data } = await apiAxiosCalls.getSuppliers()

            setLearningItemsState(useLmsStore, {
                suppliers: data.Value.Items,
                loadingSuppliers: false
            })
        } catch (e) {
            setLearningItemsState(useLmsStore, {
                suppliers: null,
                loadingSuppliers: false
            })
        }
    }

    const renderLearningItems = () => {
        return <div
            style={{ maxHeight: "600px" }}
            className={"w-100 d-flex flex-column align-items-center overflow-auto"}
        >
            {learningItems.map((learningItem, index) => {
                return <LearningItemSingleSearchResult key={`${learningItem.id}${index}`} learningItem={learningItem} />
            })}


            {isLoadingLearningItems && <div className={"w-100 d-flex justify-content-center align-items-center"}>
                <div className="spinner-border text-primary" style={{ width: '5rem', height: '5rem' }} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}

        </div>
    }
    const renderCards = () => {
        return <div className={"mt-1 row gap-1 p-1"}>
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Detail)
            }} title={learningItemsCardNames.Detail} isActive={activeTab === learningItemsTabEnum.Detail} />
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.ELearning)
            }} title={learningItemsCardNames.ELearning} isActive={activeTab === learningItemsTabEnum.ELearning} />
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Images)
            }} title={learningItemsCardNames.Images} isActive={activeTab === learningItemsTabEnum.Images} />
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Synopsis)
            }} title={learningItemsCardNames.Synopsis} isActive={activeTab === learningItemsTabEnum.Synopsis} />
        </div>
    }

    const renderCardsCourse = () => {
        return <div className={"mt-1 row gap-1 p-1"}>
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Detail)
            }} title={learningItemsCardNames.Detail} isActive={activeTab === learningItemsTabEnum.Detail} />
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Images)
            }} title={learningItemsCardNames.Images} isActive={activeTab === learningItemsTabEnum.Images} />
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Synopsis)
            }} title={learningItemsCardNames.Synopsis} isActive={activeTab === learningItemsTabEnum.Synopsis} />
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Assessments)
            }} title={learningItemsCardNames.Assessments} isActive={activeTab === learningItemsTabEnum.Assessments} />
        </div>
    }

    const renderCardsAudio = () => {
        return <div className={"mt-1 row gap-1 p-1"}>
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Detail)
            }} title={learningItemsCardNames.Detail} isActive={activeTab === learningItemsTabEnum.Detail} />
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Images)
            }} title={learningItemsCardNames.Images} isActive={activeTab === learningItemsTabEnum.Images} />
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Synopsis)
            }} title={learningItemsCardNames.Synopsis} isActive={activeTab === learningItemsTabEnum.Synopsis} />
        </div>
    }

    const renderCardsVideo = () => {
        return <div className={"mt-1 row gap-1 p-1"}>
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Detail)
            }} title={learningItemsCardNames.Detail} isActive={activeTab === learningItemsTabEnum.Detail} />
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Images)
            }} title={learningItemsCardNames.Images} isActive={activeTab === learningItemsTabEnum.Images} />
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Synopsis)
            }} title={learningItemsCardNames.Synopsis} isActive={activeTab === learningItemsTabEnum.Synopsis} />
        </div>
    }

    const renderCardsReferenceMaterial = () => {
        return <div className={"mt-1 row gap-1 p-1"}>
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Detail)
            }} title={learningItemsCardNames.Detail} isActive={activeTab === learningItemsTabEnum.Detail} />
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Images)
            }} title={learningItemsCardNames.Images} isActive={activeTab === learningItemsTabEnum.Images} />
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Synopsis)
            }} title={learningItemsCardNames.Synopsis} isActive={activeTab === learningItemsTabEnum.Synopsis} />
        </div>
    }

    const renderCreationModeCards = () => {
        return <div className={"mt-1 row gap-1 p-1"}>
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Detail)
            }} title={learningItemsCardNames.Detail} isActive={activeTab === learningItemsTabEnum.Detail} />
        </div>
    }

    const courseTypeToRenderCardsMapper = {
        [learningItemTypesIdsEnum.Elearning]: renderCards,
        [learningItemTypesIdsEnum.Course]: renderCardsCourse,
        [learningItemTypesIdsEnum.Audio]: renderCardsAudio,
        [learningItemTypesIdsEnum.Video]: renderCardsVideo,
        [learningItemTypesIdsEnum.ReferenceMaterial]: renderCardsReferenceMaterial,
    }

    const renderCardsForActiveCourse = () => {
        if (activeLearningItem?.AreWeInCreationMode) {
            return renderCreationModeCards()
        }
        return activeLearningItem ?
            Object.hasOwn(courseTypeToRenderCardsMapper, activeLearningItem.typeId)
                ? courseTypeToRenderCardsMapper[activeLearningItem.typeId]() :
                <></>
            : <></>
    }

    const onCreateNewClick = () => {
        setLearningItemsState(useLmsStore, {
            activeLearningItem: {
                Id: 0,
                TypeId: 3,
                AreWeInCreationMode: true,
                DeliveryMethodId: -1
            },
            activeTab: learningItemsTabEnum.Detail
        })
    }

    const renderCreateNewGroupButton = () => {
        return <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
            overlay={
                <Tooltip className='cls-theme-tooltip'>Add</Tooltip>
            }
        >
            <Button variant='clear' className='pt-0 pb-0' onClick={() => onCreateNewClick()}>
                <FontAwesomeIcon size='xl' icon={faPlus} />
            </Button>
        </OverlayTrigger>

    }

    const [courseTypeValue, setCourseTypeValue] = useState(0);
    const [devTypeValue, setDevTypeValue] = useState(0);
    const [delMethodValue, setDelMethodValue] = useState(0);

    const courseTypeHandler = (data) => {
        setCourseTypeValue(data);
    }

    const devTypeHandler = (data) => {
        setDevTypeValue(data);
    }

    const delMethodHandler = (data) => {
        setDelMethodValue(data);
    }

    const renderFilters = () => {
        return <div className="w-100 mb-1 mt-1 d-flex align-items-center">
            <div className="w-32">
                <CourseTypeFilter onData={courseTypeHandler} data={courseTypesState.data} />
            </div>
            {/* <div className="w-32">
                <DevelopmentTypeFilter onData={devTypeHandler} />
            </div> */}
            <div className="w-32">
                <DeliveryMethodFilter onData={delMethodHandler} data={deliveryMethodState.data} />
            </div>
        </div>
    }

    const onSearchClear = () => {
        if (!searchTerm) {
            return
        }
        setSearchTerm("")
        setLearningItemsState(useLmsStore, {
            searchLearningItemsPayload: {
                ...searchLearningItemsInitialPayload, search: ""
            },
            learningItemsCount: 0,
            learningItems: [],
            triggerSearch: triggerSearch + 1,
            activeLearningItem: null
        })
    }

    const onSearchSubmit = async () => {
        setLearningItemsState(useLmsStore, {
            searchLearningItemsPayload: {
                ...searchLearningItemsInitialPayload, search: searchTerm
            },
            learningItemsCount: 0,
            learningItems: [],
            triggerSearch: triggerSearch + 1,
            activeLearningItem: null
        })
    }
    const onSearchChange = (e) => {
        setSearchTerm(e.target.value)
    }

    const renderSkeletonsForLearningItems = () => {
        return Array(10).fill("x").map((x, i) => {
            return <Skeleton key={i} variant="rectangular" style={{ marginBottom: "1rem" }} width={"100%"} height={60} />
        })
    }

    useEffect(() => {

        if (activeLearningItem) {
            if (!activeLearningItem.AreWeInCreationMode) {
                getLearningItemDetail()
            } else {
                setLearningItemsState(useLmsStore, {
                    activeLearningItemDetail: {},
                    loadingActiveLearningDetail: false
                })
            }
        }
        if (!suppliers) {
            getSuppliers()
        }
    }, [activeLearningItem])

    useEffect(() => {
        searchLearningItems()
    }, [inactiveItems, delMethodValue, courseTypeValue])

    useEffect(() => {
        if (triggerSearch) {
            searchLearningItems()
        }
    }, [triggerSearch])

    useEffect(() => {
        return () => {
            setLearningItemsState(useLmsStore, {
                searchLearningItemsPayload: {
                    ...searchLearningItemsInitialPayload,
                },
                learningItemsCount: 0,
                learningItems: [],
                triggerSearch: 1,
                activeLearningItem: null,
                activeTab: learningItemsTabEnum.Detail
            })
        }
    }, [])
    const currentPage = searchLearningItemsPayload.itemOffset ? (searchLearningItemsPayload.itemOffset / searchLearningItemsPayload.itemCount) : 0

    const onInactiveChange = () => {
        setInactiveItems(!inactiveItems);
        console.log(inactiveItems);
    }

    return (
        <TableRowSplit
            leftPane={<Container fluid className='h-100 d-flex flex-column overflow-hidden'>
                <div className="w-100 mt-2 mx-2 form-check form-switch">
                    <input type="checkbox" id="isPublished" className="form-check-input" onChange={onInactiveChange} />
                    <label title="" htmlFor="isPublished" className="form-check-label">Include Inactive</label>
                </div>

                <div className={"w-100 mb-1 mt-4 d-flex align-items-center justify-content-end py-2"}>
                    <div className={"w-80"}>
                        <LearningItemsSearch onSearchSubmit={onSearchSubmit} value={searchTerm}
                            onSearchClear={onSearchClear} onChange={onSearchChange} />
                    </div>
                    <div className={"w-20"}>
                        {
                            hasPermission('li.add') === true ?
                                renderCreateNewGroupButton() : ''}
                    </div>
                </div>
                {renderFilters()}
                {(!hasDoneInitialLearningItemsFetch || (isLoadingLearningItems)) ? renderSkeletonsForLearningItems() : renderLearningItems()}
                <TablePagination
                    component='div'
                    sx={{
                        borderBottom: "none",
                        padding: '0 !important',
                        margin: '0 !important'
                    }}
                    classes={{
                        displayedRows: 'tablepagination-displayedRows',
                        root: 'tablepagination-root',
                        selectLabel: 'tablepagination-selectLabel',
                        toolbar: 'tablepagination-toolbar'
                    }}
                    onPageChange={(_, page) => {
                        setLearningItemsState(useLmsStore, {
                            searchLearningItemsPayload: {
                                learningItems: [],
                                ...searchLearningItemsPayload,
                                itemOffset: page * searchLearningItemsPayload.itemCount
                            },
                            activeLearningItem: null,
                            triggerSearch: triggerSearch + 1,

                        })
                    }}
                    onRowsPerPageChange={(e) => {
                        setLearningItemsState(useLmsStore, {
                            learningItems: [],
                            searchLearningItemsPayload: {
                                ...searchLearningItemsPayload,
                                itemOffset: 0,
                                itemCount: e.target.value
                            },
                            activeLearningItem: null,

                            triggerSearch: triggerSearch + 1,

                        })
                    }}
                    page={currentPage}
                    count={learningItemsCount}
                    rowsPerPage={searchLearningItemsPayload.itemCount}
                    rowsPerPageOptions={[defaultPageSize, 20, 30, 40, 50]}
                    showFirstButton
                    showLastButton
                    disabled={isLoadingLearningItems}
                />
            </Container>}
            rightPane={<Container fluid className='h-100 overflow-auto'>
                {renderCardsForActiveCourse()}
                {renderActiveTab()()}
            </Container>}
        />
    );
};


export default function LearningItemsPage(props) {
    return (
        <BaseContainer title='Learning items'>
            <LearningItemsCore />
        </BaseContainer>
    );
}