import { useState, useMemo, useCallback, useReducer, useRef } from 'react';
import { Row, Button, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { TableContainer } from '../helpers/tableHelper';
import MaterialReactTable from 'material-react-table';
import ModalGroupsSelect from './ModalGroupsSelect';


const GroupSelect = ({addFn = (obj) => null,removeFn = (obj) => null, selectedGroups, allGroups}) => {
const [showModal, setShowModal] = useState(false);
const [assignedGroupsS, setAssignedGroupsS] = useState(selectedGroups);
const [apply, setApply] = useState(false);
const [groupToRemove, setGroupToRemove] = useState(null);
const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false); 
const toggleModalGroup = () => {
        setShowModal(!showModal);
    }   

const handleRemove = (row) => {
   
    setGroupToRemove(row);
    setShowConfirmRemoveModal(true);
}
    const handleAddGroup = () => {
   
        setShowModal(true);
    }
    

    //Add the selected list of new groupvto assign to the required list to update both database and screen
  const addGroupFn = (objs) => {
   
    let assignedGroupsCopy = (assignedGroupsS.length>0? structuredClone(assignedGroupsS):[]); 
    let objsC = objs;
 
    //Add selected group/groups in the new list thst would be used later to save in database
     for (const g of objsC){

        const item = (element) => element.id === g.id;

         //Check if exist in the assign group list
        let idx = assignedGroupsCopy.findIndex(item);
         if (idx<0){

        assignedGroupsCopy.push({
            groupId: g.id,
            name:g.name
          });   
        }
         
        setAssignedGroupsS([...assignedGroupsCopy]);

        //Set if it is dirty
       //updateIsDirty();
   
       toggleModalGroup(); 
       addFn(assignedGroupsCopy);
    }
}

    const cancelRemove = () => {
        setShowConfirmRemoveModal(false);
    };

    const confirmRemove = () => {
        if (groupToRemove) {
            setApply(true);
            const index =  assignedGroupsS.findIndex((item) => item.groupId === groupToRemove.groupId);
           
            if (index !== -1) {
                 assignedGroupsS.splice(index, 1);
                
                setAssignedGroupsS([...assignedGroupsS]);
                removeFn(assignedGroupsS);
            }          
            setShowModal(false);
            setShowConfirmRemoveModal(false);         
        }

        
    };

    const columnsGroup = useMemo(
        () => [            
            {
                accessorKey: "name",
                header: "Group"
            },           
            {
                accessorKey: 'id',
                header: 'Actions',
                muiTableBodyCellProps: {
                    align: 'center',
                    sx: {
                        textOverflow: 'unset'
                    }
                },
                size: 25,
                Cell: ({ cell, row }) => {
                    return (<>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Unassign person</Tooltip>}>
                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleRemove(row.original)}>
                                <FontAwesomeIcon size='xl' icon={faTrash} className='text-primary' />
                            </Button>
                        </OverlayTrigger>
               
                    </>)
                }
            } 
        ],
        [],
    );


    return (
        <>
         <h5>Assigned Groups</h5>
            <Row>
            <TableContainer>
              <MaterialReactTable
                columns={columnsGroup}
                autoResetPageIndex={false}
                data={assignedGroupsS}
                enableSorting={true}
                enablePagination={assignedGroupsS? (assignedGroupsS.length>10?true:false):false}
                initialState={{
                    pagination: { pageIndex: 0, pageSize: 10 },
                    showGlobalFilter: true
                }}            
                muiSearchTextFieldProps={{
                  placeholder: `search assigned`,                 
                }}
                renderToolbarInternalActions={({ table }) => (
                    <>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Assign a new group</Tooltip>}>
                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleAddGroup()}>
                                Add
                            </Button>
                        </OverlayTrigger>
                        
                    </>
                )}  
             muiTableHeadProps={{
                            sx: {
                                display: 'none'
                            }
                        }}
              muiToolbarAlertBannerProps={{
                            sx: {
                                display: 'none'
                            }
                        }}

            
            />
            </TableContainer>
            </Row>
            <ModalGroupsSelect allGroups={allGroups} selectedData={assignedGroupsS} closeFn={toggleModalGroup} open={showModal} submitFn={(obj) =>addGroupFn(obj)}></ModalGroupsSelect>
            <Modal show={showConfirmRemoveModal} onHide={cancelRemove}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Remove</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to remove this group?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancelRemove}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={confirmRemove}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    );
};

export default GroupSelect;