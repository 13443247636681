import React, {  useEffect } from "react";
import { useAuth } from "./hooks/useAuth";
import { useSearchParams } from "react-router-dom";
import { getValidToken } from "../helpers/validateHelper";

export default function Saml() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { login } = useAuth();
    useEffect(() => {
        const totenStr = searchParams.get("token");
        const token = getValidToken();
        if(token !== totenStr && totenStr){
            login(totenStr);
        } 
        window.location.href = '/lms/dashboard';
    }, []);

    return (
        <></>
    );
}