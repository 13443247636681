import ModalEventUKPN from '../ModalEventUKPN';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { canShowStart, isAttended, isCompleted, isConfirmed, isPathway } from './learningPlanDataHelpers';
import { commonService } from '../../services/common/common.service';
import { faCirclePlay, faCalendarLinesPen, faChevronsUp, faChevronsDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react';

const LearningPlanActionCellUKPN = ({ handleStart, row, toggleModalElearningRefresher, onChangeState, openModal, setRowPwSelection }) => {
    const [statusId, setStatusId] = useState(row.original.statusId);
    const [isOpenEventUKPN, setisOpenEventUKPN] = useState(false);
    const [changeState, setChangeState] = useState(0);
    const onCloseModalEventUKPN = () => {
        setisOpenEventUKPN(false);
        setChangeState(changeState + 1);
        onChangeState(changeState);
        if (commonService.NotifyBookCourseEvent) {
            setStatusId(1);
        }
    }

    const handleWaitingDates = (rowData) => {
        setisOpenEventUKPN(true);
    }

    return (
        <>
            {
                canShowStart(row.original, true)
                    ?
                    row.original.learningItemTypeId === 2 ?
                        (
                            ((row.original.statusId == 15) &&
                                (
                                    <div>&nbsp;</div>
                                )
                                ||
                                (row.original.statusId == 8) &&
                                (
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Request Dates</Tooltip>}>
                                        <Button variant='clear' className='pt-0 pb-0' onClick={() => handleWaitingDates(row.original)}>
                                            <FontAwesomeIcon size='xl' icon={faCalendarLinesPen} className='fa-fw' />
                                            <span className='m-2 text-body'>Book </span>
                                        </Button>
                                    </OverlayTrigger>
                                )
                            )
                            ||
                            (!isAttended(row.original) &&
                                (row.original.statusId != 15 && row.original.statusId != 8 && !isConfirmed(row.original)) ?
                                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                                    overlay={< Tooltip className='cls-theme-tooltip' >Book {row.original.name}</Tooltip >}>
                                    <Button variant='clear' onClick={() => handleStart(row.original)}>
                                        <FontAwesomeIcon size='xl' icon={faCirclePlay} className='text-primary' /><span className='m-2 text-body'>Book</span>
                                    </Button>
                                </OverlayTrigger>
                                : <></>
                            ))
                        :
                        canShowStart(row.original, true) &&
                            (row.original.statusId != 15) &&
                            row.original.learningItemTypeId === 3 &&
                            (row.original.isOpen) &&
                            row.original.isSelected &&
                            !isCompleted(row.original) ?
                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                                overlay={< Tooltip className='cls-theme-tooltip'
                                > {(isCompleted(row.original) ? 'Review ' : 'Start ') + row.original.name}</Tooltip >}>
                                <Button variant='clear' onClick={() => toggleModalElearningRefresher(row.original.learningItemId)}>
                                    <FontAwesomeIcon size='xl' icon={faCirclePlay} className='text-primary' /><span className='m-2 text-body'>Refresh</span>
                                </Button>
                            </OverlayTrigger>
                            :
                            <>
                                {!isPathway(row.original) ?
                                    canShowStart(row.original, true) && !row.original.isSelected && (row.original.statusId != 15) ?
                                        < OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                                            overlay={< Tooltip className='cls-theme-tooltip' > {(isCompleted(row.original) ? 'Review ' : 'Start ') + row.original.name}</Tooltip >}>
                                            <Button variant='clear' onClick={() => isCompleted(row.original) ? openModal(row.original) : handleStart(row.original)}>
                                                <FontAwesomeIcon size='xl' icon={faCirclePlay} className='text-primary' /><span className='m-2 text-body'>{isCompleted(row.original) ? 'Review ' : 'Start '} </span>
                                            </Button>
                                        </OverlayTrigger >
                                        : ''
                                    :
                                    row.original.isSelected && (row.original.statusId != 15) ?
                                        <Button variant="clear" disabled>
                                            <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                                        </Button>
                                        :
                                        <Button variant='clear'
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => setRowPwSelection(row.original)}>
                                            <FontAwesomeIcon size='xl' icon={row.original.isOpen ? faChevronsUp : faChevronsDown} className='text-primary' /><span className='m-2 text-body'>{row.original.isOpen ? 'Close' : 'Open'}</span>
                                        </Button>
                                }
                            </>
                    :
                    row.original.IsPathway ? 'Expand' : ''
            }
            {
                isOpenEventUKPN ?
                    <ModalEventUKPN closeFn={onCloseModalEventUKPN} open={isOpenEventUKPN} refLearningItemId={row.original.learningItemId} refLearningItemData={{ 'name': row.original.name, 'code': row.original.code }} /> : ''
            }
        </>
    )
};

export default LearningPlanActionCellUKPN;