import BaseContainer from "../../components/BaseContainer";
import CompetencyUserView from '../../pages/competency-management/components/competency-user-view';
import MaterialReactTable from 'material-react-table';
import shallow from 'zustand/shallow';
import useLmsStore from "../../hooks/useLmsStore";
import { TableRowSplit } from '../../helpers/tableHelper'
import { useEffect, useMemo, useState } from 'react';
import { apiAxiosCalls } from "../../services/apiAxiosCalls";
import { userService } from '../../services/common/user.service';

function CompetencyManagementCore({ dimensions })
{
    const [actionType, setActionType] = useState(0);
    const [rowSelection, setRowSelection] = useState({});
    const [dataSelected, setDataSelected] = useState(false);
    const [users, setUsers] = useState([]);
    const [userView, setUserView] = useState();
    const [loading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [employeeIdSearchString, setEmployeeIdSearchString] = useState(''); // New state for employee ID search
    const [showCompetencyUserView, setShowCompetencyUserView] = useState(false);
    // const [personId, setPersonId] = useState(0);
    const [employeeID, setEmployeeId] = useState(0);
    const { computedMuiTableContainerProps } = dimensions;

    const { personSettings } = useLmsStore(state =>
    ({
        personSettings: state.personSettings.data,
    }), shallow)


    const getFirstLevelStaffList = async (managerId) => {
        try {
            const { data } = await apiAxiosCalls.getFirstLevelStaffList(managerId);

            if (!data.isValid) {
                throw new Error("Error getting staff list");
            }
            setUsers([...data.value.map(x => ({
                personID: x.personID,
                employeeID: x.userId,
                jobTitle: x.jobTitle,
                firstname: x.firstname,
                surname: x.surname,
                fullname: x.fullname,
                emailAddress: x.emailAddress,
                isManager: x.isManager
            }))]);
        }
        catch (error) {
            console.error("Error fetching data:", error);
        }
        finally {
            setLoading(false); // Set loading to false once data fetching is done
        }
    };

    useEffect(() => 
    {
        if (!users.length && personSettings?.ukpnPersonId) 
        {
            getFirstLevelStaffList(personSettings?.ukpnPersonId);
            userService.UserId = personSettings?.ukpnPersonId;
        }
    },
        [personSettings?.ukpnPersonId]);

    const sortedUsers = useMemo(() => {
        if (users) {
            let sortedUsersList = [...users].sort((a, b) => a.firstname.localeCompare(b.firstname));
            return sortedUsersList;
        }
        return [];
    }, [users]);

    useEffect(() => {
        if (dataSelected) {
            setShowCompetencyUserView(true);
        }
        else {
            setShowCompetencyUserView(false);
        }
    }, [dataSelected])

    useEffect(() => {
        let keys = Object.keys(rowSelection);
        if (keys.length === 1 && rowSelection[keys[0]] === true) {
            let id = (keys[0]);
            if (users && id) {
                let user = users.find(x => x.personID === id);
                if (user) {
                    setUserView(user);
                    // setPersonId(id);
                    setEmployeeId(user.employeeID);
                    setDataSelected(true);
                }
            }
            else {
                // setPersonId(0);
                setEmployeeId(0);
                setDataSelected(false);
            }
        } else {
            setDataSelected(null);
        }
    }, [rowSelection]);

    const onEmployeeIdSearchChange = (newEmployeeIdSearchString) => {
        setEmployeeIdSearchString(newEmployeeIdSearchString);
    };

    const onBackClick = () => {
        setUserView(null)
        setShowCompetencyUserView(false)
        setRowSelection({})
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: "fullname",
                header: "User Data",
                Cell: ({ cell, row }) => {
                    return (<>
                        <span style={{ color: 'gray', fontSize: '18px' }}>
                            <b>
                                {row.original.firstname || ''} {row.original.surname || ''}
                            </b>
                        </span> <br />
                        <b>Employee Id:</b> {row.original.employeeID || 'None'}<br />
                    </>)
                }
            }
        ],
        [],
    );

    return (
        <TableRowSplit 
        leftPane={
                    <MaterialReactTable
                        columns={columns}
                        data={sortedUsers || []}
                        enableBottomToolbar={false}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enableDensityToggle={false}
                        enableFilterMatchHighlighting={true}
                        enableFullScreenToggle={false}
                        enableGlobalFilter={true}
                        enableHiding={false}
                        enablePagination={false}
                        enableStickyHeader={true}
                        enableTopToolbar={true}
                        getRowId={(originalRow) => originalRow.personID}
                        globalFilterFn="contains"//By default is fuzzy
                        initialState={{
                            globalFilter: searchString, //zc - new for custom search
                            showGlobalFilter: true //This displays the seach generic way for the materia react table

                        }}
                        muiTableContainerProps={({ table }) => computedMuiTableContainerProps(table)}
                        muiTableBodyRowProps={({ row }) => ({
                            onClick: () =>
                                setRowSelection((prev) => ({ [row.id]: !prev[row.id] })),
                            hover: true,
                            selected: rowSelection[row.id],
                            sx: {
                                cursor: 'pointer',
                            },
                        })}
                        onGlobalFilterChange={setSearchString} //zc - new for custom search                            
                        onColumnFilterChange={onEmployeeIdSearchChange} // For employee ID search                          
                        state={{
                            setLoading: !users,
                            globalFilter: searchString,//zc - new for custom search
                        }}
                    />
                }
                rightPane={
                    showCompetencyUserView ?
                        <CompetencyUserView
                            key={userView?.personID || 1}
                            onBackClick={onBackClick}
                            userView={userView}
                            selectedManager={{
                                personID: parseInt(userView.personID),
                                employeeID: employeeID,
                                fullname: userView.fullname,
                                parentPersonID: null
                            }}
                            actionType={actionType}
                            setActionType={setActionType}
                            dimensions={dimensions}
                        />
                        :
                        (sortedUsers?.length && !loading && !userView?.personID) ? <div className={"shadow  p-3 mt-3 mr-1 rounded"}> Please select an employee</div> : <></>
                }
                />
    );
}

export default function CompetencyManagement() {
    return (<BaseContainer title='Competency Management'>
        <CompetencyManagementCore />
    </BaseContainer>)
}