import moment from 'moment';
import { isSupportedDate } from '../../helpers/formatHelper';
import { responseData, getCanCancelBooking } from "../../hooks/useLmsStore";

export const DeliveryMethod_ElearningContent = 2;
export const DeliveryMethod_ElearningMixed = 3;
export const DeliveryMethod_ElearningQuiz = 4;
export const DeliveryMethod_Instructor = 1;
export const DeliveryMethod_OntheJob = 13
export const DeliveryMethod_Undefined = -1;
export const DeliveryMethod_WDRElearning = 12;
export const LearningItemStatus_Active = 4;
export const LearningItemStatus_Added = 1;
export const LearningItemStatus_Approved = 3;
export const LearningItemStatus_Attended = 10;
export const LearningItemStatus_Booked = 8;
export const LearningItemStatus_CompleteAll = 6;
export const LearningItemStatus_CompletedColleague = 5;
export const LearningItemStatus_Declined = 7;
export const LearningItemStatus_RequestPending2 = 15
export const LearningItemStatus_RequestPending = 11
export const LearningItemStatus_Undefined = 0;
export const LearningItemStatus_WaitingApproval = 2;
export const LearningItemStatus_WaitingDates = 9;
export const LearningItemType_Audio = 5;
export const LearningItemType_Elearning = 3;
export const LearningItemType_Group = 1;
export const LearningItemType_InstructorLed = 2;
export const LearningItemType_ManualIntervention = 6;
export const LearningItemType_ReferenceMaterial = 8
export const LearningItemType_Video = 4;
export const LearningItemType_Webinar = 7;
export const CourseType_LandD = 1;
export const CourseType_Technical = 2;
export const CourseType_CustomerServices = 3;
export const CourseType_Other = 4;
export const CourseType_Control = 5;
export const CourseType_Dispatch = 6;

export const canCancel = (rowData) => {
    // isPathway = top level
    if (isMandatory(rowData) || isPathway(rowData) || isPastDate(rowData) || isInProgress(rowData)) {
        return false;
    }
    return true;
};
export const canDoBooking = (rowData) => {
    if (isPathwayItem(rowData)) {
        if (rowData.levelOfApproval == 2) {
            if (rowData.statusId == LearningItemStatus_Undefined) {
                return false;
            }
        }
    }
    //Do not show if  the level of approval 3 or 4 and status waiting dates as the booking need to be done by admin
    if ((rowData.levelOfApproval == 3) || (rowData.levelOfApproval == 4)) {
        return false;
    }
    return true;
};
export const canDoElearning = (rowData) => {
    if (rowData) {
        if (rowData.elearningInfo) {
            if (canPreviewElearning(rowData))
                return true;
            //Chekc if there is setting for max numbers of attempts and if yes then 
            if (rowData.elearningInfo.maxAttempts && rowData.elearningInfo.totalAttemptGroups) {
                if (rowData.elearningInfo.maxAttempts <= rowData.elearningInfo.totalAttemptGroups) {
                    return false;
                }
            }
        }
        //If it is elearning, do not show start button in pathway at least correct status
        if (isPathwayItem(rowData)) {
            if (rowData.levelOfApproval == 2) {
                if (rowData.statusId == LearningItemStatus_Undefined || rowData.statusId == LearningItemStatus_RequestPending2) {
                    return false;
                }
            }
        }
    }
    return true;
};
export const canPreviewElearning = (rowData) => rowData && isCompleted(rowData);
export const canShowStart = (rowData, isUKPN) => {
    let result = true;
    switch (rowData.learningItemTypeId) {
        case LearningItemType_InstructorLed:
            if (isUKPN) {
                result = canDoBooking(rowData);
            }
            break;
        case LearningItemType_Elearning:
            result = canDoElearning(rowData);
            console.log(rowData?.name);
            console.log(rowData);
            console.log(`Can start: ${result}`);
            break;
        case LearningItemType_ReferenceMaterial:
            break;
        default:
            break;
    }
    return result;
};
// export const displayBookAction = x => x && !isAttended(x) && !isConfirmed(x) && x.statusId != LearningItemStatus_RequestPending2 && x.statusId != LearningItemStatus_Booked;
// export const displayRequestDateAction = x => x && x.statusId === LearningItemStatus_Booked;
// export const displayRefreshAction = (x, isUKPN) => x && canShowStart(x, isUKPN) && x.statusId != LearningItemStatus_RequestPending2 && (!x.isSelected || (x.learningItemTypeId === LearningItemType_Elearning && x.isOpen));
export const filterComplete = x => x && filterCompleteS(x) && ((!x.isPwParentCompleted && isPathwayItemAsChild(x)) || !isPathwayItemAsChild(x));
export const filterCompleteS = x => x && (isCompleted(x) || isAttended(x));
export const filteredData = (rows, id, isUKPN) => !Array.isArray(rows) ? [] : id < 0 ? rows.filter(x => (id === -1 && filterTodo(x) && !isPathwayItemAsChild(x)) || (id === -2 && isOverdue(x, isUKPN) && !isPathway(x)) || (id === -3 && isMandatory(x)) || (id === -4 && isUpComing(x)) || (id === -5 && x.statusName === 'Waiting dates' && !isPathway(x)) || (id === -6 && filterComplete(x))) : rows.filter(x => filterTodo(x) && !isPathwayItemAsChild(x)).sort().reverse();
export const filteredDataS = (rows, id, isUKPN) => !Array.isArray(rows) ? [] : id < 0 ? rows.filter(x => (id === -1 && filterTodo(x)) || (id === -2 && isOverdue(x, isUKPN) && !isPathway(x)) || (id === -3 && isMandatory(x)) || (id === -4 && isUpComing(x)) || (id === -5 && x.statusName === 'Waiting dates' && !isPathway(x)) || (id === -6 && filterCompleteS(x))) : rows.filter(x => filterTodo(x) && !isPathwayItemAsChild(x)).sort().reverse();
export const filterTodo = x => x && !isCompleted(x) && !isAttended(x) && !isCancelled(x);
export const formatDuration = (duration, durationUnit) => {
    if (duration > 0) {
        let durationResult = (duration > 1 ? 's' : '');
        switch (durationUnit) {
            case 'H':
            case 'E':
            case 'Hours':
            case 'Hour':
                durationResult = duration + ' hour' + durationResult;
                break;
            case 'D':
            case 'Days':
            case 'Day':
                durationResult = duration + ' day' + durationResult;
                break;
            default:
                break;
        }
        return durationResult;
    }
    return '';
};
export const getTableDataWithStats = (data, isUKPN) => {
    const dataStats = {
        todo: 0,
        overdue: 0,
        mandatory: 0,
        completed: 0,
        awaiting: 0,
        upComing: 0,
        count: 0,
        tableData: [],
        eventData: []
    };
    if (Array.isArray(data)) {
        const singlePathwayItems = data.filter(x => Array.isArray(x.children) && x.children.length === 1).map(x => x.children[0]);
        data.forEach(r => {
            if (!isPathway(r)) {
                if (isConfirmed(r)) {
                    dataStats.count++;

                    if (r.statusName!='Request Pending')
                    dataStats.todo++;
                    if (isOverdue(r, isUKPN)) {
                      //  console.log('r', r);
                        dataStats.overdue++;
                    }
                    if (isUpComing(r)) {
                        dataStats.upComing++;
                    }
                    if (r.statusName === 'Waiting dates') {
                        dataStats.awaiting++;
                    }
                    if (r.instructorInfo != null) {
                        getCanCancelBooking(r.instructorInfo.bookingId).then((response) => {
                            let d = responseData(response, []);
                            if (d) {
                                dataStats.eventData.push({ id: r.instructorInfo.bookingId, msg: `Confirmation of your booking on the ${d.eventInfo.name} for the event being held at ${d.eventInfo.venueName}, ${d.eventInfo.venueCity} - Room ${d.eventInfo.roomName} on ${moment(d.eventInfo.startDate).format("DD/MM/YYYY")} between ${d.eventInfo.startTime} and ${d.eventInfo.endTime}` });
                            }
                            dataStats.count--;
                        });
                    }
                } else if (isCompleted(r) || isAttended(r)) {                 
                    dataStats.completed++;
                    console.log(dataStats.completed, r.name);
                } else {
                  
                    if (r.learningItemTypeId != LearningItemType_ReferenceMaterial && !isCancelled(r) && r.statusName!='Request Pending') {
                        dataStats.todo++;
                    }
                    if (isUpComing(r)) {
                        dataStats.upComing++;
                    }
                    if (isOverdue(r, isUKPN)) {
                        dataStats.overdue++;
                    }
                    if (r.statusName === 'Waiting dates') {
                        dataStats.awaiting++;
                    }

                }
                if (isMandatory(r)) {
                    dataStats.mandatory++;
                }
                if (isPathwayItem(r)) {
                    const singlePathwayItem = singlePathwayItems.filter(x => x.regPathwayId === r.regPathwayId);
                    if (singlePathwayItem.length) {
                        dataStats.tableData.push({ ...singlePathwayItem[0], isFlattenedPathway: true });
                    } else {
                        dataStats.tableData.push(r);
                    }
                } else {
                    dataStats.tableData.push(r);
                }
            } else {
                if (!singlePathwayItems.find(x => x.regPathwayId === r.regPathwayId)) {
                    dataStats.tableData.push(r);
                }
            }
        });
    };
    return dataStats;
};
export const isAttended = x => x && (x.statusName === 'Attended' || (x.instructorInfo && x.instructorInfo.statusName === 'Attended'));
export const isBooking = x => x && x.instructorInfo && isConfirmed(x); //ZC - I am hiding at the moment as UKPN is not returning bookingid but we may need to put it back x.instructorInfo.bookingId > 0
export const isCancelled = x => x && (x.statusName === 'Cancelled booking');
export const isCompleted = x => x && (x.statusName === 'Completed' || x.statusName === 'Completed - Colleague' || (x.elearningInfo && x.elearningInfo.completionStatusName === 'Complete' && x.elearningInfo.successStatusName !== 'Failed'));
export const isConfirmed = x => x && (x.statusName === 'Confirmed' || x.statusName === 'Request Pending' || (x.instructorInfo && x.instructorInfo.statusName === 'Confirmed'));
export const isFutureBooking = x => x && isBooking(x) && isFutureDate(x.instructorInfo.startDate);/* && x.instructorInfo.bookingId>0; */
export const isFutureDate = x => (isSupportedDate(x) && (new Date(x) >= new Date()));
export const isInProgress = x => x && x.statusName === 'In progress';
export const isInstructorLed = x => x && x.learningItemTypeId === LearningItemType_InstructorLed;
export const isMandatory = x => x && x.isMandatory && !isPathway(x) && (x.showMandatoryCompleted || (!isCompleted(x) && !isAttended(x) && x.statusName !== 'Request Pending'));
export const isOverdue = (x, elearningTypeOnly) => x && ((elearningTypeOnly && x.learningItemTypeId == LearningItemType_Elearning) || !elearningTypeOnly) && !isCompleted(x) && !isAttended(x) && ((isPastDate(x.expiryDate)) || isPastDate(x.mandatoryExpectedDate) || isPastBooking(x))
export const isPassed = x => x && x.elearningInfo && x.elearningInfo.successStatusName === 'Passed';
export const isPastBooking = x => x && isBooking(x) && isPastDate(x.instructorInfo.startDate);
export const isPastDate = x => (isSupportedDate(x) && (new Date(x) < new Date()));
export const isPathway = x => x && (x.regPathwayId != null && x.learnProgId == null);
export const isPathwayItem = x => x && x.learnProgId != null;
export const isPathwayItemAsChild = x => x && x.learnProgId != null && x.isFlattenedPathway !== true;
export const isUpComing = x => x && filterTodo(x) && ((x.expiryDate !== undefined && x.expiryDate !== null && isFutureDate(x.expiryDate)) || isFutureBooking(x));
export const withSelectedRows = (tableData, selectedRows) => tableData.map(item => { return { ...item, isSelected: selectedRows.some(item2 => item2.isActive && (item2.learningItemId === item.learningItemId)) } });
