import React, {useState} from 'react';
import GenericSubTabContainer from "../../common/GenericSubTabContainer";
import {Button, Spinner} from "react-bootstrap";
import {apiAxiosCalls} from "../../../services/apiAxiosCalls";
import useLmsStore, {toastError, toastSuccess} from "../../../hooks/useLmsStore";

const ElearningRemap = () => {
    const [isRemapping, setIsRemapping] = useState(false)

    const {learningItemsState} = useLmsStore((state) => {
        return {
            learningItemsState: state.learningItemsState
        }
    })

    const {
        activeLearningItem
    } = learningItemsState;

    const onClick = async () => {
        setIsRemapping(true)
        try {
            const {data} = await apiAxiosCalls.remapCourse(activeLearningItem.Id)
            if (!data.IsValid) {
                throw new Error("Error remapping course")
            }

            toastSuccess("Course successfully remapped")
        } catch (e) {
            console.log(e)
            toastError({message: "Error remapping course"})
        }
        setIsRemapping(false)

    }

    return (
        <GenericSubTabContainer title={"eLearning Remapping"}>
            <div className={"pt-3 pb-3"}>
                Please Note: The eLearning course must be uploaded (mapped) for this course
            </div>
            <div>
                <Button
                    type={"submit"}
                    className={"w-100 mb-3   mt-2 text-center"}
                    disabled={isRemapping}
                    onClick={() => {
                        onClick()
                    }}
                >
                     {isRemapping ? <Spinner animation={"border"} size={10} /> : <>Remap</>}


                </Button>
            </div>
        </GenericSubTabContainer>
    );
};

export default ElearningRemap;
