import React from 'react';

const LearningItemTabCard = ({title = "Title", onClick = () => null, isActive}) => {

    const getContainerStyle = () => {
        const style = {
            border: "1.5px solid ", borderColor: "rgba(148,144,144,0.53)", background: "white"
        }

        if (isActive) {
            style.background = "var(--app-bg-filter-active)"
            style.borderColor = "rgba(var(--bs-primary-rgb)"
        } else {

        }

        return style
    }
    return (<div
            role={"button"}
            onClick={() => {
                onClick()
            }}
            style={getContainerStyle()}
            className={"d-flex  justify-content-between align-items-center p-2 cursor-pointer text-white col-sm hover-darken  custom-time-animation rounded "}
        >
            <div  className={"d-flex align-items-center text-secondary"}>
                <div style={{color: isActive ? "rgba(var(--bs-primary-rgb)" : ""}}
                     className={"d-flex align-items-center  "}>{title}</div>
            </div>

        </div>);
};

export default LearningItemTabCard;
