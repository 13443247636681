import { useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { Button, Col, Form, OverlayTrigger, Row, Stack, Tooltip } from 'react-bootstrap';

import { ModalConfirmCancel } from '../ModalConfirmation';

import { columnBlankIfWidth, columnIconLearningItem, columnIfWidth, muiTableBodyCellEditFieldPropsDate, muiTableBodyCellEditFieldPropsCheckboxFn } from '../../helpers/tableHelper';

import { isSupportedDate, toDDsMMsYYString, toISODateString, toISODateTimeString, toLocaleDateString } from '../../helpers/formatHelper';

import { FilterBadgeButton } from '../CustomButtons';

import useLmsStore, { responseData, getPathwayRegItemsDates, postUpdateRegItemsDates } from "../../hooks/useLmsStore";
import shallow from 'zustand/shallow';

import MaterialReactTable from 'material-react-table';
import LearningPathwayEditorUserTableSelect from './LearningPathwayEditorUserTableSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faSquareCheck, faSquareMinus, faChevronsLeft, faChevronsRight } from '@fortawesome/pro-light-svg-icons';

const empty = (tableData) => !tableData || tableData.length === 0;

const filteredData = (rows, id, isUKPN) => !Array.isArray(rows) ? [] : id < 0 ? rows.filter(x => (id === -1 && !isCompleted(x) && !isAttended(x)) || (id === -2 && isOverdue(x, isUKPN)) || (id === -3 && x.isMandatory) || (id === -4 && (isCompleted(x) || isAttended(x)))) : rows;

const isCompleted = x => x && x.statusName === 'Completed';
const isAttended = x => x && x.statusName === 'Attended';

const isOverdue = x => x && !isCompleted(x) && (isSupportedDate(x.expiryDate) && new Date(x.expiryDate) < new Date());

export default function LearningPathwayEditorUserTable(props) {
    const [completedCount, setCompletedCount] = useState(null);
    const [dateToApply, setDateToApply] = useState('');
    const [edit, setEdit] = useState(false);
    const [editMode, setEditMode] = useState('1');
    const [filterId, setFilterId] = useState(-1);
    const [headerClickState, handleHeaderClick] = useReducer(function (state, action) { return { count: state.count + 1, flag: action }; }, { count: 0, flag: 'none' }); // count to ensure a change of headerClickState on every click
    const [mandatoryCount, setMandatoryCount] = useState(null);
    const [overdueCount, setOverdueCount] = useState(null);
    const [showCancelEditModal, setShowCancelEditModal] = useState(false);
    const [showCancelModeModal, setShowCancelModeModal] = useState(0); // Store the edit mode id
    const [tableLoading, setTableLoading] = useState(true);
    const [todoCount, setToDoCount] = useState(null);
    const headerSquareCheckRef = useRef();
    const headerSquareMinusRef = useRef();
    const headerSquareRef = useRef();
    const [reglearningItems, setRegLearningItems] = useState([]);
    const [reglearningItemsOri, setRegLearningItemsOri] = useState([]);
    const [isSelected, setIsSelected] = useState(true);
    const [isDirty, setIsDirty] = useState(false);
 
    useEffect(() => {
        initDataPromise();
  
    }, [props.dataSelected]);

    useEffect(() => {
        setEditMode(edit && !empty(reglearningItems) ? '2' : '1');

    }, [edit]);

    useEffect(() => {
     
            if (editMode !== '0') {
                setFilterId(0);
            }
            if (editMode !== '2') {
                setDateToApply('');
            }

                refreshGlobalInputCheckbox();
       
    }, [editMode]); 

    useEffect(() => {
       
        refreshGlobalInputCheckbox()
   },
       
      [isSelected]);

    useEffect(() => {
            switch(headerClickState.flag){
            case 'all':
                setItems(true,'select');
                break;
            case 'none':
   
                setItems(false,'select');
                break;
        }
       
    }, [headerClickState]);

    const editModeLessThan2 = () => parseInt(editMode) < 2;

  
    const initDataPromise = () => {
       
        if (props.pathway && props.dataSelected){
         getPathwayRegItemsDates(props.pathway.learningProgrammeGroupId,props.dataSelected.id).then((result)=>{
             let data = responseData(result, []);
       
         
            if (data){
            setRegLearningItems(data);
            let reglearningItemsCopy = structuredClone(data);
            setRegLearningItemsOri(reglearningItemsCopy)
            setTableLoading(false);
            }
         } 
         );
        }

    }


    const checkIsDirtyRow = (origDateI, updDateI) => {
        let result = false;
        
                  let origDate = new Date(origDateI);
                  let updDate = new Date(updDateI);
                 
                if (origDate.getDate()!=updDate.getDate() || origDate.getFullYear()!=updDate.getFullYear() || origDate.getMonth()!=updDate.getMonth()){
                //  alert('dirty orig ' + origDate.getDate() + ' ' + updDate.getDate() );
                  result= true;
                 
                }
              
      
              if (result){
           //    alert('set is dirty true');
                  setIsDirty(true);
              return result;
              }
              else{
            //    alert('set is dirty false');
                  setIsDirty(false);
                  return false;
              }
             
          }
      

   

    const isAnySelected = ()=> {

        var items = reglearningItems.filter((a)=> a.isRowSelected==true);
            if (items!=undefined){
          
            if (items.length>0){ 
              
                setIsSelected(true);    
            return true;
            }
            else{
                setIsSelected(false);  
            }
        }
    
       
        return false;
    }
    const setItem = (itemId,value, action) =>{
        let itemsCopy = reglearningItems;  //structuredClone(reglearningItems);
        const origItem =  reglearningItemsOri.find(x => x.learningItemId  === itemId); 
        const item = itemsCopy.find(x => x.learningItemId  === itemId);
    
        //Update list
        if (item){
         switch(action){
            case 'select':
                item.isRowSelected=!item.isRowSelected;            
                break;
            case 'date':
                checkIsDirtyRow(origItem.expiryDate,value);
                item.expiryDate= value;
                break;
         }
         setRegLearningItems([...itemsCopy]);
       
        }   
       

        isAnySelected();

  
      }

      const setItems = (value, action) =>{
        let itemsCopy =  structuredClone(reglearningItems);
     
        for (let i = 0; i <itemsCopy.length; i++) {
 
        //Update list
     
         switch(action){
            case 'select':
                itemsCopy[i].isRowSelected=value;
                   break;
            case 'date':

            if (itemsCopy[i].isRowSelected){
                checkIsDirtyRow(reglearningItemsOri[i].expiryDate,value);
                itemsCopy[i].expiryDate= value;
            }
              break;
        }
        }
      
         setRegLearningItems([...itemsCopy]);
       
         isAnySelected();
      }

    const handleApplyDateToSelected = () => {
     
        if (isSupportedDate(dateToApply)) {
            let date = toISODateTimeString(dateToApply);
             

                setItems(date,'date');
            
        }
    }

    const handleCellClick = (cell, event) => {
        if (cell.column.id === 'isRowSelected') {
           
            setItem(cell.row.original.learningItemId,null,'select');
          
        }
    };

    const handleCancelEdit = () => {
       
        if (isDirty) {
            setShowCancelEditModal(true);
        } else {
            resetToRO();
        }
    }

    const handleCellChanged = (cell, event) => {
      
        // We need to track date and hide changes separately as the server update processes are different
        if (cell.column.id === 'expiryDate') {
            let date =toISODateTimeString(event.target.value);
           
            setItem(cell.row.original.learningItemId,date,'date');        
        }
    };

   
    const handleConfirmCancelEdit = () => {
        setShowCancelEditModal(false);
        resetToRO();
    }

    const handleConfirmMode = () => {
        if (showCancelModeModal > 0) {
            setEditMode(showCancelModeModal);
            setShowCancelModeModal(0);
        }
    }

    const handleConfirmModeCancel = () => {
        setShowCancelModeModal(0);
    }

    const handleEdit = () => {
        setEditMode(2);
      //  setFilterId(0);
        setEdit(true);
    }

    const handleOnSave = () => {

      //  console.log('save props.dataSelected', props.dataSelected);
      //  alert('saving reg pathway ' +  props.dataSelected.regPathwayId);
        postUpdateRegItemsDates(
        useLmsStore,
        {
            personId: props.dataSelected.id,
            pathwayId: props.pathway.learningProgrammeGroupId,
            regPathwayId: props.dataSelected.regPathway,
            registrationItemDates: reglearningItems
        }

        );

  }

    const handleEditModeChange = (event) => {
        if (props.hasUpdates) {
            setShowCancelModeModal(event.target.value);
        } else {
            setEditMode(event.target.value);
        }
    }

    const resetToRO = () => {
        let origItems = structuredClone(reglearningItemsOri);
        setEditMode(1);
        setEdit(false);
        setRegLearningItems(origItems);
        setIsDirty(false);
        setIsSelected(false);
        
        props.onEditUserDateCancelFn('cancel');
       
    }

    const refreshGlobalInputCheckbox = () => {
       // alert('here', isSelected);
        if (headerSquareRef.current && headerSquareCheckRef.current && headerSquareMinusRef.current) {
            headerSquareRef.current.style.display = !isSelected ? 'inline-block' : 'none';
            headerSquareMinusRef.current.style.display = isSelected ? 'inline-block' : 'none';
            headerSquareCheckRef.current.style.display = isSelected ? 'inline-block' : 'none';
        }
    }

    const columnVisibility = () => {
        let editModeLT2 = editModeLessThan2();
        return {
            code: !edit,
            isRowSelected: !editModeLT2,
            expiryDate: editMode !== '3',
            supplierName: editModeLT2,
            statusName: editModeLT2,
            isMandatory: editModeLT2,
            hide: editModeLT2
        }
    }

    const columns = useMemo(() => [
        columnIconLearningItem('Learner', 'xl'),
        {
            accessorFn: (row) => row.isRowSelected,
            id: 'isRowSelected',
            header: 'Select',
            Header: ({ column }) => (
                <>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Select All</Tooltip>}>
                        <FontAwesomeIcon icon={faSquare} style={{ cursor: 'pointer', display: 'none', fontSize: '1rem' }} ref={headerSquareRef} onClick={() => handleHeaderClick('all')} />
                    </OverlayTrigger>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Select All</Tooltip>}>
                        <FontAwesomeIcon icon={faSquareMinus} style={{ cursor: 'pointer', display: 'none', fontSize: '1rem' }} ref={headerSquareMinusRef} onClick={() => handleHeaderClick('none')} />
                    </OverlayTrigger>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Deselect All</Tooltip>}>
                        <FontAwesomeIcon icon={faSquareCheck} style={{ cursor: 'pointer', display: 'none', fontSize: '1rem' }} ref={headerSquareCheckRef} onClick={() => handleHeaderClick('none')} />
                    </OverlayTrigger>
                </>
            ),
            muiTableBodyCellProps: {
                sx: {
                    cursor: 'pointer'
                }
            },
            size: 50,
            Cell: ({ cell, row }) => 
            <>             
            <FontAwesomeIcon icon={row.original.isRowSelected === true ? faSquareCheck : faSquare} style={{ fontSize: '1rem' }} />
            </>          
        }, 
        {
            accessorFn: (row) => row.itemCode,
            id: 'itemCode',
            enableEditing: false,
            header: 'Code',
            size: 150,
            muiTableBodyCellProps: ({ cell, table }) => {
                return {
                    sx: {
                        cursor: 'pointer',
                        fontWeight: cell.row.original.isRowSelected ? 'bold' : 'normal'
                    }
                }
            }
        },
        {
            accessorKey: 'itemName',
            enableEditing: false,
            header: 'Title',
            muiTableBodyCellProps: ({ cell, table }) => {
                let isRowSelectedIsVisible = table.getColumn('isRowSelected').getIsVisible();
                return {
                    classes: { root: 'fw-unset' },   // Flexible width
                    sx: {
                        cursor: isRowSelectedIsVisible ? 'pointer' : 'inherit',
                        fontWeight: cell.row.original.isRowSelected ? 'bold' : 'normal'
                    }
                }
            },
            muiTableHeadCellProps: { classes: { root: 'fw-unset' } } // Flexible width
        },
        {
            accessorFn: (row) => toISODateString(row.expiryDate),
            id: 'expiryDate',
            header: 'Due/Expiry',
            size: 160,
            muiTableBodyCellEditTextFieldProps: { ...muiTableBodyCellEditFieldPropsDate, required: false },
            Cell: ({ cell, row }) => toDDsMMsYYString(row.original.expiryDate)
        },
        columnIfWidth('xl', {
            accessorKey: 'supplier',
            enableEditing: false,
            size: 160,
            header: 'Supplier'
        }),
        columnIfWidth('lg', {
            accessorKey: 'statusName',
            enableEditing: false,
            header: 'Status',
            size: 160
        }),
        columnIfWidth('xl', {
            accessorKey: 'isMandatory',
            enableEditing: false,
            header: 'M/R',
            size: 80,
            Cell: ({ cell, row }) => (row.original.isMandatory ? 'M' : '') + (row.original.isRenewable ? (row.original.isMandatory ? '/R' : 'R') : '')
        }),
        columnBlankIfWidth('xl', 'blankcolumn')
    ], [],);

    return (<Row>
        <Col>
            <MaterialReactTable
                columns={columns}
                data= {filteredData(reglearningItems, filterId)}
                editingMode='table'
                 enableRowSelection ={editMode==='2'}            
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableEditing={edit && editMode === '1'}
                enableFullScreenToggle={false}
                enableGrouping={false}
                enableHiding={false}
                enablePagination={false}
                enableRowVirtualization
                enableSorting={false}
                enableTopToolbar={true}
                muiTableBodyCellEditTextFieldProps={({ cell }) => ({
                    onChange: (event) => {
                        handleCellChanged(cell, event);
                    },
                })}
                muiTableBodyCellProps={({ cell }) => ({
                    onClick: (event) => {
                        handleCellClick(cell, event);
                    },
                })}
                muiTableBodyRowProps={{ hover: false }}
                muiTableContainerProps={{ sx: { maxHeight: 'max(170px, calc(100vh - 477px))' } }}
                renderTopToolbarCustomActions={({ table }) => <Row>
                    {edit ? null :
                        <>
                         <Col xs='auto'>
                            <Button variant="outline-secondary" onClick={handleCancelEdit}>
                                Back
                            </Button>
                        </Col>
                            <Col xs='auto'>
                                <FilterBadgeButton variant={todoCount > 0 ? 'warning' : 'info'} title='To Do' onClick={() => setFilterId(filterId === -1 ? 0 : -1)} active={filterId === -1} disabled={empty(reglearningItems)}>
                                    {todoCount}
                                </FilterBadgeButton>
                            </Col>
                            <Col xs='auto'>
                                <FilterBadgeButton variant={overdueCount > 0 ? 'danger' : 'info'} title='Overdue' onClick={() => setFilterId(filterId === -2 ? 0 : -2)} active={filterId === -2} disabled={empty(reglearningItems)}>
                                    {overdueCount}
                                </FilterBadgeButton>
                            </Col>
                            <Col xs='auto'>
                                <FilterBadgeButton variant={mandatoryCount > 0 ? 'warning' : 'info'} title='Mandatory' onClick={() => setFilterId(filterId === -3 ? 0 : -3)} active={filterId === -3} disabled={empty(reglearningItems)}>
                                    {mandatoryCount}
                                </FilterBadgeButton>
                            </Col>
                            <Col xs='auto'>
                                <FilterBadgeButton variant={completedCount > 0 ? 'success' : 'info'} title='Completed' onClick={() => setFilterId(filterId === -4 ? 0 : -4)} active={filterId === -4} disabled={empty(reglearningItems)}>
                                    {completedCount}
                                </FilterBadgeButton>
                            </Col>
                            <Col xs='auto'>
                                <Button variant="outline-primary" onClick={handleEdit} disabled={!props.dataSelected}>
                                    Edit
                                </Button>
                            </Col>
                        </>}
                    {edit ? <>
                        <Col xs='auto'>
                            <Button variant="outline-secondary" onClick={handleCancelEdit}>
                                Back
                            </Button>
                            </Col>
                        <Col xs='auto' style={{ padding: '0.5rem 0 0 0.75rem' }}>
                            Edit Mode:
                        </Col>
                        <Col xs='auto' style={{ padding: '0 0.75rem 0 0.25rem' }}>
                            <Form.Select
                                disabled={empty(reglearningItems)}
                                value={editMode}
                                onChange={handleEditModeChange}>
                                <option value="1">By Rows</option>
                                <option value="2">Dates</option>                          
                            </Form.Select>
                        </Col>
                        {editMode === '2' ?
                            <><Col xs='auto'>
                                <Form.Control type="date" onChange={(e) => setDateToApply(e.target.value || '')} />
                            </Col>
                                <Col xs='auto'>
                                    <Button type="submit" variant="outline-primary" onClick={handleApplyDateToSelected} disabled={!isSupportedDate(dateToApply) || !isSelected}>
                                        Apply date to selected
                                    </Button>
                                </Col></> : null}
                        <Col xs='auto'>
                            <Button variant="outline-secondary" onClick={handleCancelEdit}>
                                Cancel
                            </Button>
                        </Col>
                        <Col xs='auto'>
                            <Button type="submit" variant="primary" onClick={handleOnSave} disabled={!isDirty} >
                                {tableLoading?
                                    <>
                                    <span className="spinner-border spinner-border-sm mt-1" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                                    Please wait...
                                    </>
                                    :
                                    <>
                                    Save 
                                    </>
                                }
                            </Button>
                        </Col>
                    </> : null}
                </Row>}
                initialState={{
                    showGlobalFilter: true
                }}
                state={{
                    columnVisibility: columnVisibility(),
                    isLoading: tableLoading
                }}
            />
        </Col>
        <ModalConfirmCancel
            showCancelEditModal={showCancelEditModal}
            abortFn={() => setShowCancelEditModal(false)}
            continueFn={handleConfirmCancelEdit}
        />
        <ModalConfirmCancel
            showCancelEditModal={showCancelModeModal > 0}
            abortFn={handleConfirmModeCancel}
            continueFn={handleConfirmMode}
        />
        
    </Row>);
}
