import React, { useEffect, useMemo, useState } from 'react';
import BaseContainer from "../../components/BaseContainer";
import { TableRowSplit } from "../../helpers/tableHelper";
import MaterialReactTable from "material-react-table";
import { apiAxiosCalls } from "../../services/apiAxiosCalls";
import MyTeamTrainingHistoryUserView from "./components/MyTeamTrainingHistoryUserView";
import MyTeamTrainingHistoryCourseView from "./components/MyTeamTrainingHistoryCourseView";
import { catalogService } from "../catalogue-management/services/catalog.service";
import useLmsStore from "../../hooks/useLmsStore";
import shallow from "zustand/shallow";


const ViewTypes = {
    Course: "Course",
    Employee: "Employee",
}
const MyTeamTrainingHistoryCore = ({ dimensions }) => {
    const [searchString, setSearchString] = useState('');
    const [courseSearchString, setCourseSearchString] = useState('');
    const [users, setUsers] = useState([]);
    const [courses, setCourses] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [courseRowSelection, setCourseRowSelection] = useState({});
    const [loading, setLoading] = useState(false);
    const [employeeIdSearchString, setEmployeeIdSearchString] = useState(''); //
    const [courseIdSearchString, setCourseEmployeeIdSearchString] = useState(''); //
    const [selectedEmployee, setSelectedEmploye] = useState(null)
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [showDiv, setShowDiv] = useState(true);
    const [viewType, setViewType] = useState(ViewTypes.Employee)
    const { computedMuiTableContainerProps } = dimensions;

    const { personSettings } = useLmsStore(state =>
    ({
        personSettings: state.personSettings.data,
    }), shallow)

    const sortedUsers = useMemo(() => {
        if (users) {
            let sortedUsersList = [...users].sort((a, b) => a.firstname.localeCompare(b.firstname));
            return sortedUsersList;
        }
        return [];
    }, [users]);

    const onEmployeeIdSearchChange = (newEmployeeIdSearchString) => {
        setEmployeeIdSearchString(newEmployeeIdSearchString);
    };

    const onCourseIdSearchChange = (newCourseIdSearchString) => {
        setCourseEmployeeIdSearchString(newCourseIdSearchString);
    };
    const getStaffListByManager = async (managerId) => {
        try {
            const { data } = await apiAxiosCalls.getStaffListByManager(managerId);

            if (!data.isValid) {
                throw new Error("Error getting staff list");
            }
            setUsers([...data.value.map(x => ({
                ...x
            }))]);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false); // Set loading to false once data fetching is done
        }
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "fullname",
                header: "User Data",
                Cell: ({ cell, row }) => {
                    return (<>
                        <span style={{ color: 'gray', fontSize: '18px' }}>
                            <b>
                                {row.original.firstname || ''} {row.original.surname || ''}
                            </b>
                        </span> <br />
                        <b>Employee Id:</b> {row.original.employeeID || 'None'}<br />
                        {/* <b>Person Id:</b> {row.original.personID || 'None'}<br/> */}
                        <b>Job Title:</b> {row.original.jobTitle || 'None'}<br />
                    </>)
                }
            }
        ],
        [],
    );


    const courseColumns = useMemo(
        () =>
            [
                {
                    accessorKey: 'CourseTitle',
                    header: '',
                    enableGlobalFilter: true,
                    //  size: 100,
                    //Format a number in a cell render
                    Cell: ({ cell, row }) => (
                        <>
                            <span>
                                {row.original.CourseCode + ' - ' + row.original.CourseTitle}
                            </span>
                        </>

                    )
                },

            ],
        [],
    );

    const onOptionChange = (e) => {
        setViewType(e.target.value)
    }

    const renderLeftSearchTableBasedOnViewType = () => {
        return viewType === ViewTypes.Employee ? <MaterialReactTable
            columns={columns}
            //data={withSelectedRows(companies,tableOption, tableInactive) || []}
            data={sortedUsers || []}
            enableBottomToolbar={true}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableDensityToggle={false}
            enableStickyHeader={true}
            enableFullScreenToggle={false}
            enableGrouping={false}
            enableHiding={false}
            enablePagination={true}
            enableSorting={false}
            enableTopToolbar={true}
            enableFilterMatchHighlighting={true}
            globalFilterFn="contains"
            enableGlobalFilter={true}

            getRowId={(originalRow) => originalRow.personID}
            muiTableBodyRowProps={({ row }) => ({
                onClick: () =>
                    setRowSelection((prev) => ({ [row.id]: !prev[row.id] })),
                hover: true,
                selected: rowSelection[row.id],
                sx: {
                    cursor: 'pointer',
                },
            })}
            muiTableContainerProps={({ table }) => computedMuiTableContainerProps(table)}
            onGlobalFilterChange={setSearchString} //zc - new for custom search
            onColumnFilterChange={onEmployeeIdSearchChange} // For employee ID search
            muiTableHeadProps={{
                sx: {
                    display: 'none'
                }
            }}
            muiToolbarAlertBannerProps={{
                sx: {
                    display: 'none'
                }
            }}
            initialState={{
                pagination: { pageIndex: 0, pageSize: 10 },
                globalFilter: searchString, //zc - new for custom search
                showGlobalFilter: true //This displays the seach generic way for the materia react table

            }}
            state={{
                setLoading: !users,
                globalFilter: searchString,//zc - new for custom search
            }}
        /> : <MaterialReactTable
            columns={courseColumns}
            //data={withSelectedRows(companies,tableOption, tableInactive) || []}
            data={courses || []}
            enableBottomToolbar={true}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableDensityToggle={false}
            enableStickyHeader={true}
            enableFullScreenToggle={false}
            enableGrouping={false}
            enableHiding={false}
            enablePagination={true}
            enableSorting={false}
            enableTopToolbar={true}
            enableFilterMatchHighlighting={true}
            globalFilterFn="contains"//By default is fuzzy
            //globalFilterFn={customGlobalFilterFn}
            enableGlobalFilter={true}

            getRowId={(originalRow) => originalRow.CourseID}
            muiTableBodyRowProps={({ row }) => ({
                onClick: () =>
                    setCourseRowSelection((prev) => ({ [row.id]: !prev[row.id] })),
                hover: true,
                selected: courseRowSelection[row.id],
                sx: {
                    cursor: 'pointer',
                },
            })}
            onGlobalFilterChange={setCourseSearchString} //zc - new for custom search
            onColumnFilterChange={onCourseIdSearchChange} // For employee ID search
            muiTableHeadProps={{
                sx: {
                    display: 'none'
                }
            }}
            muiToolbarAlertBannerProps={{
                sx: {
                    display: 'none'
                }
            }}
            initialState={{
                pagination: { pageIndex: 0, pageSize: 10 },
                globalFilter: courseSearchString, //zc - new for custom search
                showGlobalFilter: true //This displays the seach generic way for the materia react table

            }}
            state={{
                setLoading: !courses,
                globalFilter: courseSearchString,//zc - new for custom search
            }}
        />
    }

    const getCourseList = async () => {
        try {
            const data = await catalogService.getCourseList(-1)

            setCourses(data[0])
        } catch (e) {
            console.log(e)
        }

    }


    useEffect(() => {
        if (!users.length && personSettings?.ukpnPersonId) {
            getStaffListByManager(personSettings.ukpnPersonId);
        }
    },
        [personSettings?.ukpnPersonId]);

    useEffect(() => {
        let keys = Object.keys(rowSelection);
        if (keys.length === 1 && rowSelection[keys[0]] === true) {
            let id = (keys[0]);
            const foundUser = users.find(x => x.personID === id)
            if (foundUser) {
                setSelectedEmploye(foundUser)
            }
        }
    }, [rowSelection]);

    useEffect(() => {
        let keys = Object.keys(courseRowSelection);
        if (keys.length === 1 && courseRowSelection[keys[0]] === true) {
            let id = (keys[0]);
            const foundCourse = courses.find(x => {
                return x.CourseID == id
            })
            if (foundCourse) {
                setSelectedCourse(foundCourse)
            }
        }
    }, [courseRowSelection]);

    useEffect(() => {
        setSelectedCourse(null)
        setSelectedEmploye(null)
        if (viewType === ViewTypes.Course) {
            getCourseList()
        }
    }, [viewType])

    return (
        <TableRowSplit
            leftPane={<>
                <div id='leftDiv'
                    style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '3px' }}>Search
                    By:
                </div>
                <div key={`inline-radio`} className="m-1 d-flex"
                    style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <div className="form-check form-check-inline">
                        <input
                            name="group1"
                            type="radio"
                            id="inline-radio-users"
                            className="form-check-input"
                            value={ViewTypes.Employee}
                            defaultChecked
                            onChange={onOptionChange}
                        />
                        <label htmlFor="inline-radio-all" className="form-check-label">Employee</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            name="group1"
                            type="radio"
                            id="inline-radio-date"
                            className="form-check-input"
                            value={ViewTypes.Course}
                            onChange={onOptionChange}
                        />
                        <label htmlFor="inline-radio-suppliers"
                            className="form-check-label">Course</label>
                    </div>
                </div>
                {renderLeftSearchTableBasedOnViewType()}
            </>}
            rightPane={<>
                {(selectedEmployee && viewType === ViewTypes.Employee) &&
                    <MyTeamTrainingHistoryUserView selectedUser={selectedEmployee} dimensions={dimensions} />}
                {(selectedCourse && viewType === ViewTypes.Course) &&
                    <MyTeamTrainingHistoryCourseView selectedCourse={selectedCourse} dimensions={dimensions} />}

                {(!selectedEmployee && viewType === ViewTypes.Employee) &&
                    <div className={"shadow  p-3 mt-3 mr-1 rounded"}> Please Select an Employee</div>}

                {(!selectedCourse && viewType === ViewTypes.Course) &&
                    <div className={"shadow  p-3 mt-3 mr-1 rounded"}> Please Select a Course</div>}
            </>}
        />
    );
};


export default function MyTeamTrainingHistory(props) {
    return (
        <BaseContainer title='My Team Training History' >
            <MyTeamTrainingHistoryCore />
        </BaseContainer>
    );
}