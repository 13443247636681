import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import LinkContainer from '../../_libs/react-router-bootstrap/LinkContainer'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Nav } from 'react-bootstrap';

export function BaseNavItem({ label, icon, iconSize, to, href, isActive }) {
    return (
        (to) ?
            <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{label}</Tooltip>}>
                <Nav.Item>
                    <LinkContainer to={to} isActive={isActive}>
                        <Nav.Link>
                            <FontAwesomeIcon icon={icon} size={iconSize} className='fa-fw' />
                        </Nav.Link>
                    </LinkContainer>
                </Nav.Item>
            </OverlayTrigger>
            :
            <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{label}</Tooltip>}>
                <Nav.Item>
                    <Button variant='clear' target='_blank' href={href}> <FontAwesomeIcon icon={icon} size={iconSize} className='fa-fw' /></Button>
                </Nav.Item>
            </OverlayTrigger>
    );
}

export function BaseNavItemPill({ label, icon, iconSize, to, isActive, count, countVariant, countClass, onClick }) {
    return (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{label}</Tooltip>}>
            <Nav.Item style={{ position: 'relative' }}>
                <LinkContainer to={to} isActive={isActive}>
                    <Nav.Link onClick={onClick != null ? (x) => onClick(x) : null}>
                        <FontAwesomeIcon icon={icon} size={iconSize} className='fa-fw' />
                        {countVariant && count !== null ? <Badge pill bg={countVariant} className={countClass}>{count}</Badge> : null}
                    </Nav.Link>
                </LinkContainer>
            </Nav.Item>
        </OverlayTrigger>
    );
}
