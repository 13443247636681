import BaseNav from './BaseNav';
import { Children, cloneElement } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';

export default function BaseContainerPortrait({ title, children, dimensions }) {
    const { containerHeight, containerWidth } = dimensions;

    const [contentHeight, setContentHeight] = useState(containerHeight - 102)

    const renderChildren = (cHeight, cWidth) => {
        return Children.map(children, (child) => {
            return cloneElement(child, {
                dimensions: { ...dimensions, contentHeight: cHeight, contentWidth: cWidth }
            });
        });
    };

    useEffect(() => {
        setContentHeight(containerHeight - 102);
    }, [containerHeight]);

    // Portrait or isSmall layout
    return (
        <>
            <BaseNav dimensions={{ ...dimensions, contentHeight: contentHeight, contentWidth: containerWidth }} />
            <Col className='justify-content-center p-2' style={{ height: `${contentHeight}px`, overflowX: 'hidden', overflowY: 'auto' }}>
                <Row className='pb-1'>
                    <Col>
                        <h5>{title}</h5>
                    </Col>
                </Row>
                {renderChildren(contentHeight, containerWidth)}
            </Col>
        </>
    );
}
