import { useEffect, useMemo, useState } from 'react';

import { useSearchParams } from "react-router-dom";

import { TableContainer } from '../helpers/tableHelper';

import MaterialReactTable from 'material-react-table';

import BaseContainer from '../components/BaseContainer';
import UserCard from '../components/UserCard';

import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessageExclamation } from '@fortawesome/pro-solid-svg-icons';

import { cellToLocaleDateTimeString } from '../helpers/formatHelper';

export default function Alerts(props) {
    const [userId, setUserId] = useState(0);
    const [dataPersonAlerts, setDataPersonAlerts] = useState([]);
    const [dataPersonAlertsLoading, setDataPersonAlertsLoading] = useState(false);
    const [dataPersonFullInfo, setDataPersonFullInfo] = useState({});
    const [searchParams] = useSearchParams();

    const {
        fetchPersonAlerts,
        fetchPersonFullInfo
    } = useLmsStore(state =>
    ({
        fetchPersonAlerts: state.fetchPersonAlerts,
        fetchPersonFullInfo: state.fetchPersonFullInfo

    }), shallow);

    // on load
    useEffect(() => {
        let id = parseInt(searchParams.get("id"));
        setUserId(id > 0 ? id : 0);
    }, [searchParams]);

    useEffect(() => {
        if (userId > 0) {
            setDataPersonAlertsLoading(true);
            fetchPersonAlerts(userId).then((d) => {
                setDataPersonAlerts(d || []);
                setDataPersonAlertsLoading(false);
            });
            fetchPersonFullInfo(userId).then((d) => setDataPersonFullInfo(d || []));
        }
    }, [userId]);



    const columns = useMemo(
        () => [
            {
                accessorKey: 'alertId',
                header: 'Id',
                muiTableBodyCellProps: {
                    align: 'center',
                    sx: {
                        textOverflow: 'unset'
                    }
                },
                size: 75,
                Cell: ({ cell, row }) => {
                    return (<FontAwesomeIcon icon={faMessageExclamation} className='text-info' size='lg' />)
                }
            },
            {
                accessorKey: 'createdDate',
                header: 'Created Date',
                size: 150,
                Cell: cellToLocaleDateTimeString
            },
            {
                accessorKey: 'title',
                header: 'Title',
                muiTableBodyCellProps: {
                    classes: { root: 'fw-unset' }   // Flexible width
                },
                Cell: ({ cell, row }) => <span dangerouslySetInnerHTML={{ __html: cell.getValue() }} />
            }
        ],
        [],
    );

    return (
        <BaseContainer title='Notifications'>
            <TableContainer>
                <UserCard {...dataPersonFullInfo} displayBackButton={true} />
                <MaterialReactTable
                    columns={columns}
                    data={dataPersonAlerts}
                    enableBottomToolbar={true}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableGrouping={false}
                    enableHiding={false}
                    enablePagination={true}
                    enableSorting={false}
                    enableTopToolbar={true}
                    muiTableBodyRowProps={{ hover: false }}
                    muiTableHeadProps={{
                        sx: {
                            display: 'none'
                        }
                    }}
                    muiTableProps={{
                        sx: {
                            tableLayout: 'fixed',
                        },
                    }}
                    initialState={{
                        showGlobalFilter: true
                    }}
                    state={{ dataPersonAlertsLoading }}
                />
            </TableContainer>
        </BaseContainer>);
}