import {useEffect, useState} from "react";
import {apiAxiosCalls} from "../services/apiAxiosCalls";

export default function useGetAllCourseTypes() {
    const [eLearnCourseTypes, setELearnCourseTypes] = useState([])

    const getTypes = async () => {
        try {
            const {data} = await apiAxiosCalls.getAllCourseTypes()
            if (!data.IsValid) {
                throw new Error("Error getting course types")
            }
            setELearnCourseTypes(data.Value)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (!eLearnCourseTypes.length) {
            getTypes()
        }

    }, [])


    return eLearnCourseTypes
}
