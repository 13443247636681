import MaterialReactTable from 'material-react-table';
import ModalConfirmation from '../../ModalConfirmation';
import ModalElearningContinue from '../ModalElearningContinue';
import ModalElearningRefresher from '../ModalElearningRefresher';
import ModalEvent from '../../ModalEvent';
import ModalEventUKPN from '../../ModalEventUKPN';
import ModalUserEventInfo from '../../ModalUserEventInfo';
import moment from 'moment';
import shallow from 'zustand/shallow';
import useLmsStore, { postResetTrainingPlanOrPathway, postRemoveToPlan, getSynopsis, responseData, getElearningLinks, postCompleteTrainingPlanAndPathwayNew, setMyActiveSortedTrainingPlanNew, updateLearningPlanOpenNew, updatePathwayOpen, getCanCancelBooking, getEventFullInfo, getEventVenueInfo } from "../../../hooks/useLmsStore";
import { WDR_HUB_URL } from "../../../hooks/data";
import UserCard from '../../UserCard';
import { Badge, Button, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { columnIconLearningItem, TableContainer } from '../../../helpers/tableHelper';
import { commonService } from '../../../services/common/common.service';
import { faMemoCircleInfo, faCircleInfo, faCircleCheck, faCirclePlay, faTrash, faCalendarLinesPen, faCircleXmark, faFileArrowDown, faChevronsUp, faChevronsDown, faRecycle } from '@fortawesome/pro-solid-svg-icons';
import { FilterClearButton, FilterBadgeButton } from '../../../components/CustomButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import { isSupportedDate, /*toLocaleDateString,*/toDDsMMsYYString } from '../../../helpers/formatHelper';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { useSignalrConnection } from '../../../hooks/useSignalrConnection';
import { useSignalRHandler } from '../../../hooks/useSignalRHandler';

const canDoElearning = (rowData) => {
    if (rowData) {
        if (rowData.elearningInfo) {
            if (canPreviewElearning(rowData))
                return true;
            //Chekc if there is setting for max numbers of attempts and if yes then 
            if (rowData.elearningInfo.maxAttempts && rowData.elearningInfo.totalAttemptGroups) {
                if (rowData.elearningInfo.maxAttempts <= rowData.elearningInfo.totalAttemptGroups) {
                    return false;
                }
            }
        }
        //If it is elearning, do not show start button in pathway at least correct status
        if (isPathwayItem(rowData)) {
            if (rowData.levelOfApproval == 2) {
                if (rowData.statusId == 0 || rowData.statusId == 15) {
                    return false;
                }
            }
        }
    }
    return true;
}
const canPreviewElearning = (rowData) => rowData && isCompleted(rowData);
const filterComplete = x => x && filterCompleteS(x) && ((!x.isPwParentCompleted && isPathwayItemAsChild(x)) || !isPathwayItemAsChild(x));
const filterCompleteS = x => x && (isCompleted(x) || isAttended(x));
const filteredData = (rows, id, isUKPN) => !Array.isArray(rows) ? [] : id < 0 ? rows.filter(x => (id === -1 && filterTodo(x) && !isPathwayItemAsChild(x)) || (id === -2 && isOverdue(x, isUKPN) && !isPathway(x)) || (id === -3 && isMandatory(x)) || (id === -4 && isUpComing(x)) || (id === -5 && x.statusName === 'Awaiting Dates' && !isPathway(x)) || (id === -6 && filterComplete(x))) : rows.filter(x => filterTodo(x) && !isPathwayItemAsChild(x)).sort().reverse();
const filteredDataS = (rows, id, isUKPN) => !Array.isArray(rows) ? [] : id < 0 ? rows.filter(x => (id === -1 && filterTodo(x)) || (id === -2 && isOverdue(x, isUKPN) && !isPathway(x)) || (id === -3 && isMandatory(x)) || (id === -4 && isUpComing(x)) || (id === -5 && x.statusName === 'Awaiting Dates' && !isPathway(x)) || (id === -6 && filterCompleteS(x))) : rows.filter(x => filterTodo(x) && !isPathwayItemAsChild(x)).sort().reverse();
const filterTodo = x => x && !isCompleted(x) && !isAttended(x) && !isCancelled(x);
const getTableDataWithStats = (data, isUKPN) => {
    const dataStats = {
        todo: 0,
        overdue: 0,
        mandatory: 0,
        completed: 0,
        awaiting: 0,
        upComing: 0,
        count: 0,
        tableData: [],
        eventData: []
    };
    if (Array.isArray(data)) {
        const singlePathwayItems = data.filter(x => Array.isArray(x.children) && x.children.length === 1).map(x => x.children[0]);
        data.forEach(r => {
            if (!isPathway(r)) {
                if (isConfirmed(r)) {
                    dataStats.count++;
                    dataStats.todo++;
                    if (isOverdue(r, isUKPN)) {
                        console.log('r',r);
                        dataStats.overdue++;
                    }
                    if (isUpComing(r)) {
                        dataStats.upComing++;
                    }
                    if (r.instructorInfo != null) {
                        getCanCancelBooking(r.instructorInfo.bookingId).then((response) => {
                            let d = responseData(response, []);
                            if (d) {
                                dataStats.eventData.push({ id: r.instructorInfo.bookingId, msg: `Confirmation of your booking on the ${d.eventInfo.name} for the event being held at ${d.eventInfo.venueName}, ${d.eventInfo.venueCity} - Room ${d.eventInfo.roomName} on ${moment(d.eventInfo.startDate).format("DD/MM/YYYY")} between ${d.eventInfo.startTime} and ${d.eventInfo.endTime}` });
                            }
                            dataStats.count--;
                        });
                    }
                } else if (isCompleted(r) || isAttended(r)) {
                    dataStats.completed++;
                } else {

                    if (r.learningItemTypeId != 8 && !isCancelled(r)) {
                        dataStats.todo++;
                    }
                    if (isUpComing(r)) {
                        dataStats.upComing++;
                    }
                    if (isOverdue(r, isUKPN)) {
                        dataStats.overdue++;
                    }
                    if (r.statusName === 'Awaiting Dates') {
                        dataStats.awaiting++;
                    }
                }
                if (isMandatory(r)) {
                    dataStats.mandatory++;
                }
                if (isPathwayItem(r)) {
                    const singlePathwayItem = singlePathwayItems.filter(x => x.regPathwayId === r.regPathwayId);
                    if (singlePathwayItem.length) {
                        dataStats.tableData.push({ ...singlePathwayItem[0], isFlattenedPathway: true });
                    } else {
                        dataStats.tableData.push(r);
                    }
                } else {
                    dataStats.tableData.push(r);
                }
            } else {
                if (!singlePathwayItems.find(x => x.regPathwayId === r.regPathwayId)) {
                    dataStats.tableData.push(r);
                }
            }
        });
    };
    return dataStats;
}
const hideAggregatedCell = ({ cell, table }) => { return cell.id.startsWith('pwgrpName:') ? { sx: { display: 'none' } } : {} };
const isAttended = x => x && (x.statusName === 'Attended' || (x.instructorInfo && x.instructorInfo.statusName === 'Attended'));
const isBooking = x => x && x.instructorInfo && isConfirmed(x); //ZC - I am hiding at the moment as UKPN is not returning bookingid but we may need to put it back x.instructorInfo.bookingId > 0
const isCancelled = x => x && (x.statusName === 'Cancelled booking');
const isCompleted = x => x && (x.statusName === 'Completed' || x.statusName === 'Completed - Colleague' || (x.elearningInfo && x.elearningInfo.completionStatusName === 'Complete' && x.elearningInfo.successStatusName !== 'Failed'));
const isConfirmed = x => x && (x.statusName === 'Confirmed' || x.statusName === 'Request Pending' || (x.instructorInfo && x.instructorInfo.statusName === 'Confirmed'));
const isFutureBooking = x => x && isBooking(x) && isFutureDate(x.instructorInfo.startDate);/* && x.instructorInfo.bookingId>0; */
const isFutureDate = x => (isSupportedDate(x) && (new Date(x) >= new Date()));
const isMandatory = x => x && x.isMandatory && !isPathway(x) && (x.showMandatoryCompleted || (!isCompleted(x) && !isAttended(x)));
const isOverdue = (x, elearningTypeOnly) => x && ((elearningTypeOnly && x.learningItemTypeId == 3) || !elearningTypeOnly) && !isCompleted(x) && !isAttended(x) && ((isPastDate(x.expiryDate)) || isPastDate(x.mandatoryExpectedDate) || isPastBooking(x))
const isPassed = x => x && x.elearningInfo && x.elearningInfo.successStatusName === 'Passed';
const isPastBooking = x => x && isBooking(x) && isPastDate(x.instructorInfo.startDate);
const isPastDate = x => (isSupportedDate(x) && (new Date(x) < new Date()));
const isPathway = x => x && (x.regPathwayId != null && x.learnProgId == null);
const isPathwayItem = x => x && x.learnProgId != null;
const isPathwayItemAsChild = x => x && x.learnProgId != null && x.isFlattenedPathway !== true;
const isUpComing = x => x && filterTodo(x) && ((x.expiryDate !== undefined && x.expiryDate !== null && isFutureDate(x.expiryDate)) || isFutureBooking(x));

export default function OldLearningPlanTableCore(props) {
    const [awaitingCount, setAwaitingCount] = useState(null);
    const [canHaveNewAttempt, setCanHaveNewAttempt] = useState("");
    const [completedCount, setCompletedCount] = useState(null);
    const [continueLink, setContinueLink] = useState("");
    const [customerId, setCustomerId] = useState(1);
    const [dataActivePlan, setDataActivePlan] = useState(null);
    const [eventData, setEventData] = useState([]);
    const [eventVenueInfo, setEventVenueInfo] = useState(null);
    const [filterId, setFilterId] = useState(-1);
    const [isElearningOpen, setIsElearningOpen] = useState(false);
    const [isElearningOpenId, setIsElearningOpenId] = useState(0);
    const [isOpenElearningContinue, setisOpenElearningContinue] = useState(false);
    const [isOpenElearningRefresher, setisOpenElearningRefresher] = useState(false);
    const [isOpenEvent, setisOpenEvent] = useState(false);
    const [isOpenNewAttemptConfirmation, setisOpenNewAttemptConfirmation] = useState(false);
    const [isOpenResetElearning, setIsOpenResetElearning] = useState(false);
    const [isOpenUserEvent, setIsOpenUserEvent] = useState(false);
    const [mandatoryCount, setMandatoryCount] = useState(null);
    const [newAttemptLink, setNewAttemptLink] = useState("");
    const [openClosePw, setOpenClosePw] = useState(false);
    const [overdueCount, setOverdueCount] = useState(null);
    const [refLearningItem, setRefLearningItem] = useState(-1);
    const [refLearningItemData, setRefLearningItemData] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchString, setSearchString] = useState(decodeURI(searchParams.get("search") || ''));
    const [selectedLearningItem, setSelectedLearningItem] = useState(-1);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showByGroup, setShowByGroup] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showSynopsis, setShowSynopsis] = useState(false);
    const [synopsisSections, setSynopsisSections] = useState([]);
    const [synopsisTitle, setSynopsisTitle] = useState(null);
    const [tabId, setTabId] = useState('-1');
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [tableLoadingPw, setTableLoadingPw] = useState(false);
    const [todoCount, setToDoCount] = useState(null);
    const [trainingPlanId, setTrainingPlanId] = useState(0);
    const [upComingCount, setUpcomingCount] = useState(0);
    const [userEventInfo, setUserEventInfo] = useState(null);
    const [isUKPN, setIsUKPN] = useState(false);

    const { personSettings } = useLmsStore(state =>
    ({
        personSettings: state.personSettings.data
    }), shallow)

    useEffect(() => {
        return () => {
            setCustomFilterData(-1);
            setRefLearningItem(-1);
            setCustomerId(getCustomerIdByTheme());
        };
    }, []);

    useEffect(() => {
        setIsUKPN(personSettings?.theme?.toLowerCase() === 'ukpn')
    }, [personSettings]);

    const getCustomerIdByTheme = () => {
        let currentTheme = personSettings.theme ? personSettings.theme.toLowerCase() : props.theme?.toLowerCase();
        switch (currentTheme) {
            case 'ukpn': return 5; break;
            case 'wdr': return 1; break;
            default: return 1; break;
        }
    }

    useEffect(() => {
        setCustomFilterData(filterId);
        setShowByGroup(tabId !== '0' && (!searchString || searchString === '') && !tableLoading);
    }, [tabId, searchString, tableLoading]);

    // SignalR section start
    const [srConnection, setSRConnection] = useState(null);

    const { hubConnectionState, error } = useSignalrConnection(srConnection);

    useEffect(() => {
        setSRConnection(
            new HubConnectionBuilder()
                .withUrl(WDR_HUB_URL, { skipNegotiations: true, transport: HttpTransportType.ServerSentEvents })
                .withAutomaticReconnect()
                .build()
        )
    }, [setSRConnection]);

    useEffect(() => { if (error) console.error(error); }, [error]);

    useEffect(() => { if (process.env.NODE_ENV === 'development') console.log(`SignalR Connection: ${hubConnectionState}`); }, [hubConnectionState]);

    useSignalRHandler(srConnection, "svr_eLearning_close", (message) => onSrveLearningClose(message));

    useEffect(() => {
        if (isElearningOpenId > 0) {
            updateLearningPlanOpenNew(useLmsStore, isElearningOpenId, isElearningOpen);
        }
    }, [isElearningOpen, isElearningOpenId]);

    const onCloseRow = (learningItemId) => {
        selectedRows.forEach((row) => {
            if (row.learningItemId === learningItemId) {
                row.isActive = false;
                row.isOpen = false;
            }
        });
        setIsElearningOpenId(learningItemId);
        setIsElearningOpen(false);
        setSelectedRows(selectedRows);
    }

    const onSrveLearningClose = (message) => {
        //Check if it is not a preview, so information can be updated. If it is preview, it does not require any update
        if (!message.isPreview) {
            //Update the store with the latest status and elearning info
            if (setMyActiveSortedTrainingPlanNew(useLmsStore, message)) {
                //Check if we need to unlock the items, if it is coming from SAvePlayer or from postaback with a complete status
                if (message.unlockItem) {
                    onCloseRow(message.learningItemId);
                }
            }
        }
        else {
            onCloseRow(message.learningItemId);
        }
        return;
    };

    function toggleModalResetElearning() {
        setIsOpenResetElearning(!isOpenResetElearning);
    }

    function onHandleResetElearning() {
        let userId = props.dataUser.data.personId;
        if (props.dataUser) {
            toggleModalResetElearning();
            //Call method to reset elearning
            postResetTrainingPlanOrPathway(useLmsStore, { id: refLearningItem, personId: userId }).then((result) => {
                //Zc not woking atthe moment
                // props.resetUserElearning(userId);
                //We need to refresh the page
                window.location.reload(false);
                setRefLearningItem(null);
            });
        }
    };

    function handleResetElearning(learningItemId) {
        setRefLearningItem(learningItemId);
        toggleModalResetElearning();
    };

    const renderResetElearning = (rowData) => {
        return (
            rowData.hasRepeatItems !== true && rowData.learningItemTypeId == 3 && props.enableResetElearning && rowData.elearningInfo != undefined && rowData.statusName !== 'Not Started' ?
                <>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                        overlay={< Tooltip className='cls-theme-tooltip' > {(isConfirmed((rowData)) ? 'Cancel ' : 'Book ') + rowData.name}</Tooltip >}>
                        <Button variant='primary px-3 w-0 mx-3' style={{ lineHeight: '1rem' }} className='pt-0 pb-0' onClick={() => handleResetElearning(rowData.learningItemId)}>
                            <FontAwesomeIcon size='xl' icon={faRecycle} /><span>Reset</span>
                        </Button>
                    </OverlayTrigger>
                </> : <></>)
    }

    const formatDuration = (duration, durationUnit) => {
        if (duration > 0) {
            let durationResult = (duration > 1 ? 's' : '');
            switch (durationUnit) {
                case 'H':
                case 'E':
                case 'Hours':
                case 'Hour':
                    durationResult = duration + ' hour' + durationResult;
                    break;
                case 'D':
                case 'Days':
                case 'Day':
                    durationResult = duration + ' day' + durationResult;
                    break;
                default:
                    break;
            }
            return durationResult;
        }
        return '';
    }

    const renderDateColumn = (rowData) => {
        let durationResult = formatDuration(rowData.duration, rowData.durationUnit);
        return (
            <>
                {isCompleted(rowData) ?
                    <>
                        {renderCompletedDateColumn(rowData)}
                    </> :
                    <>
                        {(isSupportedDate(rowData.expiryDate) || isConfirmed(rowData) || isAttended(rowData)) ?
                            <>
                                {isSupportedDate(rowData.expiryDate) ?
                                    <>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Due Date</Tooltip>}>
                                            <span>{toDDsMMsYYString(rowData.expiryDate)} {durationResult != '' ? '(' + durationResult + ')' : ''}</span>
                                        </OverlayTrigger>
                                    </> : <></>}
                            </> : <></>}
                        {renderEventDateColumn(rowData, durationResult)}
                    </>
                }
            </>);
    }

    const renderCompletedDateColumn = (rowData) => {
        return (
            toDDsMMsYYString(rowData.elearningInfo?.dateUpdated) != '' ?
                <>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Completed Date</Tooltip>}>
                        <span>
                            {toDDsMMsYYString(rowData.elearningInfo?.dateUpdated)}
                        </span>
                    </OverlayTrigger>
                </>
                : rowData.dateCreated != undefined ?
                    <>{
                        toDDsMMsYYString(rowData.dateCompleted) != '' ?
                            <>
                                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Date Completed</Tooltip>}>
                                    <span>{toDDsMMsYYString(rowData.dateCompleted)}</span>
                                </OverlayTrigger>
                            </> :
                            <>
                                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Date Completed</Tooltip>}>
                                    <span>{toDDsMMsYYString(rowData.dateCreated)}</span>
                                </OverlayTrigger>
                            </>
                    }</>
                    : <></>
        )
    }

    const renderEventDateColumn = (rowData, durationResult) => {
        return ((isConfirmed(rowData) || isAttended(rowData)) && rowData.instructorInfo ?
            <>
                {toDDsMMsYYString(rowData.instructorInfo.startDate) != '' ?
                    <>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Event Date</Tooltip>}>
                            <span>{toDDsMMsYYString(rowData.instructorInfo.startDate)} {durationResult != '' ? '(' + durationResult + ')' : ''}</span>
                        </OverlayTrigger>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Event Detail</Tooltip>}>
                            <Button variant='clear' onClick={() => handleOpenUserEventInfo(rowData)}>
                                <FontAwesomeIcon className='text-primary' icon={faMemoCircleInfo} />
                            </Button>
                        </OverlayTrigger>
                    </> :
                    <></>
                }
            </>
            : <></>);
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [modalData, setModalData] = useState(null);

    const openModal = (data) => {
        setIsModalOpen(true);
        setModalData(data);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const columnAbout = {
        accessorKey: 'name',
        header: 'Course',
        muiTableBodyCellProps: ({ cell, table }) => { return cell.id.startsWith('pwgrpName:') ? { sx: { display: 'none' } } : { classes: { root: 'fw-unset' } } },
        Cell: ({ cell, row }) => (
            <>
                <Row>
                    {!isPathway(row.original) ?
                        <>
                            <Col xs="5" >
                                <div className='fw-bold'>{cell.getValue()}</div>
                                {row.original.description ? (row.original.description.length > 190 ? (<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'> {row.original.description}</Tooltip>}>
                                    {<div> {row.original.description.substring(0, 190)} ...</div>}
                                </OverlayTrigger>) : <div>{row.original.description}</div>) : ''
                                }
                            </Col>
                            <Col xs='1'>
                                {row.original.hasSynopsis ? (<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>View Synopsis</Tooltip>}>
                                    <Button variant='clear' className='pt-0 pb-0' onClick={() => handleShowSynopsis(row.original.learningItemId, row.original.name)}>
                                        <FontAwesomeIcon size='xl' icon={faCircleInfo} className='text-primary' />
                                    </Button>
                                </OverlayTrigger>) : ''
                                }
                            </Col>
                            <Col xs='1'>
                                {row.original.synopsisLink != '' && row.original.synopsisLink != undefined ? (<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Course outline</Tooltip>}>
                                    <Button variant='clear' className='pt-0 pb-0' onClick={() => handleOpenSynopsisLink(row.original.synopsisLink)} >
                                        <FontAwesomeIcon size='xl' icon={faFileArrowDown} className='text-primary' />
                                    </Button>
                                </OverlayTrigger>) : ''}
                            </Col>
                            <Col xs='2'>
                                {renderDateColumn(row.original)}
                            </Col>
                            <Col xs='2'>
                                {row.original.statusName}
                            </Col>
                            {row.original.elearningInfo ?
                                <>
                                    <Col xs='1'>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Satisfaction Status - {row.original.elearningInfo.successStatusName} ({toDDsMMsYYString(row.original.elearningInfo.dateUpdated)})</Tooltip>}>
                                            <span>
                                                {row.original.elearningInfo.successStatusName}
                                            </span>
                                        </OverlayTrigger>
                                    </Col>
                                </> : <></>}
                        </>
                        :
                        <>
                            <Col xs="6" >
                                <div className='fw-bold'>{cell.getValue()}</div>
                                {row.original.description ? (row.original.description.length > 190 ? (<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'> {row.original.description}</Tooltip>}>
                                    {<div> {row.original.description.substring(0, 190)} ...</div>}
                                </OverlayTrigger>) : <div>{row.original.description}</div>) : ''
                                }
                            </Col>
                            <Col xs='3'>
                                <>
                                    {!isCompleted(row.original) ?
                                        <>
                                            {row.original.outstandingItems} outstanding item{row.original.outstandingItems > 1 ? 's' : ''}
                                        </> : <></>}
                                </>
                            </Col>
                            <Col xs='1'>
                                {row.original.statusName}
                            </Col>
                        </>}
                </Row>
                <Row className='gx-1' >
                    {isCompleted(row.original) && row.original.learningItemTypeId !== 2 ?
                        <Col xs='auto'>
                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Completed</Tooltip>}>
                                <FontAwesomeIcon size='xl' icon={faCircleCheck} className='text-success' />
                            </OverlayTrigger>
                        </Col> : <></>}
                    {row.original.elearningInfo ?
                        row.original.elearningInfo.totalAttemptGroups > 0 ?
                            <>
                                <Col xs='auto'>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Number of times that a new attempt has been made at this eLearning course.</Tooltip>}>
                                        <Badge bg='secondary'>
                                            Attempt {row.original.elearningInfo.totalAttemptGroups}
                                        </Badge>
                                    </OverlayTrigger>
                                </Col>
                                {row.original.elearningInfo.maxAttempts > 0 ?
                                    <Col xs='auto'>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>The max number of attempts for this course is.</Tooltip>}>
                                            <Badge bg='secondary'>
                                                Max Attempts: {row.original.elearningInfo.maxAttempts}
                                                {row.original.elearningInfo.totalAttemptGroups >= row.original.elearningInfo.maxAttempts ? <span> done</span> : null}
                                            </Badge>
                                        </OverlayTrigger>
                                    </Col> : null}
                                <Col xs='auto'>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Completion Status - {row.original.elearningInfo.completionStatusName} ({toDDsMMsYYString(row.original.elearningInfo.dateUpdated)})</Tooltip>}>
                                        <Badge bg={isCompleted(row.original) ? 'success' : 'danger'}>CS</Badge>
                                    </OverlayTrigger>
                                </Col>
                                {isCompleted(row.original) ?
                                    isPassed(row.original) ?
                                        <Col xs='auto'>
                                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Satisfaction Status - {row.original.elearningInfo.successStatusName} ({toDDsMMsYYString(row.original.elearningInfo.dateUpdated)})</Tooltip>}>
                                                <Badge bg='success'>
                                                    SS
                                                </Badge>
                                            </OverlayTrigger>
                                        </Col> : <></>
                                    :
                                    <Col xs='auto'>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Satisfaction Status - {row.original.elearningInfo.successStatusName} ({toDDsMMsYYString(row.original.elearningInfo.dateUpdated)})</Tooltip>}>
                                            <Badge bg={isPassed(row.original) ? 'success' : 'warning'}>
                                                SS
                                            </Badge>
                                        </OverlayTrigger>
                                    </Col>
                                }
                                <Col xs='auto'>
                                    {renderResetElearning(row.original)}
                                </Col>
                            </> : <></> : <></>}
                    {row.original.isOpen ?
                        <>
                            {
                                row.original.children != null ? <>
                                    <Row className='m-4'>
                                        <MaterialReactTable
                                            columns={tableColumnsPw}
                                            data={withSelectedRows(row.original.children, selectedRows)}
                                            enableBottomToolbar={false}
                                            enableColumnActions={false}
                                            enableColumnFilters={false}
                                            enableDensityToggle={false}
                                            enableFullScreenToggle={false}
                                            enableGlobalFilter={false}
                                            enableGrouping={true}
                                            enableHiding={false}
                                            enablePagination={false}
                                            enableSorting={false}
                                            enableTopToolbar={false}
                                            enableFilters={true}
                                            muiTableProps={{ sx: { borderCollapse: 'collapse' } }}
                                            muiTableBodyRowProps={{ hover: false, sx: { border: '0.5rem solid var(--app-nav-primary)', } }}
                                            muiTableHeadProps={{
                                                sx: {
                                                    display: 'none'
                                                }
                                            }}
                                            muiToolbarAlertBannerProps={{
                                                sx: {
                                                    display: 'none'
                                                }
                                            }}
                                            muiTopToolbarProps={{
                                                sx: {
                                                    display: 'none'
                                                }
                                            }}
                                            state={
                                                {
                                                    expanded: true,
                                                    grouping: ['pwgrpName'],
                                                    isLoading: tableLoadingPw,
                                                }
                                            }
                                        />
                                    </Row>
                                </> : <></>
                            }
                        </>
                        : <></>
                    }
                </Row>
            </>
        )
    };

    const RenderColumnActionUKPN = ({ row, onChangeState }) => {
        const [statusId, setStatusId] = useState(row.original.statusId);
        const [isOpenEventUKPN, setisOpenEventUKPN] = useState(false);
        const [changeState, setChangeState] = useState(0);
        const onCloseModalEventUKPN = () => {
            setisOpenEventUKPN(false);
            setChangeState(changeState + 1);
            onChangeState(changeState);
            if (commonService.NotifyBookCourseEvent) {
                setStatusId(1);
            }
        }
        const handleWaitingDates = (rowData) => {
            setisOpenEventUKPN(true);
        }
        return (
            <>
                {
                    canShowStart(row.original)
                        ?
                        row.original.learningItemTypeId === 2 ?
                            (
                                ((row.original.statusId == 15) &&
                                    (
                                        <div>&nbsp;</div>
                                    )
                                    ||
                                    (row.original.statusId == 8) &&
                                    (
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Request Dates</Tooltip>}>
                                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleWaitingDates(row.original)}>
                                                <FontAwesomeIcon size='xl' icon={faCalendarLinesPen} className='fa-fw' />
                                                <span className='m-2 text-body'>Book </span>
                                            </Button>
                                        </OverlayTrigger>
                                    )
                                )
                                ||
                                (!isAttended(row.original) &&
                                    (row.original.statusId != 15 && row.original.statusId != 8) ?
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                                        overlay={< Tooltip className='cls-theme-tooltip' > {(isConfirmed((row.original)) ? 'Cancel ' : 'Book') + row.original.name}</Tooltip >}>
                                        <Button variant='clear' onClick={() => handleStart(row.original)}>
                                            <FontAwesomeIcon size='xl' icon={isConfirmed(row.original) ? faCircleXmark : faCirclePlay} className='text-primary' /><span className='m-2 text-body'>{isConfirmed(row.original) ? 'Cancel' : 'Book '}</span>
                                        </Button>
                                    </OverlayTrigger>
                                    : <></>
                                ))
                            :
                            canShowStart(row.original) &&
                                (row.original.statusId != 15) &&
                                row.original.learningItemTypeId === 3 &&
                                (row.original.isOpen) &&
                                row.original.isSelected &&
                                !isCompleted(row.original) ?
                                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                                    overlay={< Tooltip className='cls-theme-tooltip'
                                    > {(isCompleted(row.original) ? 'Review ' : 'Start ') + row.original.name}</Tooltip >}>
                                    <Button variant='clear' onClick={() => toggleModalElearningRefresher(row.original.learningItemId)}>
                                        <FontAwesomeIcon size='xl' icon={faCirclePlay} className='text-primary' /><span className='m-2 text-body'>Refresh</span>
                                    </Button>
                                </OverlayTrigger>
                                :
                                <>
                                    {!isPathway(row.original) ?
                                        canShowStart(row.original) && !row.original.isSelected && (row.original.statusId != 15) ?
                                            < OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                                                overlay={< Tooltip className='cls-theme-tooltip' > {(isCompleted(row.original) ? 'Review ' : 'Start ') + row.original.name}</Tooltip >}>
                                                <Button variant='clear' onClick={() => isCompleted(row.original) ? openModal(row.original) : handleStart(row.original)}>
                                                    <FontAwesomeIcon size='xl' icon={faCirclePlay} className='text-primary' /><span className='m-2 text-body'>{isCompleted(row.original) ? 'Review ' : 'Start '} </span>
                                                </Button>
                                            </OverlayTrigger >
                                            : ''
                                        :
                                        row.original.isSelected && (row.original.statusId != 15) ?
                                            <Button variant="clear" disabled>
                                                <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                                            </Button>
                                            :
                                            <Button variant='clear'
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => setRowPwSelection(row.original)}>
                                                <FontAwesomeIcon size='xl' icon={row.original.isOpen ? faChevronsUp : faChevronsDown} className='text-primary' /><span className='m-2 text-body'>{row.original.isOpen ? 'Close' : 'Open'}</span>
                                            </Button>
                                    }
                                </>
                        :
                        row.original.IsPathway ? 'Expand' : ''
                }
                {
                    isOpenEventUKPN ?
                        <ModalEventUKPN closeFn={onCloseModalEventUKPN} open={isOpenEventUKPN} refLearningItemId={row.original.learningItemId} refLearningItemData={{ 'name': row.original.name, 'code': row.original.code }} /> : ''
                }
            </>
        )
    }

    const columnAction = {
        accessorKey: 'id',
        header: 'Id',
        maxSize: 200,
        minSize: 200,
        muiTableBodyCellProps: hideAggregatedCell,
        Cell: ({ cell, row }) => {
            return <>
                {
                    (personSettings.theme.toLowerCase() == 'ukpn') ?
                        <RenderColumnActionUKPN
                            row={row}
                            onChangeState={props.onChangeState}
                        >
                        </RenderColumnActionUKPN>
                        :
                        canShowStart(row.original)
                            ?
                            row.original.learningItemTypeId === 2 ?
                                (
                                    (!isAttended(row.original) ?
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                                            overlay={< Tooltip className='cls-theme-tooltip' > {(isConfirmed((row.original)) ? 'Cancel ' : 'Book') + row.original.name}</Tooltip >}>
                                            <Button variant='clear' onClick={() => handleStart(row.original)}>
                                                <FontAwesomeIcon size='xl' icon={isConfirmed(row.original) ? faCircleXmark : faCirclePlay} className='text-primary' /><span className='m-2 text-body'>{isConfirmed(row.original) ? 'Cancel' : 'Book '}</span>
                                            </Button>
                                        </OverlayTrigger>
                                        : <></>
                                    ))
                                :
                                canShowStart(row.original) &&
                                    row.original.learningItemTypeId === 3 &&
                                    (row.original.isOpen) &&
                                    row.original.isSelected &&
                                    !isCompleted(row.original) ?
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                                        overlay={< Tooltip className='cls-theme-tooltip'
                                        > {(isCompleted(row.original) ? 'Review ' : 'Start ') + row.original.name}</Tooltip >}>
                                        <Button variant='clear' onClick={() => toggleModalElearningRefresher(row.original.learningItemId)}>
                                            <FontAwesomeIcon size='xl' icon={faCirclePlay} className='text-primary' /><span className='m-2 text-body'>Refresh</span>
                                        </Button>
                                    </OverlayTrigger>
                                    :
                                    <>
                                        {!isPathway(row.original) ?
                                            !row.original.isSelected ?
                                                < OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                                                    overlay={< Tooltip className='cls-theme-tooltip' > {(isCompleted(row.original) ? 'Review2 ' : 'Start ') + row.original.name}</Tooltip >}>
                                                    <Button variant='clear' onClick={() => isCompleted(row.original) ? openModal(row.original) : handleStart(row.original)}>
                                                        <FontAwesomeIcon size='xl' icon={faCirclePlay} className='text-primary' /><span className='m-2 text-body'>{isCompleted(row.original) ? 'Review ' : 'Start '}</span>
                                                    </Button>
                                                </OverlayTrigger >
                                                : ''
                                            :
                                            row.original.isSelected ?
                                                <Button variant="clear" disabled>
                                                    <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                                                </Button>
                                                :
                                                <Button variant='clear'
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => setRowPwSelection(row.original)}>
                                                    <FontAwesomeIcon size='xl' icon={row.original.isOpen ? faChevronsUp : faChevronsDown} className='text-primary' /><span className='m-2 text-body'>{row.original.isOpen ? 'Close' : 'Open'}</span>
                                                </Button>
                                        }
                                    </>
                            :
                            row.original.IsPathway ? 'Expand' :
                                ''
                }
                {/* only below p of code is generic for all customers where we check statusId for UKPN only */}
                {row.original.canBeRemoved && !isCompleted(row.original) &&
                    (row.original.statusId != 15 && row.original.statusId != 8) ?
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                        overlay={< Tooltip className='cls-theme-tooltip' > {'Remove ' + row.original.name}</Tooltip >}>
                        <Button variant='clear' onClick={() => handleRemove(row.original)}>
                            <FontAwesomeIcon size='xl' icon={faTrash} className='text-primary' />
                        </Button>
                    </OverlayTrigger >
                    :
                    <>
                    </>
                }
            </>
        }
    };

    const columnActionPw = {
        accessorKey: 'id',
        header: 'Id',
        maxSize: 200,
        minSize: 200,
        muiTableBodyCellProps: hideAggregatedCell,
        Cell: ({ cell, row }) => {
            return <>
                {isPathway(row.original) ?
                    <>
                        <Button variant='clear'
                            aria-label="expand row"
                            size="small"
                            onClick={() => setRowPwSelectionTeam(row.original)}>
                            <FontAwesomeIcon size='xl' icon={row.original.isOpen ? faChevronsUp : faChevronsDown} className='text-primary' /><span className='m-2 text-body'>{row.original.isOpen ? 'Close' : 'Open'}</span>
                        </Button>
                    </>
                    : <></>
                }
            </>
        }
    }

    const handleShowSynopsis = (learningItemId, itemName) => {
        getSynopsis(learningItemId).then((response) => {
            let data = responseData(response, []);
            setSynopsisSections(data);
            setShowSynopsis(true);
            setSynopsisTitle(itemName);
        });
    };

    const synopsisSectionsContent = synopsisSections.map((item, id) => {
        return <div key={id}>
            <h5>{item.tagTitle}</h5>
            <p><span dangerouslySetInnerHTML={{ __html: item.tagHtml }} /></p>
        </div>
    })

    const handleCloseSynopsis = (event) => {
        setShowSynopsis(false);
    };

    const handleCloseConfirmDelete = (event) => {
        setShowConfirmDelete(false);
    };

    const handleReferenceMaterialStart = async (rowData) => {
        if (rowData.url !== "") {
            if (!isCompleted(rowData)) {
                await postCompleteTrainingPlanAndPathwayNew(useLmsStore, rowData.learningItemId);
            }
            onCloseRow(rowData.learningItemId);
            //Always open he link
            window.open(rowData.url, "_blank");
        }
    }

    const handleOpenSynopsisLink = async (url) => {
        window.open(url, "_blank");
    }

    const handleRemove = async (rowData) => {
        setShowConfirmDelete(true);
        setTrainingPlanId(rowData.id);
    }

    const handlePostRemove = async () => {
        postRemoveToPlan(useLmsStore, trainingPlanId)
        setShowConfirmDelete(false);
    }

    const handleEventStart = (rowData) => {
        setRefLearningItem(rowData.learningItemId);
        setRefLearningItemData(rowData);
        setisOpenEvent(true);
    }

    const handleElearningStart = async (rowData) => {
        let canHaveContinue = false;
        let canHaveNewAttempt = false;
        let pwGroupId = -1;
        let regItemId = -1;
        let tpId = -1;
        let myregId = -1;
        let progId = -1;
        let isComplete = isCompleted(rowData);
        if (rowData.regPathwayId) {
            pwGroupId = rowData.addedFromPathway;
            myregId = rowData.regPathwayId;
            regItemId = rowData.id;
            progId = rowData.learnProgId;
            if (!isComplete && rowData.elearningInfo != null && rowData.elearningInfo != undefined) { //It is not started
                canHaveContinue = true;
            }
            canHaveNewAttempt = (rowData.elearningInfo ? (rowData.elearningInfo.canHaveNewAttempt ?? true) : true);
        }
        else {
            tpId = rowData.id;
            canHaveNewAttempt = (rowData.elearningInfo ? (rowData.elearningInfo.canHaveNewAttempt ?? true) : true);
            if (!isComplete && rowData.statusName !== 'Not Started' && rowData.statusId != null && rowData.statusId != undefined) { //It is not started
                canHaveContinue = true;
            }
        }
        getElearningLinks(rowData.learningItemId, tpId, myregId, regItemId, progId).then((response) => {
            let data = responseData(response,);
            if (data) {
                if (isComplete && data.PreviewLink !== "") {
                    setIsElearningOpenId(rowData.learningItemId);
                    setIsElearningOpen(true);
                    window.open(data.previewLink, "_blank");
                }
                else {
                    //It is a start 
                    if ((data.newAttemptLink !== null) && canHaveNewAttempt && ((data.continueAttemptLink === null) || !canHaveContinue)) {
                        //It is used to check if a refresher button is required                           
                        setIsElearningOpenId(rowData.learningItemId);
                        setIsElearningOpen(true);
                        window.open(data.newAttemptLink, '_blank');
                    }
                    else {
                        if (data.continueAttemptLink !== null && canHaveContinue) {
                            if (!canHaveNewAttempt) {
                                //It is used to check if a refresher button is required
                                setIsElearningOpenId(rowData.learningItemId);
                                setIsElearningOpen(true);
                                window.open(data.continueAttemptLink, '_blank');
                            }
                            else {
                                //Open continue elearning modal
                                toggleModalElearningContinue(data.continueAttemptLink, data.newAttemptLink, canHaveNewAttempt);
                            }
                        }
                    }
                }
            }
        });
    }

    const setRowSelection = (rowData) => {
        //Add the item as selected
        setSelectedLearningItem(rowData.learningItemId);
        let index = selectedRows.findIndex((item) => item.learningItemId === rowData.learningItemId);
        if (index < 0) {
            selectedRows.push(
                { learningItemId: rowData.learningItemId, isActive: true, isOpen: false });
        }
        else {
            if (!selectedRows[index].isActive) {
                selectedRows[index].isActive = true;
                selectedRows[index].isOpen = false;
            }
        }
        setSelectedRows(selectedRows);
        return;
    }

    const setRowPwSelection = (rowData) => {
        //Add the item as selected
        setTableLoadingPw(true);
        updatePathwayOpen(useLmsStore, rowData.id, !rowData.isOpen);
        setTableLoadingPw(false);
        setTimeout(() => {
            setOpenClosePw(false);
        }, 1000);
        return;
    }

    //This expand the pathway row only when it is used by my team screen as the data is not in uselmsstore but locally
    const setRowPwSelectionTeam = (rowData) => {
        setTableLoadingPw(true);
        if (props.dataActiveTrainingPlan) {
            //Pathway only show in filter 1
            let transformedData = structuredClone(filteredDataS(dataActivePlan, 1, isUKPN));
            let idx = transformedData.findIndex(x => x.id === rowData.id);
            if (idx > -1) {
                transformedData[idx].isOpen = !rowData.isOpen;
                transformedData[idx].isSelected = true;
                setTableData(transformedData);
                setTableLoadingPw(false);
                setTimeout(() => {
                    setOpenClosePw(false);
                }, 1000);
            }
        }
        return;
    }

    const handleStart = (rowData) => {
        alert('handleStart:' + rowData.learningItemTypeId);
        //Add the item as selected
        setRowSelection(rowData);
        switch (rowData.learningItemTypeId) {
            case 2: //events
                handleEventStart(rowData);
                break;
            case 3: //eLearning
                handleElearningStart(rowData);
                break;
            case 8:
                handleReferenceMaterialStart(rowData);
                break;
            default:
                alert('You click on start default' + rowData.name);
                break;
        }
        setIsModalOpen(false);
    };

    const canDoBooking = (rowData) => {
        if (isPathwayItem(rowData)) {
            if (rowData.levelOfApproval == 2) {
                if (rowData.statusId == 0) {
                    return false;
                }
            }
        }

        //Do not show if  the level of approval 3 or 4 and status waiting dates as the booking need to be done by admin
        if ((rowData.levelOfApproval == 3) || (rowData.levelOfApproval == 4)) {
            return false;
        }

        return true;
    }

    const canShowStart = (rowData) => {
        let result = true;
        switch (rowData.learningItemTypeId) {
            case 2: //instructor led
                if (personSettings.theme.toLowerCase() === 'ukpn') {
                    result = canDoBooking(rowData);
                }
                break;
            case 3: //eLearning
                result = canDoElearning(rowData);
                break;
            case 8:
                break;
            default:
                break;
        }
        return result;
    };

    /*
     Give a warning to the user that if the want to do a new attempt
   */
    function onHandleNewAttempt() {
        if (newAttemptLink !== "") {
            setIsElearningOpenId(selectedLearningItem);
            setIsElearningOpen(true);
            setisOpenNewAttemptConfirmation(false);
            setisOpenElearningContinue(false);
            window.open(newAttemptLink, '_blank');
        }
    };

    /*
      Trigger when the user click on the elearning continue button
    */
    function onHandleContinueElearning() {
        setisOpenElearningContinue(false);
        //Set the elearnig as open so therefresh button can appear       
        setIsElearningOpenId(selectedLearningItem);
        setIsElearningOpen(true);
    };

    function toggleModalElearningContinue(continueLink, newAttemptLink, canHaveNewAttempt) {
        setContinueLink(continueLink);
        setNewAttemptLink(newAttemptLink);
        setCanHaveNewAttempt(canHaveNewAttempt);
        setisOpenElearningContinue(!isOpenElearningContinue);
    }

    function onCloseModalElearningContinue() {
        setisOpenElearningContinue(false);
        onCloseRow(selectedLearningItem);
    }

    function toggleModalNewAttemptConfirmation() {
        setisOpenNewAttemptConfirmation(!isOpenNewAttemptConfirmation);
    }

    function toggleModalElearningRefresher(learningItemId) {
        setRefLearningItem(learningItemId);
        setisOpenElearningRefresher(!isOpenElearningRefresher);
    }

    function onCloseAndRefreshModalElearningRefresher() {
        onCloseModalElearningRefresher();
        //We need to refresh the page
        window.location.reload(false);
    }

    function onCloseModalElearningRefresher() {
        setRefLearningItem(-1);
        setisOpenElearningRefresher(false);
    }

    const onCloseModalEvent = () => {
        setRefLearningItem(0);
        setisOpenEvent(false);
    }

    const onCloseModalUserEvent = () => {
        setIsOpenUserEvent(false);
        setUserEventInfo(null);
    }

    function handleOpenUserEventInfo(userEventInfo) {
        getEventFullInfo(userEventInfo.eventId);
        getEventVenueInfo(userEventInfo.eventId)
            .then(d => setEventVenueInfo(d.data));
        setUserEventInfo(userEventInfo);
        setIsOpenUserEvent(true);
    }

    // on searchParams
    useEffect(() => {
        if (searchParams.has('search') || searchParams.has('filter')) {
            if (searchParams.has('search')) {
                searchParams.delete('search');
            }
            if (searchParams.has('filter')) {
                switch (searchParams.get("filter")) {
                    case "todo":
                        setFilterId(filterId === -1 ? 0 : -1);
                        break;
                    case "overdue":
                        setFilterId(filterId === -2 ? 0 : -2);
                        break;
                    case "mandatory":
                        setFilterId(filterId === -3 ? 0 : -3);
                        break;
                    case "upcoming":
                        setFilterId(filterId === -4 ? 0 : -4);
                        break;
                    case "awaiting dates":
                        setFilterId(filterId === -5 ? 0 : -5)
                        break;
                    case "completed":
                        setFilterId(filterId === -6 ? 0 : -6)
                        break;
                    default:
                        setFilterId(-1)
                }
                searchParams.delete('filter');
            }
            setSearchParams(searchParams);
        }
    }, [searchParams]);

    // on dataActiveTrainingPlan change
    useEffect(() => {
        if (props.dataActiveTrainingPlan.loading > 0) {
            setTableLoading(true);
        } else {
            const tableDataWithStats = getTableDataWithStats(props.dataActiveTrainingPlan.data, isUKPN);
            setToDoCount(tableDataWithStats.todo);
            setOverdueCount(tableDataWithStats.overdue);
            setMandatoryCount(tableDataWithStats.mandatory);
            setCompletedCount(tableDataWithStats.completed);
            setAwaitingCount(tableDataWithStats.awaiting);
            setUpcomingCount(tableDataWithStats.upComing);
            setDataActivePlan(tableDataWithStats.tableData);
            setEventData(tableDataWithStats.eventData);
            setTableLoading(false);
        }
    }, [props.dataActiveTrainingPlan]);

    // on tabId change
    useEffect(() => {
        if (tabId === '0' && !props.dataActiveTrainingPlan.loading > 0) {
            setCustomFilterData(filterId);
        }
        else {
            if (props.dataActiveTrainingPlan.loading > 0) {
                setTableLoading(true);
            }
            else {
                setTableLoading(false);
                setTabId('0');
            }
        }
        // eslint-disable-next-line
    }, [tabId, filterId, dataActivePlan, props.dataActiveTrainingPlan]);

    const setCustomFilterData = (filterId) => {
        if (props.dataActiveTrainingPlan.loading > 0) {
            setTableLoading(true);
        }
        else {
            setTableLoading(false);
        }
        if (searchString != undefined && searchString !== '') {
            //Show the latest complete itme first
            if (filterId == -6) {
                let completedResult = filteredDataS(dataActivePlan, filterId, isUKPN);
                completedResult = completedResult.filter(a => a.sortingDate != null).sort().reverse().concat(completedResult.filter(a => a.sortingDate == null));
                setTableData(completedResult);
            }
            else {
                //For any other tab shows the oldest first
                setTableData(filteredDataS(dataActivePlan, filterId, isUKPN));
            }
        }
        else {
            //Show the latest complete itme first
            if (filterId == -6) {
                let completedResult = filteredData(dataActivePlan, filterId, isUKPN);
                completedResult = completedResult.filter(a => a.sortingDate != null).sort().reverse().concat(completedResult.filter(a => a.sortingDate == null));
                setTableData(completedResult);
            }
            else {
                //For any other tab shows the oldest first
                setTableData(filteredData(dataActivePlan, filterId, isUKPN));
            }
        }
        if (props.dataActiveTrainingPlan.loading > 0) {
            setTableLoading(true);
        }
        else {
            setTableLoading(false);
        }
    }

    const columnsFn = (props) => {
        let columns = [
            {
                accessorKey: 'pwgrpName',
                header: 'PathWay',
                GroupedCell: ({ cell }) => <div>{cell.getValue()}</div>,
                muiTableBodyCellProps: ({ cell, table }) => {
                    return !tableLoading && cell.getIsGrouped() ? {
                        colSpan: 4,
                        sx: {
                            backgroundColor: 'var(--app-nav-primary)',
                            color: 'white',
                            display: cell.getValue() ? 'table-cell' : 'none !important',
                            fontSize: '1.25rem',
                            fontWeight: 'bold',
                            padding: '2rem 0 0 0',
                            verticalalign: 'top'
                        }
                    } :
                        {
                            sx: {
                                display: 'none'
                            }
                        }
                }
            },
            columnIconLearningItem(),
            columnAbout
        ];
        if (props.enableActions) {
            columns.push(columnAction);
        }
        if (props.enablePw) {
            columns.push(columnActionPw);
        }
        return columns;
    };

    const columnsFnPw = (props) => {
        let columns = [
            {
                accessorKey: 'pwgrpName',
                header: 'PathWay',
                GroupedCell: ({ cell, row }) => <div>{cell.getValue()}</div>,
                muiTableBodyCellProps: ({ cell, table }) => {
                    return !tableLoading && cell.getIsGrouped() ? {
                        colSpan: 7,
                        id: 'hide-next-td-1', // Cheat to hide the collapse button (see cheats.scss)
                        sx: {
                            backgroundColor: 'var(--app-nav-primary)',
                            color: 'white',
                            display: cell.getValue() ? 'table-cell' : 'none !important',
                            fontSize: '1.25rem',
                            fontWeight: 'bold',
                            padding: '2rem 0 0 0',
                        }
                    } :
                        {
                            sx: {
                                display: 'none'
                            }
                        }
                }
            },
            columnIconLearningItem(),
            columnAbout,
        ];
        if (props.enableActions) {
            columns.push(columnAction);
        }
        return columns;
    };

    const tableColumns = useMemo(() => columnsFn(props), [tableLoading],);

    const tableColumnsPw = useMemo(() => columnsFnPw(props), [tableLoading],);

    const withSelectedRows = (tableData, selectedRows) => tableData.map(item => { return { ...item, isSelected: selectedRows.some(item2 => item2.isActive && (item2.learningItemId === item.learningItemId)) } });

    //Logic to hide the arrow that appears in the table because the enableGrouping gets set to true in material react table but
    // we do not want to have that arrow. We just query the dom directly for an element with the class in query selector and attach it to
    // the observer
    useEffect(() => {
        const hideElements = () => {
            const elements = document.querySelectorAll('[data-mui-internal-clone-element="true"]');
            elements.forEach(element => {
                element.style.display = 'none';
            });
        };
        // Initially hide elements
        hideElements();
        // Create an observer to monitor changes in the DOM
        const observer = new MutationObserver(hideElements);
        // Configure the observer to watch for changes in the subtree
        observer.observe(document.body, { childList: true, subtree: true });
        // Clean up the observer on component unmount
        return () => {
            observer.disconnect();
        };
    }, []);

    return (<>
        <TableContainer>
            {props.displayUserCard ? <UserCard {...props.dataUser.data} displayBackButton={true} loading={props.dataUser.loading} /> : null}
            <MaterialReactTable
                columns={tableColumns}
                data={withSelectedRows(tableData, selectedRows)}
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableGlobalFilter={true}
                enableGrouping={false}
                enableFilters={true}
                globalFilterFn={'includesString'}
                enableStickyHeader={true}
                enableHiding={false}
                enablePagination={false}
                enableSorting={false}
                enableTopToolbar={true}
                muiTableBodyRowProps={showByGroup ? { hover: false, sx: { borderBottom: '0.25rem solid var(--app-nav-primary)' } } : { hover: false }}
                muiTableContainerProps={({ table }) => showByGroup ?
                    props.dimensions.computedMuiTableContainerProps(table, {}, { backgroundColor: 'var(--app-nav-primary)' })
                    :
                    props.dimensions.computedMuiTableContainerProps(table)
                }
                muiTableHeadProps={{
                    sx: {
                        display: 'none'
                    }
                }}
                muiToolbarAlertBannerProps={{
                    sx: {
                        display: 'none'
                    }
                }}
                onGlobalFilterChange={setSearchString}
                renderTopToolbarCustomActions={({ table }) => {
                    return (tabId === '0' ?
                        <Row className='gy-2'>
                            <Col key='-1' xs='auto'>
                                <FilterBadgeButton variant={todoCount > 0 ? 'info' : 'info'} title='To Do' onClick={() => setFilterId(filterId === -1 ? 0 : -1)} active={filterId === -1}>
                                    {todoCount}
                                </FilterBadgeButton>
                            </Col>
                            <Col key='-2' xs='auto'>
                                <FilterBadgeButton variant={overdueCount > 0 ? 'danger' : 'danger'} title='Overdue' onClick={() => setFilterId(filterId === -2 ? 0 : -2)} active={filterId === -2}>
                                    {overdueCount}
                                </FilterBadgeButton>
                            </Col>
                            <Col key='-3' xs='auto'>
                                <FilterBadgeButton variant={mandatoryCount > 0 ? 'warning' : 'warning'} title='Mandatory' onClick={() => setFilterId(filterId === -3 ? 0 : -3)} active={filterId === -3}>
                                    {mandatoryCount}
                                </FilterBadgeButton>
                            </Col>
                            <Col key='-4' xs='auto'>
                                <FilterBadgeButton variant={upComingCount > 0 ? 'warning' : 'warning'} title='Upcoming' onClick={() => setFilterId(filterId === -4 ? 0 : -4)} active={filterId === -4}>
                                    {upComingCount}
                                </FilterBadgeButton>
                            </Col>
                            <Col key='-5' xs='auto'>
                                <FilterBadgeButton variant={awaitingCount > 0 ? 'warning' : 'warning'} title='Awaiting Dates' onClick={() => setFilterId(filterId === -5 ? 0 : -5)} active={filterId === -5}>
                                    {awaitingCount}
                                </FilterBadgeButton>
                            </Col>
                            <Col key='-6' xs='auto'>
                                <FilterBadgeButton variant={completedCount > 0 ? 'success' : 'success'} title='Completed' onClick={() => setFilterId(filterId === -6 ? 0 : -6)} active={filterId === -6}>
                                    {completedCount}
                                </FilterBadgeButton>
                            </Col>
                            <Col key='-7' xs='auto'>
                                {filterId < 0 && <FilterClearButton onClick={() => setFilterId(0)} />}
                            </Col>
                        </Row> : <><Col></Col></>)
                }}
                initialState={{
                    globalFilter: searchString,
                    showGlobalFilter: true,
                }}
                state={
                    {
                        isLoading: tableLoading,
                        columnVisibility: {
                            pwgrpName: false
                        },
                        globalFilter: searchString
                    }}
            />
        </TableContainer>
        <Modal fullscreen='md-down' show={showSynopsis} onHide={handleCloseSynopsis} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{synopsisTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {synopsisSectionsContent}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={handleCloseSynopsis}>Close</Button>
            </Modal.Footer>
        </Modal>
        <Modal fullscreen='md-down' show={showConfirmDelete} onHide={handleCloseConfirmDelete} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>Are you sure you wish to remove this item from your learning plan?</h6>
                <Button variant='primary px-3 w-25 mx-3' onClick={handlePostRemove} >Yes</Button>
                <Button variant='primary px-3 w-25 mx-3 float-right' onClick={handleCloseConfirmDelete} >No</Button>
            </Modal.Body>
        </Modal>
        <ModalElearningContinue closeFn={onCloseModalElearningContinue} continueFn={onHandleContinueElearning} newAttemptFn={toggleModalNewAttemptConfirmation} open={isOpenElearningContinue} newAttemptUrl={newAttemptLink} continueUrl={continueLink} canHaveNewAttempt={canHaveNewAttempt} />
        <ModalConfirmation closeFn={toggleModalNewAttemptConfirmation} yesFn={onHandleNewAttempt} noFn={toggleModalNewAttemptConfirmation} open={isOpenNewAttemptConfirmation} title='Confirm new attempt' description='<p>Are you sure you want to start a new attempt?</p><p>If you do this you will lose your current progress</p>' />
        <ModalElearningRefresher closeFn={onCloseModalElearningRefresher} yesFn={onCloseAndRefreshModalElearningRefresher} open={isOpenElearningRefresher} refLearningItemId={refLearningItem} />
        <ModalEvent closeFn={onCloseModalEvent} open={isOpenEvent} refLearningItemId={refLearningItem} refLearningItemData={refLearningItemData} />
        {(personSettings?.ukpnPersonId && eventVenueInfo) || (!personSettings?.ukpnPersonId && userEventInfo) ?
            <ModalUserEventInfo personSettings={personSettings} closeFn={onCloseModalUserEvent} open={isOpenUserEvent} eventVenueData={eventVenueInfo} userEventData={userEventInfo?.instructorInfo ?? null} eventName={userEventInfo?.name ?? ''} status={userEventInfo?.statusName ?? ''} />
            : <></>}
        {isModalOpen && (
            <Modal show={isModalOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title><b>My Learning Plan:</b> {modalData.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You've already completed this course and your result has already been recorded. <b>Review</b> is a read only view of the content.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => handleStart(modalData)}>
                        Continue to eLearning
                    </Button>
                </Modal.Footer>
            </Modal>
        )}
        <ModalConfirmation showContinue={true} closeFn={toggleModalResetElearning} yesFn={onHandleResetElearning} open={isOpenResetElearning} title='Please Note:' description='<p>By resetting the eLearning for this user, all previous attempts will still be recorded but the status of this item will return to ‘start’ and they will be able to retake the eLearning again.​</p>' />
        {openClosePw && (
            <Modal show={openClosePw} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title><b>This action will take sometime</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This action will take sometime</p>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        )
        }
    </>);
} 
