import BaseContainer from "../components/BaseContainer";
import FormUser from '../components/FormUser';
import MaterialReactTable from 'material-react-table';
import shallow from 'zustand/shallow';
import useLmsStore from "../hooks/useLmsStore";
import usePrevious from '../hooks/usePrevious';
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TableRowSplit } from '../helpers/tableHelper'
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useMemo, useState } from 'react';

const UserAdministrationCore = ({ dimensions }) => {
    const [tableInactive, setTableInactive] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [isReadyOnlyUser, setIsReadOnlyUser] = useState(false);
    const [user, setUser] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [rowSelectionRequested, setRowSelectionRequested] = useState(null);
    const [dataSelected, setDataSelected] = useState(null);
    const rowSelectionRequestedPrevious = usePrevious(rowSelectionRequested)
    const [requestLeave, setRequestLeave] = useState(false);

    const { users, userStatus, employeeTypes, companiesByApplication, catalogues, userRoles, fetchCatalogues, fetchUseStatus, fetchUserCompaniesByApplication, fetchUserEmpType, fetchUsers, fetchUserRoles, personSettings, hasPermission } = useLmsStore(state =>
    ({
        users: state.users.data,
        userStatus: state.userStatus.data,
        employeeTypes: state.employeeTypes.data,
        companiesByApplication: state.companiesByApplication.data,
        catalogues: state.catalogues.data,
        userRoles: state.userRoles.data,
        fetchCatalogues: state.fetchCatalogues,
        fetchUseStatus: state.fetchUseStatus,
        fetchUserCompaniesByApplication: state.fetchUserCompaniesByApplication,
        fetchUserEmpType: state.fetchUserEmpType,
        fetchUsers: state.fetchUsers,
        fetchUserRoles: state.fetchUserRoles,
        personSettings: state.personSettings,
        hasPermission: state.hasPermission
    }), shallow)

    // Sorting function to sort companies by companyName
    const sortedUsers = useMemo(() => {
        if (users) {
            return [...users].sort((a, b) => a.firstName.localeCompare(b.firstName));
        }
        return [];
    }, [users]);

    const handleAdd = () => {
        setUser([]);
        setShowForm(true);
    }

    useEffect(() => {
        if (dataSelected) {
            setUser(dataSelected);
            //console.log(user);
            setShowForm(true);
        } else {
            setShowForm(false);
        }
    }, [dataSelected]);

    useEffect(() => {
        fetchUsers();
        fetchUseStatus();
        fetchUserEmpType();
        fetchUserCompaniesByApplication();
        fetchCatalogues();

        // setIsReadOnlyUser(isReadyOnly(useLmsStore, 'ac.view'));
        if (personSettings.data && hasPermission('pe.add') === false) {
            //console.log('no pe.add');
            setIsReadOnlyUser(true);
        };
        fetchUserRoles();
    }, []);

    const onInactiveChange = (event) => {
        setTableInactive(!tableInactive);
    }


    //fix issue with duplicates (delete when the server api is fixed) RM
    function getUnique(arr, index) {

        const unique = arr
            .map(e => e[index])
            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);

        return unique;
        //  return unique.filter(a=> a.firstName==='Jayne');         
    }

    useEffect(() => {
        let keys = Object.keys(rowSelection);
        if (keys.length === 1 && rowSelection[keys[0]] === true) {
            let id = parseInt(keys[0]);
            if (users) {
                setDataSelected(users.find(x => x.id === id));
            } else {
                setDataSelected(null);
            }
        } else {
            setDataSelected(null);
        }
    }, [rowSelection]);

    //fix issue with duplicates (delete when the server api is fixed) RM
    function getUnique(arr, index) {

        const unique = arr
            .map(e => e[index])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);

        return unique;
        //  return unique.filter(a=> a.firstName==='Jayne');

    }

    const withSelectedRows = (tableData, inactive) => getUnique((tableData ?? []).filter(e => inactive || e.isDeleted !== true), "id");

    const columns = useMemo(
        () => [
            {
                accessorKey: "searchTerms",
                header: "User Data",
                Cell: ({ cell, row }) => {
                    return (<>
                        <span style={{ color: 'gray', fontSize: '18px' }}>
                            <b>
                                {row.original.firstName || ''} {row.original.lastName || ''}
                            </b>
                        </span> <br />
                        <b>Employee Id:</b> {row.original.employeeId || 'None'}<br />
                        <b>Company:</b> {row.original.companyName || 'None'}<br />
                        <b>Email:</b> {row.original.emailAddress || 'None'}<br />
                    </>)
                }
            }
        ],
        [],
    );

    useEffect(() => {
        if (rowSelectionRequested) {
            if (showForm) {
                const selectionChanged = rowSelectionRequested !== rowSelectionRequestedPrevious;
                if (!requestLeave && selectionChanged) {
                    setRequestLeave(true);
                }
            } else {
                // Form not initialised so nothing to check.
                onRequestLeaveCompleted(true);
            }
        }
    }, [showForm, requestLeave, rowSelectionRequested, rowSelectionRequestedPrevious]);

    const onRequestLeaveCompleted = (doLeaveFlag) => {
        if (doLeaveFlag) {
            // Accept new selection
            setRowSelection({ [rowSelectionRequested]: true });
        } else {
            // Reject new selection. Ensure that rowSelectionRequestedPrevious is reset.
            setRowSelectionRequested(null);
        }
        if (requestLeave) {
            // The onRequestLeaveCompleted sequence was triggered by requestLeave so reset flag.
            setRequestLeave(false);
        }
    };

    return (
        <TableRowSplit
            leftPane={
                <>
                    <div className="mt-2 mx-2 form-check form-switch">
                        <input type="checkbox" id="isActive" className="form-check-input" onChange={onInactiveChange} />
                        <label title="" htmlFor="isActive" className="form-check-label">Include Inactive</label>
                    </div>

                    <MaterialReactTable
                        columns={columns}
                        data={withSelectedRows(sortedUsers, tableInactive) || []}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        enableGrouping={false}
                        enableHiding={false}
                        enablePagination={true}
                        enableBottomToolbar={true}
                        enableSorting={false}
                        enableTopToolbar={true}
                        enableFilterMatchHighlighting={true}
                        globalFilterFn="contains"//By default is fuzzy
                        enableGlobalFilter={true}
                        muiTableHeadProps={{
                            sx: {
                                display: 'none'
                            }
                        }}
                        getRowId={(originalRow) => originalRow.id}
                        muiTableBodyRowProps={({ row }) => ({
                            onClick: () => setRowSelectionRequested(row.id),
                            hover: true,
                            selected: rowSelection[row.id],
                            sx: {
                                cursor: 'pointer',
                            },
                        })}
                        muiTableContainerProps={({ table }) => dimensions.computedMuiTableContainerProps(table)}
                        onGlobalFilterChange={setSearchString} //zc - new for custom search                            
                        renderToolbarInternalActions={({ table }) => (
                            <>
                                {isReadyOnlyUser == false ?
                                    <>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add</Tooltip>}>
                                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleAdd()}>
                                                <FontAwesomeIcon size='xl' icon={faPlus} className='text-primary' />
                                            </Button>
                                        </OverlayTrigger>
                                    </>
                                    : <></>
                                }
                            </>
                        )}
                        muiToolbarAlertBannerProps={{
                            sx: {
                                display: 'none'
                            }
                        }}
                        initialState={{
                            pagination: { pageIndex: 0, pageSize: 10 },

                            globalFilter: searchString, //zc - new for custom search
                            showGlobalFilter: true //This displays the seach generic way for the materia react table
                        }}
                        state={{
                            isLoading: !users,
                            globalFilter: searchString,//zc - new for custom search
                        }}
                    />
                </>
            }
            rightPane={showForm ?
                <FormUser user={user}
                    userStatus={userStatus}
                    employeeTypes={employeeTypes}
                    companiesByApplication={companiesByApplication}
                    catalogues={catalogues}
                    userRoles={userRoles}
                    requestLeave={requestLeave}
                    onRequestLeaveCompleted={onRequestLeaveCompleted}
                    dimensions={dimensions}
                />
                :
                null
            }
        />
    );
}

export default function UserAdministration() {
    return (<BaseContainer title='Users'>
        <UserAdministrationCore />
    </BaseContainer>)
}