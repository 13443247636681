import BaseContainer from "../components/BaseContainer";
import FormCatalogue from '../components/FormCatalogue';
import MaterialReactTable from 'material-react-table';
import shallow from 'zustand/shallow';
import useLmsStore from "../hooks/useLmsStore";
import usePrevious from '../hooks/usePrevious';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TableRowSplit } from '../helpers/tableHelper'
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

function CatalogueAdministrationCore({ dimensions }) {
    const [catalogue, setCatalogue] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [tableRetired, setTableRetired] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [rowSelectionRequested, setRowSelectionRequested] = useState(null);
    const [dataSelected, setDataSelected] = useState(null);
    const rowSelectionRequestedPrevious = usePrevious(rowSelectionRequested)
    const { computedMuiTableContainerProps } = dimensions;
    const [requestLeave, setRequestLeave] = useState(false);

    const { loading, catalogues, allVisibleLearningItems, applicationGroups, applicationPeople, fetchCatalogues, fetchAllVisibleLearningItems, fetchApplicationGroups, fetchApplicationPeople } = useLmsStore(state =>
    ({
        allVisibleLearningItems: state.allVisibleLearningItems.data,
        applicationGroups: state.applicationGroups.data,
        applicationPeople: state.applicationPeople.data,
        catalogues: state.catalogues.data,
        loading: state.catalogues.loading,
        fetchCatalogues: state.fetchCatalogues,
        fetchAllVisibleLearningItems: state.fetchAllVisibleLearningItems,
        fetchApplicationGroups: state.fetchApplicationGroups,
        fetchApplicationPeople: state.fetchApplicationPeople
    }), shallow)

    const onInactiveChange = (event) => {
        setTableRetired(!tableRetired);
    }

    const handleAdd = () => {
        setCatalogue([]);
        setShowForm(true);
    }

    useEffect(() => {
        if (dataSelected) {
            setCatalogue(dataSelected);
            setShowForm(true);
        } else {
            setShowForm(false);
        }
    }, [dataSelected]);

    // on load
    useEffect(() => {
        fetchCatalogues();
        fetchAllVisibleLearningItems();
        fetchApplicationGroups();
        fetchApplicationPeople();
        fetchCatalogues().then((response) => {
            setCatalogue(response[0]);
            setShowForm(true);
        });
    }, []);

    useEffect(() => {
        let keys = Object.keys(rowSelection);
        if (keys.length === 1 && rowSelection[keys[0]] === true) {
            let id = parseInt(keys[0]);
            if (catalogues) {
                setDataSelected(catalogues.find(x => x.catalogueId === id));
            } else {
                setDataSelected(null);
            }
        } else {
            setDataSelected(null);
        }
    }, [rowSelection]);

    const withSelectedRows = (tableData, inactive) => (tableData ?? []).filter(e => inactive || e.isActive === true);

    const columns = useMemo(
        () => [
            {
                accessorKey: "title",
                header: "Catalogue Data"
            }
        ],
        [],
    );

    useEffect(() => {
        if (rowSelectionRequested) {
            if (showForm) {
                const selectionChanged = rowSelectionRequested !== rowSelectionRequestedPrevious;
                if (!requestLeave && selectionChanged) {
                    setRequestLeave(true);
                }
            } else {
                // Form not initialised so nothing to check.
                onRequestLeaveCompleted(true);
            }
        }
    }, [showForm, requestLeave, rowSelectionRequested, rowSelectionRequestedPrevious]);

    const onRequestLeaveCompleted = (doLeaveFlag) => {
        if (doLeaveFlag) {
            // Accept new selection
            setRowSelection({ [rowSelectionRequested]: true });
        } else {
            // Reject new selection. Ensure that rowSelectionRequestedPrevious is reset.
            setRowSelectionRequested(null);
        }
        if (requestLeave) {
            // The onRequestLeaveCompleted sequence was triggered by requestLeave so reset flag.
            setRequestLeave(false);
        }
    };

    return (
        <TableRowSplit
            leftPane={
                <>
                    <div className="mt-2 mx-2 form-check form-switch">
                        <input type="checkbox" id="isRetired" className="form-check-input" onChange={onInactiveChange} />
                        <label title="" htmlFor="isRetired" className="form-check-label">Include Retired</label>
                    </div>
                    <MaterialReactTable
                        columns={columns}
                        data={withSelectedRows(catalogues, tableRetired) || []}
                        enableBottomToolbar={true}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        enableGrouping={false}
                        enableHiding={false}
                        enablePagination={true}
                        enableSorting={false}
                        enableTopToolbar={true}
                        getRowId={(originalRow) => originalRow.catalogueId}
                        muiTableBodyRowProps={({ row }) => ({
                            onClick: () => setRowSelectionRequested(row.id),
                            hover: false,
                            selected: rowSelection[row.id],
                            sx: {
                                cursor: 'pointer',
                            },
                        })}
                        muiTableHeadProps={{
                            sx: {
                                display: 'none'
                            }
                        }}
                        muiTableContainerProps={({ table }) => computedMuiTableContainerProps(table)}
                        renderToolbarInternalActions={({ table }) => (
                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add</Tooltip>}>
                                <Button variant='clear' className='pt-0 pb-0' onClick={() => handleAdd()}>
                                    <FontAwesomeIcon size='xl' icon={faPlus} className='text-primary' />
                                </Button>
                            </OverlayTrigger>
                        )}
                        initialState={{
                            pagination: { pageIndex: 0, pageSize: 10 },
                            showGlobalFilter: true
                        }}
                        state={{
                            isLoading: !catalogues
                        }}
                    />
                </>
            }
            rightPane={showForm ?
                <FormCatalogue
                    catalogue={catalogue}
                    allLearningItems={allVisibleLearningItems}
                    applicationGroups={applicationGroups}
                    applicationPeople={applicationPeople}
                    requestLeave={requestLeave}
                    onRequestLeaveCompleted={onRequestLeaveCompleted}
                />
                :
                null
            }
        />
    );
}

export default function CatalogueAdministrationZ() {
    return (<BaseContainer title='Catalogues'>
        <CatalogueAdministrationCore />
    </BaseContainer>)
}