import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandCircleDownOutlined from '@mui/icons-material/ExpandMore';
import MaterialReactTable from 'material-react-table';
import React from "react";
import { TableTheme } from '../helpers/tableHelper';

export default function TrainingHistoryAccordian(props) {
    const data = props.data;
    const header = props.header;

    function ukDateToISO(ukDate) {
        const [day, month, year] = ukDate.split('/');
        const isoDate = `${year}${month.padStart(2, '0')}${day.padStart(2, '0')}`;
        return isoDate;
    }

    const columns = [
        {
            accessorKey: "title",
            header: "Course Title",
            enableSorting: false,
            enableColumnActions: false,
        },
        {
            accessorKey: "supplier",
            header: "Supplier",
            enableSorting: false,
            enableColumnActions: false,
        },
        {
            accessorKey: "startDate",
            header: "Date",
            sortingFn: (a, b) => { return ukDateToISO(a.original.startDate) - ukDateToISO(b.original.startDate) },
            enableSorting: true,
            enableColumnActions: false,
        },
        {
            accessorKey: "duration",
            header: "Duration",
            enableSorting: false,
            enableColumnActions: false,
        },
    ]

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandCircleDownOutlined />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ backgroundColor: '#281E84', color: 'white' }}
            >
                <Typography>{header}</Typography>

            </AccordionSummary>
            <AccordionDetails>
                <TableTheme>
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableBottomToolbar={false}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enableDensityToggle={false}
                        enableFilterMatchHighlighting={true}
                        enableFullScreenToggle={false}
                        enableGlobalFilter={true}
                        enableHiding={false}
                        enableMultiRowSelection={false}
                        enablePagination={false}
                        enableRowSelection={false}
                        enableStickyHeader={true}
                        enableTopToolbar={true}
                        enableExpanding={false}
                        globalFilterFn="contains"//By default is fuzzy
                        initialState={{
                            showGlobalFilter: true,
                        }}
                    />
                </TableTheme>
            </AccordionDetails>
        </Accordion>
    );
}

