import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { b2cPCA } from "../auth/configs/b2cPCA";
import { getValidToken } from "./validateHelper";

export const REFRESH_AFTER = 600000; // 5 minutes

export const WDR_HUB_URL = '/wdrlmshub';

export const apiAxios = axios.create({
    withCredentials: true,
    //headers: getValidToken()
    //headers: { 'authorization': `${window.localStorage.getItem('token') !== null  && localStorage.getItem('token').length > 0 ? 'Bearer ' + window.localStorage.getItem('token').replace(/"/g, '') : "" }`}
});

const token = getValidToken();
if (token.length > 0) {
    apiAxios.headers = { 'authorization': `Bearer ${token}` };
}


function forceLogin() {
    return;
    // Clear token
    window.localStorage.setItem('token', null);

    if (b2cPCA.getActiveAccount()) {
        b2cPCA.logoutRedirect()
    }
    window.location = `${window.location.protocol}//${window.location.host}/login`;
};

apiAxios.interceptors.request.use((config) => {
    // Abort if no token present (user not logged in)
    const token = window.localStorage.getItem('token') !== null && window.localStorage.getItem('token').length > 0 ? window.localStorage.getItem('token').replace(/"/g, '') : "";
    if (token && token.length > 0)
    //    const token = getValidToken();
    {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (!token) {
        throw new Error('No Authorization Token');
    }
    return config;
}, (error) => {
    return Promise.reject(error); // Delegate error to calling side
});

apiAxios.interceptors.response.use((response) => {
    return response;
}, (error) => { // Anything except 2XX goes to here
    const status = error.response?.status || 500;
    if ((!error.response || status === 401) && error?.message !== "Request cancelled on purpose") {
        //alert('request cancelled: ' + error?.response);
        forceLogin();
        // Clear token
    } else if (status > 499) {
        if (error.response && error.response.data && error.response.data.toLowerCase().includes('session expired')) {
            //alert('status greater than 499: ' + error?.response);
            forceLogin();
        } else if (status === 504) {
            window.location = `${window.location.protocol}//${window.location.host}/lms/servererror?error=${status}`
        } else if (process.env.NODE_ENV !== 'development') {
            window.location = `${window.location.protocol}//${window.location.host}/lms/servererror?error=${status}`
        }
    } else {
        return Promise.reject(error); // Delegate error to calling side
    }
});
