import { useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import BaseContainer from "../../../components/BaseContainer";
import { TableContainer } from '../../../helpers/tableHelper'
import MaterialReactTable from 'material-react-table';
// import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload,faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import {Button, OverlayTrigger, Col, Tooltip } from "react-bootstrap";
import * as dayjs from 'dayjs';

export default function TrainingReport(props) {
    const navigate = useNavigate();

    const downloadFile = ({ data, fileName, fileType }) => {
      const blob = new Blob([data], { type: fileType });

      const a = document.createElement("a");
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvt);
      a.remove();
    };

    const exportToJson = (e) => {
      e.preventDefault();
      downloadFile({
        data: JSON.stringify(props?.trainingDetails),
        fileName: "training-report.json",
        fileType: "text/json",
      });
    };
          
    const exportToCsv = (e) => {
      e.preventDefault();

      // Headers for each column
      let headers = ["Employee ID,First Name, Surname, Primary Email, Course Code, Course Title,Course Type, Item Status, Due Date"];

      // Convert users data to a csv
      let usersCsv = props?.trainingDetails.reduce((acc, user) => {
        const { EmployeeId,FirstName, LastName, PrimaryEmail, CourseCode, CourseTitle,CourseType, Status, ExpiryDate } = user;
        acc.push([EmployeeId,FirstName, LastName, PrimaryEmail, CourseCode, CourseTitle,CourseType, Status, ExpiryDate].join(","));
        return acc;
      }, []);

      downloadFile({
        data: [...headers, ...usersCsv].join("\n"),
        fileName: "training-report.csv",
        fileType: "text/csv",
      });
    };

    // // on load
    // useEffect(() => {
    //     fetchRegistrations();
    // }, []);

    const columns = useMemo(
        () => [
            {
                accessorKey: "EmployeeId",
                header: "Employee ID",
                //size: 30
            },
            {
                accessorKey: "FirstName",
                header: "First Name",
                //size: 30
            },
            {
                accessorKey: "LastName",
                header: "Sur Name",
                //size: 30
            },
            {
                accessorKey: "PrimaryEmail",
                header: "Primray Email",
                //size: 30
            },
           
            {
                accessorKey: "CourseCode",
                header: "Course Code",
                //size: 30
            },
            {
                accessorKey: "CourseTitle",
                header: "Course Name",
                //size: 30
            },
            {
                accessorKey: "CourseType",
                header: "Course Type",
                //size: 30
            },
            {
                accessorKey: "Status",
                header: "Item Status",
                //size: 30
            },
            {
                accessorKey: "Due Date DD/MM/YYYY",
                header: "Due Date DD/MM/YYYY",
                Cell: ({ cell, row }) => {
                    return (<>
                        {cell? moment(cell).format('DD/MM/YYYY') : ""} 
                    </>)
                }
            }
        ],
        [],
    );

    return (
        <Container fluid>
            <div>
                {<h6 className='' style={{'fontWeight':'bold'}} dangerouslySetInnerHTML={{ __html: props.heading }} />} 
            </div>
             <Button variant='outline-secondary' className='mt-2 mb-2' onClick={(event)=> {props.onBackButtonClick(event)}}><FontAwesomeIcon icon={faChevronLeft} /><span className='m-2'>Back</span></Button>
             <TableContainer className={"mb-2"}  >
                <Col style={{ maxHeight: '750px', overflow: 'auto',  overflowX: 'hidden' }} className='border-end'>
           
                    <MaterialReactTable
                        columns={columns}
                        data={props?.trainingDetails || []}
                        renderToolbarInternalActions={({ table }) => (
                            <>
                                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Download Data</Tooltip>}>
                                    <Button variant='clear' className='pt-0 pb-0' onClick={exportToCsv}>
                                        <FontAwesomeIcon size='xl' icon={faDownload} className='text-primary' />
                                    </Button>
                                </OverlayTrigger>
                            </>
                        )}
                        initialState={{
                            pagination: { pageIndex: 0, pageSize: 10 },
                            showGlobalFilter: true
                        }}
                        enableStickyHeader={true}
                        useSticky
                        muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 400px)', minHeight: 'calc(100vh - 400px)' } }}
                        state={{
                            isLoading: !props?.trainingDetails
                        }}
                    />
                </Col>
            </TableContainer>
        </Container>
    );
}
