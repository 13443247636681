import { useEffect, useMemo, useState } from 'react';
import { useSearchParams, useParams } from "react-router-dom";
import BaseContainer from "../../components/BaseContainer";

import { Container, Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { TableContainer } from '../../helpers/tableHelper'

import MandatoryTrainingList from './components/mandatory-training-list';

import CustomTableView from '../../components/common/generic-table-view';
import { mandatoryTrainingService } from './services/mandatory-training.service';

import useLmsStore from "../../hooks/useLmsStore";
import shallow from 'zustand/shallow';
import { userService } from '../../services/common/user.service';
import CustomRadioGroupList from '../../components/common/generic-radio-button';
import { TableRowSplit } from '../../helpers/tableHelper';


function MandatoryTrainingManagementCore(props) 
{
    const [userView, setUserView] = useState();
    const [dashboardCourses, setDashboardCourses] = useState();
    const [loading, setLoading] = useState(false);
    const [courseLoading, setCourseLoading] = useState(false);
    const [employeeLoading, setEmployeeLoading] = useState(false);
    const [personId, setPersonId] = useState(0);
    const [courseId, setCourseId] = useState();
    const [selectedViewType, setSelectedViewType] = useState(0);

    const [showDiv, setShowDiv] = useState(true);
    
    const [employeeID, setEmployeeId] = useState(0);

    const [employees, setEmployees] = useState([]);
    const [displayEmployeesTab, setDisplayEmployeesTab] = useState(true);    
    const [heading, setHeading] = useState('All employees');
    const [courses, setCourses] = useState([]);
    const [displayCourses, setDisplayCourses] = useState(true);
    const [userId, setUserId] = useState(0);
    const [type, setType] = useState(1);

    // const [title, setTitle] = useState('Team Dashboard/Mandatory');

    // const params = useParams();
    // const { id } = params;
    
    const viewType = 
    {
        AllEmployee: 'AllEmployee',
        AllCourses: "AllCourses",
        SelectedEmployee: "SelectedEmployee",
        SelectedCourse: "SelectedCourse"
    }    
    const radioOptions = 
    [
        {id:'employee', name:'rdnGroup', value:viewType.AllEmployee, label: 'Employee', defaultCheck: true },
        {id:'course', name:'rdnGroup', value:viewType.AllCourses, label: 'Course', defaultCheck: false }
    ]
    
    const { personSettings } = useLmsStore(state =>
        ({
            personSettings: state.personSettings.data,
            // fetchMyTeamMandatoryTrainingPlan: state.fetchMyTeamMandatoryTrainingPlan,
            // myTeamMandatoryTrainingPlan: state.myTeamMandatoryTrainingPlan
        }), shallow);

    //---------------------------------------
    // #region event handler
    //---------------------------------------

    const onBackClick = () => {
        // setUserView(null)
        // setShowUpcomingList(false);
    }

    const onRadioOptionChange = (event) => 
    {
        let value = event.target.value;
        setDisplayEmployeesTab(value == viewType.AllEmployee);
        let heading = (value == viewType.AllEmployee) ? 'All Employees' : 'Please select a course';
        setHeading(heading);
        setSelectedViewType(value);
        setPersonId(-1);
        setCourseId(-1);
    }

    //---------------------------------------
    // #region components columns region
    //---------------------------------------

    const columns = useMemo(
        () => [
            {
                accessorKey: 'personID', 
                header: '',
                size: 0,
            },
            {
                accessorKey:'jobTitle',
                header: '',
                size: 0,
            },
            {
                accessorKey:'emailAddress',
                header: '',
                size: 0,
            },
            {
                accessorKey: 'employeeID', 
                header: '',
                size: 0,
            },
            {
                accessorKey: "fullname",
                header: "",    
                Cell: ({ cell, row }) => {
                    return (<>
                        <span style={{color: 'gray',  fontSize: '18px'}}>
                            <b>
                                {row.original.firstname || ''} { row.original.surname || ''}
                            </b>
                        </span> <br />
                        <b>Employee Id:</b> {row.original.employeeID || 'None'}<br />
                        {/* <b>Person Id:</b> {row.original.personID || 'None'}<br />
                        <b>Job Title:</b> {row.original.jobTitle || 'None'}<br /> */}
                    </>)                   
                }
            }
        ],
        [],
    );

    const dashboradCoursesColumns = useMemo(
        () => 
        [
                {
                    accessorKey: 'CourseCode', 
                    id: 'CourseCode',  
                    header: '',
                    size: 0,
                },
                {
                    accessorKey: 'CourseID', 
                    id: 'CourseID',  
                    header: '',
                    size: 0,
                },
                {
                    accessorKey: 'CourseTitle',
                    header: '',
                    //  size: 100,
                    //Format a number in a cell render
                    Cell: ({ cell, row }) => (
                        // <span>${cell.getValue.toLocaleString()}</span>
                    <>
                        <div style={{'textAlign':'left'}}>{row.original.CourseCode + ' - ' + row.original.CourseTitle}</div>
                    </>
                    )
                }
        ],
        [],
    );

    //---------------------------------------
    // #endregion
    //---------------------------------------

    //---------------------------------------
    // #region UseEffect
    //---------------------------------------

useEffect(() => 
{
    //alert(JSON.stringify(personSettings));
    //console.log('personSettings?.ukpnPersonId:' + personSettings?.ukpnPersonId);
    if (personSettings?.ukpnPersonId == null || personSettings?.ukpnPersonId == undefined) return;
    if (displayEmployeesTab)
    {
        if (employees?.length > 0) return;
        setEmployeeLoading(true);
        mandatoryTrainingService.getStaffList(personSettings?.ukpnPersonId)
        .then(result=> 
            {
                setEmployees(result[0]);
                // setDisplayEmployeesTab(true);
                //setEmployeeLoading(false)
                //console.log('users:' + JSON.stringify(result[0]));
            })
        .finally(() => setEmployeeLoading(false));
    }
    else
    {
        if (dashboardCourses?.length > 0) return;
        setCourseLoading(true);
        mandatoryTrainingService.getCourseList(-1)
        .then(result=> 
            {
                setDashboardCourses(result[0]);
                //setCourseLoading(false)
            })
        .finally(() => setCourseLoading(false));
    }
}, 
[displayEmployeesTab, personSettings?.ukpnPersonId]);

    //---------------------------------------
    // #region others
    //---------------------------------------

    const employeeOrCourseRowClickEventHandler = (event, row, isEmployee) => 
    {
        event.preventDefault();
        // setPersonId(-1);
        // setCourseId(-1);
        // setSelectedViewType(null);
       let isValid = false;
        if (isEmployee)
        {
            let id = row.original.PortalPersonID;//.personID;
            if (id)
            {
                setHeading('<strong>Employee:</strong> ' + row.original.fullname);
                setPersonId(id == personId ? -1 : id);
                setSelectedViewType(viewType.SelectedCourse);
                isValid = true;
            }
            // console.log('category id: ' + row.original.CourseCategoryID);
        }
        else
        {
            let id = row.original.CourseID;
            if (id)
            {
                setSelectedViewType(viewType.SelectedCourse);
                setHeading(row.original.CourseCode + ' - ' + row.original.CourseTitle);
                //console.log(JSON.stringify(row.original));
                setCourseId(id == courseId ? -1 : id);
                // setCourses([row.original]);
                isValid = true;
            }        
        }
        // if (!isValid)
        // {
        //     setPersonId(-1);
        //     setCourseId(-1);
        //     setSelectedViewType(null);
        //     console.log('isValid:' + isValid);
        // }
        // alert(isValid);
    };

    const toggleDivWidth = () => 
    {
        setShowDiv((prevShowDiv) => !prevShowDiv);
    };
    
    return (
        <TableRowSplit
            expanded={showDiv}
            setExpanded={setShowDiv}
            leftPane={showDiv &&
                                <>
                                    <CustomRadioGroupList
                                        options = {radioOptions}
                                        title = 'Search By'
                                        onchange = {onRadioOptionChange}
                                    >
                                    </CustomRadioGroupList>
                                    {/* <div>{JSON.stringify(employees)}</div> */}
                                    {
                                        
                                        displayEmployeesTab ?
                                        <CustomTableView
                                            users = { employees }
                                            columns = {columns}
                                            loading = { employeeLoading }
                                            
                                            rowClickEventHandler = {(event, row)=> employeeOrCourseRowClickEventHandler(event, row, true)}
                                            columnVisibility = {{ personID : false, employeeID: false, emailAddress: false, jobTitle: false  }}
                                        >
                                        </CustomTableView>
                                        : ''
                                    }        
                                    {
                                        !displayEmployeesTab ?
                                        <CustomTableView
                                            users = { dashboardCourses }
                                            columns = {dashboradCoursesColumns}
                                            loading = { courseLoading }
                                            columnVisibility = {{ CourseCode : false, CourseID: false }}
                                            rowClickEventHandler = {(event, row)=> employeeOrCourseRowClickEventHandler(event, row, false)}
                                        >
                                        </CustomTableView>
                                        : ''
                                    }
                                </>
                        }           
                        rightPane={<>                        
                        {
                            <MandatoryTrainingList 
                                    heading = { heading }
                                    managerId = { personSettings?.portalPersonId }
                                    personId={ personId }  
                                    setPersonId = { setPersonId }
                                    setCourseId = { setCourseId }
                                    courseId = { courseId }
                                    selectedViewType={ selectedViewType }
                                    // viewType = { viewType }
                                    pageType = { props.pageType }
                                    onBackClick = { onBackClick }
                            >
                            </MandatoryTrainingList>
                            // : ''
                            // showCalendar ? <div div className={"shadow  p-3 mt-3 mr-1 rounded"}> {displayDateRange}</div> :
                            // (!loading ) ? <div className={"shadow  p-3 mt-3 mr-1 rounded"}> {heading} </div> : <></>
                        }
            </>}
        />
    );
}
export default function MandatoryTrainingManagement() 
{
    let params = useParams();
    const { id } = params;
    const [title, setTitle] = useState('Team Dashboard');

    useEffect(()=> 
    {
        setTitle(id == 1 ? 'Team Dashboard' : 'Team Dashboard');
    },[id]);

    return (<BaseContainer title={title}>
        <MandatoryTrainingManagementCore
            pageType = {id}
        >
        </MandatoryTrainingManagementCore>
    </BaseContainer>)
}