import React from 'react';
import BaseContainer from "../components/BaseContainer";
import OutActionsManagement from "./outstanding-actions/outstanding-actions-management";

const OutActions = () => {
    return (
        <BaseContainer title='Outstanding Actions' >
            <OutActionsManagement />
        </BaseContainer>
    );
};

export default OutActions;
