/**
 * Based on: https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/
 */

import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from "../auth/hooks/useAuth";

/**
 * Delete the token if older than 12 hours thus forcing a login
 * 
 */
export const ensureJwtTokenValid = () => {
    return  true
    try {
        const token = window.localStorage.getItem('token');
        if (token && token.includes('.')) {
            const base64Url = token.split('.')[1],
                base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'),
                jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join('')),
                accessToken = JSON.parse(jsonPayload);
            if (accessToken.aud === 'http://lms.wdr.co.uk' && accessToken.iss === 'http://lms.wdr.co.uk') {
                let exp = new Date(accessToken.exp * 1000);
                // The token has a lifespan of 48 hours / 2 days.
                // Remove 36 hours so that the token is removed if older than 12 hours                
                exp.setHours(exp.getHours() - 36);
                if (Date.now() < exp.getTime()) {
                    return true;
                }
            }
            // Passed here the token is invalid
            window.localStorage.setItem('token', null);
            return false;
        }
    } catch (e) {
        window.localStorage.setItem('token', null);
        console.error(e);
        return false;
    }
};

const ProtectedLayout = () => {
    const { token } = useAuth();
    //alert('token:' + JSON.stringify(token));
    if (!token || !ensureJwtTokenValid()) {
        return <Navigate to="/login" />;
    }
    return (<><Outlet /></>)
};

export default ProtectedLayout;
