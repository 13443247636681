import React, { useEffect, useMemo, useState, useRef } from 'react';
import { apiAxiosCalls } from "../../services/apiAxiosCalls";
import MaterialReactTable from "material-react-table";
import Checkbox from "@mui/material/Checkbox";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Alert } from "react-bootstrap";


import { toastSuccess } from "../../hooks/data";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Button, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { faFileContract, faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';

import RequestCourseRequiresAuthentication from '../../pages/shared/request-course-requires-authentication';

const defaultCancelReason = 'Please select a reason...';
const defaultCancelReasonID = -1;

const hasDefaultCancelReason  = (toApprove) => toApprove.some(x => !x.Approve && x.CancelReason === defaultCancelReason);

const OutActionsTrainingRequestApproval = ({
    dimensions,
    tableData,
    loading,
    setTableData,
    managerId,
    reasons
}) => {

    const [toApprove, setToApprove] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [authorisationRequired, setAuthorisationRequired] = useState(false);
    const [courseInfo, setCourseInfo] = useState({});
    const [currentRow, setCurrentRow] = useState();
    const [isRendered, setIsRendered] = useState(false);
    const [hasInvalidReason, setHasInvalidReason] = useState(false);
    const [hasNothingToSubmit, setHasNothingToSubmit] = useState(false);

    // const [cancelledReasons, setCancelledReasons] = useState(reasons);

    const [cancelledReasons, setCancelledReasons] = useState(reasons.filter(x => x.cancelReason.includes('Does Not Meet Pre-Requisites') ||
        x.cancelReason.includes('No Business Need') ||
        x.cancelReason.includes('Other (Please speak to manager)')));

    const displayTableIndexes = (tblName, tblData) => {
        if (tblData)
            console.log(`table ${tblName} :` + JSON.stringify(tblData.map(x => ({ index: x.index }))));
    }

    useEffect(() => console.log('hasInvalidReason', hasInvalidReason), [hasInvalidReason]);

    const approveTrainingRequest = async () => {
        try {
            // only get that data where user has at least select check box and set the value to true / false.
            if (toApprove && toApprove.length > 0) {
                setHasNothingToSubmit(false);
                const hasInvalid = hasDefaultCancelReason(toApprove);
                setHasInvalidReason(hasInvalid);
                if (!hasInvalid) {
                    setIsSubmitting(true)
                    apiAxiosCalls.SaveStaffApprovalManagerActions(toApprove)
                        .then((response) => {
                            //alert('response:' + JSON.stringify(response));
                            if (!response.data.isValid) {
                                throw new Error("Error saving data");
                            }
                            else {
                                setTableData(response.data.value.map((result, index) =>
                                ({
                                    ...result,
                                    index: index,
                                    left: '',
                                    right: '',
                                    requireJustificationApproved: result.approvalLevel > 2 ? (result.justificationTextID > 0) : true // if approvel level <=2 then we set this flag to approved by default
                                })));
                                //setTableData(tableData.filter(td => !toApprove.map(x => x.personId * 1).includes(td.personId * 1)))
                                setToApprove([]);
                                toastSuccess("Data saved successfully.")
                            }
                        })
                        .catch((error) => {
                            toastSuccess(`An error occurred while saving data: ${error}`);
                        })
                        .finally(() => {
                            setIsSubmitting(false)
                        })
                    // const {response} = await apiAxiosCalls.SaveStaffApprovalManagerActions(toApprove);
                    // alert('response:' + JSON.stringify(response));
                    // if (!response.isValid) {
                    //     throw new Error("Error saving data");
                    // }
                    // else
                    // {
                    //     setTableData(response.value);
                    //     //setTableData(tableData.filter(td => !toApprove.map(x => x.personId * 1).includes(td.personId * 1)))
                    //     setToApprove([]);
                    //     toastSuccess("Data saved successfully.")
                    // }
                }
            } else {
                setHasNothingToSubmit(true);
            }
        } catch (e) {
            console.log(e)
            toastSuccess("An error occurred while saving data.")
            setIsSubmitting(false)
        }
    }

    //-----------------------------------------
    //#region Start Approve/Disapprove Checkbox 
    //-----------------------------------------   

    const onCheckBoxChange = (e, row) => 
        {
//        e.preventDefault();
//        console.log('e:' + e.target.name + ' value:' + e.target.value);
//        console.log('index:' + row.index);
        //let tableDataCopy = structuredClone(tableData);
        const tableDataCopy = [...tableData]; // Create a shallow copy of tableData
        //        displayTableIndexes('tableDataCopy', tableDataCopy);
        const itemIndex = tableDataCopy.findIndex(x => x.index == row.index);
        //        console.log('itmeindex:' + itemIndex);
        // const itemIndex = tableDataCopy.findIndex(x => x.courseID == row.courseID && x.index == row.index);
        if (itemIndex > -1) 
        {
            if (e.target.name === 'left') {
                // Toggle 'left' checkbox, uncheck 'right' if 'left' is checked
                tableDataCopy[itemIndex].left = !tableDataCopy[itemIndex].left;
                if (tableDataCopy[itemIndex].left) {
                    tableDataCopy[itemIndex].right = false;
                }
            } else if (e.target.name === 'right') {
                // Toggle 'right' checkbox, uncheck 'left' if 'right' is checked
                tableDataCopy[itemIndex].right = !tableDataCopy[itemIndex].right;
                if (tableDataCopy[itemIndex].right) {
                    tableDataCopy[itemIndex].left = false;
                }
            }
            // Set the approve and cancel reasons based on the checkbox status
            tableDataCopy[itemIndex].approve = tableDataCopy[itemIndex].left;
            tableDataCopy[itemIndex].displayCancelReason = tableDataCopy[itemIndex].right;
            tableDataCopy[itemIndex].displayBusinessJustification = tableDataCopy[itemIndex].left;

            if (!tableDataCopy[itemIndex].right) {
                tableDataCopy[itemIndex].CancelReason = null;
                tableDataCopy[itemIndex].CancelReasonID = null;
            }

            setTableData(tableDataCopy);
            // tableDataCopy[itemIndex].left = e.target.name == 'left' && e.target.value === 'on' ? true : false;
            // tableDataCopy[itemIndex].right = e.target.name == 'right' && e.target.value === 'on' ? true : false;
            // tableDataCopy[itemIndex].approve = e.target.name == 'left' && e.target.value === 'on' ? true : false;
            // tableDataCopy[itemIndex].displayCancelReason = tableDataCopy[itemIndex].right;
            // tableDataCopy[itemIndex].displayBusinessJustification = tableDataCopy[itemIndex].left;
            // if (tableDataCopy[itemIndex].right == false)
            // {
            //     tableDataCopy[itemIndex].CancelReason = null;
            //     tableDataCopy[itemIndex].CancelReasonID = null;
            // }
            // setTableData(tableDataCopy);
        }

        // let filtered = tableDataCopy.filter(x => (x.left !== '' || x.right != ''));
        // if (filtered) {
        //     let payLoad = filtered.map(x =>
        //     ({
        //         index: x.index,
        //         PersonId: x.personID,
        //         ManagerId: managerId,
        //         CourseID: x.courseID,
        //         Approve: x.approve,
        //         CancelReason: x.CancelReason,
        //         CancelReasonID: x.CancelReasonID
        //     }));
        //     setToApprove(payLoad);
        //}
        let toApproveCopy = [...toApprove]; // Create a shallow copy of toApprove

        // Filter out items from toApprove that are no longer selected
        toApproveCopy = toApproveCopy.filter(x => {
            const dataItem = tableDataCopy.find(y => y.index === x.index);
            return dataItem && (dataItem.left || dataItem.right);
        });

        let firstOrDefaultReason = cancelledReasons[0];

        // Add or update entries in toApproveCopy based on tableDataCopy
        tableDataCopy.forEach((item) => {
            const existingItemIndex = toApproveCopy.findIndex(x => x.index === item.index);

            if (item.left || item.right) {
                if (existingItemIndex > -1) {
                    // Update existing entry while preserving CancelReason and CancelReasonID
                    toApproveCopy[existingItemIndex] = {
                        ...toApproveCopy[existingItemIndex],
                        Approve: item.approve,
                        CancelReason: item.right ? toApproveCopy[existingItemIndex].CancelReason || firstOrDefaultReason.cancelReason : defaultCancelReason,
                        CancelReasonID: item.right ? toApproveCopy[existingItemIndex].CancelReasonID || firstOrDefaultReason.cancelReasonID : defaultCancelReasonID,
                    };
                }
                else {
                    // Add new entry
                    toApproveCopy.push({
                        index: item.index,
                        PersonID: item.personID,
                        PortalPersonID: item.portalPersonID,
                        ManagerID: managerId,
                        CourseID: item.courseID,
                        Approve: item.approve,
                        // CancelReason: item.CancelReason,
                        // CancelReasonID: item.CancelReasonID,
                        CourseWaitID: item.courseWaitID,
                        IsAuthWaitList: item.isAuthWaitList,
                        AuthID: item.authID,
                        InitiatorID: item.initiatorID,
                        ApproverID: item.approverID,

                        CancelReason: item.right == true ? defaultCancelReason : item.cancelReason,
                        CancelReasonID: item.right == true ? defaultCancelReasonID : item.cancelReasonID,
                    });
                }
            }
        });

        setToApprove(toApproveCopy); // Update the state with the merged array
        //console.log(JSON.stringify(filtered));

        //         const inArray = toApprove.findIndex(x => x.courseID == row.courseID);

        //         if (inArray > -1)
        //         {
        //             let copied = structuredClone(toApprove);

        //             //console.log(copied[inArray].approve);
        //             //copied[inArray].approve = !copied[inArray].approve;
        //             copied[inArray].left = e.target.name == 'left' && e.target.value === 'on' ? true : false;
        //             copied[inArray].right = e.target.name == 'right' && e.target.value === 'on' ? true : false;

        //             setToApprove(copied);
        //             console.log('copied:' + JSON.stringify(toApprove));
        //             //console.log(copied[inArray].approve);
        //             //setToApprove(toApprove.filter(x => x.courseID != row.courseID))
        //             // let record = toApprove[inArray];
        //             // record.approve = !record.approve;
        //             // setToApprove([...toApprove, record]);
        //             // if (record.approve == true)
        //             // {
        //             //     setToApprove(toApprove.filter(x => x.courseID != row.courseID))
        //             // }
        //             // else
        //             // {
        //             //     record.approve = true;
        //             //     setToApprove([...toApprove, record]);
        //             // }
        // //            setToApprove([...toApprove, ])
        //         } else {
        //             setToApprove([...toApprove, {
        //                 employeeId: row.employeeId.toString(),
        //                 personId: row.personID.toString(),
        //                 courseID: row.courseID.toString(),
        //                 approve: true,
        //                 left: e.target.name == 'left' && e.target.value === 'on' ? true : false,
        //                 right: e.target.name == 'right' && e.target.value === 'on' ? true : false
        //             }])
        //         }
    }

    const renderCheckBox = (row) => {
        //const checked = toApprove.findIndex(x => x.employeeId == row.employeeId) > -1
        // const index = toApprove.findIndex(x => x.courseID == row.courseID) ;
        // let checked1 = '';
        // let checked2 = '';
        // if (index > -1)
        // {
        //     let record = toApprove[index];
        //     checked1 = record.left;
        //     checked2 = record.right;
        //     //console.log('left:' + checked1 + ' right:' + checked2);
        //     console.log('copied rendered:' + JSON.stringify(record));
        // }
        //console.log('checkbox:' + checked);
        return (
            <>
                {
                    //row.requireJustificationApproved &&
                    <>
                        <FormControlLabel
                            control={<Checkbox
                                // name={`left-${row.index}`}
                                name={`left`}
                                onChange={(e) => {
                                    onCheckBoxChange(e, row)
                                }}
                                 checked={row.left === true}
                            />
                            } label="Y" />
                        <FormControlLabel
                            control={<Checkbox
                                name='right'//{`right-${row.index}`}
                                onChange={(e) => {
                                    onCheckBoxChange(e, row)
                                }}
                                checked={row.right === true}
                            />
                            } label="N" />
                    </>
                }
            </>
        )
    }

    //-----------------------------------------
    //#region End Approve/Disapprove Checkbox 
    //-----------------------------------------   

    //-----------------------------------
    //#region Start Cancel Reason Section
    //-----------------------------------   

    const onchangeCanReason = (event, row) => {
        event.preventDefault();
        const selectedIndex = event.target.selectedIndex;
        const selectedKey = event.target.options[selectedIndex].getAttribute('data-key');
        // console.log('toApprove Array:' + JSON.stringify(toApprove));
        if (selectedKey) {
            let toApproveCopy = [...toApprove];
            const itemIndex = toApproveCopy.findIndex(x => x.index === row.index);

            if (itemIndex > -1) {
                toApproveCopy[itemIndex].CancelReason = event.target.value;
                toApproveCopy[itemIndex].CancelReasonID = selectedKey;
            }
            console.log('modified toApprove:' + JSON.stringify(toApproveCopy));
            setToApprove(toApproveCopy);
            setHasNothingToSubmit(false);
            setHasInvalidReason(hasDefaultCancelReason(toApproveCopy));
        }
    };

    //-----------------------------------
    //#endregion End Cancel Reason Section    
    //-----------------------------------

    // const onRadioGroupChange = (e, row) =>
    // {
    //     let radioButtonValue = e.target.value;
    // }

    // const renderRadioButtonGroup = (row) =>
    // {
    //     const index = toApprove.findIndex(x=> x.courseID == row.courseID && x.personID == row.personID);
    //     return <RadioGroup
    //     row
    //     aria-labelledby="demo-controlled-radio-buttons-group"
    //     name="controlled-radio-buttons-group"
    //     value={index > -1 ? 1: 0}
    //     onChange={(e) => onRadioGroupChange(e, row)}
    //   >
    //     <FormControlLabel value="1" control={<Radio />} label="Yes" />
    //     <FormControlLabel value="0" control={<Radio />} label="No" />
    //   </RadioGroup>
    // }

    const authorizationRequiresButtonEventHandler = (event, row) => {
        event.preventDefault();
        setAuthorisationRequired(true);
        setCourseInfo({
            managerID: managerId,
            CourseID: row.original.courseID,
            // DeliveryMethodID: row.original.DeliveryMethodID,
            CourseTitle: row.original.courseTitle
        });
        setCurrentRow(row);
    };

    const onSubmitAuthorizationRequiredForm = (row, authenticated) => {
        setAuthorisationRequired(false);
        if (authenticated) {
            let tableDataCopy = structuredClone(tableData);
            const itemIndex = tableDataCopy.findIndex(x => x.courseID == row.original.courseID && x.personID == row.original.personID);
            if (itemIndex > -1) {
                tableDataCopy[itemIndex].requireJustificationApproved = true;
                setTableData(tableDataCopy);
            }
        }
    }

    const requestCourseRequiresAuthentication = (event) => {
        event.preventDefault();
        setAuthorisationRequired(false);
    }


    const columns = useMemo(() => [
        {
            accessorKey: 'courseID',
            header: '',
        },
        {
            accessorKey: 'personID',
            header: 'Employee',
            size: 200,
            Cell: ({ row }) => {
                const original = row.original;
                return (
                    <>
                        {original.firstName} {original.surname}
                        <br />
                        {original.employeeID}
                    </>
                );
            }
        },
        {
            accessorKey: 'courseTitle',
            header: 'Course',
            size: 300,
        },
        {
            accessorKey: 'fullName',
            header: 'Employee',
            size: 150,
        },
        {
            accessorKey: 'requestedBy',
            header: 'Requested By',
            size: 150,
        },
        {
            accessorKey: 'requestDate',
            header: 'Request Date',
            size: 100,
        },
        {
            accessorKey: 'courseWaitID',
            header: 'Approve this request?',
            size: 100,
            Cell: ({ row }) => renderCheckBox(row.original)
        },
        {
            accessorKey: 'approvalLevel',
            header: 'Business Justification',
            size: 100,
            Cell: ({ cell, row }) => {
                return (<>
                    {
                        (parseInt(row.original.approvalLevel) > 2) && row.original.displayBusinessJustification ?
                            row.original.requireJustificationApproved == false ?
                                <>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add Business Justification Text</Tooltip>}>

                                        <Link to='/' onClick={(event) => {
                                            authorizationRequiresButtonEventHandler(event, row);
                                        }}>
                                            <FontAwesomeIcon color='red' icon={faFileContract} size="lg" />

                                        </Link>
                                    </OverlayTrigger>
                                </>
                                :
                                <>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{row.original.justificationText}</Tooltip>}>
                                        <FontAwesomeIcon color='green' icon={faFileContract} size="lg" />
                                    </OverlayTrigger>
                                </>
                            : ''
                    }
                </>)
            }
        },
        {
            accessorKey: 'displayCancelReason',
            header: 'Reason for Rejection',
            size: 150,
            Cell: ({ cell, row }) => {
                const selectRef = useRef(null);
                const isInitialRender = useRef(true); // Track if it's the initial render
                const renderCancelReasons = (row) => {
                    let selectedReason = defaultCancelReason;
                    if (toApprove.length) {
                        const itemIndex = toApprove.findIndex(x => x.index === row.index);
                        selectedReason = itemIndex > -1 ? toApprove[itemIndex].CancelReason || defaultCancelReason : defaultCancelReason;
                    }
                    return (
                        <Form.Select
                            aria-label=""
                            value={selectedReason}
                            name={`reason-${row.index}`}
                            onChange={(e) => { onchangeCanReason(e, row) }}
                            ref={selectRef}
                        >
                            {[{ cancelReasonID: defaultCancelReasonID, cancelReason: defaultCancelReason }].concat(cancelledReasons).map(x =>
                            (
                                <option
                                    value={x.cancelReason}
                                    key={x.cancelReasonID}
                                    data-key={x.cancelReasonID}
                                >
                                    {x.cancelReason}
                                </option>
                            ))}
                        </Form.Select>
                    );
                }

                // useEffect(() => 
                //     {
                //         // console.log('isInitialRender.current:' + isInitialRender.current);
                //         // console.log('displayCancelReason:' + row.original.displayCancelReason);
                //         // console.log('approvalLevel:' + row.original.approvalLevel);
                //         if (isInitialRender.current)
                //         {
                //             // The component is mounting for the first time
                //             isInitialRender.current = false; // Update the ref to mark that initial render is done

                //             if (row.original.displayCancelReason == true && parseInt(row.original.approvalLevel) > 1)
                //                 {
                //                     const myChangeEvent = new Event('change', {bubbles: true});
                //                     selectRef.current.dispatchEvent(myChangeEvent);
                //                 }
                //         }
                //     }, [row])

                return (<>
                    {
                        row.original.displayCancelReason == true && parseInt(row.original.approvalLevel) > 1 &&
                        renderCancelReasons(row.original)
                    }
                </>)
            }
        },
    ], [toApprove, cancelledReasons]);

    return (<>
        {authorisationRequired == false ?
            <>
                <MaterialReactTable
                    columns={columns}
                    data={tableData}
                    enableExpanding={false}
                    enableBottomToolbar={true}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableGrouping={false}
                    enableHiding={false}
                    enablePagination={true}
                    enableSorting={false}
                    enableTopToolbar={false}
                    muiTableBodyRowProps={{ hover: false }}
                    muiTableProps={{
                        sx: {
                            tableLayout: 'fixed',
                        },
                    }}
                    muiTableContainerProps={({ table }) => dimensions.computedMuiTableContainerProps(table, null, null, 64)}
                    positionActionsColumn="last"
                    initialState={{
                        pagination: { pageIndex: 0, pageSize: 10 },
                        showGlobalFilter: true,
                        columnVisibility: {
                            employeeId: false,
                            courseID: false,
                            personID: false
                        }
                    }}
                    state={{
                        isLoading: loading
                    }}
                />
                <div className={"d-flex justify-content-end mt-3"}>
                    {hasInvalidReason && <Alert variant='danger' style={{ padding: '0.25rem 0.75rem', margin: '0 0.75rem' }}>
                        Please select a reason for rejecting the requests
                    </Alert>}
                    {hasNothingToSubmit && <Alert variant='danger' style={{ padding: '0.25rem 0.75rem', margin: '0 0.75rem' }}>
                        Please complete the form before submitting
                    </Alert>}
                    <Button
                        type="button"
                        variant="primary"
                        style={{ marginRight: "1rem" }}
                        className={"d-flex align-items-center"}
                        disabled={isSubmitting || !toApprove.length}
                        onClick={() => {
                            approveTrainingRequest();
                        }}
                    >
                        {isSubmitting && <><Spinner animation={"border"} size={"sm"} /> &nbsp;</>}
                        Submit
                    </Button>
                    <Button
                        type="button"
                        onClick={() => {
                            setToApprove([])
                        }}
                        variant="primary"
                        className={"d-flex align-items-center"}
                        disabled={isSubmitting || !toApprove.length}
                    >
                        {isSubmitting && <><Spinner animation={"border"} size={"sm"} /> &nbsp;</>}
                        Cancel
                    </Button>
                </div>
            </> : ''
        }
        {
            authorisationRequired == true ?
                <RequestCourseRequiresAuthentication
                    courseInfo={courseInfo}
                    authorType={'Approver'}
                    onBackButtonClick={requestCourseRequiresAuthentication}
                    onBackSubmitButtonClick={onSubmitAuthorizationRequiredForm}
                    currentRow={currentRow}
                >
                </RequestCourseRequiresAuthentication>
                : ''
        }
    </>
    );
};

export default OutActionsTrainingRequestApproval;
