import React, { useState } from 'react';
import { FormControl, ListItemText, MenuItem, Select, OutlinedInput, InputLabel } from '@mui/material';

export default function DeliveryMethodFilter({ onData, data }) {
    const [selectedItem, setSeletetdItem] = useState('-999');

    const onDropDownChange = (event) => {
        event.preventDefault();
        let value = event.target.value;
        setSeletetdItem(value);
        onData(value);
    }

    return (
        <FormControl sx={{ m: 1, width: 120 }} size="small">
            <InputLabel id="demo-simple-select-label">Delivery Method</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedItem}
                label="Delivery Method"
                onChange={onDropDownChange}
                input={<OutlinedInput label="Tag" />}
            >
                <MenuItem key='Select' value='-999'>
                    <ListItemText primary='Select' />
                </MenuItem>
                {data.map((method) => (
                    <MenuItem key={method?.methodId} value={method?.methodId}>
                        <ListItemText primary={method?.method} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
};