export const getValidToken = (input) => 
{
    let token = window.localStorage.getItem('token');
    if (token !== null  && token.length > 0 && token != 'null')
    {
        token = 'Bearer ' + token.replace(/"/g, '');
    }
    else
    {
        token = '';
    }
    //alert(token);
    return token;
}