import React from 'react';

const GenericSubTabContainer = ({title = "", children, className = ""}) => {
    return (
        <div className={`d-flex flex-column w-100 mt-2 mb-2 rounded-1 border-top border-top-secondary border-5 p-2 bg-light shadow-sm ${className}`}>
            <div className={"h5"}>{title}</div>
            {children}
        </div>
    );
};

export default GenericSubTabContainer;
