import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { canCancel } from './learningPlanDataHelpers';
import { faCircleXmark, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isCompleted } from './learningPlanDataHelpers';

const LearningPlanAction2Cell = ({ handleCancel, handleRemove, row }) => {
    return (
        row.original.canBeRemoved && !isCompleted(row.original) && row.original.statusId != 15 && row.original.statusId != 8
            ?
            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                overlay={< Tooltip className='cls-theme-tooltip' > {'Remove ' + row.original.name}</Tooltip >}>
                <Button variant='clear' onClick={() => handleRemove(row.original)}>
                    <FontAwesomeIcon size='xl' icon={faTrash} className='text-primary' />
                </Button>
            </OverlayTrigger >
            :
            canCancel(row.original) ?
                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                    overlay={< Tooltip className='cls-theme-tooltip' >Cancel {row.original.name}</Tooltip >}>
                    <Button variant='clear' onClick={() => handleCancel(row.original)}>
                        <FontAwesomeIcon size='xl' icon={faCircleXmark} className='text-primary' /><span className='m-2 text-body'>Cancel</span>
                    </Button>
                </OverlayTrigger>
                :
                null
    )
};

export default LearningPlanAction2Cell;