import React, { useEffect, useState } from 'react';
import * as yup from "yup";
import {Form as FormikForm, Formik} from "formik";
import SaveAndCancel from "../SaveAndCancel";
import ELearingTabPropertiesInputs from "./ELearingTabPropertiesInputs";
import GenericSubTabContainer from "../../common/GenericSubTabContainer";
import {apiAxiosCalls} from "../../../services/apiAxiosCalls";

const ELearningTabPropertiesForm = ({properties, duedates}) => {

    const schema = yup.object().shape({
        Width: yup.string().required(), Height: yup.string().required(),
    });

    const handleSubmit = async (payload) => {
        try {
            apiAxiosCalls.updateCourseInfo({
                ...properties,
                ...payload
            }).then((data) => {
                if (!data.data.IsValid) {
                    throw new Error("An error occurred while updating course info")
                }

                apiAxiosCalls.updateElearningDueDates({
                ...properties,
                ...payload})
            })
        } catch (e) {
            console.log(e)
        }
    }

    const [activeDueDates, setActiveDueDates] = useState(null)

    useEffect(() =>{
        if (duedates){
            if (duedates?.elearningDueDateState){
                if(duedates.elearningDueDateState.completionDate?.split('T')[0] == '0001-01-01') {
                    duedates.elearningDueDateState.completionDate = '';
                }
            }
            setActiveDueDates(duedates?.elearningDueDateState);
        }
    }, [duedates])


    return (<div className={"w-100"}>
        <Formik
            validationSchema={schema}
            onSubmit={async (values) => {
                await handleSubmit(values)
            }}
            initialValues={{
                Width: properties?.Width || "",
                Height: properties?.Height || "",
                MaxAttempts: properties?.MaxAttempts || 0,
                CourseType: properties?.CourseType || "",
                IsResizable: properties?.IsResizable || false,
                CompletionDate: activeDueDates?.completionDate?.split('T')[0] || "",
                DueBy: activeDueDates?.dueBy || "",
                DueByPeriod: activeDueDates?.dueByPeriod || "",
            }}
            validateOnBlur={true}
            enableReinitialize
        >
            {(form) => {
                return (<FormikForm
                    noValidate
                    onSubmit={form.handleSubmit}

                >
                    <GenericSubTabContainer title={'Properties'}>
                        <div
                            style={{maxHeight: "600px"}}
                            className={"d-flex flex-column overflow-auto w-100"}
                        >

                            <ELearingTabPropertiesInputs form={form}/>
                        </div>
                    </GenericSubTabContainer>

                    <SaveAndCancel form={form} size={"sm"}/>
                </FormikForm>)
            }}
        </Formik>
    </div>);
};

export default ELearningTabPropertiesForm;
