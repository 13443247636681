import React from 'react';
import { Form } from "react-bootstrap";
import { InputAdornment, TextField, Tooltip } from "@mui/material";
import { Close, Search } from "@mui/icons-material";

function LearningItemsSearch({
    onSearchSubmit = () => null,
    onChange = () => null,
    onSearchClear = () => null,
    value = "",
}) {

    return <div className={"w-100"}>
        <Form onSubmit={(e) => {
            e.preventDefault();
            onSearchSubmit();
        }} className={"px-1 mr-auto"}>
            <TextField
                variant="standard"
                placeholder="Search"
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Tooltip title="Search for groups">
                                <Search role={"button"} />
                            </Tooltip>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip title="Clear search">
                                <Close onClick={() => {
                                    onSearchClear();
                                }} />
                            </Tooltip>
                        </InputAdornment>
                    ),
                }}
                value={value}
                onChange={e => onChange(e)}
            />
        </Form>
    </div>
}

export default LearningItemsSearch;
