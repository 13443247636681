import ResizeObserver from 'resize-observer-polyfill';
import { mergeDeep } from '../helpers/mergeHelper'
import { useEffect, useState } from 'react';

const computedMuiTableContainerProps = (table, root, sxMerge, bottomOffset = 0, dimensions) => {
    if (dimensions && table?.refs?.tableContainerRef?.current?.getBoundingClientRect) {
        const { containerHeight } = dimensions;
        const bottomToolbarHeight = table.options.enablePagination === true && table.refs.bottomToolbarRef?.current?.getBoundingClientRect ? table.refs.bottomToolbarRef?.current.getBoundingClientRect().height : 0
        const adjOffset = table.refs.tableContainerRef.current.getBoundingClientRect().top + 12 + bottomOffset + bottomToolbarHeight;
        const sxCfg = containerHeight > adjOffset ?
            {
                height: `${Math.max(containerHeight - adjOffset, 50)}px`
            }
            :
            {};
        return mergeDeep(root || {}, { sx: mergeDeep(sxCfg, sxMerge || {}) });
    } else {
        return mergeDeep(root || {}, { sx: sxMerge || {} });
    }
};

/**
 * Use ResizeObserver to listen for the changes to the dimensions of an element
 * @params ref - React ref
 * @returns {object} - { isLandscape, containerWidth, containerHeight, containerTop, containerLeft, containerClientLeft, containerClientTop }
 */
const useResize = (ref) => {
    const [dimensions, setDimensions] = useState(null);

    useEffect(() => {
        const element = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                const contentRect = entry.contentRect;
                const clientRect = entry.target.getBoundingClientRect();
                setDimensions({
                    isLandscape: contentRect.width > contentRect.height,
                    isSmall: contentRect.width < 768,
                    containerHeight: contentRect.height,
                    containerLeft: contentRect.left,
                    containerTop: contentRect.top,
                    containerWidth: contentRect.width,
                    containerClientLeft: clientRect.left,
                    containerClientTop: clientRect.top
                });
            });
        });
        resizeObserver.observe(element);
        return () => resizeObserver.unobserve(element);
    }, [ref]);

    return dimensions ?
        {
            computedMuiTableContainerProps: (table, root, sxMerge, bottomOffset) => computedMuiTableContainerProps(table, root, sxMerge, bottomOffset, dimensions),
            ...dimensions
        }
        :
        {
            isLandscape: true,
            isSmall: false,
            containerHeight: 0,
            containerLeft: 0,
            containerTop: 0,
            containerWidth: 0,
            containerClientLeft: 0,
            containerClientTop: 0,
            computedMuiTableContainerProps: (table, root, sxMerge) => mergeDeep(root || {}, { sx: sxMerge || {} }),
        };
};

export default useResize;
