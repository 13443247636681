import React from 'react';
import GenericSubTabContainer from "../../common/GenericSubTabContainer";
import useLmsStore from "../../../hooks/useLmsStore";
import {Col, Form, InputGroup, Row} from "react-bootstrap";

const checkboxes = [{
    name: "IsActive", label: "Active?"
}, {
    name: "CanBeShared", label: "Shared?"
}, {
    name: "NumberRequiredApprovals", label: "Requires Approval?"
}, {
    name: "IsMandatory", label: "Is mandatory"
}, {
    name: "IsRenewable", label: "Is Renewable"
},]

const durationUnits = [
    {
        value: -1, label: "NA"
    },
    {
        value: 1, label: "Days"
    },
    {
        value: 2, label: "Weeks"
    },
    {
        value: 3, label: "Months"
    },
]


const BasicSettingsSubTab = ({form: {handleSubmit, handleChange, values, touched, errors, handleBlur}}) => {
    const {learningItemsState} = useLmsStore((state) => {
        return {
            learningItemsState: state.learningItemsState
        }
    })

    const {
        suppliers,
        learningItemsTypes
    } = learningItemsState;

    const renderSuppliersOptions = () => {

        return suppliers?.map(supplier => {
            return <option key={supplier.Id} value={supplier.Id}>{supplier.Value}</option>
        })
    }

    const renderTypeOptions = () => {
        return learningItemsTypes?.map(learningItemType => {
            return <option key={learningItemType.Id} value={learningItemType.Id}>{learningItemType.Value}</option>
        })
    }

    const renderDurationUnitOptions = () => {
        return durationUnits?.map(durationUnit => {
            return <option key={durationUnit.value} value={durationUnit.value}>{durationUnit.label}</option>
        })
    }

    const renderCheckBoxes = () => {
        return checkboxes.map(checkbox => (
            <Col key={checkbox.name}>
                <Form.Group className="mb-3" controlId={`formCheckbox${checkbox.name}`}>
                    <Form.Check
                        type="checkbox"
                        name={checkbox.name}
                        checked={!!values[checkbox.name]}
                        label={checkbox.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Form.Group>
            </Col>
        ));
    };

    return (<GenericSubTabContainer title={'Basic Settings'}>
        <div className={"w-100"}>
            <Row className={"w-100"}>
                <Col md={6}>
                    <Form.Group className="mb-3" controlId="code1">
                        <Form.Label>Code</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                placeholder="Code"
                                disabled={true}
                                type="text"
                                name="Code"
                                value={values.Code}
                                onChange={handleChange}
                                isValid={!errors.Code}
                                isInvalid={ errors.Code}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.Code}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3" controlId="validationFormik01">
                        <Form.Label>Title <span title='Mandatory field' className='mandatory'>*</span></Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                name="Title"
                                value={values.Title}
                                onChange={handleChange}
                                isValid={!errors.Title}
                                isInvalid={ errors.Title}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.Title}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
            <Row className={"w-100"}>
                <Col md={6}>
                    <Form.Group className="mb-3" controlId="dropdownType">
                        <Form.Label>Type <span title='Mandatory field' className='mandatory'>*</span></Form.Label>
                        <Form.Select
                            name="LearningItemTypeId"
                            value={values.LearningItemTypeId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            aria-label="Default select example"
                            required
                            disabled={ values.Id }
                        >
                            <option value="">Select type</option>
                            {renderTypeOptions()}
                        </Form.Select>
                        { errors.LearningItemTypeId ? (
                            <div className="invalid-feedback d-block">{errors.LearningItemTypeId}</div>
                        ) : null}
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3" controlId="dropdownType">
                        <Form.Label>Supplier <span title='Mandatory field' className='mandatory'>*</span></Form.Label>
                        <Form.Select
                            name="ApplicationSupplierId"
                            value={values.ApplicationSupplierId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            aria-label="Default select example"
                            required
                        >
                            <option value="">Select Supplier</option>
                            {renderSuppliersOptions()}
                        </Form.Select>
                        { errors.ApplicationSupplierId ? (
                            <div className="invalid-feedback d-block">{errors.ApplicationSupplierId}</div>
                        ) : null}
                    </Form.Group>
                </Col>
            </Row>

            <Row className={"w-100"}>
                {renderCheckBoxes()}

            </Row>
            {values.IsRenewable ? <Row className={"w-100"} >
                <Col md={6}>
                    <Form.Group className="mb-3" controlId="validationFormik01">
                        <Form.Label>Duration <span title='Mandatory field' className='mandatory'>*</span></Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="number"
                                name="Duration"
                                value={values.Duration || 0}
                                onChange={handleChange}
                                isValid={ !errors.Duration}
                                isInvalid={ errors.Duration}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.Duration}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3" controlId="dropdownType">
                        <Form.Label>Duration unit <span title='Mandatory field' className='mandatory'>*</span></Form.Label>
                        <Form.Select
                            name="DurationUnit"
                            value={values.DurationUnit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            aria-label="Default select example"
                            required
                        >
                            <option value="">Select type</option>
                            {renderDurationUnitOptions()}
                        </Form.Select>
                        {errors.DurationUnit ? (
                            <div className="invalid-feedback d-block">{errors.DurationUnit}</div>
                        ) : null}
                    </Form.Group>
                </Col>
            </Row> : <></>}
            <Row className={"w-100"}>
                <Col md={6}>
                    <Form.Group className="mb-3" controlId="code1">
                        <Form.Label>Description</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                className="no-border"
                                required
                                placeholder="Description"
                                as={"textarea"}
                                name="Description"
                                value={values.Description}
                                onChange={handleChange}
                                isValid={ !errors.Description}
                                isInvalid={ errors.Description}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.Description}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3" controlId="code1">
                        <Form.Label>Invitation Text</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                className="no-border"
                                required
                                placeholder="Invitation text"
                                as={"textarea"}
                                name="InvitationText"
                                value={values.InvitationText}
                                onChange={handleChange}
                                isValid={ !errors.InvitationText}
                                isInvalid={ errors.InvitationText}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.InvitationText}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
        </div>


    </GenericSubTabContainer>);
};

export default BasicSettingsSubTab;
