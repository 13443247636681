import MaterialReactTable from 'material-react-table';
import { faCheck, faXmark } from '@fortawesome/pro-light-svg-icons';
import { Container, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getCompetencies } from "../../../hooks/useLmsStore";
import { useEffect, useMemo, useState } from 'react';

const ExpiryTypes = {
    InDate: "In Date",
    Expired: "Expired",
    Expiring: "Expiring",
}

export default function PersonCompetencies({ personID, dimensions }) {
    const [rowSelection, setRowSelection] = useState({});
    const [loading, setLoading] = useState(true);
    const [searchString, setSearchString] = useState('');
    const [users, setUsers] = useState([]);

    const getPersonCompetencies = async (personId) => {
        try {
            const { data } = await getCompetencies(personId);
            setUsers([...data.value.map(x => ({
                qualificationID: x?.qualificationID,
                proficiency: x?.proficiency,
                validFrom: x?.validFrom,
                validTo: x.validTo,
                validToISO: x?.validToISO,
                isOperational: x.isOperational,
                group: x?.qualificationGroup,
                qualificationName: x?.qualificationName,
                expiry: x.expiry,
                reqd: x.reqd,
            }))]);
        }
        catch (error) {
            console.log(`Error fetching person competencies: ${error}`)
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (personID > 0) {
            setLoading(true);
            getPersonCompetencies(personID);
        }
    },
        [personID]);

    const getColorForExpired = (row) => {
        const expiry = row.expiry
        const reqd = row.reqd

        if (expiry === ExpiryTypes.InDate) {
            return "text-success"
        }

        if (expiry === ExpiryTypes.Expiring) {
            return "text-warning"
        }

        if (reqd === "N" && expiry === ExpiryTypes.Expired) {
            return "text-primary"
        }

        if (reqd === "Y" && expiry === ExpiryTypes.Expired) {
            return "text-danger"
        }
        return ""
    }

    const columns = useMemo(
        () =>
            [
                {
                    accessorKey: 'group',
                    header: 'Group',
                    size: 150
                },
                {
                    accessorKey: 'qualificationName',
                    header: 'Qualification name',
                    size: 150
                },
                {
                    accessorKey: 'proficiency',
                    header: 'Proficiency',
                    size: 150
                },
                {
                    accessorKey: 'validFrom',
                    header: 'Valid from',
                    size: 30
                },
                {
                    accessorKey: 'validTo',
                    header: 'Valid to',
                    size: 30,
                    Cell: ({ cell, row }) => {
                        return <span className={`${getColorForExpired(row.original)}`} >
                            {cell.getValue()}
                        </span>

                    }
                },
                {
                    accessorKey: 'isOperational',
                    header: '',
                    size: 20,
                    Cell: ({ cell, row }) => (
                        <>
                            {
                                cell.getValue() == true ?
                                    <FontAwesomeIcon color='green' icon={faCheck} size="lg" />
                                    :
                                    <FontAwesomeIcon color='red' icon={faXmark} size="lg" />
                            }
                        </>
                    )
                }
            ],
        [],
    );

    const sortedUsers = useMemo(() => {
        if (users) {
            return [...users].sort((a, b) => a.group.localeCompare(b.group));
        }
        return [];
    }, [users]);


    return (
        <Container fluid>
            <MaterialReactTable
                columns={columns}
                data={sortedUsers || []}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableStickyHeader={true}
                enableFullScreenToggle={false}
                enableHiding={false}
                enablePagination={false}
                enableTopToolbar={true}
                enableFilterMatchHighlighting={true}
                globalFilterFn="contains"//By default is fuzzy
                enableGlobalFilter={true}
                getRowId={(originalRow) => originalRow.groupId}
                muiTableBodyRowProps={({ row }) => ({
                    onClick: () =>
                        setRowSelection((prev) => ({ [row.id]: !prev[row.id] })),
                    hover: true,
                    selected: rowSelection[row.id],
                    sx: {
                        cursor: 'pointer',
                    },
                })}
                onGlobalFilterChange={setSearchString} //zc - new for custom search
                muiTableContainerProps={({ table }) => dimensions.computedMuiTableContainerProps(table)}
                renderTopToolbarCustomActions={() => <h6 className='text-muted border border-secondary shadow p-2'>Qualifications</h6>}
                initialState={{
                    globalFilter: searchString, //zc - new for custom search
                    showGlobalFilter: true //This displays the seach generic way for the materia react table
                }}
                state={{
                    isLoading: loading || !users,
                    globalFilter: searchString,//zc - new for custom search
                }}
            />
        </Container>
    );
}
