import {apiAxiosCalls} from "../../../services/apiAxiosCalls";

class TeamProfileService
{
    constructor()
    {

    }

    GetDocumentsForPerson = async (personId) => 
    {
        // const dataContainer = {
        //     userInfo: {},
        //     documents: []
        // };
        try
        {
            const {data} = await apiAxiosCalls.GetDocumentsForPerson(personId);

            if (!data.isValid) 
            {
                throw new Error("Error getting documents list");
            }
            // console.log('data:' + JSON.stringify(data));
            return data.value;
            // dataContainer.userInfo({
            //     personID : data.value.personID,
            //     status: data.value.status,
            //     surname: data.value.surname,
            //     firstName: data.value.firstName,
            //     companyID: data.value.companyID,
            //     addressID: data.value.addressID,
            //     phoneNumber: data.value.phoneNumber,
            //     businessPhoneNumber: data.value.businessPhoneNumber,
            //     phoneExt: data.value.phoneExt,
            //     businessPhoneExt: data.value.businessPhoneExt,
            //     recordType: data.value.recordType,
            //     mobileNumber: data.value.mobileNumber,
            //     businessMobileNumber: data.value.businessMobileNumber,
            //     emailAddress: data.value.emailAddress,
            //     businessEmailAddress: data.value.businessEmailAddress,
            //     jobCodeID: data.value.jobCodeID,
            //     costCentreID: data.value.costCentreID,
            //     lineManagerID: data.value.lineManagerID,
            //     financeManagerID: data.value.financeManagerID,
            //     groupManagerID: data.value.groupManagerID,
            //     directorateID: data.value.directorateID,
            //     directorID: data.value.directorID,
            //     busUnitID: data.value.busUnitID,
            //     divisionID: data.value.divisionID,
            //     regionID: data.value.regionID,
            //     catHolder: data.value.catHolder,
            //     employStatusID: data.value.employStatusID,
            //     coGroupID: data.value.coGroupID,
            //     doNotSendEmail: data.value.doNotSendEmail,
            //     doNotSendPaperMail: data.value.doNotSendPaperMail,
            //     jobTitle: data.value.jobTitle,
            //     fullName: data.value.fullName,
            //     roleID: data.value.roleID,
            //     userid: data.value.userid,
            //     staff: data.value.staff,
            //     employeeNumber: data.value.employeeNumber,
            //     wAPgroup: data.value.wAPgroup,
            //     companyName: data.value.companyName,
            //     portalPersonID: data.value.portalPersonID,
            //     hasPortalAccess: data.value.hasPortalAccess,
            //     overallAuthorisation: data.value.overallAuthorisation,
            // });
            // dataContainer.documents.push([...data.value.map(x => ({ 
            //                             DocumentID: x.documentID, 
            //                             DocumentType: x.documentType,
            //                             DocumentMimeType: x.documentMimeType,
            //                             DocumentTitle: x.documentTitle,
            //                             Document: x.document,
            //                             Comment: x.comment,
            //                             DocumentSize: x.documentSize,
            //                             DocumentName: x.documentName,
            //                             DocumentThumb: x.documentThumb,
            //                             DateAdded: x.dateAdded,
            //                             CourseCategory: x.courseCategory
            // }))]);
        }
        catch(error)
        {
            console.error('Error getting documents list', error);
        }
        finally
        {

        };
        // return dataContainer;
    } 

    GetDocumentsWithoutBlobForPerson = async (personId) => 
    {
        // const dataContainer = {
        //     userInfo: {},
        //     documents: []
        // };
        try
        {
            const {data} = await apiAxiosCalls.GetDocumentsWithoutBlobForPerson(personId);

            if (!data.isValid) 
            {
                throw new Error("Error getting documents list");
            }
            // console.log('data:' + JSON.stringify(data));
            return data.value;
        }
        catch(error)
        {
            console.error('Error getting documents list', error);
        }
        finally
        {

        };
    } 

    getDocumentInfo = async (documentId) => 
    {
        try
        {
            const {data} = await apiAxiosCalls.GetDocumentInfo(documentId);

            if (!data.isValid)
            {
                throw new Error("Error getting document info");
            }
            return data.value;
        }
        catch(error)
        {
            console.error('Error getting document info', error);
        }
    }

    getUserInfo = async (personId) => 
    {
        try
        {
            const {data} = await apiAxiosCalls.GetUserInfo(personId);
            // const {data} = await apiAxiosCalls.GetPersonFullInfo(personId);

            if (!data.isValid)
            {
                throw new Error("Error getting user info");
            }
            return data.value;
        }
        catch(error)
        {
            console.error('Error getting user info', error);
        }
    }

    saveUserInfo = async (formData) => 
    {
        try
        {
            const { data } = await apiAxiosCalls.SaveUserInfo(formData);
            if (data.isValid)
            {
                throw new Error('Error saving user info');
            }
            return data.value;
        }
        catch(error)
        {
            console.error('Error saving user info', error);
        }

    }
}

export const teamProfileService = new TeamProfileService();