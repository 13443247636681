import { useEffect, useMemo, useState } from 'react';
import BaseContainer from "../components/BaseContainer";

import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { TableContainer } from '../helpers/tableHelper'

import MaterialReactTable from 'material-react-table';

import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';
import FormCompany from '../components/FormCompany';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/pro-regular-svg-icons';

export default function CompanyAdministration(props) {
    const [company, setCompany] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [dataSelected, setDataSelected] = useState(null);
    const [tableOption, setTableOption] = useState('all');
    const [tableInactive, setTableInactive] = useState(false);
    const [showDiv, setShowDiv] = useState(true);

    const { companies, addressTypes, allCountries, allCounties, allTowns, fetchCompanies, fetchAddressTypes, fetchAllTowns, fetchAllCountries, fetchAllCounties } = useLmsStore(state =>
    ({
        companies: state.companies.data,
        addressTypes: state.addressTypes.data,
        allCountries: state.allCountries.data,
        allCounties: state.allCounties.data,
        allTowns: state.allTowns.data,
        fetchCompanies: state.fetchCompanies,
        fetchAddressTypes: state.fetchAddressTypes,
        fetchAllTowns: state.fetchAllTowns,
        fetchAllCountries: state.fetchAllCountries,
        fetchAllCounties: state.fetchAllCounties
    }), shallow)

    // Sorting function to sort companies by companyName
    const sortedCompanies = useMemo(() => {
        if (companies) {
            return [...companies].sort((a, b) => a.companyName.localeCompare(b.companyName));
        }
        return [];
    }, [companies]);

    useEffect(() => {
        if (dataSelected) {
            setCompany(dataSelected);
            setShowForm(true);
        } else {
            setShowForm(false);
        }
    }, [dataSelected]);

    useEffect(() => {
        let keys = Object.keys(rowSelection);
        if (keys.length === 1 && rowSelection[keys[0]] === true) {
            let id = parseInt(keys[0]);
            if (companies) {
                setDataSelected(companies.find(x => x.companyId === id));
            } else {
                setDataSelected(null);
            }
        } else {
            setDataSelected(null);
        }
    }, [rowSelection]);

    const handleAdd = () => {
        setCompany([]);
        setShowForm(true);
    }

    // on load
    useEffect(() => {
        fetchCompanies();
        fetchAddressTypes();
        fetchAllTowns();
        fetchAllCountries();
        fetchAllCounties();
    }, []);

    const onOptionChange = (event) => {
        setTableOption(event.target.value);
        //  console.log('tableInactive',tableInactive);

    }

    const onInactiveChange = (event) => {
        setTableInactive(!tableInactive);
        //  console.dir(tableInactive);
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: "companyName",
                header: "Company Data"
            },
        ],
        [],
    );

    const withSelectedRows = (tableData, option, includeInactive) => option === "suppliers" ? (tableData ?? []).filter(x => (includeInactive || (includeInactive === false && x.isActive === true)) && (x.companyTypes.filter(e => e === 'training supplier' || e === 'venue').length > 0)) : option === "client" ? (tableData ?? []).filter(x => (includeInactive || (includeInactive === false && x.isActive === true)) && x.companyTypes.filter(e => e === 'client').length > 0) : (tableData ?? []).filter(e => includeInactive || (includeInactive === false && e.isActive === true))
    //const withSelectedRows = (tableData, option, includeInactive) => option==="suppliers" ? (tableData??[]).filter(x => (includeInactive || (includeInactive===false && x.isActive === true)) && x.companyTypes.filter(e => e === ('training supplier' || e === 'venue')  ).length > 0) : option==="client" ? (tableData??[]).filter(x => (includeInactive || (includeInactive===false && x.isActive === true)) &&  x.companyTypes.filter(e => e==='client').length > 0) : (tableData??[]).filter(e => includeInactive || (includeInactive===false && e.isActive === true))
    const toggleDivWidth = () => {
        setShowDiv((prevShowDiv) => !prevShowDiv);
    };
    return (
        <BaseContainer title='Companies'>
            <div style={{ marginTop: '10px', maxHeight: '900px', overflow: 'auto', overflowX: 'hidden' }}></div>

            <div style={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    }} className='float-left' onClick={() => toggleDivWidth()}>

                <OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip id="toggle-tooltip">
                            {showDiv ? 'Hide' : 'Expand'}
                        </Tooltip>
                    }
                >

                <FontAwesomeIcon icon={showDiv ? faAngleDoubleLeft : faAngleDoubleRight} style={{
                        fontSize: '24px', // Adjust size as needed
                        fontWeight: 'bold',
                        color: '#4CC7DB', // Adjust color as needed
                    }}
                />
                </OverlayTrigger>
            </div>

            <TableContainer>
                <Row>
                    <Col style={{ maxWidth: '500px', 'transition': 'width 0.9s ease' }} md={showDiv ? 4 : 0} lg={showDiv ? 3 : 0} className='border-end'>
                        {showDiv &&
                            <>
                                <div key={`inline-radio`} className="m-1 d-flex">
                                    <div className="form-check form-check-inline">
                                        <input
                                            name="group1"
                                            type="radio"
                                            id="inline-radio-all"
                                            className="form-check-input"
                                            value="all"
                                            defaultChecked
                                            onChange={onOptionChange}
                                        />
                                        <label htmlFor="inline-radio-all" className="form-check-label">All</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            name="group1"
                                            type="radio"
                                            id="inline-radio-suppliers"
                                            className="form-check-input"
                                            value="suppliers"
                                            onChange={onOptionChange}
                                        />
                                        <label htmlFor="inline-radio-suppliers" className="form-check-label">Suppliers</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            name="group1"
                                            type="radio"
                                            id="inline-radio-client"
                                            className="form-check-input"
                                            value="client"
                                            onChange={onOptionChange}
                                        />
                                        <label htmlFor="inline-radio-client" className="form-check-label">Client</label>
                                    </div>
                                </div>
                                <div className="m-1 form-check form-switch">
                                    <input type="checkbox" id="isActive" className="form-check-input" onChange={onInactiveChange} />
                                    <label title="" htmlFor="isActive" className="form-check-label">Include Inactive</label>
                                </div>

                                <MaterialReactTable
                                    columns={columns}
                                    //data={withSelectedRows(companies,tableOption, tableInactive) || []}
                                    data={withSelectedRows(sortedCompanies, tableOption, tableInactive) || []}
                                    enableBottomToolbar={true}
                                    enableColumnActions={false}
                                    enableColumnFilters={false}
                                    enableDensityToggle={false}
                                    enableStickyHeader={true}
                                    enableFullScreenToggle={false}
                                    enableGrouping={false}
                                    enableHiding={false}
                                    enablePagination={true}
                                    enableSorting={false}
                                    enableTopToolbar={true}
                                    getRowId={(originalRow) => originalRow.companyId}
                                    muiTableBodyRowProps={({ row }) => ({
                                        onClick: () =>
                                            setRowSelection((prev) => ({ [row.id]: !prev[row.id] })),
                                        hover: false,
                                        selected: rowSelection[row.id],
                                        sx: {
                                            cursor: 'pointer',
                                        },
                                    })}
                                    muiTableHeadProps={{
                                        sx: {
                                            display: 'none'
                                        }
                                    }}
                                    muiToolbarAlertBannerProps={{
                                        sx: {
                                            display: 'none'
                                        }
                                    }}
                                    muiTableContainerProps={{ sx: { maxHeight: 'max(242px, calc(100vh - 405px))' } }}
                                    renderToolbarInternalActions={({ table }) => (
                                        <>
                                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add company</Tooltip>}>
                                                <Button variant='clear' className='py-0' onClick={() => handleAdd()}>
                                                    <FontAwesomeIcon size='xl' icon={faPlus} className='text-clear' />
                                                </Button>
                                            </OverlayTrigger>
                                        </>
                                    )}
                                    initialState={{
                                        pagination: { pageIndex: 0, pageSize: 10 },
                                        showGlobalFilter: true
                                    }}
                                    state={{
                                        isLoading: !companies
                                    }}
                                />
                            </>
                        }
                    </Col>
                    <Col
                        md={showDiv ? 8 : 12} lg={showDiv ? 9 : 12}
                        style={{ 'transition': 'width 0.9s ease' }}
                    >
                        {showForm ?
                            <FormCompany company={company} addressTypes={addressTypes} allCountries={allCountries} allCounties={allCounties} allTowns={allTowns} />
                            :
                            <span></span>
                        }
                    </Col>
                </Row>
            </TableContainer>

        </BaseContainer>
    );
}