import { Col, Row } from 'react-bootstrap';
import { FilterClearButton, FilterBadgeButton } from '../../components/CustomButtons';

const LearningPlanFilterTabs = ({ tabId, filterId, todoCount, overdueCount, mandatoryCount, upComingCount, awaitingCount, completedCount, setFilterId }) => {

    return (tabId === '0' ?
        <Row className='gy-2'>
            <Col key='-1' xs='auto'>
                <FilterBadgeButton variant={todoCount > 0 ? 'info' : 'info'} title='To Do' onClick={() => setFilterId(filterId === -1 ? 0 : -1)} active={filterId === -1}>
                    {todoCount}
                </FilterBadgeButton>
            </Col>
            <Col key='-2' xs='auto'>
                <FilterBadgeButton variant={overdueCount > 0 ? 'danger' : 'danger'} title='Overdue' onClick={() => setFilterId(filterId === -2 ? 0 : -2)} active={filterId === -2}>
                    {overdueCount}
                </FilterBadgeButton>
            </Col>
            <Col key='-3' xs='auto'>
                <FilterBadgeButton variant={mandatoryCount > 0 ? 'warning' : 'warning'} title='Mandatory' onClick={() => setFilterId(filterId === -3 ? 0 : -3)} active={filterId === -3}>
                    {mandatoryCount}
                </FilterBadgeButton>
            </Col>
            <Col key='-4' xs='auto'>
                <FilterBadgeButton variant={upComingCount > 0 ? 'warning' : 'warning'} title='Upcoming' onClick={() => setFilterId(filterId === -4 ? 0 : -4)} active={filterId === -4}>
                    {upComingCount}
                </FilterBadgeButton>
            </Col>
            <Col key='-5' xs='auto'>
                <FilterBadgeButton variant={awaitingCount > 0 ? 'warning' : 'warning'} title='Awaiting Dates' onClick={() => setFilterId(filterId === -5 ? 0 : -5)} active={filterId === -5}>
                    {awaitingCount}
                </FilterBadgeButton>
            </Col>
            <Col key='-6' xs='auto'>
                <FilterBadgeButton variant={completedCount > 0 ? 'success' : 'success'} title='Completed' onClick={() => setFilterId(filterId === -6 ? 0 : -6)} active={filterId === -6}>
                    {completedCount}
                </FilterBadgeButton>
            </Col>
            <Col key='-7' xs='auto'>
                {filterId < 0 && <FilterClearButton onClick={() => setFilterId(0)} />}
            </Col>
        </Row> : <><Col></Col></>);
};

export default LearningPlanFilterTabs;