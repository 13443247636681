import ProtectedAccess from './ProtectedAccess';
import shallow from 'zustand/shallow';
import useLmsStore, {postSetAlertsAsRead} from '../hooks/useLmsStore'
import useLegacyStore from '../hooks/useLegacyStore';
import useLocationConfig from '../hooks/useLocationConfig';
import { BaseNavItem, BaseNavItemPill } from './BaseNavItem';
import { Col } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { faGears } from '@fortawesome/pro-solid-svg-icons';
import { faObjectsColumn, faList, faFileCertificate, faBookOpen, faCircleQuestion, faBell, faPeopleGroup, faCog, faScrewdriverWrench, faNewspaper, faCircleInfo, faToolbox, faFileChartColumn, faUserGear, faAddressCard } from '@fortawesome/pro-light-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { apiAxiosCalls } from '../services/apiAxiosCalls';
import { useMsal } from '@azure/msal-react';
import { useAuth } from '../auth/hooks/useAuth';
import { userService } from '../services/common/user.service';

const activateRules = [
    {
        match: [
            '/lms/applicationsettings',
            '/lms/catalogues',
            '/lms/companies',
            '/lms/learningplan',
            '/lms/reports',
            '/lms/users'
        ],
        personSettings: 'isAdministrator',
        activate: 'administration'
    },
    {
        match: [
            '/lms/clientteamprofiles',
            '/lms/newsfeeditems',
            '/lms/reports',
            '/lms/useralerts',
            '/lms/userlearningplan'
        ],
        personSettings: 'isApplicationAdministrator',
        activate: 'myadmindashboard'
    },
    {
        match: [
            '/lms/users',
            '/lms/clientteamprofiles',
            '/lms/newsfeeditems',
            '/lms/reports',
            '/lms/useralerts',
            '/lms/userlearningplan',
            '/lms/catalogues',
            '/lms/learningplan',
        ],
        personSettings: 'isSuperApplicationAdministrator',
        activate: 'mysuperadmindashboard'
    },
    {
        match: [
            '/lms/myteamprofiles',
            '/lms/reports',
            '/lms/useralerts',
            '/lms/userlearningplan'
        ],
        personSettings: 'isManager',
        activate: 'teamdashboard'
    },
    {
        match: [
            '/lms/ukpnserviceadmindashboard'
        ],
        personSettings: 'isCompetencyApprover',
        activate: 'compapproverdashboard'
    },
    {
        match: [
            '/lms/ukpnserviceadmindashboard'
        ],
        personSettings: 'isCompetencyUser',
        activate: 'competencyUser'
    },
    {
        match: [
            '/lms/accesscarddashboard'
        ],
        personSettings: 'hasAccessCard',
        activate: 'accesscarddashboard'
    },
    {
        personSettings: 'showReport',
        activate: 'userreport'
    }

];

const BaseBarCore = ({ iconSize }) => {
    const { instance } = useMsal()
    const [activate, setActivate] = useState(null)
    const [displayFullMenu, setDisplayFullMenu] = useState(false);
    const location = useLocation();
    const { isSAML } = useAuth();
    const [isAlertsRead, setIsAlertsRead] = useState(false);

    const { myLatestAlerts, myLatestPersonNews, personSettings, fetchPersonSettings, personBasic, fetchMyLatestAlerts, fetchMyLatestPersonNews, fetchPersonBasic } = useLmsStore(state =>
    ({
        myLatestAlerts: state.myLatestAlerts.data,
        myLatestPersonNews: state.myLatestPersonNews.data,
        personBasic: state.personBasic.data,
        fetchMyLatestAlerts: state.fetchMyLatestAlerts,
        fetchMyLatestPersonNews: state.fetchMyLatestPersonNews,
        fetchPersonBasic: state.fetchPersonBasic,
        personSettings: state.personSettings.data,
        fetchPersonSettings: state.fetchPersonSettings
    }), shallow)

    useEffect(
        () => {
            if (instance?.getActiveAccount()) {
                // A saml session is either a MS Entra or an OKTA session.
                // In this case, the full menu should be displayed
                setDisplayFullMenu(isSAML());
            } else {
                // If activateAccount is null then it is a local account.
                // In this case, the full menu should be displayed
                setDisplayFullMenu(true);
            }
        }, [instance]);

    useEffect(() => {
        fetchPersonBasic();
        fetchMyLatestAlerts();
        fetchMyLatestPersonNews();
    }, []);

    useEffect(() => {
        let activate = null;
        if (personSettings) {
            for (let i = 0; i < activateRules.length; i++) {
                let rule = activateRules[i];
                if (rule.personSettings) {
                    if (personSettings[rule.personSettings] === true) {
                        if (rule.match?.includes(location.pathname)) {
                            activate = rule.activate;
                            break;
                        }
                    }
                } else if (rule.match?.includes(location.pathname)) {
                    activate = rule.activate;
                    break;
                }
            }
        }
        setActivate(activate);
    }, [location, personSettings]);

    useEffect(() => {

        const subscription = userService.getIsAlertsRead().subscribe(value => 
        {
            setIsAlertsRead(value);
        }); 
        let totalReadAlerts = myLatestAlerts?.filter(x=> x.isRead == true);
        
        if (totalReadAlerts && totalReadAlerts.length == myLatestAlerts?.length)
            {
                setIsAlertsRead(true);
            }            

        return () => subscription.unsubscribe();

    }, [isAlertsRead, myLatestAlerts]);

    const changeAlertsReadStatus = (event) => 
        {
            //event.preventDefault();
            let isAlertsRead = userService.IsAlertsRead;

            let totalUnReadAlerts = myLatestAlerts.filter(x=> x.isRead == false);
            if (totalUnReadAlerts && totalUnReadAlerts.length > 0 && isAlertsRead == false)
            {
                if (personSettings)
                {
                    postSetAlertsAsRead(useLegacyStore, personSettings?.portalPersonId).then(result => 
                        {
                            userService.IsAlertsRead = (result == true);
                        })
                }
            }
            else if (totalUnReadAlerts.length == 0)
            {
                userService.IsAlertsRead = true;
            }
        }

    return (
        <>
            {displayFullMenu && <BaseNavItem to='/lms/dashboard' label='Dashboard' icon={faObjectsColumn} iconSize={iconSize} />}
            <BaseNavItem to='/lms/mylearningplan' label='My Learning Plan' icon={faList} iconSize={iconSize} />
            {displayFullMenu && <>
                <BaseNavItem to='/lms/courselibrary' label='Library' icon={faBookOpen} iconSize={iconSize} />
                <BaseNavItem to='/lms/faqs' label='Support' icon={faCircleQuestion} iconSize={iconSize} />
                {
                    !isAlertsRead ?
                    <BaseNavItemPill 
                        to='/lms/alerts' 
                        label='Notifications' 
                        icon={faBell} 
                        iconSize={iconSize} 
                        countVariant='warning' 
                        countClass='text-dark' 
                        count={ myLatestAlerts?.filter(x=> x.isRead == false)?.length } 
                        onClick = {(e) => changeAlertsReadStatus(e)}
                    />
                    :
                    <BaseNavItemPill to='/lms/alerts' label='Notifications' icon={faBell} iconSize={iconSize} countVariant='warning' />
                }
                
                <BaseNavItemPill to='/lms/news' label='News' icon={faNewspaper} iconSize={iconSize} countVariant='warning' countClass='text-dark' count={!myLatestPersonNews || myLatestPersonNews.length === 0 ? null : myLatestPersonNews.length} />

                <ProtectedAccess
                    adminComponent={<>
                        <BaseNavItem to='/lms/teamdashboard' isActive={activate === 'teamdashboard'} label='Team Dashboard' icon={faPeopleGroup} iconSize={iconSize} />
                        <BaseNavItem to='/lms/mysuperadmindashboard' isActive={activate === 'mysuperadmindashboard'} label='Admin Super User Dashboard' icon={faGears} iconSize={iconSize} />
                        <BaseNavItem to='/lms/myadmindashboard' isActive={activate === 'myadmindashboard'} label='My Admin Dashboard' icon={faCog} iconSize={iconSize} />
                        <BaseNavItem to='/lms/administration' isActive={activate === 'administration'} label='Admin Dashboard' icon={faScrewdriverWrench} iconSize={iconSize} />
                    </>}
                    competencyUserComponent={<BaseNavItem href={apiAxiosCalls.hrefToAdminServer(personBasic, 'portal.mydashboard')} isActive={activate === 'competencyUser'} label='User Competencies' icon={faUserGear} iconSize={iconSize} />}
                    userReportComponent={<BaseNavItem href={apiAxiosCalls.hrefToAdminServer(personBasic, 'portal.myReports')} isActive={activate === 'userreport'} label='Reports' icon={faFileChartColumn} iconSize={iconSize} />}
                    appSuperAdminComponent={<BaseNavItem to='/lms/mysuperadmindashboard' isActive={activate === 'mysuperadmindashboard'} label='Admin Super User Dashboard' icon={faGears} iconSize={iconSize} />}
                    appAdminComponent={<BaseNavItem to='/lms/myadmindashboard' isActive={activate === 'myadmindashboard'} label='My Admin Dashboard' icon={faCog} iconSize={iconSize} />}
                    managerComponent={<BaseNavItem to='/lms/teamdashboard' isActive={activate === 'teamdashboard'} label='Team Dashboard' icon={faPeopleGroup} iconSize={iconSize} />}
                    competencyApproverComponent={<BaseNavItem to='/lms/ukpnserviceadmindashboard' isActive={activate === 'compapproverdashboard'} label='UKPN Services Admin Dashboard' icon={faToolbox} iconSize={iconSize} />}
                    accessCardComponent={<BaseNavItem to='/lms/accesscarddashboard' isActive={activate === 'accesscarddashboard'} label='Access Card Dashboard' icon={faAddressCard} iconSize={iconSize} />}
                    ukpnComponent={<BaseNavItemPill to='/lms/qualifications' label='My Qualifications ' icon={faFileCertificate} iconSize={iconSize} countVariant='warning' countClass='text-dark' />
                    }
                />
                <BaseNavItem to='/lms/about' label='About' icon={faCircleInfo} iconSize={iconSize} />
            </>}
        </>
    );
}

export default function BaseBar({ dimensions }) {
    const { themeName } = useLocationConfig();
    const [logo, setLogo] = useState('')
    const { containerHeight, isLandscape, isSmall } = dimensions;

    useMemo(() => {
        if (isLandscape && themeName) {
            setLogo(require(`../assets/instance/${themeName}/icon_small.png`));
        }
    }, [isLandscape, themeName]);

    return (
        isLandscape && !isSmall ?
            <Col xs='auto'>
                <Nav id='id-basebar-wrapper' className='justify-content-center' style={{ display: 'inline-block', width: '82px' }}>
                    <div style={{ height: '58px', maxHeight: '58px' }}>
                        <img
                            src={logo}
                            style={{
                                margin: '1rem 0 0 4px',
                                maxHeight: '28px',
                                width: '74px'
                            }}
                        />
                    </div>
                    <div className='text-center' style={{ height: `${containerHeight - 115}px`, overflowX: 'hidden', overflowY: 'auto' }}>
                        <BaseBarCore iconSize={isSmall ? null : 'lg'} />
                    </div>
                </Nav>
            </Col>
            :
            <Nav id='id-basebar-wrapper' className='justify-content-center' sticky='top'>
                <BaseBarCore iconSize={isSmall ? null : 'lg'} />
            </Nav>
    );
}