import React from 'react';
import GenericSubTabContainer from "../../common/GenericSubTabContainer";
import {Col, Form, InputGroup, Row} from "react-bootstrap";

const InstructorLedCourseSettings = ({form: {handleSubmit, handleChange, values, touched, errors, handleBlur}}) => {
    return (<GenericSubTabContainer title={"Instructor Led Course Settings"}>
        <Row className={"w-100"}>
            <Col md={6}>
                <Form.Group className="mb-3" controlId="code1">
                    <Form.Label>Minimum number of Delegates</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            placeholder="Minimum number of Delegates"
                            type="number"
                            name="MinDelegates"
                            value={values.MinDelegates}
                            onChange={handleChange}
                            isValid={touched.MinDelegates && !errors.MinDelegates}
                            isInvalid={touched.MinDelegates && errors.MinDelegates}
                            onBlur={handleBlur}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.MinDelegates}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className="mb-3" controlId="658687">
                    <Form.Label>Maximum number of Delegates <span title='Mandatory field'
                                                                  className='mandatory'>*</span></Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="number"
                            name="MaxDelegates"
                            value={values.MaxDelegates}
                            placeholder={"Maximum number of Delegates"}
                            onChange={handleChange}
                            isValid={touched.MaxDelegates && !errors.MaxDelegates}
                            isInvalid={touched.MaxDelegates && errors.MaxDelegates}
                            onBlur={handleBlur}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.MaxDelegates}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </Col>
        </Row>
    </GenericSubTabContainer>);
};

export default InstructorLedCourseSettings;
