import React, {useEffect, useMemo, useState} from 'react';
import GenericSubTabContainer from "../../common/GenericSubTabContainer";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import useLmsStore, {setLearningItemsState} from "../../../hooks/useLmsStore";

const ElearningSettingsSubTab = ({
                                     form: {
                                         handleSubmit,
                                         handleChange,
                                         values,
                                         touched,
                                         errors,
                                         handleBlur,
                                         setFieldValue
                                     }
                                 }) => {
    return (
        <GenericSubTabContainer title={'Elearning Settings '}>
            <Row className={"w-100"}>
                <Col md={12}>

                    <Form.Group className="mb-3" controlId="code1">
                        <Form.Check
                            type={"checkbox"}
                            label={"Is Externally Hosted eLearning"}
                            name={"IsExternallyHosted"}
                            checked={values.IsExternallyHosted}
                            onChange={handleChange}
                        />
                    </Form.Group>

                </Col>

            </Row>
            {values.IsExternallyHosted && <Row className={"w-100"}>
                <Col md={12}>
                    <Form.Group className="mb-3" controlId="code2">
                        <Form.Label>Url <span title='Mandatory field' className='mandatory'>*</span></Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                name={"Url"}
                                type="input"
                                placeholder="Url"
                                className="no-border"
                                value={values.Url}
                                onChange={handleChange}
                                isValid={touched.Url && !errors.Url}
                                isInvalid={touched.Url && errors.Url}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.Url}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>}

        </GenericSubTabContainer>
    );
};

export default ElearningSettingsSubTab;
