import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CustomTableView from '../../../components/common/generic-table-view';
import HeadingWithBackButton from '../../../components/common/heading-with-back-button';
import MultiSelectDropdown from '../../../components/common/multi-select-dropdown';
import React from "react";
import RequestCourseRequiresAuthentication from '../../shared/request-course-requires-authentication';
import Swal from "sweetalert2";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { faFileContract,  faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useMemo, useState } from "react";
import { catalogService } from "../services/catalog.service";
export default function RequestCourseTrainingEmployees(props) {
    const [courseAssigned, setCourseAssigned] = useState(false);
    const [authorisationRequired, setAuthorisationRequired] = useState(false);
    const [currentRow, setCurrentRow] = useState();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
       
        if (props.staffList && props.staffList.length > 0) {
            let totalCourseAssigned = props.staffList.filter(x => x.SelectCheckBox == true);           
            setCourseAssigned(totalCourseAssigned?.length > 0);
        }
    },
        [props.staffList])

    const authorizationRequiresButtonEventHandler = (event, row) => {
        event.preventDefault();
        setAuthorisationRequired(true);
        setCurrentRow(row);
    };

    const onSubmitAuthorizationRequiredForm = (row, authenticated) => {
        setAuthorisationRequired(false);
        let staffListCopy = [...props.staffList];//structuredClone(props.staffList);
        const item = staffListCopy.find(x => x.Index == row.original.Index);
        if (item) {
            item.SelectCheckBox = authenticated;//(item.BusinessDropDown && authenticated);
            item.Authenticated= authenticated;
            item.BusinessDropDownDefaultValue = row.original.BusinessDropDownDefaultValue;
            props.staffList[item.Index] = item;
            props.onUpdateStaffList(staffListCopy);
        }
    }

    const requestCourseRequiresAuthentication = (event) => {
        event.preventDefault();
        setAuthorisationRequired(false);
    }

    const onDropDownChange = (event, selected, selectedValue, cell) => {
        event.preventDefault();
        const row = cell.row;
        let staffListCopy = [...props.staffList];//structuredClone(props.staffList);
        const item = staffListCopy.find(x => x.Index == row.original.Index);
      
        if (item) {
            let columnName = cell.column.id.toLowerCase();
         
            switch (columnName) {
                case 'BusinessDropDown'.toLowerCase():
                    {
                        // Location not required - Task 526 so set location to 1st location if available
                        if (row.original.CourseTowns?.length) {
                            item.LocationDropDown = row.original.CourseTowns[0];
                        }
                        item.BusinessDropDown = selected;
                        item.BusinessDropDownDefaultValue = selectedValue;
                        break;
                    }
            }
            item.SelectCheckBox = (item.BusinessDropDown && item.Authenticated);
            props.staffList[item.Index] = item;
    
            props.onUpdateStaffList(staffListCopy);
        }
    }

    const submitRequestHandler = (event) =>{
       
        event.preventDefault();
        
        let selectedPeople = props.staffList.filter(x=> x.SelectCheckBox==true).map(x1 => {
            return {
               
                lmsPersonId: x1.personID,
                businessJustification: x1.BusinessDropDownDefaultValue,
                ApprovalLevel: x1.ApprovalLevel
               
            };
        }); 
       
        if (selectedPeople.length>0){        
    
        let inputParams = 
        {        
          courseId: props.courseInfo.CourseID,
          peopleRequest: selectedPeople,
          approvalLevel: selectedPeople[0].ApprovalLevel
        };
       
        console.log('inputParams',inputParams);
         catalogService.SaveCourseRequest(inputParams).then(result => 
            {
                
                let message = "<div class='align-left'><p>Booking request completed sucessfully for the following users:</p>";
                let staffListCopy = structuredClone(props.staffList);
                
                for (const person of result){             
                const item = staffListCopy.find(x => x.personID == person.lmsPersonId);
                if (item) {                  
                    item.statusName = person.statusName;   
                    item.SelectCheckBox = false;
                    item.BusinessDropDownDefaultValue = "1";
                    item.BusinessDropDown = false;
                    message   = message + "<p>" + item.fullname+ "</p>";   
                    props.staffList[item?.Index] = item;        
                }
             
                }                 
                message = message + '</div>';
               
                props.onSubmit(staffListCopy);

                Swal.fire({
                    title: "Success!",
                    html:  message,
                    icon: "success"
                }).then((result)=> {
                 //ZC - I have to refresh the page as I could not find a way to clear the selected value of the dropdown after 
                 // the user submit so if we leave it like that, it will affect the logic of the page so for now we force a refresh 
                 window.location.reload();
                });
               
                
            })        
        .finally(() => 
        {
            setCourseAssigned(false);
            //setLoading(false);
            //console.log('getting staff list:' + JSON.stringify(staffList));
        });
    }
    }

    const requestTrainingSaveButtonHandler = (event) => {
       
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className=" card" style={{ 'width': '25rem' }}>
                        <div className="card-body">
                            <h5 className="card-title">Submit Request Confirmation</h5>
                            <p className="card-text">By Submitting this request, you are confirming that all employees meet the prerequisites for this course and if the employees fail to meet the course prerequisites or fail to complete the course their CC could be charged for them to be placed back on the waiting list</p>
                            <div className="d-flex justify-content-center">
                                <Button
                                    variant='btn btn-outline-primary'
                                    onClick={onClose}
                                    style={{ 'width': '100px' }}
                                >
                                    Cancel
                                </Button>&nbsp;
                                <Button
                                    variant='btn btn-outline-primary'
                                    style={{ 'width': '100px' }}
                                    onClick={() => {
                                        submitRequestHandler(event);
                                        onClose();
                                       /* Swal.fire({
                                            title: "Success!",
                                            text: "Your Data has been saved.",
                                            icon: "success"
                                        });
                                        onClose(); */
                                    }
                                    }
                                >
                                    Continue
                                </Button>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    };

    const onCellClickEventHandler = (event, cell) => {
        event.preventDefault();
    }

    const onRowClickEventHandler = (event, row) => {
        event.preventDefault();
    }


    const columns = useMemo(
        () => {
            return [
                {
                    accessorKey: 'SelectCheckBox',
                    header: '',
                    size: 100,
                    Cell: ({ cell, row }) => {
                        return (<>
                            <div
                                style={{ display: 'none' }}
                            >
                                <div>{'selection: ' + (row.original.BusinessDropDown == true)}</div>
                                <div>{'business: ' + row.original.BusinessDropDown}</div>
                            </div>
                            {row.original.statusName!=''  && !row.original.isCompleted?<>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{row.original.statusName}</Tooltip>}>
                                    <FontAwesomeIcon size='xl' icon={faCircleExclamation} className='text-primary' />
                                    </OverlayTrigger>
                                  </>:<></>
                                  }
                             {(row.original.isCompleted || row.original.statusName=='')?
                                 <>  
                            <div className="form-check">
                            { row.original.isCompleted?
                                   <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{row.original.statusName}</Tooltip>}>
                                    <FontAwesomeIcon size='xl' icon={faCircleExclamation} className='text-primary' />
                                    </OverlayTrigger>:<></>}
                                <input
                                    className="form-check-input"
                                    disabled
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onChange={(event) => { event.preventDefault() }}
                                    checked={row.original.BusinessDropDown && row.original.Authenticated}
                                />
                            </div>
                            </>:<></>} 
                        </>)
                    }
                },

                {
                    accessorKey: 'fullname',
                    header: 'Name',
                    size: 200
                },
                {
                    accessorKey: 'employeeID',
                    header: 'Employee ID',
                    size: 100,

                },
                {
                    accessorKey: 'BusinessDropDown',
                    header: 'Training Reason',
                    size: 150,
                    Cell: ({ cell, row }) => {
                        return (<>
                            {(row.original.isCompleted==true || row.original.statusName=='')?                               
                            <>
                                <MultiSelectDropdown 
                                    data={row.original.businessJustification} 
                                    cell = {cell} 
                                    defaultValue = {row.original.BusinessDropDownDefaultValue}
                                    onChange = {onDropDownChange}
                                />
                            </>:<></>}</>)                   
                        }
                    },
                {
                    accessorKey: 'IsAuthorisationRequired',
                    header: 'Business Justification Text',
                    size: 100,
                    Cell: ({ cell, row }) => {
                        return (<> 
                               {
                               row.original.BusinessDropDown == true && (row.original.ApprovalLevel>2)?
                               ( cell.getValue() == true  && row.original.Authenticated == false)   ?
                               <>
                                      <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add Business Justification Text</Tooltip>}>

                                        <Link to='/' onClick={(event) => {
                                            authorizationRequiresButtonEventHandler(event, row);
                                        }}>
                                            <FontAwesomeIcon color='red' icon={faFileContract} size="lg" />
                                        </Link>
                                    </OverlayTrigger>
                                    </>
                                    : 
                                    <FontAwesomeIcon color='green' icon={faFileContract} size="lg" />
                               :<></>     
                            }
                        </>)
                    }
                }
            ]
        }
        ,
        [],
    );

    return (
        <>
            {
                props.staffList?.length > 0 && authorisationRequired == false ?
                    <>
                        <HeadingWithBackButton
                            Title={props.courseInfo.CourseTitle}
                            onBackButtonClick={props.onBackButtonClick}
                        >

                        </HeadingWithBackButton>
                        <CustomTableView
                            users={props.staffList}
                            columns={columns}
                            loading={!props.staffList}
                            enableRowSelection={false}
                            enableMultiRowSelection={false}
                            rowClickEventHandler={onRowClickEventHandler}
                            cellClickEventHandler={onCellClickEventHandler}
                            enableCellClickEvent={true}
                        >
                        </CustomTableView>
                    </>
                    : ''
            }
            {
                courseAssigned && authorisationRequired == false &&
                <>
                    <div>&nbsp;</div>
                    <div className="float-right">
                        <Button
                            variant='btn btn-outline-primary'
                            style={{ marginLeft: '5px', marginRight: '10px', 'width': '100px' }}
                            onClick={(event) => { requestTrainingSaveButtonHandler(event) }}
                        >
                            Submit 
                        </Button>
                        <Button
                            variant='btn btn-outline-primary'
                            style={{ marginLeft: '5px', marginRight: '10px', 'width': '100px' }}
                            onClick={(event) => { props.onBackButtonClick(event) }}
                        >
                            Cancel
                        </Button>

                    </div>
                </>
            }
            {
                authorisationRequired == true ?
                    <RequestCourseRequiresAuthentication
                        courseInfo={props.courseInfo}
                        onBackButtonClick={requestCourseRequiresAuthentication}
                        onBackSubmitButtonClick={onSubmitAuthorizationRequiredForm}
                        currentRow={currentRow}
                    >
                    </RequestCourseRequiresAuthentication>
                    : ''
            }
        </>
    )
}