import BaseContainer from '../components/BaseContainer';
import CompetenciesAccordian from "./CompetenciesAccordian";
import React, { useState, useEffect } from "react";
import TrainingPlanAccordian from "./TrainingPlanAccordian";
import TrainingHistoryAccordian from './TrainingHistoryAccordian';
import TrainingEvidenceAccordian from './Qualifications/Components/TrainingEvidenceAccordian';
import shallow from 'zustand/shallow';
import useLmsStore, { responseData, getUserInfo } from "../hooks/useLmsStore";
import { Badge, Card, Col, Row } from 'react-bootstrap';

const QualificationsCore = ({ dimensions }) => {
    const [userInfo, setUserInfo] = useState([]);
    const [accreditationGroupNameForUser, setAccreditationGroupNameForUser] = useState([]);

    const { data, dataTP, personSummary, personSettings, fetchAccreditationGroupNameForUser, fetchTrainingPlans, fetchTrainingHistory, fetchCompetencies, fetchPersonSummary } = useLmsStore(state =>
    ({
        personSettings: state.personSettings.data,
        data: state.userCompetencies.data,
        dataTP: state.userTrainingPlans.data,
        accreditationGroupNameForUser: state.accreditationGroupNameForUser.data,
        fetchCompetencies: state.fetchCompetencies,
        fetchTrainingPlans: state.fetchTrainingPlans,
        fetchTrainingHistory: state.fetchTrainingHistory,
        fetchAccreditationGroupNameForUser: state.fetchAccreditationGroupNameForUser,
        employeeData: state.employeeData,
        personSummary: state.personSummary.data,
        fetchPersonSummary: state.fetchPersonSummary
    }), shallow)

    const [dataTH, setDataTH] = useState([]);

    useEffect(() => {
        fetchCompetencies();
        fetchPersonSummary();
        fetchTrainingHistory(personSettings?.ukpnPersonId, true).then(async (data) => {
            setDataTH(data);
        });
        fetchAccreditationGroupNameForUser().then(async (data) => 
            {
                setAccreditationGroupNameForUser(data);
            });
    }, []);

    useEffect(() => {
        setUserInfo(personSummary);
        //setAccreditationGroupNameForUser(accreditationGroupNameForUser)
    }, [personSummary]);

    return (
        <Row className='pb-2 h-100 overflow-auto'>
            <Col>
                {userInfo !== null && userInfo !== undefined ?
                    <>
                        {/* {JSON.stringify(accreditationGroupNameForUser)} */}
                        <Card >
                            <Card.Body>
                                {accreditationGroupNameForUser && accreditationGroupNameForUser.length > 0 ? (
                                    <>
                                        <Badge bg='light' text='dark'>Accreditation Group: {accreditationGroupNameForUser[0]?.accreditationGroupName || 'N/A'}</Badge><br></br>
                                        <Badge bg='light' text='dark'>Overall Authorisation: {accreditationGroupNameForUser[0]?.overallAuthorisation || 'N/A'}</Badge><br></br>
                                        <Badge bg='light' text='dark'>Certificate Expiry: {accreditationGroupNameForUser[0]?.cycleEndDate || 'N/A'}</Badge>
                                    </>
                                ) : (
                                    <>
                                        <Badge bg='light' text='dark'>Accreditation Group: N/A</Badge><br></br>
                                        <Badge bg='light' text='dark'>Overall Authorisation: N/A</Badge><br></br>
                                        <Badge bg='light' text='dark'>Certificate Expiry: N/A</Badge>
                                    </>
                                    
                                )}
                            </Card.Body>
                        </Card>
                    </>
                    : null
                }
                {data !== null && data !== undefined && (
                    <CompetenciesAccordian header='Competencies' data={data} />
                )}
                {dataTP !== null && dataTP !== undefined && (
                    <TrainingPlanAccordian header='Training Plan' data={dataTP} />
                )}
                {dataTH !== null && dataTH !== undefined && (
                    <TrainingHistoryAccordian header='Training History' data={dataTH} />
                )}

                <TrainingEvidenceAccordian header="Training Evidence" personId={personSettings?.ukpnPersonId} />
            </Col>
        </Row>
    );
};


export default function Qualifications() {

    return (
        <BaseContainer title='My Qualifications'>
            <QualificationsCore />
        </BaseContainer>
    );
};


