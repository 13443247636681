import { Badge, Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import AppAvatar from "../components/AppAvatar";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';

import MailTo from './MailTo';

export default function UserCard(props) {
    const navigate = useNavigate();

    return (
        <Row className='mb-3'>
            <Col sm={10} md={9} lg={8} xl={7} xxl={6}>
                <Card className='mt-2 shadow' style={{ marginLeft: '0.5rem' }}>
                    <Card.Body>
                        <Row>
                            <Col sm='auto'>
                                {props.loading === true ?
                                <Spinner animation="grow" variant="success" />
                                :
                                <AppAvatar
                                    size={80}
                                    name={props.fullName}
                                    src={props.photograph}
                                />}
                            </Col>
                            <Col>
                                {props.fullName ? <>
                                    <Row>
                                        <Col sm='auto' md={7}>
                                            <h5>{props.fullName}</h5>
                                        </Col>
                                        <Col sm='auto' md={5} style={{'textAlign':'right','wordWrap':'break-word'}}>
                                            <Badge bg='light' text='dark' style={{'whiteSpace':'initial'}}>{props.jobTitle}</Badge>
                                        </Col>
                                    </Row>
                                    {props.employeeId ? <Card.Text>Employee Id: {props.employeeId}</Card.Text> : null}
                                    {props.address ? <Card.Text>Address: {props.address}</Card.Text> : null}
                                    <MailTo email={props.email? props.email:props.emailAddress}>{props.email? props.email:props.emailAddress}</MailTo>
                                    {props.phoneNumber ? <Card.Text>{props.phoneNumber}</Card.Text> : null}
                                    {props.extraPhoneNumber ? <Card.Text>{props.extraPhoneNumber}</Card.Text> : null}
                                </> :
                                    props.defaultMessage || null
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            {props.displayBackButton ?
                <Col className='d-flex align-items-end'>
                    <Button variant='outline-secondary' className='mt-2' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faChevronLeft} /><span className='m-2'>Back</span></Button>
                </Col> : null}
        </Row>
    );
}