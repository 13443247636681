import React, {useEffect, useRef} from 'react';

const ZipFileSelectorInput = ({

                           onSelect = () => null,
                           onClear = () => null,
                           triggerClear = 0
                       }) => {

    const inputRef = useRef(null);

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            let file = e.target.files[0];


            onSelect(file);
        }
    };

    const clearImage = () => {
        if (inputRef.current) {
            inputRef.current.value = null;
        }
        onClear();
    };

    useEffect(() => {
        if (triggerClear) {
            clearImage()
        }
    }, [triggerClear])

    return (
        <div>
            <div className="input-group mb-3">
                <input
                    type="file"
                    className="form-control"
                    ref={inputRef}
                    accept=".zip"
                    onChange={handleImageChange}
                />
                <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={clearImage}
                >
                    Clear
                </button>
            </div>
        </div>
    );
};

export default ZipFileSelectorInput;
