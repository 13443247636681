import Avatar from '@mui/material/Avatar';

function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
}

function stringAvatar(props) {
    let spliName = (props.name || '?').trim().replace(/ +(?= )/g,'').split(' ');        
    if (props.size > 0) {
        return {
            sx: {
                bgcolor: stringToColor(props.name),
                width: props.size,
                height: props.size
            },
            children: spliName && spliName.length > 1 ? `${spliName[0][0]}${spliName[1][0] || ''}` : '',
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(props.name),
            },
            children: spliName && spliName.length > 1 ?  `${spliName[0][0]}${spliName[1][0] || ''}`: '',
        };
    }
}


export default function AppAvatar(props) {
    if (props && props.name) {
        if (props.src) {
            return (<Avatar alt={props.name} src={props.src} sx={props.size > 0 ? { width: props.size, height: props.size } : {}} />);
        } else {
            return (<Avatar {...stringAvatar(props)} />);
        }
    } else {
        return <div sx={{ width: props.size || 40, height: props.size || 40 }}></div>;
    }
}
