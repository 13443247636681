import { useState } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import * as daysjs from "dayjs";

const ViewEventModel = ({
  closeFn = () => null,
  open = false,
  okToOpen = false,
  eventStartDate,
  upcomingEventsSummary,
}) => {
  let shouldOpen = open;

  if (!okToOpen && upcomingEventsSummary && upcomingEventsSummary.length == 0) {
    shouldOpen = false;
  }

  return (
    <Modal
      fullscreen="md-down"
      style={{
        minWidth: "600px",
        maxWidth: "600px !important",
        maxHeight: "400px !important",
      }}
      show={shouldOpen}
      onHide={closeFn}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div className="row">
          <div style={{ fontSize: "15px" }}>
            Total Events: {upcomingEventsSummary?.length}
          </div>
          <div style={{ fontSize: "19px" }}>
            Event Date: {daysjs(eventStartDate).format("DD/MM/YYYY")}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "400px", overflowY: "scroll" }}>
        {upcomingEventsSummary?.length > 0 ? (
          upcomingEventsSummary.map((upcomingEventSummary, index) => {
            return (
              <div
                key={index}
              >
                <div className="row">
                  <div className="col-3">Full Name:</div>
                  <div className="col-9">{upcomingEventSummary?.fullName}</div>
                </div>
                <div className="row">
                  <div className="col-3">Course Code:</div>
                  <div className="col-9">
                    {upcomingEventSummary?.courseCode}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">Course Title:</div>
                  <div className="col-9">
                    {upcomingEventSummary?.courseTitle}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">Event Time</div>
                  <div className="col-9">
                    {upcomingEventSummary?.startTime} -{" "}
                    {upcomingEventSummary?.endTime}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">Status</div>
                  <div className="col-9">{upcomingEventSummary?.status}</div>
                </div>
                <div>
                  <hr></hr>
                </div>
              </div>
            );
          })
        ) : (
          <div
            className="row"
            style={{
              paddingTop: "30px",
              paddingBottom: "30px",
              fontSize: "22px",
              textAlign: "center",
            }}
          >
            <div className="col-12">No Event found</div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={closeFn}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewEventModel;
