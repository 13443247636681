import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";

export default function PathwayCard(props) {
  const navigate = useNavigate();

  return (
    <>
      <Row className="mb-3">
        <Col sm={10} md={9} lg={8} xl={7} xxl={6}></Col>
      </Row>
      <Row className="mb-3">
        <Col sm={10} md={9} lg={8} xl={7} xxl={6}>
          <Card className="mt-2 shadow" style={{ marginLeft: "0.5rem" }}>
            <Card.Body>
              <Row>
                <Col>
                  <Row>
                    <Col sm="auto">
                      <div>
                        <b>Learning Pathway Name</b>
                      </div>
                      <div>{props.name}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        {props.displayBackButton ? (
          <Col className="d-flex align-items-end">
            <Button
              variant="outline-secondary"
              className="mt-2"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span className="m-2">Back</span>
            </Button>
          </Col>
        ) : null}
      </Row>
    </>
  );
}
