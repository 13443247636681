import React, {useState} from 'react';
import ZipFileSelectorInput from "./ZipFileSelectorInput";
import GenericSubTabContainer from "../../common/GenericSubTabContainer";
import {Button, Spinner} from "react-bootstrap";
import {apiAxiosCalls} from "../../../services/apiAxiosCalls";
import useLmsStore, {toastError, toastSuccess} from "../../../hooks/useLmsStore";

const SelectAndUploadScormFile = ({onUploadSuccess = () => null,isNew=true, rusticiCourseId = null}) => {

    const [file, setFile] = useState(null)
    const [triggerClear, setTriggerClear] = useState(0)
    const [isUploading, setIsUploading] = useState(false)
    const [validateResponse, setValidateResponse] = useState(false)

    const {learningItemsState} = useLmsStore((state) => {
        return {
            learningItemsState: state.learningItemsState
        }
    })

    const {
        activeLearningItemDetail,
        activeLearningItem
    } = learningItemsState;

    const onSelect = (file) => {
        setFile(file)
    }

    const onClear = () => {
        setFile(null)
    }

    const uploadCourse = async () => {
        try {
            setIsUploading(true)
            const {data} = await apiAxiosCalls.uploadCourseScormFile(-1, file)
            if (!data.IsValid) {
                throw new Error("Error uploading file")
            }
            const res = await apiAxiosCalls.validateElearningCourse(file.name)
            const dataFromValidate = res.data
            if (!dataFromValidate.IsValid) {
                throw new Error("Error validating file")
            }
            setValidateResponse(dataFromValidate.Value[0])

            const publishRes = await apiAxiosCalls.publishElearningCourse({
                AppLearnItemId: activeLearningItem.applicationLearningItemid,
                CourseName: null,
                ElearnSchemaVersion: dataFromValidate.Value[0],
                IsNew: isNew,
                RusticiCourseId: rusticiCourseId,
                SelectedFileName: file.name,
            })

            const publishData = publishRes.data
            if (!publishData.IsValid) {
                throw new Error("Error publishing course")
            }

            setTriggerClear(prev => prev + 1)
            toastSuccess("Course uploaded successfully")
            onUploadSuccess()
        } catch (e) {
            toastError({message: "An error has occurred"})
        }
        setIsUploading(false)

    }

    return (
        <GenericSubTabContainer title={"Select and upload a scorm file"}>
            <ZipFileSelectorInput onSelect={onSelect} onClear={onClear} triggerClear={triggerClear}/>
            <Button
                type={"submit"}
                className={"w-100 mb-3   mt-2 text-center"}
                disabled={!file || isUploading}
                onClick={() => {
                    uploadCourse()
                }}
            >
                {isUploading ? <Spinner animation={"border"} /> : "Publish"}


            </Button>
        </GenericSubTabContainer>
    );
};

export default SelectAndUploadScormFile;
