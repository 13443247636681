import React, { useMemo } from 'react';
import MaterialReactTable from "material-react-table";
import { Button, Form, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { apiAxiosCalls } from "../../services/apiAxiosCalls";
import { toastError, toastSuccess } from "../../hooks/data";

const OutActionsNotAttendance = ({
    dimensions,
    getOutActionsNotAttendance,
    tableData,
    reasons,
    initialData,
    loading
}) => {


    const updateNoShowReasons = async (data) => {
        try {
            await apiAxiosCalls.UpdateNoShowReasons(data)
            await getOutActionsNotAttendance()
            toastSuccess("Reasons for non attendance updated successfully")
        } catch (e) {
            toastError({ message: "An error occured" })
            console.log(e)
        }
    }


    const renderOptions = () => {
        return reasons.map(r => (
            <option value={r.cancelReason} key={r.cancelReasonID}>{r.cancelReason}</option>
        ));
    };

    const initialValues = tableData.map(oa => ({
        actionId: oa.actionID,
        reason: oa.noShowReason || '',
        bookingId: oa.bookingID || '',
    }));

    const constructPayload = (changedValues) => {
        const payload = changedValues.map(dto => {
            const index = reasons.findIndex(r => r.cancelReason == dto.reason)
            return {
                ...dto,
                reasonId: reasons[index].cancelReasonID
            }
        })
        return payload
    }


    const formik = useFormik({
        initialValues: { data: initialValues },
        enableReinitialize: true,
        onSubmit: async (values) => {
            const changedValues = values.data.filter((item, index) => {
                const initialItem = initialData[index];
                return initialItem && (item.reason !== initialItem.reason);
            });

            await updateNoShowReasons(constructPayload(changedValues))
        },
    });

    const handleSelectChange = (e, actionID) => {
        const { value } = e.target;
        const updatedValues = formik.values.data.map(item =>
            item.actionId === actionID ? { ...item, reason: value } : item
        );
        formik.setFieldValue('data', updatedValues);
    };

    const renderSingleSelect = (row) => {
        const index = formik.values.data.findIndex(x => x.actionId === row.actionID);

        return (
            <Form.Select
                aria-label=""
                name={`data.${index}.reason`}
                value={formik.values.data[index]?.reason || ''}
                onChange={(e) => handleSelectChange(e, row.actionID)}
            >
                {renderOptions()}
            </Form.Select>
        );
    };

    const columns = useMemo(() => [
        {
            accessorKey: 'fullName',
            header: 'Employee',
            size: 200,
            Cell: ({ row }) => {
                const original = row.original;
                return (
                    <>
                        {original.firstName} {original.surname}
                        <br />
                        {original.userID}
                    </>
                );
            }
        },
        {
            accessorKey: 'courseTitle',
            header: 'Course',
            size: 300,
        },
        {
            accessorKey: 'startDate',
            header: 'Date',
            size: 150,
        },
        {
            accessorKey: 'noShowReason',
            header: 'Reason for Non-Attendance',
            size: 200,
            Cell: ({ row }) => renderSingleSelect(row.original)
        },
    ], [reasons, formik.values]);

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <MaterialReactTable
                    columns={columns}
                    data={tableData}
                    enableExpanding={false}
                    enableBottomToolbar={true}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableGrouping={false}
                    enableHiding={false}
                    enablePagination={true}
                    enableSorting={false}
                    enableTopToolbar={false}
                    muiTableBodyRowProps={{ hover: false }}
                    muiTableProps={{
                        sx: {
                            tableLayout: 'fixed',
                        },
                    }}
                    positionActionsColumn="last"
                    muiTableContainerProps={({ table }) => dimensions.computedMuiTableContainerProps(table, null, null, 64)}
                    initialState={{
                        pagination: { pageIndex: 0, pageSize: 10 },
                        showGlobalFilter: true,
                        columnVisibility: {
                            employeeId: false
                        }
                    }}
                    state={{
                        isLoading: loading
                    }}
                />
                <div className={"d-flex justify-content-end mt-3"}>
                    <Button
                        type="submit"
                        variant="primary"
                        style={{ marginRight: "1rem" }}
                        className={"d-flex align-items-center"}
                        disabled={formik.isSubmitting}
                    >
                        {formik.isSubmitting && <><Spinner animation={"border"} size={"sm"} /> &nbsp;</>}
                        Submit
                    </Button>
                    <Button
                        type="button"
                        onClick={() => {
                            formik.resetForm()
                        }}
                        variant="primary"
                        className={"d-flex align-items-center"}
                        disabled={formik.isSubmitting}
                    >
                        {formik.isSubmitting && <><Spinner animation={"border"} size={"sm"} /> &nbsp;</>}
                        Cancel
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default OutActionsNotAttendance;
