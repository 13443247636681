import Spinner from 'react-bootstrap/Spinner';
import { NavButton } from './CustomButtons';
import { Row, Col } from "react-bootstrap";

const TITLE_STYLE = { alignItems: 'center', display: 'grid', minHeight: '56px' };

export default function DashboardQualifications({ title, myQualificationsCount, paddingTop, basePath }) {
    return (
        myQualificationsCount && <>
            <Row className='gx-3'>
                <Col xs={12} style={{ paddingTop: paddingTop, paddingBottom: '0.5rem' }}>{title}
                </Col>
            </Row>
            <Row className='gx-3'>
                <Col xs={4}>
                    <NavButton variant={!myQualificationsCount.all || myQualificationsCount.all === 0 ? 'info' : 'success'} title='Current Qualifications/Competencies' to={{pathname: basePath, search: 'comp=1'}} titleStyle={TITLE_STYLE}>
                        {myQualificationsCount.all == null ? <Spinner animation="grow" variant="light" size="sm" /> : myQualificationsCount.all}
                    </NavButton>
                </Col>
                <Col xs={4}>
                    <NavButton variant={myQualificationsCount.operational === 0 ? 'info' : 'warning'} title='Operational Competencies' to={{ pathname: basePath, search: 'comp=1&filter=operational' }} titleStyle={TITLE_STYLE}>
                        {myQualificationsCount.operational == null ? <Spinner animation="grow" variant="light" size="sm" /> : myQualificationsCount.operational}
                    </NavButton>
                </Col>
                <Col xs={4}>
                    <NavButton variant={!myQualificationsCount.expiring || myQualificationsCount.expiring === 0 ? 'info' : 'warning'} title='Expiring Qualifications​' to={{ pathname: basePath, search: 'comp=1&filter=expiring' }} titleStyle={TITLE_STYLE}>
                        {myQualificationsCount.expiring == null ? <Spinner animation="grow" variant="light" size="sm" /> : myQualificationsCount.expiring}
                    </NavButton>
                </Col>
            </Row>
            <Row className='gx-3 pt-3'>
                <Col xs={4}>
                    <NavButton variant={!myQualificationsCount.expired || myQualificationsCount.expired === 0 ? 'info' : 'danger'} title='Expired Qualifications​' to={{ pathname: basePath, search: 'comp=1&filter=expired' }} titleStyle={TITLE_STYLE}>
                        {myQualificationsCount.expired == null ? <Spinner animation="grow" variant="light" size="sm" /> : myQualificationsCount.expired}
                    </NavButton>
                </Col>
                <Col xs={4}>
                    <NavButton variant={!myQualificationsCount.underPersonalSupervision || myQualificationsCount.underPersonalSupervision === 0 ? 'info' : 'warning'} title='Under Personal Supervision' to={{ pathname: basePath, search: 'comp=1&filter=supervised' }} titleStyle={TITLE_STYLE}>
                        {myQualificationsCount.underPersonalSupervision == null ? <Spinner animation="grow" variant="light" size="sm" /> : myQualificationsCount.underPersonalSupervision}
                    </NavButton>
                </Col>
            </Row>
        </>
    );
}
