import { useState, useEffect,useReducer, useRef, useMemo  } from 'react';
import { Container, Row, Col, Form, InputGroup, Modal, Button,OverlayTrigger, Tooltip } from "react-bootstrap";
import { TableContainer } from '../helpers/tableHelper';
import MaterialReactTable from 'material-react-table';
import useLmsStore, { responseData, getTrainerGroupMembers } from "../hooks/useLmsStore";

const ModalGroupsSelect = ({ closeFn = () => null, open = false, submitFn = (obj) => null, selectedData, allGroups}) => {

    const [inProgressM, setInProgressM] = useState(false);
    const [groups, setGroups] = useState([]);
    const [apply, setApply] = useState(false);
    const [headerClickState, handleHeaderClick] = useReducer(function (state, action) { return { count: state.count + 1, flag: action }; }, { count: 0, flag: 'none' }); // count to ensure a change of headerClickState on every click
     //optionally, you can manage the row selection state yourself
    const [rowSelection, setRowSelection] = useState({});
    
    const handleSubmit = async event => {
        let result = [];
        let newItemId= (-1) * selectedData.length;
        setInProgressM(true);
       const selectedGroups =allGroups.map((a) => {
       
            if (rowSelection[a.id]===true){
                if (a!=undefined){
                  return a;
                };
            }
    });

      if (selectedGroups){
         result= selectedGroups.filter((a) => a!=undefined).map((a) => {
           newItemId++;
              return  {'id':a.id,
                'name':a.name,
                'joinId': newItemId};
            
        });
    }
      
        await submitFn(result);
        setInProgressM(false);
        setApply(false); 
    };

    useEffect(() => {
        //const filteredData = (rows, selectedData) => rows.filter(x => selectedData.filter(e => e.learningItemId===x.id ).length <= 0);
   
        if (allGroups && selectedData){
          const availableLi= allGroups.filter(x =>x.name!='' && x.name!=undefined && selectedData.filter(e => e.groupId===x.id).length <= 0);
   
             
                setGroups(availableLi);
                setRowSelection({});  
        }

    }, [selectedData]);  

    const columnsItems = useMemo(
        () => [
            {
                accessorKey: "name",
                header: "Name"
            }
           
        ],
        [],
    );

  
    function ShowGroups(props) { 
        return (
            <Container fluid>
                <Row>
                    <TableContainer>
                        <MaterialReactTable
                            columns={columnsItems}
                            data={groups || []}
                            enableBottomToolbar={true}
                            enableColumnActions={false}
                            enableColumnFilters={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableGrouping={false}
                            enableHiding={false}
                            enablePagination={true}
                            enableSorting={false}
                            enableTopToolbar={true}
                            enableEditing={false}
                            getRowId={(row) => row.id}
                            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own                       
                            enableRowSelection
                            state={{ rowSelection }} //pass our managed row selection state to the table to use                       
                            initialState={{
                                pagination: { pageIndex: 0, pageSize: 10 },
                                showGlobalFilter: true
                            }}
                        />
                    </TableContainer>
                </Row> 
            </Container>
        )};

    return (
        <Modal size="lg" aria-labelledby="example-modal-sizes-title-lg" show={open} onHide={closeFn} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Add Groups</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                 <ShowGroups></ShowGroups>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={closeFn}>Close</Button>
                {   
                    inProgressM ?

                    <Button variant="primary" disabled>
                        <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                        Please wait...
                    </Button>
                    :
                    <Button variant="primary" onClick={handleSubmit}>
                        Add
                    </Button>
                }

            </Modal.Footer>
        </Modal>
    );
};

export default ModalGroupsSelect;