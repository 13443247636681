import { useEffect, useMemo, useState } from 'react';

import MaterialReactTable from 'material-react-table';

import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import LinkContainer from '../../_libs/react-router-bootstrap/LinkContainer'

import MailTo from './MailTo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faList } from '@fortawesome/pro-light-svg-icons';
import { FilterClearButton, FilterBadgeButton } from '../components/CustomButtons';
import Badge from 'react-bootstrap/Badge';
import { faFolderMagnifyingGlass, faMagnifyingGlass, faUser } from '@fortawesome/pro-solid-svg-icons';

export default function UserProfilesTable({ data, dimensions }) {
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const { computedMuiTableContainerProps } = dimensions;

    useEffect(() => {
        setTableLoading(!data);
        setTableData(data || []);
    }, [data]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'employeeId',
                header: '',
                size: 0,
                hidden: true
            }
            ,
            {
                accessorKey: 'fullName',
                header: '',
                size: 150,
                Cell: ({ cell, row }) => {
                    return (<>
                        <div style={{ 'textAlign': 'left' }}>{row.original.fullName}</div>
                        <div style={{ 'textAlign': 'left' }}><b>{row.original.employeeId}</b></div>
                    </>)
                }
            },
            {
                accessorKey: 'emailAddress',
                header: '',
                size: 150,
                Cell: ({ cell, table }) => {
                    let email = cell.getValue();
                    return email && email.includes('@') ? (<MailTo email={email}>{email}</MailTo>) : null;
                }
            },
            {
                accessorKey: 'jobTitle',
                header: '',
                size: 100
            },
            {
                accessorKey: 'totalToDo',
                header: 'To Do',
                size: 35,
                // muiTableBodyCellProps: {
                //     classes: { root: 'fw-unset' }   // Flexible width
                // },
                Cell: ({ cell, row }) => {
                    return (<>
                        <Badge pill bg={0 > 0 ? 'info' : 'info'}>{cell.getValue()}</Badge>
                    </>)
                }
            },
            {
                accessorKey: 'totalOverdue',
                header: 'Overdue',
                size: 35,
                // muiTableBodyCellProps: {
                //     classes: { root: 'fw-unset' }   // Flexible width
                // },
                Cell: ({ cell, row }) => {
                    return (<>
                        <Badge pill bg={0 > 0 ? 'danger' : 'danger'}>{cell.getValue()}</Badge>

                    </>)
                }
            },
            {
                accessorKey: 'totalMandatory',
                header: 'Mandatory',
                size: 35,
                // muiTableBodyCellProps: {
                //     classes: { root: 'fw-unset' }   // Flexible width
                // },
                Cell: ({ cell, row }) => {
                    return (<>
                        <Badge pill bg={0 > 0 ? 'warning' : 'warning'}>{cell.getValue()}</Badge>

                    </>)
                }
            },
            {
                accessorKey: 'totalCompleted',
                header: 'Completed',
                size: 35,
                // muiTableBodyCellProps: {
                //     classes: { root: 'fw-unset' }   // Flexible width
                // },
                Cell: ({ cell, row }) => {
                    return (<>
                        <Badge pill bg={0 > 0 ? 'success' : 'success'}>{cell.getValue()}</Badge>

                    </>)
                }
            },
            {
                accessorKey: 'personId',
                header: '',
                size: 110,
                Cell: ({ cell, row }) => {
                    return (
                        <>
                            <LinkContainer to={{ pathname: '/lms/user-profile', search: `id=${cell.getValue()}` }}>
                                <Button variant='clear' className='pt-0 pb-0'>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>View Employee Profile</Tooltip>}>
                                        <FontAwesomeIcon size='xl' icon={faUser} className='text-primary' />
                                    </OverlayTrigger>
                                </Button>
                            </LinkContainer>
                            <LinkContainer to={{ pathname: '/lms/userdocuments', search: `id=${cell.getValue()}` }}>
                                <Button variant='clear' className='pt-0 pb-0'>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>View Evidence Folder</Tooltip>}>
                                        <FontAwesomeIcon size='xl' icon={faFolderMagnifyingGlass} className='text-primary' />
                                    </OverlayTrigger>
                                </Button>
                            </LinkContainer>
                            <LinkContainer to={{ pathname: '/lms/userlearningplan', search: `id=${cell.getValue()}` }}>
                                <Button variant='clear' className='pt-0 pb-0'>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>View Learning Plan</Tooltip>}>
                                        <FontAwesomeIcon size='xl' icon={faList} className='text-primary' />
                                    </OverlayTrigger>
                                </Button>
                            </LinkContainer>
                            <LinkContainer to={{ pathname: '/lms/useralerts', search: `id=${cell.getValue()}` }}>
                                <Button variant='clear' className='pt-0 pb-0'>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>View Notifications</Tooltip>}>
                                        <FontAwesomeIcon size='xl' icon={faBell} className='text-primary' />
                                    </OverlayTrigger>
                                </Button>
                            </LinkContainer>
                        </>
                    )
                }
            }
        ],
        [],
    );

    return (
        <MaterialReactTable
            columns={columns}
            data={tableData}
            enableExpanding={true}
            getSubRows={(originalRow) => originalRow.employees} //default, can customize
            enableBottomToolbar={true}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            enableGrouping={false}
            enableHiding={false}
            enablePagination={true}
            enableSorting={false}
            enableTopToolbar={true}
            muiTableBodyRowProps={{ hover: false }}
            muiTableContainerProps={({ table }) => computedMuiTableContainerProps(table)}
            muiTableProps={{
                sx: {
                    tableLayout: 'fixed',
                },
            }}
            positionActionsColumn="last"
            initialState={{
                pagination: { pageIndex: 0, pageSize: 10 },
                showGlobalFilter: true,
                columnVisibility: {
                    employeeId: false
                }
            }}
            state={{
                isLoading: tableLoading
            }}
        />
    );
}
