import { useEffect, useMemo, useReducer, useRef, useState } from "react";

import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Stack,
  Tooltip,
} from "react-bootstrap";

import { ModalConfirmCancel } from "../ModalConfirmation";

import {
  toDDsMMsYYYYString,
  toISODateTimeString,
} from "../../helpers/formatHelper";

import {
  columnBlankIfWidth,
  columnIconLearningItem,
  columnIfWidth,
  muiTableBodyCellEditFieldPropsCheckboxFn,
} from "../../helpers/tableHelper";

import useLmsStore, {
  postAdminTrainingPlanGroupInfo,
} from "../../hooks/useLmsStore";
import shallow from "zustand/shallow";

import MaterialReactTable from "material-react-table";
import LearningPathwayEditorGroupTableDate from "./LearningPathwayEditorGroupTableDate";
import LearningPathwayEditorGroupTableSelect from "./LearningPathwayEditorGroupTableSelect";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCircleCheck,
  faSquare,
  faSquareCheck,
  faSquareMinus,
  faChevronsLeft,
  faChevronsRight,
} from "@fortawesome/pro-light-svg-icons";

export default function LearningPathwayEditorGroupTable(props) {
  const [dateToApply, setDateToApply] = useState("");
  const [edit, setEdit] = useState(false);
  const [editMode, setEditMode] = useState("2");
  const [headerClickState, handleHeaderClick] = useReducer(
    function (state, action) {
      return { count: state.count + 1, flag: action };
    },
    { count: 0, flag: "none" }
  ); // count to ensure a change of headerClickState on every click
  const [showCancelEditModal, setShowCancelEditModal] = useState(false);
  const [showCancelModeModal, setShowCancelModeModal] = useState(0); // Store the edit mode id
  const [showCancelSelectModal, setShowCancelSelectModal] = useState(0); // Store the id
  const [tableLoading, setTableLoading] = useState(true);
  const headerSquareCheckRef = useRef();
  const headerSquareMinusRef = useRef();
  const headerSquareRef = useRef();

  const { fethPathwayItems } = useLmsStore(
    (state) => ({
      fethPathwayItems: state.fethPathwayItems,
    }),
    shallow
  );

  useEffect(() => {
    initDataLeft(initDataPromise, []);
    // eslint-disable-next-line
  }, [props.dataSelected]);

  useEffect(() => {
    setEditMode(edit ? "2" : "1");
    //  props.onEditChanged(edit);
    // eslint-disable-next-line
  }, [edit]);

  useEffect(() => {
    initDataLeft(initDataPromise, editMode === "2" ? null : []).then(() => {
      if (editMode !== "2") {
        setDateToApply("");
      }
      if (!editModeLessThan2()) {
        refreshGlobalInputCheckbox();
      }
      // Allow selections on both sides when in mode 2
      props.setOptions(
        editMode !== "2"
          ? { exclusiveSelection: true, leftId: "id", rightId: "id" }
          : { exclusiveSelection: false, leftId: "id", rightId: "personId" }
      );
    });
    // eslint-disable-next-line
  }, [editMode]);

  useEffect(
    () => refreshGlobalInputCheckbox(),
    // eslint-disable-next-line
    [props.hasSelectedLeft]
  );

  useEffect(() => {
    props.selectLeft(headerClickState.flag);
    // eslint-disable-next-line
  }, [headerClickState]);

  const editModeLessThan2 = () => parseInt(editMode) < 2;

  const initDataLeft = (initDataPromiseFn, initDataRightParameter) => {
    return new Promise((res) => {
      setTableLoading(true);
      if (props.dataSelected && props.dataSelected.id > 0) {
        initDataPromiseFn().then((data) => {
          props.initData(structuredClone(data || []), initDataRightParameter);
          setTableLoading(false);
          res(data);
        });
      } else {
        props.initData([], []);
        setTableLoading(false);
        res([]);
      }
    });
  };

  const initDataPromise = () =>
    fethPathwayItems(props.pathway.learningProgrammeGroupId);

  const handleCellClick = (cell, event) => {
    if (cell.column.id === "isRowSelected" || !editModeLessThan2()) {
      if (editMode === "2") {
        if (props.hasUpdates) {
          setShowCancelSelectModal(cell.row.original.learningItemId);
        } else {
          props.toggleLeft(cell.row.original.id, true);
        }
      } else {
        props.toggleLeft(cell.row.original.learningItemId, true);
      }
    }
  };

  const handleCancelEdit = () => {
    if (props.hasUpdates) {
      setShowCancelEditModal(true);
    } else {
      resetToRO();
    }
  };

  const handleCellChanged = (cell, event) => {
    alert("cell changed 1");
    if (cell.column.id === "hide") {
      alert("cell changed 2");
      props.updateBaseLeft(
        props.dataLeft
          .filter((x) => x.learningItemId === cell.row.original.learningItemId)
          .map((x) => {
            return { ...x, hide: event.target.checked === true };
          })
      );
    } else if (cell.column.id === "expiryDate") {
      alert("cell changed 3");
      props.updateBaseLeft(
        props.dataLeft
          .filter((x) => x.learningItemId === cell.row.original.learningItemId)
          .map((x) => {
            return {
              ...x,
              expiryDate: toISODateTimeString(event.target.value),
              expiryDateStr: toDDsMMsYYYYString(event.target.value),
            };
          })
      );
    }
  };

  const handleConfirmCancelEdit = () => {
    setShowCancelEditModal(false);
    resetToRO();
  };

  const handleConfirmMode = () => {
    if (showCancelModeModal > 0) {
      setEditMode(showCancelModeModal);
      setShowCancelModeModal(0);
    }
  };

  const handleConfirmModeCancel = () => {
    setShowCancelModeModal(0);
  };

  const handleConfirmSelect = () => {
    if (showCancelSelectModal > 0) {
      props.toggleLeft(showCancelSelectModal, true);
      setShowCancelSelectModal(0);
    }
  };

  const handleConfirmSelectCancel = () => {
    setShowCancelSelectModal(0);
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleOnSave = () => {
    let items = props.dataLeft;
    items.forEach((item) => {
      item.joinId = item.joinId || -1; // New entries must default to -1
      delete item.isRowSelected;
    });
    initDataLeft(
      () =>
        postAdminTrainingPlanGroupInfo(useLmsStore, {
          groupId: props.dataSelected.id,
          learningItems: items,
        }),
      true
    );
  };

  const handleEditModeChange = (event) => {
    if (props.hasUpdates) {
      setShowCancelModeModal(event.target.value);
    } else {
      setEditMode(event.target.value);
    }
  };

  const resetToRO = () => {
    initDataLeft(initDataPromise, []).then(() => {
      setEdit(false);
      setEditMode("1");
      props.onEditGroupDateCancelFn("cancel");
    });
  };

  const refreshGlobalInputCheckbox = () => {
    if (
      headerSquareRef.current &&
      headerSquareCheckRef.current &&
      headerSquareMinusRef.current
    ) {
      headerSquareRef.current.style.display =
        editMode === "3" && props.hasSelectedLeft === "none"
          ? "inline-block"
          : "none";
      headerSquareMinusRef.current.style.display =
        editMode === "3" && props.hasSelectedLeft === "some"
          ? "inline-block"
          : "none";
      headerSquareCheckRef.current.style.display =
        editMode === "3" && props.hasSelectedLeft === "all"
          ? "inline-block"
          : "none";
    }
  };

  const columnVisibility = () => {
    let editModeLT2 = editModeLessThan2();
    return {
      code: !edit,
      isRowSelected: editMode === "3",
      isRowSelectedSingle: editMode === "2",
      isMandatory: editModeLT2,
      blankcolumn: editModeLT2,
    };
  };

  const columns = useMemo(
    () => [
      columnIconLearningItem("Learner", "xl"),
      {
        accessorFn: (row) => row.isRowSelected,
        id: "isRowSelected",
        header: "Select",
        Header: ({ column }) => (
          <>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip className="cls-theme-tooltip">Select All</Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faSquare}
                style={{ cursor: "pointer", display: "none", fontSize: "1rem" }}
                ref={headerSquareRef}
                onClick={() => handleHeaderClick("all")}
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip className="cls-theme-tooltip">Select All</Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faSquareMinus}
                style={{ cursor: "pointer", display: "none", fontSize: "1rem" }}
                ref={headerSquareMinusRef}
                onClick={() => handleHeaderClick("all")}
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip className="cls-theme-tooltip">Deselect All</Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faSquareCheck}
                style={{ cursor: "pointer", display: "none", fontSize: "1rem" }}
                ref={headerSquareCheckRef}
                onClick={() => handleHeaderClick("none")}
              />
            </OverlayTrigger>
          </>
        ),
        muiTableBodyCellProps: {
          sx: {
            cursor: "pointer",
          },
        },
        size: 80,
        Cell: ({ cell, row }) => (
          <FontAwesomeIcon
            icon={cell.getValue() === true ? faSquareCheck : faSquare}
            style={{ fontSize: "1rem" }}
          />
        ),
      },
      {
        accessorFn: (row) => row.isRowSelected,
        id: "isRowSelectedSingle",
        header: "",
        muiTableBodyCellProps: {
          sx: {
            cursor: "pointer",
          },
        },
        size: 50,
        Cell: ({ cell, row }) => (
          <FontAwesomeIcon
            icon={
              row.original.isRowSelected === true ? faCircleCheck : faCircle
            }
            style={{ fontSize: "1rem" }}
          />
        ),
      },
      {
        accessorFn: (row) => row.code,
        id: "codeG",
        enableEditing: false,
        header: "Code",
        size: 300,
        muiTableBodyCellProps: ({ cell, table }) => {
          return {
            sx: {
              cursor: "pointer",
              fontWeight: cell.row.original.isRowSelected ? "bold" : "normal",
            },
          };
        },
      },
      {
        accessorKey: "title",
        enableEditing: false,
        header: "Title",
        muiTableBodyCellProps: ({ cell, table }) => {
          let isRowSelectedIsVisible = table
            .getColumn("isRowSelected")
            .getIsVisible();
          return {
            classes: { root: "fw-unset" }, // Flexible width
            sx: {
              cursor: isRowSelectedIsVisible ? "pointer" : "inherit",
              fontWeight: cell.row.original.isRowSelected ? "bold" : "normal",
            },
          };
        },
        muiTableHeadCellProps: { classes: { root: "fw-unset" } }, // Flexible width
      },
      columnIfWidth("md", {
        accessorKey: "isMandatory",
        enableEditing: false,
        header: "M/R",
        size: 80,
        Cell: ({ cell, row }) =>
          (row.original.isMandatory ? "M" : "") +
          (row.original.isRenewable
            ? row.original.isMandatory
              ? "/R"
              : "R"
            : ""),
      }),
      columnBlankIfWidth("xl", "blankcolumn"),
    ],
    []
  );

  return (
    <Row>
      <Col>
        <MaterialReactTable
          columns={columns}
          data={props.dataLeft}
          editingMode="table"
          enableBottomToolbar={false}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableDensityToggle={false}
          enableEditing={edit && editMode === "1"}
          enableFullScreenToggle={false}
          enableGrouping={false}
          enableHiding={false}
          enablePagination={false}
          enableRowVirtualization
          enableSorting={false}
          enableTopToolbar={true}
          muiTableBodyCellEditTextFieldProps={({ cell }) => ({
            onChange: (event) => {
              handleCellChanged(cell, event);
            },
          })}
          muiTableBodyCellProps={({ cell }) => ({
            onClick: (event) => {
              handleCellClick(cell, event);
            },
          })}
          muiTableBodyRowProps={{ hover: false }}
          muiTableContainerProps={{
            sx: { maxHeight: "max(170px, calc(100vh - 477px))" },
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Row>
              {edit ? (
                <>
                  <Col xs="auto">
                    <Button
                      variant="outline-secondary"
                      onClick={handleCancelEdit}
                    >
                      Back
                    </Button>
                  </Col>
                  <Col xs="auto" style={{ padding: "0.5rem 0 0 0.75rem" }}>
                    Edit Mode:
                  </Col>
                  <Col xs="auto" style={{ padding: "0 0.75rem 0 0.25rem" }}>
                    <Form.Select
                      value={editMode}
                      onChange={handleEditModeChange}
                    >
                      <option value="2">Dates</option>
                    </Form.Select>
                  </Col>
                  {editMode === "1" || editMode === "3" ? (
                    <>
                      <Col xs="auto">
                        <Button
                          variant="outline-secondary"
                          onClick={handleCancelEdit}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col xs="auto">
                        <Button
                          type="submit"
                          variant="primary"
                          onClick={handleOnSave}
                          disabled={!props.hasUpdates}
                        >
                          {tableLoading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm mt-1"
                                style={{ marginRight: "0.25rem" }}
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Please wait...
                            </>
                          ) : (
                            <>Save</>
                          )}
                        </Button>
                      </Col>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <Col xs="auto">
                    <Button
                      variant="outline-secondary"
                      onClick={handleCancelEdit}
                    >
                      Back
                    </Button>
                  </Col>

                  <Col xs="auto">
                    <Button
                      variant="outline-primary"
                      onClick={handleEdit}
                      disabled={!props.dataSelected}
                    >
                      Edit
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          )}
          initialState={{
            showGlobalFilter: true,
          }}
          state={{
            columnVisibility: columnVisibility(),
            isLoading: tableLoading,
          }}
        />
      </Col>
      {editMode === "2" ? (
        <Col>
          <LearningPathwayEditorGroupTableDate
            {...props}
            dateToApply={dateToApply}
            setDateToApply={setDateToApply}
            resetToRO={resetToRO}
            groupId={props.dataSelected && props.dataSelected.id}
          />
        </Col>
      ) : null}
      <ModalConfirmCancel
        showCancelEditModal={showCancelEditModal}
        abortFn={() => setShowCancelEditModal(false)}
        continueFn={handleConfirmCancelEdit}
      />
      <ModalConfirmCancel
        showCancelEditModal={showCancelModeModal > 0}
        abortFn={handleConfirmModeCancel}
        continueFn={handleConfirmMode}
      />
      <ModalConfirmCancel
        showCancelEditModal={showCancelSelectModal > 0}
        abortFn={handleConfirmSelectCancel}
        continueFn={handleConfirmSelect}
      />
    </Row>
  );
}
