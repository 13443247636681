import BaseBeginContainer from "../components/BaseBeginContainer";
import BaseContainer from "../components/BaseContainer";
import { BeginButton, BeginButtonAutoSize } from '../components/CustomButtons';
import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {
    faPeopleLine,
    faFileChartColumn,
    faPersonChalkboard,
    faSolarPanel,
    faToolbox,
    faRectangleHistory,
    faCircleExclamation
} from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import DashboardTeamToDo from '../components/DashboardTeamToDo';
import UpcomingEventsListDashboard from "./upcoming-events/components/upcomingEventsListDashboard";

import useLmsStore from '../hooks/useLmsStore'
import shallow from 'zustand/shallow';
import { FastForward } from "@mui/icons-material";

function TeamDashboardCore({ dimensions }) {
       const [dashboardSummary, setDashboardSummary] = useState({});
    const [upcomingEventsCount, setUpcomingEventsCount] = useState();
    const { personSettings } = useLmsStore(state =>
    ({
        personSettings: state.personSettings.data,
    }), shallow)

    const onReceiveDashboardSummary = (data) => {
        setDashboardSummary(data);
    }

    return (
        <Row className='flex-grow-1 mb-2 overflow-auto'>
            <Col xl={9}>
                <Row className='gx-4'>
                    <Col xl={12}>
                        <DashboardTeamToDo
                            receiveDashboardSummaryData={(data) => onReceiveDashboardSummary(data)}
                            ukpnPersonId={personSettings?.ukpnPersonId}
                            portalPersonId={personSettings?.portalPersonId}
                            upcomingEventsCount={upcomingEventsCount }
                        />
                    </Col>
                </Row>
                <Row className='gx-4 mt-4 mb-3'>
                    <Col xl={12}>
                        <Row className='gy-4'>
                            <Col sm={4} >
                                {
                                    personSettings?.isManager === true ?
                                        <BeginButtonAutoSize
                                            title='Team Profiles'
                                            description='View all users learning plans, learning pathways and review their progress.'
                                            icon={faPeopleLine}
                                            iconColor={'#f54130'}
                                            to='/lms/myteamprofiles'
                                            addCardStyle={{ minHeight: '260px' }}
                                        />
                                        :
                                        <></>
                                }
                            </Col>
                            <Col sm={4}>
                                {
                                    personSettings?.isApplicationAdministrator === true || personSettings?.isAdministrator === true || personSettings?.isManager === true ?
                                        <BeginButtonAutoSize
                                            title='Team Reports'
                                            description='Run reports.'
                                            icon={faFileChartColumn} to='/lms/reports'
                                            iconColor={'#f54130'}
                                            addCardStyle={{ minHeight: '260px' }}
                                        />
                                        :
                                        <></>
                                }
                            </Col>
                            <Col sm={4}>
                                {
                                    personSettings?.isManager === true ?
                                        <BeginButtonAutoSize
                                            title='Browse Catalogue/Request Training'
                                            description='Browse Catalogues of available courses and request training for your team.'
                                            icon={faPeopleLine}
                                            iconColor={'#f54130'}
                                            to='/lms/catalogue-management'
                                            addCardStyle={{ minHeight: '260px' }}
                                        />
                                        :
                                        <></>
                                }
                            </Col>
                            <Col sm={4}>
                                {
                                    personSettings?.isManager === true ?
                                        <BeginButtonAutoSize
                                            title='Competency Management'
                                            description='Use this option to manage qualifications.'
                                            icon={faToolbox} to='/lms/competency-management'
                                            iconColor={'#f54130'}
                                            addCardStyle={{ minHeight: '260px' }}
                                        />
                                        :
                                        <></>
                                }
                            </Col>
                            <Col sm={4}>
                                {
                                    personSettings?.isManager === true ?
                                        <BeginButtonAutoSize
                                            title='My Team Training History'
                                            description='View training history for your team.'
                                            icon={faRectangleHistory} to='/lms/myTeamTrainingHistory'
                                            iconColor={'#f54130'}
                                            addCardStyle={{ minHeight: '260px' }}
                                        />
                                        :
                                        <></>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xl={3}><UpcomingEventsListDashboard
                dashboardSummary={dashboardSummary}
                ukpnPersonId={personSettings?.ukpnPersonId}
                setUpcomingEventsCount={setUpcomingEventsCount}
            />
            </Col>
        </Row>
    );
}

export default function TeamDashboard(props) {
    return (
        <BaseContainer title='Team Dashboard' >
            <TeamDashboardCore />
        </BaseContainer>
    );
}