import React, {useEffect, useState} from 'react';
import GenericSubTabContainer from "../../common/GenericSubTabContainer";
import {apiAxiosCalls} from "../../../services/apiAxiosCalls";
import useLmsStore, {toastError, toastSuccess} from "../../../hooks/useLmsStore";
import {FieldArray, Form, Formik} from 'formik';
import * as Yup from 'yup';
import SingleAssessment from "./SingleAssessment";
import {Button} from "react-bootstrap";
import SaveAndCancel from "../SaveAndCancel";
import {Skeleton} from "@mui/material";

const template = {
    Id: -1,
    AssessmentId: "",
    AssessmentName: "",
    AssessmentTypeId: "",
    AssessmentTypeName: "",
    NoDaysToSendAssessment: 0,
    IsReminderFromPrevReminder: false,
    UsePortal: true,
    Reminders: []
}

const AssessmentsTab = () => {
    const [assessmentNames, setAssessmentNames] = useState([])
    const [assessmentActionTypes, setAssessmentActionTypes] = useState([])
    const [listOfAppSettings, setListOfAppSettings] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const {learningItemsState} = useLmsStore((state) => {
        return {
            learningItemsState: state.learningItemsState
        }
    })

    const {
        activeLearningItemDetail, activeLearningItem
    } = learningItemsState;

    const getAssessmentNames = async () => {
        try {
            setIsLoading(true)
            const {data} = await apiAxiosCalls.getAssessmentNames()
            setAssessmentNames(data.Value)
        } catch (e) {

        }
        setIsLoading(false)
    }

    const getAssessmentActionTypes = async () => {
        try {
            const {data} = await apiAxiosCalls.getAssessmentActionTypes()
            setAssessmentActionTypes(data.Value)
        } catch (e) {

        }
    }

    const initialValues = {
        items: listOfAppSettings,
    };

    const getAssessments = async () => {
        try {
            const {data} = await apiAxiosCalls.getLearningItemAssessSettingInfo(activeLearningItem.Id)
            if (!data.IsValid) {
                throw  new Error("Error getting data")
            }
            setListOfAppSettings(data.Value.ListOfAppSettings)
        } catch (e) {

        }
    }

    const onSubmit = async (payload) => {
        try {
            await apiAxiosCalls.savePreAndPostAccessSetting(payload)
            toastSuccess('Assessments successfully updated')
        } catch (e) {
            console.log(e)
            toastError({message: "An error occurred"})
        }

    }

    const validationSchema = Yup.object().shape({
        items: Yup.array()
            .of(Yup.object().shape({

                AssessmentId: Yup.string().required("Assessment name is a required field"),
                AssessmentTypeId: Yup.string().required("Assessment type is a required field"),
                NoDaysToSendAssessment: Yup.number().min(0, "Must be 0 or greater").required("Assessment type is a required field"),
                Reminders: Yup.array().of(
                    Yup.object().shape({
                        ReminderNumber: Yup.number().min(1, "Must be 0 or greater").required("Reminder number is required"),
                        NumberDaysToSend: Yup.number().min(0, "Must be 0 or greater").required("Reminder period is required")
                    })
                )
            }))

    });

    const renderSkeletons = () => {
        return <div>
            {[1, 2].map(skeleton => {
                return <Skeleton key={skeleton} height={250}/>
            })}
        </div>
    }


    useEffect(() => {
        getAssessmentNames()
        getAssessmentActionTypes()
        getAssessments()
    }, [])

    return (<GenericSubTabContainer title={'Assesments'}>
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={async (values, actions) => {
                await onSubmit({
                    ApplicationLearningItemId: activeLearningItem.Id, ListOfAppSettings: values.items,
                })
            }}
        >
            {(form) => (<Form>
                <FieldArray name="items">
                    {(fieldArrayProps) => (<div>
                        <div className={"mb-3"}>
                            <Button
                                onClick={() => fieldArrayProps.push(template)}
                            >
                                Add Item
                            </Button>
                        </div>
                        <div style={{maxHeight: "500px", overflowY: "auto"}} >
                            {isLoading ? renderSkeletons() : form.values.items.map((item, index) => (
                                <SingleAssessment assessmentNames={assessmentNames}
                                                  assessmentActionTypes={assessmentActionTypes} form={form}
                                                  item={item} fieldArrayProps={fieldArrayProps}
                                                  index={index}/>))}
                        </div>


                    </div>)}
                </FieldArray>

                <SaveAndCancel form={form}/>
            </Form>)}
        </Formik>
    </GenericSubTabContainer>);
};

export default AssessmentsTab;
