import BaseBeginContainer from "../components/BaseBeginContainer";
import BaseContainer from "../components/BaseContainer";
import shallow from 'zustand/shallow';
import useLmsStore, { hasPermission } from '../hooks/useLmsStore';
import { BeginButton } from '../components/CustomButtons'
import { Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { faBuildings, faClipboardList, faBooks, faListOl, faPeopleLine, faRoad, faNewspaper, faFileChartColumn, faPersonChalkboard, faUsers, faUser, faLaptopFile } from '@fortawesome/pro-solid-svg-icons';
import { isLocalOrDevelopment } from "../helpers/EnvironmentDeterminator";
import { useEffect, useState } from 'react';
import { apiAxiosCalls } from '../services/apiAxiosCalls';

function ExternalTitle(props) {
    return (<>{props.title} <FontAwesomeIcon size='xs' icon={faArrowUpRightFromSquare} className='text-mutted' /></>);
};

const MySuperAdministrationDashboardCore = ({ dimensions }) => {

    const { personBasic, personSettings, fetchPersonSettings, fetchPersonBasic, hasPermission } = useLmsStore(state =>
    ({
        personBasic: state.personBasic.data,
        personSettings: state.personSettings.data,
        fetchPersonBasic: state.fetchPersonBasic,
        fetchPersonSettings: state.fetchPersonSettings,
        hasPermission: state.hasPermission
    }), shallow)

    useEffect(() => {
        // fetchPersonBasic();
        fetchPersonSettings();
    }, []);

    return (
        <BaseBeginContainer dimensions={dimensions}>
            <BeginButton title='Users' description='View, create & update user records.' icon={faUser} to='/lms/users' />
            <BeginButton title='Groups' description='View, create and update groups, assign users and learning pathways to the group.' icon={faUsers} to='/lms/GroupManagement' target='admin-tab' />
            <BeginButton title='Team Profiles' description='View all users learning plans, learning pathways and review their progress.' icon={faPeopleLine} to='/lms/clientteamprofiles' />
            <BeginButton title='Reports' description='Run reports.' icon={faFileChartColumn} to='/lms/reports' />
            {hasPermission('ea.view') === true ? <>
                <BeginButton title={<ExternalTitle title='Event Attendance' />} description='View & update event attendance for instructor-led events and upload copies of registers to the event.' icon={faUsers} href={apiAxiosCalls.hrefToAdminServer(personBasic, 'portal.admin.eventAttendance')} target='admin-tab' />
            </> : <></>}

            {personSettings.theme === 'ukpn' ?
                <BeginButton title='Learning items' description='View, create and update learning items.' icon={faLaptopFile} to={"/lms/learningitems"} />
                :
                <>
                    <BeginButton title={<ExternalTitle title='Learning Items' />} description='View, create and update learning items.' icon={faLaptopFile} href={apiAxiosCalls.hrefToAdminServer(personBasic, 'portal.learningItems')} target='admin-tab' />
                    <BeginButton title='Companies' description='View, create, update or delete companies.' icon={faBuildings} to='/lms/companies' />
                </>
            }

            <BeginButton title='Catalogues' description='View, create, update or mark catalogues as inactive and assign to users/groups.' icon={faBooks} to='/lms/catalogues' />
            <BeginButton title='Learning Plan Admin' description='View, create, update learning plans and assign to users/groups.' icon={faListOl} to='/lms/learningplan' />
            <BeginButton title='PathWays Admin' description='View, create & update user records.' icon={faUser} to='/lms/pathways' />

            {hasPermission('ac.view') === true ? <>
                <BeginButton title={<ExternalTitle title='Access Card' />} description='View, print, activate, re-issue and deactivate access cards.' icon={faRoad} href={apiAxiosCalls.hrefToAdminServer(personBasic, 'portal.admin.accessCards')} target='admin-tab' />
            </> : <></>}
        </BaseBeginContainer>
    );
};

export default function MySuperAdministrationDashboard(props) {
    return (<BaseContainer title='Admin Super User Dashboard'>
        <MySuperAdministrationDashboardCore />
    </BaseContainer>);
}
