import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandCircleDownOutlined from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {toDDsMMsYYString } from '../helpers/formatHelper';
import React, { useState, useEffect } from "react";

export default function TrainingPlanAccordian(props) {
    const data = props.data;
    const header = props.header;

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandCircleDownOutlined />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ backgroundColor: '#281E84', color: 'white' }}
            >
                <Typography>{header}</Typography>

            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date Added</TableCell>
                            <TableCell>Course Title</TableCell>
                            <TableCell>Course Code</TableCell>
                            <TableCell>Business Case</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((data) => (
                            <TableRow key={data.courseID}>
                                <TableCell>{toDDsMMsYYString(data.createDate2)}</TableCell>
                                <TableCell>{data.courseTitle}</TableCell>
                                <TableCell>{data.courseCode}</TableCell>
                                <TableCell>{data.businessCase}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>
    );
}

