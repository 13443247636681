import React, { useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import useGetAllCourseTypes from "../../../hooks/useGetAllCourseTypes";
import { useGetAllVersioningActions } from "../../../hooks/useGetAllVersioningActions";

const ELearingTabPropertiesInputs = ({
  form: { handleSubmit, handleChange, values, touched, errors, handleBlur },
}) => {
  const eLearnCourseTypes = useGetAllCourseTypes();
  const versioningActions = useGetAllVersioningActions();

  const renderCourseTypeOptions = () => {
    return eLearnCourseTypes?.map((courseType) => {
      return (
        <option key={courseType.Id} value={courseType.Id}>
          {courseType.Value}
        </option>
      );
    });
  };

  const renderVersioningActionsOptions = () => {
    return versioningActions?.map((courseType) => {
      return (
        <option key={courseType.Id} value={courseType.Id}>
          {courseType.Value}
        </option>
      );
    });
  };

  const renderDueByOptions = () => {
    const values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    return values.map((v) => {
      return (
        <option key={v} value={v}>
          {v}
        </option>
      );
    });
  };

  const renderDueByPeriodOptions = () => {
    const values = ["Days", "Weeks", "Months"];
    return values.map((v) => {
      return (
        <option key={v} value={v}>
          {v}
        </option>
      );
    });
  };

  return (
    <>
      <Row className={"w-100"}>
        <Row className={"w-100"}>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="code1">
              <Form.Label>MaxAttempts</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  placeholder="MaxAttempts"
                  type="text"
                  name="MaxAttempts"
                  value={values.MaxAttempts}
                  onChange={handleChange}
                  isValid={touched.MaxAttempts && !errors.MaxAttempts}
                  isInvalid={touched.MaxAttempts && errors.MaxAttempts}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.MaxAttempts}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Row className={"w-100"}>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="dropdownType">
              <Form.Label>
                Select eLearning Course Type{" "}
                <span title="Mandatory field" className="mandatory">
                  *
                </span>
              </Form.Label>
              <Form.Select
                name="CourseType"
                value={values.CourseType}
                onChange={handleChange}
                onBlur={handleBlur}
                aria-label="Default select example"
                required
              >
                <option value="">Select type</option>
                {renderCourseTypeOptions()}
              </Form.Select>
              {touched.CourseType && errors.CourseType ? (
                <div className="invalid-feedback d-block">
                  {errors.CourseType}
                </div>
              ) : null}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3" controlId="dropdownType">
              <Form.Label>
                Move existing registrations to newest version of courseware when{" "}
                <span title="Mandatory field" className="mandatory">
                  *
                </span>
              </Form.Label>
              <Form.Select
                name="RusticiVersioningAction"
                value={values.RusticiVersioningAction}
                onChange={handleChange}
                onBlur={handleBlur}
                aria-label="Default select example"
                required
              >
                <option value="">Select type</option>
                {renderVersioningActionsOptions()}
              </Form.Select>
              {touched.RusticiVersioningAction &&
              errors.RusticiVersioningAction ? (
                <div className="invalid-feedback d-block">
                  {errors.RusticiVersioningAction}
                </div>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row className={"w-100"}>
          <h5>Window display information</h5>
        </Row>

        <Row className={"w-100"}>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="code1">
              <Form.Label>Width</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  placeholder="Width"
                  type="text"
                  name="Width"
                  value={values.Width}
                  onChange={handleChange}
                  isValid={touched.Width && !errors.Width}
                  isInvalid={touched.Width && errors.Width}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Width}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="code1">
              <Form.Label>Height</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  placeholder="Height"
                  type="text"
                  name="Height"
                  value={values.Height}
                  onChange={handleChange}
                  isValid={touched.Height && !errors.Height}
                  isInvalid={touched.Height && errors.Height}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Height}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Row className={"w-100"}>
          <Col md={12}>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                name={"IsResizable"}
                checked={!!values.IsResizable}
                label={"Is resizable"}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className={"w-100"}>
          <h5>Due Dates</h5>
        </Row>
        <Row className={"w-100"}>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="dropdownType">
              <Form.Label>eLearning Completion Date</Form.Label>
              <Form.Control
                name={"CompletionDate"}
                type="date"
                value={values.CompletionDate}
                disabled={values.DueBy !== "" || values.DueByPeriod !== ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={3}></Col>
          <Col md={3}>
            <Form.Group className="mb-3" controlId="dropdownType">
              <Form.Label>Select allowable duration:</Form.Label>
              <Form.Select
                name={"DueBy"}
                disabled={values.CompletionDate !== ""}
                value={values.DueBy}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select duration</option>
                {renderDueByOptions()}
              </Form.Select>
              <Form.Select
                name={"DueByPeriod"}
                disabled={values.CompletionDate !== ""}
                value={values.DueByPeriod}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select duration unit</option>
                {renderDueByPeriodOptions()}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default ELearingTabPropertiesInputs;
