import BaseContainer from "../components/BaseContainer";
import React, { useEffect, useState, useMemo } from "react";
import shallow from "zustand/shallow";
import useLmsStore from "../hooks/useLmsStore";
import { CourseCard, CourseCardLoading } from "../components/CourseCard";
import { PathwayCard, PathwayCardLoading } from "../components/PathwayCard";
import {
  FilterClearButton,
  FilterButton,
  SearchTableButton,
} from "../components/CustomButtons";
import { Row, Col, Container } from "react-bootstrap";
import { learningItemName } from "../helpers/tableHelper";
import { searchObjectArray } from "../helpers/searchHelper";
import { useSearchParams } from "react-router-dom";
import { TableRowSplit } from "../helpers/tableHelper";

import { catalogService } from "./catalogue-management/services/catalog.service";
import CustomTableView from "../components/common/generic-table-view";
import LearningItemTabCard from "../components/learningItemsPageComponents/LearningItemTabCard";

const getUniqueCatalogues = (items) => {
  if (items && items.length > 0) {
    let catalogueFilterItems = items.map((item) => {
      return item.catalogueId > 0
        ? { id: item.catalogueId, name: item.catalogueName }
        : undefined;
    });
    return [
      ...new Map(catalogueFilterItems.map((item) => [item.id, item])).values(),
    ].sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)); // Unique array & sort by name
  } else {
    return [];
  }
};

/**
 * Get unique catalogue items
 *
 * @param {*} items
 * @returns
 */
const getUniqueCatalogueItems = (items) => [
  ...new Map(items.map((item) => [item.code, item])).values(),
];

const CourseLibraryCore = ({ dimensions }) => {
  const [filterId, setFilterId] = useState(0);
  const [categoryId, setCategoryId] = useState(0);
  const [searchParams] = useSearchParams();
  const [searchTerms, setSearchTerms] = useState(
    decodeURI(searchParams.get("search") || "")
  );
  const [tableData, setTableData] = useState([]);
  const [pathwayTableData, setPathwayTableData] = useState([]);
  const [uniqueCatalogues, setUniqueCatalogues] = useState([]);
  const [customerId, setCustomerId] = useState(1);
  const [requeryCatalogueItems, setRequeryCatalogueItems] = useState(false);

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [userId, setUserId] = useState(0);

  const [activeTab, setActiveTab] = useState("Learning Items");

  const updateTableData = (applySearch = false, searchToSet = null) => {
    if (activeTab == "Learning Items") {
      setLearningItemData(applySearch, searchToSet);
    } else {
      setLearningPathwayData();
    }
  };

function setLearningPathwayData(){
    if(learningPathwayItems)
    {
        setTableData(learningPathwayItems);
    }
}

  function setLearningItemData(applySearch, searchToSet) {
    if (allPersonCatalogueItems) {
      if (searchToSet !== null) {
        setSearchTerms(searchToSet);
      }

      let search = searchToSet === null ? searchTerms : searchToSet,
        data = structuredClone(
          getUniqueCatalogueItems(allPersonCatalogueItems)
        );

      if (filterId > 0) {
        let filter = uniqueCatalogues.find((x) => x.id === filterId);
        if (filter) {
          data = data.filter(
            (rowData) =>
              rowData.allCatalogueNames &&
              rowData.allCatalogueNames.includes(filter.name)
          );
        }
      }

      if (applySearch && search !== "") {
        data = searchObjectArray(data, search, [
          "catalogueName",
          "code",
          "name",
          (obj) => learningItemName(obj.learningItemTypeId),
        ]);
      }

      if (categoryId > 0) {
        data = data.filter((rowData) => rowData.courseCategoryId == categoryId);
      }

      setTableData(data);
    }
  }

  const categoryClickEventHandler = (event, row, isCategory) => {
    event.preventDefault();

    if (isCategory) {
      let id = row.original.CourseCategoryID;
      if (categoryId !== id) {
        setCategoryId(id);
      } else {
        setCategoryId(0);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "",
        id: "CourseCategoryID",
        header: "",
        size: 0,
      },
      {
        accessorKey: "CourseCategory",
        header: "",
        header: "",
        Cell: ({ cell, row }) => (
          <div style={{ textAlign: "left" }}>{row.original.CourseCategory}</div>
        ),
      },
    ],
    []
  );

  const {
    personSettings,
    allPersonCatalogueItems,
    fetchAllPersonCatalogueItems,
    learningPathwayItems,
    fetchLearningPathwayItems
  } = useLmsStore(
    (state) => ({
      personSettings: state.personSettings.data,
      allPersonCatalogueItems: state.allPersonCatalogueItems.data,
      fetchAllPersonCatalogueItems: state.fetchAllPersonCatalogueItems,
      learningPathwayItems: state.learningPathwayItems.data,
      fetchLearningPathwayItems: state.fetchLearningPathwayItems
    }),
    shallow
  );

  // On load
  useEffect(() => {
    fetchAllPersonCatalogueItems();
    fetchLearningPathwayItems();
    setCustomerId(getCustomerIdByTheme(personSettings));
    if (categories?.length > 0) return;
    setLoading(true);
    catalogService
      .getCategoryList(userId)
      .then((result) => {
        setCategories(result[0]);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!learningPathwayItems)
    {
      fetchLearningPathwayItems();
    }
    setTimeout(() => {
      setPathwayTableData(learningPathwayItems);
    }, 250);
  }, [learningPathwayItems]);

  useEffect(() => {
    updateTableData(true);
  }, [uniqueCatalogues, filterId, categoryId]);

  useEffect(() => {
    if (Array.isArray(allPersonCatalogueItems)) {
      fetchAllPersonCatalogueItems();
      setUniqueCatalogues(getUniqueCatalogues(allPersonCatalogueItems));
      setTimeout(() => {
        setTableData(allPersonCatalogueItems);
      }, 250);
    }

    updateTableData(true);
  }, [allPersonCatalogueItems]);

  const getCustomerIdByTheme = (personSettings) => {
    switch (personSettings?.theme?.toLowerCase()) {
      case "ukpn":
        return 5;
      case "wdr":
        return 1;
      default:
        return 1;
    }
  };

  const onTabCardClick = (tab) => {
    setActiveTab(tab);
  };

  const renderActiveTab = () => {
    return activeTab === "Learning Items" ? (
      <LearningItemCatalogueTab />
    ) : (
      <PathwaysCatalogueTab />
    );
  };

  function PathwaysCatalogueTab() {
    if (!process.env.FLAG_PATHWAYS_IN_CATALOGUE)
    {
      return <></>;
    }
    return (
      <>
        <Container
          fluid
          className="overflow-auto"
          style={{ height: "calc(100% - 120px)" }}
        >
          <Row className="mb-2 gx-4 mt-0 pl-2 row-cols-auto flex-grow-1 border-bottom overflow-auto">
            <Col className="p-1 d-flex align-content-start flex-wrap">
              {!learningPathwayItems || !pathwayTableData ? (
                <>
                  <PathwayCardLoading key="1" />
                  <PathwayCardLoading key="2" />
                  <PathwayCardLoading key="3" />
                  <PathwayCardLoading key="4" />
                  <PathwayCardLoading key="5" />
                </>
              ) : pathwayTableData?.length === 0 ? (
                <i>None available</i>
              ) : (
                pathwayTableData.map((rowData, idx) => (
                  <PathwayCard
                    customerId={customerId}
                    rowData={rowData}
                    key={idx}
                  />
                ))
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }

function CalcHeight() {
  if (process.env.FLAG_PATHWAYS_IN_CATALOGUE)
  {
    return "calc(100% - 120px)";
  } else {
    return "calc(100% - 66px)";
  }
}

  function LearningItemCatalogueTab() {
    return (
      <>
        <Container
          fluid
          className="overflow-auto"
          style={{ height: CalcHeight() }}
        >
          <Row className="mb-2 gx-4 mt-0 pl-2 row-cols-auto flex-grow-1 border-bottom overflow-auto">
            <Col className="p-1 d-flex align-content-start flex-wrap">
              {!allPersonCatalogueItems ? (
                <>
                  <CourseCardLoading key="1" />
                  <CourseCardLoading key="2" />
                  <CourseCardLoading key="3" />
                  <CourseCardLoading key="4" />
                  <CourseCardLoading key="5" />
                </>
              ) : tableData?.length === 0 ? (
                <i>None available</i>
              ) : (
                tableData.map((rowData, idx) => (
                  <CourseCard
                    onChangeStore={() => setRequeryCatalogueItems(true)}
                    customerId={customerId}
                    rowData={rowData}
                    key={idx}
                  />
                ))
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  return (
    <>
      <TableRowSplit
        leftPane={
          <>
            {activeTab === "Learning Items" && (
              <>
                <div key={0} sm="auto">
                  <SearchTableButton
                    placeholder="Search courses"
                    onClick={(searchTermsToSet) =>
                      updateTableData(true, searchTermsToSet)
                    }
                  />
                </div>
                <CustomTableView
                  users={categories}
                  columns={columns}
                  loading={loading}
                  rowClickEventHandler={(event, row) =>
                    categoryClickEventHandler(event, row, true)
                  }
                  columnVisibility={{ CourseCategoryID: false }}
                  dimensions={dimensions}
                  disableGlobalFilter={true}
                />
              </>
            )}
          </>
        }
        rightPane={
          <>
            <Row className="mb-2">
              <Col>
              {process.env.FLAG_PATHWAYS_IN_CATALOGUE &&
                <Row className="offset-1 col-10 gx-3 gy-3">
                  <LearningItemTabCard
                    onClick={() => {
                      onTabCardClick("Learning Items");
                    }}
                    title="Learning Items"
                    isActive={activeTab === "Learning Items"}
                  />
                  <LearningItemTabCard
                    onClick={() => {
                      onTabCardClick("Pathways");
                    }}
                    title="Pathways"
                    isActive={activeTab === "Pathways"  }
                  />
                </Row>}
                {activeTab === "Learning Items" && (
                  <Row className="gx-3 gy-3">
                    {uniqueCatalogues.length === 0
                      ? []
                      : uniqueCatalogues.map((catalogueData, idx) => (
                          <Col key={idx} sm="auto">
                            <FilterButton
                              title={catalogueData.name}
                              onClick={() =>
                                setFilterId(
                                  filterId === catalogueData.id
                                    ? 0
                                    : catalogueData.id
                                )
                              }
                              active={filterId === catalogueData.id}
                            />
                          </Col>
                        ))}
                    <Col xs="auto">
                      {filterId > 0 && (
                        <FilterClearButton onClick={() => setFilterId(0)} />
                      )}
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            {renderActiveTab()}
          </>
        }
      />
    </>
  );
};

export default function CourseLibrary(props) {
  return (
    <BaseContainer title="Course Library">
      <CourseLibraryCore />
    </BaseContainer>
  );
}
