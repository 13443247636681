import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import LinkContainer from '../../_libs/react-router-bootstrap/LinkContainer'
import { Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faBan } from '@fortawesome/pro-solid-svg-icons';
import { faSearch, faXmark } from '@fortawesome/pro-light-svg-icons';
import { useState } from 'react';

export function BeginButton(props) {
    return (
        <Card className='cls-card-plain shadow m-3' style={{ height: '220px', width: '350px' }}>
            <Card.Body>
                <h5><FontAwesomeIcon size='lg' icon={props.icon} className='text-info' /><span className="p-2">{props.title}</span></h5>
                <p>{props.description}</p>
            </Card.Body>
            <Card.Footer>
                {(props.href && props.target) ?
                    <Button variant='outline-primary' href={props.href} target={props.target}>Begin »</Button>
                    :
                    (props.to) ?
                        <LinkContainer to={props.to}>
                            <Button variant='outline-primary' onClick={props.onClick}>Begin »</Button>
                        </LinkContainer>
                        :
                        (props.onClick) ?
                            <Button variant='outline-primary' onClick={props.onClick}>Begin »</Button>
                            :
                            <Button variant='outline-primary' disabled>Begin »</Button>
                }
            </Card.Footer>
        </Card>
    );
}

export function BeginButtonAutoSize(props) {
    return (
        <Col className='auto' style={props.addStyle ? props.addStyle : {}}>
            <Card className='cls-card-plain h-100 shadow' style={props.addCardStyle ? props.addCardStyle : {}} >
                <Card.Body>
                    <h5><FontAwesomeIcon
                        size='lg'
                        ref={
                            element => { if (element && props.iconColor) element.style.setProperty('color', props.iconColor, 'important'); }
                        }
                        // style={{color:`red ${" !important"}`}}//{props.addIconStyle ? props.addIconStyle : {}} 
                        icon={props.icon}
                        className='text-info'
                    />
                        <span className="p-2">{props.title}</span>
                    </h5>
                    {props.description}
                </Card.Body>
                <Card.Footer>
                    {(props.href && props.target) ?
                        <Button variant='outline-primary' href={props.href} target={props.target}>Begin »</Button>
                        :
                        (props.to) ?
                            <LinkContainer to={props.to}>
                                <Button variant='outline-primary' onClick={props.onClick}>Begin »</Button>
                            </LinkContainer>
                            :
                            (props.onClick) ?
                                <Button variant='outline-primary' onClick={props.onClick}>Begin »</Button>
                                :
                                <Button variant='outline-primary' disabled>Begin »</Button>
                    }
                </Card.Footer>
            </Card>
        </Col>
    );
}


export function FilterClearButton(props) {
    const style = props.style || {};
    return (
        <Button variant='filter' style={style} onClick={props.onClick}>
            <span className='text-nowrap'><span className="fa-layers"><FontAwesomeIcon icon={faFilter} /><FontAwesomeIcon className='text-danger' icon={faBan} /></span><span className='m-2'>{props.title || 'Clear filter'}</span></span>
        </Button>
    );
}

export function FilterButton(props) {
    const style = props.style || {};
    return (
        <Button variant='filter' style={style} onClick={props.onClick} active={props.active === true}>
            <span className='text-nowrap'><FontAwesomeIcon icon={faFilter} /><span className='m-2'>{props.title}</span></span>
        </Button>
    );
}

export function FilterBadgeButton(props) {
    const style = props.style || {};
    return (
        <Button variant='filter' style={style} onClick={props.onClick} active={props.active === true} disabled={props.disabled === true}>
            <span className='text-nowrap'><FontAwesomeIcon icon={faFilter} /><span className='m-2'>{props.title}</span><Badge pill bg={props.variant}>{props.children}</Badge></span>
        </Button>
    );
}

export function FilterBadgeButtonDashboard(props) {
    const style = props.style || { width: '100%' };
    return (
        <Button variant='filter-shadow' className={`text-center ${props.className ? props.className : ""}`} style={style} onClick={props.onClick} active={props.active === true} disabled={props.disabled === true}>
            <div className='cls-badge-btn-dash-label-filter d-flex justify-content-center align-items-center'><FontAwesomeIcon icon={faFilter} /><span className='mx-2'>{props.title}</span></div>
            <Badge pill bg={props.variant} className='cls-badge-btn-dash-pill-filter'>
                {props.children}
            </Badge>
        </Button>
    );
}

export function FilterBadgeButtonDashboardSmallVariant(props) {
    const style = props.style || { width: '100%' };
    return (
        <Button variant='filter-shadow' className={`text-center d-flex flex-column align-items-center p-3 ${props.className ? props.className : ""}`} style={style} onClick={props.onClick} active={props.active === true} disabled={props.disabled === true}>
            <div style={{ fontSize: "1rem" }} className='cls-badge-btn-dash-label-filter d-flex flex-column justify-content-center align-items-center text-nowrap'><FontAwesomeIcon size={"1x"} icon={faFilter} /><span className='mx-2'>{props.title}</span></div>
            <Badge pill bg={props.variant} className='p-2 w-100'>
                {props.children}
            </Badge>
        </Button>
    );
}

export function NavButton(props) {
    return (
        <LinkContainer className={`${props.className ? props.className : ""}`} to={props.to}>
            <Button disabled={props.disabled || false} variant='clear-shadow' className={`text-center w-100 `}>
                <div className='cls-badge-btn-dash-label' style={props.titleStyle}>{props.title}</div>
                <Badge pill bg={props.variant} className='cls-badge-btn-dash-pill'>
                    {props.children}
                </Badge>
            </Button>
        </LinkContainer>
    );
}

export function NavButtonSmallVariant(props) {
    return (
        <LinkContainer className={`${props.className ? props.className : ""}`} to={props.to}>
            <Button disabled={props.disabled || false} variant='clear-shadow' className={`text-center d-flex flex-column align-items-center w-100`}>
                <div className='h6' style={props.titleStyle}>{props.title}</div>
                <Badge pill bg={props.variant} className='p-2 w-100 h-2'>
                    {props.children}
                </Badge>
            </Button>
        </LinkContainer>
    );
}

export function SearchTableButton(props) {
    const [fieldValue, setFieldValue] = useState("");
    const style = props.style || { width: '300px' };

    const handleKeyPress = (e) => {
        if (e.charCode === 13) {

            props.onClick(fieldValue);
        }
    }
    const handleOnChange = (e) => {
        setFieldValue(e.target.value);
     
        props.onClick(e.target.value.trim()); //ZC I had to change to this as it was delaying as user needed to do an extra type to get the results
        //props.onClick(fieldValue.trim());
    }
    const handleOnClick = (e) => {
     
        props.onClick(fieldValue);
    }
    const handleOnClear = (e) => {
        setFieldValue('');
        props.onClick('');
    }
    return (
        <InputGroup className='cls-search-input-group' style={style}>
            <Form.Control placeholder={props.placeholder || 'Search'} aria-label='Search string' onChange={handleOnChange} onKeyPress={handleKeyPress} value={fieldValue} />
            <Button variant='search-reset' onClick={handleOnClear} disabled={fieldValue === ''}>
                <FontAwesomeIcon icon={faXmark} />
            </Button>
            <Button variant='search' onClick={handleOnClick}>
                <FontAwesomeIcon icon={faSearch} />
            </Button>
        </InputGroup>
    );
}

