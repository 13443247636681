import React from 'react';
import {Button, Modal} from "react-bootstrap";

const SynopsisModal = ({
                           isModalOpen = false,
                           closeModal = () => null
                       }) => {
    return (
        <Modal show={isModalOpen} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Synopsis help</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"mb-2 fw-bold"}>Notifications</div>
                <div>
                     We recommend you upload a synopsis for every learning item to include the following headings: Overview, Target Audience, Learning Content, Pre-requisites, Pre and Post work
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    );
};

export default SynopsisModal;
