import BaseContainer from '../components/BaseContainer';
import DashboardAlert from '../components/DashboardAlert';
import DashboardCarousel from '../components/DashboardCarousel';
import DashboardMyPlan from '../components/DashboardMyPlan';
import DashboardToDo from '../components/DashboardToDo';
import NewsStand from '../components/NewsStand';
import { Row, Col } from 'react-bootstrap';
import shallow from 'zustand/shallow';
import useLmsStore from "../hooks/useLmsStore";
import { useEffect, useState } from 'react';
import { apiAxiosCalls } from "../services/apiAxiosCalls";
import DashboardQualifications from '../components/DashboardQualifications';

const DashboardCore = (props) => {
    const [personId, setPersonId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [myQualificationsCount, setMyQualificationsCount] = useState({});
    const [outstandingCount, setOutstandingCount] = useState();
    const [isUKPN, setIsUKPN] = useState(false);

    const { personSettings, dashboardSummary, fetchDashboardSummary } = useLmsStore(state =>
    ({
        personSettings: state.personSettings.data,
        dashboardSummary: state.dashboardSummary.data,
        fetchDashboardSummary: state.fetchDashboardSummary
    }), shallow);

    useEffect(() => {
        setIsUKPN(personSettings?.theme?.toLowerCase() === 'ukpn')
    }, [personSettings]);

    const getDashboardQualifications = async (managerId) => {
        if (managerId == null || managerId == undefined) {
            throw new Error("Error getting Qualification count with invalid manager id");
            return;
        }
        setLoading(false);
        apiAxiosCalls.GetDashboardQualifications(managerId)
            .then((response) => {
                const actionsCounts = response.data.actionsCounts;
                setOutstandingCount(actionsCounts.competencyRewardedCount + actionsCounts.noShowCount + actionsCounts.requestsAwaitingCount);
                setMyQualificationsCount(response.data.myQualificationsCount);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false once data fetching is done
            })
    };

    useEffect(() => {
        if (personSettings?.ukpnPersonId) {
            setPersonId(personSettings?.ukpnPersonId);
            getDashboardQualifications(personSettings?.ukpnPersonId);
        }
    }, [personSettings?.ukpnPersonId]);

    return (
        <>
            <NewsStand personSettings={personSettings} />
            <Row>
                <Col xl={8}>
                    <Row className='gx-3'>
                        <DashboardToDo isUKPN={isUKPN} />
                    </Row>
                    <Row className='gx-3'>
                        <DashboardMyPlan {...props} />
                    </Row>
                    <DashboardQualifications
                        myQualificationsCount={myQualificationsCount}
                        basePath='/lms/qualifications'
                        paddingTop='6rem'
                        title="My Qualifications" />
                </Col>
                <Col xl={4}>
                    <Row className='justify-content-center'>
                        <DashboardAlert outstandingCount={outstandingCount} />
                    </Row>
                    <Row>
                        <DashboardCarousel personSettings={personSettings} dimensions={props} />
                    </Row>
                </Col>
            </Row>
        </>
    );
};


export default function Dashboard(props) {
    return (
        <BaseContainer title='Dashboard'>
            <DashboardCore />
        </BaseContainer>
    );
}
