import React, {useEffect} from 'react';
import {apiAxiosCalls} from "../../../services/apiAxiosCalls";
import useLmsStore, {responseData, getELearningPreviewLink } from "../../../hooks/useLmsStore";

const SRV_URL_LOCAL = 'https://localhost:53296';
const SRV_URL_LIVE = 'https://lms.wdr.co.uk/';
const SRV_URL_TEST = 'https://lms-portal.test.wdr.co.uk/';
const ELearningTabPreview = ({id}) => {
    const getBaseUrl = () => {
        return process.env.REACT_APP_SERVER === 'live' ? SRV_URL_LIVE : process.env.REACT_APP_SERVER === 'test' ? SRV_URL_TEST : SRV_URL_LOCAL
    }

    const extractProperties = (properties) => {
        let extractedProperties = {width: null, height: null, resizable: null}

        properties.forEach(property => {
            if (extractedProperties.hasOwnProperty(property.Name)) {
                extractedProperties[property.Name] = property.Value;
            }
        })

        return extractedProperties;
    }

    const getPreviewLink = async () => {
        try {
                getELearningPreviewLink(id).then((response) => {
                let data = responseData(response);
                if (data) {
                    console.log('data', data);
                    const properties = extractProperties(data.properties)
                    const windowFeatures = `width=${properties.width},height=${properties.height},resizable=${properties.resizable}`

                    window.open(data.previewLink, "_blank", windowFeatures);
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getPreviewLink()

    }, [])

    return (
        <div className={"p-3 mt-3"}>
            The preview of the eLearning course has been openned in a new window
        </div>
    );
};

export default ELearningTabPreview;
