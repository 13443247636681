import { useEffect, useMemo, useState } from 'react';

import { useSearchParams, useParams, Link, redirect, useHistory, useNavigate } from "react-router-dom";

import { TableContainer } from '../../../helpers/tableHelper';

import BaseContainer from '../../../components/BaseContainer';
import UserCard from '../../../components/UserCard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessageExclamation, faArrowAltSquareDown } from '@fortawesome/pro-solid-svg-icons';

import { cellToLocaleDateTimeString } from '../../../helpers/formatHelper';

import { teamProfileService } from '../services/team-profile.service';
import CustomTableView from '../../../components/common/generic-table-view';
import ViewDocument from './view-document';


export default function UserDocuments(props) {
    
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [documents, setDocuments] = useState([]);
    const [searchParams] = useSearchParams();
    const [showDocument, setShowDocument] = useState(false);

    const [docData, setDocData] = useState(null);
    const [documentMimeType, setDocumentMimeType] = useState(null);
    let params = useParams();
    const [documentId, setDocumentId] = useState(params.id);
    const { id } = params;
    const navigate = useNavigate();


    useEffect(() => {
        if (id > 0) 
        {
            setLoading(true);
            teamProfileService.getDocumentInfo(id)
            .then((result)=> 
                {
                    //console.log('result.docData:' + result.document);
                    setDocData(result.document);
                    setDocumentMimeType(result.documentMimeType);
                })
            .finally(() => { setLoading(false) });
        }
    }, [id]);



    const handleBackButtonClick = (event) => 
    {
        // event.preventDefault();
        //setShowDocument(false);
        navigate(-1);
    }

    return (
        <BaseContainer title='My Team Profiles / View Evidence Folder'>
            <TableContainer>
                {/* <div>user info:{JSON.stringify({...userInfo})}</div> */}
                {
                    <ViewDocument 
                        docData = {docData}
                        documentMimeType = {documentMimeType}
                        onBackButtonClick = {handleBackButtonClick}
                    ></ViewDocument>
                }

            </TableContainer>
        </BaseContainer>);
}