import "react-calendar/dist/Calendar.css";
// reference article: https://reactdatepicker.com/#example-input-time
import "react-datepicker/dist/react-datepicker.css";
import * as dayjs from "dayjs";
import DatePicker from "react-datepicker";
import MaterialReactTable from "material-react-table";
import ViewEventModel from "../../team-profile-management/components/view-event.model";
import { faCalendar, faClock, } from "@fortawesome/pro-regular-svg-icons";
import { Row, Col, } from "react-bootstrap";
import { apiAxiosCalls } from "../../../services/apiAxiosCalls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";

export default function UpcomingEventsListDashboard(props) {
  const [rowSelection, setRowSelection] = useState({});
  const [dataSelected, setDataSelected] = useState(null);
  const [tableOption, setTableOption] = useState("users");
  const [tableInactive, setTableInactive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [upcomingEventsSummary, setUpcomingEventsSummary] = useState([]);
  const [upcomingEventsBottomTable, setUpcomingEventsBottomTable] = useState(
    []
  );

  const [upcomingEventsDates, setUpcomingEventsDates] = useState([new Date()]);
  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  const [isOpenEventModel, setisOpenEventModel] = useState(false);
  const [displayNoEventAlert, setDisplayNoEventsAlert] = useState(false);
  const [dateValue, setDateValue] = useState(
    dayjs(new Date()).format(dateFormat)
  );

  const toggleEventModal = () => {
    setisOpenEventModel(false);
  };

  const GetUpcomingEventsDates = (ukpnPersonId, startDate) => {
    startDate = startDate
      ? startDate.toISOString()
      : dayjs(new Date().toISOString()).format("DD/MM/YYYY");
    setLoading(true);
    let counter = apiAxiosCalls.GetUpcomingEventCount(ukpnPersonId, startDate)
    counter.then((value) => {
      if (value && value.data){
       props.setUpcomingEventsCount(value.data);
      } else {
        props.setUpcomingEventsCount(0);
      }
    })
    .catch((error) => {
      throw new Error("Error getting upcoming events dates:" + error);
    })

    let data = apiAxiosCalls.GetUpcomingEventDates(ukpnPersonId, startDate);
    data
      .then((result) => {
        if (result && result.data) {
          let upcomingEventsDates = result.data.map(
            (x) => new Date(x.eventDate)
          );
          if (upcomingEventsDates) {
            setUpcomingEventsDates(upcomingEventsDates);
          }
        }
      })
      .catch((error) => {
        throw new Error("Error getting upcoming events dates:" + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const GetUpcomingEventsByDate = (eventDate) => {
    let formattedDate = dayjs(eventDate).format(dateFormat);
    setLoading(true);
    let data = apiAxiosCalls.GetUpcomingEventsByDate(
      props.ukpnPersonId,
      formattedDate
    );
    data
      .then((upcomingEvents) => {
        if (upcomingEvents && upcomingEvents.data) {
          let eventSummary = upcomingEvents.data;
          setUpcomingEventsSummary(
            eventSummary && eventSummary.length > 0 ? eventSummary : []
          );
          setisOpenEventModel(true);
          let upcomingEventsList = eventSummary.map((x) => ({
            eventID: x.eventID,
            fullName: x.fullName,
            startDate: dayjs(formattedDate).format("DD/MM/YYYY"),
            eventTime: x.startTime + " - " + x.endTime,
            status: x.status,
            address: x.roomName + ", " + x.venue,
          }));
          setUpcomingEventsBottomTable(upcomingEventsList);
        }
      })
      .catch((error) => {
        throw new Error("Error getting upcoming events dates:" + error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUpcomingEvents_old = async () => {
    /** now we are getting selected event detail from props.dashboardSummary rather making below call */
    return;
    try {
      let inputParams = {
        personId: null,
        startDate: dateValue ? dateValue.toISOString() : new Date(),
        endDate: dateValue ? dateValue.toISOString() : new Date(),
        searchEventsType: 1,
      };

      setLoading(true);
      const { data } = await apiAxiosCalls.getUpcomingEvents(inputParams);

      if (!data.isValid) {
        throw new Error("Error getting upcoming events");
      } else {
        setUpcomingEvents([
          ...data.value.map((x) => ({
            eventID: x.eventID,
            courseTitle: x.courseTitle,
            startDate: x.startDate,
            bookingID: x.bookingID,
            status: x.status,
          })),
        ]);
      }
    } catch (error) {
      console.log(`Error fetching upcoming events data: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props?.ukpnPersonId != null && props?.ukpnPersonId != undefined) {
      GetUpcomingEventsDates(props.ukpnPersonId);
    }
  }, [props.ukpnPersonId]);

  const sortedUpcomingEvents = useMemo(() => {
    if (upcomingEvents) {
      let sortedUpcomingEventsList = [...upcomingEvents].sort((a, b) =>
        a.startDate.localeCompare(b.startDate)
      );
      return sortedUpcomingEventsList;
    }
    return [];
  }, [upcomingEvents]);
  function onMonthChange(date) {
    setDateValue(date);
    GetUpcomingEventsByDate(date);
    setDisplayNoEventsAlert(false);
  }
  function onDateChange(selectedDateValue) {
    setDateValue(selectedDateValue);
    GetUpcomingEventsByDate(selectedDateValue);
    setDisplayNoEventsAlert(true);
  }

  const onGlobalFilterChange = (newSearchString) => {
    setSearchString(newSearchString);
  };

  const customGlobalFilterFn = (value, searchString) => {
    if (typeof value === "string" && typeof searchString === "string") {
      return value?.toLowerCase().includes(searchString.toLowerCase());
    }
    return false;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "fullName",
        header: "",
        Cell: ({ cell, row }) => {
          return (
            <>
              <b>{row.original.fullName || "None"}</b>
              <br />
              <FontAwesomeIcon icon={faCalendar} />
              <b> </b> {row.original.startDate || "None"}
              <br />
              <FontAwesomeIcon icon={faClock} />
              <b> </b> {row.original.eventTime || "None"}
              <br />
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Row>
        <Col className="mt-2 d-flex justify-content-center">
          <div style={{ maxWidth: '300px', minHeight: '242px' }} >
            <DatePicker
              onMonthChange={onMonthChange}
              selected={dateValue}
              onChange={(date) => onDateChange(date)}
              dateFormat={dateFormat}
              highlightDates={upcomingEventsDates}
              minDate={new Date()}
              showDisabledMonthNavigation
              inline
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="mt-2 d-flex justify-content-center">
          <MaterialReactTable
            columns={columns}
            data={upcomingEventsBottomTable || []}
            enableBottomToolbar={false}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableDensityToggle={false}
            enableFilterMatchHighlighting={true}
            enableFullScreenToggle={false}
            enableGlobalFilter={false}
            enableHiding={false}
            enableMultiRowSelection={false}
            enablePagination={false}
            enableRowSelection={false}
            enableStickyHeader={true}
            enableTopToolbar={true}
            enableExpanding={false}
            renderTopToolbarCustomActions={() => <h6>Upcoming events</h6>}
            globalFilterFn="contains"//By default is fuzzy
            muiTableContainerProps={{ sx: { height: '327px', width: '242px' } }}
            state={{
              isLoading: loading
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ViewEventModel
            closeFn={toggleEventModal}
            open={isOpenEventModel}
            okToOpen={displayNoEventAlert}
            eventStartDate={dateValue.toString()}
            upcomingEventsSummary={upcomingEventsSummary}
          />
        </Col>
      </Row>
    </>
  );
}
