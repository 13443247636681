import LinearProgress from '@mui/material/LinearProgress';
import { apiAxiosCalls } from "../../../services/apiAxiosCalls";
import { Button, Col, Row  } from "react-bootstrap";
import { useEffect, useState } from 'react';

export default function ViewCertificatesPdf(props) {
    const [loading, setLoading] = useState(true);
    const [pdfData, setPdfData] = useState([]);

    const getCertificatesPdf = async (certId) => {
        try {
            const { data } = await apiAxiosCalls.GetCertificatePdf(certId);

            if (!data.isValid) {
                throw new Error("Error getting certificates history");
            }
            else {
                setPdfData(data.value);
            }
        }
        catch (error) {
            console.log(`Error fetching certificates: ${error}`)
        }
        finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        setLoading(true);
        if (props.pdfData && props.pdfData.length > 0) {
            console.log("Looking to set pdfData");
            setPdfData(props.pdfData);
        }
        else {
            getCertificatesPdf(props.certId);
        }
        setLoading(false);
    },
        [props.certId, props.pdfData]);

    return (
        <Row>
            <Col>
                {
                    loading ?
                        <Row>
                            <Col>
                                <LinearProgress />
                            </Col>
                        </Row>
                        :
                        <>
                            <Row>
                                <Col md={12}>
                                    <div className='float-right' >
                                        <Button
                                            variant='btn btn-outline-primary'
                                            style={{ 'marginBottom': '10px', 'marginTop': '10px', marginLeft: '5px', marginRight: '10px', 'width': '100px' }}
                                            onClick={(event) => { props.onBackButtonClick() }}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {/* <embed src={`data:application/pdf;base64,${pdfData}`} width="100%" height="600px" /> */}
                                    <embed src={`data:application/pdf;base64,${pdfData}`} width="100%" height="600px" />
                                </Col>
                            </Row>
                        </>
                }
            </Col>
        </Row>
    );
}
