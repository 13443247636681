import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import SaveAndCancelButton from "../SaveAndCancelButton";
import GroupManagementTabSearchField from "../GroupManagementTabSearchField";
import {apiAxiosCalls} from "../../../services/apiAxiosCalls";
import MaterialReactTable from "material-react-table";
import {TableContainer} from "../../../helpers/tableHelper";
import GenericCheckBox from "../../common/GenericCheckBox";
import useLmsStore, { postSaveGroup } from "../../../hooks/useLmsStore";
import {FormButtons} from "../../buttons/FormButtons";
const pageSize = 20
const initialSearchPayload = {
    groupId: null,
    filter: "",
    itemCount: pageSize,
    itemOffset: 0
}

const payload = {
    groupId: -1,
    Name: "",
    Description: "",
    Reports: [],
    GroupMembers: [],
    TrainingCards: [],
    Permissions: [],
    LearningItems: [],
    Groups: []
}

function GroupMembersTab({group, onSuccessUpdateGroup, onRequestLeaveCompleted, requestLeave}) {

    const [searchTerm, setSearchTerm] = useState("")
    const [searchPayload, setSearchPayload] = useState({...initialSearchPayload})
    const [searchCount, setSearchCount] = useState(0)
    const [searchResults, setSearchResults] = useState([])
    const [payloadForGroupMembers, setPayloadForGroupMembers] = useState([])
    const hasMore = (searchPayload.itemOffset) <= searchCount
    const [isUpdating, setIsUpdating] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const searchForGroupMembers = async () => {
        try {
            if (group) {
                setIsLoading(true)
                const result = await apiAxiosCalls.searchGroupMembers({...searchPayload, groupId: group.Id})
                setSearchCount(result.data.Value.Count)
                setSearchResults([...searchResults, ...result.data.Value.Items])
                setSearchPayload({...searchPayload, itemOffset: searchPayload.itemOffset + pageSize})
            }


        } catch (e) {

        } finally {
            setIsLoading(false)
        }
    }

    const onUpdate = async () => {
        try {
            setIsUpdating(true)
            await postSaveGroup(useLmsStore, {
                ...payload,
                groupId: group.Id,
                GroupMembers: payloadForGroupMembers,
                Name: group?.Name,
                Description: group?.Description,
            })
           /* await apiAxiosCalls.createGroupApiCall({
                ...payload,
                groupId: group.Id,
                GroupMembers: payloadForGroupMembers,
                Name: group?.Name,
                Description: group?.Description,
            }) */
            setIsUpdating(false)
            await onSuccessUpdateGroup(group)
            setPayloadForGroupMembers([])
        } catch (e) {
            setIsUpdating(false)
        }
    }

    const [triggerSearch, setTriggerSearch] = useState(0)

    const onSearchClick = () => {
        setSearchResults([])
        setSearchCount(0)
        setSearchPayload({
            ...initialSearchPayload, filter: searchTerm
        })
        setTriggerSearch(triggerSearch + 1)
    }

    useEffect(() => {
        if (triggerSearch) {
            searchForGroupMembers()
        }
    }, [triggerSearch])

    useEffect(() => {
        setSearchResults([])
        setSearchCount(0)
        setSearchTerm("")
        setSearchPayload({
            ...initialSearchPayload
        })
        setTriggerSearch(triggerSearch + 1)

    }, [group])


    const renderCell = (cell) => {
        return cell.row.original ? <GenericCheckBox
            payloadPart={payloadForGroupMembers}
            setPayloadPart={setPayloadForGroupMembers}
            singleSearchResult={cell.row.original}
        >
            placeholder
        </GenericCheckBox> : <></>
    }

    const columns = useMemo(() => {
        return [

            {
                header: ' ', size: 30, Cell: ({cell}) => renderCell(cell),

            },
            {
                accessorKey: "FullName",
                header: 'FullName',
                size: 100,
            },
            {
                accessorKey: "EmployeeId",
                header: 'Employee ID',
                size: 100,
            },
            {
                accessorKey: "PrimaryEmailAddress",
                header: 'Email',
                size: 100,
            },
            {
                accessorKey: 'ReportsTo',
                enableEditing: false,
                size: 160,
                header: 'Line manager'
            },
        ]
    }, [payloadForGroupMembers, setPayloadForGroupMembers, searchResults])

    const tableContainerRef = useRef(null);
    const fetchMoreOnBottomReached = useCallback((containerRefElement) => {
        if (apiAxiosCalls.searchGroupsCancellationToken) {
            return
        }
        if (containerRefElement) {
            const {scrollHeight, scrollTop, clientHeight} = containerRefElement;
            //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
            if (
                scrollHeight - scrollTop - clientHeight < 20 && hasMore

            ) {
                searchForGroupMembers();
            }
        }
    }, [tableContainerRef, searchForGroupMembers, hasMore])

    const topToolbarStyles = {
        minHeight: isLoading ? '4px' : 'auto',
        padding: isLoading ? '0' : '0.5rem 1rem',
    };

    const renderTable = useCallback(() => {
        return <TableContainer>
            <MaterialReactTable
                columns={columns}
                data={searchResults}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableGlobalFilter={false}
                enableGrouping={false}
                enableHiding={false}
                enablePagination={false}
                enableSorting={false}
                enableTopToolbar={true}
                muiTableBodyRowProps={{hover: false}}
                enableBottomToolbar={false}
                enableStickyHeader
                enableRowVirtualization={false}
                muiTopToolbarProps={{
                    style: topToolbarStyles,
                }}
                muiTableContainerProps={{
                    ref: tableContainerRef,
                    sx: {maxHeight: '400px'},
                    onScroll: (e) => {
                        fetchMoreOnBottomReached(e.target)
                    }
                }}
                muiTableProps={{
                    sx: {
                        tableLayout: 'fixed',

                    },
                }}
                state={{showProgressBars: isLoading}}
            />
        </TableContainer>
    }, [payloadForGroupMembers, searchResults, searchCount, searchTerm, setPayloadForGroupMembers, isLoading])

    return (
        <div className={''}>
            <GroupManagementTabSearchField onSearchClick={onSearchClick} setSearchTerm={setSearchTerm}
                                           searchTerm={searchTerm} placeHolder={"Search group members"}/>
            <div className={" align-items-center justify-content-center"}>

                {renderTable()}
                <FormButtons
                    hasUnsavedChanges={payloadForGroupMembers.length}
                    pleaseWait={isUpdating}
                    requestLeave={requestLeave}
                    onRequestLeaveCompleted={onRequestLeaveCompleted}
                    cancelButtonProps={{
                        onClick: ()=>{setPayloadForGroupMembers([])}
                    }}
                    saveButtonProps={{
                        onClick: () => {
                            onUpdate()
                        }
                    }}
                />
            </div>
        </div>
    );
}

export default GroupMembersTab;
