import Spinner from 'react-bootstrap/Spinner';
import shallow from 'zustand/shallow';
import useLmsStore, {fetchPersonSettings, postSetAlertsAsRead} from "../hooks/useLmsStore";
import { NavButton } from './CustomButtons';
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from 'react';
import useLegacyStore from '../hooks/useLegacyStore';
import { userService } from '../services/common/user.service';

export default function DashboardAlerts(props) 
{
    const [isAlertsRead, setIsAlertsRead] = useState(false);

    const {myLatestAlerts, fetchMyLatestAlerts} = useLmsStore(state =>
    ({
        myLatestAlerts: state.myLatestAlerts.data,
        fetchMyLatestAlerts: state.fetchMyLatestAlerts
    }), shallow)

    const { personSettings, fetchPersonSettings } = useLmsStore(state =>
        ({
            personSettings: state.personSettings.data,
            fetchPersonSettings: state.fetchPersonSettings
        }), shallow)
    
        useEffect(() => {

            const subscription = userService.getIsAlertsRead().subscribe(value => 
            {
                setIsAlertsRead(value);
                //console.log('IsAlertsRead:' + isAlertsRead);
            }); 
            fetchPersonSettings();
            let totalReadAlerts = myLatestAlerts?.filter(x=> x.isRead == true);
            if (totalReadAlerts && totalReadAlerts.length == myLatestAlerts?.length)
                {
                    setIsAlertsRead(true);
                }            

            return () => subscription.unsubscribe();

        }, [isAlertsRead, myLatestAlerts]);

        const changeAlertsReadStatus = (event) => 
        {
            event.preventDefault();
            let isAlertsRead = userService.IsAlertsRead;

            let totalUnReadAlerts = myLatestAlerts.filter(x=> x.isRead == false);
            // console.log('isAlertsRead:' + isAlertsRead);
            // console.log('totalUnReadAlerts:' + totalUnReadAlerts);
            if (totalUnReadAlerts && totalUnReadAlerts.length > 0 && isAlertsRead == false)
            {
                postSetAlertsAsRead(useLegacyStore, personSettings.portalPersonId).then(result => 
                    {
                        userService.IsAlertsRead = (result == true);
                    })
            }
            else if (totalUnReadAlerts.length == 0)
            {
                userService.IsAlertsRead = true;
            }
        }

    return (
        <Row>
            {
                !personSettings.isAdministrator && !personSettings.isManager && !personSettings.isApplicationAdministrator  ?
                <Col xs={6}></Col> : null
            }
            {
                !isAlertsRead ?
                <Col xs={6}
                  onClick={(x)=> changeAlertsReadStatus(x)}
                >
                    <NavButton 
                        variant={!myLatestAlerts || myLatestAlerts.length === 0 ? 'info' : 'warning'} title='Notifications' to='/lms/alerts'
                    >
                    {!myLatestAlerts ? <Spinner animation="grow" variant="light" size="sm" /> : myLatestAlerts?.filter(x=> x.isRead == false)?.length}
                    </NavButton>
                </Col> :
                <Col xs={6}>
                <NavButton variant={'info'} title='Notifications' to='/lms/alerts'>
                    0
                </NavButton>
            </Col>

            }
            <DisplayActions details={personSettings} data={props} />
        </Row>
    );
}

function DisplayActions(props) { 
    return (
        <>
        { 
            props.details.isAdministrator || props.details.isManager || props.details.isApplicationAdministrator  ?
            <Col xs={6}>
                <NavButton variant={!props.data.outstandingCount || props.data.outstandingCount === 0 ? 'info' : 'danger'} title='Outstanding Actions' to='/lms/outstanding'>
                {props.data.outstandingCount === undefined ? <Spinner animation="grow" variant="light" size="sm" /> : props.data.outstandingCount}
                </NavButton>
            </Col>
            : null}
        </>);
};
