import axios from "axios";
import FAQsCommonItems from '../components/FAQsCommonItems';
import MailTo from '../components/MailTo';
import ModalForceChangePassword from '../components/ModalForceChangePassword';
import ModalForgottenPassword from '../components/ModalForgottenPassword';
import ModalTermsAndConditions from "../components/ModalTermsAndConditions";
import useLocationConfig from '../hooks/useLocationConfig';
import { Accordion, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { clearStore } from "../hooks/useLmsStore";
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from "./hooks/useAuth";
import { useGetApplicationLoginPageConfiguration } from "../hooks/useGetApplicationLoginPageConfiguration";
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";

/**
 * This function is used exclusively to check the success / failure of the B2C login 
 */
export const authAxios = axios.create({
    withCredentials: true
});

authAxios.interceptors.request.use((config) => {
    // Abort if no token present (user not logged in)
    const token = window.localStorage.getItem('token') !== null && window.localStorage.getItem('token').length > 0 ? window.localStorage.getItem('token').replace(/"/g, '') : "";
    if (token && token.length > 0) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (!token) {
        throw new Error('No Authorization Token');
    }
    return config;
}, (error) => {
    return Promise.reject(error); // Delegate error to calling side
});

/**
 * This function is used exclusively to handle a B2C login failure
 */
export const authAxiosOnError = (error, msalInstance, logout, currentAccount) => {
    try {
        console.error(error);
        const status = error.response?.status || 500;
        let redirectUrl;
        // eslint-disable-next-line
        if (status == '401') {
            redirectUrl = '/lms/autherror?error=401';
        } else {
            redirectUrl = `/lms/servererror?error=${status}`
        }
        if (currentAccount) {
            msalInstance.logoutRedirect({
                account: msalInstance.getActiveAccount(), // The account that needs to be signed out
                postLogoutRedirectUri: redirectUrl

            });
        } else {
            window.location = `${window.location.protocol}//${window.location.host}${redirectUrl}`
        }
    } finally {
        // Remove token for good measure
        logout();
    }
};

export default function AuthLogin() {
    const location = useLocation();
    const navigate = useNavigate()
    const { instance } = useMsal();
    const { login, logout } = useAuth();
    const { themeName } = useLocationConfig();
    const { loginPageConfiguration, loading } = useGetApplicationLoginPageConfiguration()
    const [inProgress, setInProgress] = useState(true);
    const [terms, setTerms] = useState("");
    const [user, setUser] = useState(null);
    const [isOpenForgottenPsw, setisOpenForgottenPsw] = useState(false);
    const [isOpenTerms, setisOpenTerms] = useState(false);
    const [isOpenForceChangePsw, setisOpenForceChangePsw] = useState(false);
    const [serviceLabel, setServiceLabel] = useState('Learning Service');
    const [serviceEmail, setServiceEmail] = useState('learning.service@wdr.co.uk');
    const queryParams = new URLSearchParams(location.search);
    const samlError = queryParams.get("error");

    useEffect(() => {
        // Make sure the store is empty;
        clearStore();
        if (window.location.hostname.includes('cerespoweracademy')) {
            setServiceLabel('Ceres Academy');
            setServiceEmail('ceres.academy@wdr.co.uk');
        }
    }, []);

    function toggleModalForgottenPsw() {
        setisOpenForgottenPsw(!isOpenForgottenPsw);
    }

    function toggleModalTerms() {
        setisOpenTerms(!isOpenTerms);
    }

    function toggleModalForceChangePsw() {
        setisOpenForceChangePsw(!isOpenForceChangePsw);
    }

    /*
    This function is called when the person login and the user is set sucessfully and is used to check if they require to accept terms and condition
    */
    function processAcceptTermsAndChangePsw() {
        if (user != null) {
            //Check if the user had not approved the terms and conditions
            if (!user.agreedTermsAndConditionsVersion) {
                //Open terms and conditions modal
                setTerms(user.terms);
                toggleModalTerms();
            } else {
                //Carry on with the login process
                processLogin();
            }
        }
    }

    /* After all the checking are done sucessfully, do final setting and go to the dashboard page */
    function processLogin() {
        login(user.token);
        setInProgress(false);
    }

    /* This function is call the when submit terms ad condition modal
    */
    function onCloseSubmitModalTerms(event) {

        //Check if require password reset if not then login
        if (user.requiresPasswordReset) {
            toggleModalTerms();
            toggleModalForceChangePsw();
            // handleShowForceChangePsw();
        } else {
            processLogin();
            event.preventDefault();
            event.stopPropagation();
        }
    }

    const [showFAQs, setShowFAQs] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const handleCloseFAQs = () => setShowFAQs(false);
    const handleShowFAQs = () => setShowFAQs(true);
    const handleCloseSignUp = () => setShowSignUp(false);
    const handleShowSignUp = () => setShowSignUp(true);
    const onLoginWithB2CClick = () => {
        setInProgress(true);
        instance.loginRedirect();
    }
    const onLoginWithOktaClick = () => {
        setInProgress(true);
        window.location.href = loginPageConfiguration?.applicationLogInMethods?.oktaSigninUrl
    }
    const onLoginWithMicrosoftAccountClick = () => {
        setInProgress(true);
        window.location.href = loginPageConfiguration?.applicationLogInMethods?.microsoftAccountSignInUrl
    }

    const goToStandardLogin = () => {
        navigate("/login")
    }

    const goToUkpnContractorAccountSetup = () => {
        navigate("/ukpnLogin")
    }

    //Load when the user change after the login iss approved
    useEffect(() => {
        // Process terms and condtions and change password procedure
        processAcceptTermsAndChangePsw();
    }, [user]);

    useEffect(() => {
        if (samlError) {
            window.location = `${window.location.protocol}//${window.location.host}/lms/autherror?error=${samlError}`
        }
    }, [samlError])

    useEffect(() => {
        // If not null then this is a B2C Account
        const currentAccount = instance.getActiveAccount();
        if (currentAccount) {
            const fn = async () => {
                setInProgress(true);
                const tokenRequest = {
                    account: currentAccount,
                    scopes: instance.config.auth.scopes,
                };
                await instance.acquireTokenSilent(tokenRequest).then(async response => {
                    login(response.accessToken)
                    await authAxios.get('/api/account/setApReToSesion').then(res => {
                        window.location.href = '/lms/mylearningplan';
                    }).catch((e) => {
                        authAxiosOnError(e, instance, logout, currentAccount);
                    })
                }).catch(e => {
                    authAxiosOnError(e, instance, logout, currentAccount);
                    setInProgress(false);
                });
            };
            fn();
        } else {
            setInProgress(false);
        }
    }, [instance])

    return (<>
        {
            (inProgress === true) ?
                <div style={{ minHeight: "100vh" }} className="d-flex justify-content-center align-items-center" >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="row">
                            <div className="spinner-border mt-5 text-primary" style={{ width: '5rem', height: '5rem' }}
                                role="status">
                                {/* <span className="sr-only">Loading...</span> */}
                            </div>
                        </div>
                        <div className="row mt-2">
                            <strong>Establishing session</strong>
                        </div>
                    </div>
                </div >
                :
                <>
                    <Row className="m-0 ">
                        <Col className="cls-login-col-left p-0" md={6} lg={5} xl={4} xxl={3}>
                            <div className="cls-login-colour-ovl d-flex align-items-center justify-content-center">
                                <Row style={{ width: '100%' }}>
                                    <Col sm={2} md={1}></Col>
                                    <Col>
                                        <Card className="mb-5" style={{ width: '100%' }}>
                                            <div className="d-flex justify-content-end"
                                                style={{ margin: ' 1rem 1.5rem 0 0' }}>
                                                {themeName ? <Card.Img variant="top"
                                                    src={require(`../assets/instance/${themeName}/logo.png`)}
                                                    style={{ width: '140px' }} /> : <></>}
                                            </div>
                                            <Card.Body>
                                                <Card.Title>Sign In</Card.Title>
                                                <Form>
                                                    <Form.Group className="mb-3">
                                                        <Button
                                                            type="button"
                                                            style={{ backgroundColor: 'var(--app-login-ovl)' }}
                                                            variant="primary"
                                                            onClick={onLoginWithB2CClick}
                                                            disabled={loading}
                                                            className="w-100 mt-3">
                                                            B2C Login
                                                        </Button>
                                                        {
                                                            loginPageConfiguration &&
                                                            loginPageConfiguration?.applicationLogInMethods?.oktaSigninUrl &&
                                                            <Button
                                                                type="button"
                                                                style={{ backgroundColor: 'var(--app-login-ovl)' }}
                                                                variant="primary"
                                                                onClick={onLoginWithOktaClick}
                                                                disabled={loading}
                                                                className="w-100 mt-3">
                                                                Log in with okta
                                                            </Button>
                                                        }
                                                        {
                                                            loginPageConfiguration &&
                                                            loginPageConfiguration?.applicationLogInMethods?.microsoftAccountSignInUrl &&
                                                            <Button
                                                                type="button"
                                                                style={{ backgroundColor: 'var(--app-login-ovl)' }}
                                                                variant="primary"
                                                                onClick={onLoginWithMicrosoftAccountClick}
                                                                disabled={loading}
                                                                className="w-100 mt-3">
                                                                Log in with microsoft account
                                                            </Button>
                                                        }
                                                        <Button
                                                            type="button"
                                                            style={{ backgroundColor: 'var(--app-login-ovl)' }}
                                                            variant="primary"
                                                            onClick={goToStandardLogin}
                                                            disabled={loading}
                                                            className="w-100 mt-3">
                                                            Standard login
                                                        </Button>
                                                        <Button
                                                            type="button"
                                                            style={{ backgroundColor: 'var(--app-login-ovl)' }}
                                                            variant="primary"
                                                            onClick={goToUkpnContractorAccountSetup}
                                                            disabled={loading}
                                                            className="w-100 mt-3">
                                                            UKPN contractor account setup
                                                        </Button>
                                                    </Form.Group>
                                                </Form>
                                                <Row className="mt-3">
                                                    <Col style={{ width: '60px' }}><Button variant="clear" size="lg"
                                                        onClick={handleShowFAQs}
                                                        disabled={loading}><FontAwesomeIcon
                                                            style={{ color: 'var(--app-login-ovl)' }} icon={faCircleQuestion}
                                                            className='m-0' /></Button></Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={2} md={1}></Col>
                                </Row>
                            </div>
                        </Col>
                        <Col className="cls-login-col-right">
                            <div className="cls-login-colour-ovl"></div>
                        </Col>
                    </Row>
                    <Modal fullscreen='xl-down' size='xl' show={showFAQs} onHide={handleCloseFAQs}>
                        <Modal.Header closeButton>
                            <Modal.Title>FAQs</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h5>Learning Portal - FAQs Frequently Asked Questions</h5>
                            <p>The Learning Portal manages the administration and delivery of online learning
                                (eLearning)
                                courses and learning or training materials, and at the same time tracks members’ online
                                learning
                                progression.</p>
                            <Accordion>
                                <FAQsCommonItems serviceEmail={serviceEmail} serviceLabel={serviceLabel} />
                            </Accordion>
                            <p className="mt-4 text-center">
                                <h6>Can't find the answer to your question?</h6>
                                <MailTo email={`${serviceEmail}?subject=LMS Support Query`}>Click Here</MailTo> to send
                                us an
                                email.
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleCloseFAQs}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showSignUp} onHide={handleCloseSignUp}>
                        <Modal.Header closeButton>
                            <Modal.Title>Sign-up</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Please send an email to <MailTo
                                email='wdrassist@wdr.co.uk?subject=LMS Access Request'>wdrassist@wdr.co.uk</MailTo> to
                                request
                                access.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleCloseSignUp}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                    <ModalForgottenPassword closeFn={toggleModalForgottenPsw}
                        open={isOpenForgottenPsw}
                    />
                    <ModalTermsAndConditions closeFn={toggleModalTerms} closeAndSubmitFn={onCloseSubmitModalTerms}
                        open={isOpenTerms} termsText={terms} />
                    <ModalForceChangePassword closeFn={toggleModalForceChangePsw} closeAndSubmitFn={processLogin}
                        open={isOpenForceChangePsw} />
                </>
        }
    </>);
}
